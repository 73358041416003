import React, { useEffect, useState, useContext } from 'react';
import { Icons } from '@ems/client-design-system';
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import theme from '../../styles/themes.json';
import { useMutation } from '@apollo/react-hooks';

// mutations
import { UPDATE_SETTING } from 'src/@settings/mutations';

// actions
import { updateOperationTheme, updateDistanceUnits } from 'src/app/actions';
// providers
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';

// Components
import { SettingsSelector } from 'src/app/components/SettingsSelector';

const TrackIcons = ({ value }) => {
  const themePath = theme.operations[value];

  return (
    <>
      {Object.entries(themePath).map(([key, value], i) => (
        <Icons
          iconName={`ic-ad-${key}`}
          size={24}
          style={{
            fill: value,
            color: value,
          }}
          key={i}
        />
      ))}
    </>
  );
};

export const GeneralSettings: React.FC<{}> = ({}) => {
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const selectedDistanceUnits = configSelectors.getDistanceUnitsProfile();
  const currentConfig = configSelectors.getConfig();
  const hasLocalUnitSystem = currentConfig.globals.units !== undefined;

  const [selectedTrackThemeRadio, setSelectedTrackThemeRadio] = useState<string | null>(
    selectedTrackTheme
  );
  const [selectedDistanceUnitRadio, setSelectedDistanceUnitRadio] = useState<string | null>(
    selectedDistanceUnits
  );

  console.log(selectedDistanceUnitRadio);
  const dispatcher = useContext(GlobalDispatchContext);
  const [updateSettingsForDeployedProduct] = useMutation(UPDATE_SETTING, {
    update() {},
  });

  const trackColourOptions = [
    {
      label: '',
      value: 'default',
      body: <TrackIcons value="default" />,
    },
    {
      label: '',
      value: 'defense',
      body: <TrackIcons value="defense" />,
    },
    {
      label: '',
      value: 'classic',
      body: <TrackIcons value="classic" />,
    },
  ];

  const distanceUnitOptions = [
    {
      label: 'US Customary',
      value: 'US Customary',
    },
    {
      label: 'ICAO Metric',
      value: 'ICAO Metric',
    },
    {
      label: 'ICAO Alternative',
      value: 'ICAO Alternative',
    },
  ];
  if (hasLocalUnitSystem) {
    distanceUnitOptions.push({
      label: 'Local System',
      value: 'Local System',
    });
  }

  const handleThemeChange = (value: string) => {
    const capitalizeValue = value.charAt(0).toUpperCase() + value.slice(1);
    const settingsPath = [
      { parameter: 'globals/colors/operations/colorScheme', value: capitalizeValue },
    ];
    updateSettingsForDeployedProduct({
      variables: {
        settings: settingsPath,
      },
    }).then(() => {
      updateOperationTheme(dispatcher, value);
      setSelectedTrackThemeRadio(value);
    });
  };

  const handleDistanceChange = (value: string) => {
    const settingsPath = [{ parameter: 'globals/unitProfileName', value }];
    updateSettingsForDeployedProduct({
      variables: {
        settings: settingsPath,
      },
    }).then(() => {
      updateDistanceUnits(dispatcher, value);
      setSelectedDistanceUnitRadio(value);
    });
  };

  useEffect(() => {
    if (selectedTrackThemeRadio === null) {
      setSelectedTrackThemeRadio(trackColourOptions[0].value);
    }
  });

  return (
    <>
      <SettingsSelector
        title={'Units'}
        className="distance-units"
        options={distanceUnitOptions}
        selected={selectedDistanceUnitRadio}
        onChangeHandler={handleDistanceChange}
      />
      <SettingsSelector
        title={'Track Theme'}
        className="track-theme"
        options={trackColourOptions}
        selected={selectedTrackThemeRadio}
        onChangeHandler={handleThemeChange}
      />
    </>
  );
};
