import { useContext } from 'react';
import uuid from 'uuid';
// ts
import { IDataSelectors, IDataState, IFetchedData } from 'src/@operations/props';
import { actionTypes } from 'src/@operations/newActionTypes';
import { appActionTypes } from 'src/app/actionTypes';
import { OperationStateContext } from 'src/@operations/providers/OperationsStateProvider';
// function
import { useSelectors } from 'src/utils/storeHelpers';
import {
  uniqueIds,
  setSelectionDelta,
  getMapDataFromSelectedIds,
  convertMapToArray,
} from 'src/utils';

export const useDataSelectors: () => IDataSelectors = () => {
  const state: any = useContext(OperationStateContext);
  const dataState: IDataState = state.data;

  return useSelectors(dataState, (state: IDataState) => ({
    getDataInformation: () => ({
      data: state.fetchedData,
      pageInfo: state.pageInfo,
      totalCount: state.totalCount,
      selectedInTable: state.selectedInTable,
      selectedInMap: state.selectedInMap,
      selectedOperations: state.selectedOperations,
      isLoading: state.isLoading,
      areAllRowsSelected: areAllRowsSelected(state),
      isLoadingMore: state.isLoadingMore,
    }),
    getTotalCount: () => (state.hasTotalCount ? state.totalCount : -1),
    getSelectedIDs: (): number[] => {
      return uniqueIds([...state.selectedInTable, ...state.selectedInMap]);
    },
    getSelectedDateRange: () => state.selectedDateRange,
    getRequiredDataForMap: () => {
      return {
        selectionLength: state.selectedInMap.length,
        addedToSelection: state.addedToSelection,
        removedFromSelection: state.removedFromSelection,
        selectedData: state.selectedOperations.map(
          getMapDataFromSelectedIds(state.fetchedData, 'id')
        ),
      };
    },
    getDataFromID: (id: number) => {
      const foundData = state.fetchedData.get(id);
      if (foundData) {
        return {
          runwayName: foundData.runwayName,
          aircraftCategory: foundData.aircraftCategory,
          operatorCategory: foundData.operatorCategory,
          airportId: foundData.airportId,
          remoteAirportId: foundData.remoteAirportId,
          operationType: foundData.operationType,
          aircraftType: foundData.aircraftType,
          time: foundData.time,
        };
      }
      return {};
    },
    getNavigationData: () => {
      const itemsIds: number[] = [];
      // convert map selection to an array of IDs
      state.fetchedData.forEach((value, key) => {
        itemsIds.push(key);
      });
      return {
        itemsIds,
        hasNextPage: state.pageInfo ? state.pageInfo.hasNextPage : false,
        endCursor: state.pageInfo ? state.pageInfo.endCursor : undefined,
        dateRange: state.selectedDateRange,
      };
    },
  }));
};

export const dataInitialState: IDataState = {
  isLoading: true,
  selectedInTable: [],
  selectedInMap: [],
  selectedOperations: [],
  selectedDateRange: null,
  addedToSelection: [],
  removedFromSelection: [],
  fetchedData: new Map(),
  isLoadingMore: false,
  pageInfo: undefined,
  totalCount: undefined,
  hasTotalCount: false,
};

const areAllRowsSelected = state => {
  if (state.fetchedData.size && state.selectedInTable && state.selectedInTable.length) {
    if (state.fetchedData.size === state.selectedInTable.length) {
      return true;
    } else {
      return 'indeterminate';
    }
  }
  return false;
};

const setLocalSelectionData = (state, ids) => {
  const { removedFromSelection, addedToSelection } = setSelectionDelta(ids, [
    ...state.selectedInTable,
  ]);

  state.removedFromSelection = removedFromSelection.map(
    getMapDataFromSelectedIds(state.fetchedData, 'id')
  );
  state.addedToSelection = addedToSelection.map(getMapDataFromSelectedIds(state.fetchedData, 'id'));
};

const addtableId = (currentData, newData: IFetchedData[]) => {
  const data = new Map(currentData);
  for (const operation of newData) {
    data.set(operation.id, {
      ...operation,
      tableId: uuid.v4(),
    });
  }
  return data;
};

export const dataReducer = (
  state: IDataState,
  action: any,
  isDynamicTileServer: boolean = false
) => {
  switch (action.type) {
    case actionTypes.GET_FIRST_FETCH:
      return Object.assign({}, state, {
        isLoading: true,
        hasTotalCount: false,
      });
    case actionTypes.DATA_FETCHED:
      const { data, selectedDateRange, pageInfo } = action.data;
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingMore: false,
        selectedDateRange,
        fetchedData: addtableId(state.fetchedData, data),
        pageInfo,
      });
    case actionTypes.GET_TOTAL_COUNT:
      return Object.assign({}, state, {
        totalCount: action.data.totalCount,
        hasTotalCount: true,
      });
    case actionTypes.LOAD_MORE:
      return Object.assign({}, state, {
        isLoadingMore: true,
      });
    case actionTypes.SELECT_ROW:
      const operationsForMap = action.data.map(id => state.fetchedData.get(id));
      setLocalSelectionData(state, action.data);
      return Object.assign({}, state, {
        selectedInTable: action.data,
        selectedInMap: operationsForMap.map(operation => {
          return isDynamicTileServer ? operation.properties.id : operation.id;
        }),
        selectedOperations: operationsForMap,
      });
    case actionTypes.SELECT_TRACK:
      const selectedInTable: number[] = [];
      let selectedInMap: any[] = [];
      let selectedOperations = [];
      // TODO: What if action.data is null and what that means
      if (typeof action.data !== 'undefined' && action.data) {
        selectedInMap = action.data.map(operation => {
          return isDynamicTileServer ? operation.properties.id : operation.id;
        });
        setLocalSelectionData(state, selectedInMap);
        selectedOperations = action.data;
        if (action.data && action.data.length) {
          const dataLength = action.data.length;
          for (let i = dataLength; i--; ) {
            if (state.fetchedData.has(selectedInMap[i])) {
              selectedInTable.push(selectedInMap[i]);
            }
          }
        }
      }
      return Object.assign({}, state, {
        selectedInTable,
        selectedInMap,
        selectedOperations,
      });
    case actionTypes.INLINE_EDIT:
      const { updatedItem } = action.data;
      const currentItem = state.fetchedData.get(updatedItem.id);
      const dataEdited = new Map(state.fetchedData);

      dataEdited.set(updatedItem.id, {
        ...currentItem,
        ...updatedItem,
        tableId: uuid.v4(),
      });
      return Object.assign({}, state, {
        fetchedData: dataEdited,
      });
    case actionTypes.UPDATE_TABLE_IDS: {
      if (state.fetchedData.size) {
        const { items } = convertMapToArray(state.fetchedData);

        return Object.assign({}, state, {
          fetchedData: addtableId([], items),
        });
      }

      return state;
    }
    case appActionTypes.ROUTE_CHANGE:
    case actionTypes.RESET_DATA:
      // remove tracks before resetting store
      setLocalSelectionData(state, []);

      if (action.data && action.data.resetMap) {
        state.selectedOperations = [];
      }

      return Object.assign({}, state, {
        isLoading: true,
        selectedInTable: [],
        selectedInMap: state.selectedOperations,
        selectedOperations: [],
        selectedDateRange: null,
        addedToSelection: [],
        fetchedData: new Map(),
        isLoadingMore: false,
        pageInfo: undefined,
        totalCount: undefined,
        hasTotalCount: false,
      });
    default:
      return state;
  }
};
