import { useContext } from 'react';
import uuid from 'uuid';
import { IDataSelectors, IDataState, IFetchedData } from 'src/@infringements/props';
import { dataActionTypes } from 'src/@infringements/newActionTypes';
import { appActionTypes } from 'src/app/actionTypes';
import { InfringementStateContext } from 'src/@infringements/providers/InfringementsStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';
import { setSelectionDelta, getMapDataFromSelection } from 'src/utils';
import { NOISE_INFRINGEMENT } from 'src/constants';

export const useDataSelectors: () => IDataSelectors = () => {
  const state: any = useContext(InfringementStateContext);
  const dataState: IDataState = state.data;

  return useSelectors(dataState, (state: IDataState) => ({
    getDataInformation: () => ({
      data: state.fetchedData,
      pageInfo: state.pageInfo,
      totalCount: state.totalCount,
      selectedData: state.selectedData,
      isLoading: state.isLoading,
      areAllRowsSelected: areAllRowsSelected(state),
      isLoadingMore: state.isLoadingMore,
    }),
    getTotalCount: () => (state.hasTotalCount ? state.totalCount : -1),
    getSelectedIDs: (selectedIndexes: number[]): number[] => {
      return selectedIndexes.map(index => state.fetchedData[index].id);
    },
    getDataIds: () => state.fetchedData.map(item => item.id),
    getPagingInfo: () => state.pageInfo,
    getSelectedDateRange: () => state.selectedDateRange,
    getCandidatesEnabled: () => state.candidatesEnabled,
    getIfCandidatesSelected: () => {
      if (!state.candidatesEnabled) {
        return false;
      }

      for (const idx of state.selectedData) {
        if (!state.fetchedData[idx].isInfringement) {
          return true;
        }
      }

      return false;
    },
    getRequiredDataForMap: () => {
      const selectedData = state.selectedData;
      const requiredData: any[] = [];
      const selectedDataLength = selectedData && selectedData.length;
      if (selectedData && selectedDataLength) {
        for (let i = selectedDataLength; i--; ) {
          const {
            id,
            operationId,
            ruleId,
            infringementType,
            time,
            position,
            airportId,
          } = state.fetchedData[selectedData[i]];
          requiredData.push({
            id,
            operationId,
            ruleId,
            infringementType,
            time,
            position: typeof position !== 'undefined' && position ? position : null, // inf candidate doesn't have position
            airportId,
          });
        }
      }
      return {
        requiredData,
        infringementTypes: state.infringementTypes,
        selectionLength: state.selectedData.length,
        addedToSelection: state.addedToSelection,
        removedFromSelection: state.removedFromSelection,
        noiseMonitors: state.noiseMonitorData,
      };
    },
    getNoiseMonitors: () => state.noiseMonitorData,
    getNoiseEventIds: () => state.noiseEventIds,
    getNoiseEventDetails: () => state.noiseEventDetails,
    getNavigationData: () => {
      return {
        itemsIds: state.fetchedData.map(item => item.id),
        hasNextPage: state.pageInfo ? state.pageInfo.hasNextPage : false,
        endCursor: state.pageInfo ? state.pageInfo.endCursor : undefined,
        dateRange: state.selectedDateRange,
      };
    },
  }));
};

export const dataInitialState: IDataState = {
  isLoading: true,
  selectedData: [],
  selectedDateRange: null,
  addedToSelection: [],
  removedFromSelection: [],
  fetchedData: [],
  infringementTypes: [],
  isLoadingMore: false,
  pageInfo: undefined,
  totalCount: undefined,
  hasTotalCount: false,
  noiseMonitorData: [],
  noiseEventIds: [],
  noiseEventDetails: [],
  candidatesEnabled: false,
  lastRequestTime: null,
  lastResetTime: null,
};

const setNoiseEventIds = (state, selectedData) => {
  const noiseEvents: number[] = [];
  const selectedDataLength = selectedData && selectedData.length;
  if (selectedData && selectedDataLength) {
    for (let i = selectedDataLength; i--; ) {
      const { detail, infringementType } = state.fetchedData[selectedData[i]];

      if (infringementType === NOISE_INFRINGEMENT && detail && detail.noiseEventIds) {
        noiseEvents.push(...detail.noiseEventIds);
      }
    }
  }

  return noiseEvents;
};

const setLocalSelectionData = (state, data) => {
  const { removedFromSelection, addedToSelection } = setSelectionDelta(data, [
    ...state.selectedData,
  ]);
  const addedToSelectionData =
    state.fetchedData.length &&
    addedToSelection.map(rowId => {
      const addedMapData = getMapDataFromSelection(state.fetchedData)(rowId);
      const addedInfringement = state.fetchedData[rowId];
      return {
        infringementId: addedInfringement.id,
        infringementType: addedInfringement.infringementType,
        operation: addedMapData,
      };
    });
  const removedFromSelectionData =
    state.fetchedData.length &&
    removedFromSelection.map(rowId => {
      const removedMapData = getMapDataFromSelection(state.fetchedData)(rowId);
      const removedInfringement = state.fetchedData[rowId];
      return {
        infringementId: removedInfringement.id,
        infringementType: removedInfringement.infringementType,
        operation: removedMapData,
      };
    });
  state.removedFromSelection = removedFromSelectionData ? removedFromSelectionData : [];
  state.addedToSelection = addedToSelectionData ? addedToSelectionData : [];
};

const areAllRowsSelected = state => {
  if (state.selectedData && state.selectedData.length && state.fetchedData) {
    if (state.fetchedData.length === state.selectedData.length) {
      return true;
    } else {
      return 'indeterminate';
    }
  }
  return false;
};

const addDisplayData = (currentData: IFetchedData[], newData: IFetchedData[]) => {
  const data = new Map();
  for (const operation of currentData) {
    data.set(operation.tableId, operation);
  }
  for (const operation of newData) {
    operation.tableId = uuid.v4();
    data.set(operation.tableId, operation);
  }
  const fetchedData: IFetchedData[] = [];
  const infringementTypes: any = {};
  data.forEach(item => {
    infringementTypes[item.infringementType] = true;
    fetchedData.push(item);
  });

  return {
    fetchedData,
    infringementTypes: Object.keys(infringementTypes),
  };
};

export const dataReducer = (state: IDataState, action: any) => {
  switch (action.type) {
    case dataActionTypes.GET_FIRST_FETCH:
      return Object.assign({}, state, {
        isLoading: true,
        hasTotalCount: false,
      });
    case dataActionTypes.INLINE_EDIT_STATUS: {
      const { id, status, candidatesEnabled } = action.data;
      const dataEdited = state.fetchedData;
      const foundId = candidatesEnabled
        ? dataEdited.findIndex(item => item.infringementId === id)
        : dataEdited.findIndex(item => item.id === id);
      if (foundId !== -1) {
        const currentItem = { ...dataEdited[foundId] };
        currentItem.status = status;
        currentItem.tableId = uuid.v4();

        const edited = Object.assign([...dataEdited], { [foundId]: currentItem });

        return Object.assign({}, state, {
          fetchedData: edited,
        });
      }
    }
    case dataActionTypes.INLINE_EDIT_VECTORED: {
      const { id, vectored } = action.data;
      const dataEdited = state.fetchedData;
      const foundId = dataEdited.findIndex(item => item.operationId === id);

      if (foundId !== -1) {
        const currentItem = { ...dataEdited[foundId] };

        if (vectored === true) {
          currentItem.operationTags = [{ tagId: 11, name: 'Vector', isCompleted: null }];
        } else {
          currentItem.operationTags = null;
        }
        currentItem.tableId = uuid.v4();

        const edited = Object.assign([...dataEdited], { [foundId]: currentItem });

        return Object.assign({}, state, {
          fetchedData: edited,
        });
      }
    }
    case dataActionTypes.TABLE_HEADERS:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case dataActionTypes.BEGIN_NOISE_EVENT_FETCH: {
      return Object.assign({}, state, {
        lastRequestTime: new Date().getTime(),
      });
    }
    case dataActionTypes.NOISE_EVENTS_RECEIVED: {
      const { data } = action.data;
      if (
        state.lastResetTime &&
        state.lastRequestTime &&
        state.lastRequestTime > state.lastResetTime
      ) {
        return Object.assign({}, state, {
          noiseEventDetails: data,
        });
      }

      return state;
    }
    case dataActionTypes.RESET_NOISE_EVENTS: {
      return Object.assign({}, state, {
        noiseEventDetails: [],
        lastResetTime: new Date().getTime(),
      });
    }
    case dataActionTypes.NOISE_MONITORS_RECEIVED: {
      const { data } = action.data;
      return Object.assign({}, state, {
        noiseMonitorData: data,
      });
    }
    case dataActionTypes.INFRINGEMENTS_FETCHED:
      const { data, selectedDateRange, pageInfo } = action.data;
      const { fetchedData, infringementTypes } = addDisplayData(state.fetchedData, data);
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingMore: false,
        selectedDateRange,
        fetchedData,
        infringementTypes,
        pageInfo,
      });
    case dataActionTypes.SET_CANDIDATES_ENABLED:
      return Object.assign({}, state, {
        candidatesEnabled: action.data,
      });
    case dataActionTypes.GET_TOTAL_COUNT:
      return Object.assign({}, state, {
        totalCount: action.data.totalCount,
        hasTotalCount: true,
      });
    case dataActionTypes.LOAD_MORE:
      return Object.assign({}, state, {
        isLoadingMore: true,
      });
    case dataActionTypes.SELECT_ROW:
      setLocalSelectionData(state, action.data);
      const noiseEventIds = setNoiseEventIds(state, action.data);

      return Object.assign({}, state, {
        selectedData: action.data,
        noiseEventIds,
      });
    case appActionTypes.ROUTE_CHANGE:
    case dataActionTypes.RESET_DATA:
      // remove tracks before resetting store
      setLocalSelectionData(state, []);

      return Object.assign({}, state, {
        isLoading: true,
        selectedData: [],
        selectedDateRange: null,
        fetchedData: [],
        addedToSelection: [],
        infringementTypes: [],
        isLoadingMore: false,
        pageInfo: undefined,
        totalCount: undefined,
        hasTotalCount: false,
      });
    default:
      return state;
  }
};
