import sassVars from 'src/styles/vars.module.scss';

// Utils
import { getOperationTheme } from 'src/utils';

/**
 * Function to provide line opacity with interpolation
 *
 * @param filter
 * @param opacity
 */

export const getInterpolatedOpacity = ({
  filter,
  totalCount = -1,
  multiplier = 1,
}: {
  filter?;
  totalCount?: number;
  multiplier?: number;
}) => {
  const bpLow = 500;
  const bpMid = 5000;
  const bpHigh = 50000;

  const opacityDefault = 0.3;
  const opacityHigh = 0.6;
  const opacityMid = 0.2;
  const opacityLow = 0.05;

  // If total count is less than bpLow, or there is no total count, no need to change
  let opacity = opacityDefault;
  if (totalCount < 0 || !totalCount) {
    opacity = opacityDefault;
  } else if (totalCount <= bpLow) {
    opacity = opacityHigh;
  } else if (totalCount <= bpMid) {
    opacity = opacityMid + ((bpMid - totalCount) * (opacityHigh - opacityMid)) / (bpMid - bpLow);
  } else if (totalCount <= bpHigh) {
    opacity = opacityLow + ((bpHigh - totalCount) * (opacityMid - opacityLow)) / (bpHigh - bpMid);
  } else {
    opacity = opacityLow;
  }

  const finalOpacity = opacity * multiplier;
  return filter ? ['case', filter, finalOpacity, 0] : finalOpacity;
};

/*
 * Mapbox styling for when dynamic sources are loaded
 * Better styling ruleset without having to duplicate source layers etc
 */

export const mapboxStyleDynamicSource = (selectedTheme = 'default', operationCount: number) => {
  const lineOpacity = getInterpolatedOpacity({ totalCount: operationCount });
  const currentTheme = getOperationTheme(selectedTheme);
  return {
    'line-color': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      sassVars.brand01,
      ['boolean', ['feature-state', 'hovered'], false],
      sassVars.support03,
      ['==', ['get', 'operationType'], 'Departure'],
      currentTheme.departure,
      ['==', ['get', 'operationType'], 'Arrival'],
      currentTheme.arrival,
      ['==', ['get', 'operationType'], 'Overflight'],
      currentTheme.overflight,
      ['==', ['get', 'operationType'], 'TouchAndGo'],
      currentTheme.touchandgo,
      'hsl(0, 100%, 100%)',
    ],
    'line-opacity': [
      'case',
      [
        'any',
        ['boolean', ['feature-state', 'selected'], false],
        ['boolean', ['feature-state', 'hovered'], false],
      ],
      1,
      lineOpacity,
    ],
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      8,
      [
        'case',
        [
          'any',
          ['boolean', ['feature-state', 'selected'], false],
          ['boolean', ['feature-state', 'hovered'], false],
        ],
        0.75,
        0.5,
      ],
      11,
      [
        'case',
        [
          'any',
          ['boolean', ['feature-state', 'selected'], false],
          ['boolean', ['feature-state', 'hovered'], false],
        ],
        4,
        1,
      ],
      14,
      [
        'case',
        [
          'any',
          ['boolean', ['feature-state', 'selected'], false],
          ['boolean', ['feature-state', 'hovered'], false],
        ],
        6,
        3,
      ],
    ],
  };
};

/*
 * The Mapbox map consists of two layers per source,
 *  - background To show all unselected tracks
 *  - foreground To show the selected track
 *
 *  There are multiple sources on the map dependent on the date range.
 */
// Mapbox Style for background layer in normal mode when nothing is selected
// opacity 0 for overflights

export const mapboxStyleBackgroundNormalPaint = (selectedTheme = 'default') => {
  const currentTheme = getOperationTheme(selectedTheme);
  return {
    'line-color': [
      'case',
      ['==', ['get', 'operationType'], 'Departure'],
      currentTheme.departure,
      ['==', ['get', 'operationType'], 'Arrival'],
      currentTheme.arrival,
      ['==', ['get', 'operationType'], 'Overflight'],
      currentTheme.overflight,
      ['==', ['get', 'operationType'], 'TouchAndGo'],
      currentTheme.touchandgo,
      'hsl(0, 100%, 100%)',
    ],
    'line-opacity': 0.4,
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 11, 1, 14, 3],
  };
};

// Mapbox Style for foreground layer, when something is selected
// highlight selected feature, hide everything else
export const mapboxStyleForegroundPaint = {
  'line-opacity': ['case', ['boolean', ['feature-state', 'selected'], false], 1, 0],
  'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.75, 11, 4, 14, 6],
  'line-color': [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    sassVars.brand01,
    'hsl(0, 100%, 100%)',
  ],
};

export const mapboxStyleInfringementPaint = {
  'line-opacity': ['case', ['boolean', ['feature-state', 'selected'], false], 1, 0],
  'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.75, 11, 4, 14, 6],
  'line-color': [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    sassVars.brandDarkened,
    'hsl(0, 100%, 100%)',
  ],
};

export const mapboxStyleHoverPaint = {
  'line-opacity': [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    0,
    ['case', ['boolean', ['feature-state', 'hovered'], false], 1, 0],
  ],
  'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.75, 11, 4, 14, 6],
  'line-color': [
    'case',
    ['boolean', ['feature-state', 'hovered'], false],
    sassVars.support03,
    'hsl(0, 100%, 100%)',
  ],
};

export const mapboxStyleInfringementSelectPaint = {
  'line-opacity': [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    0,
    ['case', ['boolean', ['feature-state', 'infringement-select'], false], 1, 0],
  ],
  'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.75, 11, 4, 14, 6],
  'line-color': [
    'case',
    ['boolean', ['feature-state', 'infringement-select'], false],
    sassVars.support03,
    'hsl(0, 100%, 100%)',
  ],
};

export const mapboxCorridorStyle = {
  'fill-color': '#47c2c0',
  'fill-opacity': ['case', ['boolean', ['feature-state', 'selected'], false], 0.25, 0],
};

export const mapboxExclusionStyle = {
  'fill-color': '#E644FC',
  'fill-opacity': ['case', ['boolean', ['feature-state', 'selected'], false], 0.25, 0],
};

export const mapboxGateStyle = {
  'line-color': [
    'case',
    ['boolean', ['feature-state', 'selected'], true],
    '#BD1427',
    ['boolean', ['feature-state', 'visible'], true],
    '#2E384D',
    '#2E384D',
  ],
  'line-width': ['interpolate', ['linear'], ['zoom'], 8, 1, 14, 3],
  'line-opacity': [
    'case',
    [
      'any',
      ['boolean', ['feature-state', 'selected'], false],
      ['boolean', ['feature-state', 'visible'], false],
    ],
    1,
    0,
  ],
};

export const mapboxGateLightStyle = {
  'line-color': [
    'case',
    ['boolean', ['feature-state', 'selected'], true],
    '#BD1427',
    ['boolean', ['feature-state', 'visible'], true],
    '#DDD',
    '#DDD',
  ],
  'line-width': ['interpolate', ['linear'], ['zoom'], 8, 1, 14, 3],
  'line-opacity': [
    'case',
    [
      'any',
      ['boolean', ['feature-state', 'selected'], false],
      ['boolean', ['feature-state', 'visible'], false],
    ],
    1,
    0,
  ],
};

export const mapboxGateSymbolsStyle = {
  'circle-color': [
    'case',
    ['boolean', ['feature-state', 'selected'], true],
    '#BD1427',
    ['boolean', ['feature-state', 'visible'], true],
    '#2E384D',
    '#2E384D',
  ],
  'circle-opacity': [
    'case',
    [
      'any',
      ['boolean', ['feature-state', 'selected'], false],
      ['boolean', ['feature-state', 'visible'], false],
    ],
    1,
    0,
  ],
  'circle-radius': ['interpolate', ['linear'], ['zoom'], 9, 0, 10.5, 4, 12, 8],
};

export const mapboxGateSymbolsLightStyle = {
  'circle-color': [
    'case',
    ['boolean', ['feature-state', 'selected'], true],
    '#BD1427',
    ['boolean', ['feature-state', 'visible'], true],
    '#DDD',
    '#DDD',
  ],
  'circle-opacity': [
    'case',
    [
      'any',
      ['boolean', ['feature-state', 'selected'], false],
      ['boolean', ['feature-state', 'visible'], false],
    ],
    1,
    0,
  ],
  'circle-radius': ['interpolate', ['linear'], ['zoom'], 9, 0, 10.5, 4, 12, 8],
};

export const mapboxRunwayStyle = {
  'line-color': '#8796A1',
  'line-opacity': ['case', ['boolean', ['feature-state', 'selected'], false], 0.5, 0],
  'line-width': ['interpolate', ['linear'], ['zoom'], 1, 9, 9, 1],
};

export const mapboxRunwayFillStyle = {
  'fill-color': '#8796A1',
  'fill-opacity': ['case', ['boolean', ['feature-state', 'selected'], false], 0.9, 0],
};

export const mapboxNmtSymbolsStyle = {
  'icon-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 12, 1],
};

export const mapboxNmtSymbolsLayout = {
  visibility: 'none',
  'icon-image': 'noise_monitor',
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
  'icon-size': ['interpolate', ['linear'], ['zoom'], 8, 0.25, 12, 0.5],
};

export const mapboxNmtSymbolsLightLayout = {
  visibility: 'none',
  'icon-image': 'noise_monitor_light',
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
  'icon-size': ['interpolate', ['linear'], ['zoom'], 8, 0.25, 12, 0.5],
};
