import { ApolloClient } from 'apollo-client';
import { fetchInfringementRulesData } from 'src/app/resolvers/infringementRulesResolver';
import { appActionTypes } from 'src/app/newActionTypes';

export const loadInfringementRules = (client: ApolloClient<object>, dispatcher): void => {
  fetchInfringementRulesData(client)
    .then(response => {
      dispatcher({ type: appActionTypes.UPDATE_INFRINGEMENT_RULES, payload: response });
    })
    .catch(error => {
      dispatcher({ type: appActionTypes.INFRINGEMENT_RULES_ERROR, payload: error });
    });
};

export const ignoreInfringementRules = (client: ApolloClient<object>, dispatcher): void => {
  dispatcher({ type: appActionTypes.IGNORE_INFRINGEMENT_RULES });
};
