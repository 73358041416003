import { useEffect } from 'react'
// types
import { IHeapProps } from './interfaces';
// toggles
import { ANALYTICS_TRACKING } from 'src/app/featureToggles';
import { useConfigSelectors } from 'src/app/reducers';

// We need a handle to the global Window, Document, and navigator here,
// so let's declare them and make TS happy.
declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    heap: any | undefined;
  }
  // tslint:disable-next-line: no-empty-interface interface-name
  interface Document {}
}

// These methods may be subject to change if heap.io updates
type IHeapAPIMethods = "addEventProperties"
| "addUserProperties"
| "clearEventProperties"
| "identify"
| "resetIdentity"
| "removeEventProperty"
| "setEventProperties"
| "track"
| "unsetEventProperty";

const DOMReady = !!(
  (typeof window !== 'undefined' &&
  window.document && window.document.createElement)
);

// Returns the heap object for verification
const HeapCodeInjection = (appId: string): void => {
  if (!DOMReady) { return; }

  return ((window: Window, document: Document, appId: string) => {
    window.heap = window.heap||[];
    window.heap.load = (e: string, t: any) => {
      window.heap.appid = e;
      window.heap.config = t = t || {};
      const r = document.createElement("script");
      r.type = "text/javascript";
      r.async = !0;
      r.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`;

      const a = document.getElementsByTagName("script")[0];
      if(a.parentNode) { a.parentNode.insertBefore(r, a) }

      for(
        let n = (e: string) => { 
          return (...arg: any) => { 
            window.heap.push([e].concat(arg));
          }
        },
        // These methods may be subject to change if heap.io updates
        p = [
          "addEventProperties",
          "addUserProperties",
          "clearEventProperties",
          "identify",
          "resetIdentity",
          "removeEventProperty",
          "setEventProperties",
          "track",
          "unsetEventProperty"
        ],
        o = 0; o < p.length; o++
      ) {
        window.heap[p[o]] = n(p[o]);
      }
    }
    window.heap.load(appId);

    return window.heap;
  })(window, document, appId);
}

// Provides access to the heap.io API for use in the app
export const HeapAPI = (method: IHeapAPIMethods, ...args: any): void => {
  if (DOMReady && window.heap) {
    window.heap[method].apply(null, args);
  }
}

export const Heap = ({
  appId,
  authData,
}: IHeapProps) => {
  const configSelectors = useConfigSelectors();
  const hasAnalyticsTrackingFeature = configSelectors.isFeatureAvailable(ANALYTICS_TRACKING);

  useEffect(() => {
    if(
      !window.heap &&
      !window.navigator.doNotTrack
    ) { HeapCodeInjection(appId) }
  }, []);

  if(authData && hasAnalyticsTrackingFeature && window.heap) {
    if(!authData.isAnonymous) {
      window.heap.identify(authData.identifier);
      window.heap.addUserProperties(authData);
    }
  }

  return null;
}
