import { useApolloClient, useMutation } from '@apollo/react-hooks';
import cx from 'classnames';
import { Button, FlightSelector, NoData, Icons } from '@ems/client-design-system';
import { DateTime, Duration } from 'luxon';
import React, { useEffect, useState, FC } from 'react';
import {
  INearestFlight,
  ICorrelatedFlight,
  IContentContainer,
  ISelectFlightComplaint,
  IPosition,
  ICorrelatedFlightProfiles,
} from 'src/@complaints/interfaces';
import {
  fetchSingleComplaint,
  getCorrelatedFlights,
  getCorrelatedFlightProfiles,
} from 'src/@complaints/resolvers';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import {
  getDeployedProductId,
  history,
  rememberSavedFilters,
  timePlusMinusDuration,
  getOperationTheme,
} from 'src/utils';
import { HeaderContainer, MapContainer } from 'src/@complaints/containers/addFlight';
import { goBack } from 'src/app/functions/itemsNavigation';
import { COMPLAINTS } from 'src/constants';
import {
  UPDATE_COMPLAINER_POSITION,
  UPDATE_COMPLAINT_OPEARTION_CORRELATION,
} from 'src/@complaints/mutations';
import { Prompt } from 'react-router-dom';

// Returns true if there is a location object it's not at (0, 0) with null altitude.
const hasComplainerPosition = (location: IPosition | null) => {
  const emptyLocation =
    location && location.altitude === null && location.longitude === 0 && location.longitude === 0;
  return location && !emptyLocation;
};

export const ContentContainer: FC<IContentContainer> = ({ id, fromInquiryDetails = false }) => {
  const configSelectors = useConfigSelectors();
  const {
    complaints: {
      correlation: { timeBufferAroundTime, maxAltitudeRange, maxHorizontalRange },
    },
  } = configSelectors.getConfig();
  const units = configSelectors.getUnits();

  const languageSelectors = useLanguageSelectors();
  const {
    fields: {
      operations: { aircraftType },
    },
    components: {
      headings: { addressNotFound, addressChange },
      hints: {
        tryChangingTimeLoc,
        addFlightPlacePin,
        addFlightChangePin,
        areYouSureYouWantToLeave,
      },
      buttons: { done, removeFlight, cancel, save },
      labels: { pca: pcaLabel },
    },
    screens: {
      complaints: {
        errors: { noDataFound },
      },
    },
  } = languageSelectors.getLanguage();

  const client = useApolloClient();
  const [complaintData, setComplaintData] = useState<ISelectFlightComplaint | null>(null);
  const [nearestFlights, setNearestFlights] = useState<INearestFlight[]>([]);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [hasFinishedLoading, setHasFinishedLoading] = useState<boolean>(false);
  const [canEditAddress, setCanEditAddress] = useState<boolean>(false);
  const [pinPosition, setPinPosition] = useState<IPosition>({ latitude: 0, longitude: 0 });
  const [pinIsSet, setPinIsSet] = useState<boolean>(false);
  // Used to check which copy to show in the save position panel
  const [initialPinSet, setInitialPinSet] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [correlationSaved, setCorrelationSaved] = useState<boolean>(false);
  const [positionSaved, setPositionSaved] = useState<boolean>(false);
  const selectedTrackTheme = configSelectors.getTheme('operations');
  useEffect(() => {
    if (correlationSaved && positionSaved) {
      if (fromInquiryDetails) {
        // go back to complaint details after selecting/correlating flight
        goBack(`${COMPLAINTS}/${id}`, null, []);
      } else {
        goBack(`${COMPLAINTS}`, null, []);
      }
    }
  }, [correlationSaved, positionSaved]);

  useEffect(() => {
    rememberSavedFilters(COMPLAINTS);
  }, []);

  const [updateCorrelation] = useMutation(UPDATE_COMPLAINT_OPEARTION_CORRELATION, {
    onCompleted() {
      setCorrelationSaved(true);
    },
  });

  const [updateComplainerPosition] = useMutation(UPDATE_COMPLAINER_POSITION, {
    onCompleted() {
      setPositionSaved(true);
    },
  });

  // First get the complaints data if it exists, otherwise redirect to 404
  useEffect(() => {
    if (id) {
      fetchSingleComplaint({ client, id })
        .then((data: ISelectFlightComplaint) => setComplaintData(data))
        .catch(error => {
          history.replace(`/${getDeployedProductId()}/404`);
          console.error(error);
        });
    } else {
      history.replace(`/${getDeployedProductId()}/404`);
    }
  }, [id]);

  // Once we have complaint data, we should request for the nearest flights
  useEffect(() => {
    // Reset if it's finished loading each time their position changes
    setHasFinishedLoading(false);

    if (complaintData) {
      // If we already have an operationId value, we should pre-select it
      if (complaintData.operationId) {
        setSelectedId(complaintData.operationId);
        setIsEditing(true);
      }

      if (!hasComplainerPosition(complaintData.complainerPosition)) {
        setCanEditAddress(true);

        if (!pinIsSet) {
          setHasFinishedLoading(true);
          return;
        }
      }

      const position: IPosition = hasComplainerPosition(complaintData.complainerPosition)
        ? complaintData.complainerPosition
        : pinPosition;

      if (pinPosition && pinPosition.altitude) {
        if (position.altitude === null) {
          position.altitude = pinPosition.altitude;
        }
      }

      // default to 30 mins either side of time
      const timeBuffer = timeBufferAroundTime !== undefined ? timeBufferAroundTime : 1800;
      const minTime = timePlusMinusDuration(complaintData.time, { seconds: -timeBuffer });
      const maxTime = timePlusMinusDuration(complaintData.time, { seconds: timeBuffer });

      getCorrelatedFlights({
        client,
        position,
        time: { minTime, maxTime },
        range: { maxHorizontalRange, maxAltitudeRange },
      }).then((data: ICorrelatedFlight[]) => {
        const complaintTime = DateTime.fromISO(complaintData.time, { setZone: true });
        const correlatedIds: number[] = [];
        const formattedData: INearestFlight[] = data.map(flight => {
          const {
            operation,
            time,
            horizontalDistance,
            slantDistance,
            elevationAngle,
            verticalDistance,
          } = flight;
          const {
            id,
            acid,
            operationType,
            aircraftCategory,
            aircraftType,
            runwayName,
            startTime,
            endTime,
            points,
          } = operation;
          const pcaTime = DateTime.fromISO(time, { setZone: true });
          const timeDiff = pcaTime.diff(complaintTime).milliseconds;
          const displayTime = Duration.fromMillis(Math.abs(timeDiff)).toFormat("mm':'ss");
          const operationTypeLower = operation.operationType.toLowerCase();
          const trackTheme = getOperationTheme(selectedTrackTheme);

          correlatedIds.push(id);

          return {
            operationIcon: (
              <Icons
                iconName={`ic-ad-${operationTypeLower}`}
                size={20}
                style={{
                  fill: trackTheme[operationTypeLower],
                  color: trackTheme[operationTypeLower],
                }}
              />
            ),
            id,
            acid,
            operationType,
            aircraftCategory,
            aircraftType,
            runway: runwayName,
            startTime,
            endTime,
            points,
            pca: {
              horizontalDistance,
              time,
              slantDistance,
              elevationAngle,
              verticalDistance,
              displayTime: timeDiff > 0 ? displayTime : `-${displayTime}`,
            },
          };
        });

        getCorrelatedFlightProfiles({ client, correlatedIds }).then(
          (data: ICorrelatedFlightProfiles[]) => {
            data.map(({ id, profile }) => {
              formattedData.find(operation => {
                if (operation.id === id) {
                  operation.profile = profile;
                }
              });
            });
          }
        );

        // Manually sort by PCA time
        const sortedData = formattedData.sort((a, b) => {
          const timeA = DateTime.fromISO(a.pca.time, { setZone: true });
          const timeB = DateTime.fromISO(b.pca.time, { setZone: true });
          const aDiff = Math.abs(timeA.diff(complaintTime).milliseconds);
          const bDiff = Math.abs(timeB.diff(complaintTime).milliseconds);
          return bDiff > aDiff ? -1 : bDiff < aDiff ? 1 : 0;
        });
        setNearestFlights(sortedData);
        setHasFinishedLoading(true);
      });
    }
  }, [complaintData, pinIsSet, pinPosition]);

  const languageData = {
    model: aircraftType,
    pca: pcaLabel,
    removeFlight,
  };

  const onSelectCB = (val: number) => {
    setSelectedId(val);
  };

  const onPinMoveCB = (position: IPosition) => {
    setPinPosition(position);
    setPinIsSet(false);
  };

  const onAltitudeChangeCB = (altitude: number) => {
    setPinPosition({ ...pinPosition, altitude });
  };

  const setPin = () => {
    setPinIsSet(true);
    setInitialPinSet(false);
  };

  const onDoneSelect = () => {
    if (selectedId) {
      updateCorrelation({
        variables: {
          id,
          correlation: {
            operationId: selectedId !== -1 ? selectedId : null,
          },
        },
      });
    } else {
      setCorrelationSaved(true);
    }

    if (canEditAddress && complaintData) {
      updateComplainerPosition({
        variables: {
          complainer: {
            id: complaintData.complainerId,
            address: {
              position: pinPosition,
            },
          },
        },
      });
    } else {
      setPositionSaved(true);
    }
  };

  // Conditions for save being disabled:
  // 1. If you can edit the address, and the pin is not set
  // 2. If no operation has been selected
  const saveDisabled: boolean = (canEditAddress && !pinIsSet) || selectedId === null;

  return (
    <>
      <div className="map_wrapper">
        <MapContainer
          trackData={nearestFlights}
          selectedId={selectedId}
          onSelect={onSelectCB}
          complainerPosition={
            complaintData && hasComplainerPosition(complaintData.complainerPosition)
              ? complaintData.complainerPosition
              : null
          }
          canMovePin={canEditAddress}
          onPinMove={onPinMoveCB}
          onAltitudeChange={onAltitudeChangeCB}
        />
        {!pinIsSet && canEditAddress ? (
          <div className="map-overlay-panel">
            <div className="flight-confirm-panel">
              <div>
                <div className="flight-confirm-panel_title">
                  {initialPinSet ? addressNotFound : addressChange}
                </div>
                <div className="flight-confirm-panel_description">
                  {initialPinSet ? addFlightPlacePin : addFlightChangePin}
                </div>
              </div>
              <Button className="flight-confirm-panel_confirm" style="primary" onClick={setPin}>
                {done}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      <div className={cx('container-fluid', 'container-fluid--details')}>
        <div className="container-fluid--inner">
          <HeaderContainer
            isEditing={isEditing}
            time={complaintData ? complaintData.time : null}
            id={id}
          />
          <div className="layout_single">
            {hasFinishedLoading && nearestFlights.length === 0 ? (
              <NoData text={noDataFound} title={tryChangingTimeLoc} bordered={false} />
            ) : null}
            {hasFinishedLoading && nearestFlights.length > 0 ? (
              <>
                <FlightSelector
                  data={nearestFlights}
                  selectedId={selectedId}
                  onSelect={onSelectCB}
                  languageData={languageData}
                  unitProfile={{
                    distanceUnit: units.distance,
                    distanceVerticalUnit: units.distanceVertical,
                  }}
                  allowRemoval={isEditing}
                />
                <div className="cancel-save-panel">
                  <Button style="subtle" onClick={() => goBack(`${COMPLAINTS}/${id}`, null, [])}>
                    {cancel}
                  </Button>
                  <Button style="primary" disabled={saveDisabled} onClick={onDoneSelect}>
                    {save}
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <Prompt
          message={areYouSureYouWantToLeave}
          when={selectedId !== null && !correlationSaved}
        />
      </div>
    </>
  );
};
