/**
 * We won't use the RouteLoader component but I leave it here for future reference
 * We might want to have an independent page loader to improve user experience in the fututre and this could be reused 
 */
import React from 'react';
import { Route } from 'react-router-dom';
// import NProgress from 'nprogress'; removed nprogress for now
// import 'nprogress/nprogress.css';
import './styles.scss';

interface IRouteProgressProps {
  path?: string;
  exact?: boolean;
  component: any;
}

export class RouteLoader extends React.Component<IRouteProgressProps, {}> {
  componentWillMount() { // componentWillMount is being deprecated now 
    // NProgress.start();
  }

  componentDidMount() {
    // NProgress.done();
  }

  render() {
    return <Route {...this.props} />;
  }
}
