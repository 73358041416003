import React from 'react';
import { useConfigSelectors } from 'src/app/reducers';
// components
import {
  MapPopupMoveable,
  getConvertedLocation,
  getConvertedVerticalDistance,
} from '@ems/client-design-system';

// style
import './styles.scss';

export const ComplaintsPopup = ({
  longitude,
  latitude,
  address,
  elevation,
  altitudeUnits,
  onClose,
  languageData,
  mapApis,
}: any) => {
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  if (!latitude || !longitude || !address) {
    return null;
  }

  const { lat, lng, amsl } = languageData;
  return (
    <MapPopupMoveable
      longitude={longitude}
      latitude={latitude}
      anchorLatitude={latitude}
      anchorLongitude={longitude}
      onClose={onClose}
      draggable={true}
      className="location-popup"
      anchorLine={true}
      offsetLeft={20}
      offsetTop={-140}>
      <div className="location-popup">
        {address && <h3 className="location-popup_title">{address}</h3>}
        <div className="location-popup_row">
          <p className="location-popup_col">
            <span className="location-popup_label">{lat}</span>
            <span className="location-popup_value">
              {getConvertedLocation(latitude, units.location, 5)}
            </span>
          </p>
          <p className="location-popup_col">
            <span className="location-popup_label">{lng}</span>
            <span className="location-popup_value">
              {getConvertedLocation(longitude, units.location, 5)}
            </span>
          </p>
          <p className="location-popup_col">
            <span className="location-popup_label">{amsl}</span>
            <span className="location-popup_value">
              {elevation !== null
                ? getConvertedVerticalDistance(elevation, units.distanceVertical, 0)
                : `—`}
            </span>
          </p>
        </div>
      </div>
    </MapPopupMoveable>
  );
};
