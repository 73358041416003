import React, { FC, useState, useEffect, useRef, useMemo, ReactElement } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { debounce } from 'debounce';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
// common components
import {
  StyledMap,
  MapControl,
  GeocoderPin,
  ComplainerGeocoderPin,
  RulerTool,
} from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
// functions
import { useMapSettings } from 'src/app/functions/mapSettings';
import { useMapRef, useMapWhenReady, useMapProps, useMapConfig } from 'src/app/functions/map';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
import {
  flyTo,
  fitPointsInMap,
  useStaticFlightDisplay,
  uniqueIds,
  useClickOnMapElement,
  useHoverOnMapElement,
  useHoveredPointData,
  useClickedPointData,
  useGeocoderPinAlternative,
  offsetCoordinatesByPixels,
} from 'src/utils';
import { useGeocodePosition } from 'src/utils/geocoding';
import { ComplaintsPopup, MapSelectionArea, InquirerPopup, AMSLPopup } from 'src/components';
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { FLY_TO_DURATION, MAPTYPES, MARKER_OFFSET } from 'src/constants';
import { IMapProps, IPosition } from 'src/@complaints/interfaces';
// ts
import { IGeocodeCandidateDetail } from 'src/app/props';

import { IRulerCoordinateObject } from 'src/utils/interfaces';

// Returns true if there is a location object it's not at (0, 0) with null altitude.
const hasComplainerPosition = (location: IPosition | null) => {
  const emptyLocation =
    location && location.altitude === null && location.longitude === 0 && location.longitude === 0;
  return location && !emptyLocation;
};

export const MapContainer: FC<IMapProps> = ({ complainerData, pointData, markedTimes }) => {
  const client = useApolloClient();
  // get map props from config
  const {
    viewportFromProps,
    mapboxApiAccessToken,
    mapStyle: defaultMapStyle,
    ...mapProps
  } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });

  // used for taking screenshot of map
  const captureRef = useRef(null);
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { altitudeUnits },
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  const units = configSelectors.getUnits();

  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);
  const [selectedPinCoordinates, setSelectedPinCoordinates] = useState<number[][]>([]);
  const [closeSearch, updateCloseSearch] = useState<boolean>(false);
  const [selectionBounds]: any = useState(null);
  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>({
    longitude: 0,
    latitude: 0,
  });
  // get field labels from language selectors
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { operations: opsFields },
    abbreviations: { operations: opsAbbreviation },
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        backToCenter: backToCenterLabel,
        search: searchLabel,
        addPin: addPinLabel,
        removePin: removePinLabel,
        lat: latLabel,
        lng: lngLabel,
        amsl: amslLabel,
      },
    },
  } = languageSelectors.getLanguage();
  const labels = Object.assign(opsFields, opsAbbreviation);

  const { addRemoveCircles } = useCircleRanges(mapApis, mapStyle);

  const goToSelectedAddress = (address: IGeocodeCandidateDetail) => {
    const { formattedAddress, position } = address;
    if (position && typeof position !== 'undefined') {
      if (mapApis !== null) {
        const zoom = 12;
        const { longitude, latitude } = position;
        mapApis.flyTo({
          center: [longitude, latitude],
          zoom,
          duration: FLY_TO_DURATION,
        });
        setTimeout(() => {
          updateGeocoding({ longitude, latitude });
          addRemoveCircles({ longitude, latitude });
          updateLocationAddress(formattedAddress);
          updateLocationTagOpen(true);
          onViewportChange({
            ...viewport,
            zoom,
            maxPitch: 0,
            longitude,
            latitude,
          });
          updateCloseSearch(true);
          setTimeout(() => {
            updateCloseSearch(false);
          });
        }, FLY_TO_DURATION);
      }
    }
  };

  const AddressSearch = useMemo(
    () => (
      <div className="mapboxgl-ctrl-search">
        <AddressSearchContainer source="map" onAddressFound={goToSelectedAddress} />
      </div>
    ),
    [mapApis, addRemoveCircles]
  );

  const addPinToCentre = () => {
    // toggle the feature
    updateLocationAddress(null);
    if (geocoding.longitude) {
      // remove the pin if it's already added to the map
      updateGeocoding({ longitude: 0, latitude: 0 });
      addRemoveCircles(null);
    } else {
      const { longitude, latitude } = viewport;
      updateGeocoding({ longitude, latitude });
      addRemoveCircles({ longitude, latitude });
      updateLocationTagOpen(true);
    }
  };
  // restrict map pan
  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // resets map view
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  const operationIds = useMemo(() => uniqueIds(complainerData.map(data => data.operationId)), [
    complainerData,
  ]);
  useStaticFlightDisplay(
    mapApis,
    pointData,
    false,
    operationIds.length > 0,
    operationIds,
    markedTimes
  );

  const [complainerDetails, setComplainerDetails] = useState<ReactElement[]>([]);
  const [complainerPopups, setComplainerPopups] = useState<ReactElement[]>([]);
  const [openedComplainers, setOpenedComplainers] = useState<number[]>([]);
  const openedComplainerRef = useRef<number[]>(openedComplainers);
  const [hoveredComplainer, setHoveredComplainer] = useState<number | null>(null);

  useEffect(() => {
    openedComplainerRef.current = openedComplainers;
  }, [openedComplainers]);

  useEffect(() => {
    // zoom and animate viewport to show all selected complaints on the map
    if (mapApis) {
      const complainerCoordinates: any = [];
      complainerData.map(complainer => {
        if (complainer !== undefined && hasComplainerPosition(complainer.complainerPosition)) {
          complainerCoordinates.push(complainer.complainerPosition);
        }
      });
      fitPointsInMap(mapApis, viewport, setViewport, complainerCoordinates);
    }
  }, [mapApis, openedComplainers]);

  const onComplainerClick = (id: number) => {
    const ids = openedComplainerRef.current;
    const idx = openedComplainerRef.current.findIndex(e => e === id);
    if (idx === -1) {
      setOpenedComplainers([...ids, id]);
    } else {
      const newComplainers: number[] = [...ids];
      newComplainers.splice(idx, 1);
      setOpenedComplainers(newComplainers);
    }
  };

  const onComplainerHoverEnter = (id: number) => {
    setHoveredComplainer(id);
  };

  const onComplainerHoverExit = () => {
    setHoveredComplainer(null);
  };

  useEffect(() => {
    const pins: ReactElement[] = [];
    const pinCoordinates: number[][] = [];
    const complainerIds: number[] = [];
    setComplainerDetails([]);
    complainerData.map(({ id, complainerId, complainerPosition }) => {
      const idx = complainerIds.findIndex(e => e === complainerId);
      if (idx === -1 && hasComplainerPosition(complainerPosition)) {
        pins.push(
          <ComplainerGeocoderPin
            key={`pin_${id}`}
            latitude={complainerPosition.latitude}
            longitude={complainerPosition.longitude}
            draggable={false}
            onMouseEnter={() => onComplainerHoverEnter(complainerId)}
            onMouseLeave={() => onComplainerHoverExit()}
            onClick={() => {
              onComplainerClick(complainerId);
            }}
          />
        );
      }
      pinCoordinates.push([complainerPosition.longitude, complainerPosition.latitude]);
    });

    // Remove any popups that are no longer selected
    const newOpened: number[] = [];
    openedComplainerRef.current.map(id => {
      if (complainerData.findIndex(e => e.complainerId === id) !== -1) {
        newOpened.push(id);
      }
    });

    setOpenedComplainers(newOpened);
    setTimeout(() => {
      // TODO: short-term solution to improve UX - container's logic needs refactoring
      // add pins after animation is completed
      setComplainerDetails(pins);
      setSelectedPinCoordinates(pinCoordinates);
    }, FLY_TO_DURATION);
  }, [complainerData]);

  useEffect(() => {
    const popups: ReactElement[] = [];

    // Finds and removes a closed popup from map
    const closeInquirerPopup = (popups: any, key: number, id: number) => {
      popups = popups.filter(popup => popup.key !== `inq_popup_${key}`);
      setOpenedComplainers(openedComplainers.filter(item => item !== id));
    };

    openedComplainers.map((id: number) => {
      const data = complainerData.find(e => e.complainerId === id);
      if (data !== undefined && hasComplainerPosition(data.complainerPosition)) {
        popups.push(
          <InquirerPopup
            key={`inq_popup_${data.id}`}
            latitude={data.complainerPosition.latitude}
            longitude={data.complainerPosition.longitude}
            complainerName={data.complainerName}
            complainerDetails={data.complainer}
            complainerContactMethod={data.contactMethod}
            sameComplainerCount={
              complainerData.filter(item => item.complainerId === data.complainerId).length
            }
            elevation={data.complainerPosition.altitude}
            languageData={{
              lat: latLabel,
              lng: lngLabel,
              amsl: amslLabel,
            }}
            mapApis={mapApis}
            onClose={() => {
              closeInquirerPopup(popups, data.id, id);
            }}
            id={data.id}
          />
        );
      }
    });

    // Only open the hover one if it hasn't been clicked open
    if (
      hoveredComplainer !== null &&
      openedComplainers.findIndex(e => e === hoveredComplainer) === -1
    ) {
      const data = complainerData.find(e => e.complainerId === hoveredComplainer);
      if (data !== undefined && data.complainerPosition) {
        popups.push(
          <InquirerPopup
            key={`inq_popup_${data.id}`}
            latitude={data.complainerPosition.latitude}
            longitude={data.complainerPosition.longitude}
            complainerName={data.complainerName}
            complainerDetails={data.complainer}
            sameComplainerCount={
              complainerData.filter(item => item.complainerId === data.complainerId).length
            }
            elevation={data.complainerPosition.altitude}
            languageData={{
              lat: latLabel,
              lng: lngLabel,
              amsl: amslLabel,
            }}
            mapApis={mapApis}
            id={data.id}
          />
        );
      }
    }

    setComplainerPopups(popups);
  }, [openedComplainers, hoveredComplainer]);

  const { latitude, longitude } = geocoding;
  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude,
      latitude,
    },
  });

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);
  useGeocoderPinAlternative({
    mapApis,
    enableMap: enableMapControls,
    coordinates: [[longitude, latitude], ...selectedPinCoordinates],
  });

  const requiredMouseLayers = useMemo(
    () => ['static-points', 'static-lines', 'points', 'lines'],
    []
  );
  const mouseFilters = useMemo(() => ['any', true], []);

  const { hoveredElement, handleHover, setHoveredElement } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: requiredMouseLayers,
    tracksFilter: mouseFilters,
    restrictZoomLevels: false,
    layerPrefix: '',
    radius: 0.5,
    disabled: false,
    radiusGradient: 0.1,
    mapType: MAPTYPES.COMPLAINTDETAILS,
  });

  const { handleClick, clickedElement, setClickedElement } = useClickOnMapElement(
    viewport,
    mapApis,
    requiredMouseLayers,
    mouseFilters,
    false,
    '',
    0.5,
    0.1
  );

  const [hoveredPointData, setHoveredPointData] = useState<any>({
    amsl: null,
    time: null,
    longitude: null,
    latitude: null,
    showPointData: false,
    flightId: null,
  });
  const [clickedPointData, setClickedPointData] = useState<any>({
    amsl: null,
    time: null,
    longitude: null,
    latitude: null,
    showPointData: false,
    flightId: null,
  });

  const closePopup = () => {
    setClickedPointData({
      amsl: null,
      time: null,
      longitude: null,
      latitude: null,
      showPointData: false,
      flightId: null,
    });
  };

  const matchedHoverOperation = useMemo(() => {
    if (hoveredElement) {
      return pointData.find(p => p.id === hoveredElement.properties.id);
    }

    return null;
  }, [hoveredElement]);

  let userHomeLocation = null;
  if (matchedHoverOperation && matchedHoverOperation.id) {
    const hoveredOperationComplainer = complainerData.find(
      cd => cd.operationId === matchedHoverOperation.id
    );
    userHomeLocation = hoveredOperationComplainer.complainerPosition;
  }

  useHoveredPointData({
    mapApis,
    operation: matchedHoverOperation,
    hoveredElement,
    profileHoverTime: null,
    setSelectedPointData: setHoveredPointData,
    isPlaybackMode: false,
    isPlaybackRunning: false,
    userHomeLocation,
    mapProjectionString,
  });

  const matchedClickOperation = useMemo(() => {
    if (clickedElement) {
      return pointData.find(p => p.id === clickedElement.properties.id);
    }

    return null;
  }, [clickedElement]);

  useClickedPointData({
    operation: matchedClickOperation,
    clickedElement,
    setClickedPointData,
    userHomeLocation,
    mapProjectionString,
  });

  const hoveredElementIsSelected = useMemo(() => {
    const isInComplainerData =
      complainerData &&
      hoveredElement &&
      complainerData.findIndex(p => p.operationId === hoveredElement.properties.id) !== -1;

    if (isInComplainerData) {
      return true;
    }

    setHoveredElement(null);
    return false;
  }, [complainerData, hoveredElement]);

  const clickedElementIsSelected = useMemo(() => {
    const isInComplainerData =
      complainerData &&
      clickedElement &&
      complainerData.findIndex(p => p.operationId === clickedElement.properties.id) !== -1;

    if (isInComplainerData) {
      return true;
    }

    setClickedElement(null);
    return false;
  }, [complainerData, clickedElement]);

  // Ruler Tool
  const [isRulerEnabled, setIsRulerEnabled] = useState<boolean>(false);
  const [rulerCoordinates, updateRulerCoordinates] = useState<IRulerCoordinateObject>({
    start: { longitude: 0, latitude: 0 },
    end: { longitude: 0, latitude: 0 },
  });

  const toggleRuler = () => {
    if (isRulerEnabled) {
      setIsRulerEnabled(false);
      mapApis.isRulerEnabled = false;
    } else {
      setIsRulerEnabled(true);
      mapApis.isRulerEnabled = true;

      const { longitude, latitude } = viewport;
      const startMarkerCoordinates = offsetCoordinatesByPixels(
        [longitude, latitude],
        MARKER_OFFSET,
        mapApis
      );

      updateRulerCoordinates({
        start: { longitude: startMarkerCoordinates.lng, latitude: startMarkerCoordinates.lat },
        end: { longitude, latitude },
      });
    }
  };

  const rulerCoordinatesChanged = (rulerPoint: string, [longitude, latitude]: number[]) => {
    if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
      updateRulerCoordinates({
        ...rulerCoordinates,
        [rulerPoint]: { longitude, latitude },
      });
    }
  };

  return (
    <div className="map_wrapper">
      <div ref={captureRef} className="map">
        <StyledMap
          onLoad={() => mapLoaded()}
          viewport={viewport}
          mapStyle={mapStyle}
          onClick={handleClick}
          onHover={debounce(handleHover, 5)}
          onViewportChange={viewport => {
            viewport.maxPitch = 0;
            onViewportChange(viewport);
          }}
          mapboxApiAccessToken={mapboxApiAccessToken}
          {...mapProps}
          ref={mapRef}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }>
          {isLocationTagOpen && (
            <ComplaintsPopup
              latitude={latitude}
              longitude={longitude}
              address={locationAddress || place}
              elevation={elevation}
              altitudeUnits={altitudeUnits}
              languageData={{
                lat: latLabel,
                lng: lngLabel,
                amsl: amslLabel,
              }}
              mapApis={mapApis}
              onClose={() => {
                updateLocationTagOpen(!isLocationTagOpen);
                setHoveredComplainer(null);
              }}
            />
          )}
          <MapSelectionArea selectionBounds={selectionBounds} />

          <GeocoderPin
            latitude={enableMapControls ? latitude : 0}
            longitude={enableMapControls ? longitude : 0}
            draggable
            onClick={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
            onDragStart={() => {
              addRemoveCircles(null);
              updateLocationTagOpen(false);
            }}
            onDragEnd={([longitude, latitude]) => {
              if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
                updateGeocoding({ longitude, latitude });
              }
              updateLocationAddress(null);
              addRemoveCircles({ longitude, latitude });
              setTimeout(() => {
                updateLocationTagOpen(true);
              }, 1);
            }}
          />
          {enableMapControls && complainerDetails.length ? complainerDetails : null}
          {complainerPopups}
          {enableMapControls && (
            <MapControl
              isPinAdded={latitude && longitude ? true : false}
              addPinToCentre={addPinToCentre}
              navigationControl={{
                showCompass: true,
                showHome: true,
                showSearch: true,
                showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
              }}
              rulerControl={{
                isRulerEnabled,
                toggleRuler,
              }}
              translationData={{
                home: backToCenterLabel,
                search: searchLabel,
                addPin: addPinLabel,
                removePin: removePinLabel,
                mapSettings: mapSettingsTitle,
              }}
              resetView={resetView}
              addressSearch={AddressSearch}
              closeSearch={closeSearch}
              mapSettings={{
                update: () => {
                  applyBackground();
                  applyLayers();
                },
                reset: () => {
                  resetBackground();
                  resetLayers();
                },
                content: (
                  <MapSettingsContainer
                    config={{
                      background: mapStyle,
                      layers: layersDisplayed,
                    }}
                    updateEvent={({ selectedBackground, selectedLayers }) => {
                      if (typeof selectedBackground !== 'undefined') {
                        storeSelectedBackground(selectedBackground);
                      }
                      if (typeof selectedLayers !== 'undefined') {
                        storeSelectedLayers(selectedLayers);
                      }
                    }}
                  />
                ),
              }}
            />
          )}
          <MapReferenceLayers
            mapApis={mapApis}
            mapRef={{ current: mapNode }}
            mapStyle={mapStyle}
            layers={layersDisplayed}
          />
          {clickedElement && clickedElementIsSelected && (
            <AMSLPopup
              labels={labels}
              pointData={clickedPointData}
              anchor="bottom-right"
              mapApis={mapApis}
              draggable
              onClose={() => closePopup()}
            />
          )}
          {hoveredElement && hoveredElementIsSelected && (
            <AMSLPopup
              labels={labels}
              pointData={hoveredPointData}
              mapApis={mapApis}
              draggable={false}
            />
          )}
          <RulerTool
            units={units.distance}
            coordinates={rulerCoordinates}
            isRulerEnabled={isRulerEnabled}
            addressCoordinates={geocoding}
            mapProjection={mapProjectionString}
            handleDragEvent={rulerCoordinatesChanged}
            mapApis={mapApis}
          />
        </StyledMap>
      </div>
    </div>
  );
};
