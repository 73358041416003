import { useState, useEffect } from 'react';
import { ApolloClient } from 'apollo-client';
// resolver
import { geocodePosition } from 'src/app/resolvers/addressResolver';
// utils
import { alert } from 'src/utils';

let failedToAccess = false;
const displayErrorAlert = () => {
  if (!failedToAccess) {
    failedToAccess = true;
    alert('failedToGetResult');
    setTimeout(() => {
      failedToAccess = false;
    }, 10000); // display alert every 10 sec if the problem persist
  }
};

const getAddress = (endpoint: string) => {
  return new Promise<string | null>((resolve, reject) => {
    fetch(endpoint)
      .then(response => {
        if (response.status !== 200) {
          reject(null);
        }
        response.json().then(({ features }) => {
          if (typeof features !== 'undefined' && features.length) {
            const { place_name, context } = features[0];
            const country =
              typeof context !== 'undefined' && context.length
                ? context[context.length - 1].text
                : null;
            resolve(place_name.replace(`, ${country}`, '')); // exclude country
          } else {
            resolve(null);
          }
        });
      })
      .catch(() => {
        reject(null);
      });
  });
};

const getElevation = (endpoint: string) => {
  return new Promise<number | null>((resolve, reject) => {
    fetch(endpoint)
      .then(response => {
        if (response.status !== 200) {
          reject(null);
        }
        response.json().then(data => {
          // array of elevation data
          const elevations: number[] =
            data && typeof data.features !== 'undefined' && data.features
              ? data.features.map(({ properties: { ele } }) => ele)
              : [];
          // find and return the largest elevation value
          if (elevations.length) {
            // Since the elevation value which comes back from the "mapbox-terrain-v2" API is mapped to 10 meter height increments.
            // No elevation value lower than 0 is allowed as it will be incorrect/invalid.
            // An elevation value lower than 0 will be set to 0.
            const elevation = Math.max(...elevations);
            resolve(elevation >= 0 ? elevation : 0);
          } else {
            resolve(null);
          }
        });
      })
      .catch(() => {
        reject(null);
      });
  });
};

export const useElevation = (
  mapBoxConfig: any,
  accessToken: string,
  longitude: number,
  latitude: number,
): { elevation: number | null; place: string | null } => {
  const [elevation, updateElevation] = useState<number | null>(null);
  const [place, updatePlace] = useState<string | null>(null);
  const { elevationEndpoint, reverseGeocodingEndpoint } = mapBoxConfig;
  const makeNewRequest = (position: {
    latitude: number,
    longitude: number,
  }) => {
    const { longitude, latitude } = position;
    if (longitude && latitude) {
      updateElevation(null);
      updatePlace(null);
      getElevation(
        `${elevationEndpoint}/${longitude},${latitude}.json?layers=contour&limit=50&access_token=${accessToken}`
      )
        .then((elevation: number | null) => {
          updateElevation(elevation);
        })
        .catch(() => {
          displayErrorAlert();
          updateElevation(null);
        });
      getAddress(
        `${reverseGeocodingEndpoint}/${longitude},${latitude}.json?access_token=${accessToken}`
      )
        .then((place: string | null) => {
          updatePlace(place ? place : '');
        })
        .catch(() => {
          displayErrorAlert();
          updatePlace(null);
        });
    } else {
      updateElevation(null);
      updatePlace(null);
    }
  };

  useEffect(() => {
    makeNewRequest({ latitude, longitude });
  }, [latitude, longitude]);

  return {
    elevation,
    place,
  };
};

export const useGeocodePosition = ({
  client,
  position,
}: {
  client: ApolloClient<object>;
  position: {
    latitude: number,
    longitude: number,
  };
}
): { elevation: number | null; place: string | null } => {
  const { longitude, latitude } = position;
  const [elevation, updateElevation] = useState<number>(0);
  const [place, updatePlace] = useState<string | null>(null);
  const makeNewRequest = ({ longitude, latitude }) => {
    if (longitude && latitude) {
      updatePlace(null);
      updateElevation(0);
      if (client) {
        geocodePosition({ client, position }).then((response) => {
          if (response.length) {
            const [result] = response;
            updateElevation(result.position.altitude);
            updatePlace(result.formattedAddress);
          } else {
            updatePlace(null);
            updateElevation(0);
          }
        })
          .catch(() => {
            updatePlace(null);
            updateElevation(0);
          });
      }
    } else {
      updatePlace(null);
      updateElevation(0);
    }
  };

  useEffect(() => {
    makeNewRequest({ latitude, longitude });
  }, [latitude, longitude]);

  return {
    elevation,
    place,
  };
};
