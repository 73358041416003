import React, { useState } from 'react';
import { Tooltip, InlineDropDown } from '@ems/client-design-system';

export const TooltipDropdown = ({
  text,
  searchItems,
  selectedItem,
  updateSelection,
  hasIcon,
  isIconOnly,
  isNullable,
}: any) => {
  const [displayTooltip, setDisplayTooltip] = useState<boolean>(true);

  const onInteraction: any = (nextState: boolean) => {
    setDisplayTooltip(!nextState);
  };

  return (
    <Tooltip text={displayTooltip ? text : ''}>
      <InlineDropDown
        searchItems={searchItems}
        selectedItem={selectedItem}
        updateSelection={updateSelection}
        hasIcon={hasIcon}
        isIconOnly={isIconOnly}
        isNullable={isNullable}
        onInteractionCB={onInteraction}
      />
    </Tooltip>
  );
};
