import React, { FC, useState } from 'react';
// common components
import { StyledMap } from '@ems/client-design-system';
// functions
import {
  useMapRef,
  useMapWhenReady,
  useMapProps,
  useMapConfig,
  useSingleDateTile,
  useMapCenter,
} from 'src/app/functions/map';
import { useMapLayer, useInfringementMap } from 'src/app/functions/infringementsOnMap';
import { useGeometryRequiredByMap } from 'src/utils';

export const InfringementMapContainer: FC<{
  time: string;
  operationId: string;
  operation: any;
  infringementType: string;
  infTypeId: number;
  infringementId: string;
  position: any;
  extraIds: number[];
}> = ({
  time,
  operationId,
  operation,
  infringementType,
  infTypeId,
  infringementId,
  position,
  extraIds,
}) => {
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);

  // TODO calculate initial value
  const [mapHeight, setMapHeight] = useState(347);

  // get map props from config
  const { viewportFromProps, ...mapProps } = useMapProps('2D');

  // update map zoom limit values
  mapProps.minZoom = 8;
  mapProps.maxZoom = 16;

  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);

  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();

  // restrict map pan
  const onViewportChange = viewport => {
    if (viewport.width && typeof viewport.width === 'number') {
      // set corridor map aspect to ratio 2:1
      const height = viewport.width / 2;
      setMapHeight(height);
    }

    if (
      Math.abs(viewport.latitude - position.latitude) < 0.2 &&
      Math.abs(viewport.longitude - position.longitude) < 0.2
    ) {
      setViewport(viewport);
    }
  };

  useMapCenter(viewport, onViewportChange, position, operationId);

  // getting map style layers
  const layers = useMapLayer({ mapApis, mapBoxConfig, maptype: 'infringements' });
  const { dateString } = useSingleDateTile({ mapApis, mapBoxConfig, layers, time, operationId });

  useGeometryRequiredByMap({
    mapApis,
    mapBoxConfig,
    infringementTypes: typeof infringementType !== 'undefined' ? [infringementType] : [],
  });

  useInfringementMap(
    'inf-summary-corridor',
    mapApis,
    mapBoxConfig,
    dateString,
    operationId,
    operation,
    infTypeId,
    infringementId,
    infringementType,
    true,
    time,
    extraIds
  );

  return (
    <StyledMap
      onLoad={() => mapLoaded()}
      viewport={viewport}
      onViewportChange={viewport => onViewportChange(viewport)}
      {...mapProps}
      height={mapHeight}
      ref={mapRef}
      disableSpinner={true}
      transformRequest={
        mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
      }
    />
  );
};
