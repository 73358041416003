import { ApolloClient } from 'apollo-client';
import { appActionTypes } from 'src/app/newActionTypes';
import { fetchLanguageData } from 'src/app/resolvers/languageResolver';
import { alert } from 'src/utils';

export const loadAppLanguage = (client: ApolloClient<object>, dispatcher, config): void => {
  // get languageTag to get the correct translation
  const languageTag = config ? config.globals.languageTag : 'en';
  fetchLanguageData(client, languageTag)
    .then(response => {
      dispatcher({ type: appActionTypes.UPDATE_LANGUAGE, payload: response });
    })
    .catch(error => {
      console.error(error);
      alert('failedToLoadLanguage');
      dispatcher({ type: appActionTypes.LANGUAGE_ERROR, payload: error });
    });
};
