import React from 'react';
// utils
import { InlineEditDropDown, InlineEditText } from 'src/utils';
// constants
import {
  ASC,
  DESC,
  FUELTYPES_MODULE,
  LOADFACTORS_MODULE,
  MANAGE_CONFIGURATIONS_MODULE,
  AIRCRAFT_MAPPING_MODULE,
  TIME_IN_MODES_MODULE,
  FLEETMIX_MODULE,
  AIRLINE_MAPPING_MODULE,
  AIRCRAT_MODEL_SEPERATOR,
} from 'src/constants';
// libraries
import uuid from 'uuid';
// components
import { Filter } from '@ems/client-design-system';
import { ColumnHeader, DatePickerComponent } from 'src/components';
// actions
import {
  sortTable,
  sortFleetmixTable,
  sortLoadFactorsTable,
  sortTimeinModesTable,
  sortFuelTypesTable,
  sortAirlineMappingTable,
} from 'src/@settings/actions';
// interfaces
import { IFilterItem } from 'src/@operations/props';
import { IEmissionData, TMode } from 'src/@settings/interfaces';
// functions
import { getFormattedScenarioMutationRecord, getFormattedAircraftMappingRecord, getFormattedPhaseDefaultRecord, getFormattedFuelTypesMutationRecord, getFormattedLoadFactorRecord, getFormattedFleetMixMutationRecord } from 'src/@settings/functions';

export const formatHeaders = (
  dispatcher: any,
  sortSelectors: any,
  columns: any,
  moduleName?: string
) => {
  const sortAction = getSortActionName(moduleName);
  const columnHeaders = {};
  const columnHeads: string[] = Object.keys(columns);
  columnHeads.forEach(column => {
    columnHeaders[column] = ColumnHeader({
      sortName: column,
      resultSize: 10,
      isLoading: false,
      dispatcher,
      sortSelectors,
      sortTable: sortAction,
      languageData: {
        title: columns[column],
        abbreviation: null,
        sortBy: column,
      },
      showSortIcon: column === 'status' ? false : true,
      isSettingsTable: true,
      moduleName,
    });
  });

  return Object.assign({}, columns, columnHeaders);
};

const getSortActionName = moduleName => {
  switch (moduleName) {
    case AIRCRAFT_MAPPING_MODULE:
      return sortTable;
    case FLEETMIX_MODULE:
      return sortFleetmixTable;
    case LOADFACTORS_MODULE:
      return sortLoadFactorsTable;
    case TIME_IN_MODES_MODULE:
      return sortTimeinModesTable;
    case FUELTYPES_MODULE:
      return sortFuelTypesTable;
    case AIRLINE_MAPPING_MODULE:
      return sortAirlineMappingTable;
    default:
      return () => {};
  }
};

export const getLtoEmissionMappings = (ltoEmissions: IEmissionData[]) => {
  return ltoEmissions.map(item => {
    return item.aircraftType + AIRCRAT_MODEL_SEPERATOR + item.engine;
  });
};

export const getEnrouteEmissionMappings = (enrouteEmissions: IEmissionData[]) => {
  return enrouteEmissions.map(item => {
    return item.aircraftType + AIRCRAT_MODEL_SEPERATOR + item.engine;
  });
};

export const addNewTableIds = data => {
  return data.map(item => {
    return {
      ...item,
      tableId: uuid.v4(),
    };
  });
};

export const assignDropdown = (
  index,
  aircraftMapping,
  options,
  translationData,
  fieldType,
  labelName,
  updateAircraftMapping
) => {
  return InlineEditDropDown({
    id: index,
    operation: aircraftMapping,
    fieldType,
    position: 'center',
    labelName,
    translationData,
    options,
    onUpdateSelection: updateAircraftMapping,
    canUpdate: true,
  });
};

export const assignDatePicker = (index, value, updateLoadFactor, fieldNameVal, maxDate, minDate) => {
  return (
    <DatePickerComponent
      value={value}
      onChange={(fieldName, value) => {
        const dateChanged = {};
        dateChanged[fieldName] = value;

        updateLoadFactor(index, dateChanged);
      }}
      setFieldValue={null}
      fieldName={fieldNameVal}
      maxDate={maxDate}
      minDate={minDate}
    />
  );
};

export const assignTextInput = (
  index,
  translationData,
  fieldType,
  labelName,
  updateAction,
  validationRegex
) => {
  const {
    components: {
      labels: { entervalue },
      hints: { invalidEntry },
    },
  } = translationData;
  return InlineEditText({
    id: index,
    fieldType,
    position: 'center',
    value: labelName,
    translationData: {
      invalidText: invalidEntry,
      placeholder: entervalue,
    },
    canUpdate: true,
    onUpdateSelection: updateAction,
    validation: { regexStr: validationRegex },
    onCheckValidity: () => {},
  });
};

const getDayWithIndex = day => {
  for (let i = 0; i < day.length; i++) {
    const dayValue = day.charAt(i);
    if (dayValue !== '_') {
      return { index: i, value: dayValue };
    }
  }
  return { index: 0, value: '_' };
};

const formatDaysItem = days => {
  let daysString = '_______';
  days.forEach((day: IFilterItem) => {
    const { index, value } = getDayWithIndex(day.key);
    daysString = daysString.substr(0, index) + value + daysString.substr(index + 1);
  });
  return daysString;
};

export const assignDaysDropDrown = (
  index,
  selectedItems,
  originalItems,
  fieldName,
  updateLoadFactor
) => {
  return (
    <Filter
      key={''}
      categoryName={''}
      filterItems={originalItems}
      selectedItems={selectedItems}
      updateItems={(items: IFilterItem[]) => {
        const itemsChanged = {};
        itemsChanged[fieldName] = formatDaysItem(items);
        updateLoadFactor(index, itemsChanged);
      }}
      languageData={{ clearValue: '', filterValue: '', noMatchesFound: '' }}
      iconCategories={[]}
      isCategoryNameRequired={false}
      showClear={false}
    />
  );
};

export const getSortData = (data, sortObject) => {
  if (sortObject) {
    const { field, direction } = sortObject;
    let sortedData = data.sort((item1, item2) => {
      if (
        field === 'ltoModel' ||
        field === 'aircraft' ||
        field === 'enrouteModel' ||
        field === 'engine' ||
        field === 'airline' ||
        field === 'origin' ||
        field === 'destination' ||
        field === 'aircraftEngine' ||
        field === 'airport' ||
        field === 'comment' ||
        field === 'mappedLTOAircraft' ||
        field === 'mappedEnrouteAircraft'
      ) {
        switch (field) {
          case 'ltoModel':
            return ('' + item1.ltoModel).localeCompare(item2.ltoModel);
          case 'aircraft':
            return ('' + item1.aircraft).localeCompare(item2.aircraft);
          case 'enrouteModel':
            return ('' + item1.enrouteModel).localeCompare(item2.enrouteModel);
          case 'engine':
            return ('' + item1.engine).localeCompare(item2.engine);
          case 'airline':
            return ('' + item1.airline).localeCompare(item2.airline);
          case 'origin':
            return ('' + item1.origin).localeCompare(item2.origin);
          case 'destination':
            return ('' + item1.destination).localeCompare(item2.destination);
          case 'aircraftEngine':
            return ('' + item1.aircraftEngine).localeCompare(item2.aircraftEngine);
          case 'airport':
            return ('' + item1.airport).localeCompare(item2.airport);
          case 'comment':
            return ('' + item1.comment).localeCompare(item2.comment);
          case 'mappedLTOAircraft':
            return ('' + item1.mappedLTOAircraft).localeCompare(item2.mappedLTOAircraft);
          case 'mappedEnrouteAircraft':
            return ('' + item1.mappedEnrouteAircraft).localeCompare(item2.mappedEnrouteAircraft);
        }
      } else if (field === 'toDatetime' || field === 'fromDatetime') {
        const date1 = new Date(item1[field]);
        const date2 = new Date(item2[field]);
        if (direction === ASC) {
          return date1.getTime() - date2.getTime();
        } else if (direction === DESC) {
          return date2.getTime() - date1.getTime();
        }
      } else if (field === 'runway') {
        if (direction === ASC) {
          return (
            parseFloat(item1[field].replace('L', '')) - parseFloat(item2[field].replace('L', ''))
          );
        } else if (direction === DESC) {
          return (
            parseFloat(item2[field].replace('L', '')) - parseFloat(item1[field].replace('L', ''))
          );
        }
      } else {
        if (direction === ASC) {
          return parseFloat(item1[field]) - parseFloat(item2[field]);
        } else if (direction === DESC) {
          return parseFloat(item2[field]) - parseFloat(item1[field]);
        }
      }
    });
    if (
      (field === 'ltoModel' ||
        field === 'aircraft' ||
        field === 'enrouteModel' ||
        field === 'engine' ||
        field === 'airline' ||
        field === 'origin' ||
        field === 'destination' ||
        field === 'aircraftEngine' ||
        field === 'comment' ||
        field === 'mappedLTOAircraft' ||
        field === 'mappedEnrouteAircraft' ||
        field === 'airport') &&
      direction === DESC
    ) {
      sortedData = sortedData.reverse();
    }
    return addNewTableIds(sortedData);
  } else {
    return data;
  }
};

export const formatFilterItems = filterItems => {
  return filterItems && filterItems.length
    ? filterItems.map(item => {
        const filterItem: IFilterItem = {
          key: item.shortcut,
          label: item.name,
          icon: '',
          className: '',
        };
        return filterItem;
      })
    : [];
};

export const getFormattedMutationItem = (mode: TMode, updatingItem, mutationData, module) => {
  const existingItem = mutationData.get(updatingItem.id);
  let record;
  if (existingItem && existingItem.mode === 'Insert' && mode === 'Update') {
    mode = 'Insert';
  }
  if (existingItem && existingItem.mode === 'Duplicate' && mode === 'Update') {
    mode = 'Duplicate';
  }
  if (existingItem && existingItem.mode === 'Insert' && mode === 'Delete') {
    mutationData.delete(updatingItem.id);
  } else {
    switch (module) {
      case FLEETMIX_MODULE:
      record = getFormattedFleetMixMutationRecord(updatingItem);
      break;
      case FUELTYPES_MODULE:
        record = getFormattedFuelTypesMutationRecord(updatingItem);
        break;
      case LOADFACTORS_MODULE:
        record = getFormattedLoadFactorRecord(updatingItem);
        break;
      case AIRCRAFT_MAPPING_MODULE:
        record = getFormattedAircraftMappingRecord(updatingItem);
        break;
      case TIME_IN_MODES_MODULE:
        record = getFormattedPhaseDefaultRecord(updatingItem);
        break;
      case MANAGE_CONFIGURATIONS_MODULE:
        record = getFormattedScenarioMutationRecord(updatingItem);
    }
    mutationData.set(updatingItem.id, { mode, record });
  }
  return mutationData;
};

export const getColumnTypes = columnValues => {
  const columnTypeValues = {};
  columnValues.forEach(columnValue => {
    columnTypeValues[columnValue] = {
      title: columnValue,
      abbreviation: '',
    };
  });
  return columnTypeValues;
};