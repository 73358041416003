import { keyToActionTypes } from 'src/utils/keyToActionTypes';
// constants
import {
  CORRIDOR_INFRINGEMENT,
  EXCLUSION_INFRINGEMENT,
  GATE_INFRINGEMENT,
  MONITOR_LOCATIONS,
  RUNWAYS,
} from 'src/constants';

export const appActionTypes: { [key: string]: string } = keyToActionTypes(
  {
    UPDATE_CONFIG: null,
    UPDATE_USER_CONFIG: null,
    UPDATE_OPERATION_THEME: null,
    UPDATE_DISTANCE_UNITS: null,
    CONFIG_ERROR: null,
    UPDATE_LANGUAGE: null,
    LANGUAGE_ERROR: null,
    UPDATE_ROLES: null,
    ROLES_ERROR: null,
    UPDATE_FILTERS: null,
    FILTERS_ERROR: null,
    // Infringment Rules
    UPDATE_INFRINGEMENT_RULES: null,
    INFRINGEMENT_RULES_ERROR: null,
    IGNORE_INFRINGEMENT_RULES: null,
  },
  'APP'
);

// for geometry global store
export const geometryActionTypes: { [key: string]: string } = keyToActionTypes(
  {
    [CORRIDOR_INFRINGEMENT]: null,
    [EXCLUSION_INFRINGEMENT]: null,
    [GATE_INFRINGEMENT]: null,
    [MONITOR_LOCATIONS]: null,
    [RUNWAYS]: null,
    GIS_LAYERS: null,
    UPDATE_KML: null,
    GEOMETRY_LOADING: null,
    GEOMETRY_ERROR: null,
  },
  'APP'
);

// for geometry global store
export const exportActionTypes: { [key: string]: string } = keyToActionTypes(
  {
    CAPTURE_IMAGE: null,
    IMAGE_CAPTURED: null,
    UPDATE_EXPORT_IN_PROGRESS: null,
    UPDATE_EXPORT_COMPLETE: null,
    EXPORT_ERROR: null,
  },
  'APP'
);
