import { useContext } from 'react';
// interfaces
import { ISortSelectors, ISortState, ISortField } from '../interfaces';
// helpers
import { useSelectors } from 'src/utils/storeHelpers';
// provider
import { SettingsStateContext } from '../provider/SettingsStateProvider';
// actionTypes
import { actionTypes } from 'src/@settings/actionTypes';
// constants
import {
  AIRCRAFT_MAPPING_MODULE,
  FLEETMIX_MODULE,
  LOADFACTORS_MODULE,
  TIME_IN_MODES_MODULE,
  FUELTYPES_MODULE,
  AIRLINE_MAPPING_MODULE,
} from 'src/constants';

export const useSortSelectors: () => ISortSelectors = () => {
  const state: any = useContext(SettingsStateContext);
  const dataState: ISortState = state.sort;

  return useSelectors(dataState, (state: ISortState) => ({
    getIconProps: (sortName, moduleName) => {
      return findSortObject(state, sortName, moduleName);
    },
    getSortString: moduleName => {
      const sortObject = getSortObjectByModuleName(state, moduleName);
      const sortString = `{field:"${sortObject.field}", direction:${sortObject.direction}}`;
      return `sort: [${sortString}]`;
    },
    getSortObject: moduleName => {
      return getSortObjectByModuleName(state, moduleName);
    },
  }));
};

const initAircraftMappingsSortField: ISortField = {
  field: 'numberOfFlights',
  direction: 'DESC',
};
const initLoadFactorsSortField: ISortField = {
  field: 'airline',
  direction: 'ASC',
};
const initTimeinModesSortField: ISortField = {
  field: 'runway',
  direction: 'ASC',
};
const initAirlineMappingSortField: ISortField = {
  field: 'numberOfAircraft',
  direction: 'DESC',
};

export const sortInitialState: ISortState = {
  aircraftMappingsSortObject: initAircraftMappingsSortField,
  fleetMixSortObject: initAircraftMappingsSortField,
  loadFactorsSortObject: initLoadFactorsSortField,
  timeinModesSortObject: initTimeinModesSortField,
  fuelTypesSortObject: initLoadFactorsSortField,
  airlineMappingSortObject: initAirlineMappingSortField,
};

const findSortObject = (state, sortName, moduleName) => {
  const sortObject = getSortObjectByModuleName(state, moduleName);
  return sortObject && sortObject.field === sortName ? sortObject : false;
};

const getSortObjectByModuleName = (state, moduleName) => {
  switch (moduleName) {
    case AIRCRAFT_MAPPING_MODULE:
      return state.aircraftMappingsSortObject;
    case FLEETMIX_MODULE:
      return state.fleetMixSortObject;
    case LOADFACTORS_MODULE:
      return state.loadFactorsSortObject;
    case TIME_IN_MODES_MODULE:
      return state.timeinModesSortObject;
    case FUELTYPES_MODULE:
      return state.fuelTypesSortObject;
    case AIRLINE_MAPPING_MODULE:
      return state.airlineMappingSortObject;
    default:
      return;
  }
};

export const sortReducer = (state: ISortState, action: any) => {
  switch (action.type) {
    case actionTypes.SORT_TABLE: {
      const sortName = action.data;
      const sortObject = findSortObject(state, sortName, AIRCRAFT_MAPPING_MODULE);
      if (sortObject) {
        const newSortObject: ISortField = state.aircraftMappingsSortObject;
        newSortObject.direction =
          state.aircraftMappingsSortObject.direction === 'ASC' ? 'DESC' : 'ASC';
        return Object.assign({}, state, {
          aircraftMappingsSortObject: newSortObject,
        });
      } else {
        const newSortObject = { field: action.data, direction: 'ASC' };
        return Object.assign({}, state, {
          aircraftMappingsSortObject: newSortObject,
        });
      }
    }

    case actionTypes.SORT_FLEET_MIX_TABLE: {
      const sortName = action.data;
      const sortObject = findSortObject(state, sortName, FLEETMIX_MODULE);
      if (sortObject) {
        const newSortObject: ISortField = state.fleetMixSortObject;
        newSortObject.direction = state.fleetMixSortObject.direction === 'ASC' ? 'DESC' : 'ASC';
        return Object.assign({}, state, {
          fleetMixSortObject: newSortObject,
        });
      } else {
        const newSortObject = { field: action.data, direction: 'ASC' };
        return Object.assign({}, state, {
          fleetMixSortObject: newSortObject,
        });
      }
    }

    case actionTypes.SORT_LOAD_FACTORS_TABLE: {
      const sortName = action.data;
      const sortObject = findSortObject(state, sortName, LOADFACTORS_MODULE);
      if (sortObject) {
        const newSortObject: ISortField = state.loadFactorsSortObject;
        newSortObject.direction = state.loadFactorsSortObject.direction === 'ASC' ? 'DESC' : 'ASC';
        return Object.assign({}, state, {
          loadFactorsSortObject: newSortObject,
        });
      } else {
        const newSortObject = { field: action.data, direction: 'ASC' };
        return Object.assign({}, state, {
          loadFactorsSortObject: newSortObject,
        });
      }
    }

    case actionTypes.SORT_TIME_IN_MODES_TABLE: {
      const sortName = action.data;
      const sortObject = findSortObject(state, sortName, TIME_IN_MODES_MODULE);
      if (sortObject) {
        const newSortObject: ISortField = state.timeinModesSortObject;
        newSortObject.direction = state.timeinModesSortObject.direction === 'ASC' ? 'DESC' : 'ASC';
        return Object.assign({}, state, {
          timeinModesSortObject: newSortObject,
        });
      } else {
        const newSortObject = { field: action.data, direction: 'ASC' };
        return Object.assign({}, state, {
          timeinModesSortObject: newSortObject,
        });
      }
    }

    case actionTypes.SORT_FUEL_TYPES_TABLE: {
      const sortName = action.data;
      const sortObject = findSortObject(state, sortName, FUELTYPES_MODULE);
      if (sortObject) {
        const newSortObject: ISortField = state.fuelTypesSortObject;
        newSortObject.direction = state.fuelTypesSortObject.direction === 'ASC' ? 'DESC' : 'ASC';
        return Object.assign({}, state, {
          fuelTypesSortObject: newSortObject,
        });
      } else {
        const newSortObject = { field: action.data, direction: 'ASC' };
        return Object.assign({}, state, {
          fuelTypesSortObject: newSortObject,
        });
      }
    }

    case actionTypes.SORT_AIRLINE_MAPPING_TABLE: {
      const sortName = action.data;
      const sortObject = findSortObject(state, sortName, AIRLINE_MAPPING_MODULE);
      if (sortObject) {
        const newSortObject: ISortField = state.airlineMappingSortObject;
        newSortObject.direction = state.airlineMappingSortObject.direction === 'ASC' ? 'DESC' : 'ASC';
        return Object.assign({}, state, {
          airlineMappingSortObject: newSortObject,
        });
      } else {
        const newSortObject = { field: action.data, direction: 'ASC' };
        return Object.assign({}, state, {
          airlineMappingSortObject: newSortObject,
        });
      }
    }

    default:
      return state;
  }
};
