import React, { FC, useEffect, useContext } from 'react';
import { MapContainer } from 'src/@infringements/containers/MapContainer';
import { InfringementDispatchContext } from 'src/@infringements/providers/InfringementsStateProvider';
import { useDataSelectors } from 'src/@infringements/reducers';
import { fetchNoiseEventData, resetNoiseEventData } from 'src/@infringements/actions';
import { useApolloClient } from '@apollo/react-hooks';

export const MapInfoContainer: FC = () => {
  const client = useApolloClient();
  const dispatcher = useContext(InfringementDispatchContext);
  const dataSelectors = useDataSelectors();
  const noiseEventIds = dataSelectors.getNoiseEventIds();
  const noiseMonitors = dataSelectors.getNoiseMonitors();
  const noiseEventDetails = dataSelectors.getNoiseEventDetails();

  useEffect(() => {
    if (noiseEventIds.length > 0) {
      fetchNoiseEventData(client, dispatcher, noiseEventIds);
    } else {
      resetNoiseEventData(dispatcher);
    }
  }, [noiseEventIds]);

  return <MapContainer noiseMonitors={noiseMonitors} noiseData={noiseEventDetails} />;
};
