// utils
import { getDeployedProductId } from 'src/utils';
// constants
import {
  FILTER,
  OPERATION,
  INFRINGEMENT,
  NOISEEVENT,
  FILTER_RANGE_SELECTION,
  FILTER_ROW_SELECTION,
  FILTER_TIME_SELECTION,
  FILTER_CORRELATED_IDS,
  FILTER_COMPLAINER,
} from 'src/constants';

const validDuration = 1000 * 60 * 60 * 3; // 3 hours

const readFiltersSet = (key: string, validation: any): any => {
  let data: any;
  const filters = localStorage.getItem(key);
  try {
    if (filters) {
      const temp = JSON.parse(filters);
      const now = new Date().getTime();
      const { time } = temp;
      if (typeof time !== 'undefined' && now - time <= validDuration) {
        // extra check to make sure saved filters are not out of date
        // filters saved are within the allowed time duration
        delete temp.time;
        if (validation === Object.keys(temp).join()) {
          data = temp;
        }
      }
    }
  } catch {
    localStorage.setItem(key, JSON.stringify({}));
    console.error('Failed to read stored filters.');
  }
  return data ? data : {};
};

export const readFiltersRow = (source: string, validation: any): any => {
  const apply = localStorage.getItem(`${getDeployedProductId()}.${source}.${FILTER}`);
  return apply
    ? readFiltersSet(`${getDeployedProductId()}.${source}.${FILTER_ROW_SELECTION}`, validation)
    : null;
};

export const readTimeFilters = (source: string, validation: any): any => {
  const apply = localStorage.getItem(`${getDeployedProductId()}.${source}.${FILTER}`);
  return apply
    ? readFiltersSet(`${getDeployedProductId()}.${source}.${FILTER_TIME_SELECTION}`, validation)
    : null;
};

export const storeFiltersRow = (source: string, filters: any): void => {
  const selection = {};
  Object.entries(filters).forEach(([key, value]: any) => {
    const { selectedItems } = value;
    selection[key] = selectedItems.map(({ key }) => key);
  });
  selection[`time`] = new Date().getTime();
  localStorage.setItem(
    `${getDeployedProductId()}.${source}.${FILTER_ROW_SELECTION}`,
    JSON.stringify(selection)
  );
};

export const storeCorrelatedIds = (source: string, ids: number[]): void => {
  localStorage.setItem(
    `${getDeployedProductId()}.${source}.${FILTER_CORRELATED_IDS}`,
    JSON.stringify(ids)
  );
};

export const readCorrelatedIds = (source: string): any => {
  const apply = localStorage.getItem(`${getDeployedProductId()}.${source}.${FILTER}`);
  return apply
    ? localStorage.getItem(`${getDeployedProductId()}.${source}.${FILTER_CORRELATED_IDS}`)
    : null;
};

export const storeFilterComplainer = (source: string, complainer: object): void => {
  localStorage.setItem(
    `${getDeployedProductId()}.${source}.${FILTER_COMPLAINER}`,
    JSON.stringify(complainer)
  );
};

export const readFilterComplainer = (source: string): any => {
  const apply = localStorage.getItem(`${getDeployedProductId()}.${source}.${FILTER}`);
  return apply
    ? localStorage.getItem(`${getDeployedProductId()}.${source}.${FILTER_COMPLAINER}`)
    : null;
};

export const storeFilterReducerRow = (source: string, filters: any): void => {
  localStorage.setItem(
    `${getDeployedProductId()}.${source}.${FILTER_ROW_SELECTION}`,
    JSON.stringify(filters)
  );
};

export const clearFilterReducerRow = (source: string): void => {
  localStorage.removeItem(`${getDeployedProductId()}.${source}.${FILTER_ROW_SELECTION}`);
};

export const storeFilterReducerRangeItem = (source: string, rangeType: string, value: object) => {
  localStorage.setItem(
    `${getDeployedProductId()}.${source}.${FILTER_RANGE_SELECTION}.${rangeType}`,
    JSON.stringify(value)
  );
};

export const clearFilterReducerRangeItem = (source: string, rangeType: string) => {
  localStorage.removeItem(
    `${getDeployedProductId()}.${source}.${FILTER_RANGE_SELECTION}.${rangeType}`
  );
};

export const readFilterReducerRow = (source: string): any => {
  const apply = localStorage.getItem(`${getDeployedProductId()}.${source}.${FILTER}`);
  return apply
    ? localStorage.getItem(`${getDeployedProductId()}.${source}.${FILTER_ROW_SELECTION}`)
    : null;
};

export const readFilterReducerRangeItem = (source: string, rangeType: string): any => {
  const apply = localStorage.getItem(`${getDeployedProductId()}.${source}.${FILTER}`);
  return apply
    ? localStorage.getItem(
        `${getDeployedProductId()}.${source}.${FILTER_RANGE_SELECTION}.${rangeType}`
      )
    : null;
};

export const storeTimeFilters = (source: string, filters: any): void => {
  const selection = Object.assign(filters, { time: new Date().getTime() });
  localStorage.setItem(
    `${getDeployedProductId()}.${source}.${FILTER_TIME_SELECTION}`,
    JSON.stringify(selection)
  );
};

export const rememberSavedFilters = (source: string): void => {
  localStorage.setItem(`${getDeployedProductId()}.${source}.${FILTER}`, 'true');
};

export const ignoreSavedFilters = (source?: string): void => {
  setTimeout(() => {
    if (source !== undefined) {
      localStorage.removeItem(`${getDeployedProductId()}.${source}.${FILTER}`);
    } else {
      [OPERATION, INFRINGEMENT, NOISEEVENT].forEach(source => {
        localStorage.removeItem(`${getDeployedProductId()}.${source}.${FILTER}`);
      });
    }
  }, 1000);
};

export const removeAvailableFilters = (): void => {
  [OPERATION, INFRINGEMENT, NOISEEVENT].forEach(source => {
    localStorage.removeItem(`${getDeployedProductId()}.${source}.${FILTER}`);
    localStorage.removeItem(`${getDeployedProductId()}.${source}.${FILTER_ROW_SELECTION}`);
    localStorage.removeItem(`${getDeployedProductId()}.${source}.${FILTER_TIME_SELECTION}`);
  });
};
