import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SplitLayout } from 'src/components/Layout';
import {
  FilterContainer,
  MapInfoContainer,
  HeaderContainer,
  TableContainer,
  DataContainer,
} from 'src/@infringements/containers';
import { InfringementsStateProvider } from 'src/@infringements/providers/InfringementsStateProvider';
import { useConfigSelectors } from 'src/app/reducers/configReducer';

const InfringementsScreen = ({ location, updateUrl }) => {
  const configSelectors = useConfigSelectors();
  const {
    infringements: {
      filter: { availableCategoryList },
    },
  } = configSelectors.getConfig();

  const locationState = location.state;
  const fromCorrelated =
    locationState && locationState.hasOwnProperty('fromCorrelated')
      ? locationState.fromCorrelated
      : false;
  const correlatedIds = fromCorrelated ? location.state.ids : [];

  return (
    <SplitLayout>
      <InfringementsStateProvider>
        <DataContainer correlatedIds={correlatedIds}>
          <MapInfoContainer />
          <div className="container-fluid">
            <div className="header-container">
              <HeaderContainer updateUrl={updateUrl} fromCorrelated={fromCorrelated} />
            </div>
            <FilterContainer availableCategoryList={availableCategoryList} />
            <TableContainer fromCorrelated={fromCorrelated} />
          </div>
        </DataContainer>
      </InfringementsStateProvider>
    </SplitLayout>
  );
};

export const Infringements = withRouteProps(InfringementsScreen);
