import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import cx from 'classnames';
// components
import { NavBar } from 'src/components/Navbar';
// reducers
import { useConfigSelectors } from 'src/app/reducers';
// functions
import { useWindowDimensions } from 'src/utils';

import { displayToast } from '@ems/client-design-system';

const SplitLayoutScreen = props => {
  const breakpoint = 1280;
  const configSelectors = useConfigSelectors();
  const isPortrait = configSelectors.getIfPortraitLayout();
  const { width } = useWindowDimensions();

  const [privacyAlertChecked, setPrivacyAlertChecked] = useState<boolean>(
    window.localStorage.getItem('privacyAlertChecked') === 'true'
  );

  const privacyMsg = (
    <p>
      You are accessing this platform as your employer has purchased this product from Envirosuite
      to assist with environmental monitoring. We have a legitimate business reason for collecting
      data regarding how our business customers use our platforms, so we can continue to develop and
      enhance our products however, we would like to explain how we, and selected partners, will
      collect certain information about users use of our platforms. This collection may include
      things like browser version, IP address, location at a suburb/city level, URL, company name
      and ‘clickstream’ of interactions on the product. This information is solely collected for the
      purpose of analysing how users are using our platforms in order to improve our products and
      services. More information about the collection and use of data from our platforms can be
      gained via our privacy policy located at:{' '}
      <a href="https://envirosuite.com/privacy">https://envirosuite.com/privacy</a>
    </p>
  );

  useEffect(() => {
    if (!privacyAlertChecked) {
      displayToast({
        timeout: 0,
        intent: 'none',
        message: privacyMsg,
        size: 'large',
        onDismiss: didTimeoutExpire => {
          if (!didTimeoutExpire) {
            window.localStorage.setItem('privacyAlertChecked', 'true');
            setPrivacyAlertChecked(true);
          }
        },
      });
    }
  }, [privacyAlertChecked]);

  return (
    <>
      <NavBar>
        <div
          className={cx('page-wrapper', {
            'page-wrapper--portrait': isPortrait,
            'page-wrapper--landscape': !isPortrait,
            'table--slim': !isPortrait || width < breakpoint,
          })}>
          {props.children}
        </div>
      </NavBar>
    </>
  );
};

export const SplitLayout = withRouter(SplitLayoutScreen);
