import { dispatcher } from 'src/utils/dispatcher';
import { debounce } from 'debounce';
// ts
import { ApolloClient } from 'apollo-client';
import { ITableFilterItem } from 'src/@infringements/interfaces';
// actions
import {
  updateSelectedItemsAction,
  clearSelectedItemsAction,
  updateTimeFilterValueAction,
  updateTimeFilterInputAction,
  initialiseFilterAction,
} from 'src/@infringements/actions/actions';
import { resetAndFetchData } from 'src/@infringements/actions/infringementsActions';
import { dataActionTypes } from 'src/@infringements/newActionTypes';

const applyFiltersChanged = debounce(
  (
    client: ApolloClient<object>,
    dispatcher,
    options: {
      resultSize: number;
      sortString: string;
      showCandidates?: boolean;
      atcView?: boolean;
    }
  ): void => {
    resetAndFetchData(client, dispatcher, options);
  },
  500
);

export const updateSelectedItems = (
  client: ApolloClient<object>,
  category: string,
  selectedItems: ITableFilterItem[],
  newDispatcher: any,
  sortString: any,
  resultSize: number,
  showCandidates?: boolean,
  atcView?: boolean
): void => {
  if (category === 'ruleNames' && selectedItems.length === 0) {
    newDispatcher({ type: dataActionTypes.SET_CANDIDATES_ENABLED, data: false });
  }
  dispatcher.handleDispatch(updateSelectedItemsAction(category, selectedItems));
  applyFiltersChanged(client, newDispatcher, {
    resultSize,
    sortString,
    showCandidates,
    atcView,
  });
};

export const clearSelectedItems = (
  client: ApolloClient<object>,
  resultSize: number,
  newDispatcher: any,
  sortString: any,
  showCandidates?: boolean,
  atcView?: boolean
): void => {
  dispatcher.handleDispatch(clearSelectedItemsAction());
  resetAndFetchData(client, newDispatcher, {
    resultSize,
    sortString,
    showCandidates,
    atcView,
  });
};

export const updateTimeFilterInput = (time: string, fromOrTo: 'from' | 'to') => {
  dispatcher.handleDispatch(
    updateTimeFilterInputAction({
      time,
      fromOrTo,
    })
  );
};

export const updateTimeFilterValue = (time: string, fromOrTo: 'from' | 'to') => {
  dispatcher.handleDispatch(
    updateTimeFilterValueAction({
      time,
      fromOrTo,
    })
  );
};

export const initialiseFilterStore = ({ infringementFilterData, correlatedIds }) => {
  dispatcher.handleDispatch(initialiseFilterAction(infringementFilterData, correlatedIds));
};
