import { actionTypes } from 'src/@settings/actionTypes';
import { fetchAircraftFlights, fetchAircraftMappings, fetchAirports, fetchEnrouteMappings, fetchLTOMappings } from 'src/@settings/resolvers';
import ApolloClient from 'apollo-client';

export const getAircraftMappings = (client: ApolloClient<object>, dispatcher: any, scenarioIds: number[]) => {
  dispatcher({ type: actionTypes.AIRCRAFT_MAPPINGS_LOADING });
  fetchAircraftMappings(client, scenarioIds)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_AIRCRAFT_MAPPINGS, aircraftMappingsData: data });
    })
    .catch(error => {
      console.error(error);
      dispatcher({ type: actionTypes.AIRCRAFT_MAPPINGS_LOADING });
    });
};

export const getLTOMappings = (client: ApolloClient<object>, dispatcher: any) => {
  fetchLTOMappings(client)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_LTO_MAPPINGS, ltoAircraftEngineMappings: data });
    })
    .catch(error => {
      console.error(error);
    });
};

export const getEnrouteMappings = (client: ApolloClient<object>, dispatcher: any) => {
  fetchEnrouteMappings(client)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_ENROUTE_MAPPINGS, enrouteAircraftEngineMappings: data });
    })
    .catch(error => {
      console.error(error);
    });
};

export const getAircraftFlights = (client: ApolloClient<object>, dispatcher: any, startTime: string, endTime: string) => {
  fetchAircraftFlights(client, startTime, endTime)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_AIRCRAFT_FLIGHTS, aircraftFlights: data });
    })
    .catch(error => {
      console.error(error);
    });
};

export const getAirportRunways = (client: ApolloClient<object>, dispatcher: any) => {
  fetchAirports(client)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_AIRPORTS, airports: data });
    })
    .catch(error => {
      console.error(error);
    });
};

export const updateShowMapped = (dispatcher: any, showMapped: boolean) => {
  dispatcher({ type: actionTypes.SHOW_MAPPED, showMapped });
};