import React, { FC } from 'react';
// context
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
// components
import { SkeletonText } from '@ems/client-design-system';
import { PageHeader, DateFilter } from 'src/components';
// fns
import { IHeaderContainer } from 'src/@infringementsCandidates/interfaces';
import { useDataSelectors } from 'src/@infringementsCandidates/reducers/dataReducer';
import { formatNumber } from 'src/utils';
import { withQueryStringUpdater } from 'src/app/hocs/withQueryStringUpdater';

export const HeaderContainer: FC<IHeaderContainer> = ({ updateUrl }) => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        table: { totalItems },
      },
    },
    screens: {
      infringements: { title },
    },
  } = languageSelectors.getLanguage();

  const dataSelectors = useDataSelectors();
  const { totalCount } = dataSelectors.getDataInformation();

  const DateFilterHoc = withQueryStringUpdater(DateFilter, updateUrl);

  return (
    <PageHeader title={title}>
      <SkeletonText loading={typeof totalCount === 'undefined' || totalCount === -1} width="4rem">
        <span className="page-count">
          {totalCount && formatNumber(totalCount)} {totalItems}
        </span>
      </SkeletonText>
      <div>
        <DateFilterHoc />
      </div>
    </PageHeader>
  );
};
