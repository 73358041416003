import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import {
  fetchInfringementSummary,
  summarySchema,
  fetchOperationDetailsQuery,
  operationDetailsSchema,
  fetchRuleDetailsQuery,
  ruleDetailsSchema,
  operationPlaybackSchema,
  fetchInAirQuery,
  fetchNoiseEventQuery,
  noiseEventSchema,
  fetchContinuousNoiseQuery,
  continuousNoiseSchema,
  fetchNoiseMonitorQuery,
  noiseMonitorSchema,
} from './schema';

export const fetchSummaryData = (client: ApolloClient<object>, id: number) => {
  return new Promise((resolve, reject) => {
    const GET_OPERATIONS = gql`
      query {
        ${fetchInfringementSummary}(id: ${id}) ${summarySchema}
      }
    `;

    client
      .query({ query: GET_OPERATIONS })
      .then(response => {
        if (response && response.data && response.data.infringement) {
          resolve({
            data: response.data.infringement,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchOperationDetails = (client: ApolloClient<object>, id: number) => {
  return new Promise((resolve, reject) => {
    const GET_OPERATIONS = gql`
      query {
        ${fetchOperationDetailsQuery}(id: ${id}) ${operationDetailsSchema}
      }
    `;

    client
      .query({ query: GET_OPERATIONS })
      .then(response => {
        if (response && response.data && response.data.operationSummary) {
          resolve({
            data: response.data.operationSummary,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchInAirTracksForPlayback = (client: ApolloClient<object>, time: string) => {
  return new Promise((resolve, reject) => {
    const GET_OPERATIONS = gql`
      query {
        ${fetchInAirQuery}(
          startTime: "${time}"
          endTime: "${time}"
          filter: {
            operationTypes: [Arrival, Departure, TouchAndGo]
          }
          timeRangeMode: InFlight
        ) {
          items ${operationPlaybackSchema}
        }
      }
    `;

    client
      .query({ query: GET_OPERATIONS })
      .then(response => {
        if (response && response.data && response.data[fetchInAirQuery]) {
          resolve({
            data: response.data[fetchInAirQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchRuleDetails = (client: ApolloClient<object>, id: number) => {
  return new Promise((resolve, reject) => {
    const GET_OPERATIONS = gql`
      query {
        ${fetchRuleDetailsQuery}(id: ${id}) ${ruleDetailsSchema}
      }
    `;

    client
      .query({ query: GET_OPERATIONS })
      .then(response => {
        if (response && response.data && response.data.infringementRule) {
          resolve({
            data: response.data.infringementRule,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchNoiseEventData = (client: ApolloClient<object>, id: number[]) => {
  return new Promise((resolve, reject) => {
    const GET_NOISE_EVENT = gql`
      query {
        ${fetchNoiseEventQuery}(
          ids: [${id}]
        ) ${noiseEventSchema}
      }
    `;

    client
      .query({ query: GET_NOISE_EVENT })
      .then(response => {
        if (response && response.data && response.data[fetchNoiseEventQuery].length > 0) {
          resolve({
            data: response.data[fetchNoiseEventQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchNoiseMonitorData = (client: ApolloClient<object>, id: number[]) => {
  return new Promise((resolve, reject) => {
    const GET_NOISE_MONITOR = gql`
      query {
        ${fetchNoiseMonitorQuery}(
          ids: [${id}]
        ) ${noiseMonitorSchema}
      }
    `;

    client
      .query({ query: GET_NOISE_MONITOR })
      .then(response => {
        if (response && response.data && response.data[fetchNoiseMonitorQuery].length > 0) {
          // Since we are looking for a single id, just take the first response.
          resolve({
            data: response.data[fetchNoiseMonitorQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchContinuousNoiseData = (
  client: ApolloClient<object>,
  locationId: number[] = [],
  noiseSampleType: string,
  {
    startTime,
    endTime,
  }: {
    startTime: string;
    endTime: string;
  }
) => {
  return new Promise((resolve, reject) => {
    const GET_CONTINUOUS_NOISE = gql`
      query {
        ${fetchContinuousNoiseQuery}(
          startTime: "${startTime}"
          endTime: "${endTime}"
          filter:{
            locationIds:[${locationId.join(',')}]
            sampleTypes:[${noiseSampleType}]
          }
        ) ${continuousNoiseSchema}
      }
    `;

    client
      .query({ query: GET_CONTINUOUS_NOISE })
      .then(response => {
        if (response && response.data) {
          resolve({
            data: response.data[fetchContinuousNoiseQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
