import React, { createContext, useReducer } from 'react';
// reducers
import {
  sortReducer,
  sortInitialState,
  fleetMixReducer,
  fleetMixInitialState,
  aircraftMappingsInitialState,
  aircraftsMappingsReducer,
  timeInModesReducer,
  timeInModesInitialState,
  loadFactorsInitialState,
  loadFactorsReducer,
  aircraftFuelMappingsReducer,
  aircraftFuelMappingsInitialState,
} from 'src/@settings/reducers';
// interfaces
import { IStoreState } from 'src/@settings/interfaces';

export const SettingsStateContext = createContext({
  aircraftMappings: aircraftMappingsInitialState,
  sort: sortInitialState,
  fleetMix: fleetMixInitialState,
  timeInModes: timeInModesInitialState,
  loadFactors: loadFactorsInitialState,
  fuelTypes: aircraftFuelMappingsInitialState,
});

export const SettingsDispatchContext = createContext<React.Dispatch<any> | null>(null);

export const SettingsStateProvider = ({ children }) => {
  const [sortState, sortDispatch] = useReducer(sortReducer, sortInitialState);
  const [fleetMixState, fleetMixDispatch] = useReducer(fleetMixReducer, fleetMixInitialState);

  const [aircraftMappingsState, aircraftMappingsDispatch] = useReducer(
    aircraftsMappingsReducer,
    aircraftMappingsInitialState
  );

  const [timeInModesState, timeInModesDispatch] = useReducer(
    timeInModesReducer,
    timeInModesInitialState
  );

  const [loafFactorsState, loadFactorsDispatch] = useReducer(
    loadFactorsReducer,
    loadFactorsInitialState
  );

  const [fuelTypesState, fuelTypesDispatch] = useReducer(
    aircraftFuelMappingsReducer,
    aircraftFuelMappingsInitialState
  );

  const state: IStoreState = {
    aircraftMappings: aircraftMappingsState,
    sort: sortState,
    fleetMix: fleetMixState,
    timeInModes: timeInModesState,
    loadFactors: loafFactorsState,
    fuelTypes: fuelTypesState,
  };

  const dispatchers = [
    aircraftMappingsDispatch,
    sortDispatch,
    fleetMixDispatch,
    timeInModesDispatch,
    loadFactorsDispatch,
    fuelTypesDispatch,
  ];

  const dispatch: (action: string) => void = (action: string) =>
    dispatchers.map((dispatcher: React.Dispatch<any>) => {
      dispatcher(action);
    });

  return (
    <SettingsStateContext.Provider value={state}>
      <SettingsDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsStateContext.Provider>
  );
};
