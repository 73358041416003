import { keyToActionTypes } from 'src/utils/keyToActionTypes';

export const actionTypes: any = keyToActionTypes(
  {
    // DATA
    GET_FIRST_FETCH: null,
    DATA_FETCHED: null,
    GET_TOTAL_COUNT: null,
    GET_GROOMING_COUNT: null,
    INLINE_EDIT_CAUSE: null,
    INLINE_EDIT_GROOMING_TAG: null,
    LOAD_MORE: null,
    SELECT_ROW: null,
    SELECT_TRACK: null,
    SORT_TABLE: null,
    RESET_DATA: null,
    NOISE_MONITORS_RECEIVED: null,
    IN_AIR_DATA_RECEIVED: null,
    // FILTER
    UPDATE_DATE_RANGE: null,
    UPDATE_SELECTED_ITEMS: null,
    CLEAR_SELECTED_ITEMS: null,
    UPDATE_RANGE_FILTER: null,
    UPDATE_GROOMING_FLAG_FILTER: null,
    INITIALISE_STORE: null,
  },
  'NOISEEVENTS'
);
