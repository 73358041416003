import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SplitLayout } from 'src/components/Layout';
import { ComplaintsStateProvider } from 'src/@complaints/providers/ComplaintsStateProvider';
import {
  DataContainer,
  MapDataContainer,
  TableContainer,
  HeaderContainer,
  FilterContainer
} from 'src/@complaints/containers';

const ComplaintsScreen = ({ location, updateUrl }) => {
  const locationState = location.state;
  const fromCorrelated =
    locationState && locationState.hasOwnProperty('fromCorrelated')
      ? locationState.fromCorrelated
      : false;
  const correlatedIds = fromCorrelated ? location.state.ids : [];
  return (
    <SplitLayout>
      <ComplaintsStateProvider>
        <DataContainer correlatedIds={correlatedIds}>
          <MapDataContainer />
          <div className="container-fluid">
            <div className="header-container"><HeaderContainer fromCorrelated={fromCorrelated} updateUrl={updateUrl} /></div>
            <FilterContainer />
            <TableContainer />
          </div>
        </DataContainer>
      </ComplaintsStateProvider>
    </SplitLayout>
  );
}

export const Complaints = withRouteProps(ComplaintsScreen);
