import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Duration } from 'luxon';
// selectors
import {
  useLanguageSelectors,
  useInfringementRulesSelectors,
  useGeometrySelectors,
  useConfigSelectors,
} from 'src/app/reducers';
// resolvers
import {
  BlockHeader,
  CardCollapsibleFreeform,
  getConvertedDistance,
  getConvertedMass,
  getConvertedSpeed,
  getConvertedVerticalDistance,
} from '@ems/client-design-system';
import { secToTime, getGeometryType, convertObjectKeys } from 'src/utils';
// constants
import {
  CORRIDOR_INFRINGEMENT,
  EXCLUSION_INFRINGEMENT,
  GATE_INFRINGEMENT,
  CURFEW_INFRINGEMENT,
  MINHEIGHT_INFRINGEMENT,
  NOISE_INFRINGEMENT,
} from 'src/constants';

export const RuleDetailsContainer = ({ ruleId }) => {
  const NON_COLLAPSIBLE_TYPES = [
    EXCLUSION_INFRINGEMENT,
    GATE_INFRINGEMENT,
    CURFEW_INFRINGEMENT,
    NOISE_INFRINGEMENT,
  ];
  let items: any = [];
  let cohortItems: any = [];
  const infringementRuleSelectors = useInfringementRulesSelectors();
  const data = infringementRuleSelectors.getRule(ruleId);
  const geometrySelectors = useGeometrySelectors();
  const gateGeometry = geometrySelectors.getGeometry(getGeometryType(GATE_INFRINGEMENT));
  const geometryFeatures = gateGeometry ? gateGeometry.features : [];

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: { weekdaysMedium, operationTypes, aircraftCategories },
      ruleDetails: {
        title,
        fields: {
          corridorIdTitle,
          passageCriteria,
          floorHeight,
          ceilingHeight,
          minimumLevelSegmentLength,
          maximumHeightVariation,
          maximumLevelSegments,
          maximumLevelSegmentLength,
          gateId: gateIdString,
          intersectionMode: intersectionModeString,
          gateDirection,
          altitudeRange: altitudeRangeString,
          speedRange: speedRangeString,
          startTime: startTimeField,
          endTime: endTimeField,
          flightDistance: flightDistanceField,
          requiredAltitude: requiredAltitudeField,
          cohort,
          day,
          time,
          operationType,
          runway,
          operatorType,
          mtow,
          aircraftType,
          mlw,
        },
        values: { gateDirection: gateDirectionValues, intersectionMode, any: anyValue },
        allowedPassage: allowedPassageTypes,
      },
    },
  } = languageSelectors.getLanguage();

  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  const [translationDataList, setTranslationDataList] = useState<object[]>([]);
  useEffect(() => {
    setTranslationDataList(
      convertObjectKeys({
        ...weekdaysMedium,
        ...aircraftCategories,
        ...operationTypes,
      })
    );
  }, [weekdaysMedium, operationTypes, aircraftCategories]);

  const hasData: boolean = !!Object.keys(data).length;
  const hasCohortData: boolean =
    hasData && data.candidateFilter && !!Object.keys(data.candidateFilter).length;

  const { name, description, infringementType } = data;

  if (hasData) {
    if (infringementType === CORRIDOR_INFRINGEMENT) {
      const { corridorId, ceilingAltitude, allowedPassage } = data;
      items = [
        {
          title: corridorIdTitle,
          description: corridorId,
        },
        {
          title: ceilingHeight,
          description: getConvertedVerticalDistance(ceilingAltitude, units.distanceVertical, 0),
        },
        {
          title: passageCriteria,
          description: allowedPassage.map((item, index) => (
            <span key={index}>
              {allowedPassage.length === index + 1
                ? allowedPassageTypes[item]
                : `${allowedPassageTypes[item]}, `}
            </span>
          )),
        },
      ];
    } else if (infringementType === GATE_INFRINGEMENT) {
      const { gateCriteria } = data;
      items = [];

      if (gateCriteria.length) {
        gateCriteria.map(criteria => {
          const feature = geometryFeatures.find(e => e.id === criteria.gateId.toString());
          const name = feature ? feature.properties.name : '';
          const altStart = getConvertedVerticalDistance(
            criteria.altitudeRange.start,
            units.distanceVertical,
            0
          );
          const altEnd = getConvertedVerticalDistance(
            criteria.altitudeRange.end,
            units.distanceVertical,
            0
          );
          const altRange =
            altStart && altEnd
              ? `${altStart}-${altEnd}`
              : altStart
              ? `>${altStart}`
              : altEnd
              ? `<${altEnd}`
              : '—';
          const speedStart = getConvertedSpeed(criteria.altitudeRange.start, units.speed, 0);
          const speedEnd = getConvertedSpeed(criteria.altitudeRange.end, units.speed, 0);
          const speedRange =
            speedStart && speedEnd
              ? `${speedStart}-${speedEnd}`
              : speedStart
              ? `>${speedStart}`
              : speedEnd
              ? `<${speedEnd}`
              : '—';
          items.push(
            {
              title: gateIdString,
              description: name,
            },
            {
              title: intersectionModeString,
              description: intersectionMode[criteria.intersectionMode],
            },
            {
              title: gateDirection,
              description: criteria.gateDirections
                .filter(key => gateDirectionValues[key] !== 'undefined')
                .map(key => gateDirectionValues[key])
                .join(', '),
            },
            {
              title: altitudeRangeString,
              description: altRange,
            },
            {
              title: speedRangeString,
              description: speedRange,
            }
          );
        });
      }
    } else if (infringementType === CURFEW_INFRINGEMENT) {
      const {
        candidateFilter: { startTime, endTime },
      } = data;
      items = [
        {
          title: startTimeField,
          description: secToTime(startTime, 'hh:mm'),
        },
        {
          title: endTimeField,
          description: secToTime(endTime, 'hh:mm'),
        },
      ];
    } else if (infringementType === MINHEIGHT_INFRINGEMENT) {
      const { flightDistance, requiredAltitude } = data;
      items = [
        {
          title: flightDistanceField,
          description: getConvertedDistance(flightDistance, units.distance, 0),
        },
        {
          title: requiredAltitudeField,
          description: getConvertedVerticalDistance(requiredAltitude, units.distanceVertical, 0),
        },
      ];
    } else if (NON_COLLAPSIBLE_TYPES.includes(infringementType)) {
      items = [];
    } else {
      const {
        floorAltitude,
        ceilingAltitude,
        minLevelSegmentLength,
        maxAltitudeVariation,
        maxLevelSegments,
        autoInfringementSegmentLength,
      } = data;

      items = [
        {
          title: floorHeight,
          description: floorAltitude
            ? getConvertedVerticalDistance(floorAltitude, units.distanceVertical, 0)
            : '',
        },
        {
          title: ceilingHeight,
          description: ceilingAltitude
            ? getConvertedVerticalDistance(ceilingAltitude, units.distanceVertical, 0)
            : '',
        },
        {
          title: minimumLevelSegmentLength,
          description: minLevelSegmentLength
            ? getConvertedDistance(minLevelSegmentLength, units.distance, 0)
            : '',
        },
        {
          title: maximumHeightVariation,
          description: maxAltitudeVariation
            ? getConvertedVerticalDistance(maxAltitudeVariation, units.distanceVertical, 0)
            : '',
        },
        {
          title: maximumLevelSegments,
          description: maxLevelSegments,
        },
        {
          title: maximumLevelSegmentLength,
          description: getConvertedDistance(autoInfringementSegmentLength, units.distance, 0),
        },
      ];
    }
  }

  if (hasCohortData) {
    const {
      startTime,
      endTime,
      daysOfWeek,
      operationTypes: opTypes,
      airportRunways,
      aircraftCategories,
      operatorCategories,
      lowestMaxTakeOffWeight,
      lowestMinLandingWeight,
    } = data.candidateFilter;
    const hasTime = startTime !== null;
    const hasDay = daysOfWeek !== null;
    const hasOpType = opTypes !== null;
    const hasRunway = airportRunways !== null;
    const hasAircraftType = aircraftCategories !== null;
    const hasOperatorType = operatorCategories !== null;
    const hasMTOW = lowestMaxTakeOffWeight !== null;
    const hasMLW = lowestMinLandingWeight !== null;
    const formattedStartTime = Duration.fromObject({ seconds: startTime }).toFormat('hh:mm');
    const formattedEndTime = Duration.fromObject({ seconds: endTime }).toFormat('hh:mm');

    cohortItems = [
      {
        title: time,
        description: hasTime ? `${formattedStartTime} - ${formattedEndTime}` : '00:00 - 00:00',
      },
      {
        title: day,
        description: hasDay
          ? daysOfWeek.map((day: string) => translationDataList[day]).join(', ')
          : anyValue,
      },
      {
        title: operationType,
        description: hasOpType
          ? opTypes.map((op: string) => translationDataList[op]).join('\n')
          : anyValue,
      },
      {
        title: runway,
        description: hasRunway ? airportRunways[0].runwayNames.join(', ') : anyValue,
      },
      {
        title: aircraftType,
        description: hasAircraftType
          ? aircraftCategories.map((ac: string) => translationDataList[ac]).join('\n')
          : anyValue,
      },
      {
        title: operatorType,
        description: hasOperatorType ? operatorCategories.join('\n') : anyValue,
      },
      {
        title: mtow,
        description: hasMTOW ? getConvertedMass(lowestMaxTakeOffWeight, units.mass, 0) : '',
      },
      {
        title: mlw,
        description: hasMLW ? getConvertedMass(lowestMinLandingWeight, units.mass, 0) : '',
      },
    ];
  } else {
    cohortItems = [
      {
        title: time,
        description: '00:00 - 00:00',
      },
      {
        title: day,
        description: anyValue,
      },
      {
        title: operationType,
        description: anyValue,
      },
      {
        title: runway,
        description: anyValue,
      },
      {
        title: aircraftType,
        description: anyValue,
      },
      {
        title: operatorType,
        description: anyValue,
      },
      {
        title: mtow,
        description: '',
      },
      {
        title: mlw,
        description: '',
      },
    ];
  }

  const detailsGrid = (
    <div
      className={cx('rule-details-grid', {
        'rule-details-grid--5': infringementType === GATE_INFRINGEMENT,
        'rule-details-grid--2': infringementType !== GATE_INFRINGEMENT,
      })}>
      {items.map((item, i) => (
        <div key={`rule_details-${i}`}>
          <div className="description-list_title">{item.title}</div>
          <div className="rule-details-grid_description">
            {item.description ? item.description : '—'}
          </div>
        </div>
      ))}
    </div>
  );

  const cohortGrid = (
    <div className={cx('rule-details-grid', 'rule-details-grid--2')}>
      {cohortItems.map((item, i) => (
        <div key={`rule_details-cohort-${i}`}>
          <div className="description-list_title">{item.title}</div>
          <div className="rule-details-grid_description">
            {item.description ? item.description : '—'}
          </div>
        </div>
      ))}
    </div>
  );

  const combinedGrids = (
    <>
      {detailsGrid}
      <div className="rule-details_divider">
        <h6 className="rule-details_cohort-title">{cohort}</h6>
        {cohortGrid}
      </div>
    </>
  );

  return (
    <div className="block">
      <BlockHeader title={title} loading={!hasData} />
      <CardCollapsibleFreeform
        title={name}
        description={description}
        items={combinedGrids}
        loading={!hasData}
      />
    </div>
  );
};
