import React, { useContext } from 'react';
// selectors
import { useConfigSelectors, useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useFilterSelectors } from 'src/@operations/reducers';
import { OperationDispatchContext } from 'src/@operations/providers/OperationsStateProvider';
// common components
import {
  Filter,
  FilterRow,
  RangeSelector,
  validateTimeFormat,
  isValidTime,
  isCompletedTimeFormat,
  onCloseTimeDialogValidation,
  Button,
  Icons,
} from '@ems/client-design-system';
import {
  updateSelectedItems,
  clearSelectedItems,
  updateRangeFilter,
  clearPcaFilter,
} from 'src/@operations/actions';
// function
import {
  getIfFiltersEmpty,
  translateLabels,
  convertDataToFilterForm,
} from 'src/@operations/functions';
import { convertObjectKeys } from 'src/utils/objectModifiers';
// interfaces
import { IFilterItem } from 'src/@operations/props';
// constants
import { NULL_VALUE, AIRCRAFT_TYPE_ICON_CODES } from 'src/constants';
import { usePermissions } from 'src/app/functions/permissions';
import { OPERATOR_CATEGORY, OPERATION_TAGS, FORMATION_COUNT } from 'src/app/featureToggles';
// theme
import themeToken from 'src/styles/themes.json';

export const FilterContainer = () => {
  const dispatcher = useContext(OperationDispatchContext);
  const configSelectors = useConfigSelectors();
  const {
    operations: {
      filter: { availableCategoryList, nullable, showSelectedIcons },
      availableOperationTags,
    },
  } = configSelectors.getConfig();
  const filterSelectors = useFilterSelectors();
  const filteredItems = filterSelectors.getRegularFilters();
  const usingPca = filterSelectors.getIfUsingPcaFilter();
  const pcaLocation = filterSelectors.getPcaLocation();
  const { canRead: infringementsRead } = usePermissions('Infringement');
  const { canRead: noiseEventsRead } = usePermissions('NoiseEvent');
  const FEATURE_FLAG_OPERATOR_CATEGORY = configSelectors.isFeatureAvailable(OPERATOR_CATEGORY);
  const FEATURE_FLAG_OPERATION_TAGS = configSelectors.isFeatureAvailable(OPERATION_TAGS);
  const FEATURE_FLAG_FORMATION_COUNT = configSelectors.isFeatureAvailable(FORMATION_COUNT);
  const selectedTrackTheme = configSelectors.getTheme('operations');

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        filters: { clear: clearValue, filter: filterValue, clearFilters },
      },
      hints: { noMatchesFound },
      filters: filtersTranslation,
      lists: {
        operationFilterCategories,
        aircraftCategories,
        operatorCategories,
        operationTypes,
        correlatedCategories,
        extraFilterValues,
      },
    },
  } = languageSelectors.getLanguage();

  // Time filter
  const { value: timeFilters, label: timeLabel } = filterSelectors.getTimeFilter();

  const convertedDataList = convertObjectKeys({
    ...aircraftCategories,
    ...operatorCategories,
    ...operationTypes,
    ...correlatedCategories,
    ...extraFilterValues,
  });
  // filters data
  const filtersSelectors = useFilterDataSelectors();
  const operationFilterData = filtersSelectors.getOperationsFilterData();
  //  The operation tags are different from other filters in that they need to
  //  come from the DB instead of the filter JSON files.
  if (
    FEATURE_FLAG_OPERATOR_CATEGORY &&
    Array.isArray(availableOperationTags) &&
    availableOperationTags.length
  ) {
    operationFilterData.operationTags = availableOperationTags;
  }
  const convertedFilterData = {};
  Object.entries(operationFilterData).map(([key, value]) => {
    const iconType: string = AIRCRAFT_TYPE_ICON_CODES[key] || '';
    convertedFilterData[key] = convertDataToFilterForm(value, iconType);
  });
  const filterComponents: JSX.Element[] = [];

  const rangeSelectors = ['time'];
  availableCategoryList.map((category: string) => {
    if (rangeSelectors.includes(category)) {
      switch (category) {
        case 'time':
          filterComponents.push(
            <RangeSelector
              key="filter-time-selector"
              type="time"
              min={null}
              max={null}
              fromInputValue={timeFilters.from}
              toInputValue={timeFilters.to}
              setInputValue={(value: string, field: 'from' | 'to') => {
                updateRangeFilter('time', value, field, dispatcher);
              }}
              label={timeLabel}
              validateInputFormat={validateTimeFormat}
              isValidValue={isValidTime}
              onCloseValidation={onCloseTimeDialogValidation}
              isCompletedValueFormat={isCompletedTimeFormat}
              languageData={filtersTranslation.time}
            />
          );
          break;
      }
    } else {
      const nullValue = { key: NULL_VALUE, label: NULL_VALUE, icon: undefined };
      const originalItems = convertedFilterData[category];
      if (originalItems !== undefined) {
        const transformedItems =
          nullable && nullable.includes(category) ? [nullValue, ...originalItems] : originalItems;
        let addFilter = true;
        if (category === 'correlated' && !(infringementsRead && noiseEventsRead)) {
          addFilter = false;
        }
        if (category === 'operatorCategories' && !FEATURE_FLAG_OPERATOR_CATEGORY) {
          addFilter = false;
        }
        if (category === 'operationTags' && !FEATURE_FLAG_OPERATION_TAGS) {
          addFilter = false;
        }
        if (category === 'remoteAirportIds' && FEATURE_FLAG_FORMATION_COUNT) {
          addFilter = false;
        }

        if (addFilter) {
          const categoryName: string = operationFilterCategories[category];
          if (!categoryName) {
            throw new Error(`categoryName for ${category} is ${categoryName}`);
          }
          filterComponents.push(
            <Filter
              key={category}
              categoryName={categoryName}
              filterItems={translateLabels(transformedItems, convertedDataList)}
              selectedItems={filteredItems[category]}
              updateItems={(items: IFilterItem[]) =>
                updateSelectedItems(category, items, dispatcher)
              }
              languageData={{ clearValue, filterValue, noMatchesFound }}
              iconCategories={showSelectedIcons.map(
                (category: string) => operationFilterCategories[category]
              )}
              theme={
                category === 'operationTypes' ? themeToken.operations[selectedTrackTheme] : null
              }
            />
          );
        }
      }
    }
  });

  if (pcaLocation) {
    filterComponents.push(
      <span className="filter-pca-location" key="filter-pca">
        <span className="filter-pca-location--category">Location: </span>
        <span className="filter-pca-location--item">{pcaLocation}</span>
        <Button
          style="subtle"
          leftIcon={<Icons iconName="ic-ui-cancel-sm" />}
          onClick={() => clearPcaFilter(dispatcher)}
        />
      </span>
    );
  }

  return (
    <div>
      <FilterRow
        filters={filterComponents}
        clearFilters={() => clearSelectedItems(dispatcher)}
        clearDisabled={!usingPca && getIfFiltersEmpty(filteredItems, [timeFilters])}
        languageData={{ clearFilters }}
      />
    </div>
  );
};
