import { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// config
import { mapLayerApi } from 'src/config';
// global store
import { GeometryDispatchContext } from 'src/app/providers/GeometryProvider';
import { loadRequiredGeometry } from 'src/app/actions/geometryActions';
import { useGeometrySelectors } from 'src/app/reducers/geometryReducer';
// functions
import { getDeployedProductId } from 'src/utils/generics';
import { whenMapHasLoadedSource, isSourceAvailable } from 'src/utils/mapHelpers';
import {
  mapboxCorridorStyle,
  mapboxExclusionStyle,
  mapboxGateStyle,
  mapboxGateLightStyle,
  mapboxGateSymbolsStyle,
  mapboxGateSymbolsLightStyle,
  mapboxRunwayStyle,
  mapboxRunwayFillStyle,
  mapboxNmtSymbolsStyle,
  mapboxNmtSymbolsLayout,
  mapboxNmtSymbolsLightLayout,
} from 'src/utils/mapStyles';
// constants
import {
  CORRIDOR_INFRINGEMENT,
  EXCLUSION_INFRINGEMENT,
  GATE_INFRINGEMENT,
  MONITOR_LOCATIONS,
  RUNWAYS,
} from 'src/constants';

export const getGeometryType = (infringementType: string): string => {
  let path: string;
  switch (infringementType) {
    case CORRIDOR_INFRINGEMENT:
      path = `corridors`;
      break;
    case EXCLUSION_INFRINGEMENT:
      path = `selectionzones`;
      break;
    case GATE_INFRINGEMENT:
      path = `gates`;
      break;
    case MONITOR_LOCATIONS:
      path = `monitorLocations`;
      break;
    case RUNWAYS:
      path = `runways`;
      break;
    default:
      path = '';
  }
  return path;
};

export const getGeometryFields = (infringementType: string): string => {
  let path: string;
  switch (infringementType) {
    case MONITOR_LOCATIONS:
      path = `name,description`;
      break;
    case RUNWAYS:
      path = `airportId,name`;
      break;
    default:
      path = 'name';
  }
  return path;
};

export const useGeometryData = ({
  infringementType,
  as3d,
}: {
  infringementType: string;
  as3d: boolean;
}): {
  geometryData: any;
} => {
  const geometryType = getGeometryType(infringementType);
  const geometryFields = getGeometryFields(infringementType);
  const client = useApolloClient();
  const geometryDispathcer = useContext(GeometryDispatchContext);
  const geometrySelectors = useGeometrySelectors();
  const geometryData = geometrySelectors.getGeometry(geometryType, as3d);

  if (!geometryData && geometryType) {
    loadRequiredGeometry({
      client,
      dispatcher: geometryDispathcer,
      source: infringementType,
      path: geometryType,
      fields: geometryFields,
      as3d,
    });
  }

  return {
    geometryData,
  };
};

// request to fetch required geometry data and add them as new sources to map
export const useGeometryRequiredByMap = ({
  mapApis = null,
  mapBoxConfig = null,
  sourceIdExt = '',
  infringementTypes,
}: {
  mapApis?: any;
  mapBoxConfig?: any;
  sourceIdExt?: string;
  infringementTypes: string[];
}) => {
  const client = useApolloClient();
  const geometryDispathcer = useContext(GeometryDispatchContext);
  const geometrySelectors = useGeometrySelectors();
  const [requiredGemoteryTypes, updateRequiredTypes] = useState<any>({
    [CORRIDOR_INFRINGEMENT]: false,
    [EXCLUSION_INFRINGEMENT]: false,
    [GATE_INFRINGEMENT]: false,
    [MONITOR_LOCATIONS]: false,
    [RUNWAYS]: false,
  });
  const corridorsData = geometrySelectors.getGeometry(getGeometryType(CORRIDOR_INFRINGEMENT));
  const exlusionsData = geometrySelectors.getGeometry(getGeometryType(EXCLUSION_INFRINGEMENT));
  const gateData = geometrySelectors.getGeometry(getGeometryType(GATE_INFRINGEMENT));
  const monitorLocationsData = geometrySelectors.getGeometry(getGeometryType(MONITOR_LOCATIONS));
  const runwaysData = geometrySelectors.getGeometry(getGeometryType(RUNWAYS));

  useEffect(() => {
    if (infringementTypes.length) {
      const temp: any = Object.assign({}, requiredGemoteryTypes);
      infringementTypes.forEach((infringementType: string) => {
        if (
          typeof requiredGemoteryTypes[infringementType] !== 'undefined' &&
          !requiredGemoteryTypes[infringementType]
        ) {
          temp[infringementType] = true;
          if (
            (infringementType === CORRIDOR_INFRINGEMENT && !corridorsData) ||
            (infringementType === EXCLUSION_INFRINGEMENT && !exlusionsData) ||
            (infringementType === GATE_INFRINGEMENT && !gateData) ||
            (infringementType === MONITOR_LOCATIONS && !monitorLocationsData) ||
            (infringementType === RUNWAYS && !runwaysData)
          ) {
            loadRequiredGeometry({
              client,
              dispatcher: geometryDispathcer,
              source: infringementType,
              path: getGeometryType(infringementType),
              fields: getGeometryFields(infringementType),
              as3d: false,
            });
          }
        }
      });
      if (Object.values(requiredGemoteryTypes).join() !== Object.values(temp).join()) {
        updateRequiredTypes(temp);
      }
    }
  }, [mapApis, infringementTypes]);

  useEffect(() => {
    if (mapApis && mapBoxConfig) {
      if (corridorsData) {
        addSourceToMap(mapApis, mapBoxConfig, CORRIDOR_INFRINGEMENT, corridorsData, sourceIdExt);
      }
      if (exlusionsData) {
        addSourceToMap(mapApis, mapBoxConfig, EXCLUSION_INFRINGEMENT, exlusionsData, sourceIdExt);
      }
      if (gateData) {
        addSourceToMap(mapApis, mapBoxConfig, GATE_INFRINGEMENT, gateData, sourceIdExt);
      }
      if (monitorLocationsData) {
        addSourceToMap(mapApis, mapBoxConfig, MONITOR_LOCATIONS, monitorLocationsData, sourceIdExt);
      }
      if (runwaysData) {
        addSourceToMap(mapApis, mapBoxConfig, RUNWAYS, runwaysData, sourceIdExt);
      }
    }
  }, [mapApis, corridorsData, exlusionsData, gateData, monitorLocationsData, runwaysData]);

  return {
    requiredGemoteryTypes,
  };
};

/**
 * Add geometry (corridors, selectionzones, gates, etc) sources to interactive map
 * @param mapApis - Mapbox API: provides methods to access map
 * @param mapBoxConfig - map config - site specific
 */
export const addSourceToMap = (mapApis, mapBoxConfig, source, sourceData, sourceIdExt = '') => {
  const {
    isSatelliteBackground,
    corridorIdentifier,
    selectionZoneIdentifier,
    gateIdentifier,
    monitorLocationsIdentifier,
    runwaysIdentifier,
  } = mapBoxConfig;

  let type;
  let identifier;
  let paint; // map style object to paint layer
  let layout: any = null;
  switch (source) {
    case CORRIDOR_INFRINGEMENT:
      type = 'fill';
      identifier = `${corridorIdentifier}${sourceIdExt}`;
      paint = mapboxCorridorStyle;
      break;
    case EXCLUSION_INFRINGEMENT:
      type = 'fill';
      identifier = `${selectionZoneIdentifier}${sourceIdExt}`;
      paint = mapboxExclusionStyle;
      break;
    case GATE_INFRINGEMENT:
      type = 'line';
      identifier = `${gateIdentifier}${sourceIdExt}`;
      paint = isSatelliteBackground ? mapboxGateLightStyle : mapboxGateStyle;
      break;
    case MONITOR_LOCATIONS:
      type = 'symbol';
      identifier = `${monitorLocationsIdentifier}${sourceIdExt}`;
      paint = mapboxNmtSymbolsStyle;
      layout = isSatelliteBackground ? mapboxNmtSymbolsLightLayout : mapboxNmtSymbolsLayout;
      break;
    case RUNWAYS:
      type = 'line';
      identifier = `${runwaysIdentifier}${sourceIdExt}`;
      paint = mapboxRunwayStyle;
      break;
    default:
  }

  if (
    !mapApis ||
    isSourceAvailable(mapApis, identifier) ||
    mapApis.getStyle().layers.findIndex(layer => layer.id === identifier) !== -1
  ) {
    return; // already added
  }

  mapApis.addSource(identifier, {
    type: 'geojson',
    data: sourceData,
  });

  whenMapHasLoadedSource(mapApis, identifier).then(() => {
    if (source === MONITOR_LOCATIONS) {
      mapApis.addLayer({
        id: identifier,
        type,
        source: identifier,
        paint,
        layout,
      });
    } else {
      mapApis.addLayer({
        id: identifier,
        type,
        source: identifier,
        paint,
      });
    }

    // add runway fill
    if (source === RUNWAYS) {
      const sourceIdentifier = `${identifier}-fill`;
      mapApis.addSource(sourceIdentifier, {
        type: 'geojson',
        data: sourceData,
      });
      whenMapHasLoadedSource(mapApis, sourceIdentifier).then(() => {
        mapApis.addLayer({
          id: sourceIdentifier,
          type: 'fill',
          source: identifier,
          paint: mapboxRunwayFillStyle,
        });
      });
    }
    // add gate symbols
    else if (source === GATE_INFRINGEMENT) {
      const { features } = sourceData;
      const sourceFeatures: any[] = [];
      const sourceIdentifier = `${identifier}-sybmols`;
      features.forEach(({ id, geometry: { coordinates } }) => {
        coordinates.forEach(([lat, lng]) => {
          sourceFeatures.push({
            id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [lat, lng],
            },
          });
        });
      });
      const gatesSourceData = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: sourceFeatures,
        },
      };

      mapApis.addSource(sourceIdentifier, gatesSourceData);
      whenMapHasLoadedSource(mapApis, sourceIdentifier).then(() => {
        mapApis.addLayer({
          id: sourceIdentifier,
          type: 'circle',
          source: identifier,
          paint: isSatelliteBackground ? mapboxGateSymbolsLightStyle : mapboxGateSymbolsStyle,
        });
      });
    }
  });
};

export const getGeometryEndpoint = (infringementType: string) => {
  const endpoint = `${mapLayerApi}${getDeployedProductId()}`;
  let path: null | string;
  switch (infringementType) {
    case CORRIDOR_INFRINGEMENT:
      path = `corridors`;
      break;
    case GATE_INFRINGEMENT:
      path = `gates`;
      break;
    case EXCLUSION_INFRINGEMENT:
      path = `selectionzones`;
      break;
    default:
      path = null;
  }
  return {
    geometryUrl: path ? `${endpoint}/${path}` : null,
    geometryUrl3d: path ? `${endpoint}/${path}?as3d=true` : null,
  };
};
