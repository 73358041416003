import React, { FC, useState } from 'react';
import { debounce } from 'debounce';
import { useApolloClient } from '@apollo/react-hooks';
// selectors
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
// components
import { AutoSearch } from '@ems/client-design-system';
// resolver
import { addressSearchResults, geocodeCandidateDetail } from 'src/app/resolvers/addressResolver';
// ts
import { IAddress, IGeocodeCandidateDetail } from 'src/app/props';

// geocodeAddressSchema
export interface IAddressSearchContainer {
  onAddressFound?: (address: IGeocodeCandidateDetail) => void;
  source: 'map' | 'complaint';
  formRelatedValues?: {
    value: string;
    error: string;
    disabled: boolean;
  }
}

export const AddressSearchContainer: FC<IAddressSearchContainer> = ({
  onAddressFound,
  source,
  formRelatedValues,
}) => {
  const client = useApolloClient();
  const [isLoading, updateIsLoading] = useState<boolean>(false);
  const [isNotFound, updateIsNotFound] = useState<boolean>(false);
  const [results, updateResults] = useState<IAddress[]>([]);

  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    map: { centre: mapCentre },
  } = configSelectors.getConfig();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        search: placeholderText,
        notFound: notFoundText
      },
    }
  } = languageSelectors.getLanguage();

  const fetchAddressResult = debounce((address: string) => {
    if (address && address.length > 2) {
      updateIsLoading(true);
      updateIsNotFound(false);
      const bounds = mapCentre ? {
        location: {
          latitude: mapCentre.latitude,
          longitude: mapCentre.longitude,
        },
        radius: 2000
      } : undefined;
      addressSearchResults({ client, address, bounds }).then((response) => {
        updateIsLoading(false);
        if (response.length) {
          updateResults(response);
        } else {
          updateIsNotFound(true);
        }
      })
        .catch(() => {
          updateResults([]);
          updateIsLoading(false);
        });
    } else {
      onClearResult();
    }
  }, 500);

  const onSelectedResult = ({ id, formattedAddress }) => {
    geocodeCandidateDetail({ client, id }).then((response) => {
      updateIsLoading(false);
      if (onAddressFound) {
        onAddressFound({
          ...response,
          autoCompleteAddress: formattedAddress,
        });
      }
    })
      .catch(() => {
      });
    updateResults([]);
  }

  const onClearResult = () => {
    updateResults([]);
    updateIsLoading(false);
    updateIsNotFound(false);
  }

  let autoFocusOnOpen: boolean = false;
  let hideClearBtnOnSelect: boolean = false;
  const translationData = {
    placeholder: placeholderText,
    notFound: notFoundText,
  }
  switch (source) {
    case 'map':
      autoFocusOnOpen = true;
      break;
    case 'complaint':
      hideClearBtnOnSelect = true;
      translationData.placeholder = '';
      break;
  }

  return (
    <>
      <AutoSearch
        searchResults={results}
        overwriteInputValue={formRelatedValues && typeof formRelatedValues.value !== undefined && formRelatedValues.value ? formRelatedValues.value : undefined}
        resultsNotFound={isNotFound}
        onAddressChange={fetchAddressResult}
        autoFocusOnOpen={autoFocusOnOpen}
        hideClearBtnOnSelect={hideClearBtnOnSelect}
        isLoading={isLoading}
        disabled={formRelatedValues && typeof formRelatedValues.disabled !== undefined && formRelatedValues.disabled ? formRelatedValues.disabled : undefined}
        onSelectedResult={onSelectedResult}
        onClearResult={onClearResult}
        translationData={translationData} />
    </>
  );
};
