import { ApolloClient } from 'apollo-client';
import { fetchOperationData } from 'src/@operations/resolvers/getDataResolver';
// const
import { actionTypes } from 'src/@operations/newActionTypes';
import { GET_COUNT_DELAY } from 'src/constants';
// utils
import { ResponseValidator } from 'src/utils/responseValidator';
import { IFetchAction, IFetchOptions } from 'src/@operations/props';
import { IFeatureFlags } from 'src/app/props';
const request = new ResponseValidator();

// fetch 1 record and check for count
export const getDataCount = (
  client: ApolloClient<object>,
  newDispatcher: any,
  options: IFetchOptions
) => {
  const checkTotalCount = data => {
    if (typeof data.totalCount !== undefined && data.totalCount !== -1) {
      newDispatcher({ type: actionTypes.GET_TOTAL_COUNT, data });
    } else {
      getDataCount(client, newDispatcher, options);
    }
  };
  const [instance, t] = request.get('operations-count');
  request.set(instance, t);
  const {
    sortString,
    filterString,
    pcaData,
    permissions,
    cursor,
    featureFlags,
    availableFilters,
  } = options;
  fetchOperationData({
    client,
    count: 1,
    sortString,
    filterString,
    pcaData,
    permissions,
    cursor,
    featureFlags,
    availableFilters,
  })
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        checkTotalCount(data);
      }
    })
    .catch(error => {
      console.error(error);
    });
};

// checks value of the data received for count
export const checkDataCount = (
  client: ApolloClient<object>,
  newDispatcher: any,
  data: any,
  options: IFetchOptions
) => {
  if (
    (data &&
      data.pageInfo &&
      data.pageInfo.hasNextPage &&
      typeof data.totalCount === 'undefined') ||
    data.totalCount === -1
  ) {
    // Fetch more to get count when not available after delay
    setTimeout(() => {
      getDataCount(client, newDispatcher, options);
    }, GET_COUNT_DELAY);
  } else {
    newDispatcher({ type: actionTypes.GET_TOTAL_COUNT, data });
  }
};

// Fetch data
export const fetchData = ({
  client,
  resultSize,
  dispatcher,
  sortString,
  filterString,
  pcaData,
  permissions,
  cursor,
  featureFlags,
  availableFilters,
}: IFetchAction) => {
  if (!cursor) {
    // Only happens on the first fetch
    dispatcher({ type: actionTypes.GET_FIRST_FETCH });
  }
  const [instance, t] = request.get('fetch-operations');
  request.set(instance, t);
  fetchOperationData({
    client,
    count: resultSize,
    sortString,
    filterString,
    pcaData,
    permissions,
    cursor,
    featureFlags,
    availableFilters,
  })
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        checkDataCount(client, dispatcher, data, {
          sortString,
          filterString,
          pcaData,
          cursor: data.pageInfo.startCursor,
          featureFlags,
          availableFilters,
        });
        dispatcher({ type: actionTypes.DATA_FETCHED, data });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const resetAndFetchData = ({
  client,
  resultSize,
  dispatcher,
  sortString,
  filterString,
  pcaData,
  permissions,
  featureFlags,
  availableFilters,
}: IFetchAction) => {
  dispatcher({ type: actionTypes.RESET_DATA });
  fetchData({
    client,
    resultSize,
    dispatcher,
    sortString,
    filterString,
    pcaData,
    permissions,
    featureFlags,
    availableFilters,
  });
};

export const selectRow = (ids: number[], newDispatcher?: any) => {
  newDispatcher({ type: actionTypes.SELECT_ROW, data: ids });
};

export const selectTracks = (
  ids: number[],
  newDispatcher?: any,
  isDynamicTileServer: boolean = false
) => {
  newDispatcher({ type: actionTypes.SELECT_TRACK, data: ids, isDynamicTileServer });
};

export const resetData = (newDispatcher: any) => {
  newDispatcher({ type: actionTypes.RESET_DATA });
};

export const resetDataAndMap = (newDispatcher: any) => {
  newDispatcher({ type: actionTypes.RESET_DATA, data: { resetMap: true } });
};

export const loadMore = (
  client: ApolloClient<object>,
  newDispatcher: any,
  options: {
    resultSize: number;
    endCursor: any;
    sortString: any;
    filterString: any;
    pcaData?: any;
    permissions?: {
      infringements: boolean;
      noiseEvents: boolean;
      complaints: boolean;
    };
    featureFlags?: IFeatureFlags;
    availableFilters?: {
      operationTags: string[];
    };
  }
) => {
  const {
    resultSize,
    sortString,
    filterString,
    endCursor,
    pcaData = {},
    permissions,
    featureFlags,
    availableFilters,
  } = options;
  newDispatcher({ type: actionTypes.LOAD_MORE });
  fetchData({
    client,
    resultSize,
    dispatcher: newDispatcher,
    sortString,
    filterString,
    pcaData,
    permissions,
    cursor: endCursor,
    featureFlags,
    availableFilters,
  });
};

export const sortTable = async (data: any, newDispatcher: any) => {
  const { sortName } = data;
  await newDispatcher({ type: actionTypes.SORT_TABLE, data: sortName });
};

export const updateTableIds = (dispatcher: any) => {
  dispatcher({ type: actionTypes.UPDATE_TABLE_IDS });
};
