import React, { FC, useState, useEffect, useContext } from 'react';
import { Table } from '@ems/client-design-system';
import { useDataSelectors, useSortSelectors, useFilterSelectors } from 'src/@reports/reducers';
import { IModifiedData } from 'src/@reports/interfaces';
import { formatReportsData, formatReportsHeaders } from 'src/@reports/functions/reports';
import { useLanguageSelectors, useConfigSelectors } from 'src/app/reducers';
import { convertObjectKeys, convertMapToArray, getSelectedIndexes } from 'src/utils';
import { ReportsDispatchContext } from 'src/@reports/providers/ReportsStateProvider';
import { LoadMoreBar } from 'src/components';
// Re-enable this when mass download is available
// import { selectRow } from 'src/@reports/actions';
import { loadMore } from 'src/@reports/actions';

export const TableContainer: FC = () => {
  const dispatcher = useContext<any>(ReportsDispatchContext);
  const dataSelectors = useDataSelectors();
  const reportData = dataSelectors.getReportsData();
  const isLoading = dataSelectors.getIfLoading();
  const isLoadingMore = dataSelectors.getIfLoadingMore();
  const totalCount = dataSelectors.getReportCount();
  const selectedInTable = dataSelectors.getSelectedInTable();
  const pageInfo = dataSelectors.getPageInfo();
  const filterSelectors = useFilterSelectors();
  const filterString = filterSelectors.getFilterString();
  const [lastKnownCount, setLastKnownCount] = useState<number>(10);
  const [dataIds, setDataIds] = useState<number[]>([]);
  useEffect(() => {
    if (totalCount && totalCount > 0) {
      setLastKnownCount(Math.min(totalCount, 100));
    }
  }, [totalCount]);
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();

  const configSelectors = useConfigSelectors();
  const {
    globals: { '12HourFormat': twelveHourFormat },
    reports: {
      grid: { resultSize },
    },
  } = configSelectors.getConfig();
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      buttons: { loadMore: loadMoreText },
      hints: { tryChangingFilters },
      lists: { downloadFormats, reportingPeriods, reportDataTypes },
      labels: {
        table: { endTable },
      },
    },
    screens: {
      reports: {
        errors: { noDataFound },
      },
    },
  } = languageSelectors.getLanguage();

  const [translationDataList, setTranslationDataList] = useState<object[]>([]);
  useEffect(() => {
    setTranslationDataList(
      convertObjectKeys({
        ...downloadFormats,
        ...reportingPeriods,
        ...reportDataTypes,
      })
    );
  }, [downloadFormats, reportingPeriods]);

  // Re-enable this when mass download is available
  // const onSelectRow = (indexes: number[]) => {
  //   const ids: number[] = [];
  //   for (const index of indexes) {
  //     ids.push(dataIds[index]);
  //   }
  //   selectRow(ids, dispatcher);
  // };

  const columnTypes = {
    displayName: {
      title: 'report',
      abbreviation: '',
    },
    displayTime: {
      title: 'time',
      abbreviation: '',
    },
    reportInterval: {
      title: 'period',
      abbreviation: '',
    },
    displayDownload: {
      title: 'download',
      abbreviation: '',
    },
  };

  // const [dataIds, setDataIds] = useState<number[]>([]);
  const [displayData, setDisplayData] = useState<IModifiedData[]>([]);
  useEffect(() => {
    const { keys, items } = convertMapToArray(
      formatReportsData(reportData, translationDataList, twelveHourFormat)
    );
    setDataIds(keys);
    setDisplayData(items);
  }, [reportData, translationDataList]);

  const loading = isLoading || isLoadingMore;

  return (
    <>
      <Table
        className="reports-table"
        loading={loading}
        rowHeaders={formatReportsHeaders(
          resultSize,
          loading,
          dispatcher,
          sortSelectors,
          languageSelectors.getLanguage()
        )}
        data={displayData}
        columns={['displayName', 'displayTime', 'reportInterval', 'displayDownload']}
        columnTypes={columnTypes}
        selectedData={getSelectedIndexes(selectedInTable, dataIds)}
        areAllRowsSelected={false}
        gridID="reports"
        hasEnded={!!displayData.length && pageInfo && !pageInfo.hasNextPage}
        languageData={{
          noDataTitle: noDataFound,
          noDataText: tryChangingFilters,
          endTable,
        }}
        showDashIfEmpty={true}
        numSkeletonRows={lastKnownCount}
        skeletonWidths={[225]}
      />
      <LoadMoreBar
        isVisible={pageInfo && pageInfo.hasNextPage ? true : false}
        isLoadingMore={isLoadingMore ? true : false}
        loadMore={loadMore}
        dispatcher={dispatcher}
        sortString={sortString}
        filterString={filterString}
        resultSize={resultSize}
        endCursor={pageInfo && pageInfo.endCursor}
        loadMoreText={loadMoreText}
      />
    </>
  );
};
