import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useApolloClient } from '@apollo/react-hooks';
// containers
import { OperationPanelContainer, OperationWeatherContainer } from 'src/containers';
import { MapContainer } from 'src/@operations/containers/Summary';
import { ExportContainer } from 'src/containers/ExportContainer';
// components
import { ItemsNavigator, Spinner, Icons } from '@ems/client-design-system';
import { PlaybackControl } from 'src/components/PlaybackControl';
import { ProfileGraph } from 'src/components/ProfileGraph';
import { SummaryHeader } from 'src/components/PageHeader';
// hoc
import { withAvailabilityChecks } from 'src/app/hocs/withPermissionsCheck';
// resolvers
import {
  fetchOperation,
  fetchOperationDetails,
  fetchInAirTracksForPlayback,
} from 'src/@operations/resolvers/operationSummaryResolver';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
import { useFilterDataSelectors } from 'src/app/reducers';
// functions
import { history, getDeployedProductId, rememberSavedFilters } from 'src/utils';
import { getPaginationInformation, goBack, navigate } from 'src/app/functions/itemsNavigation';
// constants
import { OPERATION } from 'src/constants';
import { DATA_EXPORT } from 'src/app/featureToggles';

export const ContentContainer = ({ id, path, paginationInfo }) => {
  const [operation, updateOperation]: any = useState({});
  const [inAirData, setInAirData] = useState<any>([]);
  const client = useApolloClient();
  // filters data
  const filtersSelectors = useFilterDataSelectors();
  const operationFilterData = filtersSelectors.getOperationsFilterData();
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    operationDetails: { showWeatherPanel },
  } = configSelectors.getConfig();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      operations: { title: goBackTitle },
    },
  } = languageSelectors.getLanguage();
  // Playback states
  const [isPlaybackMode, setIsPlaybackMode] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [runningStatus, setRunningStatus] = useState<boolean>(false);
  const [, setDraggingStatus] = useState<boolean>(false);
  const [, setPlaybackSpeed] = useState<number>(0);
  const [markedTime, setMarkedTime] = useState<number | null>(null);
  const [profileHoverTime, setProfileHoverTime] = useState<number | null>(null);

  const getCenterPoint = (points, operationType) => {
    let position = { altitude: 0, latitude: 0, longitude: 0 };
    if (typeof points !== 'undefined' && points.length > 2) {
      const at = operationType === 'Arrival' ? points.length - 1 : 0;
      position = { altitude: points[at].alt, latitude: points[at].lat, longitude: points[at].lon };
    }
    return position;
  };

  const resetPageData = () => {
    updateOperation({});
    setInAirData([]);
  };

  useEffect(() => {
    rememberSavedFilters(OPERATION);
    if (id) {
      let operationObject = {};
      fetchOperation(client, id)
        .then(({ data }: any) => {
          operationObject = Object.assign({}, operationObject, data);
          fetchOperationDetails(client, id)
            .then(({ data }: any) => {
              updateOperation(Object.assign({}, operationObject, data));
            })
            .catch(error => {
              history.replace(`/${getDeployedProductId()}/404`);
              console.error(error);
            });
        })
        .catch(error => {
          history.replace(`/${getDeployedProductId()}/404`);
          console.error(error);
        });
    }
  }, [id]);

  useEffect(() => {
    if (operation && operation.time) {
      fetchInAirTracksForPlayback(client, operation.time).then((data: any) => {
        setInAirData(data.data);
      });
    }
  }, [operation]);

  const { id: operationId, operationType, acid, time, points, correlated } = operation;
  const [pageTitle, updatePageTitle] = useState<string>(acid);
  useEffect(() => {
    if (acid && acid !== undefined) {
      updatePageTitle(acid);
    }
  }, [acid]);
  const position = getCenterPoint(points, operationType);
  const hasWeatherData: boolean =
    typeof correlated !== 'undefined' && correlated.hasOwnProperty('weather') && correlated.weather;
  const hasData: boolean = !!Object.keys(operation).length;
  const paging = getPaginationInformation(Number(id), paginationInfo);

  // data export feature
  const DataExportFeature = useMemo(
    () =>
      withAvailabilityChecks(ExportContainer, {
        feature: DATA_EXPORT,
        permissions: 'Export',
      }),
    []
  );
  const isExportEnabled =
    (!isPlaybackMode && runningStatus) ||
    (isPlaybackMode && !runningStatus) ||
    (!isPlaybackMode && !runningStatus);

  if (!hasData) {
    return (
      <>
        <div className="map-skeleton">
          <Spinner loading={true} size="xl" centered={true} />
        </div>
        <div className="content-skeleton" />
      </>
    );
  }

  return (
    <>
      <div className="map_wrapper">
        <MapContainer
          time={time}
          operationId={operationId}
          operation={operation}
          position={position}
          isPlaybackMode={isPlaybackMode}
          currentTime={currentTime}
          inAirData={inAirData}
          setSelectedTime={setMarkedTime}
          profileHoverTime={profileHoverTime}
          isPlaybackRunning={runningStatus}
        />
        <div className="map-overlay-panel">
          <ProfileGraph
            data={operation.profile}
            operationType={operation.operationType}
            currentTime={isPlaybackMode ? currentTime : 0}
            startTime={operation.startTime}
            endTime={operation.endTime}
            shouldDisplay={operation && operation.profile}
            markedTime={markedTime}
            onHoverCB={setProfileHoverTime}
            isPlaybackMode={isPlaybackMode}
            isPlaybackRunning={runningStatus}
            selectedTrackTheme={selectedTrackTheme}
          />
          {isPlaybackMode && operation.startTime && (
            <>
              <PlaybackControl
                startTime={operation.startTime}
                endTime={operation.endTime}
                onPositionUpdate={setCurrentTime}
                onPlaybackSpeedUpdate={setPlaybackSpeed}
                onRunningStatusUpdate={setRunningStatus}
                onDraggingStatusUpdate={setDraggingStatus}
                onPlaybackStop={() => {
                  setIsPlaybackMode(false);
                  setRunningStatus(false);
                }}
                playbackTimeBuffer={0}
                alwaysShowDot={true}
                profileData={operation.profile}
                showProfileData={true}
              />
            </>
          )}
          {!isPlaybackMode && (
            <button
              className={cx('mode-playback', {
                'mode-playback--hidden': isPlaybackMode,
              })}
              onClick={() => setIsPlaybackMode(!isPlaybackMode)}>
              <Icons iconName={`ic-ui-play`} size="24" fill="#2e384d" />
            </button>
          )}
        </div>
      </div>
      <div className="container-fluid container-fluid--details">
        <div className="container-fluid--inner">
          <SummaryHeader type="navigation">
            <ItemsNavigator
              languageData={{ goBackTitle }}
              goBack={() => goBack(path, paging, [])}
              navigateItems={paging.navigateItems}
              nextItem={navigate('forward', path, paging, () => {
                resetPageData();
                setIsPlaybackMode(false);
              })}
              previousItem={navigate('backward', path, paging, () => {
                resetPageData();
                setIsPlaybackMode(false);
              })}
            />
          </SummaryHeader>
          <SummaryHeader type="summary">
            <div className="page-header_title">{pageTitle}</div>
            <DataExportFeature
              source={OPERATION}
              selectedIds={isExportEnabled ? [Number(id)] : []}
              dropdownWidth={103}
            />
          </SummaryHeader>
          <div className="layout_split">
            <div className="layout_split--half">
              <div className="layout_content">
                <OperationPanelContainer
                  operation={operation}
                  operationFilterData={operationFilterData}
                  updatePageTitle={updatePageTitle}
                />
                {showWeatherPanel && hasWeatherData && (
                  <OperationWeatherContainer data={hasWeatherData} />
                )}
              </div>
            </div>
            <div className="layout_split--half">
              <div className="layout_content" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
