import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import turfBearing from '@turf/bearing';
import { point as turfPoint } from '@turf/helpers';
// @ts-ignore
import sassVars from 'src/styles/vars.module.scss';
import {
  IMapData,
  IFeatureData,
  INoiseMonitorPlayback,
  IMarkedTime,
  ISelectedTrackShadowPoints,
} from 'src/utils/interfaces';
import { linearInterpolateValue, getOperationTheme, getDefaultStyleColor } from 'src/utils';
// selectors
import { useConfigSelectors } from 'src/app/reducers';

//  Takes the existing lat-long track data, and using interpolation, converts
//  it to an array with the lat-long coordinates for each second of the flight
export const convertToSecondPoints = (points: any) => {
  const converted: any = [];
  const maxTime = points[points.length - 1].t;
  let counter = 0;

  for (let time = 0; time <= maxTime; time++) {
    const currentPoint: { lon: number; lat: number; alt: number; t: number } = points[counter];
    if (currentPoint.t === time) {
      converted.push([currentPoint.lon, currentPoint.lat, currentPoint.alt]);
    } else if (counter < points.length && time < points[counter + 1].t) {
      const nextPoint = points[counter + 1];
      const timeDiff = nextPoint.t - currentPoint.t;
      const jumps = time - currentPoint.t;
      converted.push([
        linearInterpolateValue(currentPoint.lon, nextPoint.lon, timeDiff, jumps),
        linearInterpolateValue(currentPoint.lat, nextPoint.lat, timeDiff, jumps),
        linearInterpolateValue(currentPoint.alt, nextPoint.alt, timeDiff, jumps),
      ]);
    } else {
      const nextPoint = points[counter + 1];
      converted.push([nextPoint.lon, nextPoint.lat, nextPoint.alt]);
      counter++;
    }
  }
  return converted;
};

// determines if the passed track matches highlight id's
const isTrackHighlighted = (id: number, highlightId: number | number[]) => {
  if (Array.isArray(highlightId)) {
    return highlightId.includes(id);
  }
  return id === highlightId;
};

export const useInAirPlayback = (
  mapApis: any,
  dataSources: any[],
  currentTime: number,
  isPlaybackMode: boolean,
  highlightId?: number | number[]
) => {
  const MAX_ALTITUDE = 5000;
  const [dataArray, setDataArray] = useState<IMapData[]>([]);
  const [lineData, setLineData] = useState<IFeatureData[]>([]);
  const [pointData, setPointData] = useState<IFeatureData[]>([]);
  const [shadowData, setShadowData] = useState<ISelectedTrackShadowPoints[]>([]);
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const currentTheme = getOperationTheme(selectedTrackTheme);

  useEffect(() => {
    const setupArray: IMapData[] = [];
    for (const item of dataSources) {
      if (item) {
        const { points, ...otherElements } = item;
        const convertedPoints = convertToSecondPoints(points);
        setupArray.push({
          ...otherElements,
          startTime: DateTime.fromISO(item.startTime).toSeconds(),
          endTime: DateTime.fromISO(item.endTime).toSeconds(),
          dataPoints: convertedPoints,
        });
      }
      setDataArray(setupArray);
    }
  }, [dataSources]);

  useEffect(() => {
    const lines: IFeatureData[] = [];
    const points: IFeatureData[] = [];
    const shadows: ISelectedTrackShadowPoints[] = [];

    dataArray.map(data => {
      const { dataPoints, ...remainingData } = data;
      lines.push({
        type: 'Feature',
        properties: {
          ...remainingData,
          valid: false,
        },
        geometry: {
          type: 'LineString',
          coordinates: dataPoints[0],
        },
      });

      const iconName =
        data.aircraftCategory === 'Unknown' ? 'ac-unknown' : data.aircraftCategory.toLowerCase();

      let iconType =
        data.operationType === 'Miscellaneous' ? 't' : data.operationType.charAt(0).toLowerCase();

      const themeType = selectedTrackTheme === 'default' ? '' : `_${selectedTrackTheme}`;

      if (isTrackHighlighted(data.id, highlightId)) {
        iconType = 'selected';
        shadows.push({
          type: 'Feature',
          properties: {
            id: data.id,
          },
          geometry: {
            type: 'Point',
            coordinates: dataPoints[0],
          },
        });
      }

      points.push({
        type: 'Point',
        properties: {
          ...remainingData,
          valid: false,
          bearing: turfBearing(turfPoint(dataPoints[0]), turfPoint(dataPoints[1])),
          icon_type:
            iconType === 'selected'
              ? `${iconName}_${iconType}`
              : `${iconName}_${iconType}${themeType}`,
        },
        geometry: {
          type: 'Point',
          coordinates: dataPoints[0],
        },
      });
    });
    setLineData(lines);
    setPointData(points);
    setShadowData(shadows);
  }, [dataArray]);
  useEffect(() => {
    if (mapApis && lineData.length) {
      if (!mapApis.getSource('lines')) {
        mapApis.addSource('lines', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: lineData,
          },
        });

        let lineCase: any = [];
        const selectedTrackColour = getDefaultStyleColor('selectedFlight');

        if (Array.isArray(highlightId)) {
          highlightId.map(id => {
            lineCase.push(['==', ['get', 'id'], id], selectedTrackColour);
          });
        } else {
          const safeHighlightId = highlightId ? highlightId : -1;
          lineCase = [['==', ['get', 'id'], safeHighlightId], selectedTrackColour];
        }

        mapApis.addLayer({
          id: 'lines',
          source: 'lines',
          type: 'line',
          paint: {
            'line-width': 2,
            'line-color': [
              'case',
              ...lineCase,
              ['==', ['get', 'operationType'], 'Departure'],
              currentTheme.departure,
              ['==', ['get', 'operationType'], 'Arrival'],
              currentTheme.arrival,
              ['==', ['get', 'operationType'], 'Overflight'],
              currentTheme.overflight,
              ['==', ['get', 'operationType'], 'TouchAndGo'],
              currentTheme.touchandgo,
              ['==', ['get', 'operationType'], 'Miscellaneous'],
              currentTheme.touchandgo,
              'hsl(0, 100%, 100%)',
            ],
          },
          filter: ['==', 'valid', true],
        });
      }

      if (!mapApis.getSource('points')) {
        mapApis.addSource('shadows', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: shadowData,
          },
        });

        mapApis.addLayer({
          id: 'shadows',
          type: 'circle',
          source: 'shadows',
          layout: {
            visibility: 'none',
          },
          paint: {
            'circle-radius': ['step', ['zoom'], 12, 6, 16, 9, 20, 12, 28, 15, 46],
            'circle-color': 'white',
            'circle-opacity': 1,
            'circle-blur': 0.5,
          },
        });

        mapApis.addSource('points', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: pointData,
          },
        });

        mapApis.addLayer({
          id: 'points',
          source: 'points',
          type: 'symbol',
          layout: {
            'icon-image': [
              'step',
              ['zoom'],
              ['concat', ['get', 'icon_type'], '_16'],
              6,
              ['concat', ['get', 'icon_type'], '_24'],
              9,
              ['concat', ['get', 'icon_type'], '_32'],
              12,
              ['concat', ['get', 'icon_type'], '_48'],
              15,
              ['concat', ['get', 'icon_type'], '_64'],
            ],
            'icon-rotate': ['get', 'bearing'],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-size': {
              property: 'scale',
              type: 'identity',
            },
          },
          filter: ['==', 'valid', true],
        });
      }
    }
  }, [mapApis, lineData, pointData]);

  const updateSources = (index: number, id: number) => {
    const lines = [...lineData];
    const points = [...pointData];
    const shadows = [...shadowData];
    const currentLine = lines[index];
    const currentPoint = points[index];
    const currentDataPoints = dataArray[index].dataPoints;
    const convertedPosition = currentTime - currentLine.properties.startTime;
    const validPosition = convertedPosition >= 0 && convertedPosition < currentDataPoints.length;
    const isValid = validPosition && isPlaybackMode;
    currentLine.properties.valid = isValid;
    points[index].properties.valid = isValid;
    if (validPosition) {
      const roundedLowPos = Math.floor(convertedPosition);
      let roundedHighPos = Math.ceil(convertedPosition);
      if (roundedHighPos === roundedLowPos) {
        // If it's the same, add 1 to rounded low position as it will be the same otherwise
        roundedHighPos = roundedLowPos + 1;
      }
      // Check if it exceeds the length of coordsSrc, as it will overflow if so
      roundedHighPos = Math.min(currentDataPoints.length - 1, roundedHighPos);
      const pointLow = currentDataPoints[roundedLowPos];
      const pointHigh = currentDataPoints[roundedHighPos];
      const interpolatedLon = linearInterpolateValue(
        pointLow[0],
        pointHigh[0],
        1,
        convertedPosition - roundedLowPos
      );
      const interpolatedLat = linearInterpolateValue(
        pointLow[1],
        pointHigh[1],
        1,
        convertedPosition - roundedLowPos
      );
      const interpolatedAlt = linearInterpolateValue(
        pointLow[2],
        pointHigh[2],
        1,
        convertedPosition - roundedLowPos
      );

      currentPoint.geometry.coordinates = [interpolatedLon, interpolatedLat, interpolatedAlt];

      const shadowIndex = shadows.findIndex(shadow => shadow.properties.id === id);
      if (shadowIndex !== -1) {
        shadows[shadowIndex].geometry.coordinates = [
          interpolatedLon,
          interpolatedLat,
          interpolatedAlt,
        ];
      }

      currentPoint.properties.bearing = turfBearing(
        turfPoint(currentDataPoints[Math.max(0, roundedHighPos - 1)]),
        turfPoint(currentDataPoints[roundedHighPos])
      );

      currentLine.geometry.coordinates = currentDataPoints.slice(
        Math.max(0, roundedHighPos - 30),
        roundedHighPos
      );

      currentPoint.properties.scale =
        0.75 + 0.25 * Math.min(1, currentDataPoints[roundedHighPos][2] / MAX_ALTITUDE);
    }

    setPointData(points);
    setLineData(lines);
    setShadowData(shadows);
  };

  useEffect(() => {
    for (let i = 0; i < dataArray.length; ++i) {
      updateSources(i, dataArray[i].id);
    }

    if (mapApis) {
      const mapApiPointSrc = mapApis.getSource('points');
      const mapApiLineSrc = mapApis.getSource('lines');
      const mapApiShadowSrc = mapApis.getSource('shadows');
      const layers = mapApis.getStyle().layers;

      // Update the source with this new data.
      if (mapApiLineSrc !== undefined) {
        mapApiLineSrc.setData({
          type: 'FeatureCollection',
          features: lineData,
        });
      }

      if (mapApiShadowSrc !== undefined) {
        mapApiShadowSrc.setData({
          type: 'FeatureCollection',
          features: shadowData,
        });
        mapApis.setLayoutProperty('shadows', 'visibility', isPlaybackMode ? 'visible' : 'none');
      }

      if (mapApiPointSrc !== undefined) {
        mapApiPointSrc.setData({
          type: 'FeatureCollection',
          features: pointData,
        });

        // move the points layer to the top if it isn't already
        if (layers.findIndex(layer => layer.id === 'points') !== layers.length - 1) {
          mapApis.moveLayer('lines');
          mapApis.moveLayer('shadows');
          mapApis.moveLayer('points');
        }
      }
    }
  }, [currentTime, isPlaybackMode]);

  useEffect(() => {
    if (mapApis) {
      const mapApiLineLayer = mapApis.getLayer('lines');
      // Update the line layer with highlightId
      let lineCase: any = [];
      const selectedTrackColour = getDefaultStyleColor('selectedFlight');
      if (Array.isArray(highlightId)) {
        highlightId.map(id => {
          lineCase.push(['==', ['get', 'id'], id], selectedTrackColour);
        });
      } else {
        const safeHighlightId = highlightId ? highlightId : -1;
        lineCase = [['==', ['get', 'id'], safeHighlightId], selectedTrackColour];
      }

      if (mapApiLineLayer) {
        mapApis.setPaintProperty('lines', 'line-color', [
          'case',
          ...lineCase,
          ['==', ['get', 'operationType'], 'Departure'],
          currentTheme.departure,
          ['==', ['get', 'operationType'], 'Arrival'],
          currentTheme.arrival,
          ['==', ['get', 'operationType'], 'Overflight'],
          currentTheme.overflight,
          ['==', ['get', 'operationType'], 'TouchAndGo'],
          currentTheme.touchandgo,
          ['==', ['get', 'operationType'], 'Miscellaneous'],
          currentTheme.touchandgo,
          'hsl(0, 100%, 100%)',
        ]);
      }
    }
  }, [mapApis, highlightId, isPlaybackMode]);
};

export const useStaticFlightDisplay = (
  mapApis: any,
  dataSources: any[],
  isPlaybackMode: boolean,
  displayFlight: boolean,
  highlightIds: number[],
  markedTime: IMarkedTime[]
) => {
  useEffect(() => {
    if (mapApis) {
      const visibility = displayFlight && !isPlaybackMode ? 'visible' : 'none';
      if (mapApis.getLayer('static-points')) {
        mapApis.setLayoutProperty('static-points', 'visibility', visibility);
      }
      if (mapApis.getLayer('static-lines')) {
        mapApis.setLayoutProperty('static-lines', 'visibility', visibility);
      }
    }
  }, [mapApis, isPlaybackMode, displayFlight]);

  const MAX_ALTITUDE = 5000;
  const [dataArray, setDataArray] = useState<IMapData[]>([]);
  const [lineData, setLineData] = useState<IFeatureData[]>([]);
  const [pointData, setPointData] = useState<IFeatureData[]>([]);
  const [coordinates, setCoordinates] = useState<any>([]);
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const currentTheme = getOperationTheme(selectedTrackTheme);

  useEffect(() => {
    const setupArray: IMapData[] = [];
    for (const item of dataSources) {
      if (item) {
        const { points, ...otherElements } = item;
        const convertedPoints = convertToSecondPoints(points);
        setupArray.push({
          ...otherElements,
          startTime: DateTime.fromISO(item.startTime).toSeconds(),
          endTime: DateTime.fromISO(item.endTime).toSeconds(),
          dataPoints: convertedPoints,
        });
      }
    }
    setDataArray(setupArray);
  }, [dataSources]);

  useEffect(() => {
    const lines: IFeatureData[] = [];
    const points: IFeatureData[] = [];
    const newCoords: any = [];

    highlightIds.map((id: number) => {
      const dataIndex = dataArray.findIndex(element => element.id === id);
      if (dataIndex === -1) {
        return;
      }

      const data = dataArray[dataIndex];
      // Find all instances of the required operation ID
      const relatedMarkedTimes: any = markedTime.filter(item => item.id === data.id);

      // If none, then don't try to display
      if (!relatedMarkedTimes.length) {
        return;
      }

      // Sort if there's more than 1 by timestamp
      if (relatedMarkedTimes.length > 1) {
        relatedMarkedTimes.sort((a, b) => {
          a = new Date(a.time);
          b = new Date(b.time);
          return a < b ? -1 : a > b ? 1 : 0;
        });
      }

      // We only want to show the earliest one, so extract that one.
      const earliestMarkedTime = relatedMarkedTimes[0];
      const convertedMarkedTime = DateTime.fromISO(earliestMarkedTime.time).toSeconds();
      const markedDataPoint = Math.round(convertedMarkedTime - data.startTime);
      const { dataPoints, ...remainingData } = data;
      // if the marked data point is less than 0, there is no point/line to show
      if (typeof dataPoints[markedDataPoint] === 'undefined' || markedDataPoint < 0) {
        // console.log("the marked data point is less than 0, there is no point/line to show");
        return;
      }

      const slicedPoints = dataPoints.slice(Math.max(0, markedDataPoint - 50), markedDataPoint);
      lines.push({
        type: 'Feature',
        properties: {
          ...remainingData,
          valid: true,
        },
        geometry: {
          type: 'LineString',
          coordinates: slicedPoints,
        },
      });

      const iconName =
        data.aircraftCategory === 'Unknown' ? 'ac-unknown' : data.aircraftCategory.toLowerCase();
      const iconType =
        data.operationType === 'Miscellaneous' ? 't' : data.operationType.charAt(0).toLowerCase();
      const themeType = selectedTrackTheme === 'default' ? '' : `_${selectedTrackTheme}`;

      // ensure altitude value exists
      const altitude =
        dataPoints[markedDataPoint].length === 3
          ? Math.min(1, dataPoints[markedDataPoint][2] / MAX_ALTITUDE)
          : 1;

      points.push({
        type: 'Point',
        properties: {
          ...remainingData,
          valid: true,
          bearing: turfBearing(
            turfPoint(dataPoints[Math.max(0, markedDataPoint - 1)]),
            turfPoint(dataPoints[markedDataPoint])
          ),
          icon_type: `${iconName}_${iconType}${themeType}`,
          scale: 0.75 + 0.25 * altitude,
        },
        geometry: {
          type: 'Point',
          coordinates: dataPoints[markedDataPoint],
        },
      });

      newCoords.push({
        latitude: dataPoints[markedDataPoint][1],
        longitude: dataPoints[markedDataPoint][0],
      });
    });

    setLineData(lines);
    setPointData(points);
    setCoordinates(newCoords);
  }, [dataArray, highlightIds]);

  useEffect(() => {
    if (mapApis) {
      const linesSrc = mapApis.getSource('static-lines');
      if (!linesSrc) {
        mapApis.addSource('static-lines', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: lineData,
          },
        });
      } else {
        linesSrc.setData({
          type: 'FeatureCollection',
          features: lineData,
        });
      }

      if (mapApis.getLayer('static-lines') === undefined) {
        mapApis.addLayer({
          id: 'static-lines',
          source: 'static-lines',
          type: 'line',
          paint: {
            'line-width': 2,
            'line-color': [
              'case',
              ['==', ['get', 'operationType'], 'Departure'],
              currentTheme.departure,
              ['==', ['get', 'operationType'], 'Arrival'],
              currentTheme.arrival,
              ['==', ['get', 'operationType'], 'Overflight'],
              currentTheme.overflight,
              ['==', ['get', 'operationType'], 'TouchAndGo'],
              currentTheme.touchandgo,
              ['==', ['get', 'operationType'], 'Miscellaneous'],
              currentTheme.touchandgo,
              'hsl(0, 100%, 100%)',
            ],
          },
          filter: ['==', 'valid', true],
        });
      }

      const pointsSrc = mapApis.getSource('static-points');
      if (!pointsSrc) {
        mapApis.addSource('static-points', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: pointData,
          },
        });
      } else {
        pointsSrc.setData({
          type: 'FeatureCollection',
          features: pointData,
        });
      }

      if (mapApis.getLayer('static-points') === undefined) {
        mapApis.addLayer({
          id: 'static-points',
          source: 'static-points',
          type: 'symbol',
          layout: {
            'icon-image': [
              'step',
              ['zoom'],
              ['concat', ['get', 'icon_type'], '_16'],
              6,
              ['concat', ['get', 'icon_type'], '_24'],
              9,
              ['concat', ['get', 'icon_type'], '_32'],
              12,
              ['concat', ['get', 'icon_type'], '_48'],
              15,
              ['concat', ['get', 'icon_type'], '_64'],
            ],
            'icon-rotate': ['get', 'bearing'],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-size': {
              property: 'scale',
              type: 'identity',
            },
          },
          filter: ['==', 'valid', true],
        });
      }
    }
  }, [mapApis, lineData, pointData]);

  return coordinates;
};

export const useStaticDbDisplay = (
  mapApis: any,
  noiseMonitors: any[],
  noiseEventData: any[],
  isPlaybackMode: boolean
) => {
  useEffect(() => {
    if (mapApis) {
      const visibilityStatus = !isPlaybackMode ? 'visible' : 'none';
      if (mapApis.getLayer('static-monitors')) {
        mapApis.setLayoutProperty('static-monitors', 'visibility', visibilityStatus);
      }
    }
  }, [mapApis, isPlaybackMode]);

  const [selectedMonitorData, setSelectedMonitorData] = useState<IMapData[]>([]);
  useEffect(() => {
    const selectedMonitorArray: any = [];
    if (noiseMonitors.length > 0) {
      if (noiseEventData.length > 0) {
        noiseMonitors.map(monitorData => {
          const { position, id } = monitorData;
          const matchedEvents = noiseEventData.filter(noiseData => noiseData.locationId === id);
          if (matchedEvents.length > 1) {
            matchedEvents.sort((a, b) => b.maxLevel - a.maxLevel);
          }
          if (matchedEvents.length > 0) {
            const maxLv = matchedEvents[0].maxLevel;
            selectedMonitorArray.push({
              type: 'Feature',
              properties: {
                id,
                noiseLevel: typeof maxLv === 'number' ? maxLv : Number(maxLv),
                dbString: typeof maxLv === 'number' ? maxLv.toFixed(1) : maxLv,
                valid: true,
              },
              geometry: {
                type: 'Point',
                coordinates: [position.longitude, position.latitude],
              },
            });
          }
        });
      }
    }
    setSelectedMonitorData(selectedMonitorArray);
  }, [noiseEventData, noiseMonitors, mapApis]);

  useEffect(() => {
    if (mapApis && !isPlaybackMode) {
      const staticMonitorsSrc = mapApis.getSource('static-monitors');
      if (!staticMonitorsSrc) {
        mapApis.addSource('static-monitors', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: selectedMonitorData,
          },
        });
      } else {
        staticMonitorsSrc.setData({
          type: 'FeatureCollection',
          features: selectedMonitorData,
        });
      }

      if (mapApis.getLayer('static-monitors') === undefined) {
        mapApis.addLayer({
          id: 'static-monitors',
          source: 'static-monitors',
          type: 'circle',
          paint: {
            'circle-color': [
              'step',
              ['get', 'noiseLevel'],
              sassVars['noisedb-30-35'],
              30,
              sassVars['noisedb-30-35'],
              35,
              sassVars['noisedb-35-40'],
              40,
              sassVars['noisedb-40-45'],
              45,
              sassVars['noisedb-45-50'],
              50,
              sassVars['noisedb-50-55'],
              55,
              sassVars['noisedb-55-60'],
              60,
              sassVars['noisedb-60-65'],
              65,
              sassVars['noisedb-65-70'],
              70,
              sassVars['noisedb-70-75'],
              75,
              sassVars['noisedb-75-80'],
              80,
              sassVars['noisedb-80-plus'],
            ],
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              6,
              ['*', 0.5, ['interpolate', ['linear'], ['get', 'noiseLevel'], 45, 4, 120, 16]],
              11,
              ['interpolate', ['linear'], ['get', 'noiseLevel'], 45, 4, 120, 16],
              16,
              ['*', 2.2, ['interpolate', ['linear'], ['get', 'noiseLevel'], 45, 4, 120, 16]],
            ],
          },
        });
      }
    }
  }, [mapApis, isPlaybackMode, selectedMonitorData]);

  return selectedMonitorData;
};

export const useNoiseMonitorPlayback = ({
  mapApis,
  noiseMonitors,
  noiseData,
  processedNoiseData,
  currentTime,
  isPlaybackMode,
  showNMTDefault = false,
}: INoiseMonitorPlayback) => {
  const [noiseMonitorData, setNoiseMonitorData] = useState<IMapData[]>([]);
  const [selectedMonitorData, setSelectedMonitorData] = useState<IFeatureData[]>([]);

  useEffect(() => {
    if (!noiseData.length) {
      setSelectedMonitorData([]);
    }
  }, [noiseData]);

  useEffect(() => {
    if (processedNoiseData.length && noiseMonitors.length) {
      const selectedMonitorArray: any = [];
      noiseMonitors.map(monitorData => {
        const { position, id, ...remainingData } = monitorData;
        const matchedEvents = processedNoiseData.filter(data => data.locationId === id);
        if (matchedEvents.length > 1) {
          matchedEvents.sort((a, b) => Number(b.maxLevel) - Number(a.maxLevel));
        }
        if (matchedEvents.length > 0) {
          const {
            continuousStart: startTime,
            continuousEnd: endTime,
            stitched: noiseData,
            maxLevel: maxLevel,
          } = matchedEvents[0];
          const maxLevel1dp = typeof maxLevel === 'number' ? maxLevel.toFixed(1) : maxLevel;

          selectedMonitorArray.push({
            type: 'Feature',
            properties: {
              id,
              startTime: DateTime.fromISO(startTime).toSeconds(),
              endTime: DateTime.fromISO(endTime).toSeconds(),
              noiseLevel: Number(maxLevel),
              dbString: maxLevel1dp,
              samples: noiseData,
              valid: false,
              ...remainingData,
            },
            geometry: {
              type: 'Point',
              coordinates: [position.longitude, position.latitude],
            },
          });
        }
      });
      setSelectedMonitorData(selectedMonitorArray);
    }
  }, [processedNoiseData, noiseMonitors, mapApis]);

  useEffect(() => {
    if (noiseMonitors.length > 0) {
      const noiseMonitorArray: any = [];
      noiseMonitors.map(monitorData => {
        const { position, ...remainingData } = monitorData;
        noiseMonitorArray.push({
          type: 'Feature',
          properties: {
            ...remainingData,
          },
          geometry: {
            type: 'Point',
            coordinates: [position.longitude, position.latitude],
          },
        });
      });

      setNoiseMonitorData(noiseMonitorArray);
    }
  }, [noiseMonitors, mapApis]);

  useEffect(() => {
    if (mapApis) {
      const noiseMonitorSrc = mapApis.getSource('noiseMonitors');
      if (!noiseMonitorSrc) {
        mapApis.addSource('noiseMonitors', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: noiseMonitorData,
          },
        });
      } else {
        noiseMonitorSrc.setData({
          type: 'FeatureCollection',
          features: noiseMonitorData,
        });
      }

      if (mapApis.getLayer('noiseMonitors') === undefined) {
        mapApis.addLayer({
          id: 'noiseMonitors',
          source: 'noiseMonitors',
          type: 'symbol',
          paint: {
            'icon-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 12, 1],
          },
          layout: {
            visibility: showNMTDefault ? 'visible' : 'none',
            'icon-image': 'noise_monitor',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-size': ['interpolate', ['linear'], ['zoom'], 8, 0.25, 12, 0.5],
          },
        });
      }
    }
  }, [noiseMonitorData]);

  useEffect(() => {
    if (mapApis && selectedMonitorData.length) {
      const selectedMonitorSrc = mapApis.getSource('selectedMonitors');
      if (!selectedMonitorSrc) {
        mapApis.addSource('selectedMonitors', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: selectedMonitorData,
          },
        });
      } else {
        selectedMonitorSrc.setData({
          type: 'FeatureCollection',
          features: selectedMonitorData,
        });
      }

      if (mapApis.getLayer('selectedMonitors') === undefined) {
        mapApis.addLayer({
          id: 'selectedMonitors',
          source: 'selectedMonitors',
          type: 'circle',
          paint: {
            'circle-color': [
              'step',
              ['get', 'noiseLevel'],
              sassVars['noisedb-30-35'],
              30,
              sassVars['noisedb-30-35'],
              35,
              sassVars['noisedb-35-40'],
              40,
              sassVars['noisedb-40-45'],
              45,
              sassVars['noisedb-45-50'],
              50,
              sassVars['noisedb-50-55'],
              55,
              sassVars['noisedb-55-60'],
              60,
              sassVars['noisedb-60-65'],
              65,
              sassVars['noisedb-65-70'],
              70,
              sassVars['noisedb-70-75'],
              75,
              sassVars['noisedb-75-80'],
              80,
              sassVars['noisedb-80-plus'],
            ],
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              6,
              ['*', 0.5, ['interpolate', ['linear'], ['get', 'noiseLevel'], 45, 4, 120, 16]],
              11,
              ['interpolate', ['linear'], ['get', 'noiseLevel'], 45, 4, 120, 16],
              16,
              ['*', 2.2, ['interpolate', ['linear'], ['get', 'noiseLevel'], 45, 4, 120, 16]],
            ],
          },
          filter: ['==', 'valid', true],
        });
      }
    }
  }, [selectedMonitorData]);

  const updateMonitorNoiseLevel = () => {
    const selectedMonitors = [...selectedMonitorData];
    if (selectedMonitors.length) {
      const monitorIds: number[] = [];
      selectedMonitors.forEach((monitor: any, index: number) => {
        const convertedPosition = Math.floor(currentTime - monitor.properties.startTime);
        const sampleLength = monitor.properties.samples ? monitor.properties.samples.length : 0;
        const validPosition = convertedPosition >= 0 && convertedPosition < sampleLength;
        const isValid =
          validPosition && isPlaybackMode && monitor.properties.samples[convertedPosition] !== null;
        monitor.properties.valid = isValid;
        if (isValid) {
          const currentNoiseLevel = monitor.properties.samples[convertedPosition];
          const noiseLevelString = currentNoiseLevel ? currentNoiseLevel.toFixed(1) : null;
          monitor.properties.noiseLevel = currentNoiseLevel;
          monitor.properties.dbString = noiseLevelString;
          monitorIds.push(monitor.properties.id);
        }
      });

      if (monitorIds.length) {
        mapApis.setFilter('noiseMonitors', ['!in', 'id', ...monitorIds]);
      } else {
        mapApis.setFilter('noiseMonitors', null);
      }
    }

    setSelectedMonitorData(selectedMonitors);
  };

  useEffect(() => {
    if (isPlaybackMode) {
      updateMonitorNoiseLevel();
    }

    if (mapApis) {
      const mapApiSelectedMonitorSrc = mapApis.getSource('selectedMonitors');

      if (mapApiSelectedMonitorSrc !== undefined) {
        mapApiSelectedMonitorSrc.setData({
          type: 'FeatureCollection',
          features: selectedMonitorData,
        });
      }

      // If it's not in playback mode, reset the noise monitor filter
      if (!isPlaybackMode) {
        if (mapApis.getLayer('noiseMonitors') !== undefined) {
          mapApis.setFilter('noiseMonitors', null);
        }
      }
    }
  }, [currentTime, isPlaybackMode]);

  // NM for Noise Monitor
  const [isNMAdded, updateNMVisibility] = useState<boolean>(false);

  useEffect(() => {
    if (mapApis && !isNMAdded) {
      const nmtVisibility = showNMTDefault && !isPlaybackMode ? 'visible' : 'none';

      if (mapApis.getLayer('noiseMonitors')) {
        mapApis.setLayoutProperty('noiseMonitors', 'visibility', nmtVisibility);
      }

      updateNMVisibility(true);
    }
  }, [mapApis, showNMTDefault, isPlaybackMode]);

  return selectedMonitorData;
};

export const useStaticTrackDisplay = ({
  mapApis,
  dataSources,
  highlightIds,
}: {
  mapApis: any;
  dataSources: any[];
  highlightIds: number[];
}) => {
  const [dataArray, setDataArray] = useState<IMapData[]>([]);
  const [trackData, setTrackData] = useState<IFeatureData[]>([]);

  useEffect(() => {
    const setupArray: IMapData[] = [];
    for (const item of dataSources) {
      if (item) {
        const { points, ...otherElements } = item;
        const convertedPoints = convertToSecondPoints(points);
        setupArray.push({
          ...otherElements,
          startTime: DateTime.fromISO(item.startTime).toSeconds(),
          endTime: DateTime.fromISO(item.endTime).toSeconds(),
          dataPoints: convertedPoints,
        });
      }
    }
    setDataArray(setupArray);
  }, [dataSources]);

  useEffect(() => {
    const tracks: IFeatureData[] = [];

    dataArray.map((data: IMapData) => {
      const { dataPoints, ...remainingData } = data;

      tracks.push({
        type: 'Feature',
        properties: {
          ...remainingData,
          selected: highlightIds.findIndex(id => id === data.id) !== -1,
        },
        geometry: {
          type: 'LineString',
          coordinates: dataPoints,
        },
      });
    });

    setTrackData(tracks);
  }, [dataArray, highlightIds]);

  useEffect(() => {
    if (mapApis) {
      const tracksSrc = mapApis.getSource('select-flight-tracks');
      if (!tracksSrc) {
        mapApis.addSource('select-flight-tracks', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: trackData,
          },
        });
      } else {
        tracksSrc.setData({
          type: 'FeatureCollection',
          features: trackData,
        });
      }

      if (mapApis.getLayer('select-flight-tracks') === undefined) {
        mapApis.addLayer({
          id: 'select-flight-tracks',
          source: 'select-flight-tracks',
          type: 'line',
          paint: {
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              8,
              ['case', ['==', ['get', 'selected'], true], 1.5, 0.75],
              11,
              ['case', ['==', ['get', 'selected'], true], 4, 2],
              14,
              ['case', ['==', ['get', 'selected'], true], 6, 3],
            ],
            'line-opacity': ['case', ['==', ['get', 'selected'], true], 1, 0.6],
            'line-color': [
              'case',
              ['==', ['get', 'selected'], true],
              sassVars.brand01,
              ['==', ['get', 'operationType'], 'Departure'],
              sassVars.departure,
              ['==', ['get', 'operationType'], 'Arrival'],
              sassVars.arrival,
              ['==', ['get', 'operationType'], 'Overflight'],
              sassVars.overflight,
              ['==', ['get', 'operationType'], 'TouchAndGo'],
              sassVars.touchGo,
              ['==', ['get', 'operationType'], 'Miscellaneous'],
              sassVars.touchGo,
              'hsl(0, 100%, 100%)',
            ],
          },
        });
      }
    }
  }, [mapApis, trackData]);

  // return;
};
