import React, { FC, useEffect, useState, useCallback, useContext } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
// selectors
import {
  useConfigSelectors,
  useFilterDataSelectors,
  useLanguageSelectors,
  useRolesSelectors,
} from 'src/app/reducers';
import { useDataSelectors } from 'src/@infringements/reducers/dataReducer';
import { useSortSelectors } from 'src/@infringements/reducers/sortReducer';
import { InfringementDispatchContext } from 'src/@infringements/providers/InfringementsStateProvider';
// actions
import { selectRow, loadMore, setCandidatesEnabled } from 'src/@infringements/actions';
import { dataActionTypes } from 'src/@infringements/newActionTypes';
// components
import { Table, Icons } from '@ems/client-design-system';
import { LoadMoreBar } from 'src/components';
// function
import { convertObjectKeys } from 'src/utils/objectModifiers';
import { formatHeaders, formatInfringementData } from 'src/@infringements/functions';
import { UPDATE_INFRINGEMENT_STATUS } from 'src/@infringements/mutations';
import { IInfringementsData, ITableContainer } from 'src/@infringements/interfaces';
import { filterStore } from 'src/@infringements/stores/filterStore';
import { hideInfringementColumns } from 'src/utils/tableColumns';

export const TableContainer: FC<ITableContainer> = ({ fromCorrelated = false }) => {
  const client = useApolloClient();
  const dispatcher = useContext<any>(InfringementDispatchContext);
  const dataSelectors = useDataSelectors();
  const rolesSelectors = useRolesSelectors();
  const canUpdate = rolesSelectors.hasPermission('Infringement.Update');
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const {
    data: latestData,
    pageInfo,
    isLoading,
    selectedData,
    areAllRowsSelected,
    isLoadingMore,
  } = dataSelectors.getDataInformation();
  const [updateInfringementStatus] = useMutation(UPDATE_INFRINGEMENT_STATUS, {
    update(cache, { data: { updateInfringement } }) {
      const id = updateInfringement.id;
      const status = updateInfringement.status;

      dispatcher({
        type: dataActionTypes.INLINE_EDIT_STATUS,
        data: {
          id,
          status,
          candidatesEnabled: lightSwitchState,
        },
      });
    },
  });

  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { '12HourFormat': twelveHourFormat },
    grid: { resultSize },
    infringements: {
      grid: { statusColors, hiddenColumns },
    },
  } = configSelectors.getConfig();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const translationStrings = languageSelectors.getLanguage();
  const {
    components: {
      buttons: { loadMore: loadMoreText },
      labels: {
        table: { endTable },
      },
      hints: { tryChangingFilters },
      lists: {
        aircraftCategories,
        candidates,
        operationTypes,
        ruleTypes,
        severities,
        infringementStatuses,
      },
    },
    screens: {
      infringements: {
        errors: { noDataFound },
      },
    },
  } = translationStrings;

  const [translationDataList, setTranslationDataList] = useState<object[]>([]);
  useEffect(() => {
    setTranslationDataList(
      convertObjectKeys({
        ...aircraftCategories,
        ...operationTypes,
        ...ruleTypes,
        ...severities,
        ...infringementStatuses,
        ...candidates,
      })
    );
  }, [aircraftCategories, operationTypes, ruleTypes, severities, infringementStatuses, candidates]);
  const loading = isLoading || isLoadingMore;

  // Always 'show' all columns, then hide the ones we don't need.
  const columns = [
    'displayRuleName',
    'displayTime',
    'displayInfringement',
    'displaySeverity',
    'acid',
    'displayCategory',
    'aircraftType',
    'displayStatus',
    'displayCandidates',
  ];

  const hiddenColumnsNonNull = hiddenColumns ? hiddenColumns : [];
  const showSeverity = !hiddenColumnsNonNull.includes('displaySeverity');
  const showCandidates = !hiddenColumnsNonNull.includes('displayCandidates') && !fromCorrelated;
  const showStatus = !hiddenColumnsNonNull.includes('displayStatus');
  // column types are used to add class-names to each column (used only for styling)
  // TODO: this logic needs to be handled in the design system, not here. We'll refactor when the table needs to be modified.
  const columnTypes = {
    rule: {
      title: 'rule',
      abbreviation: '',
    },
    acid: {
      title: 'record-title',
      abbreviation: '',
    },
    displayRunwayName: {
      title: 'runway',
      abbreviation: 'Rwy',
    },
    displayCategory: {
      title: 'aircraft',
      abbreviation: 'Categ',
    },
    displayTime: {
      title: 'time',
      abbreviation: '',
    },
    displayStatus: {
      title: 'status',
      abbreviation: '',
    },
    displayInfringement: {
      title: 'infringement',
      abbreviation: '',
      grow: showSeverity ? null : '2',
    },
    displaySeverity: {
      title: 'severity',
      abbreviation: '',
      grow: '2',
    },
    aircraftType: {
      title: 'model',
      abbreviation: '',
      grow: '3',
    },
    displayCandidates: {
      title: 'candidates',
      abbreviation: '',
    },
  };

  const handleInlineDropdown = useCallback((infringementId, newStatus, infringementType) => {
    updateInfringementStatus({
      variables: { id: infringementId, status: newStatus },
      optimisticResponse: {
        __typename: infringementType,
        updateInfringement: {
          id: infringementId,
          status: newStatus,
          __typename: infringementType,
        },
      },
    });
  }, []);

  const onSelectRow = id => {
    selectRow(id, dispatcher);
  };

  hideInfringementColumns({
    showSeverity,
    showCandidates,
    showStatus,
  });

  const [statusItems, setStatusItems] = useState<any[]>([]);
  const filtersSelectors = useFilterDataSelectors();
  const infringementFilterData = filtersSelectors.getInfringementFilterData();

  useEffect(() => {
    const items: any[] = [];
    const statusOptions = infringementFilterData.statuses;
    statusOptions.map((key: string) => {
      const option = {
        key,
        label: translationDataList[key] ? translationDataList[key] : key,
        icon: <Icons iconName="ic-ui-circle" fill={statusColors[key]} />,
      };
      items.push(option);
    });
    setStatusItems(items);
  }, [translationDataList]);

  // Candidates
  const [candidateFlag, setCandidateFlag] = useState<boolean>(false);
  const lightSwitchState = dataSelectors.getCandidatesEnabled();
  const handleCandidatesChange = bool => {
    setCandidateFlag(bool);
    setCandidatesEnabled(client, resultSize, dispatcher, sortString, bool); // fetch the next page
  };
  const ruleFilterApplied = filterStore.isFilterApplied('ruleNames');

  const [displayData, setDisplayData] = useState<IInfringementsData[]>([]);
  const { itemsIds, hasNextPage, endCursor, dateRange } = dataSelectors.getNavigationData();
  const ruleInfo = { itemsIds, hasNextPage, endCursor, dateRange };
  useEffect(() => {
    setDisplayData(
      formatInfringementData(
        latestData,
        translationDataList,
        statusItems,
        handleInlineDropdown,
        ruleInfo,
        canUpdate,
        lightSwitchState,
        twelveHourFormat
      )
    );
  }, [latestData, statusItems, translationDataList, handleInlineDropdown]);

  return (
    <>
      <Table
        className={`infringements-table infringements-table--regular`}
        loading={isLoading || isLoadingMore}
        rowHeaders={formatHeaders(
          resultSize,
          loading,
          dispatcher,
          sortSelectors,
          lightSwitchState,
          handleCandidatesChange,
          ruleFilterApplied,
          translationStrings
        )}
        data={displayData}
        columns={columns}
        columnTypes={columnTypes}
        selectedData={selectedData}
        areAllRowsSelected={areAllRowsSelected}
        gridID="infringements"
        onSelectRow={onSelectRow}
        hasEnded={displayData.length && pageInfo && !pageInfo.hasNextPage}
        languageData={{ noDataTitle: noDataFound, noDataText: tryChangingFilters, endTable }}
      />
      <LoadMoreBar
        isVisible={pageInfo && pageInfo.hasNextPage ? true : false}
        isLoadingMore={isLoadingMore ? true : false}
        loadMore={loadMore}
        dispatcher={dispatcher}
        sortString={sortString}
        resultSize={resultSize}
        endCursor={pageInfo && pageInfo.endCursor}
        loadMoreText={loadMoreText}
        showCandidates={candidateFlag}
      />
    </>
  );
};
