import React, { useContext, useState, useEffect, FC } from 'react';
// components
import { Spinner, Table } from '@ems/client-design-system';
import { AirlineMappingContainer } from 'src/@settings/containers';
// functions
import {
  formatHeaders,
  getFilteredAirlineMappings,
  updateTableIds,
  getSortData,
} from 'src/@settings/functions';
// providers
import { SettingsDispatchContext } from '../provider/SettingsStateProvider';
// reducers
import { useSortSelectors, useFleetMixSelector } from 'src/@settings/reducers';
import { useLanguageSelectors } from 'src/app/reducers';
// actions
import { updateCurrentAirline, updateCurrentAirlineMappings } from 'src/@settings/actions';
// libraries
import uuid from 'uuid';
// interfaces
import { IAirlineItem, IAirlineMapping, IFleetMixContainer } from 'src/@settings/interfaces';
// constants
import { FLEETMIX_MODULE } from 'src/constants';

export const FleetMixContainer: FC<IFleetMixContainer> = ({
  setChangesAvailable,
  areChangesDiscarded,
  updateFleetMixSettings,
  discardMutationData,
}) => {
  // dispatchers
  const dispatcher = useContext(SettingsDispatchContext);
  // selectors
  const sortSelector = useSortSelectors();
  const fleetMixSelector = useFleetMixSelector();
  const languageSelectors = useLanguageSelectors();
  const currentAirline = fleetMixSelector.getCurrentAirline();
  const sortObject = sortSelector.getSortObject(FLEETMIX_MODULE);
  const sortString = sortSelector.getSortString(FLEETMIX_MODULE);
  const {
    components: {
      info: { fleetMixInfo },
    },
    fields: { fleetMix: {
      airline: airlineColumnHeader,
      numberOfFlights: numberOfFlightsColumnHeader
    } },
  } = languageSelectors.getLanguage();

  const fleetMixColumns:string[] = ['airline', 'numberOfFlights'];
  const fleetMixColumnHeaders = {
    'airline': airlineColumnHeader,
    'numberOfFlights': numberOfFlightsColumnHeader
  };
  const fleetMixColumnTypes = {
    airline: {
      title: 'airline',
      abbreviation: '',
    },
    numberOfFlights: {
      title: 'numberOfFlights',
      abbreviation: '',
      formatter: 'formatNumberToString',
      fractionDigits: 0,
    }
  };

  // variables
  const airlines: IAirlineItem[] = fleetMixSelector.getAirlines();
  const [selectedAirline, setSelectedAirline] = useState<string>(currentAirline);

  const airlineMappings: IAirlineMapping[] = fleetMixSelector.getAirlineMappings();
  const loading: boolean = fleetMixSelector.getIfLoading();
  // state
  const [rowData, setRowData] = useState<IAirlineItem[]>(airlines);
  const [clickedRow, setClickedRow] = useState<number>(0);

  useEffect(() => {
    const sortData = getSortData(airlines, sortObject);
    setRowData(updateTableIds([], sortData));
  }, [airlines]);

  useEffect(() => {
    const sortData = getSortData(airlines, sortObject);
    setRowData(updateTableIds([], sortData));
  }, [sortString]);

  const onRowClick = event => {
    const rowIndex = event.currentTarget.rowIndex;
    const rowData = event.currentTarget.innerText;
    updateMappings(rowData);
    if (rowIndex) {
      updateHighlightRow(rowIndex);
    }
  };

  const updateMappings = rowData => {
    const selectedAirline = rowData ? rowData.split('\n')[1] : null;
    setSelectedAirline(selectedAirline);
    if (selectedAirline) {
      updateCurrentAirlineMappings(
        getFilteredAirlineMappings(selectedAirline, airlineMappings),
        dispatcher
      );
      updateCurrentAirline(selectedAirline, dispatcher);
    }
  };

  const updateHighlightRow = rowIndex => {
    const updatedData: any = [];
    airlines.forEach(item => {
      const i = Object.assign({}, item, { tableId: uuid.v4() });
      updatedData.push(i);
    });
    setRowData(updatedData);
    setClickedRow(rowIndex - 1);
  };

  const getSpinnerComponent = () => {
    return <div className="spinner-loading"> <Spinner loading={true} size="l" centered={true} /> </div>;
  };

  const getFleetMixComponent = () => {
    return (
      <div className={'fleet-mix-wrapper'}>
        <div className="airlines">
          <Table
            className="fleet-mix-table"
            data={rowData}
            columns={fleetMixColumns}
            rowHeaders={formatHeaders(dispatcher, sortSelector, fleetMixColumnHeaders, FLEETMIX_MODULE)}
            gridID={'fleet-mix'}
            columnTypes={fleetMixColumnTypes}
            onRowClick={onRowClick}
            clickedRow={clickedRow}
          />
        </div>
        <AirlineMappingContainer
          airline={selectedAirline}
          setChangesAvailable={setChangesAvailable}
          areChangesDiscarded={areChangesDiscarded}
          updateFleetMixSettings={updateFleetMixSettings}
          discardMutationData={discardMutationData}
        />
      </div>);
  };

  return (
    <>
      <p className={'fleetmix-info'}>{fleetMixInfo}</p>
      { loading ? getSpinnerComponent() : getFleetMixComponent()}
    </>
  );
};
