import React, { FC } from 'react';
import cx from 'classnames';

import { IPageHeaderDetails } from './interfaces';

export const PageHeaderDetails: FC<IPageHeaderDetails> = ({
  children,
  title,
  subtitle = '05 Oct 1999 hello',
  showReturnButton = false,
  returnToButton,
}) => (
  <>
    <section className={cx('page-header page-header_navigation')}>
      {showReturnButton ? <div className="page-header_details-button">{returnToButton}</div> : null}
    </section>
    <section className={cx('page-header page-header_details')}>
      <div>
        <div className="page-header_title">{title}</div>
        <div className="page-header_subtitle">{subtitle}</div>
      </div>
      {children}
    </section>
  </>
);
