// client
import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import {
  airlineMappingsSchema,
  airlineMappingsQuery,
} from './schema';

export const fetchAirlines = (client: ApolloClient<object>, scenarioIds: number[]) => {
  return new Promise((resolve, reject) => {
    const queryParams = `scenarioIds: ${scenarioIds} `;
    const GET_AIRLINE_MAPPINGS = gql`
    query {
      ${airlineMappingsQuery} (${queryParams}) ${airlineMappingsSchema}
    }`;
    client
      .query({
        query: GET_AIRLINE_MAPPINGS,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data[airlineMappingsQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
