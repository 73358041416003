import React, { useState } from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { NoiseEventsStateProvider } from 'src/@noiseEvents/providers/NoiseEventsStateProvider';
import {
  HeaderContainer,
  DataContainer,
  MapPlaybackContainer,
  TableContainer,
} from 'src/@noiseEvents/containers';
import { FilterContainer } from 'src/@noiseEvents/containers';
import { SplitLayout } from 'src/components/Layout';

const NoiseEventsScreen = ({ location, updateUrl }) => {
  const locationState = location.state;
  const fromCorrelated =
    locationState && locationState.hasOwnProperty('fromCorrelated')
      ? locationState.fromCorrelated
      : false;
  const correlatedIds = fromCorrelated ? location.state.ids : [];
  const [isPlayingBack, setPlayback] = useState<boolean>(false); // local state used to disable export while playing back noise

  return (
    <SplitLayout>
      <NoiseEventsStateProvider>
        <DataContainer correlatedIds={correlatedIds}>
          <MapPlaybackContainer playbackModeUpdated={isPlayingBack => setPlayback(isPlayingBack)} />
          <div className="container-fluid">
            <div className="header-container">
              <HeaderContainer
                updateUrl={updateUrl}
                fromCorrelated={fromCorrelated}
                isPlayingBack={isPlayingBack}
              />
            </div>
            <FilterContainer />
            <TableContainer />
          </div>
        </DataContainer>
      </NoiseEventsStateProvider>
    </SplitLayout>
  );
};

export const NoiseEvents = withRouteProps(NoiseEventsScreen);
