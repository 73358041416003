import gql from 'graphql-tag';

export const UPDATE_AIRCRAFT_FUEL_MAPPINGS = gql`
  mutation updateAircraftFuelMappings($aircraftFuelMappings: [BulkAircraftFuelMappingChangeInput], $scenarioId: Int , $startTime: DateTimeOffset, $endTime: DateTimeOffset) {
    updateAircraftFuelMappings(aircraftFuelMappings: $aircraftFuelMappings) {
      airline
      aircraftType
      engine
      factorCO2
      percentage
      comment
      scenarioId
      id
    }
    carbonEmissionsRequestReprocessing(scenarioId: $scenarioId, startTime: $startTime, endTime: $endTime)
  }
`;
