import { keyToActionTypes } from 'src/utils/keyToActionTypes';

export const actionTypes = keyToActionTypes(
  {
    SORT_TABLE: null,
    GET_AIRLINES: null,
    UPDATE_CURRENT_AIRLINE_MAPPINGS: null,
    UPDATE_AIRLINE_MAPPINGS: null,
    UPDATE_CURRENT_AIRLINE: null,
    GET_AIRCRAFT_MAPPINGS: null,
    UPDATE_AIRCRAFT_MAPPINGS: null,
    GET_TIME_IN_MODES: null,
    GET_LOAD_FACTORS: null,
    GET_AIRCRAFT_FUEL_MAPPINGS: null,
    SORT_FLEET_MIX_TABLE: null,
    SORT_LOAD_FACTORS_TABLE: null,
    SORT_TIME_IN_MODES_TABLE: null,
    SORT_FUEL_TYPES_TABLE: null,
    SORT_AIRLINE_MAPPING_TABLE: null,
    AIRCRAFT_FUEL_MAPPINGS_LOADING: null,
    AIRCRAFT_MAPPINGS_LOADING: null,
    AIRLINES_LOADING: null,
    LOAD_FACTORS_LOADING: null,
    TIME_IN_MODES_LOADING: null,
    GET_LTO_MAPPINGS: null,
    GET_ENROUTE_MAPPINGS: null,
    GET_AIRCRAFT_FLIGHTS: null,
    SHOW_MAPPED: null,
    GET_AIRPORTS: null,
  },
  'SETTINGS'
);
