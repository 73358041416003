// client
import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import {
  aircraftMappingsQuery,
  aircraftMappingsSchema,
  ltoMappingsQuery,
  ltoMappingsSchema,
  enrouteMappingsQuery,
  enrouteMappingsSchema,
  aircraftFlightsQuery,
  aircraftFlightsSchema,
} from './schema';

export const fetchAircraftMappings = (client: ApolloClient<object>, scenarioIds: number[]) => {
  return new Promise((resolve, reject) => {
    const queryParams = `scenarioIds: ${scenarioIds}`;
    const GET_AIRCRAFT_MAPPINGS = gql`
    query {
      ${aircraftMappingsQuery} (${queryParams}) ${aircraftMappingsSchema}
    }`;
    client
      .query({
        query: GET_AIRCRAFT_MAPPINGS,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data[aircraftMappingsQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchLTOMappings = (client: ApolloClient<object>) => {
  return new Promise((resolve, reject) => {
    const GET_LTO_MAPPINGS = gql`
    query {
      ${ltoMappingsQuery} ${ltoMappingsSchema}
    }`;
    client
      .query({
        query: GET_LTO_MAPPINGS,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data[ltoMappingsQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchEnrouteMappings = (client: ApolloClient<object>) => {
  return new Promise((resolve, reject) => {
    const GET_ENROUTE_MAPPINGS = gql`
    query {
      ${enrouteMappingsQuery} ${enrouteMappingsSchema}
    }`;
    client
      .query({
        query: GET_ENROUTE_MAPPINGS,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data[enrouteMappingsQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchAircraftFlights = (client: ApolloClient<object>, startTime: string, endTime: string) => {
  return new Promise((resolve, reject) => {
    const queryParams = `startTime: "${startTime}", endTime: "${endTime}"`;
    const GET_AIRCRAFT_FLIGHTS = gql`
    query {
      ${aircraftFlightsQuery} (${queryParams}) ${aircraftFlightsSchema}
    }`;
    client
      .query({
        query: GET_AIRCRAFT_FLIGHTS,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data[aircraftFlightsQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};