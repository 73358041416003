import React, { FC, useContext, useRef, useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useMutation } from '@apollo/react-hooks';
// providers
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
import { AirtrakDispatchContext } from 'src/@airtrak/providers/AirtrakStateProvider';
// selectors
import { useDataSelectors, useViewSelector } from 'src/@airtrak/reducers';
import { useLanguageSelectors, useScenariosSelectors } from 'src/app/reducers';
// components
import { PageHeaderDropdown, DateFilter } from 'src/components';
import { Button, Filter, formatNumberToString, Icons, IFilterItem } from '@ems/client-design-system';

// actions
import {
  updateMetric,
  cleanUpData,
  updateStatus,
  updateView,
  updateBreakdown,
  setIsExport,
} from 'src/@airtrak/actions';
import { appActionTypes } from 'src/app/actionTypes';
// constants
import {
  TOTAL_METRIC,
  AIRTRAK,
  PER_MOVEMENT_METRIC,
  PER_PASSENGER_METRIC,
  PER_PASSENGER_KILOMETER_METRIC,
  TOTAL_MOVEMENTS,
  TOTAL_PASSENGERS,
  TMA_EFFICIENCY,
  LINE,
  PIE,
  BAR,
  MANAGE_CONFIGURATIONS_MODULE,
  CARBON_EMISSION_ROUNDING_PRECISION,
} from 'src/constants';
// functions
import { getExportData, getExportFileName } from 'src/@airtrak/functions';
import { getFormattedMutationItem } from 'src/@settings/functions';
import { withQueryStringUpdater } from 'src/app/hocs/withQueryStringUpdater';
import { convertMapToArray } from 'src/utils';
// interfaces
import { IHeaderContainer } from 'src/@operations/props';
import { IScenario } from 'src/app/props';
import { IMutationItem } from 'src/@settings/interfaces';
// mutations
import { UPDATE_SCENARIOS } from 'src/@settings/mutations';

export const HeaderContainer: FC<IHeaderContainer> = ({ updateUrl }) => {
  // dispatcher
  const dispatcher = useContext(AirtrakDispatchContext);
  const appDispatcher: any = useContext(GlobalDispatchContext);

  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();
  const viewSelector = useViewSelector();
  const view = viewSelector.getView();
  const dataSelectors = useDataSelectors();
  const unitLabel = dataSelectors.getCarbonEmissionDisplayUnit();
  const emissionSummary = dataSelectors.getEmissionSummary();
  const rowGrouping = dataSelectors.getRowGrouping();
  const itemsMap = dataSelectors.getTableData();
  const { items } = convertMapToArray(itemsMap);
  const footerData = dataSelectors.getFooterData();
  const exportData = getExportData(items, footerData);
  const metric = dataSelectors.getMetric();
  const {
    components: {
      lists: { metrics },
      hints: { noMatchesFound },
      labels: {
        emissions,
        movements,
        passengers,
        activeConfig: activeConfiguration,
        filters: { clear: clearValue, filter: filterValue },
      },
    },
  } = translationData;

  const exportTableRef = useRef(null);
  const fileName = getExportFileName(rowGrouping, view);

  const setMetricOnClick = ({ key }) => {
    cleanUpData(dispatcher);
    updateStatus(dispatcher);
    updateMetric(key, dispatcher);
    if (key === TMA_EFFICIENCY) {
      updateBreakdown('None', dispatcher);
      updateView('line', dispatcher);
    }
  };

  const pageHeaders = [
    {
      key: TOTAL_METRIC,
      label: metrics.GreenhouseGasTotal,
    },
    {
      key: PER_MOVEMENT_METRIC,
      label: metrics.GreenhouseGasPerMovement,
    },
    {
      key: PER_PASSENGER_METRIC,
      label: metrics.GreenhouseGasPerPassenger,
    },
    {
      key: PER_PASSENGER_KILOMETER_METRIC,
      label: metrics.GreenhouseGasPerPassengerKilometer,
    },
    {
      key: TMA_EFFICIENCY,
      label: metrics.TerminalManagementAreaEfficiencyTotal,
    },
  ];

  const scenariosSelector = useScenariosSelectors();
  const activeScenario: IScenario = scenariosSelector.getActiveScenario();
  const scenarioList: IScenario[] = scenariosSelector.getScenarios();

  // state
  const [selectedConfig, setSelectedConfig] = useState<IScenario>(activeScenario);
  const [mutationData, setMutationData] = useState<Map<number, IMutationItem>>(new Map());
  
  const DateFilterHOC = withQueryStringUpdater(DateFilter, updateUrl, AIRTRAK, activeScenario);
  const getSummaryValue = measure => {
    if (measure === PER_MOVEMENT_METRIC) {
      return (
        emissionSummary.length > 0 ?
        emissionSummary.filter(summary => summary.measure === PER_MOVEMENT_METRIC)[0].value : 0
      );
    }
    if (measure === PER_PASSENGER_METRIC) {
      return (
        emissionSummary.length > 0 ?
        emissionSummary.filter(summary => summary.measure === PER_PASSENGER_METRIC)[0].value : 0
      );
    }
    if (measure === PER_PASSENGER_KILOMETER_METRIC) {
      return (
        emissionSummary.length > 0 ?
        emissionSummary.filter(summary => summary.measure === PER_PASSENGER_KILOMETER_METRIC)[0]
          .value : 0
      );
    }
    if (measure === TOTAL_MOVEMENTS) {
      return (
        emissionSummary.length > 0 ?
        emissionSummary.filter(summary => summary.measure === TOTAL_MOVEMENTS)[0].value : 0
      );
    }
    if (measure === TOTAL_PASSENGERS) {
      return (
        emissionSummary.length > 0 ?
        emissionSummary.filter(summary => summary.measure === TOTAL_PASSENGERS)[0].value : 0
      );
    }
    return (
      emissionSummary.length > 0 ?
      emissionSummary.filter(summary => summary.measure === TOTAL_METRIC)[0].value : 0
    );
  };

  const handleIsExport = () => {
    if (view === LINE) {
      setIsExport(LINE, true, dispatcher);
      setTimeout(() => {
        setIsExport(LINE, false, dispatcher);
      }, 1000);
    } else if (view === PIE) {
      setIsExport(PIE, true, dispatcher);
      setTimeout(() => {
        setIsExport(PIE, false, dispatcher);
      }, 1000);
    } else if (view === BAR) {
      setIsExport(BAR, true, dispatcher);
      setTimeout(() => {
        setIsExport(BAR, false, dispatcher);
      }, 1000);
    } else {
      // @ts-ignore
      exportTableRef.current.link.click();
    }
  };

  const [updateScenarios] = useMutation(UPDATE_SCENARIOS, {
    update() {},
  });

  const handleMutation = () => {
    const { items } = convertMapToArray(mutationData);
    updateScenarios({
      variables: {
        scenarios: items,
      },
    })
      .then(({ data }) => {
        appDispatcher({
          type: appActionTypes.UPDATE_SCENARIOS,
          payload: data.updateScenarios,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getScenarioById = (id: number | undefined) => {
    return scenarioList.filter(scenario => scenario.id === id);
  };

  const onScenarioSelection = (item: IFilterItem[]) => {
    const presentActiveScenario = getScenarioById(activeScenario.id);
    const newSelectedScenario = getScenarioById(parseInt(item[0].key, 10));
    if (activeScenario.id && item[0].key !== activeScenario.id.toString()) {
      setMutationData(
        getFormattedMutationItem('Update', Object.assign({}, ...presentActiveScenario, { isActive: false }), mutationData, MANAGE_CONFIGURATIONS_MODULE)
      );
      setMutationData(
        getFormattedMutationItem('Update', Object.assign({}, ...newSelectedScenario, { isActive: true }), mutationData, MANAGE_CONFIGURATIONS_MODULE)
      );
      setSelectedConfig(Object.assign({}, ...newSelectedScenario, { isActive: true }));
      handleMutation();
    }
  };

  const getScenarioFilterItems = (scenarios: IScenario[] ) => {
    const configs = scenarios.map(scenario => ({
      key: scenario.id ? scenario.id.toString() : "",
      label: scenario.name,
    }));
    return configs;
  };

  useEffect(() => {
    setSelectedConfig(activeScenario);
  }, [activeScenario]);

  return (
    <div>
      <div className="airtrak-header-container">
        <div className="header-left-items">
          <div className="page-header-dropdown">
            <PageHeaderDropdown items={pageHeaders} headerUpdated={setMetricOnClick} />
          </div>
          <Filter
            categoryName={activeConfiguration}
            filterItems={getScenarioFilterItems(scenarioList)}
            selectedItems={getScenarioFilterItems([selectedConfig])}
            updateItems={(items: IFilterItem[]) => onScenarioSelection(items)}
            type={'single'}
            languageData={{ clearValue, filterValue, noMatchesFound }}
          />
          {metric !== TMA_EFFICIENCY && (
            <div className="emission-summary-container">
              <span className="emission-summary-item">
                {emissions}: {formatNumberToString(getSummaryValue(metric), CARBON_EMISSION_ROUNDING_PRECISION)} ({unitLabel})
            </span>
              <span className="emission-summary-item">
                {passengers}: {formatNumberToString(getSummaryValue(TOTAL_PASSENGERS), 0)}
              </span>
              <span className="emission-summary-item">
                {movements}: {formatNumberToString(getSummaryValue(TOTAL_MOVEMENTS), 0)}
              </span>
            </div>
          )}
        </div>
        <div className="header-right-items">
          <Button
            size="m"
            style="primary"
            leftIcon={<Icons iconName={`ic-ui-export`} size="20" />}
            disabled={rowGrouping.length ? false : true}
            onClick={() => {
              handleIsExport();
            }}>
            Export
        </Button>
          <CSVLink
            data={exportData}
            filename={fileName}
            target="_blank"
            ref={exportTableRef}
            style={{ display: 'none' }}>
            download
        </CSVLink>
          <DateFilterHOC />
        </div>
      </div>
    </div>
  );
};
