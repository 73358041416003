import React, { FC, useRef, useEffect } from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
// components
import { MinLineGraph } from '@ems/client-design-system';
// props
import { IProfileGraph } from './interfaces';
// functions
import { useGraphReftoCaptureImage } from 'src/app/functions/export';
import { useConfigSelectors } from 'src/app/reducers';
// Constants
import { THEME_DEFAULT_OPERATIONS_THEME, THEME_DEFAULT_OPERATION_TYPE } from 'src/constants';
// Utils
import { getOperationTypeColor } from 'src/utils';

// Include the 60 seconds either side of the Infringing Operation time and render them on the 1 second sample graph.
export const ProfileGraph: FC<IProfileGraph> = ({
  data,
  currentTime,
  shouldDisplay,
  startTime,
  endTime,
  operationType,
  markedTime,
  onHoverCB = () => {},
  displayStartTime,
  displayEndTime,
  displayBuffer = 0,
  isPlaybackMode = false,
  isPlaybackRunning = false,
  selectedTrackTheme = THEME_DEFAULT_OPERATIONS_THEME,
}) => {
  // used for taking screenshot of map
  const captureRef = useRef<HTMLDivElement | null>(null);
  // capture graph image
  useGraphReftoCaptureImage({
    captureRef,
    isGraphAvailable: typeof data !== 'undefined' && data.length,
    image: 'altitudeImage',
  });

  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  const lineColor = getOperationTypeColor(
    selectedTrackTheme,
    operationType || THEME_DEFAULT_OPERATION_TYPE
  );

  useEffect(() => {
    if (captureRef && captureRef.current) {
      const chartElement = captureRef.current.querySelector('.chart');
      if (chartElement && chartElement.getAttribute('style')) {
        chartElement.setAttribute('style', '');
      }
    }
  });

  if (typeof data === 'undefined' || !data.length) {
    // make sure we have the required data
    return null;
  }

  const convertedStart = DateTime.fromISO(startTime, { setZone: true }).toSeconds();
  const convertedEnd = DateTime.fromISO(endTime, { setZone: true }).toSeconds();

  const convertedDisplayStart = displayStartTime
    ? DateTime.fromISO(displayStartTime, { setZone: true }).toSeconds() - displayBuffer
    : null;
  const convertedDisplayEnd = displayEndTime
    ? DateTime.fromISO(displayEndTime, { setZone: true }).toSeconds() + displayBuffer
    : null;
  const timezone = DateTime.fromISO(startTime, { setZone: true }).zoneName;

  return (
    <div
      ref={captureRef}
      className={cx('noise-graph_container', {
        'noise-graph_container--hide': !shouldDisplay,
      })}>
      <div className="noise-graph__spacer" />
      <MinLineGraph
        data={data}
        count={currentTime}
        startTime={convertedStart}
        endTime={convertedEnd}
        markedTime={markedTime}
        onHoverCB={onHoverCB}
        displayStartTime={convertedDisplayStart}
        displayEndTime={convertedDisplayEnd}
        timezone={timezone}
        units={units}
        lineColor={lineColor}
      />
    </div>
  );
};
