// functions
import { assignDropdown, assignTextInput } from 'src/@settings/functions';
import { AIRCRAT_MODEL_SEPERATOR } from 'src/constants';
// libraries
import uuid from 'uuid';

export const formatFleetMixData = (
  data,
  aircraftOptions,
  ltoOptions,
  enrouteOptions,
  translationData,
  updateFleetMix
) => {
  const formattedAirlineMappings = new Array();
  let index = 0;
  for (const fleetMix of data) {
    const fleetMixObj = { ...fleetMix };
    fleetMixObj.numberOfAircraft = assignTextInput(
      index,
      translationData,
      'numberOfAircraft',
      fleetMixObj.numberOfAircraft,
      updateFleetMix,
      '^[0-9]*$'
    );
    fleetMixObj.aircraftType = assignDropdown(
      index,
      fleetMix,
      aircraftOptions,
      translationData,
      'aircraftType',
      fleetMixObj.aircraftType,
      updateFleetMix
    );
    fleetMixObj.mappedLTOAircraft = assignDropdown(
      index,
      fleetMix,
      ltoOptions,
      translationData,
      'mappedLTOAircraft',
      fleetMixObj.mappedLTOAircraft,
      updateFleetMix
    );
    fleetMixObj.mappedEnrouteAircraft = assignDropdown(
      index,
      fleetMix,
      enrouteOptions,
      translationData,
      'mappedEnrouteAircraft',
      fleetMixObj.mappedEnrouteAircraft,
      updateFleetMix
    );
    formattedAirlineMappings.push(fleetMixObj);
    index = index + 1;
  }
  return formattedAirlineMappings;
};

export const getFilteredAirlineMappings = (airline, airlineMappings) => {
  return airlineMappings.filter(item => item.airline === airline);
};

export const updateTableIds = (updatedData, data) => {
  data.forEach(({ ...item }) => {
    updatedData.push({
      ...item,
      tableId: uuid.v4(),
    });
  });
  return updatedData;
};


export const getFormattedFleetMixMutationRecord = record => {
  const enrouteModel = record.mappedEnrouteAircraft.split(AIRCRAT_MODEL_SEPERATOR);
  const ltoModel = record.mappedLTOAircraft.split(AIRCRAT_MODEL_SEPERATOR);
  return {
    id: record.id,
    scenarioId: record.scenarioId,
    mappedEnrouteAircraft: enrouteModel[0] ? enrouteModel[0].trim() : '',
    aircraftType: record.aircraftType,
    mappedEnrouteEngine: enrouteModel[1] ? enrouteModel[1].trim() : '',
    mappedLTOAircraft: ltoModel[0] ? ltoModel[0].trim() : '',
    airline: record.airline,
    numberOfAircraft: parseInt(record.numberOfAircraft, 10),
    mappedLTOEngine: ltoModel[1] ? ltoModel[1].trim() : '',
  };
};
