import React, { createContext, useReducer } from 'react';
import { IStoreState } from 'src/@operations/props';
import { dataInitialState, dataReducer } from 'src/@operations/reducers/dataReducer';
import { sortInitialState, sortReducer } from 'src/@operations/reducers/sortReducer';
import { filterInitialState, filterReducer } from 'src/@operations/reducers/filterReducer';
// import { useReducerWithLogger } from 'src/utils/storeHelpers';

export const OperationStateContext = createContext({
  data: dataInitialState,
  sort: sortInitialState,
});

export const OperationDispatchContext = createContext(null);

export const OperationsStateProvider = ({ children }) => {
  const [dataState, dataDispatch]: any = useReducer<any>(dataReducer, dataInitialState);
  const [sortState, sortDispatch]: any = useReducer<any>(sortReducer, sortInitialState);
  const [filterState, filterDispatch]: any = useReducer<any>(filterReducer, filterInitialState);

  const state: IStoreState = {
    data: dataState,
    sort: sortState,
    filter: filterState,
  };

  const dispatchers = [dataDispatch, sortDispatch, filterDispatch];

  const dispatch: any = (action: any) =>
    dispatchers.map((dispatcher: any) => {
      dispatcher(action);
    });

  return (
    <OperationStateContext.Provider value={state}>
      <OperationDispatchContext.Provider value={dispatch}>
        {children}
      </OperationDispatchContext.Provider>
    </OperationStateContext.Provider>
  );
};
