import React, { createContext, useReducer } from 'react';
import { IStoreState } from 'src/@reports/interfaces';
import { dataInitialState, dataReducer } from 'src/@reports/reducers/dataReducer';
import { sortInitialState, sortReducer } from 'src/@reports/reducers/sortReducer';
import { filterInitialState, filterReducer } from 'src/@reports/reducers/filterReducer';

export const ReportsStateContext = createContext({
  data: dataInitialState,
});

export const ReportsDispatchContext = createContext(null);

export const ReportsStateProvider = ({ children }) => {
  const [dataState, dataDispatch] = useReducer(dataReducer, dataInitialState);
  const [sortState, sortDispatch] = useReducer(sortReducer, sortInitialState);
  const [filterState, filterDispatch] = useReducer(filterReducer, filterInitialState);

  const state: IStoreState = {
    data: dataState,
    sort: sortState,
    filter: filterState,
  };

  const dispatchers = [dataDispatch, sortDispatch, filterDispatch];

  const dispatch: any = (action: any) =>
    dispatchers.map((dispatcher: any) => {
      dispatcher(action);
    });

  return (
    <ReportsStateContext.Provider value={state}>
      <ReportsDispatchContext.Provider value={dispatch}>{children}</ReportsDispatchContext.Provider>
    </ReportsStateContext.Provider>
  );
};
