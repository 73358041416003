import React from 'react';
import { withRouter } from 'react-router';
import cx from 'classnames';
// components
import { NavBar } from 'src/components/Navbar';

const SimpleSidebarLayoutScreen = props => {
  return (
    <NavBar subMenuLinkSelected={props.subMenuLinkSelected}>
      <div
        className={cx('page-wrapper', {
          'simple-sidebar-layout': true,
        })}>
        {props.children}
      </div>
    </NavBar>
  );
};

export const SimpleSidebarLayout = withRouter(SimpleSidebarLayoutScreen);
