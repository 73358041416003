import React from 'react';
import cx from 'classnames';
import { MapPopup } from '@ems/client-design-system';

import './styles.scss';
import { linearInterpolateValue } from 'src/utils';

export const NoisePopup = ({ zoomLevel, monitorData }: any) => {
  if (!monitorData || !monitorData.properties.valid) {
    return null;
  }

  // 8px base offset + scaled based on the radius of the noise monitor circle
  const zoomScale = linearInterpolateValue(0.5, 2.2, 10, zoomLevel - 6);
  const circleRadius = linearInterpolateValue(4, 16, 75, monitorData.properties.noiseLevel - 45);
  const offsetLeft = zoomScale * (4 + circleRadius);

  return (
    <MapPopup
      className={cx('noise-popup', {
        'noise-popup--large': zoomLevel >= 13.5,
        'noise-popup--small': zoomLevel <= 8.5,
      })}
      latitude={monitorData.geometry.coordinates[1]}
      longitude={monitorData.geometry.coordinates[0]}
      closeButton={false}
      closeOnClick={false}
      offsetLeft={offsetLeft}
      anchor="left">
      <div className="noise-popup_db">{monitorData.properties.dbString}</div>
    </MapPopup>
  );
};
