import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { RestLink } from 'apollo-link-rest';
import { setContext } from 'apollo-link-context';
// config
import { anomsApi, mapLayerApi } from 'src/config';
// utils
import { getDeployedProductId, alert } from 'src/utils';
import { renewToken, processAuthResponse, signOut } from 'src/app/functions/core';
import { getAuth, removeAuth } from 'src/app/functions/storage';
// introspection Query
import introspectionQueryResultData from 'src/fragmentTypes.json';

// for config please look @ https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-fetchPolicy
const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });
const getUri = (): string => `${anomsApi}${getDeployedProductId()}`;

let refetch = false;

export const createClient = ({ authorization, refreshToken, refreshTokenExpiry }): any => {
  let requestNewAccess: {
    refreshToken: string;
    refreshTokenExpiry: number;
  } = {
    refreshToken,
    refreshTokenExpiry,
  };

  const customFetch: any = (uri, options) => {
    let refreshingPromise;

    return fetch(uri, options).then((response: any) => {
      if (response && response.status === 401) {
        if (!refreshingPromise) {
          const { refreshToken, refreshTokenExpiry } = requestNewAccess;
          if (refreshToken) {
            refreshingPromise = new Promise((resolve, reject) => {
              console.info('-> request to renew token...');
              renewToken({ refreshToken, refreshTokenExpiry })
                .then(response => {
                  resolve(response);
                })
                .catch((error: any) => {
                  reject(error);
                });
            })
              .then(response => {
                const { authorization, refreshToken, refreshTokenExpiry } = processAuthResponse(
                  response
                );
                if (authorization) {
                  requestNewAccess = {
                    refreshToken,
                    refreshTokenExpiry,
                  };
                  return authorization;
                } else {
                  console.error(`Error 401.001`, 'signOut');
                  signOut('401');
                }
              })
              .catch(() => {
                console.error(`Error 401.002`, 'signOut');
                signOut('401');
              });
          } else {
            console.error(`Error 401.003`, 'signOut');
            signOut('401');
          }
        }
        return refreshingPromise.then((authorization: string) => {
          refreshingPromise = undefined;
          options.headers.Authorization = authorization;
          return fetch(uri, options);
        });
      } else if (response && response.status === 400) {
        alert('500');
      } else if (response && response.status === 403) {
        // user specified a site/product that do not have permission to access
        console.error(`Error 403.001`, 'signOut');
        signOut('403');
      } else if (response && response.status === 404) {
        // user specified a site/product that does not exist and tried to log in
        console.error(`Error 404.001`, 'signOut');
        signOut('404');
      } else if (response && response.status === 500) {
        // user specified a site/product that does not exist and tried to log in
        console.error(`Error 500.001`);
        // 500 error that usually occurs on the very first call to get currentUserPermissions
        // Will have a log for the backend to see why this is happening. Could be the data caching...
        const { body } = options;
        if (typeof body !== 'undefined' && body && !refetch) {
          console.info('-> refetching the required data...');
          refetch = true;
          return fetch(uri, options);
        } else {
          alert('500');
        }
      } else if (response && response.status === 200 && refetch) {
        refetch = false;
      }
      return response;
    });
  };

  const authLink = setContext((_, { headers }) => {
    const authData = getAuth();
    // check if logout has already performed in another active tab then invalidate the token that is stored in memory
    if (!authData || authData.authorization !== authorization) {
      authorization = null;
      removeAuth();
      signOut('401');
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: authorization,
      },
    };
  });

  const httpLink = createHttpLink({
    uri: getUri(),
    fetch: customFetch,
  });

  const restLink = new RestLink({
    uri: `${mapLayerApi}${getDeployedProductId()}/`, // default
    endpoints: {
      mapLayer: `${mapLayerApi}${getDeployedProductId()}/`,
    },
  });

  return new ApolloClient({
    cache,
    link: ApolloLink.from([authLink, restLink, httpLink]),
    defaultOptions,
    connectToDevTools: true,
  });
};
