// stores
import { configStore } from 'src/app/stores/configStore';
// luxon
import DateTime from 'luxon/src/datetime.js';
import {
  TABLE_TWELVE_HOURS_FORMAT,
  TABLE_TWENTYFOUR_HOURS_FORMAT,
  TABLE_TWELVE_HOURS_SHORT_FORMAT,
  TABLE_TWENTYFOUR_HOURS_SHORT_FORMAT,
  TABLE_TWELVE_HOURS_SECONDS_FORMAT,
  TABLE_TWENTYFOUR_HOURS_SECONDS_FORMAT,
  TABLE_TWENTYFOUR_HOURS_YEARS_FORMAT,
  TABLE_TWELVE_HOURS_YEARS_FORMAT,
} from 'src/constants';
import { IFilterItem } from '@ems/client-design-system';

// main dateTime format used for the date picker or date values in the queries
// "yyyy-LL-dd'T'TTZZ" -> output ISO format will be 'YYYY-MM-DDTHH:mm:ssZ'
export const dateTimeFormat = "yyyy-LL-dd'T'TTZZ";
export const dateFormat = 'yyyy-LL-dd';
export const dayFormat = 'dd';
export const monthFormat = 'mm';

const startDayFormat = "yyyy-LL-dd'T00:00:00'";
const endDayFormat = "yyyy-LL-dd'T23:59:59'";
const startMonthFormat = "yyyy-LL-'01T00:00:00'";
const endMonthFormat = "yyyy-LL-'{dd}T00:00:00'";

// get the intended date to calculate start and end
export const dateToLocalDay = (dateObject: Date, timezone: string) => {
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();

  let localday = DateTime.local(year, month, day).setZone(timezone);

  if (localday.day !== day) {
    // if not equal it would be a day before or after
    if (localday.minus({ days: 1 }).day === day) {
      localday = localday.minus({ days: 1 });
    } else if (localday.plus({ days: 1 }).day === day) {
      localday = localday.plus({ days: 1 });
    }
  }
  return localday;
};

/**
 * All DateTime query parameters follow the same universal date format YYYY-MM-DDTHH:mm:ssZ
 * "2018-12-31T00:00:00-5:00"
 * "2018-12-31-5:00"
 */
export const dateTimeInQuery = (dateObject: Date, type: null | 'start' | 'end' = null): string => {
  if (!type) {
    return format(dateObject, dateTimeFormat);
  }

  // get the intended date to calculate start and end
  const localday = dateToLocalDay(dateObject, configStore.getTimeZone());

  if (type === 'start') {
    return localday.startOf('day').toISO();
  }
  if (type === 'end') {
    return localday.endOf('day').toISO();
  }
  return localday.toISO();
};

/**
 * Get day in local date/time
 * day: today or yesterday
 * type: start or finish
 */
export const getDay = (
  unit: 'day' | 'month',
  timeOfDay: 'start' | 'end',
  count: number = 0
): Date => {
  let dateString = '';
  let date = DateTime.local().setZone(configStore.getTimeZone());
  if (unit === 'day') {
    if (count !== 0) {
      date = date.plus({ days: count });
    }
    dateString =
      timeOfDay === 'start' ? date.toFormat(startDayFormat) : date.toFormat(endDayFormat);
  } else if (unit === 'month') {
    if (count !== 0) {
      date = date.plus({ months: count });
    }
    dateString =
      timeOfDay === 'start'
        ? date.toFormat(startMonthFormat)
        : date.toFormat(endMonthFormat.replace('{dd}', `${date.daysInMonth}`));
  }
  return new Date(dateString);
};

/**
 * Get day in local date/time
 * date: string e.g. "2018-12-31"
 */
//
export const getDayFromDateString = (dateString: string, timeOfDay: 'start' | 'end'): Date => {
  const data = dateString.split('-');
  const date = DateTime.local(Number(data[0]), Number(data[1]), Number(data[2]));
  const selected =
    timeOfDay === 'start' ? date.toFormat(startDayFormat) : date.toFormat(endDayFormat);
  return new Date(selected);
};

/**
 * format a date object and convert to string
 */
// Luxon datetime formatting: https://moment.github.io/luxon/docs/manual/formatting.html
export const format = (dateObject: Date, stringFormat: string, locale: string = ''): string => {
  return locale
    ? DateTime.fromJSDate(dateObject)
      .setLocale(locale)
      .toFormat(stringFormat)
    : DateTime.fromJSDate(dateObject, { setZone: true }).toFormat(stringFormat);
};

// formatter: number of seconds (in a day) to 'hh:mm' | 'hh:mm:ss'
export const secToTime = (sec: number, format: 'hh:mm' | 'hh:mm:ss') => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;
  const hourStr = hours < 10 ? `0${hours}` : `${hours}`;
  const minStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return format === 'hh:mm' ? `${hourStr}:${minStr}` : `${hourStr}:${minStr}:${secStr}`;
};

export const formatFromISO = (date: Date, stringFormat: string) => {
  return DateTime.fromISO(date, { zone: configStore.getTimeZone() }).toFormat(stringFormat);
};

// time difference between two datetime object in seconds
export const timeDiffInSec = (firstDate: string, secondDate: string): number => {
  const dateA = DateTime.fromISO(firstDate, { zone: configStore.getTimeZone() });
  const dateB = DateTime.fromISO(secondDate, { zone: configStore.getTimeZone() });
  const { milliseconds } = dateA.diff(dateB).toObject();
  return Number((milliseconds / 1000).toFixed(0));
};

// Add a period of time to date
export const timePlusMinusDuration = (
  date: string,
  params: { years?: number; hours?: number; minutes?: number; seconds?: number }
) => {
  return DateTime.fromISO(date, { zone: configStore.getTimeZone() })
    .plus(params)
    .toISO();
};

/*
 * get format Time for display
 * format example: 20/5/19 9:39 or 1/2/01 0:04
 */
export const tableDateTimeFormat = (time: string | null, twelveHourFormat: boolean = false) => {
  if (!time) {
    return time;
  }
  const format = twelveHourFormat ? TABLE_TWELVE_HOURS_FORMAT : TABLE_TWENTYFOUR_HOURS_FORMAT;
  return DateTime.fromISO(time, { setZone: true }).toFormat(format);
};

export const tableDateTimeWithSecondsFormat = (
  time: string | null,
  twelveHourFormat: boolean = false
) => {
  if (!time) {
    return time;
  }

  const format = twelveHourFormat
    ? TABLE_TWELVE_HOURS_SECONDS_FORMAT
    : TABLE_TWENTYFOUR_HOURS_SECONDS_FORMAT;

  return DateTime.fromFormat(time, "yyyy-MM-dd'T'HH:mm:ssZZ", { setZone: true }).toFormat(format);
};

export const tableDateTimeFormatLong = (time: string | null, twelveHourFormat: boolean = false) => {
  if (!time) {
    return time;
  }

  const format = twelveHourFormat
    ? TABLE_TWELVE_HOURS_YEARS_FORMAT
    : TABLE_TWENTYFOUR_HOURS_YEARS_FORMAT;

  return DateTime.fromISO(time, { setZone: true }).toFormat(format);
};

export const tableDateTimeFormatShort = (
  time: string | null,
  twelveHourFormat: boolean = false
) => {
  if (!time) {
    return time;
  }

  const format = twelveHourFormat
    ? TABLE_TWELVE_HOURS_SHORT_FORMAT
    : TABLE_TWENTYFOUR_HOURS_SHORT_FORMAT;

  return DateTime.fromISO(time, { setZone: true }).toFormat(format);
};

export const tooltipTimeFormat = (time: string) => {
  if (!time) {
    return time;
  }

  return DateTime.fromISO(time, { setZone: true }).toFormat('HH:mm:ss');
};

export const mapDateTimeFormat = (time: string) => {
  if (!time) {
    return time;
  }
  return DateTime.fromFormat(time, 'yyyy-MM-dd HH:mm:ss').toFormat('HH:mm MMM dd');
};

export const tableDateTimeWithSecondsFormatShort = (
  time: string | null,
  twelveHourFormat: boolean = false
) => {
  if (!time) {
    return time;
  }

  const format = twelveHourFormat
    ? TABLE_TWELVE_HOURS_SECONDS_FORMAT
    : TABLE_TWENTYFOUR_HOURS_SECONDS_FORMAT;

  return DateTime.fromFormat(time, "yyyy-MM-dd'T'HH:mm:ssZZ", { setZone: true }).toFormat(format);
};

/*
 * Converts date object to dd/mm/yyyy
 */
export const stringToEngFormat = (date: string): any => {
  const data = date.split('T');
  const dateArray = data[0].split('-');
  return `${addZero(Number(dateArray[2]))}/${addZero(Number(dateArray[1]))}/${addZero(
    Number(dateArray[0])
  )}`;
};

export const localeStringToEngFormat = (date: string): any => {
  const data = date.split(', ');
  const dateArray = data[0].split('/');
  return `${addZero(Number(dateArray[2]))}-${addZero(Number(dateArray[0]))}-${addZero(
    Number(dateArray[1])
  )}`;
};

// min and max dates used in the application
// min = January 01, 2019 or as defined by minDate
// max = end of today
// Set min to Jan 01, 2019 until a safer way to check can be made
export const getDatePickerRange = (type: 'min' | 'max'): Date => {
  if (type === 'min') {
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 20;
    return new Date(`${minYear}-01-01`);
  } else {
    return getDay('day', 'end');
  }
};

/*
 * Converts date object to yyyy-mm-dd
 * example date -> 2017-02-01T00:00:00.000Z -> 2017-02-01
 */
export const dateToStringYMd = (dateObject: Date): string => {
  const localday = dateToLocalDay(dateObject, configStore.getTimeZone());
  return `${localday.toFormat(dateFormat)}`;
};

export const addZero = (num: number): string => {
  return num < 10 ? `0${num}` : `${num}`;
};

// add x number of seconds to either side of a time window
export const addPaddingToDuration = ({
  seconds,
  startTime,
  endTime,
}: {
  seconds: number;
  startTime: string | null;
  endTime: string | null;
}): { startTime: string; endTime: string; duration: number } => {
  return {
    startTime: DateTime.fromISO(startTime, { setZone: true })
      .minus({ seconds })
      .toISO({ suppressMilliseconds: true, includeOffset: true }),
    endTime: DateTime.fromISO(endTime, { setZone: true })
      .plus({ seconds })
      .toISO({ suppressMilliseconds: true, includeOffset: true }),
    duration:
      DateTime.fromISO(endTime, { setZone: true }).toSeconds() -
      DateTime.fromISO(startTime, { setZone: true }).toSeconds() +
      2 * seconds,
  };
};

export const appBuildDateTime = (date: Date) => {
  return DateTime.fromJSDate(date)
    .setZone('Australia/Melbourne')
    .toFormat('DDD, HH:mm');
};

export const getDateRange = (
  startMonthIndex: number,
  startYear: number,
  endMonthIndex: number,
  endYear: number
) => {
  let monthIndex = startMonthIndex;
  let yearIndex = startYear;
  const dates: IFilterItem[] = [];

  while (monthIndex !== endMonthIndex || yearIndex !== endYear) {
    const ISODate: string = DateTime.utc(yearIndex, ++monthIndex, 1).toISO();
    dates.push({
      key: formatDateString(ISODate, 'yyyy-MM-dd'),
      label: formatDateString(ISODate, 'LLLL yyyy'),
      icon: '',
      className: '',
    });

    if (monthIndex === 12) {
      yearIndex++;
      monthIndex = 0;
    }
  }
  return dates;
};

export const formatDateString = (dateString: string, format: string) => {
  const formattedDate = DateTime.fromISO(dateString, { setZone: configStore.getTimeZone() }).toFormat(format);
  return formattedDate;
};
