import React, { FC, useState, useEffect, useContext, useMemo, ReactElement } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
// providers
import { OperationDispatchContext } from 'src/@operations/providers/OperationsStateProvider';
// actions
import { fetchData, resetDataAndMap } from 'src/@operations/actions';
// selectors
import {
  useRolesSelectors,
  useConfigSelectors,
  useLanguageSelectors,
  useFilterDataSelectors,
} from 'src/app/reducers';
import { useSortSelectors, useDataSelectors, useFilterSelectors } from 'src/@operations/reducers';
// hoc
import { withAvailabilityChecks } from 'src/app/hocs/withPermissionsCheck';
import { withQueryStringUpdater } from 'src/app/hocs/withQueryStringUpdater';
// containers
import { ExportContainer } from 'src/containers/ExportContainer';
// components
import { Button, Dropdown, Icons, Overlay, SkeletonText } from '@ems/client-design-system';
import { DateFilter, PageHeader } from 'src/components';
// mutations
import { UPDATE_OPERATIONS } from 'src/@operations/mutations';
// functions
import { createSelectItems } from 'src/@operations/functions';
import { formatNumber, convertObjectKeys } from 'src/utils';
// props
import { IHeaderContainer } from 'src/@operations/props';
// constants
import { OPERATIONS } from 'src/constants';
import { DATA_EXPORT, OPERATION_TAGS } from 'src/app/featureToggles';
import { usePermissions } from 'src/app/functions/permissions';

export const HeaderContainer: FC<IHeaderContainer> = ({ updateUrl }) => {
  const client = useApolloClient();
  const dispatcher = useContext(OperationDispatchContext);

  const configSelectors = useConfigSelectors();
  const {
    grid: { resultSize },
    operations: {
      filter: { nullable },
      pca: { maxAltitudeRange, maxHorizontalRange },
      availableOperationTags,
    },
  } = configSelectors.getConfig();
  const readOnlyFields = configSelectors.getOperationReadOnlyFields();

  const sortSelectors = useSortSelectors();
  const rolesSelectors = useRolesSelectors();
  const canUpdate = rolesSelectors.hasPermission('Operation.Update');
  const sortString = sortSelectors.getSortString();
  const filterSelectors = useFilterSelectors();
  const filterString = filterSelectors.getFilterString();
  const usingPca = filterSelectors.getIfUsingPcaFilter();
  const pcaPosition = filterSelectors.getPcaPosition();
  const [overlayState, setoverlayState] = useState(false);
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [selectedItems, setselectedItems]: any = useState({});
  const { canRead: infringementsRead } = usePermissions('Infringement');
  const { canRead: noiseEventsRead } = usePermissions('NoiseEvent');
  const { canRead: complaintsRead } = usePermissions('Complaint');
  const FEATURE_FLAG_OPERATION_TAGS = configSelectors.isFeatureAvailable(OPERATION_TAGS);
  const filterDataSelectors = useFilterDataSelectors();
  const operationFilterData = filterDataSelectors.getOperationsFilterData();

  const [updateOperationsCall] = useMutation(UPDATE_OPERATIONS, {
    onCompleted() {
      fetchData({
        client,
        resultSize,
        dispatcher,
        sortString,
        filterString,
        pcaData: usingPca
          ? {
              position: pcaPosition,
              altRange: maxAltitudeRange,
              horizRange: maxHorizontalRange,
            }
          : undefined,
        permissions: {
          infringements: infringementsRead,
          noiseEvents: noiseEventsRead,
          complaints: complaintsRead,
        },
        featureFlags: {
          operationTags: FEATURE_FLAG_OPERATION_TAGS,
        },
        availableFilters: {
          operationTags: availableOperationTags,
        },
      });
    },
  });
  const [itemPlaceholders, setitemPlaceholders]: any = useState({});

  const [operationsMapping, setoperationsMapping]: any = useState({
    operationType: [],
    airportId: [],
    remoteAirportId: [],
    runwayName: [],
    aircraftCategory: [],
    operatorCategory: [],
    aircraftType: [],
  });

  const pluralizeOperations = {
    operationType: 'operationTypes',
    airportId: 'airportIds',
    remoteAirportId: 'remoteAirportIds',
    runwayName: 'runwayNames',
    aircraftCategory: 'aircraftCategories',
    operatorCategory: 'operatorCategories',
    aircraftType: 'aircraftTypes',
  };

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { infringements: dropdownLabel }, // Why is this infringements when we are in operations?
    components: {
      buttons: { update, cancel, bulkEdit },
      labels: {
        table: { totalItems },
      },
      dropdowns: { multiple, empty, unknown },
      lists: { aircraftCategories, operatorCategories, operationTypes, extraFilterValues },
      overlay: {
        bulkEdit: {
          operations: {
            update: overlayTitle1,
            operation: overlayTitle2,
            operations: overlayTitle2Plural,
          },
        },
      },
    },
    screens: {
      operations: { title },
    },
  } = languageSelectors.getLanguage();

  const dataSelectors = useDataSelectors();
  const { data } = dataSelectors.getDataInformation();
  const totalCount = dataSelectors.getTotalCount();
  const selectedIds = dataSelectors.getSelectedIDs();

  useEffect(() => {
    const dataItems = {};
    Object.keys(operationsMapping).map(item => {
      const filterData = operationFilterData[pluralizeOperations[item]];
      if (!filterData) {
        console.warn(`operationFilterData.${pluralizeOperations[item]} is ${filterData}; check your filter JSON file`);
        return;
      }
      Object.assign(dataItems, {
        [item]: createSelectItems(
          filterData,
          item,
          nullable && nullable.includes(pluralizeOperations[item]),
          translatedList
        ),
      });
    });
    setoperationsMapping(dataItems);
  }, []);

  const DateFilterHOC = withQueryStringUpdater(DateFilter, updateUrl);

  const translatedList = convertObjectKeys({
    ...aircraftCategories,
    ...operatorCategories,
    ...operationTypes,
    ...extraFilterValues,
  });

  const updateOperations = selectedData => {
    const operationsToUpdate: number[] = [];
    const variablesToUpdate: any = {};

    selectedData.map((id: number) => {
      operationsToUpdate.push(id);
    });

    Object.keys(selectedItems).map(item => {
      if (selectedItems[item] !== undefined) {
        Object.assign(variablesToUpdate, { [item]: selectedItems[item].key });
      }
    });

    updateOperationsCall({
      variables: {
        ids: operationsToUpdate,
        ...variablesToUpdate,
      },
    });

    handleOverlayClose();
    resetDataAndMap(dispatcher);
  };

  // TODO: When user wants to bulk edit, some items may not be available in the data store (as they may have been selected from the map)
  // so bulk edit dialog to show if values are the same, empty or multiple would not be accurate
  const handleOverlayState = (bool: boolean) => {
    const defaultSelectItems: any = {};
    const itemPlaceholders: any = {};
    const operationsToUpdate: any = {
      operationType: [],
      airportId: [],
      remoteAirportId: [],
      runwayName: [],
      aircraftCategory: [],
      operatorCategory: [],
      aircraftType: [],
    };
    const hasUnknowns = {
      operationType: false,
      airportId: false,
      remoteAirportId: false,
      runwayName: false,
      aircraftCategory: false,
      operatorCategory: false,
      aircraftType: false,
    };

    selectedIds.map((id: number) => {
      Object.keys(operationsMapping).map(key => {
        if (data.has(id)) {
          operationsToUpdate[key].push(data.get(id)[key]);
        } else {
          hasUnknowns[key] = true;
        }
      });
    });

    Object.keys(operationsMapping).map(key => {
      const isTheSame = operationsToUpdate[key].every((val, i, arr) => val === arr[0]);

      if (!isTheSame) {
        Object.assign(itemPlaceholders, { [key]: multiple });
      } else {
        if (operationsToUpdate[key][0] === null) {
          Object.assign(itemPlaceholders, { [key]: empty });
        } else {
          Object.assign(itemPlaceholders, { [key]: unknown });
        }

        if (typeof operationsToUpdate[key][0] === 'string' && !hasUnknowns[key]) {
          const selected = operationsMapping[key].filter(obj => {
            return obj.key === operationsToUpdate[key][0];
          });

          if (selected.length) {
            Object.assign(defaultSelectItems, { [key]: selected[0] });
          }
        }
      }
    });

    setitemPlaceholders(itemPlaceholders);
    setselectedItems(defaultSelectItems);
    setoverlayState(bool);
  };

  const dropdowns: ReactElement[] = useMemo(() => {
    const components: ReactElement[] = [];
    const categoryList = [
      'operationType',
      'airportId',
      'remoteAirportId',
      'runwayName',
      'aircraftCategory',
      'operatorCategory',
      'aircraftType',
    ];
    const nullableItems = ['remoteAirportId'];
    categoryList.map(cat => {
      if (readOnlyFields.findIndex(e => e === cat) === -1) {
        components.push(
          <Dropdown
            key={dropdownLabel[cat]}
            label={dropdownLabel[cat]}
            placeholderValue={itemPlaceholders[cat]}
            searchItems={operationsMapping[cat]}
            isNullable={nullableItems.findIndex(e => e === cat) !== -1}
            updateSelection={item => handleUpdateState(item, cat)}
            selectedItem={selectedItems[cat]}
          />
        );
      }
    });
    return components;
  }, [readOnlyFields, dropdownLabel, itemPlaceholders, operationsMapping, selectedItems]);

  const handleOverlayClose = () => {
    setoverlayState(false);
    setselectedItems({});
    setbuttonDisabled(true);
  };

  const handleUpdateState = (item: any, key: any) => {
    if (
      selectedItems[key] !== undefined &&
      selectedItems[key] !== null &&
      item.label !== selectedItems[key].label
    ) {
      setbuttonDisabled(false);
    } else if (selectedItems[key] === undefined || selectedItems[key] === null) {
      setbuttonDisabled(false);
    } else {
      setbuttonDisabled(true);
    }

    setselectedItems(Object.assign({}, selectedItems, { [key]: item }));
  };

  // when no item selected, then action button should be disabled
  const disableBulkEditBtn = selectedIds.length >= 1 ? false : true;
  // data export feature
  const DataExportFeature = useMemo(
    () =>
      withAvailabilityChecks(ExportContainer, {
        feature: DATA_EXPORT,
        permissions: 'Export',
      }),
    []
  );

  return (
    <PageHeader title={title}>
      <SkeletonText loading={typeof totalCount === 'undefined' || totalCount === -1} width="4rem">
        <span className="page-count">
          {totalCount && formatNumber(totalCount)} {totalItems}
        </span>
      </SkeletonText>
      <div className="page-tools">
        <DataExportFeature source={OPERATIONS} selectedIds={selectedIds} />
        {canUpdate && (
          <Button
            style="primary"
            size="s"
            leftIcon={<Icons iconName={`ic-ui-edit`} size="20" />}
            disabled={disableBulkEditBtn}
            onClick={() => handleOverlayState(!overlayState)}>
            {bulkEdit}
          </Button>
        )}
        <DateFilterHOC />
      </div>
      <Overlay
        openState={overlayState}
        onClose={() => handleOverlayClose()}
        classes={['overlay--bulk-edit']}>
        <div className="overlay_header">
          <h3
            style={{
              fontWeight: 'normal',
            }}>{`${overlayTitle1} ${selectedIds.length} ${
            selectedIds.length > 1 ? overlayTitle2Plural : overlayTitle2
          }`}</h3>
          <Button
            size="s"
            style="subtle"
            iconOnly={true}
            onClick={() => handleOverlayClose()}
            aria-label="Close modal"
            className="overlay_close"
            leftIcon={
              <Icons
                iconName="ic-ui-cancel"
                size={16}
                style={{ cursor: 'pointer', fill: '#5a6872' }}
              />
            }
          />
        </div>
        <div className="overlay_content">{dropdowns}</div>
        <div className="overlay_footer">
          <Button onClick={() => handleOverlayClose()} style="subtle" className="bulk-edit_cancel">
            {cancel}
          </Button>
          <Button
            style="primary"
            onClick={() => updateOperations(selectedIds)}
            disabled={buttonDisabled}>
            {update}
          </Button>
        </div>
      </Overlay>
    </PageHeader>
  );
};
