import { EventEmitter } from 'events';
import { DateTime } from 'luxon';
// ts
import { IDateRange, IDateRangeSelection } from 'src/app/props';
// actions
import { appActionTypes } from 'src/app/actionTypes';
// functions
import { dispatcher } from 'src/utils/dispatcher';
import { getDay, dateToStringYMd, getDayFromDateString } from 'src/utils/dateTimeConverters';
import {
  getDateRangeBasedOnQueryString,
  isQueryStringAvailable,
} from 'src/app/functions/queryString';
// store
import { configStore } from 'src/app/stores/configStore';
// const
import { CHANGE_EVENT } from 'src/constants';
import { getDeployedProductId } from 'src/utils';

class DateRangeStore extends EventEmitter {
  dateRange: IDateRange;
  routeChangedDateRange: string; // not used for any logic atm (only for debugging), just to keep track of what route has changed the last state of the date range
  previousDateRange: IDateRange | null;
  isDateRangeDefaultSet: boolean;

  constructor() {
    super();
    this.isDateRangeDefaultSet = false;
    this.dateRange = {
      from: new Date(),
      to: new Date(),
    };
    this.routeChangedDateRange = '';
    this.previousDateRange = null;
  }

  setDefaultDateRange() {
    if (this.previousDateRange) {
      this.previousDateRange = Object.assign({}, this.dateRange);
    }
    const appConfig = configStore.getConfig();
    let dateRanges: any = {};
    if (appConfig) {
      const {
        dateRange: { defaultDateRanges }
      } = appConfig;
      dateRanges = defaultDateRanges;
    }

    const {
      location: { pathname },
    } = window;
    const path = pathname.replace(`/${getDeployedProductId()}/`, '');

    const range = dateRanges[path] ? dateRanges[path] : dateRanges.default;
    let rangeTo: Date = getDay('day', 'start', 0);
    const specialDates = ['today', 'yesterday'];
    if (specialDates.includes(range.to)) {
      const dateSubtraction =
        {
          today: 0,
          yesterday: 1,
        }[range.to] || 0;

      rangeTo = new Date();
      rangeTo.setDate(rangeTo.getDate() - dateSubtraction);
      rangeTo.setHours(0, 0, 0, 0);
    } else {
      rangeTo = getDayFromDateString(range.to, 'start');
    }

    const rangeFrom = DateTime.fromJSDate(rangeTo)
      .minus(range.range)
      .toJSDate();

    this.dateRange = {
      to: rangeTo,
      from: rangeFrom,
    };

    // now read the query string
    const { from, to } = getDateRangeBasedOnQueryString(this.dateRange, appConfig);
    if (from && to) {
      this.dateRange = {
        from: getDayFromDateString(from, 'start'),
        to: getDayFromDateString(to, 'end'),
      };
    }
  }


  getDateFilters() {
    // Make sure app's config is ready and available before we set default values
    if (!this.isDateRangeDefaultSet || !isQueryStringAvailable()) {
      this.isDateRangeDefaultSet = true;
      this.setDefaultDateRange();
    }
    return {
      from: this.dateRange.from,
      to: this.dateRange.to,
    };
  }

  getPreviousDateRange() {
    if (this.previousDateRange) {
      return {
        from: this.previousDateRange.from,
        to: this.previousDateRange.to,
      };
    }
    return null;
  }

  setDateFilters(dateRange: IDateRangeSelection) {
    this.previousDateRange = Object.assign({}, this.dateRange);
    this.dateRange = {
      from: getDayFromDateString(dateRange.from, 'start'),
      to: getDayFromDateString(dateRange.to, 'end'),
    };
  }

  getDatesStrings() {
    const { from, to } = this.dateRange;
    return {
      from: dateToStringYMd(from),
      to: dateToStringYMd(to),
    };
  }

  handleActions(action: any) {
    if (action) {
      switch (action.type) {
        case appActionTypes.UPDATE_DATE_RANGE:
          this.setDateFilters(action.dateRange);
          this.routeChangedDateRange = action.route;
          this.emit(CHANGE_EVENT);
          break;
        default:
      }
    }
  }
}

export const dateRangeStore = new DateRangeStore();
dispatcher.register(dateRangeStore.handleActions.bind(dateRangeStore));
