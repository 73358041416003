import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { Button, Icons } from '@ems/client-design-system';
import { sortTable } from 'src/@infringementsCandidates/actions/infringementsActions';
import { IInfringementColumnHeader } from 'src/@infringementsCandidates/interfaces';
import { overflowTextModifier } from 'src/utils';
import { InfringementDispatchContext } from 'src/@infringementsCandidates/providers/InfringementsStateProvider';
import { useSortSelectors } from 'src/@infringementsCandidates/reducers';

export const InfringementColumnHeader: FC<IInfringementColumnHeader> = ({
  titleIcon,
  sortName,
  resultSize,
  isLoading,
  sortable = true,
  content,
  languageData,
}) => {
  const dispatcher = useContext(InfringementDispatchContext);
  const sortSelectors = useSortSelectors();
  const sortObject = sortSelectors.getIconProps(sortName);
  let iconClass = '';
  let iconName = '';
  const { title: titleText, sortBy, abbreviation } = languageData;
  const title = titleIcon !== undefined ? titleIcon : titleText;

  const isSortable = !sortable ? 'sort-header--static' : '';
  const hasAbbreviation = abbreviation ? 'has-abbreviation' : '';
  const loading = isLoading ? 'sort-header--loading' : '';
  const className = classNames('sort-header', loading, isSortable, hasAbbreviation);

  if (sortObject) {
    iconClass = 'sort-icon';
    iconName = sortObject.direction === 'DESC' ? 'ic-ui-caret-up' : 'ic-ui-caret-down';
  } else {
    iconClass = 'sort-icon hover-only';
    iconName = 'ic-ui-caret-down';
  }

  const columnContent = abbreviation ? (
    overflowTextModifier(title, abbreviation)
  ) : (
    <span className="grid-cell--title">{title}</span>
  );

  if (content && !sortable) {
    return content;
  }

  if (!sortable) {
    return <span className={className}>{content ? content : columnContent}</span>;
  }

  return (
    <>
      <Button
        style="subtle"
        className={className}
        disabled={isLoading}
        aria-label={`${sortBy} ${titleText}`}
        onClick={() => {
          if (!isLoading && sortable) {
            sortTable({ sortName, resultSize }, dispatcher);
          }
        }}>
        {columnContent}
      </Button>
      <Icons iconName={iconName} title={name} size={20} className={iconClass} />
    </>
  );
};
