import React from 'react';
import { Tooltip, Checkbox, Icons } from '@ems/client-design-system';
import cx from 'classnames';
// components
import { ColumnHeader, TableIcon } from 'src/components';
// action
import { sortTable } from 'src/@noiseEvents/actions';
import { Link } from 'react-router-dom';
import {
  getDeployedProductId,
  InlineEditDropDown,
  tableDateTimeWithSecondsFormat,
  tableDateTimeWithSecondsFormatShort,
  getOperationTheme,
} from 'src/utils';
// constants
import {
  NOISEEVENTS,
  CAUSE_COMMUNITY,
  CAUSE_WEATHER,
  CAUSE_EQUIPMENT,
  CAUSE_UNKNOWN,
  THEME_DEFAULT_OPERATION_TYPE,
} from 'src/constants';

const formatDecimalValue = (value: number) => {
  return typeof value === 'number' ? value.toFixed(1) : value;
};

/*
 * icon in table
 */
const iconField = (
  acType: string | null,
  prefix: string,
  size: number,
  translationData,
  selectedTrackTheme: string = THEME_DEFAULT_OPERATION_TYPE
) => {
  if (!acType) {
    return '';
  }
  const name = acType.toLowerCase();
  const selectedTheme = getOperationTheme(selectedTrackTheme);

  return (
    <Tooltip text={translationData[acType]}>
      <Icons
        iconName={`ic-${prefix}-${name}`}
        title={name}
        className={cx(`${prefix}-${name}`)}
        size={size}
        style={
          selectedTrackTheme && {
            fill: selectedTheme[name],
            color: selectedTheme[name],
          }
        }
      />
    </Tooltip>
  );
};

/*
 * Create link to the noise events details
 */
const linkToDetails = (name: string | null, id: any | null, requiredInfo) => (
  <Link
    className="rule_link"
    to={{
      pathname: `/${getDeployedProductId()}/${NOISEEVENTS}/${id}`,
      search: undefined,
      state: requiredInfo,
    }}>
    {name}
  </Link>
);

const groomingIcon = (
  defaultChecked: boolean,
  handleChange: (isGroomed: boolean, id: number) => void,
  id: number
) => {
  const handleValueHandover = event => {
    handleChange(event.target.checked, id);
  };

  return (
    <Checkbox variant="circle" defaultChecked={defaultChecked} onChange={handleValueHandover} />
  );
};

/*
 * Prepare operation for render on table
 */
export const formatDisplayData = (
  data: any,
  translationData: any,
  requiredInfo: any,
  onUpdateSelection: any,
  canUpdate: boolean,
  isGroomingRequired: boolean,
  handleChange: (isGroomed: boolean, id: number) => void = () => {},
  twelveHourFormat: boolean,
  selectedTrackTheme: string
) => {
  for (const [id, noiseEvent] of data) {
    const hasOperationAndIsEmpty = (field: string): boolean =>
      (!field || field === '') && !!noiseEvent.operationId;

    if (!noiseEvent.Id) {
      noiseEvent.aircraftType = hasOperationAndIsEmpty(noiseEvent.aircraftType)
        ? '—'
        : noiseEvent.aircraftType;

      noiseEvent.displayCategory = hasOperationAndIsEmpty(noiseEvent.aircraftCategory)
        ? '—'
        : iconField(noiseEvent.aircraftCategory, 'ac', 26, translationData);

      noiseEvent.displayFlag = hasOperationAndIsEmpty(noiseEvent.operationType)
        ? '—'
        : iconField(noiseEvent.operationType, 'ad', 32, translationData, selectedTrackTheme);

      noiseEvent.correlated = hasOperationAndIsEmpty(noiseEvent.displayFields.correlated)
        ? '—'
        : noiseEvent.displayFields.correlated;
    }

    noiseEvent.displayLocationName = linkToDetails(
      noiseEvent.locationName,
      noiseEvent.id,
      requiredInfo
    );

    noiseEvent.displayTime = tableDateTimeWithSecondsFormat(noiseEvent.time, twelveHourFormat);
    noiseEvent.maxLevel = formatDecimalValue(noiseEvent.maxLevel);
    noiseEvent.sel = formatDecimalValue(noiseEvent.sel);
    noiseEvent[`displayTime-short`] = tableDateTimeWithSecondsFormatShort(
      noiseEvent.time,
      twelveHourFormat
    );
    noiseEvent.classification = InlineEditDropDown({
      id,
      operation: noiseEvent,
      fieldType: 'classification',
      position: 'center',
      labelName: translationData[noiseEvent.cause],
      translationData,
      options: [
        translationData[CAUSE_COMMUNITY],
        translationData[CAUSE_WEATHER],
        translationData[CAUSE_EQUIPMENT],
        translationData[CAUSE_UNKNOWN],
      ],
      onUpdateSelection,
      canUpdate,
    });
    if (isGroomingRequired) {
      let isGroomed = false;
      if (Array.isArray(noiseEvent.tags) && noiseEvent.tags.length) {
        noiseEvent.tags.forEach(item => {
          if (
            typeof item.name !== 'undefined' &&
            typeof item.isCompleted !== 'undefined' &&
            item.name === 'Manual Event Match Review' &&
            item.isCompleted
          ) {
            isGroomed = true;
          }
        });
      }
      noiseEvent.isGroomed = groomingIcon(isGroomed, handleChange, noiseEvent.id);
    }

    data.set(id, noiseEvent);
  }

  return data;
};

/*
 * Prepare operation header
 */
export const formatHeaders = (
  resultSize: number,
  isLoading: boolean,
  dispatcher: any,
  sortSelectors: any,
  translationData: any
) => {
  // Overrides the headers provided
  // could improve and loop through data from config

  const {
    fields: { noiseEvents: rowHeaders },
    abbreviations: {
      aircraftCategory: aircraftCategoryAbrr,
      duration: durationAbbr,
      maxLevel: maxLevelAbrr,
    },
    components: {
      labels: { sortBy },
    },
  } = translationData;

  const displayLocationName = ColumnHeader({
    sortName: 'locationName',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayLocationName,
      abbreviation: null,
      sortBy,
    },
  });
  const displayTime = ColumnHeader({
    sortName: 'time',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayTime,
      abbreviation: null,
      sortBy,
    },
  });
  const maxLevel = ColumnHeader({
    sortName: 'maxLevel',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.maxLevel,
      abbreviation: maxLevelAbrr,
      sortBy,
    },
  });
  const sel = ColumnHeader({
    sortName: 'sel',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.sel,
      abbreviation: maxLevelAbrr,
      sortBy,
    },
  });
  const duration = ColumnHeader({
    sortName: 'duration',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.duration,
      abbreviation: durationAbbr,
      sortBy,
    },
  });
  const displayFlag = ColumnHeader({
    titleIcon: TableIcon({
      name: 'arrival',
      prefix: 'ad',
      size: 24,
      className: isLoading ? 'disabled' : '',
    }),
    sortName: 'operationType',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.operationType,
      abbreviation: null,
      sortBy,
    },
  });
  const acid = ColumnHeader({
    sortName: 'acid',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.acid,
      abbreviation: null,
      sortBy,
    },
  });
  const displayCategory = ColumnHeader({
    sortName: 'aircraftCategory',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.aircraftCategory,
      abbreviation: aircraftCategoryAbrr,
      sortBy,
    },
  });
  const aircraftType = ColumnHeader({
    sortName: 'aircraftType',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.aircraftType,
      abbreviation: null,
      sortBy,
    },
  });
  const classification = ColumnHeader({
    sortName: 'classification',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.classification,
      abbreviation: null,
      sortBy,
    },
  });
  const correlated = ColumnHeader({
    sortName: 'correlated',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.correlated,
      abbreviation: null,
      sortBy,
    },
  });

  return Object.assign({}, rowHeaders, {
    displayLocationName,
    displayTime,
    maxLevel,
    sel,
    duration,
    displayFlag,
    acid,
    displayCategory,
    aircraftType,
    classification,
    correlated,
  });
};
