import { useState, useEffect, useCallback } from 'react';
// props
import { IViewState } from 'src/@infringements/interfaces';
// reducers
import { useConfigSelectors } from 'src/app/reducers/configReducer';
// stores
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
// functions
import {
  getDateArray,
  getDateMapping,
  addSourceStyleToMap,
  removeSourceStylesFromMap,
  getAdditionalMapBoxConfig,
  getMapDate,
  addTrackPointsToMap,
  IMap,
} from 'src/utils/mapHelpers';
import { IMapData } from 'src/utils/interfaces';
import { getAuth } from 'src/app/functions/storage';
// constants
import { CHANGE_EVENT, MAPTYPES } from 'src/constants';

import { mapLayerApi } from 'src/config';
import { getDeployedProductId } from 'src/utils';
import { DYNAMIC_TILE_SERVER } from 'src/app/featureToggles';

/**
 * Custom hook to get ref
 */

export const useMapRef = () => {
  const [map, setMap] = useState(null);
  const mapRef = useCallback(map => {
    setMap(map);
  }, []);
  return [map, mapRef];
};

/**
 * Custom hook to get map ref
 * @param map - Mapbox API
 */

export const useMapApis = map => {
  const [mapApis, setMapApis] = useState(null);
  useEffect(() => {
    if (map) {
      // when map is ready
      setMapApis(map.getMap());
    }
  }, [map]);

  return mapApis;
};

/**
 * Custom hook to flag when map style is ready
 * @param map - Mapbox API
 */

export const useMapStyle = () => {
  const [isMapStyleReady, mapStyleReady] = useState<boolean>(false);
  const setMapStyleFlag = mapApis => {
    if (mapApis && mapApis.isStyleLoaded()) {
      mapStyleReady(true);
    }
  };
  return {
    isMapStyleReady,
    setMapStyleFlag,
  };
};

export const useMapWhenReady = map => {
  const [mapApi, setMapApi] = useState<null | any>(null);
  const [isMapStyleReady, mapStyleReady] = useState<boolean>(false);
  const mapLoaded = () => {
    if (mapApi && mapApi.isStyleLoaded()) {
      mapStyleReady(true);
    }
  };

  useEffect(() => {
    if (map) {
      setMapApi(map.getMap());
    }
  }, [map]);

  return {
    mapApis: mapApi && isMapStyleReady ? mapApi : null,
    mapLoaded,
  };
};

/**
 * Custom hook that returns props used for the StyledMap component
 */

export const useMapProps = (mode?: string) => {
  const configSelectors = useConfigSelectors();
  const {
    map: {
      centre: { latitude, longitude, altitude },
      zoom,
      mapAccessToken,
      mapStyleURL,
      mapStyleURLDarkMode,
      dragRotate,
      minZoom,
      maxZoom,
      pitch,
      bearing,
    },
  } = configSelectors.getConfig();

  const viewportFromProps: IViewState = {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
    zoom,
    pitch,
    bearing,
  };

  if (altitude) {
    viewportFromProps.altitude = altitude;
  }
  if (mode === '2D') {
    viewportFromProps.maxPitch = 0;
  }

  return {
    viewportFromProps,
    mapboxApiAccessToken: mapAccessToken,
    mapStyle: mapStyleURL,
    mapStyleDark: mapStyleURLDarkMode,
    dragRotate,
    minZoom,
    maxZoom,
  };
};

/**
 * Add the map sources with the selected date-range
 * @param mapApis - Mapbox API
 * @param datesArray - array of date strings
 * @param mapBoxConfig - map config from db
 */

export const addSourcesStylesToMap = (
  mapApis: any,
  datesArray: string[],
  mapBoxConfig: any,
  layers: any[]
) => {
  if (mapApis) {
    addSourceStyleToMap({
      mapApis,
      mapBoxConfig,
      datesArray,
      layers,
    });
  }
};

/**
 * Removes sources from map
 * @param mapApis - Mapbox API
 * @param datesArray - array of date strings
 * @param mapBoxConfig - map config from db
 */

export const removeSourcesStylesFromMap = (
  mapApis: any,
  datesArray: string[],
  mapBoxConfig,
  layers
) => {
  if (mapApis) {
    datesArray.forEach((dateString: string) => {
      removeSourceStylesFromMap(mapApis, dateString, mapBoxConfig, layers);
    });
  }
};

/**
 * Provides map config required for mapbox
 */

export const useMapConfig = () => {
  const configSelectors = useConfigSelectors();
  const {
    globals: { protectedServices: protectedServicesList },
    map: { mapboxUser, sitePrefix, sourcePrefix, sourceLayerPrefix, geocoderBbox, geocoderLimit },
  } = configSelectors.getConfig();

  const protectedServices: string[] = Array.isArray(protectedServicesList)
    ? protectedServicesList
    : [];
  // values from config
  const mapBoxConfig = {
    mapboxUser,
    sitePrefix,
    sourcePrefix,
    sourceLayerPrefix,
    geocoderBbox,
    geocoderLimit,
    elevationEndpoint: 'https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery',
    reverseGeocodingEndpoint: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
    operationPrefix: 'Operations_',
    protectedServices,
    transformRequest: () => {
      return url => {
        for (let i = protectedServices.length; i--; ) {
          if (url.includes(protectedServices[i])) {
            return {
              url,
              headers: {
                Authorization: getAuth().authorization,
              },
            };
            break;
          }
        }
      };
    },
  };
  // providing mapbox config with additional constants
  return Object.assign({}, mapBoxConfig, getAdditionalMapBoxConfig(mapBoxConfig));
};

/**
 * Format track URL's to get usefull info
 * @param track - start and end time for tile request
 */
const formatTrackList = trackList => {
  return trackList.map(track => {
    const split = track.replace('.json', '').split('_');
    return {
      upper: split[split.length - 1],
      lower: split[split.length - 2],
      url: track,
    };
  });
};

/**
 * Retrieves tile URL's from api
 * @param timeRange - start and end time for tile request
 */
export const fetchTrackTiles = async (startCursor: string) => {
  const uri = `${mapLayerApi}${getDeployedProductId()}/tracktiles/newlayer?anomsapiquerycursor=${startCursor}`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: getAuth().authorization,
    },
  };
  return fetch(uri, options)
    .then(response => response.json())
    .then(data => {
      return formatTrackList(data);
    });
};

/**
 * Hook to update map on changes to dateRangeStore
 * @param map - Mapbox API
 */
export const useDatesDataForMap = (
  mapApis,
  mapBoxConfig,
  layers,
  disabled = false,
  mapType = 'operationDetails'
) => {
  const [datesArray, updateDatesArray] = useState([]);
  const [layesrAlreadyAdded, updateLayesrAlreadyAdded] = useState([]);
  const [dateRangeMapping, updateDateRangeMapping] = useState({});
  const configSelectors = useConfigSelectors();
  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);
  useEffect(() => {
    const handleDateRangeUpdates = () => {
      const previousDateRange = dateRangeStore.getPreviousDateRange();
      const previousDates = previousDateRange ? getDateArray(previousDateRange) : previousDateRange;

      // get filter data from store and extract dates
      const dateFilters = dateRangeStore.getDateFilters();
      const datesData = getDateArray(dateFilters);
      updateDatesArray(datesData);

      // generate date mapping from dates
      const dateMapping = getDateMapping(dateFilters);
      updateDateRangeMapping(dateMapping);

      const isDynamic = FEATURE_FLAG_DYNAMIC_TILE_SERVER && mapType === MAPTYPES.OPERATIONDETAILS;

      if (disabled) {
        if (datesArray && datesArray.length) {
          removeSourcesStylesFromMap(mapApis, datesArray, mapBoxConfig, layesrAlreadyAdded);
        }
      } else {
        if (layers && layers.length) {
          if (previousDates && previousDates.length) {
            removeSourcesStylesFromMap(mapApis, previousDates, mapBoxConfig, layers);
          }
          // Add sources the old way if not using dynamic tile server
          if (!isDynamic) {
            addSourcesStylesToMap(mapApis, datesData, mapBoxConfig, layers);
          }
          updateLayesrAlreadyAdded(layers);
        }
      }
    };

    // when map is ready
    if (mapApis) {
      handleDateRangeUpdates();
      // attach store listeners
      dateRangeStore.on(CHANGE_EVENT, handleDateRangeUpdates);
    }
    return () => {
      if (mapApis) {
        dateRangeStore.removeListener(CHANGE_EVENT, handleDateRangeUpdates);
      }
      mapApis = null; // invalidate mapApis on map unmount
    };
  }, [mapApis, layers, disabled]);

  return {
    datesArray,
    dateRangeMapping,
  };
};

export const useMapCenter = (viewport, setViewport, position, operationId) => {
  useEffect(() => {
    if (position.latitude >= -90 && position.latitude <= 90) {
      viewport.latitude = position.latitude;
    }
    if (position.longitude >= -180 && position.longitude <= 180) {
      viewport.longitude = position.longitude;
    }
    viewport.zoom = 10;
    setViewport(viewport);
  }, [operationId]);
};

export const useSingleDateTile = ({
  mapApis,
  mapBoxConfig,
  layers,
  time,
  operationId,
  isDynamicTileServer = false,
  mapTrackData,
}: {
  mapApis: IMap;
  mapBoxConfig: any;
  layers: any;
  time: string;
  operationId: number | string;
  isDynamicTileServer?: boolean;
  mapTrackData?: [IMapData];
}) => {
  const [dateString, updateDateString] = useState('');
  const [datesArray, updateDatesArray]: any[] = useState([]);
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');

  useEffect(() => {
    const mapDate = getMapDate(new Date(time.slice(0, 10)));
    updateDateString(mapDate);
    updateDatesArray([mapDate]);
    if (mapApis) {
      if (isDynamicTileServer) {
        addTrackPointsToMap({
          mapApis,
          trackData: mapTrackData,
          selectedTrackTheme,
          selectedTrackId:
            typeof operationId === 'string' ? parseInt(operationId, 10) : operationId,
        });
      } else {
        addSourcesStylesToMap(mapApis, [mapDate], mapBoxConfig, layers);
      }
    }
    return () => {
      mapApis = null; // invalidate mapApis on map unmount
    };
  }, [mapApis, operationId, mapTrackData]);
  return { dateString, datesArray };
};
