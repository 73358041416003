import React, { createContext, useReducer } from 'react';
import { IStoreState } from 'src/app/props';
import { configInitialState, configReducer } from 'src/app/reducers/configReducer';
import { filtersInitialState, filterDataReducer } from 'src/app/reducers/filtersReducer';
import { languageInitialState, languageReducer } from 'src/app/reducers/languageReducer';
import { rolesInitialState, rolesReducer } from 'src/app/reducers/rolesReducer';
import { scenariosInitialState, scenariosReducer } from 'src/app/reducers/scenariosReducer';

import {
  infringementRulesInitialState,
  infringementRulesReducer,
} from 'src/app/reducers/infringementRulesReducer';

export const GlobalStateContext = createContext({
  config: configInitialState,
  filters: filtersInitialState,
  language: languageInitialState,
  roles: rolesInitialState,
  infringementRules: infringementRulesInitialState,
  scenarios: scenariosInitialState
});

export const GlobalDispatchContext = createContext(null);

export const GlobalStateProvider = ({ children }) => {
  const [configState, configDispatch]: any = useReducer(configReducer, configInitialState);
  const [filtersState, filtersDispatch]: any = useReducer(filterDataReducer, filtersInitialState);
  const [languageState, languageDispatch]: any = useReducer(languageReducer, languageInitialState);
  const [rolesState, rolesDispatch]: any = useReducer(rolesReducer, rolesInitialState);
  const [infringementRulesState, infringementRulesDispatch]: any = useReducer(
    infringementRulesReducer,
    infringementRulesInitialState
  );
  const [scenariosState, scenariosDispatch]: any = useReducer(scenariosReducer, scenariosInitialState);

  const state: IStoreState = {
    config: configState,
    filters: filtersState,
    language: languageState,
    roles: rolesState,
    infringementRules: infringementRulesState,
    scenarios: scenariosState
  };

  const dispatchers = [
    configDispatch,
    filtersDispatch,
    languageDispatch,
    rolesDispatch,
    infringementRulesDispatch,
    scenariosDispatch
  ];

  const dispatch: any = (action: any) =>
    dispatchers.map((dispatcher: any) => {
      dispatcher(action);
    });

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};
