import { IFilterItem } from "@ems/client-design-system";
import { IScenario } from "src/app/props";

export const getConfigurationNames = configurations => {
  return configurations.map(configuration => {
    return { name: configuration.name };
  });
};

export const getSelectedConfiguration = (scenarios: IScenario[], currentRowIndex: number) => {
  let selectedScenario;
  scenarios.forEach((scenario, index) => {
    if (index === currentRowIndex) {
      selectedScenario = scenario;
    }
  });
  return selectedScenario;
};

export const updateConfigurationList = (configurations, updatedConfig, currentRowIndex) => {
  return configurations.map((configuration, index) => {
    if (index === currentRowIndex) {
      return updatedConfig;
    } else {
      return configuration;
    }
  });
};

export const formatDateFilterItems = (filterItems: IFilterItem[], monthsLong: { [key: string]: string }) => {
  return filterItems.map(item => {
    const itemList = item.label.split(' ');
    const filterLabel = monthsLong[itemList[0].toLowerCase()] + ' ' + itemList[1];
    return {
      key: item.key,
      label: filterLabel,
      icon: '',
      className: '',
    };
  });
};

export const isValidDateRange = (startDate, endDate) => {
  return new Date(startDate) <= new Date(endDate);
};

export const getFormattedScenarioMutationRecord = (record) => {
  return {
    id: record.id,
    name: record.name,
    isActive: record.isActive,
    isDefault: record.isDefault,
    isLocked: record.isLocked,
    startTime: record.startTime,
    endTime: record.endTime,
    defaultOptimumTMAApproachDistance: parseFloat(record.defaultOptimumTMAApproachDistance),
    applyWeatherAdjustment: record.applyWeatherAdjustment,
    defaultLoadFactor: parseInt(record.defaultLoadFactor, 10),
    defaultDurationClimb: parseInt(record.defaultDurationClimb, 10),
    defaultDurationTaxiIn: parseInt(record.defaultDurationTaxiIn, 10),
    defaultDurationTakeOff: parseInt(record.defaultDurationTakeOff, 10),
    defaultDurationTaxiOut: parseInt(record.defaultDurationTaxiOut, 10),
    defaultDurationApproach: parseInt(record.defaultDurationApproach, 10),
    emissionRateMode: record.emissionRateMode,
    emissionRateAvGas: parseFloat(record.emissionRateAvGas),
    emissionRateSingle: parseFloat(record.emissionRateSingle),
    emissionRateJetFuel: parseFloat(record.emissionRateJetFuel),
    emissionRateUnknown: parseFloat(record.emissionRateUnknown),
    duplicateScenarioId: record.duplicateScenarioId,
  };
};
