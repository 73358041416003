import { IConfigurationUnits } from 'src/app/props';

// shared/global app constants

export const CHANGE_EVENT = 'change';
export const DEPLOYED_PRODUCT_ID = 'deployedProductId';
export const GET_COUNT_DELAY = 1500;
export const INITIALISE_EVENT = 'initialise';
export const SITENAME_KEY = 'sitename';

// permission and feature toggle
export const READ = 'Read';
export const UPDATE = 'Update';
export const DELETE = 'Delete';
// progress status
export const IN_PROGRESS = 'InProgress';
export const COMPLETE = 'Complete';
export const ERROR = 'Error';

export const TOTAL_METRIC = 'GreenhouseGasTotal';
export const PER_MOVEMENT_METRIC = 'GreenhouseGasPerMovement';
export const PER_PASSENGER_METRIC = 'GreenhouseGasPerPassenger';
export const PER_PASSENGER_KILOMETER_METRIC = 'GreenhouseGasPerPassengerKilometer';
export const TMA_EFFICIENCY = 'TerminalManagementAreaEfficiencyTotal';
export const TOTAL_MOVEMENTS = 'MovementTotal';
export const TOTAL_PASSENGERS = 'PassengerTotal';
export const OPERATION = 'operation';
export const OPERATIONS = 'operations';
export const INFRINGEMENT = 'infringement';
export const INFRINGEMENTS = 'infringements';
export const NOISEEVENT = 'noiseevent';
export const NOISEEVENTS = 'noiseevents';
export const COMPLAINT = 'complaint';
export const COMPLAINTS = 'complaints';
export const IMPLAUSIBLEEVENTS = 'implausibleEvents';
export const FILTER = 'filter';
export const FILTER_ROW_SELECTION = 'filter.row';
export const FILTER_RANGE_SELECTION = 'filter.range';
export const FILTER_TIME_SELECTION = 'filter.time';
export const FILTER_CORRELATED_IDS = 'filter.correlatedids';
export const FILTER_COMPLAINER = 'filter.complainer';

// Complaints constants
export const HAS_OPERATION = 'hasOperation';
export const FLIGHT = 'Flight';
export const NO_FLIGHT = 'No Flight';
export const COMPLAINER = 'Complainer';
export const ANONYMOUS = 'Anonymous';
export const INPUT_TWELVE_HOURS_FORMAT = 'hh:mm a LLL d, yyyy';
export const INPUT_TWENTYFOUR_HOURS_FORMAT = 'HH:mm  LLL d, yyyy';
export const FIELD_TWELVE_HOURS_FORMAT = 'hh:mm a, DDD';
export const FIELD_TWENTYFOUR_HOURS_FORMAT = 'HH:mm, DDD';
export const TABLE_TWELVE_HOURS_FORMAT = 'hh:mm a MMM dd';
export const TABLE_TWENTYFOUR_HOURS_FORMAT = 'HH:mm MMM dd';
export const TABLE_TWELVE_HOURS_SHORT_FORMAT = 'hh:mm a';
export const TABLE_TWENTYFOUR_HOURS_SHORT_FORMAT = 'HH:mm';
export const TABLE_TWELVE_HOURS_SECONDS_FORMAT = 'hh:mm:ss a MMM dd';
export const TABLE_TWENTYFOUR_HOURS_SECONDS_FORMAT = 'HH:mm:ss MMM dd';
export const TABLE_TWELVE_HOURS_SECONDS_SHORT_FORMAT = 'hh:mm:ss a';
export const TABLE_TWENTYFOUR_HOURS_SECONDS_SHORT_FORMAT = 'HH:mm:ss';
export const TABLE_TWELVE_HOURS_YEARS_FORMAT = 'hh:mm a MMM dd yyyy';
export const TABLE_TWENTYFOUR_HOURS_YEARS_FORMAT = 'HH:mm MMM dd yyyy';
export const SAVE = 'SAVE';
export const SAVE_AND_DUPLICATE = 'SAVE_AND_DUPLICATE';

// Airtrak constants
export const AIRTRAK = 'airtrak';
export const IN_BOUND = 'Inbound';
export const OUT_BOUND = 'Outbound';
export const LTO = 'LTO';
export const SHORTCUTS = 'Shortcuts';
export const ARRIVAL_ENROUTE = 'ArrivalEnroute';
export const DEPARTURE_ENROUTE = 'DepartureEnroute';
export const REGION_BREAKDOWN = 'DomesticOrInternational';
export const GHG_BREAKDOWN = 'Pollutant';
export const AIRLINE_BREAKDOWN = 'Airline';
export const AIRCRAFT_BREAKDOWN = 'Aircraft';
export const NONE_BREAKDOWN = 'None';
export const PHASE_BREAKDOWN = 'Phase';
export const PHASES = 'phases';
export const REGIONS = 'regions';
export const LINE = 'line';
export const BAR = 'bar';
export const PIE = 'pie';
export const TABLE = 'table';
export const ASC = 'ASC';
export const DESC = 'DESC';
export const EMMISIONS = 'emissions';
export const COMBINED_TMA = 'TMACombined';
export const COMBINED = 'Combined';
export const MIN_CARBON_EMISSION_TABLE_COLUMNS = 3;
export const AIRCRAT_MODEL_SEPERATOR = ' / ';
export const MAX_TIME_IN_MODE_SECONDS = 9999;
export const MAX_TMA_DISTANCE = 99.9;
export const MAX_CARBON_EMISSION_FACTOR = 9.9999;
export const MAX_LOAD_FACTOR_PERCENTAGE = 100;
export const LOADFACTORS_FUTURE_YEARS = 1;
export const SCENARIO_FUTURE_YEARS = 10;
export const DEFAULT_SCENARIO_START_YEAR = 1996;
export const CARBON_EMISSION_ROUNDING_PRECISION = 2;
// Below are values in pixels
export const MIN_CARBON_EMISSION_TABLE_WIDTH = 800;
export const CARBON_EMISSION_TABLE_PER_COLUMN_WIDTH = 100;

// layers
export const TRACKS = 'Tracks';
export const MONITOR_LOCATIONS = 'MonitorLocations';
export const RUNWAYS = 'Runways';
export const LAYER_PREFIX_DYNAMIC_TRACK = 'trackLayer';

// exports
export const EXCEL_EXPORT = 'excel';
export const WORD_EXPORT = 'word';
export const KML_EXPORT = 'kml';
export const INM_EXPORT = 'inm';

export const DATA_FILTER_DIR = 'data/filters/';

// pkce localstorage keys
export const STATE = 'pkceState';
export const CODE_VERIFIER = 'pkceCodeVerifier';
export const ROUTE = 'pageRoute';

// local storage keys
export const USER_SESSION = 'user.session';
export const USER_SETTINGS = 'user.settings';
export const USER_LAST_ACTIVE = 'user.lastActive';

// Possible product IDs
// Only used for local development
export const PRODUCT_PANY = 'x_pany';
export const PRODUCT_SZG = 'szg';
export const PRODUCT_SZG_ANOMSX = 'szg.anomsx';
export const PRODUCT_LHR = 'lhr';
export const PRODUCT_LHR_ANOMSX = 'lhr.anomsx';
export const PRODUCT_BNE = 'bne';
export const PRODUCT_BNE_ANOMSX = 'bne.anomsx';
export const PRODUCT_LHR_PT = 'lhr.pt';
export const PRODUCT_KEF = 'kef';

export const CORRELATED_TYPES = {
  INFRINGEMENT: 'infringement',
  NOISEEVENT: 'noiseevent',
  COMPLAINT: 'complaint',
};

export const CORRIDOR_INFRINGEMENT = 'Corridor';
export const EXCLUSION_INFRINGEMENT = 'Exclusion';
export const GATE_INFRINGEMENT = 'Gate';
export const CURFEW_INFRINGEMENT = 'Curfew';
export const CCO_INFRINGEMENT = 'Cco';
export const CDO_INFRINGEMENT = 'Cdo';
export const MINHEIGHT_INFRINGEMENT = 'MinHeight';
export const NOISE_INFRINGEMENT = 'Noise';

// cause status
export const CAUSE_AIRCRAFT = 'Aircraft';
export const CAUSE_COMMUNITY = 'Community';
export const CAUSE_WEATHER = 'Weather';
export const CAUSE_EQUIPMENT = 'Equipment';
export const CAUSE_UNKNOWN = 'Unknown';
export const CAUSE_NOT_SET = 'NotSet';
// TODO: will be removed soon
export const CAUSE_SINGLE_LOCAL_AIRCRAFT = 'SingleLocalAircraft';
export const CAUSE_MULTIPLE_LOCAL_AIRCRAFT = 'MultipleLocalAircraft';
export const CAUSE_NON_LOCAL_AIRCRAFT = 'NonLocalAircraft';
export const CAUSE_MIXED_AIRCRAFT = 'MixedLocalAndNonLocalAircraft';

// comment status
export const COMMENT_STATUS_LOADED = 'loaded';
export const COMMENT_STATUS_LOADING = 'loading';
export const COMMENT_STATUS_SAVED = 'saved';

// device mode
export const DESKTOP_MODE = 'Desktop';
export const MOBILE_MODE = 'Mobile';

// status options
export const NULL_VALUE = 'NullValue';
export const VECTORED = 'vectored';

// Unit Profiles
export const PROFILE_US_CUSTOMARY = 'US Customary';
export const PROFILE_ICAO_METRIC = 'ICAO Metric';
export const PROFILE_ICAO_ALTERNATIVE = 'ICAO Alternative';

// UNITS OF MEASUREMENT
// Sound
export const UNIT_DECIBEL = 'dB';
// Time
export const UNIT_SECOND = 'sec';
// Distances and Lengths
export const UNIT_METER = 'm';
export const UNIT_KILOMETER = 'km';
export const UNIT_FOOT = 'ft';
export const UNIT_MILE = 'mi';
export const UNIT_NAUTICAL_MILE = 'NM';
export const UNIT_INCH = 'in';
export const UNIT_MILLIMETER = 'mm';
export const UNIT_CENTIMETER = 'cm';
// Speed
export const UNIT_METERS_PER_SECOND = 'm/s';
export const UNIT_KNOT = 'kt';
export const UNIT_MILES_PER_HOUR = 'mph';
export const UNIT_KILOMETERS_PER_HOUR = 'km/h';
export const UNIT_FEET_PER_MIN = 'ft/min';
// Temperature
export const UNIT_CELCIUS = 'C';
export const UNIT_FAHREINHEIT = 'F';
export const UNIT_KELVIN = 'K';
// Pressure
export const UNIT_HECTOPASCAL = 'hPa';
export const UNIT_INCHES_OF_MERCURY = 'inHg';
export const UNIT_MILLIBAR = 'mb';
// Humidity
export const UNIT_PERCENTAGE = '%';
// Location and Angles (needs to be converted into required symbols upon use)
export const UNIT_DEGREE = 'degrees';
export const UNIT_DMS = 'dms';
// Mass
export const UNIT_KILOGRAM = 'kg';
export const UNIT_POUND = 'lb';
export const UNIT_TON = 't';
export const UNIT_GRAM = 'g';
export const UNIT_MILLIGRAM = 'mg';

// Unit libraries for conversions and presentation
export const UNIT_PROFILE_DEFAULT = 'ICAO_ALTERNATIVE';
export const UNIT_PROFILE_LOCAL = 'LOCAL_SYSTEM';
export const UNIT_PROFILES: { [key: string]: IConfigurationUnits } = {
  US_CUSTOMARY: {
    distance: 'mi',
    distanceVertical: 'ft',
    speed: 'mph',
    speedVertical: 'ftmin',
    temperature: 'f',
    pressure: 'inhg',
    humidity: 'percent',
    visibility: 'mi',
    runwayVisualRange: 'ft',
    precipitation: 'in',
    location: 'dms',
    direction: 'deg',
    runwayLength: 'ft',
    mass: 'lb',
    carbonEmissions: 'kg',
  },
  ICAO_METRIC: {
    distance: 'km',
    distanceVertical: 'm',
    speed: 'kmh',
    speedVertical: 'ms',
    temperature: 'c',
    pressure: 'hpa',
    humidity: 'percent',
    visibility: 'km',
    runwayVisualRange: 'm',
    precipitation: 'mm',
    location: 'dms',
    direction: 'deg',
    runwayLength: 'm',
    mass: 'kg',
    carbonEmissions: 'kg',
  },
  ICAO_ALTERNATIVE: {
    distance: 'nm',
    distanceVertical: 'ft',
    speed: 'kt',
    speedVertical: 'ftmin',
    temperature: 'c',
    pressure: 'hpa',
    humidity: 'percent',
    visibility: 'km',
    runwayVisualRange: 'm',
    precipitation: 'mm',
    location: 'dms',
    direction: 'deg',
    runwayLength: 'm',
    mass: 'kg',
    carbonEmissions: 'kg',
  },
};

export const CONVERSION_TABLE: { [key: string]: string } = {
  km: UNIT_KILOMETER,
  m: UNIT_METER,
  mi: UNIT_MILE,
  nm: UNIT_NAUTICAL_MILE,
  ft: UNIT_FOOT,
  kt: UNIT_KNOT,
  mph: UNIT_MILES_PER_HOUR,
  kmh: UNIT_KILOMETERS_PER_HOUR,
  ms: UNIT_METERS_PER_SECOND,
  ftmin: UNIT_FEET_PER_MIN,
  f: UNIT_FAHREINHEIT,
  c: UNIT_CELCIUS,
  k: UNIT_KELVIN,
  inhg: UNIT_INCHES_OF_MERCURY,
  hpa: UNIT_HECTOPASCAL,
  mb: UNIT_MILLIBAR,
  percent: UNIT_PERCENTAGE,
  in: UNIT_INCH,
  mm: UNIT_MILLIMETER,
  cm: UNIT_CENTIMETER,
  deg: UNIT_DEGREE,
  dms: UNIT_DMS,
  kg: UNIT_KILOGRAM,
  lb: UNIT_POUND,
};

export const DECIBEL_FILTER_MIN = 0;
export const DECIBEL_FILTER_MAX = 140;
export const SECONDS_FILTER_MIN = 0;
export const SECONDS_FILTER_MAX = 240;
export const ERROR_MAX_GREATER_THAN_MIN = 'maxGreaterThanMin';

export const MOBILE_SCREEN_WIDTH = 992;
export const DEFAULT_BBOX_DISTANCE = 320;
export const FLY_TO_DURATION = 800;

export const ONE_DAY_IN_SECONDS = 86400;
export const ONE_HOUR = 1000 * 60 * 60;

export const MAX_COMPLAINER_ADDRESS_LENGTH = 30;
export const ALIASES = '...';
export const PRIMARY_CONTACT = 'Phone1';
export const SECONDARY_CONTACT = 'Phone2';

export const NOT_PREFERRED_CONTACT = 'NotSet';
export const EMAIL = 'Email';
export const MAIL = 'Letter';

export const TOO_LOUD = 'Too Loud';
export const TOO_LOW = 'Too Low';
export const FREQUENT = 'Frequent';
export const GENERAL_INQUIRY = 'General Inquiry';
export const PRIMARY_PHONE_CONTACT = 'Phone1';
export const SECONDARY_PHONE_CONTACT = 'Phone2';
export const EMAIL_CONTACT = 'Email';
export const NOT_SET_CONTACT = 'NotSet';

// Settings
export const SINGLE = 'single';
export const MULTIPLE = 'multiple';
export const LOADFACTORS_MODULE = 'loadFactors';
export const FUELTYPES_MODULE = 'fuelTypes';
export const MANAGE_CONFIGURATIONS_MODULE = 'manageConfigurations';
export const TIME_IN_MODES_MODULE = 'timeInModes';
export const AIRCRAFT_MAPPING_MODULE = 'aircraftMapping';
export const FLEETMIX_MODULE = 'fleetMix';
export const AIRLINE_MAPPING_MODULE = 'airlineMapping';
export const FORMATION_COUNT_EDIT_VALUES = Array.from(Array(20), (e, i) => (i + 1).toString());

// ZOOM
export const ZOOM_DEFAULT_LEVEL = 8;
export const ZOOM_SELECTION_TOLERANCE_LOW = 1; // When zoomed in, set tolerance for track selection click lower
export const ZOOM_SELECTION_TOLERANCE_HIGH = 5; // When zoomed out, set tolerance for track selection click higher

// Theme
export const THEME_DEFAULT_COLOR = '#8C54FF';
export const THEME_DEFAULT_OPERATIONS_THEME = 'default';
export const THEME_DEFAULT_OPERATION_TYPE = 'arrival';
export const THEME_DEFAULT_OPERATIONS = {
  arrival: '#EA702E',
  departure: '#9B68DE',
  touchandgo: '#5AAAFA',
  overflight: '#5AA700',
};

export const AIRCRAFT_TYPE_ICON_CODES = {
  aircraftCategories: 'ac',
  operationTypes: 'ad',
  correlated: 'co',
};

// Maptypes
export const MAPTYPES = {
  COMPLAINTDETAILS: 'complaintDetails',
  COMPLAINTADDFLIGHT: 'complaintAddFlight',
  COMPLAINTVIEWCOMPLAINT: 'complaintViewComplaint',
  INFRINGEMENTDETAILS: 'infringementDetails',
  INFRINGEMENTSUMMARY: 'infringementSummary',
  INFRINGEMENTCANDIDATES: 'infringementCandidates',
  NOISEEVENTDETAILS: 'noiseEventDetails',
  NOISEEVENTSUMMARY: 'noiseEventSummary',
  OPERATIONDETAILS: 'operationDetails',
  OPERATIONSUMMARY: 'operationSummary',
};
export const MAPTYPE_DEFAULT = MAPTYPES.OPERATIONSUMMARY;

// Ruler Constants
export const MARKER_OFFSET = 100;

export const KEYCODES = {
  backspace: 8,
  tab: 9,
  enter: 13,
  shift: 16,
  ctrl: 17,
  alt: 18,
  pausebreak: 19,
  capslock: 20,
  esc: 27,
  space: 32,
  pageup: 33,
  pagedown: 34,
  end: 35,
  home: 36,
  leftarrow: 37,
  uparrow: 38,
  rightarrow: 39,
  downarrow: 40,
  insert: 45,
  delete: 46,
  0: 48,
  1: 49,
  2: 50,
  3: 51,
  4: 52,
  5: 53,
  6: 54,
  7: 55,
  8: 56,
  9: 57,
  a: 65,
  b: 66,
  c: 67,
  d: 68,
  e: 69,
  f: 70,
  g: 71,
  h: 72,
  i: 73,
  j: 74,
  k: 75,
  l: 76,
  m: 77,
  n: 78,
  o: 79,
  p: 80,
  q: 81,
  r: 82,
  s: 83,
  t: 84,
  u: 85,
  v: 86,
  w: 87,
  x: 88,
  y: 89,
  z: 90,
  leftwindowkey: 91,
  rightwindowkey: 92,
  selectkey: 93,
  numpad0: 96,
  numpad1: 97,
  numpad2: 98,
  numpad3: 99,
  numpad4: 100,
  numpad5: 101,
  numpad6: 102,
  numpad7: 103,
  numpad8: 104,
  numpad9: 105,
  multiply: 106,
  add: 107,
  subtract: 109,
  decimalpoint: 110,
  divide: 111,
  f1: 112,
  f2: 113,
  f3: 114,
  f4: 115,
  f5: 116,
  f6: 117,
  f7: 118,
  f8: 119,
  f9: 120,
  f10: 121,
  f11: 122,
  f12: 123,
  numlock: 144,
  scrolllock: 145,
  semicolon: 186,
  equalsign: 187,
  comma: 188,
  dash: 189,
  period: 190,
  forwardslash: 191,
  graveaccent: 192,
  openbracket: 219,
  backslash: 220,
  closebracket: 221,
  singlequote: 222,
};
