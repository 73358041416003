import React, { FC, useState, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'debounce';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
// common components
import { StyledMap, MapControl, RulerTool } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
// functions
import { useMapSettings } from 'src/app/functions/mapSettings';
import {
  useMapRef,
  useMapWhenReady,
  useMapProps,
  useMapConfig,
  useMapCenter,
  useSingleDateTile,
} from 'src/app/functions/map';
import {
  useMapLayer,
  useInfringementMap,
  useMapTracksFilter,
} from 'src/app/functions/infringementsOnMap';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import {
  flyTo,
  useHoverOnMapElement,
  useMapHover,
  useMapClick,
  useMapSelection,
  useGeometryRequiredByMap,
  useHoveredPointData,
  useMapStylesForPlayback,
  offsetCoordinatesByPixels,
} from 'src/utils';

import { AMSLPopup } from 'src/components';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
import { useInAirPlayback, useNoiseMonitorPlayback, useStaticDbDisplay } from 'src/utils/playback';
import { NoisePopup } from 'src/components/NoisePopup';
// constants
import { TOGGLE_MAP_SETTINGS_CTRL, DYNAMIC_TILE_SERVER } from 'src/app/featureToggles';
import { MAPTYPES, MARKER_OFFSET } from 'src/constants';
import { IRulerCoordinateObject } from 'src/utils/interfaces';

export const MapContainer: FC<{
  time: string;
  operationId: string;
  operation: any;
  infTypeId: number;
  infringementType: string;
  infringementId: string;
  position: any;
  isPlaybackMode?: boolean;
  currentTime: number;
  inAirData?: any;
  noiseData: any;
  processedNoiseData: any;
  noiseMonitors: any;
  extraIds: number[];
  setSelectedTime: any;
  profileHoverTime: number | null;
  isPlaybackRunning: boolean;
}> = ({
  time,
  operationId,
  operation,
  infTypeId,
  infringementType,
  infringementId,
  position,
  isPlaybackMode = false,
  currentTime,
  inAirData,
  noiseData,
  processedNoiseData,
  noiseMonitors,
  extraIds,
  setSelectedTime,
  profileHoverTime,
  isPlaybackRunning,
}) => {
  // get map props from config
  const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });
  // used for taking screenshot of map
  const captureRef = useRef(null);
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();
  // get field labels from language selectors
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { operations: opsFields },
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: { backToCenter: backToCenterLabel },
    },
    abbreviations: { operations: opsAbbreviation },
  } = languageSelectors.getLanguage();

  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  const labels = Object.assign(opsFields, opsAbbreviation);

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);

  useMapCenter(viewport, setViewport, position, operationId);
  // getting map style layers
  const layers = useMapLayer({ mapApis, mapBoxConfig, maptype: 'infringements' });

  useGeometryRequiredByMap({
    mapApis,
    mapBoxConfig,
    infringementTypes: typeof infringementType !== 'undefined' ? [infringementType] : [],
  });

  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);

  const { dateString, datesArray } = useSingleDateTile({
    mapApis,
    mapBoxConfig,
    layers,
    time,
    operationId,
    isDynamicTileServer: FEATURE_FLAG_DYNAMIC_TILE_SERVER,
    mapTrackData: inAirData,
  });

  const { tracks } = useInfringementMap(
    'inf-summary',
    mapApis,
    mapBoxConfig,
    dateString,
    operationId,
    operation,
    infTypeId,
    infringementId,
    infringementType,
    true,
    time,
    extraIds
  );

  useMapStylesForPlayback({ mapApis, mapBoxConfig, dateString, isPlaybackMode });

  // extrack map filters for the features
  const { infringementFilter } = useMapTracksFilter(tracks);

  // handle hover and get hovered operation
  const { hoveredElement, handleHover, setHoveredElement } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: datesArray,
    tracksFilter: infringementFilter,
    restrictZoomLevels: false,
    mapType: MAPTYPES.INFRINGEMENTDETAILS,
  });
  // set map selection for the hover object
  useMapHover(hoveredElement, mapApis);

  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  // Map selection
  const [showSelected, setShowSelected] = useState<boolean>(false);
  const [selectedOperations, setSelectedOperations]: any = useState(null);
  const [selectedPointData, setSelectedPointData] = useState<any>({
    amsl: null,
    time: null,
    longitude: null,
    latitude: null,
    showPointData: false,
    flightId: null,
  });

  const [selectedClickPointData, setSelectedClickedPointData] = useState<any>({
    amsl: null,
    time: null,
    longitude: null,
    latitude: null,
    showPointData: false,
    flightId: null,
  });

  // click handlers that provide selected operations
  const { handleClick, selectedOperation } = useMapClick({
    hoveredOperation: hoveredElement,
    setShowSelected,
    mapApis,
    datesArray,
    tracksFilter: infringementFilter,
    selectedOperations,
    setSelectedOperations,
    mapType: MAPTYPES.INFRINGEMENTDETAILS,
  });

  // Set persistant point data for AMSL Popup that appears on track click
  useEffect(() => {
    setSelectedClickedPointData(selectedPointData);
  }, [showSelected]);

  useHoveredPointData({
    mapApis,
    operation,
    hoveredElement,
    profileHoverTime,
    setSelectedTime,
    setSelectedPointData,
    isPlaybackMode,
    isPlaybackRunning,
    userHomeLocation: null,
    mapProjectionString: null,
  });

  // set feature state for the hovered operation
  useMapSelection(selectedOperations, mapApis, 'infringement-select');

  // reset hover and select when selection changes
  useEffect(() => {
    setShowSelected(false);
    setHoveredElement(null);
    setSelectedOperations([]);
  }, [dateString]);

  // Playback Section
  const dataSources = useMemo(() => (operation ? [operation, ...inAirData] : inAirData), [
    operation,
    inAirData,
  ]);
  useInAirPlayback(mapApis, dataSources, currentTime, isPlaybackMode, Number(operationId));
  const staticMonitors = useStaticDbDisplay(mapApis, noiseMonitors, noiseData, isPlaybackMode);
  const selectedMonitor = useNoiseMonitorPlayback({
    mapApis,
    noiseMonitors,
    noiseData,
    processedNoiseData,
    currentTime,
    isPlaybackMode,
  });

  const [noisePopups, setNoisePopups] = useState<JSX.Element[]>([]);
  useEffect(() => {
    if (mapApis) {
      if (selectedMonitor.length > 0 || staticMonitors.length > 0) {
        const popups: JSX.Element[] = [];
        const mapFn = (monitor: any) => {
          popups.push(
            <NoisePopup
              key={`popup_${monitor.properties.id}`}
              zoomLevel={mapApis.getZoom()}
              monitorData={monitor}
            />
          );
        };
        if (isPlaybackMode) {
          selectedMonitor.map(mapFn);
        } else {
          staticMonitors.map(mapFn);
        }
        setNoisePopups(popups);
      }
    }
  }, [mapApis, selectedMonitor, staticMonitors]);
  // End Playback Section

  // Ruler Tool

  const units = configSelectors.getUnits();
  const [isRulerEnabled, setIsRulerEnabled] = useState<boolean>(false);
  const [rulerCoordinates, updateRulerCoordinates] = useState<IRulerCoordinateObject>({
    start: { longitude: 0, latitude: 0 },
    end: { longitude: 0, latitude: 0 },
  });

  const toggleRuler = () => {
    if (isRulerEnabled) {
      setIsRulerEnabled(false);
      mapApis.isRulerEnabled = false;
    } else {
      setIsRulerEnabled(true);
      mapApis.isRulerEnabled = true;

      const { longitude, latitude } = viewport;
      const startMarkerCoordinates = offsetCoordinatesByPixels(
        [longitude, latitude],
        MARKER_OFFSET,
        mapApis
      );

      updateRulerCoordinates({
        start: { longitude: startMarkerCoordinates.lng, latitude: startMarkerCoordinates.lat },
        end: { longitude, latitude },
      });
    }
  };

  const rulerCoordinatesChanged = (rulerPoint: string, [longitude, latitude]: number[]) => {
    if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
      updateRulerCoordinates({
        ...rulerCoordinates,
        [rulerPoint]: { longitude, latitude },
      });
    }
  };

  return (
    <div ref={captureRef} className="map">
      <StyledMap
        onLoad={() => mapLoaded()}
        viewport={viewport}
        mapStyle={mapStyle}
        onViewportChange={viewport => {
          viewport.maxPitch = 0;
          setViewport(viewport);
        }}
        {...mapProps}
        ref={mapRef}
        onClick={handleClick}
        onHover={debounce(handleHover, 5)}
        transformRequest={
          mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
        }>
        {enableMapControls && (
          <MapControl
            translationData={{
              home: backToCenterLabel,
              mapSettings: mapSettingsTitle,
            }}
            rulerControl={{
              isRulerEnabled,
              toggleRuler,
            }}
            navigationControl={{
              showCompass: true,
              showHome: true,
              showSearch: false,
              showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
            }}
            resetView={resetView}
            mapSettings={{
              update: () => {
                applyBackground();
                applyLayers();
              },
              reset: () => {
                resetBackground();
                resetLayers();
              },
              content: (
                <MapSettingsContainer
                  config={{
                    background: mapStyle,
                    layers: layersDisplayed,
                  }}
                  updateEvent={({ selectedBackground, selectedLayers }) => {
                    if (typeof selectedBackground !== 'undefined') {
                      storeSelectedBackground(selectedBackground);
                    }
                    if (typeof selectedLayers !== 'undefined') {
                      storeSelectedLayers(selectedLayers);
                    }
                  }}
                />
              ),
            }}
          />
        )}

        {noisePopups}
        {showSelected &&
          selectedOperation &&
          selectedOperation.latitude &&
          selectedOperation.longitude && (
            <AMSLPopup
              labels={labels}
              draggable
              onClose={() => setShowSelected(false)}
              pointData={selectedClickPointData}
              mapApis={mapApis}
            />
          )}

        {hoveredElement && (
          <AMSLPopup labels={labels} pointData={selectedPointData} mapApis={mapApis} />
        )}
        <MapReferenceLayers mapApis={mapApis} mapStyle={mapStyle} layers={layersDisplayed} />
        <RulerTool
          units={units.distance}
          coordinates={rulerCoordinates}
          isRulerEnabled={isRulerEnabled}
          mapProjection={mapProjectionString}
          handleDragEvent={rulerCoordinatesChanged}
          mapApis={mapApis}
        />
      </StyledMap>
    </div>
  );
};
