// app config
import { authConfig } from 'src/config';
// utils
import { generateKey, pkceChallengeFromVerifier } from 'src/app/functions/pkce';
import {
  cleanupOutDatedAuthData,
  getAuth,
  setAuth,
  storeInfoBeforeRedirect,
  getCodeVerifier,
  cleanupAfterComingBack,
} from 'src/app/functions/storage';
import {
  isExpired,
  isLoginValid,
  isLogoutValid,
  expiryInMilliSecond,
} from 'src/app/functions/validation';
// constants
import { ONE_HOUR } from 'src/constants';

export const getUrlDetails = (): {
  siteName: null | string;
  pageRoute: string;
} => {
  const [, site, route] = window.location.pathname.split('/');
  const validate = value => (value !== undefined && value && value !== 'null' ? value : null);
  return {
    siteName: validate(site),
    pageRoute: validate(route) || '',
  };
};

export const getCurrentRoute = () => {
  const { pathname, search } = window.location;
  return `${pathname}${search}`;
};

export const getIdleTimeout = (): number => {
  const timeoutDuration: number = Number(window.localStorage.getItem('timeoutDuration')); // TODO: remove from delta4 once finished testing
  if (timeoutDuration && !isNaN(timeoutDuration)) {
    console.log(`temporary idle timeout: ${timeoutDuration / 60 / 1000} Min`);
    return Number(timeoutDuration);
  }
  const { idleSessionTimeout } = authConfig;
  return idleSessionTimeout ? Math.max(idleSessionTimeout, ONE_HOUR) : ONE_HOUR;
};

// Initiate the PKCE Auth Code flow when the sign-in link is clicked
export const initiateAuthCodeflow = async () => {
  const { siteName, pageRoute } = getUrlDetails();

  if (siteName) {
    cleanupOutDatedAuthData();

    // Create and store a random "state" value
    const state = `${encodeURI(siteName)}|${encodeURI(pageRoute)}|${generateKey()}`;
    // Create and store a new PKCE code_verifier (the plaintext random secret)
    const codeVerifier = generateKey();

    storeInfoBeforeRedirect({ state, route: getCurrentRoute(), codeVerifier });

    // Hash and base64-urlencode the secret to use as the challenge
    const codeChallenge = await pkceChallengeFromVerifier(codeVerifier);

    redirectToAuthorizationServer(state, codeChallenge);
  }
};

export const setAppUri = siteName => {
  cleanupAfterComingBack();
  // Replace the history entry to remove the auth code from the browser address bar
  window.history.replaceState({}, '', `/${siteName}`);
};

export const endpoint = (type: string) => {
  const { instance, tenant, signInPolicy } = authConfig;

  switch (type) {
    case 'authorize':
      return `${instance}/${tenant}/${signInPolicy}/oauth2/v2.0/authorize?`;
    case 'token':
      return `${instance}/${tenant}/${signInPolicy}/oauth2/v2.0/token`;
    case 'signout':
      return `${instance}/${tenant}/${signInPolicy}/oauth2/v2.0/logout?`;
    default:
      return '';
  }
};

// Redirect to the authorization server
export const redirectToAuthorizationServer = (state: string, codeChallenge: string) => {
  const { clientId, scopes, redirectUri } = authConfig;

  const params = {
    response_type: 'code',
    response_mode: 'query',
    client_id: encodeURIComponent(clientId),
    scope: encodeURIComponent(scopes),
    // redirect rul includes the sitename. This means more security; only valid sites are allowed to use the defined authorization endpoint
    redirect_uri: encodeURIComponent(`${redirectUri}`),
    state: encodeURIComponent(state),
    code_challenge: encodeURIComponent(codeChallenge),
    code_challenge_method: 'S256',
  };

  // Build the authorization URL
  const loginEndpoint = `${endpoint('authorize')}${Object.keys(params)
    .map(key => `${key}=${params[key]}&`)
    .join('')}`.slice(0, -1);

  window.location.href = loginEndpoint;
};

// Parse a query string into an object
export const parseQueryString = (str: string) => {
  const queryString = {};
  if (str === '') {
    return queryString;
  }
  const segments = str.split('&').map(s => s.split('='));
  segments.forEach(s => (queryString[s[0]] = s[1]));
  return queryString;
};

// Make a POST request and parse the response as JSON
export const sendPostRequest = (url: string, params: any, success, error) => {
  const request = new XMLHttpRequest();
  request.open('POST', url, true);
  request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  request.onload = () => {
    let body = {};
    try {
      body = JSON.parse(request.response);
    } catch (e) {
      console.error(`Error: Failed to get response. 00-01`);
    }

    if (request.status === 200) {
      success(request, body);
    } else {
      error(request, body);
    }
  };
  request.onerror = () => {
    error(request, {});
  };
  const body = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
  request.send(body);
};

// Exchange the authorization code for an access token
export const getToken = ({ code, codeVerifier }: { code: string; codeVerifier: string }): any =>
  new Promise((resolve, reject) => {
    const { clientId, redirectUri } = authConfig;

    // Exchange the authorization code for an access token
    sendPostRequest(
      endpoint('token'),
      {
        grant_type: 'authorization_code',
        client_id: clientId,
        redirect_uri: redirectUri,
        code,
        code_verifier: codeVerifier,
      },
      (request, body) => {
        if (body && body.access_token !== undefined && body.access_token) {
          resolve(body);
        } else {
          console.error(`Error: Failed to get response. 00-02`);
          reject();
        }
      },
      () => {
        // This could be an error response from the OAuth server, or an error because the
        // request failed such as if the OAuth server doesn't allow CORS requests
        console.error(`Error: Failed to get response. 00-03`);
        reject();
      }
    );
  });

export const renewToken = ({
  refreshToken,
  refreshTokenExpiry,
}: {
  refreshToken: string;
  refreshTokenExpiry: number;
}) =>
  new Promise((resolve, reject) => {
    if (refreshToken && refreshTokenExpiry && !isExpired(refreshTokenExpiry)) {
      const { clientId, scopes, redirectUri } = authConfig;
      // Exchange the authorization code for an access token
      sendPostRequest(
        endpoint('token'),
        {
          grant_type: 'refresh_token',
          client_id: clientId,
          scope: scopes,
          redirect_uri: redirectUri,
          refresh_token: refreshToken,
        },
        (request, body) => {
          if (body && body.access_token !== undefined && body.access_token) {
            resolve(body);
          } else {
            console.error(`Error: Failed to get response. 00-04`);
            reject();
          }
        },
        () => {
          // This could be an error response from the OAuth server, or an error because the
          // request failed such as if the OAuth server doesn't allow CORS requests
          console.error(`Error: Failed to get response. 00-05`);
          reject();
        }
      );
    } else {
      reject();
    }
  });

// Process authentication response; get a new token or renew a current token
export const processAuthResponse = response => {
  const {
    token_type,
    access_token,
    expires_in,
    profile_info,
    refresh_token,
    refresh_token_expires_in,
  } = response;
  const isProfileAvailable = typeof profile_info !== 'undefined' && profile_info;
  const isTokenAvailable =
    typeof token_type !== 'undefined' && typeof access_token !== 'undefined' && access_token;
  const isRefeshTokenAvailable = typeof refresh_token !== 'undefined' && refresh_token;
  const authorization = `${token_type} ${access_token}`;
  setAuth({
    authorization: isTokenAvailable ? authorization : '',
    expiry: expiryInMilliSecond(expires_in),
    profile: isProfileAvailable ? profile_info : null,
  });

  return {
    authorization: isTokenAvailable ? authorization : '',
    refreshToken: isRefeshTokenAvailable ? refresh_token : '',
    refreshTokenExpiry: isRefeshTokenAvailable ? expiryInMilliSecond(refresh_token_expires_in) : 0,
  };
};

export const defineAuthState = () => {
  const query: any = parseQueryString(window.location.search.substring(1));
  // after coming back from the login page
  if (Object.keys(query).length) {
    // Check if the server returned an error string
    if (query.error !== undefined && query.error_description !== undefined && query.error) {
      const { validSiteName } = isLoginValid(query.state);
      if (validSiteName) {
        setAppUri(validSiteName);
        return {
          state: 'start',
          query: {
            error: 'accessDenied',
          },
        };
      } else {
        console.error(`Error: Failed to validate. 00-06`);
        return {
          state: 'start',
          query: {
            displayButton: false,
          },
        };
      }
    }
    // Check if the server returned an authorization code
    else if (query.code !== undefined && query.code) {
      const { validSiteName } = isLoginValid(query.state);
      if (validSiteName) {
        query.codeVerifier = getCodeVerifier();
        setAppUri(validSiteName);
        return {
          state: 'code',
          query,
        };
      } else {
        console.error(`Error: Failed to validate. 00-07`);
        return {
          state: 'start',
          query: {},
        };
      }
    }
  }

  // logout
  const { siteName } = getUrlDetails();
  if (siteName === 'logout') {
    const { validSiteName, code } = isLogoutValid(query.state);
    if (validSiteName && code) {
      setAppUri(validSiteName);
      return {
        state: 'signout',
        query: {
          text: code,
          displayButton: code === '404' ? false : true,
          removeAuth: code === '404' ? false : true,
        },
      };
    } else {
      console.error(`Error: Failed to validate. 00-08`);
      // this is an invalid logout request, show the start screen but with no sign-in button
      return {
        state: 'start',
        query: {
          displayButton: false,
        },
      };
    }
  }

  const authData = getAuth();
  // authentication already done
  if (authData) {
    const { authorization, expiry } = authData;
    // Check if token is valid
    if (authorization && expiry && !isExpired(expiry)) {
      return {
        state: 'valid',
        query: {
          authorization,
        },
      };
    } else {
      return {
        state: 'signout',
        query: {
          text: 'sessionExpired',
          removeAuth: true,
        },
      };
    }
  } else {
    return {
      state: 'start',
      query: {},
    };
  }
};

// decode user jwt token
export const parseJwt = (token: string) => {
  const base64Url = token;
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((str: string) => {
        return '%' + ('00' + str.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const signOut = (code: string = '000') => {
  const { siteName } = getUrlDetails();
  // Create and store a random "state" value for signout
  // code: 000, 001, 403, 404
  const state = `${siteName}|${code}|${generateKey()}`;
  storeInfoBeforeRedirect({ state });

  if (siteName && code === '401') {
    // access token is invalid, redirect to the authorization/sign in page
    initiateAuthCodeflow();
  } else {
    const { redirectUri } = authConfig;
    const logoutEndpoint = `${endpoint('signout')}post_logout_redirect_uri=${encodeURI(
      `${redirectUri}/logout`
    )}&state=${state}`;
    window.location.href = logoutEndpoint;
  }
};
