export const operationSummariesQuery = {
  name: 'operationSummariesByTimeRange',
  schema: `
    {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      items{
        id
        acid
        operationType
        operatorCategory
        aircraftCategory
        aircraftType
        aircraftCount
        airportId
        endTime
        remoteAirportId
        runwayName
        startTime
        time
        {{subQueryOperationTags}}
        pca(position: $position) @include(if: $includePca) {
          elevationAngle
          horizontalDistance
          slantDistance
          time
          trackPoint {
            alt
            lat
            lon
            t
          }
          verticalDistance
        }
        {{subQueryCorrelated}}
      }
    }
  `,
};

export const operationDetailsQuery = {
  name: 'operationSummary',
  schema: `
    {
      time
      startTime
      endTime
      points {
        alt
        lat
        lon
        t
      }
      profile {
        alt: altitude
        dist: distance
        time: timeOffset
      }
      correlated {
        weather {
          precipitation
          cloudCover
          temperature
          relativeHumidity
          atmosphericPressure
          windSpeed
          windDirection
          visibility
          ceiling
        }
      }
      tags {
        name
      }
    }
  `,
};

export const fetchOperationQuery = {
  name: 'operation',
  schema: `
    {
      id
      acid
      airportId
      runwayName
      remoteAirportId
      aircraftCategory
      aircraftType
      aircraftCount
      beaconCode
      callSign
      flightNumber
      tailNumber
      operationType
      airline
      operatorCategory
      pathName
      aircraft {
        azbNoiseClassification
        icaoNoiseClassification
      }
    }
  `,
};

export const inAirQuery = {
  name: 'operationsByTimeRange',
  schema: `
    {
      id
      startTime
      endTime
      operationType
      aircraftCategory
      points {
        alt
        lat
        lon
        t
      }
    }
  `,
};
