import React, { useEffect, useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';

// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
import { useDataSelectors } from 'src/@infringements/reducers/dataReducer';
import { useSortSelectors } from 'src/@infringements/reducers/sortReducer';
import { InfringementDispatchContext } from 'src/@infringements/providers/InfringementsStateProvider';
// actions
import { selectRow, loadMore } from 'src/@infringements/actions/infringementsActions';
import { dataActionTypes } from 'src/@infringements/newActionTypes';
// components
import { Table } from '@ems/client-design-system';
import { LoadMoreBar } from 'src/components';
// function
import { convertObjectKeys } from 'src/utils/objectModifiers';
import { formatATCHeaders, formatATCInfringementData } from 'src/@infringements/functions';

import { ADD_OPERATION_TAGS, REMOVE_OPERATION_TAGS } from 'src/@infringements/mutations';
import { IInfringementsData } from 'src/@infringements/interfaces';
import { hideInfringementColumns } from 'src/utils/tableColumns';
import { isAtcView } from 'src/utils';

export const TableContainer = ({ source = '' }) => {
  const dispatcher = useContext<any>(InfringementDispatchContext);
  const dataSelectors = useDataSelectors();
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const atcView = isAtcView(source);
  const {
    data: latestData,
    pageInfo,
    isLoading,
    selectedData,
    areAllRowsSelected,
    isLoadingMore,
  } = dataSelectors.getDataInformation();
  const [addInfringementVectored] = useMutation(ADD_OPERATION_TAGS, {
    update(cache, { data: { addOperationTags } }) {
      dispatcher({
        type: dataActionTypes.INLINE_EDIT_VECTORED,
        data: {
          id: addOperationTags[0].id,
          vectored: true,
        },
      });
    },
  });
  const [removeInfringementVectored] = useMutation(REMOVE_OPERATION_TAGS, {
    update(cache, { data: { removeOperationTags } }) {
      dispatcher({
        type: dataActionTypes.INLINE_EDIT_VECTORED,
        data: {
          id: removeOperationTags[0].id,
          vectored: false,
        },
      });
    },
  });

  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { '12HourFormat': twelveHourFormat },
    grid: { resultSize },
    infringements: {
      grid: { hiddenColumns },
    },
  } = configSelectors.getConfig();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      buttons: { loadMore: loadMoreText },
      labels: {
        table: { endTable },
      },
      hints: { tryChangingFilters },
      lists: { aircraftCategories, operationTypes, ruleTypes, severities, infringementStatuses },
    },
    screens: {
      infringements: {
        errors: { noDataFound },
      },
    },
  } = languageSelectors.getLanguage();

  const [translationDataList, setTranslationDataList] = useState<object[]>([]);
  useEffect(() => {
    setTranslationDataList(
      convertObjectKeys({
        ...aircraftCategories,
        ...operationTypes,
        ...ruleTypes,
        ...severities,
        ...infringementStatuses,
      })
    );
  }, [aircraftCategories, operationTypes, ruleTypes, severities, infringementStatuses]);
  const columns = [
    'displayRuleName',
    'displayTime',
    'displayInfringement',
    'displaySeverity',
    'acid',
    'displayCategory',
    'aircraftType',
    'displayVectored',
  ];

  const loading = isLoading || isLoadingMore;
  const hiddenColumnsNonNull = hiddenColumns ? hiddenColumns : [];
  const showSeverity = !hiddenColumnsNonNull.includes('displaySeverity');
  // column types are used to add class-names to each column (used only for styling)
  // TODO: this logic needs to be handled in the design system, not here. We'll refactor when the table needs to be modified.
  const columnTypes = {
    rule: {
      title: 'rule',
      abbreviation: '',
    },
    acid: {
      title: 'record-title',
      abbreviation: '',
    },
    displayRunwayName: {
      title: 'runway',
      abbreviation: 'Rwy',
    },
    displayCategory: {
      title: 'aircraft',
      abbreviation: 'Categ',
    },
    displayTime: {
      title: 'time',
      abbreviation: '',
    },
    displayVectored: {
      title: 'vectored',
      abbreviation: '',
    },
    displayInfringement: {
      title: 'infringement',
      abbreviation: '',
      grow: showSeverity ? null : '2',
    },
    displaySeverity: {
      title: 'severity',
      abbreviation: '',
      grow: '2',
    },
    aircraftType: {
      title: 'model',
      abbreviation: '',
      grow: '3',
    },
  };

  const onSelectRow = id => {
    selectRow(id, dispatcher);
  };

  hideInfringementColumns({
    showSeverity,
    showCandidates: true,
    showStatus: true,
  });

  const handleVectoredChange = (bool, operationId) => {
    if (bool === true) {
      addInfringementVectored({
        variables: { ids: [operationId] },
      });
    } else {
      removeInfringementVectored({
        variables: { ids: [operationId] },
      });
    }
  };

  const [displayData, setDisplayData] = useState<IInfringementsData[]>([]);
  const { itemsIds, hasNextPage, endCursor, dateRange } = dataSelectors.getNavigationData();
  const ruleInfo = { itemsIds, hasNextPage, endCursor, dateRange };
  useEffect(() => {
    setDisplayData(
      formatATCInfringementData(
        latestData,
        translationDataList,
        ruleInfo,
        handleVectoredChange,
        twelveHourFormat
      )
    );
  }, [latestData, translationDataList]);

  return (
    <>
      <Table
        className={`infringements-table infringements-table--atc`}
        loading={isLoading || isLoadingMore}
        rowHeaders={formatATCHeaders(
          resultSize,
          loading,
          dispatcher,
          sortSelectors,
          languageSelectors.getLanguage()
        )}
        data={displayData}
        columns={columns}
        columnTypes={columnTypes}
        selectedData={selectedData}
        areAllRowsSelected={areAllRowsSelected}
        gridID="infringements"
        onSelectRow={onSelectRow}
        hasEnded={displayData.length && pageInfo && !pageInfo.hasNextPage}
        languageData={{ noDataTitle: noDataFound, noDataText: tryChangingFilters, endTable }}
      />
      <LoadMoreBar
        isVisible={pageInfo && pageInfo.hasNextPage ? true : false}
        isLoadingMore={isLoadingMore ? true : false}
        loadMore={loadMore}
        dispatcher={dispatcher}
        sortString={sortString}
        resultSize={resultSize}
        endCursor={pageInfo && pageInfo.endCursor}
        atcView={atcView}
        loadMoreText={loadMoreText}
      />
    </>
  );
};
