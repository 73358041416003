import { IComplainerFilterItem, IFilterItem } from 'src/@complaints/interfaces';
import { actionTypes } from 'src/@complaints/actionTypes';
import { TSource } from 'src/app/props';

export const updateSelectedFilterItems = (
  category: string,
  selectedItems: IFilterItem[],
  newDispatcher: any
): void => {
  newDispatcher({
    type: actionTypes.UPDATE_SELECTED_FILTER_ITEMS,
    data: { category, selectedItems },
  });
};

export const clearSelectedFilterItems = (newDispatcher: any): void => {
  newDispatcher({ type: actionTypes.CLEAR_SELECTED_FILTER_ITEMS });
};

export const updateRangeFilter = (
  type: string,
  value: string,
  field: TSource,
  newDispatcher: any
) => {
  newDispatcher({ type: actionTypes.UPDATE_RANGE_FILTER, data: { type, value, field } });
};

export const updateComplainerFilter = (
  complainer: IComplainerFilterItem | null,
  dispatcher: any
) => {
  dispatcher({ type: actionTypes.UPDATE_COMPLAINER_FILTER, data: { complainer } });
};
