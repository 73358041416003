import React from 'react';
import { Radio } from '@ems/client-design-system';
import classNames from 'classnames';

export const SettingsSelector: React.FC<{
  title: string;
  className: string;
  options: any;
  selected: string | null;
  onChangeHandler: (value: string) => void;
}> = ({ title, className, options, selected, onChangeHandler }) => {
  const onChange = (value: string) => {
    onChangeHandler(value);
  };

  return (
    <>
      <h2 className="settings-selector__title">{title}</h2>
      <fieldset className={`${className}__fieldset settings-selector__fieldset`}>
        {options.map((option, key) => {
          const { label, value, body } = option;
          return (
            <div
              key={key}
              className={classNames(`${className}__group settings-selector__group`, value)}>
              <Radio
                label={label}
                value={value}
                checked={value === selected}
                onChange={() => onChange(value)}
                className={`${className}__radio settings-selector__radio`}
              />
              {body}
            </div>
          );
        })}
      </fieldset>
    </>
  );
};
