import React, { FC, useEffect, useState, useCallback, useContext } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
import { useDataSelectors, useSortSelectors } from 'src/@infringementsCandidates/reducers';
import { InfringementDispatchContext } from 'src/@infringementsCandidates/providers/InfringementsStateProvider';
import { filterStore } from 'src/@infringementsCandidates/stores/filterStore';
// actions
import {
  selectRow,
  loadMore,
  resetAndFetchData,
} from 'src/@infringementsCandidates/actions/infringementsActions';
import { dataActionTypes } from 'src/@infringementsCandidates/newActionTypes';
// components
import { Table } from '@ems/client-design-system';
import { LoadMoreBar } from 'src/components';
// function
import { convertObjectKeys } from 'src/utils/objectModifiers';
import {
  formatInfringementHeaders,
  formatInfringementData,
} from 'src/@infringementsCandidates/functions';

import { UPDATE_INFRINGEMENT_STATUS } from 'src/@infringementsCandidates/mutations';
import { IInfringementsData } from 'src/@infringementsCandidates/interfaces';
import { hideInfringementColumns } from 'src/utils/tableColumns';

export const TableContainer: FC = () => {
  const client = useApolloClient();
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();

  const newDispatcher = useContext<any>(InfringementDispatchContext);
  const [updateInfringementStatus] = useMutation(UPDATE_INFRINGEMENT_STATUS, {
    update(cache, { data: { updateInfringement } }) {
      const id = updateInfringement.id;
      const status = updateInfringement.status;

      newDispatcher({
        type: dataActionTypes.INLINE_EDIT,
        data: {
          id,
          status,
        },
      });
    },
  });

  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { '12HourFormat': twelveHourFormat },
    grid: { resultSize },
    infringements: {
      grid: { hiddenColumns },
    },
  } = configSelectors.getConfig();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const translationStrings = languageSelectors.getLanguage();
  const {
    components: {
      buttons: { loadMore: loadMoreText },
      tooltip: { candidates: candidatesTooltipText },
      labels: {
        table: { endTable },
      },
      hints: { tryChangingFilters },
      lists: {
        aircraftCategories,
        candidates,
        operationTypes,
        ruleTypes,
        severities,
        infringementStatuses,
      },
    },
    screens: {
      infringements: {
        errors: { noDataFound },
      },
    },
  } = translationStrings;
  const dataSelectors = useDataSelectors();
  const {
    data: latestData,
    pageInfo,
    isLoading,
    selectedData,
    areAllRowsSelected,
    isLoadingMore,
  } = dataSelectors.getDataInformation();

  const [translationDataList, setTranslationDataList] = useState<object[]>([]);
  useEffect(() => {
    setTranslationDataList(
      convertObjectKeys({
        ...aircraftCategories,
        ...candidates,
        ...operationTypes,
        ...ruleTypes,
        ...severities,
        ...infringementStatuses,
      })
    );
  }, [aircraftCategories, candidates, operationTypes, ruleTypes, severities, infringementStatuses]);

  // Always 'show' all columns, then hide the ones we don't need.
  const columns = [
    'displayRuleName',
    'displayTime',
    'displayInfringement',
    'displaySeverity',
    'acid',
    'displayCategory',
    'aircraftType',
    'displayStatus',
    'displayCandidates',
  ];

  const loading = isLoading || isLoadingMore;
  const hiddenColumnsNonNull = hiddenColumns ? hiddenColumns : [];
  const showSeverity = !hiddenColumnsNonNull.includes('displaySeverity');
  const showCandidates = !hiddenColumnsNonNull.includes('displayCandidates');
  const showStatus = !hiddenColumnsNonNull.includes('displayStatus');
  // column types are used to add class-names to each column (used only for styling)
  // TODO: this logic needs to be handled in the design system, not here. We'll refactor when the table needs to be modified.
  const columnTypes = {
    rule: {
      title: 'rule',
      abbreviation: '',
    },
    acid: {
      title: 'record-title',
      abbreviation: '',
    },
    displayRunwayName: {
      title: 'runway',
      abbreviation: 'Rwy',
    },
    displayCategory: {
      title: 'aircraft',
      abbreviation: 'Categ',
    },
    displayTime: {
      title: 'time',
      abbreviation: '',
    },
    displayStatus: {
      title: 'status',
      abbreviation: '',
    },
    displayInfringement: {
      title: 'infringement',
      abbreviation: '',
      grow: showSeverity ? null : '2',
    },
    displaySeverity: {
      title: 'severity',
      abbreviation: '',
      grow: '2',
    },
    aircraftType: {
      title: 'model',
      abbreviation: '',
      grow: '3',
    },
    displayCandidates: {
      title: 'candidates',
      abbreviation: '',
    },
  };

  const handleInlineDropdown = useCallback((infringementId, newStatus, infringementType) => {
    updateInfringementStatus({
      variables: { id: infringementId, status: newStatus },
      optimisticResponse: {
        __typename: infringementType,
        updateInfringement: {
          id: infringementId,
          status: newStatus,
          __typename: infringementType,
        },
      },
    });
  }, []);

  const onSelectRow = id => {
    selectRow(id, newDispatcher);
  };

  hideInfringementColumns({
    showSeverity,
    showStatus,
    showCandidates,
  });

  const [displayData, setDisplayData] = useState<IInfringementsData[]>([]);
  const [candidateFlag, setCandidateFlag] = useState<boolean>(false);
  const { itemsIds, hasNextPage, endCursor, dateRange } = dataSelectors.getNavigationData();
  const ruleInfo = { itemsIds, hasNextPage, endCursor, dateRange };
  useEffect(() => {
    setDisplayData(
      formatInfringementData(
        latestData,
        translationDataList,
        lightSwitchState,
        ruleInfo,
        twelveHourFormat
      )
    );
  }, [latestData, translationDataList, handleInlineDropdown]);
  const lightSwitchState = dataSelectors.getCandidatesEnabled();

  const handleCandidatesChange = bool => {
    setCandidateFlag(bool);
    newDispatcher({ type: dataActionTypes.SET_CANDIDATES_ENABLED, data: bool });
    resetAndFetchData(client, resultSize, newDispatcher, sortString, bool); // fetch the next page
  };

  const ruleFilterApplied = filterStore.isFilterApplied('ruleNames');

  return (
    <>
      <Table
        className={`infringements-table infringements-table--regular`}
        loading={isLoading || isLoadingMore}
        rowHeaders={formatInfringementHeaders(
          resultSize,
          loading,
          lightSwitchState,
          handleCandidatesChange,
          ruleFilterApplied,
          candidatesTooltipText,
          translationStrings
        )}
        data={displayData}
        columns={columns}
        columnTypes={columnTypes}
        selectedData={selectedData}
        areAllRowsSelected={areAllRowsSelected}
        gridID="infringementsCandidates"
        onSelectRow={onSelectRow}
        hasEnded={latestData.length && pageInfo && !pageInfo.hasNextPage}
        languageData={{ noDataTitle: noDataFound, noDataText: tryChangingFilters, endTable }}
      />
      <LoadMoreBar
        isVisible={pageInfo && pageInfo.hasNextPage ? true : false}
        isLoadingMore={isLoadingMore ? true : false}
        loadMore={loadMore}
        dispatcher={newDispatcher}
        sortString={sortString}
        showCandidates={candidateFlag}
        resultSize={resultSize}
        endCursor={pageInfo && pageInfo.endCursor}
        loadMoreText={loadMoreText}
      />
    </>
  );
};
