// client
import ApolloClient from 'apollo-client';
// actionTypes
import { actionTypes } from 'src/@settings/actionTypes';
// resolvers
import { fetchAirlines } from 'src/@settings/resolvers';
// interfaces
import { IAirlineMapping } from 'src/@settings/interfaces';

export const getAirlines = (
  client: ApolloClient<object>,
  dispatcher: any,
  scenarioIds: number[]
) => {
  dispatcher({ type: actionTypes.AIRLINES_LOADING });
  fetchAirlines(client, scenarioIds)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_AIRLINES, fleetMixData: data });
    })
    .catch(error => {
      console.error(error);
      dispatcher({ type: actionTypes.AIRLINES_LOADING });
    });
};

export const updateCurrentAirlineMappings = async (data: IAirlineMapping[], dispatcher: any) => {
  await dispatcher({ type: actionTypes.UPDATE_CURRENT_AIRLINE_MAPPINGS, fleetMixData: data });
};

export const updateCurrentAirline = async (data: IAirlineMapping[], dispatcher: any) => {
  await dispatcher({ type: actionTypes.UPDATE_CURRENT_AIRLINE, currentAirline: data });
};
