import React, { createContext, useReducer } from 'react';
import { geometryInitialState, geometryReducer } from 'src/app/reducers/geometryReducer';
export const GeometryStateContext = createContext({
  data: geometryInitialState,
});
export const GeometryDispatchContext = createContext(null);
export const GeometryStateProvider = ({ children }) => {
  const [geometryState, geometryDispatch] = useReducer<any>(geometryReducer, geometryInitialState);
  const state: any = {
    data: geometryState,
  };
  const dispatchers = [geometryDispatch];
  const dispatch: any = (action: any) =>
    dispatchers.map((dispatcher: any) => {
      dispatcher(action);
    });
  return (
    <GeometryStateContext.Provider value={state}>
      <GeometryDispatchContext.Provider value={dispatch}>
        {children}
      </GeometryDispatchContext.Provider>
    </GeometryStateContext.Provider>
  );
};
