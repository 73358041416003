import { useContext } from 'react';
// interfaces
import { IFleetMixState, IActions, IFleetMixSelector } from '../interfaces';
// helpers
import { useSelectors } from 'src/utils/storeHelpers';
// actionTypes
import { actionTypes } from 'src/@settings/actionTypes';
// provider
import { SettingsStateContext } from '../provider/SettingsStateProvider';
// libraries
import uuid from 'uuid';
// functions
import { getFilteredAirlineMappings } from '../functions';
import { groupBy } from 'src/utils';
import { AIRCRAT_MODEL_SEPERATOR } from 'src/constants';

export const useFleetMixSelector: () => IFleetMixSelector = () => {
  const state: any = useContext(SettingsStateContext);
  const fleetMixState: IFleetMixState = state.fleetMix;
  return useSelectors(fleetMixState, (state: IFleetMixState) => ({
    getAirlines: () => state.aircraftFlights,
    getAircrafts: () => state.aircrafts,
    getAirlineMappings: () => state.airlineMappings,
    getLtoEmissions: () => state.ltoAircraftEngineMappings,
    getEnrouteEmissions: () => state.enrouteAircraftEngineMappings,
    getCurrentAirlineMappings: () => state.currentAirlineMappings,
    getCurrentAirline: () => state.currentAirline,
    getIfLoading: () => state.isLoading,
  }));
};

export const fleetMixInitialState: IFleetMixState = {
  aircraftFlights: [],
  aircrafts: [],
  airlineMappings: [],
  ltoAircraftEngineMappings: [],
  enrouteAircraftEngineMappings: [],
  currentAirlineMappings: [],
  currentAirline: '',
  isLoading: false,
};

const addTableId = (newData: any) => {
  const data = new Array();
  if (newData && newData.length > 0) {
    let index = 0;
    for (const mapping of newData) {
      const ltoAircraft = mapping.mappedLTOAircraft == null ? '?' : mapping.mappedLTOAircraft;
      const enrouteAircraft = mapping.mappedEnrouteAircraft == null ? '?' : mapping.mappedEnrouteAircraft;
      const ltoEngine = mapping.mappedLTOEngine == null ? '?' : mapping.mappedLTOEngine;
      const enroutEngine = mapping.mappedEnrouteEngine == null ? '?' : mapping.mappedEnrouteEngine;
      data.push({
        id: mapping.id,
        scenarioId: mapping.scenarioId,
        airline: mapping.airline,
        numberOfAircraft: mapping.numberOfAircraft,
        aircraftType: mapping.aircraftType,
        mappedLTOAircraft: ltoAircraft + AIRCRAT_MODEL_SEPERATOR + ltoEngine,
        mappedEnrouteAircraft: enrouteAircraft + AIRCRAT_MODEL_SEPERATOR + enroutEngine,
        tableId: uuid.v4(),
      });
      index = index + 1;
    }
  }
  return data;
};

const getUniqueAircrafts = data => {
  const aircraftsMappings = data.filter(
    (item, index, self) => index === self.findIndex(mapping => mapping.aircraftType === item.aircraftType)
  );
  const aircrafts = aircraftsMappings.map(item => {
    return item.aircraftType;
  });
  return aircrafts;
};

const getCurrentAirline = airlinesData =>{
  return airlinesData && airlinesData.length > 0 ? airlinesData[0].airline : '';
}

export const fleetMixReducer = (state: IFleetMixState, action: IActions) => {
  switch (action.type) {
    case actionTypes.AIRLINES_LOADING:
      return Object.assign({}, state, { isLoading: true});
    case actionTypes.GET_LTO_MAPPINGS:
        return Object.assign({}, state, { ltoAircraftEngineMappings: action.ltoAircraftEngineMappings });
    case actionTypes.GET_ENROUTE_MAPPINGS:
        return Object.assign({}, state, { enrouteAircraftEngineMappings: action.enrouteAircraftEngineMappings });
    case actionTypes.GET_AIRCRAFT_FLIGHTS:
        return Object.assign({}, state, { aircraftFlights : groupBy(action.aircraftFlights, ['airline'], 'numberOfFlights') });
    case actionTypes.GET_AIRLINES: {
      const airlineMappings = action.fleetMixData;
      const airlineMappingsWithTableID = addTableId(airlineMappings);
      const currentAirline = getCurrentAirline(state.aircraftFlights);
      const currentMappings = (state.aircraftFlights && state.aircraftFlights.length > 0) ? getFilteredAirlineMappings(currentAirline, airlineMappingsWithTableID) : [];
      return Object.assign({}, state, {
        aircrafts: getUniqueAircrafts(airlineMappings),
        airlineMappings: airlineMappingsWithTableID,
        currentAirlineMappings: currentMappings,
        currentAirline,
        isLoading: false,
      });
    }
    case actionTypes.UPDATE_CURRENT_AIRLINE:
      return Object.assign({}, state, { currentAirline: action.currentAirline })
    case actionTypes.UPDATE_CURRENT_AIRLINE_MAPPINGS:
      return Object.assign({}, state, { currentAirlineMappings: action.fleetMixData });
    case actionTypes.UPDATE_AIRLINE_MAPPINGS: {
      const updatedAirlineMappings = addTableId(action.fleetMixData);
      const updatedCurrentMappings = (state && state.aircraftFlights.length > 0) ? getFilteredAirlineMappings(state.currentAirline, updatedAirlineMappings) : [];
      return Object.assign({}, state, {
        airlineMappings: updatedAirlineMappings,
        currentAirlineMappings: updatedCurrentMappings,
        isLoading: false,
      });
    }
    default:
      return state;
  }
};
