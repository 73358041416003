export const fetchInfringementsQuery = 'infringementSummariesByTimeRange';
export const fetchInfringementsCandidatesQuery = 'infringementCandidateSummariesByTimeRange';
export const fetchInfringementSummary = 'infringement';
export const fetchOperationDetailsQuery = 'operationSummary';
export const fetchRuleDetailsQuery = 'infringementRule';
export const fetchInAirQuery = 'operationsByTimeRange';
export const fetchNoiseEventQuery = 'noiseEventSummariesByIds';
export const fetchNoiseMonitorQuery = 'monitorLocationsByIds';
export const fetchContinuousNoiseQuery = 'continuousNoiseSamplesByTimeRange';
export const fetchNoiseMonitorByOpsQuery = 'monitorLocationsByAirportIds';

export const infringementsSchema = `
{
  totalCount
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
  items {
    id
    operationId
    infringementType
    ruleId
    ruleName
    status
    aircraftType
    aircraftCategory
    acid
    time
    airportId
    position {
      altitude
      latitude
      longitude
    }
    severity
    operationType
    detail {
      id
      ... on CorridorInfringement {
        corridorId
      }
      ... on ExclusionInfringement {
        selectionZoneId
      }
      ... on GateInfringement {
        gateId
        gateDirection
        speed
        reason
      }
      ... on NoiseInfringement {
        noiseEventIds
      }
    }
    operationTags(returnOnly:["Vector"]) @include(if: $includeOperationTags) {
      tagId
      name
      isCompleted
    }
  }
}
`;

export const infringementsCandidatesSchema = `
{
  totalCount
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
  items {
    operationId
    infringementType
    ruleId
    ruleName
    status
    aircraftType
    aircraftCategory
    acid
    detail {
      id
      ... on CorridorInfringement {
        corridorId
      }
      ... on ExclusionInfringement {
        selectionZoneId
      }
      ... on GateInfringement {
        gateId
        gateDirection
        speed
        reason
      }
      ... on NoiseInfringement {
        noiseEventIds
      }
    }
    isInfringement
    time
    airportId
    position {
      altitude
      latitude
      longitude
    }
    severity
    operationType
    infringementId
    rule {
      ... on CorridorInfringementRule {
        corridorId
      }
    }
  }
}
`;

export const summarySchema = `
{
  infringementType
  id
  ruleId
  ruleName
  operationId
  time
  comments
  position {
    altitude
    latitude
    longitude
  }
  status
  ... on CcoInfringement {
    segments {
      startDistance
      endDistance
    }
  }
  ... on CdoInfringement {
    segments {
      startDistance
      endDistance
    }
  }
  ... on CorridorInfringement {
    corridorId
  }
  ... on ExclusionInfringement {
    selectionZoneId
  }
  ... on GateInfringement {
    gateId
    gateDirection
    speed
    reason
  }
  ... on NoiseInfringement {
    noiseEventIds
  }
}
`;

export const operationDetailsSchema = `
  {
    id
    acid
    airline
    aircraftCategory
    aircraftType
    airportId
    operationType
    remoteAirportId
    runwayName
    startTime
    endTime
    correlated {
      weather {
        precipitation
        cloudCover
        temperature
        relativeHumidity
        atmosphericPressure
        windSpeed
        windDirection
        visibility
        ceiling
      }
    }
    points
    {
      t
      lat
      lon
      alt
    }
    profile {
      alt: altitude
      dist: distance
      time: timeOffset
    }
    tags(returnOnly:["Vector"]) {
      tagId
      name
      isCompleted
    }
  }
`;

export const operationPlaybackSchema = `
{
  id
  startTime
  endTime
  acid
  airline
  runwayName
  airportId
  operationType
  aircraftCategory
  points
  {
    t
    lat
    lon
    alt
  }
}`;

export const ruleDetailsSchema = `
  {
    infringementType
    description
    name
    ... on CcoInfringementRule {
      id
      maxAltitudeVariation
      autoInfringementSegmentLength
      ceilingAltitude
      floorAltitude
      maxLevelSegments
      minLevelSegmentLength
    }
    ... on CdoInfringementRule {
      id
      maxAltitudeVariation
      autoInfringementSegmentLength
      ceilingAltitude
      floorAltitude
      maxLevelSegments
      minLevelSegmentLength
    }
    ... on CorridorInfringementRule {
      id
      name
      allowedPassage
      ceilingAltitude
      corridorId
    }
    ... on GateInfringementRule {
      gateCriteria {
        altitudeRange {
          start
          end
        }
        gateDirections
        gateId
        intersectionMode
        speedRange {
          start
          end
        }
      }
    }
    ... on CurfewInfringementRule {
      candidateFilter {
        startTime
        endTime
      }
    }
    ... on ExclusionInfringementRule {
      selectionZoneId
    }
    ... on MinHeightInfringementRule {
      id
      requiredAltitude
      flightDistance
    }
    ... on NoiseInfringementRule {
      metric
      thresholds {
        locationId
        threshold
      }
    }
  }
`;

export const noiseEventSchema = `
{
  audio {
    id
    startTime
    endTime
    resourceUri {
      uri
    }
  }
  duration
  endTime
  epnl
  id
  leq
  locationId
  locationName
  maxLevel
  samples {
    sampleType
    samples
  }
  sel
  startTime
  threshold
  time
}`;

export const continuousNoiseSchema = `
{
  locationId
  startTime
  endTime
  sampleType
  sampleResolution
  samples
}
`;

export const noiseMonitorSchema = `
{
  id
  position {
    latitude
    longitude
  }
}
`;
