import React from 'react';
import cx from 'classnames';
import { USER_SETTINGS } from 'src/constants';
import './styles.scss';

export const PageSkeleton = () => {
  const isPortrait = () => {
    try {
      const settings = JSON.parse(localStorage.getItem(USER_SETTINGS) || '');
      return settings && typeof settings.isPortrait !== 'undefined' && settings.isPortrait
        ? true
        : false;
    } catch {
      return true;
    }
  };

  return (
    <>
      <div className="navbar-container" />
      <div className="navbar-children">
        <div
          className={cx('page-wrapper', {
            'page-wrapper--landscape': !isPortrait(),
          })}>
          <div className="map-skeleton" />
          <div className="content-skeleton" />
        </div>
      </div>
    </>
  );
};
