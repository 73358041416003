import React, { ReactElement } from 'react';
import { isMobileOnly } from 'react-device-detect';
import cx from 'classnames';
import { NavBarContainer } from 'src/app/containers/NavBarContainer';
import './styles.scss';

export const NavBar = ({
  subMenuLinkSelected,
  children,
}: {
  subMenuLinkSelected?: (selectedObject) => void;
  children: ReactElement;
}) => {
  // TODO: Once all pages are mobile-friendly then use this logic to switch to mobile view when screen width smaller that the defined breakpoint
  // const { width } = useWindowDimensions();
  // const mobileMode = isMobile || width < MOBILE_SCREEN_WIDTH;
  const mobileMode = isMobileOnly;

  return (
    <>
      <NavBarContainer isMobile={mobileMode} subMenuLinkSelected={subMenuLinkSelected} />
      <div
        className={cx('navbar-children', {
          mobile: mobileMode,
        })}>
        {children}
      </div>
    </>
  );
};
