import { useContext } from 'react';
import { IInfringementRulesSelectors, IStoreState, IInfringementRulesState } from 'src/app/props';
import { appActionTypes } from 'src/app/newActionTypes';
import { GlobalStateContext } from 'src/app/providers/GlobalStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';

export const useInfringementRulesSelectors: () => IInfringementRulesSelectors = () => {
  const state: IStoreState = useContext(GlobalStateContext);
  const infringementRulesState: IInfringementRulesState = state.infringementRules;

  return useSelectors(infringementRulesState, (state: IInfringementRulesState) => ({
    hasCompletedInitialLoad: () => state.initialLoadCompleted,
    getRules: () => state.rules,
    getRule: (id: number) => {
      if (state.rules.length && id) {
        const rule = state.rules.find(rule => rule.id === id);
        return rule;
      } else {
        return {};
      }
    },
    hasAccessFailed: () => state.failedToAccessInfringmentRules,
    getGateIdsByRuleId: (id: number) => {
      const rule = state.rules.find(rule => rule.id === id);
      if (rule && rule.gateCriteria) {
        return rule.gateCriteria.map(gates => gates.gateId);
      } else {
        return [];
      }
    },
  }));
};

export const infringementRulesInitialState: IInfringementRulesState = {
  rules: [],
  failedToAccessInfringmentRules: false,
  initialLoadCompleted: false,
};

export const infringementRulesReducer = (state: IInfringementRulesState, action: any) => {
  switch (action.type) {
    case appActionTypes.UPDATE_INFRINGEMENT_RULES:
      return Object.assign({}, state, {
        rules: action.payload,
        initialLoadCompleted: true,
      });
    case appActionTypes.IGNORE_INFRINGEMENT_RULES:
      return Object.assign({}, state, {
        initialLoadCompleted: true,
      });
    case appActionTypes.INFRINGEMENT_RULES_ERROR:
      return Object.assign({}, state, {
        rules: null,
        failedToAccessInfringmentRules: true,
      });
    default:
      return state;
  }
};
