import React from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from 'src/app/routes';
// screen
import { NotFound } from 'src/screens/notFound';
import { NoAccess } from 'src/screens/noAccess';
// props
import { IRolesSelectors } from 'src/app/props';
// utils
import { getDeployedProductId } from 'src/utils/generics';
import { getRoute, removeRoute } from 'src/app/functions/storage';
// constants
import { DEPLOYED_PRODUCT_ID } from 'src/constants';

export const getRoutes = (
  defaultRouteName: string,
  availableRoutes: string[],
  rolesSelectors: IRolesSelectors
) => {
  // constructing app routes
  const appRoutes: any = [];
  let defaultRoute: any;
  let setDefaultRoute: boolean = true;

  const routesLength = routes && routes.length;

  if (routesLength) {
    for (let i = routesLength; i--; ) {
      const { route, permissions, component } = routes[i];
      if (availableRoutes.includes(route)) {
        let canAccessRoute: boolean = true;
        for (const permission of permissions) {
          if (!rolesSelectors.canAccess(permission.name, permission.type)) {
            canAccessRoute = false;
            break;
          }
        }

        // Check default route
        // Since the for loop iterates from footer links upwards, we keep setting it until we hit the default route
        // or until we hit the highest link in the header that matches.
        if (canAccessRoute && setDefaultRoute) {
          defaultRoute = routes[i];
        }

        // Once we have hit our default route name, don't try to set it to any other route
        if (route === defaultRouteName) {
          setDefaultRoute = false;
        }

        // show component when permission is available
        appRoutes.unshift({
          path: `/:${DEPLOYED_PRODUCT_ID}/${route}`,
          component: canAccessRoute ? component : NoAccess,
        });
        // error on child that doesn't exist
        appRoutes.unshift({
          path: `/:${DEPLOYED_PRODUCT_ID}/${route}/:unknownRoute`,
          component: NotFound,
        });
      }
    }
  }

  // route did not match
  appRoutes.push({
    path: `/:${DEPLOYED_PRODUCT_ID}/:unknownRoute`,
    component: NotFound,
  });

  // add redirect to default if default is available
  if (defaultRoute !== undefined && defaultRoute) {
    appRoutes.push({
      path: `/:${DEPLOYED_PRODUCT_ID}`,
      component: ({ match: { params } }) => {
        return <Redirect to={`/${params[DEPLOYED_PRODUCT_ID]}/${defaultRoute.route}`} />;
      },
    });
  }

  // none of the paths matched
  appRoutes.push({
    component: ({ match: { params } }) => {
      // handle default route after a successful authentication
      if (!Object.keys(params).length && getDeployedProductId()) {
        const storedRoute = getRoute();
        if (storedRoute) {
          // enable shareable urls for the app after successful authentication
          removeRoute();
          return <Redirect to={storedRoute} />;
        } else {
          return <Redirect to={`/${getDeployedProductId()}/${defaultRouteName}`} />;
        }
      } else {
        return <NotFound />;
      }
    },
  });

  return appRoutes;
};
