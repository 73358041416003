import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useApolloClient } from '@apollo/react-hooks';
// containers
import {
  OperationDetailsContainer,
  NoisePanelContainer,
  SingleDetailContainer,
  NoiseCauseContainer,
} from 'src/containers';
import { MapContainer } from 'src/@noiseEvents/containers/Summary';
import { ExportContainer } from 'src/containers/ExportContainer';
// components
import { ItemsNavigator, CommentBox, Icons, Spinner } from '@ems/client-design-system';
import { SummaryHeader } from 'src/components/PageHeader';
import { PlaybackControl } from 'src/components/PlaybackControl';
import { AudioPlayback } from 'src/components/AudioPlayback';
import { ContinuousNoiseGraph } from 'src/components/ContinuousNoiseGraph';
import { ProfileGraph } from 'src/components/ProfileGraph';
// hoc
import { withPermissionsCheck, withAvailabilityChecks } from 'src/app/hocs/withPermissionsCheck';
// resolvers
import {
  fetchNoiseEventsById,
  fetchNoiseMonitorLocations,
  fetchOperationDetails,
  fetchProfilesById,
} from 'src/@noiseEvents/resolvers/summaryResolver';
import { fetchContinuousNoiseData } from 'src/@infringements/resolvers/summaryResolver';
import { fetchInAirTracksForPlayback } from 'src/@noiseEvents/resolvers/getDataResolver';
// selectors
import { useConfigSelectors, useLanguageSelectors, useExportSelectors } from 'src/app/reducers';
// functions
import { rememberSavedFilters, addPaddingToDuration } from 'src/utils';
import { getPaginationInformation, goBack, navigate } from 'src/app/functions/itemsNavigation';
import { processNoiseData } from 'src/app/functions/noise';
// constants
import { NOISEEVENT, COMMENT_STATUS_LOADED, COMMENT_STATUS_LOADING } from 'src/constants';
import { DATA_EXPORT } from 'src/app/featureToggles';
import { IMonitorData, INoiseEventData, IOperationData } from 'src/@noiseEvents/interfaces';

export const ContentContainer = ({ id, path, paginationInfo }) => {
  // FIX: CHANGE TO TRUE OR REMOVE ONCE THIS IS AVAILABLE
  const showCommentBox = false;

  const [loading, updateLoadingState] = useState<boolean>(true);
  const [noiseEventData, setNoiseEventData] = useState<INoiseEventData>({ hasData: false });
  const [monitorData, setMonitorData] = useState<IMonitorData>({});
  const [operationData, setOperationData] = useState<IOperationData>({});
  const [inAirData, setInAirData] = useState<IOperationData[]>([]);
  const [noiseMonitorData, setNoiseMonitorData] = useState<any>([]);
  const [comment, setComment] = useState<string>('');
  const [commentStatus, setCommentStatus] = useState<'loading' | 'loaded' | 'saved'>(
    COMMENT_STATUS_LOADED
  );
  // Playback states
  const [isPlaybackMode, setIsPlaybackMode] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [runningStatus, setRunningStatus] = useState<boolean>(true);
  const [draggingStatus, setDraggingStatus] = useState<boolean>(false);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(0);
  const [processedNoiseData, setProcessedNoiseData] = useState<any>([]);
  const [selectedNMT, setSelectedNMT] = useState<any>({ key: null, label: null });
  const [showProfileGraph, setShowProfileGraph] = useState<boolean>(false);
  const client = useApolloClient();

  // TODO: Update this to use withAvailabilityChecks hoc - withPermissionsCheck is redundant
  const CommentBoxHoc = useMemo(
    () => withPermissionsCheck(CommentBox, 'NoiseEvent.Read', 'NoiseEvent.Update'),
    []
  );

  // data export feature
  const DataExportFeature = useMemo(
    () =>
      withAvailabilityChecks(ExportContainer, {
        feature: DATA_EXPORT,
        permissions: 'Export',
      }),
    []
  );
  const isExportEnabled =
    (!isPlaybackMode && runningStatus) ||
    (isPlaybackMode && !runningStatus) ||
    (!isPlaybackMode && !runningStatus);

  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { noiseSampleType },
  } = configSelectors.getConfig();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      noiseMonitor: { title: noiseMonitorTitle },
      headings: { comments: commentsBoxTitle },
      buttons: { save, saved },
    },
    screens: {
      noiseEvents: { title: goBackTitle },
    },
  } = languageSelectors.getLanguage();

  // Check state of export so we can force required areas (graphs) to display
  const exportData = useExportSelectors();
  const exportedImages = exportData.captureImage();

  const resetPageData = () => {
    setNoiseEventData({ hasData: false });
    setMonitorData({});
    setOperationData({});
    setProcessedNoiseData([]);
  };

  useEffect(() => {
    rememberSavedFilters(NOISEEVENT);
    if (id) {
      getNoiseEvents();
    }
  }, [id]);

  const getNoiseEvents = () => {
    fetchNoiseEventsById(client, id)
      .then(({ data }: any) => {
        if (data.length) {
          setNoiseEventData({ ...data[0], hasData: true });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (noiseEventData.hasData) {
      fetchNoiseMonitorLocations(client, [noiseEventData.locationId])
        .then(({ data }: any) => {
          if (typeof data !== 'undefined' && data.length) {
            setNoiseMonitorData(data);
            setMonitorData(data[0]);
          } else {
            setNoiseMonitorData([]);
            setMonitorData({});
          }
        })
        .catch(error => {
          console.error(error);
        });

      const duration = addPaddingToDuration({
        seconds: 60,
        startTime: noiseEventData.startTime,
        endTime: noiseEventData.endTime,
      });

      const playbackDuration = duration.duration;
      const playbackStartEnd = { startTime: duration.startTime, endTime: duration.endTime };

      fetchContinuousNoiseData(
        client,
        [Number(noiseEventData.locationId)],
        noiseSampleType,
        duration
      )
        .then((data: any) => {
          setProcessedNoiseData(
            processNoiseData([noiseEventData], data.data, playbackDuration, playbackStartEnd)
          );
        })
        .catch(error => {
          setProcessedNoiseData(
            processNoiseData([noiseEventData], [], playbackDuration, playbackStartEnd)
          );
          // TODO: handle this error more gracefully - still valid if continuous data is not shown
          console.error(error);
        });
      if (noiseEventData.operationId) {
        getOperationDetails(noiseEventData.operationId);
      }

      fetchInAirTracksForPlayback(client, noiseEventData.time, noiseEventData.time).then(
        (data: any) => {
          const initialInAirData = data.data;
          const ids = initialInAirData.map(e => e.id);
          fetchProfilesById(client, ids).then((profileData: any) => {
            const mergedData = initialInAirData.map(e => {
              const foundData = profileData.data.find(d => d.id === e.id);
              return foundData
                ? {
                    ...e,
                    profile: foundData.profile,
                  }
                : e;
            });

            setInAirData(mergedData);
            updateLoadingState(false);
          });
        }
      );
    }
  }, [noiseEventData]);

  const getOperationDetails = (operationId: number) => {
    fetchOperationDetails(client, operationId)
      .then(({ data }: any) => {
        setOperationData(data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (processedNoiseData.length && noiseMonitorData.length) {
      const fidx = processedNoiseData.findIndex(e => e.locationId === selectedNMT.key);
      if (selectedNMT.key && fidx !== -1) {
        return;
      }

      const locationId = processedNoiseData[0].locationId;
      const found = noiseMonitorData.find(e => e.id === locationId);
      if (found) {
        setSelectedNMT({
          key: locationId,
          label: found.name,
        });
      }
    }
  }, [processedNoiseData, noiseMonitorData]);

  const audioArray = useMemo(() => {
    const dataArray = [noiseEventData];
    return dataArray.length && selectedNMT.key
      ? dataArray.filter(data => data.locationId === selectedNMT.key).map(data => data.audio)
      : null;
  }, [noiseEventData, selectedNMT]);
  const hasAudio = useMemo(() => {
    if (!audioArray) {
      return false;
    }

    return audioArray.findIndex(e => e && e.resourceUri && e.resourceUri.uri) !== -1;
  }, [audioArray]);

  const causeUpdated = (dynamic: boolean) => {
    // if only switching between static options then no need to reload map and operation container
    if (dynamic || noiseEventData.operationId) {
      setOperationData({});
      setIsPlaybackMode(false);
      setShowProfileGraph(false);
      getNoiseEvents();
    }
  };

  const onSubmit = (text: string) => {
    // set status to loading
    setCommentStatus(COMMENT_STATUS_LOADING);
    // mutation to change data
    setComment(text);
  };

  // navigation funcionality
  const paging = getPaginationInformation(Number(id), paginationInfo);

  const noiseDetails = {
    lmax: noiseEventData.maxLevel,
    sel: noiseEventData.sel,
    threshold: noiseEventData.threshold,
    duration: noiseEventData.duration,
    epnl: noiseEventData.epnl,
    hasData: noiseEventData.hasData,
  };

  const monitorTitle =
    monitorData.id && monitorData.name ? `${monitorData.id}. ${monitorData.name}` : '';

  if (loading) {
    return (
      <>
        <div className="map-skeleton">
          <Spinner loading={true} size="xl" centered={true} />
        </div>
        <div className="content-skeleton" />
      </>
    );
  }

  return (
    <>
      <div className="map_wrapper noise">
        <MapContainer
          currentTime={currentTime}
          isPlaybackMode={isPlaybackMode}
          inAirData={inAirData}
          noiseMonitors={noiseMonitorData}
          noiseData={noiseEventData.hasData ? [noiseEventData] : []}
          processedNoiseData={processedNoiseData}
          markedTime={
            noiseEventData.time && noiseEventData.operationId
              ? [{ time: noiseEventData.time, id: noiseEventData.operationId }]
              : []
          }
          pointData={operationData.points ? [operationData] : []}
          isPlaybackRunning={runningStatus}
        />
        <div className="map-overlay-panel">
          <ContinuousNoiseGraph
            noiseData={processedNoiseData}
            currentTime={isPlaybackMode ? currentTime : 0}
            shouldDisplay={exportedImages ? true : processedNoiseData.length && !showProfileGraph}
            selectedNMT={selectedNMT}
          />
          <ProfileGraph
            data={operationData.profile}
            operationType={operationData.operationType}
            currentTime={isPlaybackMode ? currentTime : 0}
            startTime={operationData.startTime}
            endTime={operationData.endTime}
            shouldDisplay={
              exportedImages ? true : operationData && operationData.profile && showProfileGraph
            }
            displayStartTime={noiseEventData.hasData ? noiseEventData.startTime : null}
            displayEndTime={noiseEventData.hasData ? noiseEventData.endTime : null}
            displayBuffer={60}
          />
          {isPlaybackMode && noiseEventData.hasData ? (
            <>
              <AudioPlayback
                audio={audioArray}
                isPlaying={isPlaybackMode && runningStatus && !draggingStatus}
                currentTime={currentTime}
                playbackSpeed={playbackSpeed}
              />
              <PlaybackControl
                startTime={noiseEventData.startTime}
                endTime={noiseEventData.endTime}
                markerTime={noiseEventData.time}
                onPositionUpdate={setCurrentTime}
                onPlaybackSpeedUpdate={setPlaybackSpeed}
                onRunningStatusUpdate={setRunningStatus}
                onDraggingStatusUpdate={setDraggingStatus}
                onUpdatePlaybackType={setShowProfileGraph}
                onPlaybackStop={() => {
                  setIsPlaybackMode(false);
                }}
                playbackTimeBuffer={60}
                alwaysShowDot={true}
                showProfileData={showProfileGraph}
                profileData={operationData.profile}
                canToggle={operationData.profile !== undefined}
                operationStartTime={operationData.startTime}
                showAudio={true}
                hasAudio={hasAudio}
              />
            </>
          ) : null}
          {!isPlaybackMode && noiseEventData.hasData && (
            <button
              className={cx('mode-playback', {
                'mode-playback--hidden': isPlaybackMode,
              })}
              onClick={() => setIsPlaybackMode(!isPlaybackMode)}>
              <Icons iconName={`ic-ui-play`} size="24" fill="#2e384d" />
            </button>
          )}
        </div>
      </div>
      <div className="container-fluid container-fluid--details">
        <div className="container-fluid--inner">
          <SummaryHeader type="navigation">
            <ItemsNavigator
              languageData={{ goBackTitle }}
              goBack={() => goBack(path, paging, [])}
              navigateItems={paging.navigateItems}
              nextItem={navigate('forward', path, paging, () => {
                resetPageData();
                setIsPlaybackMode(false);
              })}
              previousItem={navigate('backward', path, paging, () => {
                resetPageData();
                setIsPlaybackMode(false);
              })}
            />
          </SummaryHeader>
          <SummaryHeader type="summary">
            <div className="page-header_title">{monitorData.name}</div>
            <div className="page-tools-summary noise">
              <DataExportFeature
                source={NOISEEVENT}
                selectedIds={isExportEnabled ? [Number(id)] : []}
                dropdownWidth={103}
              />
            </div>
          </SummaryHeader>
          <div className="layout_split">
            <div className="layout_split--half">
              <div className="layout_content">
                <NoisePanelContainer
                  audioData={hasAudio ? audioArray : null}
                  noiseData={noiseEventData.samples ? noiseEventData.samples.samples : []}
                  startTime={noiseEventData.startTime ? noiseEventData.startTime : null}
                  endTime={noiseEventData.endTime ? noiseEventData.endTime : null}
                  // @ts-ignore - date expected
                  eventTime={noiseEventData.time ? noiseEventData.time : undefined}
                  noiseDetails={noiseDetails}
                  showSeconds={true}
                />
                <NoiseCauseContainer
                  noiseEvent={noiseEventData}
                  operationData={operationData}
                  noiseMonitor={monitorData}
                  causeUpdated={causeUpdated}
                />
                {noiseEventData.operationId && (
                  <OperationDetailsContainer operation={operationData} />
                )}
                <SingleDetailContainer
                  title={noiseMonitorTitle}
                  name={monitorTitle}
                  description={monitorData.description}
                />
              </div>
            </div>
            <div className="layout_split--half">
              {showCommentBox ? (
                <div className="layout_content">
                  <CommentBoxHoc
                    readOnly={false}
                    title={commentsBoxTitle}
                    inputText={comment}
                    buttonText={save}
                    successButtonText={saved}
                    textLimit={600}
                    status={commentStatus}
                    onSubmit={onSubmit}
                    canSave={false}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
