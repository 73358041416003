import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import {
  fetchOperationQuery,
  operationDetailsQuery,
  inAirQuery,
} from 'src/@operations/resolvers/operationQuerySchema';

export const fetchOperation = (client: ApolloClient<object>, id: number) => {
  return new Promise((resolve, reject) => {
    const GET_OPERATION = gql`
      query {
        ${fetchOperationQuery.name}(id: ${id}) ${fetchOperationQuery.schema}
      }
    `;

    client
      .query({ query: GET_OPERATION })
      .then(response => {
        if (response && response.data && response.data.operation) {
          resolve({
            data: response.data.operation,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchOperationDetails = (client: ApolloClient<object>, id: number) => {
  return new Promise((resolve, reject) => {
    const GET_OPERATIONS = gql`
      query {
        ${operationDetailsQuery.name}(id: ${id}) ${operationDetailsQuery.schema}
      }
    `;

    client
      .query({ query: GET_OPERATIONS })
      .then(response => {
        if (response && response.data && response.data.operationSummary) {
          resolve({
            data: response.data.operationSummary,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchInAirTracksForPlayback = (client: ApolloClient<object>, time: string) => {
  return new Promise((resolve, reject) => {
    const GET_OPERATIONS = gql`
      query {
        ${inAirQuery.name}(
          startTime: "${time}"
          endTime: "${time}"
          filter: {
            operationTypes: [Arrival, Departure, Overflight, TouchAndGo, Miscellaneous]
          }
          timeRangeMode: InFlight
        ) {
          items ${inAirQuery.schema}
        }
      }
    `;

    client
      .query({ query: GET_OPERATIONS })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data[inAirQuery.name] &&
          response.data[inAirQuery.name].items
        ) {
          resolve({
            data: response.data[inAirQuery.name].items,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
