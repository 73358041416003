import React from 'react';
// selectors
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
// components
import { Card, BlockHeader, DescriptionList } from '@ems/client-design-system';
// utils
import { formatFromISO } from 'src/utils/dateTimeConverters';
import { INoiseEventContainer } from 'src/@infringements/interfaces';

export const NoiseEventContainer = ({
  time,
  title,
  infDbLevel,
  noiseData,
  ruleMetric,
  threshold,
}: INoiseEventContainer) => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      noiseEvents: {
        fields: { leq, lmax, sel, infLevel, monitor },
      },
    },
  } = languageSelectors.getLanguage();

  const ruleTitle = ruleMetric
    ? {
        LEQ: leq,
        LMAX: lmax,
        SEL: sel,
      }[ruleMetric] || null
    : null;

  const thresholdValue = threshold.length > 0 ? threshold[0].threshold : null;

  const hasData: boolean = !!Object.keys(noiseData).length;

  let monitorLocations = '';
  if (noiseData.length) {
    noiseData.map((data, index) => {
      monitorLocations += data.locationName;

      if (index < noiseData.length - 1) {
        monitorLocations += ', ';
      }
    });
  }

  let items: any[] = [];

  if (hasData) {
    if (ruleTitle && infDbLevel) {
      items.push({
        title: ruleTitle,
        description: `${infDbLevel.toFixed(1)}dB`,
      });
    }

    if (infLevel && thresholdValue) {
      items.push({
        title: infLevel,
        description: `>${thresholdValue}dB`,
      });
    }

    items.push({
      title: monitor,
      description: monitorLocations !== '' ? monitorLocations : '—',
    });
  } else {
    items = [
      {
        title: ruleTitle,
        description: null,
      },
      {
        title: infLevel,
        description: null,
      },
      {
        title: monitor,
        description: null,
      },
    ];
  }

  return (
    <div className="block">
      <Card>
        <div>
          <BlockHeader
            title={title}
            cta={
              time && (
                <span className="infringements_time">{formatFromISO(time, 'HH:mm, DDD')}</span>
              )
            }
          />
          <DescriptionList
            className="infringements_noiseDescription"
            items={items}
            loading={!hasData}
          />
        </div>
      </Card>
    </div>
  );
};
