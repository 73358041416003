import { ApolloClient } from 'apollo-client';
import { exportActionTypes } from 'src/app/newActionTypes';
import { exportData, exportResult } from 'src/app/resolvers/exportResolver';
// props
import {
  IExport,
  IExportFilter,
  IExportRequest,
  IRequiredImage,
  TExportImage,
} from 'src/app/props';
// utils
import { alert, validationAlert } from 'src/utils';

export const triggerImageCapture = ({
  dispatcher,
  capture,
}: {
  dispatcher;
  capture: TExportImage[];
}): void => {
  dispatcher({
    type: exportActionTypes.CAPTURE_IMAGE,
    capture,
  });
};

export const updateImageExportCaptured = ({
  dispatcher,
  image,
  base64Image,
}: {
  dispatcher;
  image: TExportImage;
  base64Image: string;
}): void => {
  dispatcher({
    type: exportActionTypes.IMAGE_CAPTURED,
    image,
    base64Image,
  });
};

export const requestToExportData = ({
  client,
  dispatcher,
  requestExport,
  filter,
  source,
  requiredImages,
  noContentImages,
}: {
  client: ApolloClient<object>;
  dispatcher;
  requestExport: IExport;
  filter: IExportFilter;
  source?: string;
  requiredImages?: IRequiredImage;
  noContentImages?: TExportImage[];
}): void => {
  exportData({
    client,
    requestExport,
    filter,
    source,
    requiredImages,
    noContentImages,
  })
    .then(requestKey => {
      dispatcher({
        type: exportActionTypes.UPDATE_EXPORT_IN_PROGRESS,
        requestKey,
        requestExport,
        filter,
      });
    })
    .catch(error => {
      alert('failedToGetResult');
      dispatcher({ type: exportActionTypes.EXPORT_ERROR, error, requestExport });
    });
};

export const checkExportResult = ({
  client,
  dispatcher,
  exportRequest,
}: {
  client: ApolloClient<object>;
  dispatcher;
  exportRequest: IExportRequest;
}): void => {
  const { requestKey } = exportRequest;
  exportResult({
    client,
    requestKey,
  })
    .then(({ status, downloadResource }) => {
      if (status === 'Error') {
        validationAlert('failedToMakeRequest');
        dispatcher({ type: exportActionTypes.EXPORT_ERROR, requestKey });
      } else {
        if (status === 'InProgress') {
          // take no action - wait for the download to be completed
        } else if (status === 'Complete') {
          dispatcher({
            type: exportActionTypes.UPDATE_EXPORT_COMPLETE,
            requestKey,
            downloadResource,
          });
        }
      }
    })
    .catch(error => {
      alert('failedToGetResult');
      dispatcher({ type: exportActionTypes.EXPORT_ERROR, requestKey });
    });
};
