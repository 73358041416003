import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import cx from 'classnames';
import { useApolloClient } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';
// ts
import { TLoaded, TLoading, TSaved } from 'src/@infringements/props';
// action types
import { dataActionTypes } from 'src/@infringements/newActionTypes';
// containers
import {
  OperationDetailsContainer,
  OperationWeatherContainer,
  NoisePanelContainer,
} from 'src/containers';
import {
  RuleDetailsContainer,
  CcoGraphContainer,
  CdoGraphContainer,
  InfringementRuleContainer,
  MapContainer3D,
  MapContainer,
  MinHeightGraphContainer,
  NoiseEventContainer,
  ClimbProfileGraphContainer,
  GatesDetailsContainer,
  StatusContainer,
} from 'src/@infringements/containers/Summary';
import { ExportContainer } from 'src/containers/ExportContainer';
// components
import {
  ItemsNavigator,
  CommentBox,
  Spinner,
  Icons,
  MapCircleButton,
  Checkbox,
  Card,
} from '@ems/client-design-system';
import { ContinuousNoiseGraph } from 'src/components/ContinuousNoiseGraph';
import { PlaybackControl } from 'src/components/PlaybackControl';
import { ProfileGraph } from 'src/components/ProfileGraph';
import { AudioPlayback } from 'src/components/AudioPlayback';
// hoc
import { withPermissionsCheck, withAvailabilityChecks } from 'src/app/hocs/withPermissionsCheck';
// provider
import { InfringementDispatchContext } from 'src/@infringements/providers/InfringementsStateProvider';
// resolvers
import {
  fetchSummaryData,
  fetchInAirTracksForPlayback,
  fetchOperationDetails,
  fetchNoiseEventData,
  fetchContinuousNoiseData,
  fetchNoiseMonitorData,
} from 'src/@infringements/resolvers/summaryResolver';
import { SummaryHeader } from 'src/components/PageHeader';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
import { useInfringementRulesSelectors } from 'src/app/reducers';
// mutations
import { ADD_OPERATION_TAGS, REMOVE_OPERATION_TAGS } from 'src/@infringements/mutations';
import { UPDATE_INFRINGEMENT, updateInfringementQuery } from 'src/@infringements/mutations';
// functions
import {
  isAtcView,
  isCandidateView,
  history,
  getDeployedProductId,
  addPaddingToDuration,
  rememberSavedFilters,
  useGeometryRequiredByMap,
} from 'src/utils';
import { processNoiseData } from 'src/app/functions/noise';
import { getPaginationInformation, goBack, navigate } from 'src/app/functions/itemsNavigation';

// constants
import {
  CORRIDOR_INFRINGEMENT,
  EXCLUSION_INFRINGEMENT,
  GATE_INFRINGEMENT,
  CURFEW_INFRINGEMENT,
  CCO_INFRINGEMENT,
  CDO_INFRINGEMENT,
  MINHEIGHT_INFRINGEMENT,
  NOISE_INFRINGEMENT,
  INFRINGEMENT,
  COMMENT_STATUS_LOADED,
  COMMENT_STATUS_LOADING,
  COMMENT_STATUS_SAVED,
} from 'src/constants';
import { DATA_EXPORT } from 'src/app/featureToggles';

export const ContentContainer = ({ id, path, paginationInfo }) => {
  const dispatcher = useContext<any>(InfringementDispatchContext);
  const client = useApolloClient();
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { availableRoutes, noiseSampleType },
    map: { enable3DMap },
    infringementDetails: { showCorridorTrackProfileGraph, showWeatherPanel },
  } = configSelectors.getConfig();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { comments: commentsBoxTitle },
      buttons: { save, saved },
      labels,
    },
    screens: {
      infringements: { title: goBackTitle },
    },
  } = languageSelectors.getLanguage();
  const infringementRuleSelectors = useInfringementRulesSelectors();

  const isMountedRef = useRef<boolean>(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const [data, updateData] = useState<any>({});
  const [monitorData, updateMonitorData] = useState<any>([]);
  const [operation, updateOperation]: any = useState(null);
  const [inAirData, updateInAirData] = useState<any>([]);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaybackMode, setIsPlaybackMode] = useState(false);
  const [runningStatus, setRunningStatus] = useState<boolean>(true);
  const [draggingStatus, setDraggingStatus] = useState<boolean>(false);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(0);
  const [noiseData, setNoiseData] = useState<any[]>([]);
  const [continuousNoiseData, setContinuousNoiseData] = useState<any[]>([]);
  const [processedNoiseData, setProcessedNoiseData] = useState<any[]>([]);
  const [playbackDuration, setPlaybackDuration] = useState<number>(0);
  const [playbackStartEnd, setPlaybackStartEnd] = useState<{
    startTime: string | null;
    endTime: string | null;
  }>({
    startTime: null,
    endTime: null,
  });
  const [selectedNMT, setSelectedNMT] = useState<any>({ key: null, label: null });
  const [vectoredState, setVectoredState] = useState(false);
  const [showProfileGraph, setShowProfileGraph] = useState<boolean>(true);
  const [hoverTime, setHoverTime] = useState<number | null>(null);
  const [profileHoverTime, setProfileHoverTime] = useState<number | null>(null);
  const [commentStatus, updateCommentStatus] = useState<TLoading | TLoaded | TSaved>(
    COMMENT_STATUS_LOADED
  );
  const atcView = isAtcView(availableRoutes);
  const candidateView = isCandidateView(availableRoutes);
  const showStatus = !atcView && !candidateView;
  const [addInfringementVectored] = useMutation(ADD_OPERATION_TAGS, {
    update(cache, { data: { addOperationTags } }) {
      dispatcher({
        type: dataActionTypes.INLINE_EDIT_VECTORED,
        data: {
          id: addOperationTags[0].id,
          vectored: true,
        },
      });

      const currentItem = operation;

      currentItem.tags = [{ tagId: 11, name: 'Vector', isCompleted: null }];

      updateOperation(currentItem);
    },
  });
  const [removeInfringementVectored] = useMutation(REMOVE_OPERATION_TAGS, {
    update(cache, { data: { removeOperationTags } }) {
      dispatcher({
        type: dataActionTypes.INLINE_EDIT_VECTORED,
        data: {
          id: removeOperationTags[0].id,
          vectored: false,
        },
      });

      const currentItem = operation;

      currentItem.tags = null;

      updateOperation(currentItem);
    },
  });

  // TODO: Update this to use withAvailabilityChecks hoc - withPermissionsCheck is redundant
  const CommentBoxHoc = useMemo(
    () =>
      withPermissionsCheck(
        CommentBox,
        'Infringement.Comments.Read',
        'Infringement.Comments.Update'
      ),
    []
  );

  // data export feature
  const DataExportFeature = useMemo(
    () =>
      withAvailabilityChecks(ExportContainer, {
        feature: DATA_EXPORT,
        permissions: 'Export',
      }),
    []
  );

  const resetPageData = () => {
    setNoiseData([]);
    updateData({});
    updateOperation(null);
  };

  useEffect(() => {
    rememberSavedFilters(INFRINGEMENT);
    if (id) {
      resetPageData();
      fetchSummaryData(client, id)
        .then((data: any) => {
          if (isMountedRef.current) {
            updateData(data.data);
          }
        })
        .catch(error => {
          history.replace(`/${getDeployedProductId()}/404`);
          console.error(error);
        });
    }
  }, [id]);

  const [updateInfringementCall] = useMutation(UPDATE_INFRINGEMENT, {
    onCompleted(response) {
      const { comments } = response[updateInfringementQuery];
      // update comments
      updateData(Object.assign({}, data, { comments }));
      // change saved status
      updateCommentStatus(COMMENT_STATUS_SAVED);
    },
  });

  const [is3D, setIs3D] = useState(false);
  const {
    operationId,
    corridorId,
    selectionZoneId,
    gateId,
    ruleId,
    ruleName: pageTitle,
    infringementType,
    time,
    segments,
    position,
    comments,
    noiseEventIds,
  } = data;
  const updateStatus = (newStatus: string) => {
    const newData = Object.assign({}, data, {
      status: newStatus,
    });
    updateData(newData);
  };

  // enable the export button when not in playback mode and not in 3d mode
  const isExportEnabled =
    (!isPlaybackMode && runningStatus && !is3D) ||
    (isPlaybackMode && !runningStatus && !is3D) ||
    (!isPlaybackMode && !runningStatus && !is3D);

  const ruleData = infringementRuleSelectors.getRule(ruleId);
  const allExtraIds: number[] = infringementRuleSelectors.getGateIdsByRuleId(ruleId);
  const requiredExtraIds: number[] = allExtraIds;
  if (gateId) {
    const index = requiredExtraIds.indexOf(gateId);
    if (index !== -1) {
      requiredExtraIds.splice(index, 1);
    }
  }

  const hasWeatherData: boolean =
    operation &&
    operation.correlated &&
    operation.correlated.hasOwnProperty('weather') &&
    operation.correlated.weather;
  const hasData: boolean = typeof data !== 'undefined' && !!Object.keys(data).length;
  const paging = getPaginationInformation(Number(id), paginationInfo);

  useGeometryRequiredByMap({
    infringementTypes: typeof infringementType !== 'undefined' ? [infringementType] : [],
  });

  let infTypeId;
  switch (infringementType) {
    case CORRIDOR_INFRINGEMENT:
      infTypeId = corridorId;
      break;
    case EXCLUSION_INFRINGEMENT:
      infTypeId = selectionZoneId;
      break;
    case GATE_INFRINGEMENT:
      infTypeId = gateId;
      break;
    default:
  }

  useEffect(() => {
    if (operationId) {
      fetchOperationDetails(client, operationId)
        .then(({ data }: any) => {
          if (isMountedRef.current) {
            updateOperation(data);
          }
        })
        .catch(error => {
          console.error(error);
        });

      if (infringementType === NOISE_INFRINGEMENT) {
        fetchNoiseEventData(client, noiseEventIds)
          .then((data: any) => {
            if (isMountedRef.current) {
              setNoiseData(data.data);
            }
          })
          .catch(error => console.error(error));
      }
    }
  }, [operationId]);

  useEffect(() => {
    if (data.time && operation && operation.airportId) {
      fetchInAirTracksForPlayback(client, data.time)
        .then((data: any) => {
          if (isMountedRef.current) {
            updateInAirData(data.data.items);
          }
        })
        .catch(error => console.error(error));
    }
  }, [data, operation]);

  useEffect(() => {
    if (noiseData.length) {
      const locationIds = noiseData.map(event => event.locationId);
      fetchNoiseMonitorData(client, locationIds)
        .then((data: any) => {
          if (isMountedRef.current) {
            updateMonitorData(data.data);
          }
        })
        .catch(error => {
          console.error(error);
        });
      if (locationIds && locationIds.length) {
        const { startTime, endTime } = noiseData[0];
        const duration = addPaddingToDuration({
          seconds: 60,
          startTime,
          endTime,
        });
        setPlaybackDuration(duration.duration);
        setPlaybackStartEnd({ startTime: duration.startTime, endTime: duration.endTime });
        fetchContinuousNoiseData(
          client,
          locationIds, // locationId
          noiseSampleType,
          duration
        )
          .then((data: any) => {
            if (isMountedRef.current) {
              setContinuousNoiseData(data.data);
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  }, [noiseData]);

  useEffect(() => {
    if (isMountedRef.current) {
      if (noiseData.length && continuousNoiseData.length) {
        setProcessedNoiseData(
          processNoiseData(noiseData, continuousNoiseData, playbackDuration, playbackStartEnd)
        );
      } else {
        setProcessedNoiseData([]);
      }
    }
  }, [noiseData, continuousNoiseData]);

  useEffect(() => {
    if (processedNoiseData.length && isMountedRef.current) {
      const fidx = processedNoiseData.findIndex(e => e.locationId === selectedNMT.key);
      if (selectedNMT.key && fidx !== -1) {
        return;
      }

      const locationId = processedNoiseData[0].locationId;
      const found = monitorData.find(e => e.id === locationId);
      if (found) {
        setSelectedNMT({
          key: locationId,
          label: found.name,
        });
      }
    }
  }, [processedNoiseData]);

  const audioArray = useMemo(
    () =>
      noiseData.length && selectedNMT.key
        ? noiseData.filter(data => data.locationId === selectedNMT.key).map(data => data.audio)
        : null,
    [noiseData, selectedNMT]
  );

  const hasAudio = useMemo(() => {
    if (!audioArray) {
      return false;
    }

    return audioArray.findIndex(e => e && e.resourceUri && e.resourceUri.uri) !== -1;
  }, [audioArray]);

  useEffect(() => {
    if (operation && operation.tags && isMountedRef.current) {
      setVectoredState(!!operation.tags);
    }
  }, [operation]);

  if (!hasData) {
    return (
      <>
        <div className="map-skeleton">
          <Spinner loading={true} size="xl" centered={true} />
        </div>
        <div className="content-skeleton" />
      </>
    );
  }

  const infringementTitle = labels[infringementType.toLowerCase()];

  const onSubmit = (text: string) => {
    // set status to loading
    updateCommentStatus(COMMENT_STATUS_LOADING);
    // mutation to change data
    updateInfringementCall({
      variables: { id, comments: text },
    });
  };

  const handleValueHandover = event => {
    if (event.target.checked === true) {
      addInfringementVectored({
        variables: { ids: [operationId] },
      });
      setVectoredState(true);
    } else {
      removeInfringementVectored({
        variables: { ids: [operationId] },
      });
      setVectoredState(false);
    }
  };

  const markedTime =
    infringementType === NOISE_INFRINGEMENT && noiseData.length ? noiseData[0].time : time;
  const NOISE_PLAYBACK_BUFFER = 60;

  const canCalculatePlaybackTimes =
    infringementType === NOISE_INFRINGEMENT ? noiseData.length : operation;

  let playbackStartTime: string | null = null;
  let playbackEndTime: string | null = null;
  const playbackBufferTime = infringementType === NOISE_INFRINGEMENT ? NOISE_PLAYBACK_BUFFER : 0;
  if (canCalculatePlaybackTimes) {
    if (infringementType === NOISE_INFRINGEMENT) {
      playbackStartTime = noiseData[0].startTime;
      playbackEndTime = noiseData[0].endTime;
    } else {
      playbackStartTime = operation.startTime;
      playbackEndTime = operation.endTime;
    }
  }

  const noiseDetails = noiseData.length
    ? {
        lmax: noiseData[0].maxLevel,
        sel: noiseData[0].sel,
        threshold: noiseData[0].threshold,
        duration: noiseData[0].duration,
        epnl: noiseData[0].epnl,
        hasData: true,
      }
    : {
        lmax: undefined,
        sel: undefined,
        threshold: undefined,
        duration: undefined,
        epnl: undefined,
        hasData: false,
      };

  const noiseRuleMetricValue =
    ruleData && noiseData.length
      ? {
          LMAX: Number(noiseData[0].maxLevel),
          LEQ: Number(noiseData[0].leq),
          SEL: Number(noiseData[0].sel),
        }[ruleData.metric] || null
      : 0;

  const infringementRuleSection = () => {
    if (
      infringementType === CORRIDOR_INFRINGEMENT ||
      infringementType === EXCLUSION_INFRINGEMENT ||
      infringementType === CURFEW_INFRINGEMENT ||
      infringementType === GATE_INFRINGEMENT
    ) {
      return (
        <div className="block">
          <Card>
            <InfringementRuleContainer
              title={infringementTitle}
              time={time}
              operationId={operationId}
              operation={operation}
              selectionOnMap={{
                corridorId,
                selectionZoneId,
                gateId,
              }}
              infringementId={id}
              infringementType={infringementType}
              position={position}
              extraIds={requiredExtraIds}
            />
            {infringementType === GATE_INFRINGEMENT && (
              <GatesDetailsContainer infringement={data} />
            )}
          </Card>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="map_wrapper">
        {is3D && !isPlaybackMode ? (
          <div className="map">
            <MapContainer3D
              operationId={operationId}
              operation={operation}
              infringementType={infringementType}
              infTypeId={infTypeId}
              extraIds={requiredExtraIds} // extra gates
              infringementId={id}
              position={position}
              time={time}
            />
          </div>
        ) : (
          <MapContainer
            time={time}
            operation={operation}
            operationId={operationId}
            infringementType={infringementType}
            infTypeId={infTypeId}
            extraIds={requiredExtraIds} // extra gates
            infringementId={id}
            position={position}
            inAirData={inAirData}
            isPlaybackMode={isPlaybackMode}
            currentTime={currentTime}
            noiseData={noiseData}
            processedNoiseData={processedNoiseData}
            noiseMonitors={monitorData}
            setSelectedTime={setHoverTime}
            profileHoverTime={profileHoverTime}
            isPlaybackRunning={runningStatus}
          />
        )}
        <div className="map-overlay-panel">
          {!is3D && (
            <>
              <ContinuousNoiseGraph
                noiseData={processedNoiseData}
                currentTime={isPlaybackMode ? currentTime : 0}
                shouldDisplay={noiseData.length && operation && !showProfileGraph}
                selectedNMT={selectedNMT}
              />
              <ProfileGraph
                data={operation ? operation.profile : []}
                operationType={operation ? operation.operationType : ''}
                currentTime={isPlaybackMode ? currentTime : 0}
                startTime={operation ? operation.startTime : 0}
                endTime={operation ? operation.endTime : 0}
                shouldDisplay={operation !== null && operation.profile && showProfileGraph}
                markedTime={hoverTime}
                onHoverCB={setProfileHoverTime}
                isPlaybackMode={isPlaybackMode}
                isPlaybackRunning={runningStatus}
                selectedTrackTheme={selectedTrackTheme}
              />
            </>
          )}
          {hasAudio ? (
            <AudioPlayback
              audio={audioArray}
              isPlaying={runningStatus && !draggingStatus}
              currentTime={currentTime}
              playbackSpeed={playbackSpeed}
            />
          ) : null}
          {isPlaybackMode && (
            <PlaybackControl
              markerTime={markedTime}
              startTime={playbackStartTime}
              endTime={playbackEndTime}
              onPositionUpdate={setCurrentTime}
              onPlaybackSpeedUpdate={setPlaybackSpeed}
              onRunningStatusUpdate={setRunningStatus}
              onDraggingStatusUpdate={setDraggingStatus}
              onUpdatePlaybackType={setShowProfileGraph}
              onPlaybackStop={() => {
                setIsPlaybackMode(false);
              }}
              playbackTimeBuffer={playbackBufferTime}
              alwaysShowDot={infringementType === NOISE_INFRINGEMENT}
              showProfileData={showProfileGraph}
              profileData={operation ? operation.profile : null}
              canToggle={
                operation &&
                operation.profile !== undefined &&
                infringementType === NOISE_INFRINGEMENT
              }
              showAudio={infringementType === NOISE_INFRINGEMENT}
              hasAudio={hasAudio}
              operationStartTime={operation.startTime}
            />
          )}
        </div>
        {!is3D && !isPlaybackMode && playbackStartTime && (
          <button
            className={cx('mode-playback', {
              'mode-playback--hidden': isPlaybackMode,
            })}
            onClick={() => setIsPlaybackMode(!isPlaybackMode)}>
            <Icons iconName={`ic-ui-play`} size="24" fill="#2e384d" />
          </button>
        )}
        {enable3DMap && (
          <MapCircleButton
            icon={<Icons iconName={`ic-ui-3d-map`} size="18" fill="#FFF" />}
            className={cx('mode-3d', {
              'mode-playback--hidden': isPlaybackMode,
            })}
            active={is3D}
            onClick={() => {
              setIs3D(!is3D);
            }}
          />
        )}
      </div>

      <div className="container-fluid container-fluid--details">
        <div className="container-fluid--inner">
          <SummaryHeader type="navigation">
            <ItemsNavigator
              languageData={{ goBackTitle }}
              goBack={() => goBack(path, paging, availableRoutes)}
              navigateItems={paging.navigateItems}
              nextItem={navigate('forward', path, paging, () => {
                setIsPlaybackMode(false);
              })}
              previousItem={navigate('backward', path, paging, () => {
                setIsPlaybackMode(false);
              })}
            />
          </SummaryHeader>
          <SummaryHeader type="summary">
            <div className="page-header_title">{pageTitle}</div>
            {atcView &&
              typeof operation !== 'undefined' &&
              operation &&
              operation.hasOwnProperty('tags') &&
              operation.tags && (
                // display vectored checkbox for atc view
                // if operation.tags exist -> tags(returnOnly:["Vector"]) {
                <Checkbox
                  variant="circle"
                  checked={vectoredState}
                  labelElement={<span>Vectored</span>}
                  alignIndicator="right"
                  onChange={handleValueHandover}
                />
              )}
            <div className="page-tools-summary">
              <DataExportFeature
                source={INFRINGEMENT}
                selectedIds={isExportEnabled ? [Number(id)] : []}
                dropdownWidth={103}
              />
              {showStatus && <StatusContainer infringement={data} updateStatus={updateStatus} />}
            </div>
          </SummaryHeader>
          <div className="layout_split">
            <div className="layout_split--half">
              <div className="layout_content">
                {infringementType === CCO_INFRINGEMENT && (
                  <CcoGraphContainer
                    operationData={operation}
                    ruleData={ruleData}
                    infringementData={segments}
                    title={infringementTitle}
                    time={time}
                    type={infringementType}
                    selectedTrackTheme={selectedTrackTheme}
                  />
                )}
                {infringementType === CDO_INFRINGEMENT && (
                  <CdoGraphContainer
                    operationData={operation}
                    ruleData={ruleData}
                    infringementData={segments}
                    title={infringementTitle}
                    time={time}
                    type={infringementType}
                    selectedTrackTheme={selectedTrackTheme}
                  />
                )}
                {infringementRuleSection()}
                {infringementType === CORRIDOR_INFRINGEMENT && showCorridorTrackProfileGraph && (
                  <ClimbProfileGraphContainer
                    operationData={operation}
                    ruleData={ruleData}
                    type={infringementType}
                    infringementTime={time}
                    selectedTrackTheme={selectedTrackTheme}
                  />
                )}
                {infringementType === MINHEIGHT_INFRINGEMENT && (
                  <MinHeightGraphContainer
                    operationData={operation}
                    ruleData={ruleData}
                    title={infringementTitle}
                    time={time}
                    type={infringementType}
                    selectedTrackTheme={selectedTrackTheme}
                  />
                )}
                {infringementType === NOISE_INFRINGEMENT && (
                  <>
                    <NoiseEventContainer
                      title={infringementTitle}
                      time={time}
                      infDbLevel={noiseRuleMetricValue}
                      ruleMetric={ruleData ? ruleData.metric : null}
                      noiseData={noiseData}
                      threshold={ruleData && ruleData.thresholds ? ruleData.thresholds : []}
                    />
                    <NoisePanelContainer
                      audioData={hasAudio ? audioArray : null}
                      noiseData={noiseData.length ? noiseData[0].samples.samples : []}
                      startTime={playbackStartTime}
                      endTime={playbackEndTime}
                      noiseDetails={noiseDetails}
                      showFileDownload={false}
                    />
                  </>
                )}
                <OperationDetailsContainer operation={operation} />
                {showWeatherPanel && hasWeatherData && (
                  <OperationWeatherContainer data={hasWeatherData} />
                )}
                <RuleDetailsContainer ruleId={ruleId} />
              </div>
            </div>
            <div className="layout_split--half">
              <div className="layout_content">
                <CommentBoxHoc
                  readOnly={false}
                  title={commentsBoxTitle}
                  inputText={comments ? comments : ''}
                  buttonText={save}
                  successButtonText={saved}
                  textLimit={600}
                  status={commentStatus}
                  onSubmit={onSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
