import React, { createContext, useReducer } from 'react';
// import { IStoreState } from 'src/@infringements/props';
import { dataInitialState, dataReducer } from 'src/@infringements/reducers/dataReducer';
import { sortInitialState, sortReducer } from 'src/@infringements/reducers/sortReducer';

export const InfringementStateContext = createContext({
  data: dataInitialState,
  sort: sortInitialState,
});

export const InfringementDispatchContext = createContext(null);

export const InfringementsStateProvider = ({ children }) => {
  const [dataState, dataDispatch] = useReducer<any>(dataReducer, dataInitialState);
  const [sortState, sortDispatch]: any = useReducer<any>(sortReducer, sortInitialState);

  const state: any = {
    data: dataState,
    sort: sortState,
  };

  const dispatchers = [dataDispatch, sortDispatch];

  const dispatch: any = (action: any) =>
    dispatchers.map((dispatcher: any) => {
      dispatcher(action);
    });

  return (
    <InfringementStateContext.Provider value={state}>
      <InfringementDispatchContext.Provider value={dispatch}>
        {children}
      </InfringementDispatchContext.Provider>
    </InfringementStateContext.Provider>
  );
};
