// utils
import { displayError } from '@ems/client-design-system';
// local translation
import * as strings from 'src/app/strings.json';

const translationData = strings.components.errors;

export const alert = (type: string) => {
  let message = '';
  switch (type) {
    case '403':
      message = `${translationData['403']} ${translationData.contactSupport}`;
      break;
    case '404':
      message = `${translationData['404']} ${translationData.contactSupport}`;
      break;
    case '500':
      message = `${translationData['500']} ${translationData.contactSupport}`;
      break;
    case 'failedToLoadFilters':
      message = `${translationData.filtersfileNotFound} ${translationData.contactSupport}`;
      break;
    case 'failedToLoadLanguage':
      message = `${translationData.translationfileNotFound} ${translationData.contactSupport}`;
      break;
    case 'failedToGetResult':
      message = `${translationData.failedToGetResult} ${translationData.contactSupport}`;
      break;
    case 'failedToGetPrivacyAgreement':
      message = `${translationData.failedToGetPrivacyAgreement} ${translationData.contactSupport}`;
      break;
  }
  displayError({
    message,
    actionLabel: 'Reload',
    action: () => {
      location.reload();
    },
  });
};

export const validationAlert = (type: string) => {
  let message = '';
  switch (type) {
    case 'selectionExceedLimit':
      message = `${translationData.selectedIdsExceededError}`;
      break;
    case 'failedToMakeRequest':
      message = `${translationData.requestFailed} ${translationData.contactSupport}`;
      break;
  }
  displayError({
    message,
    timeout: 3000,
  });
};
