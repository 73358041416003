import React, { FC, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// context
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
import { InfringementDispatchContext } from 'src/@infringementsCandidates/providers/InfringementsStateProvider';
import { useDataSelectors, useSortSelectors } from 'src/@infringementsCandidates/reducers';
// common components
import { Filter, FilterRow, TimeRangeSelector } from '@ems/client-design-system';
import {
  updateSelectedItems,
  clearSelectedItems,
  updateTimeFilterValue,
  updateTimeFilterInput,
} from 'src/@infringementsCandidates/actions/filterActions';
// store
import { filterStore } from 'src/@infringementsCandidates/stores/filterStore';
// function
import {
  useLatestFilter,
  getIfFiltersEmpty,
  translateLabels,
  useTimeFilter,
} from 'src/@infringementsCandidates/functions';
import { convertObjectKeys } from 'src/utils/objectModifiers';
// interfaces
import { ITableFilterItem } from 'src/@infringementsCandidates/interfaces';
// constants
import { NULL_VALUE } from 'src/constants';

export const FilterContainer: FC = () => {
  const client = useApolloClient();
  // Configuration
  const configSelectors = useConfigSelectors();
  const dispatcher = useContext(InfringementDispatchContext);
  const sortSelectors = useSortSelectors();
  const dataSelectors = useDataSelectors();
  const sortString = sortSelectors.getSortString();
  const candidatesEnabled = dataSelectors.getCandidatesEnabled();
  const {
    grid: { resultSize },
    infringementsCandidates: {
      filter: { availableCategoryList },
    },
  } = configSelectors.getConfig();
  const nullable: any = [];
  const showSelectedIcons: any = [];
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        filters: { clear: clearValue, filter: filterValue, clearFilters },
      },
      hints: { noMatchesFound },
      lists: {
        infringementFilterCategories,
        aircraftCategories,
        candidates,
        ruleTypes,
        severities,
        extraFilterValues,
        infringementStatuses,
        timeFilter: languageData,
      },
    },
  } = languageSelectors.getLanguage();

  const timeFilters = useTimeFilter(resultSize);

  const dataListUpdated = convertObjectKeys({
    ...aircraftCategories,
    ...candidates,
    ...extraFilterValues,
    ...ruleTypes,
    ...severities,
    ...infringementStatuses,
  });

  const filterItems = filterStore.getAllFilterItems();
  const selectedItems = useLatestFilter({
    extraFilterValues,
  });
  const filterComponents: JSX.Element[] = [];
  availableCategoryList.map((category: string) => {
    const NullValue = { key: NULL_VALUE, label: NULL_VALUE, icon: undefined };
    const originalItems = filterItems[category];
    if (originalItems !== undefined) {
      const transformedItems =
        nullable && nullable.includes(category) ? [NullValue, ...originalItems] : originalItems;
      filterComponents.push(
        <Filter
          key={category}
          categoryName={infringementFilterCategories[category]}
          filterItems={translateLabels(transformedItems, dataListUpdated)}
          selectedItems={selectedItems[category]}
          updateItems={(items: ITableFilterItem[]) =>
            updateSelectedItems(
              client,
              category,
              items,
              dispatcher,
              sortString,
              resultSize,
              candidatesEnabled
            )
          }
          iconCategories={showSelectedIcons.map(
            (category: string) => infringementFilterCategories[category]
          )}
          languageData={{ clearValue, filterValue, noMatchesFound }}
        />
      );
    }
  });

  /*
   * arr.splice(index, 0, item); will insert
   * item into arr at the specified index
   * (deleting 0 items first, that is, it's just an insert).
   */
  filterComponents.splice(
    1,
    0,
    <TimeRangeSelector
      key="filter-time-selector"
      fromInputValue={timeFilters.fromInput}
      toInputValue={timeFilters.toInput}
      setInputValue={updateTimeFilterInput}
      fromTime={timeFilters.from}
      toTime={timeFilters.to}
      setTime={updateTimeFilterValue}
      languageData={languageData}
    />
  );

  return (
    <div>
      <FilterRow
        filters={filterComponents}
        clearFilters={() => clearSelectedItems(client, resultSize, dispatcher, sortString)}
        clearDisabled={getIfFiltersEmpty(selectedItems, timeFilters)}
        languageData={{ clearFilters }}
      />
    </div>
  );
};
