import { EventEmitter } from 'events';
import { dispatcher } from 'src/utils/dispatcher';
// ts
import { TdeployedProductId, TdoesntExist, IConfigData } from 'src/app/props';
// functions
import { parseQueryString } from 'src/utils/queryParams';
import { isAtcView, getDeployedProductId } from 'src/utils';
import { appActionTypes } from 'src/app/actionTypes';
// const
import { CHANGE_EVENT } from 'src/constants';

class ConfigStore extends EventEmitter {
  deployedProductId: TdoesntExist | TdeployedProductId;
  config: TdoesntExist | IConfigData;
  failedToAccessConfig: boolean;

  constructor() {
    super();
    this.deployedProductId = getDeployedProductId();
    this.config = null;
    this.failedToAccessConfig = false;
  }

  getConfig() {
    return this.config;
  }

  setConfig(config: IConfigData) {
    this.config = config;
  }

  getTheme(type: string) {
    if (!type || !this.config) {
      return 'default';
    }
    if (
      this.config.globals &&
      this.config.globals.colours &&
      this.config.globals.colors[type] &&
      this.config.globals.colors[type].colorScheme
    ) {
      return this.config.globals.colors[type].colorScheme.toLowerCase();
    } else {
      return 'default';
    }
  }

  // make Timezone available (for the local airport)
  getTimeZone() {
    if (this.config && typeof this.config.globals !== 'undefined') {
      const {
        globals: { timeZone },
      } = this.config;
      return timeZone;
    }
    return 'UTC'; // default is GMT
  }

  getOperationFilterSet = () => {
    const savedSetName = parseQueryString(window.location.search).filter;
    const filterSet = savedSetName ? savedSetName : 'defaults';
    if (this.config) {
      const configSet = this.config.operations.filter[filterSet];
      return configSet ? configSet : this.config.operations.filter.defaults;
    }
    return {};
  };

  getInfringementsFilterSet = () => {
    const pathname = window.location.pathname;
    const savedSetName = parseQueryString(window.location.search).filter;
    const filterSet = savedSetName ? savedSetName : 'defaults';
    if (this.config) {
      const infConfigSet = this.config.infringements.filter[filterSet];
      const infDefaultFilterSet = this.config.infringements.filter.defaults;
      const atcConfigSet = this.config.infringementsATC.filter[filterSet];
      const atcDefaultFilterSet = this.config.infringementsATC.filter.defaults;
      const atcView = isAtcView(pathname);
      const configSet = atcView ? atcConfigSet : infConfigSet;
      const defaultFilterSet = atcView ? atcDefaultFilterSet : infDefaultFilterSet;
      return configSet ? configSet : defaultFilterSet;
    }
    return {};
  };

  handleActions(action: any) {
    if (action) {
      switch (action.type) {
        case appActionTypes.UPDATE_CONFIG:
          this.setConfig(action.data);
          this.failedToAccessConfig = false;
          this.emit(CHANGE_EVENT);
          break;
        case appActionTypes.CONFIG_ERROR:
          this.config = null;
          this.failedToAccessConfig = true;
          this.emit(CHANGE_EVENT);
          break;
        default:
      }
    }
  }
}

export const configStore = new ConfigStore();
dispatcher.register(configStore.handleActions.bind(configStore));
