import { useContext } from 'react';
import { ILanguageSelectors, ILanguageState, IStoreState } from 'src/app/props';
import { appActionTypes } from 'src/app/newActionTypes';
import { GlobalStateContext } from 'src/app/providers/GlobalStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';

export const useLanguageSelectors: () => ILanguageSelectors = () => {
  const state: IStoreState = useContext(GlobalStateContext);
  const languageState: ILanguageState = state.language;

  return useSelectors(languageState, (state: ILanguageState) => ({
    hasCompletedInitialLoad: () => state.initialLoadCompleted,
    getLanguage: () => state.language,
    hasAccessFailed: () => state.failedToAccessLanguage,
  }));
};

export const languageInitialState: ILanguageState = {
  language: null,
  failedToAccessLanguage: false,
  initialLoadCompleted: false,
};

export const languageReducer = (state: ILanguageState, action: any) => {
  switch (action.type) {
    case appActionTypes.UPDATE_LANGUAGE:
      return Object.assign({}, state, {
        language: action.payload,
        failedToAccessLanguage: false,
        initialLoadCompleted: true,
      });
    case appActionTypes.LANGUAGE_ERROR:
      return Object.assign({}, state, {
        language: null,
        failedToAccessLanguage: true,
      });
    default:
      return state;
  }
};
