import { useContext } from 'react';
import { IExportState, IExportSelectors, IExportRequest } from 'src/app/props';
import { exportActionTypes } from 'src/app/newActionTypes';
import { ExportStateContext } from 'src/app/providers/ExportProvider';
import { useSelectors } from 'src/utils/storeHelpers';
// constants
import { IN_PROGRESS, COMPLETE, ERROR } from 'src/constants';

export const useExportSelectors: () => IExportSelectors = () => {
  const state: any = useContext(ExportStateContext);
  const exportState: IExportState = state.data;
  return useSelectors(exportState, (state: IExportState) => ({
    captureImage: (): boolean => state.captureImage,
    requiredImages: () => {
      if (state.requiredToCapture.length) {
        const temp = {};
        state.requiredToCapture.forEach(item => {
          temp[item] = state[item];
        });
        return temp;
      }
      return null;
    },
    whatIsRequiredToCapture: () => state.requiredToCapture,
    getInProgress: (): IExportRequest[] => state.inProgress,
    getCompleted: (): IExportRequest[] => state.complete,
    isLoading: () => state.inProgress.length, // for now we won't allow concurrent download - only a single download is allowed
    isReadyToSendImages: () => state.capture.length === 0,
  }));
};

export const exportInitialState: IExportState = {
  captureImage: false,
  capture: [],
  requiredToCapture: [],
  trackmapImage: null,
  altitudeImage: null,
  noiseImage: null,
  inProgress: [],
  complete: [],
  error: [],
};

const findItem = (Items: IExportRequest[], requestKey: string): null | IExportRequest => {
  let found: null | IExportRequest = null;
  Items.forEach(item => {
    if (item.requestKey === requestKey) {
      found = item;
    }
  });
  return found;
};

// This reducer doesn't support concurrent downloads at the moment - uplift is required
export const exportReducer = (state: IExportState, action: any) => {
  switch (action.type) {
    case exportActionTypes.CAPTURE_IMAGE:
      const requiredToCapture = [...action.capture];
      return Object.assign({}, state, {
        captureImage: true,
        capture: action.capture,
        requiredToCapture,
        trackmapImage: null,
        altitudeGraph: null,
        noiseGraph: null,
        inProgress: state.inProgress,
      });
    case exportActionTypes.IMAGE_CAPTURED:
      const capture = state.capture;
      const index = capture.indexOf(action.image);
      if (index !== -1) {
        capture.splice(index, 1);
      }
      return Object.assign({}, state, {
        captureImage: capture.length ? true : false,
        capture,
        [action.image]: action.base64Image,
        inProgress: state.inProgress,
      });
    case exportActionTypes.UPDATE_EXPORT_IN_PROGRESS:
      return Object.assign({}, state, {
        captureImage: false,
        capture: [],
        requiredToCapture: [],
        inProgress: [
          {
            requestKey: action.requestKey,
            export: action.requestExport,
            status: IN_PROGRESS,
            datetime: new Date().getTime(),
            uri: '',
            filter: action.filter,
          },
        ],
      });
    case exportActionTypes.UPDATE_EXPORT_COMPLETE:
      let foundInProgress = findItem(state.inProgress, action.requestKey);
      if (foundInProgress) {
        foundInProgress = Object.assign(foundInProgress, {
          status: COMPLETE,
          uri: action.downloadResource.uri,
        });
        return Object.assign({}, state, {
          inProgress: [],
          complete: [foundInProgress],
        });
      }
    case exportActionTypes.EXPORT_ERROR:
      let foundFailedItem = findItem(state.inProgress, action.requestKey);
      if (foundFailedItem) {
        foundFailedItem = Object.assign(foundFailedItem, {
          status: ERROR,
        });
      }
      return Object.assign({}, state, {
        captureImage: false,
        capture: [],
        requiredToCapture: [],
        inProgress: [],
        error: foundFailedItem ? [foundFailedItem] : state.error,
      });
    default:
      return state;
  }
};
