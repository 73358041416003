import React, { FC, ReactElement, useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// providers
import { ExportDispatchContext } from 'src/app/providers/ExportProvider';
// selectors
import { useLanguageSelectors, useExportSelectors } from 'src/app/reducers';
// actions
import { triggerImageCapture, requestToExportData } from 'src/app/actions/exportActions';
// common components
import { DropdownButton, Icons } from '@ems/client-design-system';
// functions
import { validationAlert } from 'src/utils';
import { freezePage, unFreezePage } from 'src/app/functions/export';
import { exportOptions } from './options';
// props
import { IExport, IExportFilter, TExportImage } from 'src/app/props';
// constants
import {
  WORD_EXPORT,
  COMPLAINT,
  OPERATIONS,
  OPERATION,
  INFRINGEMENTS,
  INFRINGEMENT,
  NOISEEVENTS,
  NOISEEVENT,
  COMPLAINTS,
} from 'src/constants';

interface IExportContainer {
  source: string;
  selectedIds: number[];
  dropdownWidth?: number;
}

interface IReport {
  key: string;
  label: string;
  icon: ReactElement;
  export: IExport;
}

export const ExportContainer: FC<IExportContainer> = ({
  source,
  selectedIds,
  dropdownWidth = 190,
}) => {
  const client = useApolloClient();
  const dispatcher = useContext(ExportDispatchContext);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [penddingRequest, setPendingRequest] = useState<null | {
    filter: IExportFilter;
    noContentImages?: TExportImage[];
  }>(null);
  const exportLimit = 20000; // set limit for large exports
  // when no item selected, then action button should be disabled
  const disabled = selectedIds.length >= 1 ? false : true;

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: { exportReport },
      lists: {
        exportFormats: { excel, word, kml, inm },
      },
    },
  } = languageSelectors.getLanguage();
  // Export reports
  const exportSelectors = useExportSelectors();
  const loading = exportSelectors.isLoading();
  const captureImage = exportSelectors.captureImage();
  const isReadyToSendImages = exportSelectors.isReadyToSendImages();
  const requiredImages = exportSelectors.requiredImages();

  useEffect(() => {
    if (loading) {
      setExportLoading(true);
    } else {
      if (exportLoading) {
        setExportLoading(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (captureImage) {
      setExportLoading(true);
    }
    if (!captureImage && isReadyToSendImages && requiredImages) {
      if (penddingRequest) {
        unFreezePage();
        requestToExportData({
          client,
          dispatcher,
          requestExport: selectedItem.export,
          filter: penddingRequest.filter,
          source,
          requiredImages,
          noContentImages: penddingRequest.noContentImages,
        });
        setPendingRequest(null);
      }
    }
  }, [captureImage, isReadyToSendImages]);

  const exportItems: IReport[] = exportOptions({
    source,
    translationData: { excel, word, kml, inm },
  });

  const [selectedItem, setSelectedItem] = useState<any>(exportItems[0]);

  const requestReportExport = item => {
    setSelectedItem(item);
    let filter = {};
    let requiredToCapture: TExportImage[] = []; // capture screen shots form these graphs. If not available, send a no-content image instead
    let noContentImages: TExportImage[] = []; // include a no-content (dummy) image
    // the logic to select items may change in the future if multiple selection need to be done
    // e.g. operationIds: [...], infringementIds: [...]
    switch (source) {
      case OPERATIONS:
        filter = { operationIds: selectedIds };
        if (item.export.type === WORD_EXPORT) {
          requiredToCapture = ['trackmapImage'];
          noContentImages = ['altitudeImage'];
        }
        break;
      case OPERATION:
        filter = { operationIds: selectedIds };
        if (item.export.type === WORD_EXPORT) {
          requiredToCapture = ['trackmapImage', 'altitudeImage'];
          noContentImages = [];
        }
        break;
      case INFRINGEMENTS:
        filter = { infringementIds: selectedIds };
        if (item.export.type === WORD_EXPORT) {
          requiredToCapture = ['trackmapImage'];
          noContentImages = ['altitudeImage'];
        }
        break;
      case INFRINGEMENT:
        filter = { infringementIds: selectedIds };
        if (item.export.type === WORD_EXPORT) {
          requiredToCapture = ['trackmapImage', 'altitudeImage'];
          noContentImages = [];
        }
        break;
      case NOISEEVENTS:
        filter = { noiseEventIds: selectedIds };
        if (item.export.type === WORD_EXPORT) {
          requiredToCapture = ['trackmapImage', 'noiseImage'];
          noContentImages = ['altitudeImage'];
        }
        break;
      case NOISEEVENT:
        filter = { noiseEventIds: selectedIds };
        if (item.export.type === WORD_EXPORT) {
          requiredToCapture = ['trackmapImage', 'noiseImage', 'altitudeImage'];
          noContentImages = [];
        }
        break;
      case COMPLAINTS:
      case COMPLAINT:
        filter = { complaintIds: selectedIds };
        if (item.export.type === WORD_EXPORT) {
          requiredToCapture = ['trackmapImage'];
          noContentImages = ['altitudeImage'];
        }
        break;
      default:
    }

    if (selectedIds.length <= exportLimit) {
      if (requiredToCapture.length) {
        setPendingRequest({
          filter,
          noContentImages,
        });
        freezePage().then(() => {
          triggerImageCapture({
            dispatcher,
            capture: requiredToCapture,
          });
        });
      } else {
        requestToExportData({
          client,
          dispatcher,
          requestExport: item.export,
          filter,
          source,
        });
      }
    } else {
      validationAlert('selectionExceedLimit');
    }
  };

  return (
    <DropdownButton
      label={exportReport}
      icon={<Icons iconName={`ic-ui-export`} size="20" />}
      width={dropdownWidth}
      loading={exportLoading}
      disabled={disabled}
      items={exportItems}
      selectedItem={selectedItem}
      updateSelection={requestReportExport}
    />
  );
};
