import React, { useContext, useEffect, useState, FC } from 'react';
// components
import { Table, Button, Icons, displaySuccess } from '@ems/client-design-system';
// containers
import { ConfigurationDetailsContainer } from 'src/@settings/containers';
// functions
import {
  formatHeaders,
  getColumnTypes,
  updateTableIds,
  getConfigurationNames,
  getSelectedConfiguration,
  updateConfigurationList,
  getFormattedMutationItem,
} from 'src/@settings/functions';
// providers
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
// reducers
import { useSortSelectors } from 'src/@settings/reducers';
import { useLanguageSelectors, useScenariosSelectors } from 'src/app/reducers';
// actions
import { updateCurrentScenario } from 'src/app/actions';
// libraries
import uuid from 'uuid';
// interfaces
import { IScenario } from 'src/app/props';
import { IManageConfigurationsContainer, IMutationItem, TMode } from 'src/@settings/interfaces';
// helpers
import { dateTimeInQuery } from 'src/utils/dateTimeConverters';
// mutations
import { CARBON_EMISSIONS_REQUEST_REPROCESSING, UPDATE_SCENARIOS } from 'src/@settings/mutations';
// client
import { useMutation } from '@apollo/react-hooks';
// actions
import { appActionTypes } from 'src/app/actionTypes';
// utils
import { convertMapToArray } from 'src/utils';
// constants
import { MANAGE_CONFIGURATIONS_MODULE } from 'src/constants';
import { DateTime } from 'luxon';
import { configStore } from 'src/app/stores/configStore';

export const ManageConfigurationsContainer: FC<IManageConfigurationsContainer> = ({
  onPopupClose,
}) => {
  // dispatchers
  const globalDispatcher: any = useContext(GlobalDispatchContext);
  const dispatcher = useContext(SettingsDispatchContext);

  // selectors
  const sortSelector = useSortSelectors();
  const scenariosSelector = useScenariosSelectors();
  const configurations = scenariosSelector.getScenarios();
  const copyOfScenarios = configurations.map(item => {
    return { ...item };
  });

  const [data, setData] = useState<IScenario[]>(copyOfScenarios);

  const [apiData, setApiData] = useState<IScenario[]>(copyOfScenarios);
  const selectedRowIndex: number = scenariosSelector.getSelectedRowIndex();
  const [disableDelete, setDisableDelete] = useState(false);
  const configurationNames = getConfigurationNames(data);
  const languageSelectors = useLanguageSelectors();
  const {
    fields: {
      manageConfiguration: {
        name: nameColumnHeader,
      }
    },
    components: {
      buttons: { addNew, deleteScenario, duplicate, save },
      labels: { manageConfiguration, scenariosUpdateSuccessMessage },
      hints: { areYouSureYouWantToLeave, areYouSureYouWantToDelete },
    },
  } = languageSelectors.getLanguage();

  const manageConfigurationColumns = ['name'];
  const manageConfigurationColumnHeaders = {
    'name': nameColumnHeader
  };

  // state
  const [rowData, setRowData] = useState<string[]>(configurationNames);
  const [clickedRow, setClickedRow] = useState<number>(0);
  const [changesAvailable, setChangesAvailable] = useState(false);
  const [mutationData, setMutationData] = useState<Map<number, IMutationItem>>(new Map());

  useEffect(() => {
    setApiData(configurations);
  }, [configurations]);

  useEffect(() => {
    setRowData(updateTableIds([], getConfigurationNames(data)));
  }, [data]);

  useEffect(() => {
    selectedRowIndex === 0 ? setDisableDelete(true) : setDisableDelete(false);
  }, [selectedRowIndex]);

  const onRowClick = event => {
    const rowIndex = event.currentTarget.rowIndex;
    const rowData = event.currentTarget.innerText;
    updateSelectedConfiguration(rowIndex, rowData);
    if (rowIndex >= 0) {
      updateHighlightRow(rowIndex);
    }
  };

  const updateSelectedConfiguration = (rowIndex, rowData) => {
    rowData = rowData.replace(/[^a-zA-Z ]/g, '');
    if (rowData) {
      const scenario = getSelectedConfiguration(data, rowIndex);
      updateCurrentScenario({ scenario, selectedRowIndex: rowIndex }, globalDispatcher);
    }
  };

  const updateHighlightRow = rowIndex => {
    const updatedData: any = [];
    configurationNames.forEach(item => {
      const i = Object.assign({}, item, { tableId: uuid.v4() });
      updatedData.push(i);
    });
    setRowData(updatedData);
    setClickedRow(rowIndex);
  };

  const handleMutation = (mode: TMode, updatingItem) => {
    if(mode === 'Duplicate') {
      // this adds the temporary key used to persist the source of duplicated scenario.
      updatingItem.duplicateScenarioId = updatingItem.id;
      updatingItem.id = new Date().getTime();
    }
    if (!updatingItem.id) {
      updatingItem.id = new Date().getTime();
    } else if (!updatingItem.name) {
      updatingItem.name = 'New Configuration';
    }
    setMutationData(getFormattedMutationItem(mode, updatingItem, mutationData, MANAGE_CONFIGURATIONS_MODULE));
  };

  const updateConfigurations = props => {
    const { name, startDate, endDate, isLocked, selectedRowIndex } = props;
    const scenario = getSelectedConfiguration(data, selectedRowIndex);
    scenario.name = name;
    scenario.startTime = dateTimeInQuery(new Date(DateTime.fromISO(startDate, { setZone: configStore.getTimeZone() })), 'start');
    scenario.endTime = dateTimeInQuery(new Date(DateTime.fromISO(endDate, { setZone: configStore.getTimeZone() }).endOf('month')), 'end');
    scenario.isLocked = isLocked;

    updateCurrentScenario({ scenario, selectedRowIndex }, globalDispatcher);
    setData(updateConfigurationList(data, scenario, selectedRowIndex));
    handleMutation('Update', scenario);
    setChangesAvailable(true);
  };

  const addNewRow = () => {
    const defaultScenario = scenariosSelector.getDefaultScenario();
    const newScenario = {
      name: 'New Configuration',
      startTime: defaultScenario.startTime,
      endTime: defaultScenario.endTime,
      isLocked: false,
      isDefault: false,
      isActive: false,
      airportId: 'EGLL',
      defaultOptimumTMAApproachDistance: defaultScenario.defaultOptimumTMAApproachDistance,
      applyWeatherAdjustment: defaultScenario.applyWeatherAdjustment,
      defaultLoadFactor: defaultScenario.defaultLoadFactor,
      defaultDurationClimb: defaultScenario.defaultDurationClimb,
      defaultDurationTaxiIn: defaultScenario.defaultDurationTaxiIn,
      defaultDurationTakeOff: defaultScenario.defaultDurationTakeOff,
      defaultDurationTaxiOut: defaultScenario.defaultDurationTaxiOut,
      defaultDurationApproach: defaultScenario.defaultDurationApproach,
      emissionRateMode: defaultScenario.emissionRateMode,
      emissionRateAvGas: defaultScenario.emissionRateAvGas,
      emissionRateSingle: defaultScenario.emissionRateSingle,
      emissionRateJetFuel: defaultScenario.emissionRateJetFuel,
      emissionRateUnknown: defaultScenario.emissionRateUnknown,
      outstandingMonthsToProcess: 0,
    };

    const updatedData = data.slice();
    updatedData.push(newScenario);
    setData(updatedData);
    updateCurrentScenario(
      { scenario: newScenario, selectedRowIndex: data.length },
      globalDispatcher
    );
    updateHighlightRow(data.length);
    setChangesAvailable(true);
    handleMutation('Insert', newScenario);
  };

  const onDeleteClick = () => {
    const result = confirm(areYouSureYouWantToDelete);
    if (result) {
      const newRowIndex =
        selectedRowIndex === data.length - 1 ? selectedRowIndex - 1 : selectedRowIndex;
      const deletedScenario = getSelectedConfiguration(data, selectedRowIndex);
      const updatedData = data.filter((item, index) => index !== selectedRowIndex);
      setData(updatedData);
      const scenario = getSelectedConfiguration(updatedData, newRowIndex);
      updateCurrentScenario({ scenario, selectedRowIndex: newRowIndex }, globalDispatcher);
      updateHighlightRow(newRowIndex);
      setChangesAvailable(true);
      handleMutation('Delete', deletedScenario);
    }
  };

  const duplicateRow = () => {
    const scenario = getSelectedConfiguration(data, selectedRowIndex);
    if (scenario.id) {
      const copiedScenario = { ...scenario };
      const updatedData = data.slice();
      updatedData.push(copiedScenario);
      setData(updatedData);
      updateCurrentScenario(
        { scenario: copiedScenario, selectedRowIndex: data.length },
        globalDispatcher
      );
      updateHighlightRow(data.length);
      setChangesAvailable(true);
      handleMutation('Duplicate', copiedScenario);
    }
  };

  const updateDuplicateMutationItems = (items: IMutationItem[]) => {
    return items.map(each => {
      if (each.mode === 'Duplicate') {
        each.record.id = each.record.duplicateScenarioId;
      }
      // this deletes the temporary key used to persist the source of duplicated scenario.
      delete each.record.duplicateScenarioId;
      return each;
    });
  };

  const getUpdatedScenarios = (updateResponse: IScenario[], mutationItems: IMutationItem[], apiData: IScenario[]) => {
    const apiIds: number[] = apiData.map(each => each.id ? each.id : 0);
    const mutationIds: Array<number|string|boolean> = mutationItems.map(each => each.mode !== 'Duplicate' && each.record.id);
    const updateResponseIds: number[] = updateResponse.map(each => each.id ? each.id : 0);
    const returnValue: number[] = [];
    updateResponseIds.forEach(element => {
      if (mutationIds.includes(element)) {
        returnValue.push(element);
      } else if (!apiIds.includes(element)) {
        returnValue.push(element);
      }
    });
    return returnValue;
  };

  const resetLocalState = () => {
    const scenario = getSelectedConfiguration(data, 0);
    updateCurrentScenario({ scenario, selectedRowIndex: 0 }, globalDispatcher);
    onPopupClose();
    setData(apiData);
  };

  const handleClosePopup = () => {
    if (mutationData.size > 0) {
      const result = confirm(areYouSureYouWantToLeave);
      if (result) {
        resetLocalState();
      }
    } else {
      resetLocalState();
    }
  };

  const [updateScenarios] = useMutation(UPDATE_SCENARIOS, {
    update() {
      displaySuccess({
        message: scenariosUpdateSuccessMessage,
      });
    },
  });

  const [raiseCarbonEmissionReprocessRequest] = useMutation(CARBON_EMISSIONS_REQUEST_REPROCESSING, {});

  const saveScenarios = () => {
    let { items } = convertMapToArray(mutationData);
    items = updateDuplicateMutationItems(items);
    updateScenarios({
      variables: {
        scenarios: items,
      },
    })
      .then(({ data }) => {
        const updatedIds = getUpdatedScenarios(data.updateScenarios, items, apiData);
        setMutationData(new Map());
        setChangesAvailable(false);
        globalDispatcher({
          type: appActionTypes.UPDATE_SCENARIOS,
          payload: data.updateScenarios,
        });
        data.updateScenarios.map(each => {
          if (updatedIds.includes(each.id)) {
            raiseCarbonEmissionReprocessRequest({
              variables: {
                scenarioId: each.id,
                startTime: each.startTime,
                endTime: each.endTime
              }
            });
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className="manageconfig-container">
      <div className="manageconfig-header">
        {manageConfiguration}
        <Button
          style="subtle"
          onClick={handleClosePopup}
          leftIcon={<Icons iconName={`ic-ui-cancel-sm`} size="20" />}
          iconOnly={true}
        />
      </div>
      <div className="manageconfig-body">
        <div className="manageconfig-left">
          <Table
            className="airlines-table"
            data={rowData}
            columns={manageConfigurationColumns}
            rowHeaders={formatHeaders(dispatcher, sortSelector, manageConfigurationColumnHeaders, MANAGE_CONFIGURATIONS_MODULE)}
            gridID={'configuration-names'}
            columnTypes={getColumnTypes(manageConfigurationColumns)}
            onRowClick={onRowClick}
            clickedRow={clickedRow}
            showHeader={false}
          />
          <div className="manageconfig-buttons-row">
            <Button style="standard" onClick={addNewRow}>
              {addNew}
            </Button>
            <Button style="standard" disabled={disableDelete} onClick={onDeleteClick}>
              {deleteScenario}
            </Button>
            <Button style="standard" onClick={duplicateRow}>
              {duplicate}
            </Button>
          </div>
        </div>
        <ConfigurationDetailsContainer updateConfigurations={updateConfigurations} />
      </div>

      <div className="manageconfig-save">
        <Button style="primary" disabled={!changesAvailable} onClick={saveScenarios}>
          {save}
        </Button>
      </div>
    </div>
  );
};
