import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { PlaybackControls } from '@ems/client-design-system';
import { IPlaybackControl } from './interfaces';
import { useLanguageSelectors, useConfigSelectors } from 'src/app/reducers';

export const PlaybackControl = ({
  startTime,
  endTime,
  onPositionUpdate,
  onRunningStatusUpdate,
  onPlaybackSpeedUpdate,
  onDraggingStatusUpdate,
  onUpdatePlaybackType,
  onPlaybackStop,
  markerTime,
  markerTimes = [],
  startTimeBuffer = 30,
  playbackTimeBuffer = 0,
  alwaysShowDot = false,
  isFullControl = true,
  startOnLoad = true,
  className,
  profileData,
  showProfileData = false,
  canToggle = false,
  showAudio = false,
  hasAudio = false,
  operationStartTime,
}: IPlaybackControl) => {
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      tooltip: { audioUnavailable },
    },
  } = languageSelectors.getLanguage();

  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  const tsOpStartTime = operationStartTime
    ? DateTime.fromISO(operationStartTime, { setZone: true }).toSeconds()
    : null;
  const tsStartTime =
    DateTime.fromISO(startTime, { setZone: true }).toSeconds() - playbackTimeBuffer;
  const tsEndTime = DateTime.fromISO(endTime, { setZone: true }).toSeconds() + playbackTimeBuffer;
  // TODO: Deprecate this
  const tsMarkerTime = markerTime
    ? DateTime.fromISO(markerTime, { setZone: true }).toSeconds()
    : null;
  const markerLocation = markerTime ? [tsMarkerTime] : [];
  // Use this instead
  const markerLocations = markerTimes.map(time =>
    DateTime.fromISO(time, { setZone: true }).toSeconds()
  );
  const playbackStartTime = Math.max(tsStartTime, tsMarkerTime - startTimeBuffer);
  const timeZone = useMemo(() => DateTime.fromISO(startTime, { setZone: true }).zoneName, [
    startTime,
  ]);

  const convertProfileData = (data: any) => {
    const converted: any = [];
    const maxTime = data[data.length - 1].time;
    let idx = 0;
    for (let time = 0; time <= maxTime; ++time) {
      const currentPoint: any = data[idx];

      if (currentPoint.time === time) {
        converted.push({
          alt: currentPoint.alt,
          dist: currentPoint.dist,
        });
      } else if (idx < data.length && time < data[idx + 1].time) {
        converted.push({
          alt: currentPoint.alt,
          dist: currentPoint.dist,
        });
      } else {
        converted.push({
          alt: data[idx + 1].alt,
          dist: data[idx + 1].dist,
        });
        idx++;
      }
    }

    return converted;
  };

  const convertedProfileData = useMemo(() => (profileData ? convertProfileData(profileData) : []), [
    profileData,
  ]);

  const updatePosition = (position: number) => {
    if (onPositionUpdate) {
      onPositionUpdate(position);
    }
  };

  const updateRunning = (isRunning: boolean) => {
    if (onRunningStatusUpdate) {
      onRunningStatusUpdate(isRunning);
    }
  };

  const updateSpeed = (speed: number) => {
    if (onPlaybackSpeedUpdate) {
      onPlaybackSpeedUpdate(speed);
    }
  };

  const updateDrag = (isDragging: boolean) => {
    if (onDraggingStatusUpdate) {
      onDraggingStatusUpdate(isDragging);
    }
  };

  return (
    <PlaybackControls
      startTime={tsStartTime}
      endTime={tsEndTime}
      playbackStartTime={playbackStartTime}
      markerLocations={markerLocations.length ? markerLocations : markerLocation}
      timeZone={timeZone}
      onPositionUpdate={updatePosition}
      onPlayToggle={updateRunning}
      onSpeedUpdate={updateSpeed}
      onDragUpdate={updateDrag}
      onPlaybackTypeToggle={onUpdatePlaybackType}
      onPlaybackStop={onPlaybackStop}
      alwaysShowDot={alwaysShowDot}
      isFullControl={isFullControl}
      startOnLoad={startOnLoad}
      className={className}
      profileData={convertedProfileData}
      showProfileData={showProfileData}
      canToggleModes={canToggle}
      showAudio={showAudio}
      hasAudio={hasAudio}
      audioTooltipText={audioUnavailable}
      operationStartTime={tsOpStartTime}
      units={units}
    />
  );
};
