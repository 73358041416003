import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { geometryActionTypes } from 'src/app/newActionTypes';
import { fetchCustomLayersData, fetchKml } from 'src/app/resolvers/layersResolver';
// utils
import { signOut } from 'src/app/functions/core';
// constants
import { GATE_INFRINGEMENT } from 'src/constants';

export const loadRequiredGeometry = ({
  client,
  dispatcher,
  source,
  path,
  fields,
  as3d = false,
}: {
  client: ApolloClient<object>;
  dispatcher;
  source: string;
  path: string;
  fields: string;
  as3d: boolean;
}): void => {
  if (!source || !path) {
    return;
  }

  const query = gql`
    query get_source {
      sourceData @rest(type: "${source}", endpoint:"mapLayer", path: "${path}?fields=${fields}${
    as3d ? '&as3d=true' : ''
  }") {
        type
        features
      }
    }
  `;

  client
    .query({ query })
    .then(response => {
      const {
        data: { sourceData },
      } = response;
      if (sourceData !== undefined && sourceData) {
        if (source === GATE_INFRINGEMENT) {
          const { features } = sourceData;
          const sourceFeatures: any[] = [];
          features.forEach(({ id, geometry: { coordinates } }) => {
            coordinates.forEach(([lat, lng]) => {
              sourceFeatures.push({
                id,
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [lat, lng],
                },
              });
            });
          });
          const gatesSourceData = {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: sourceFeatures,
            },
          };
          // gates & symbols
          dispatcher({
            type: geometryActionTypes[source],
            data: sourceData,
            additionalData: gatesSourceData,
            as3d: as3d ? true : false,
          });
        } else {
          // corridors, selectionzones, etc
          dispatcher({
            type: geometryActionTypes[source],
            data: sourceData,
            as3d: as3d ? true : false,
          });
        }
      } else {
        dispatcher({
          type: geometryActionTypes.GEOMETRY_ERROR,
          source,
          as3d: as3d ? true : false,
        });
      }
    })
    .catch(error => {
      const { message } = error;
      // TODO: handle token refresh here just like how it's handled in createClient
      if (typeof message !== 'undefined' && message.indexOf('401') !== -1) {
        console.error(`Error 401.003`, 'signOut');
        signOut('401');
      } else if (typeof message !== 'undefined' && message.indexOf('403') !== -1) {
        console.error(`Error 403.002`, 'signOut');
        signOut('403');
      } else {
        console.error('Geometry:', error.message);
        dispatcher({
          type: geometryActionTypes.GEOMETRY_ERROR,
          source,
          as3d: as3d ? true : false,
        });
      }
    });
};

export const getAvailableCustomLayers = ({
  client,
  dispatcher,
}: {
  client: ApolloClient<object>;
  dispatcher;
}) => {
  fetchCustomLayersData(client)
    .then(response => {
      dispatcher({
        type: geometryActionTypes.GIS_LAYERS,
        data: response,
      });
    })
    .catch(error => {
      console.error('Error:', error.message);
    });
};

export const getCustomLayerById = async ({ id, dispatcher }: { id: number; dispatcher }) => {
  fetchKml(id).then(data => {
    if (data) {
      dispatcher({
        type: geometryActionTypes.UPDATE_KML,
        id,
        data,
      });
    }
  });
};
