import React, { useEffect, FC, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// selectors
import { useConfigSelectors, useFilterDataSelectors } from 'src/app/reducers';
import { useSortSelectors, useFilterSelectors } from 'src/@noiseEvents/reducers';
import { NoiseEventsDispatchContext } from 'src/@noiseEvents/providers/NoiseEventsStateProvider';
// actions
import {
  fetchData,
  initialiseFilterStore,
  resetAndFetchData,
  getNoiseGroomingCount,
  fetchNoiseMonitorData,
} from 'src/@noiseEvents/actions';
// stores
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
// utils
import { usePermissions } from 'src/app/functions/permissions';
import { screenScrollToTop } from 'src/utils';
// interfaces
import { IDataContainer } from 'src/@noiseEvents/interfaces';
// constants
import { GROOMING } from 'src/app/featureToggles';

export const DataContainer: FC<IDataContainer> = ({ children, correlatedIds = [] }) => {
  const client = useApolloClient();
  const dispatcher = useContext(NoiseEventsDispatchContext);
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    grid: { resultSize },
  } = configSelectors.getConfig();
  const isGroomingAvailable = configSelectors.isFeatureAvailable(GROOMING);
  // permissions
  const { canUpdate } = usePermissions('NoiseEvent');

  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const filterSelectors = useFilterSelectors();
  const filterString = filterSelectors.getFilterString();
  const isInitialised = filterSelectors.getIfInitialised();

  // filters data
  const filtersSelectors = useFilterDataSelectors();
  const operationFilterData = filtersSelectors.getOperationsFilterData();
  const classificationGroups = filtersSelectors.getNoiseEventsFilterData().classificationGroups;
  const { from, to } = dateRangeStore.getDatesStrings();

  useEffect(() => {
    if (isInitialised) {
      resetAndFetchData(client, resultSize, dispatcher, sortString, filterString);
      if (canUpdate && isGroomingAvailable) {
        getNoiseGroomingCount({ client, dispatcher, sortString });
      }
    }
  }, [filterString, sortString, from, to]);

  useEffect(() => {
    screenScrollToTop();
  }, []);

  useEffect(() => {
    if (isInitialised) {
      fetchData({ client, resultSize, newDispatcher: dispatcher, sortString, filterString });
      fetchNoiseMonitorData(client, dispatcher, operationFilterData.airportIds);
    } else {
      initialiseFilterStore(dispatcher, classificationGroups, correlatedIds);
    }
  }, [isInitialised]);

  return <>{children}</>;
};
