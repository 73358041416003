import { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { kml as kmlToGeoJson } from '@tmcw/togeojson';
// actions
import { updateUserConfig } from 'src/app/actions';
import { getAvailableCustomLayers, getCustomLayerById } from 'src/app/actions/geometryActions';
// providers
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
import { GeometryDispatchContext } from 'src/app/providers/GeometryProvider';
// reducers
import { useGeometrySelectors } from 'src/app/reducers/geometryReducer';
import { useConfigSelectors } from 'src/app/reducers';
// props
import { IGisLayer } from 'src/app/props';
// constants
import {
  CORRIDOR_INFRINGEMENT,
  EXCLUSION_INFRINGEMENT,
  GATE_INFRINGEMENT,
  OPERATIONS,
  TRACKS,
  MONITOR_LOCATIONS,
  RUNWAYS,
} from 'src/constants';

export const useMapSettings = ({
  background,
  layers,
}: {
  background: string;
  layers: string[];
}): {
  mapStyle: string;
  storeSelectedBackground: (selectedBackground: string) => void;
  applyBackground: () => void;
  resetBackground: () => void;
  layersDisplayed: string[];
  useTracks: boolean;
  storeSelectedLayers: (selectedLayers: string[]) => void;
  applyLayers: () => void;
  resetLayers: () => void;
} => {
  const dispatcher = useContext(GlobalDispatchContext);
  // For now keep the background setting in local storage
  const configSelectors = useConfigSelectors();
  const userConfigMapBackground = configSelectors.getMapBackground();
  const [mapStyle] = useState<string>(userConfigMapBackground || background);
  // temporary selection of the background in the settings modal
  const [selectedBackground, updateSelectedBackground] = useState<string>(mapStyle);
  const [layersDisplayed, updateLayersDisplayed] = useState<string[]>(layers);
  const [selectedLayers, updateSelectedLayers] = useState<string[]>(layersDisplayed);
  // control displaying tracks layers
  const isTracksLayerOn = (options: string[]): boolean => options.indexOf(TRACKS) !== -1;
  const [useTracks, toggleUseTracks] = useState<boolean>(isTracksLayerOn(layersDisplayed));

  const storeSelectedBackground = (selectedBackground: string) => {
    updateSelectedBackground(selectedBackground);
  };

  const storeSelectedLayers = (selectedLayers: string[]) => {
    updateSelectedLayers(selectedLayers);
  };

  const requestToUpdateMapBackground = (styleSource: string) => {
    // only apply new changes if there is difference
    if (mapStyle !== styleSource) {
      updateUserConfig(dispatcher, {
        mapStyle: styleSource,
      });
      location.reload();
    }
  };

  const applyBackground = () => {
    requestToUpdateMapBackground(selectedBackground);
  };

  const resetBackground = () => {
    requestToUpdateMapBackground(background);
  };

  const requestToUpdateMapLayers = (options: string[]) => {
    // only apply new changes if there is difference
    if (layersDisplayed.sort().join() !== options.sort().join()) {
      updateLayersDisplayed(options);
      const useTracksCheck: boolean = isTracksLayerOn(options);
      if (useTracks !== useTracksCheck) {
        toggleUseTracks(useTracksCheck);
      }
    }
  };

  const applyLayers = () => {
    requestToUpdateMapLayers(selectedLayers);
  };

  const resetLayers = () => {
    requestToUpdateMapLayers(layers);
  };

  return {
    mapStyle, // map style already applied
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed, // reference layers already applied
    useTracks,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  };
};

export const useGis = (): {
  gisLayers: IGisLayer[];
} => {
  const client = useApolloClient();
  const geometryDispathcer = useContext(GeometryDispatchContext);
  const geometrySelectors = useGeometrySelectors();
  const gisLayers = geometrySelectors.getGisLayers();

  useEffect(() => {
    if (!gisLayers.length) {
      getAvailableCustomLayers({
        client,
        dispatcher: geometryDispathcer,
      });
    }
  }, []);

  return {
    gisLayers,
  };
};

export const useCustomLayers = (
  ids: number[]
): {
  kmlData: Map<number, string>;
} => {
  const geometryDispathcer = useContext(GeometryDispatchContext);
  const geometrySelectors = useGeometrySelectors();
  const kmlData = geometrySelectors.getKmlLayers();

  useEffect(() => {
    if (ids.length) {
      ids.forEach(id => {
        if (kmlData.get(id) === undefined) {
          getCustomLayerById({
            id,
            dispatcher: geometryDispathcer,
          });
        }
      });
    }
  }, [ids]);

  return {
    kmlData,
  };
};

export const useKmlLayersOnMap = ({
  mapApis,
  layersToAdd,
  kmlData,
  customLayersAvailable,
}: {
  mapApis;
  layersToAdd: number[];
  kmlData: Map<number, string>;
  customLayersAvailable;
}): {
  alreadyAdded: number[];
} => {
  const [alreadyAdded, updateAlreadyAdded] = useState<number[]>([]);
  const isDataReady = () => {
    let dataReady = true;
    layersToAdd.forEach(id => {
      if (kmlData.get(id) === undefined) {
        dataReady = false;
      }
    });
    return dataReady;
  };
  const layersToAddOrRemove = () => {
    const remove: number[] = [];
    const keep: number[] = [];
    alreadyAdded.map(id => {
      if (layersToAdd.indexOf(id) === -1) {
        remove.push(id);
      } else {
        keep.push(id);
      }
    });
    const add = layersToAdd.filter(id => keep.indexOf(id) === -1);
    if (add.length) {
      add.map(id => {
        const data = kmlData.get(id);
        const sourceId = `sourceId${id}`;
        const layerId = `layerId${id}`;
        const geoJsonData = kmlToGeoJson(new DOMParser().parseFromString(data, 'text/xml'), {
          styles: true,
        });

        const sorted = [];
        geoJsonData.features.map(feature => {
          const type = feature.geometry.type;
          if (!sorted[type]) {
            sorted[type] = [];
          }
          sorted[type].push(feature);
        });

        if (mapApis && data) {
          if (!mapApis.getSource(sourceId)) {
            mapApis.addSource(sourceId, {
              type: 'geojson',
              data: geoJsonData,
            });
          }

          // KML can be a Polygon or LineString, so we need to sort them in order to correctly style.
          Object.keys(sorted).map((key, index) => {
            const collection = sorted[key];
            const sortedSourceId = `${sourceId}${key}`;
            const sortedLayerId = `${layerId}${key}`;

            mapApis.addSource(sortedSourceId, {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: collection,
              },
            });

            if (key === 'LineString') {
              mapApis.addLayer({
                id: sortedLayerId,
                source: sortedSourceId,
                beforeId: mapApis.isRulerEnabled ? `RULER_LAYER_LINE` : null,
                type: 'line',
                paint: {
                  'line-width': 1,
                  'line-color': 'black',
                },
              });
            } else if (key === 'Polygon') {
              mapApis.addLayer({
                id: sortedLayerId,
                source: sortedSourceId,
                type: 'fill',
                beforeId: mapApis.isRulerEnabled ? `RULER_LAYER_LINE` : null,
                paint: {
                  'fill-color': ['get', 'fill'],
                  'fill-opacity': ['get', 'fill-opacity'],
                  'fill-outline-color': ['get', 'stroke'],
                },
              });
              mapApis.addLayer({
                id: `${sortedLayerId}Text`,
                source: sortedSourceId,
                beforeId: mapApis.isRulerEnabled ? `RULER_LAYER_LINE` : null,
                type: 'symbol',
                layout: {
                  'text-field': ['get', 'name'],
                },
                paint: {
                  'text-opacity': ['step', ['zoom'], 0, 11, 1],
                },
              });
            }
          });
        }

        // Place the track layers back on top
        const trackLayers = mapApis.getStyle().layers.filter(layer => layer.id.includes('tracks_'));
        trackLayers.map(layer => {
          mapApis.moveLayer(layer.id);
        });
      });
    }
    if (remove.length) {
      remove.map(id => {
        const sourceId = `sourceId${id}`;
        const layerId = `layerId${id}`;
        const mapStyle = mapApis.getStyle();
        const layersToRemove = mapStyle.layers.filter(layer => layer.id.includes(layerId));
        const sourceToRemove = Object.keys(mapStyle.sources).filter(key => key.includes(sourceId));

        layersToRemove.map(layer => {
          if (mapApis.getLayer(layer.id)) {
            mapApis.removeLayer(layer.id);
          }
        });
        sourceToRemove.map(source => {
          if (mapApis.getSource(source)) {
            mapApis.removeSource(source);
          }
        });
      });
    }
  };

  useEffect(() => {
    if (layersToAdd.join('') !== alreadyAdded.join('') && isDataReady()) {
      layersToAddOrRemove();
      updateAlreadyAdded(layersToAdd);
    }
  }, [layersToAdd, kmlData]);

  return {
    alreadyAdded,
  };
};

export const getMapSettings = (mapInstance: string, config: any, langugae: any) => {
  // Configuration
  const {
    map: { mapStyleURL, mapStyleSatelliteURL, mapStyleStreetURL },
  } = config;
  // Translation
  const {
    components: {
      labels: {
        mapStyleBasicLabel,
        mapStyleStreetLabel,
        mapStyleSatelliteLabel,
        corridors: corridorsLabel,
        gates: gatesLabel,
        exclusions: exclusionsLabel,
        monitorLocations: monitorLocationsLabel,
        runways: runwaysLabel,
        tracks: tracksLabel,
      },
    },
  } = langugae;
  const backgrounds = [
    {
      value: mapStyleURL,
      label: mapStyleBasicLabel,
      imageName: 'mapbox_style.png',
    },
    {
      value: mapStyleStreetURL,
      label: mapStyleStreetLabel,
      imageName: 'mapstyle_street.png',
    },
    {
      value: mapStyleSatelliteURL,
      label: mapStyleSatelliteLabel,
      imageName: 'satellite.png',
    },
  ];
  const layers = [
    {
      label: corridorsLabel,
      name: CORRIDOR_INFRINGEMENT,
      format: '',
    },
    {
      label: gatesLabel,
      name: GATE_INFRINGEMENT,
      format: '',
    },
    {
      label: exclusionsLabel,
      name: EXCLUSION_INFRINGEMENT,
      format: '',
    },
    {
      label: monitorLocationsLabel,
      name: MONITOR_LOCATIONS,
      format: '',
    },
    {
      label: runwaysLabel,
      name: RUNWAYS,
      format: '',
    },
    {
      label: tracksLabel,
      name: TRACKS,
      format: '',
      disabled: true,
    },
  ];

  switch (mapInstance) {
    case OPERATIONS:
      layers[5].disabled = false;
      break;
    default:
      break;
  }

  return {
    backgrounds,
    layers,
  };
};
