import React, { useState, useEffect } from 'react';
// reducers
import { useConfigSelectors, useGeometrySelectors } from 'src/app/reducers';
// utils
import { useGeometryRequiredByMap, getGeometryType } from 'src/utils';
// functions
import { useMapConfig } from 'src/app/functions/map';
import { useGis, useCustomLayers, useKmlLayersOnMap } from 'src/app/functions/mapSettings';
// constants
import {
  CORRIDOR_INFRINGEMENT,
  EXCLUSION_INFRINGEMENT,
  GATE_INFRINGEMENT,
  MONITOR_LOCATIONS,
  RUNWAYS,
} from 'src/constants';

interface IMapLayers {
  mapApis: any;
  mapRef?: any;
  mapStyle: string;
  layers: string[];
}

export const MapReferenceLayers = React.memo(({ mapApis, mapStyle, layers }: IMapLayers) => {
  const [displayed, updateDisplayed] = useState<string[]>([]);
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    map: { mapStyleSatelliteURL },
  } = configSelectors.getConfig();
  const mapBoxConfig = useMapConfig();

  const sourceIdExt = '_reference';
  const {
    corridorIdentifier,
    selectionZoneIdentifier,
    gateIdentifier,
    monitorLocationsIdentifier,
    runwaysIdentifier,
  } = mapBoxConfig;
  const sourceIdentifiers = {
    [CORRIDOR_INFRINGEMENT]: `${corridorIdentifier}${sourceIdExt}`,
    [EXCLUSION_INFRINGEMENT]: `${selectionZoneIdentifier}${sourceIdExt}`,
    [GATE_INFRINGEMENT]: `${gateIdentifier}${sourceIdExt}`,
    [MONITOR_LOCATIONS]: `${monitorLocationsIdentifier}${sourceIdExt}`,
    [RUNWAYS]: `${runwaysIdentifier}${sourceIdExt}`,
  };

  const geometrySelectors = useGeometrySelectors();
  const corridorsData = geometrySelectors.getGeometry(getGeometryType(CORRIDOR_INFRINGEMENT));
  const exlusionsData = geometrySelectors.getGeometry(getGeometryType(EXCLUSION_INFRINGEMENT));
  const gateData = geometrySelectors.getGeometry(getGeometryType(GATE_INFRINGEMENT));
  const monitorLocationsData = geometrySelectors.getGeometry(getGeometryType(MONITOR_LOCATIONS));
  const runwaysData = geometrySelectors.getGeometry(getGeometryType(RUNWAYS));

  const [referenceLayers, updateRefLayers] = useState<string[]>([]);
  const [customLayers, updateCustomLayers] = useState<any>([]);
  const { gisLayers: customLayersAvailable } = useGis();
  const { kmlData } = useCustomLayers(customLayers);
  useKmlLayersOnMap({
    mapApis,
    layersToAdd: customLayers, // needs to be added to map
    kmlData, // kml data
    customLayersAvailable,
  });

  useEffect(() => {
    if (layers) {
      const refLayersToAdd: string[] = [];
      const customLayersToAdd = customLayersAvailable.filter(
        ({ name }) => layers.indexOf(name) !== -1
      );
      const customLayerNames = customLayersToAdd.map(({ name }) => name);
      const customLayerIds = customLayersToAdd.map(({ id }) => id);
      layers.forEach(name => {
        if (customLayerNames.indexOf(name) === -1) {
          refLayersToAdd.push(name);
        }
      });
      updateRefLayers(refLayersToAdd);
      updateCustomLayers(customLayerIds);
    }
  }, [layers]);

  useGeometryRequiredByMap({
    mapApis,
    infringementTypes: referenceLayers,
    mapBoxConfig: Object.assign(mapBoxConfig, {
      isSatelliteBackground: mapStyle === mapStyleSatelliteURL,
    }),
    sourceIdExt,
  });

  const getFilters = (layer: string) => {
    switch (layer) {
      case GATE_INFRINGEMENT:
        return { selected: false, visible: true };
      case MONITOR_LOCATIONS:
        return { selected: false, visible: true };
      default:
        return { selected: true };
    }
  };

  const getData = (layer: string) => {
    switch (layer) {
      case CORRIDOR_INFRINGEMENT:
        return corridorsData && typeof corridorsData.features !== 'undefined'
          ? corridorsData.features
          : null;
      case EXCLUSION_INFRINGEMENT:
        return exlusionsData && typeof exlusionsData.features !== 'undefined'
          ? exlusionsData.features
          : null;
      case GATE_INFRINGEMENT:
        return gateData && typeof gateData.features !== 'undefined' ? gateData.features : null;
      case MONITOR_LOCATIONS:
        return monitorLocationsData && typeof monitorLocationsData.features !== 'undefined'
          ? monitorLocationsData.features
          : null;
      case RUNWAYS:
        return runwaysData && typeof runwaysData.features !== 'undefined'
          ? runwaysData.features
          : null;
      default:
        return null;
    }
  };

  // layer being added is set to state so it can trigger mapApis.on(render)
  const [layerBeingAdded, updateLayerBeingAdded] = useState<null | string>(null);

  // make sure layout visibility is set once layer is available
  const [layoutVisibilityPending, updateLayoutVisibilityPending] = useState<null | string>(null);
  const setLayerVisible = () => {
    if (mapApis && layoutVisibilityPending && mapApis.getLayer(layoutVisibilityPending)) {
      mapApis.setLayoutProperty(layoutVisibilityPending, 'visibility', 'visible');
      updateLayoutVisibilityPending(null);
    }
    updateLayerBeingAdded(null);
  };

  const handleAfterRender = () => {
    setLayerVisible();
    const rulerLayer = mapApis.getLayer('RULER_LAYER_LINE');
    // Check if ruler is top most layer
    const allLayers = mapApis.getStyle().layers;
    if (allLayers[allLayers.length - 1].id !== 'RULER_LAYER_LINE') {
      mapApis.isRulerEnabled && rulerLayer && mapApis.moveLayer('RULER_LAYER_LINE');
    }
  };

  useEffect(() => {
    if (mapApis && (layoutVisibilityPending || layerBeingAdded)) {
      mapApis.on('render', handleAfterRender);
    }
  }, [mapApis, layoutVisibilityPending, layerBeingAdded]);

  const displayLayers = layers => {
    if (mapApis && layers.length) {
      layers.forEach(layer => {
        const data = getData(layer);
        const filter = getFilters(layer);
        if (data && mapApis) {
          if (layer === MONITOR_LOCATIONS) {
            if (mapApis.getLayer(sourceIdentifiers[layer])) {
              updateLayerBeingAdded(layer);
              mapApis.setLayoutProperty(sourceIdentifiers[layer], 'visibility', 'visible');
            } else {
              // map layer not available yet
              updateLayoutVisibilityPending(sourceIdentifiers[layer]);
            }
          } else {
            updateLayerBeingAdded(layer);
            data.forEach(({ id }) => {
              mapApis.setFeatureState(
                {
                  id,
                  source: sourceIdentifiers[layer],
                },
                filter
              );
            });
          }
        }
      });
    }
  };

  const hideLayers = layers => {
    if (mapApis && layers.length) {
      layers.forEach(layer => {
        const data = getData(layer);
        if (data && mapApis) {
          if (layer === MONITOR_LOCATIONS) {
            mapApis.setLayoutProperty(sourceIdentifiers[layer], 'visibility', 'none');
          } else {
            data.forEach(({ id }) => {
              mapApis.removeFeatureState({
                id,
                source: sourceIdentifiers[layer],
              });
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    if (mapApis) {
      const layersToShow: string[] = [];
      const layerToRemove: string[] = [];
      referenceLayers.forEach(display => {
        // make sure source is added before displaying layer
        if (getData(display)) {
          layersToShow.push(display);
        }
      });
      updateDisplayed(layersToShow);
      // remove those already added
      displayed.forEach(remove => {
        if (layersToShow.indexOf(remove) === -1) {
          layerToRemove.push(remove);
        }
      });
      displayLayers(layersToShow);
      hideLayers(layerToRemove);
    }
  }, [
    mapApis,
    referenceLayers,
    corridorsData,
    exlusionsData,
    gateData,
    monitorLocationsData,
    runwaysData,
  ]);

  return null;
});
