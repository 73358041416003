import React from 'react';
import { DateTime } from 'luxon';
import { TableIcon, ColumnHeader, SafeLink } from 'src/components';
import { sortTable } from 'src/@reports/actions';
import { IFilterTypes } from 'src/@reports/interfaces';
import { tableDateTimeFormatLong, tableDateTimeFormatShort } from 'src/utils';

export const convertDataToFilterForm = (
  data: any,
  iconType: string = '',
  dataForm: 'array' | 'object' = 'array'
) => {
  // Find and add an extra classname if required based on icon type
  const className =
    {
      ac: 'filter-icon-ac',
      co: 'filter-icon-co',
    }[iconType] || '';

  if (dataForm === 'object') {
    return data && data.length
      ? data.map(({ id, name }) => ({
          key: id,
          label: name,
          icon: iconType
            ? TableIcon({ name: name.toLowerCase(), prefix: iconType, size: 24, className })
            : undefined,
        }))
      : [];
  } else {
    return data && data.length
      ? data.map(item => ({
          key: item,
          label: item,
          icon: iconType
            ? TableIcon({ name: item.toLowerCase(), prefix: iconType, size: 24, className })
            : undefined,
        }))
      : [];
  }
};

const createDownloadLink = (downloadList, translationData) => {
  const links: any[] = [];
  if (downloadList.length > 0) {
    downloadList.map(item => {
      const { id, format, resource } = item;
      if (resource) {
        const linkText = translationData[format];
        links.push(
          <SafeLink
            key={`${format}_${id}`}
            className="reports_downloadlink"
            href={resource.uri}
            download={true}>
            {linkText}
          </SafeLink>
        );
      }
    });

    if (links.length > 0) {
      return links;
    }

    return null;
  } else {
    return null;
  }
};

const createDisplayName = (type, period, startTime, translationData) => {
  const translatedType = translationData[type];
  const convertedTime = DateTime.fromISO(startTime, { setZone: true });
  let displayPeriod = convertedTime.year;
  if (period === 'Daily') {
    displayPeriod = `${convertedTime.monthLong} ${convertedTime.day} ${convertedTime.year}`;
  }
  if (period === 'Monthly') {
    displayPeriod = `${convertedTime.monthLong} ${convertedTime.year}`;
  }
  if (period === 'Quarterly') {
    displayPeriod = `Q${convertedTime.quarter} ${convertedTime.year}`;
  }

  return `${translatedType} ${displayPeriod}`;
};

export const formatReportsData = (data, translationData: object, twelveHourFormat: boolean) => {
  for (const [id, report] of data) {
    report.displayName = createDisplayName(
      report.dataType,
      report.reportInterval,
      report.startTime,
      translationData
    );
    report.displayTime = tableDateTimeFormatLong(report.lastUpdateTime, twelveHourFormat);
    report[`displayTime-short`] = tableDateTimeFormatShort(report.lastUpdateTime, twelveHourFormat);
    report.displayDownload = createDownloadLink(report.downloadList, translationData);
    data.set(id, report);
  }
  return data;
};

export const formatReportsHeaders = (
  resultSize: number,
  isLoading: boolean,
  dispatcher: any,
  sortSelectors: any,
  translationData: any
) => {
  const {
    fields: { reports: rowHeaders },
    components: {
      labels: { sortBy },
    },
  } = translationData;

  const displayName = ColumnHeader({
    sortName: 'name',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.name,
      abbreviation: null,
      sortBy,
    },
  });
  const displayTime = ColumnHeader({
    sortName: 'lastUpdateTime',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.updated,
      abbreviation: null,
      sortBy,
    },
  });
  const reportInterval = ColumnHeader({
    sortName: 'reportInterval',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.period,
      abbreviation: null,
      sortBy,
    },
  });
  const displayDownload = ColumnHeader({
    sortName: 'download',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.download,
      abbreviation: null,
      sortBy,
    },
  });

  return Object.assign({}, rowHeaders, {
    displayName,
    displayTime,
    reportInterval,
    displayDownload,
  });
};

export const translateLabels = (items: any[], translationList) => {
  if (items === undefined) {
    return items;
  }

  items.map(item => {
    const translatedLabel = translationList[item.label];
    if (translatedLabel !== undefined) {
      item.label = translatedLabel;
    }
  });

  return items;
};

export const getIfFiltersEmpty = (filters: IFilterTypes) => {
  const totalFilterLength = Object.values(filters).reduce(
    (accumulator, currentValue) => accumulator + currentValue.length,
    0
  );

  return totalFilterLength === 0;
};
