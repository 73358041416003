import React, { SyntheticEvent, useState } from 'react';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
// BluePrint JS
import { PopoverInteractionKind, Position } from '@blueprintjs/core';
// common components
import { Popover, Button, DatePicker } from '@ems/client-design-system';
// utils
import { LocaleCalendar } from 'src/utils/localeCalendar';
import { format } from 'src/utils/dateTimeConverters';

export const DatePickerComponent: any = ({
  value,
  maxDate,
  onChange,
  fieldName,
  setFieldValue,
  ...rest
}) => {
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { languageTag, firstDayOfWeek },
    operations: {
      filter: { dateFormat },
    },
  } = configSelectors.getConfig();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: { monthsLong, weekdaysShort, weekdaysLong },
    },
  } = languageSelectors.getLanguage();

  const localeCalendar = new LocaleCalendar(
    { firstDayOfWeek },
    { monthsLong, weekdaysShort, weekdaysLong }
  );

  // calendar's from and to dates
  // dialog's open & close state
  const [isDialogOpen, toggleDialog] = useState(false);
  // on interaction with datepicker's popover
  const popOverInteraction = (
    nextOpenState: boolean,
    event?: SyntheticEvent<HTMLElement>
  ): void => {
    if (typeof event !== 'undefined' && event.type === 'mousedown') {
      // mouse clicked outside the Popover
      toggleDialog(false);
    }
  };

  // datepicker's button clicked
  const buttonOnClick = () => {
    toggleDialog(!isDialogOpen);
  };
  // when datepicker's date-values changed
  const dateChanged = (selectedDate: any) => {
    if (selectedDate) {
      onChange(fieldName, selectedDate);
    }
  };

  const convertedDate = value;

  // datepicker's button's title
  const buttonTitle = (from: Date, dateFormat: string): string => {
    return `${format(from, dateFormat, languageTag)}`;
  };

  return (
    <Popover
      position={Position.BOTTOM}
      interactionKind={PopoverInteractionKind.CLICK}
      transitionDuration={0}
      autoFocus={true}
      isOpen={isDialogOpen}
      onClose={() => toggleDialog(false)}
      onInteraction={popOverInteraction}>
      <Button
        style="subtle"
        aria-label={`${buttonTitle(value, dateFormat)}`}
        onClick={buttonOnClick}>
        {buttonTitle(value, dateFormat)}
      </Button>
      <DatePicker
        datePickerProps={{
          locale: languageTag,
          localeUtils: localeCalendar.localeUtils(),
        }}
        {...rest}
        value={convertedDate}
        onChange={dateChanged}
        maxDate={maxDate}
      />
    </Popover>
  );
};
