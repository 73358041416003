import React, { createContext, useReducer } from 'react';
// reducers
import {
  dataInitialState,
  dataReducer,
  sortInitialState,
  sortReducer,
  breakdownIntialState,
  breakdownReducer,
  viewIntialState, 
  viewReducer
} from 'src/@airtrak/reducers';
import { filterInitialState, filterReducer } from 'src/@airtrak/reducers/filterReducer';
// interfaces
import { IStoreState } from 'src/@airtrak/interfaces';

export const AirtrakStateContext = createContext({
  data: dataInitialState,
  sort: sortInitialState,
  breakdown: breakdownIntialState,
  view: viewIntialState,
  filters: filterInitialState
});

export const AirtrakDispatchContext = createContext<React.Dispatch<any> | null>(null);

export const AirtrakStateProvider = ({ children }) => {
  const [dataState, dataDispatch] = useReducer(dataReducer, dataInitialState);
  const [sortState, sortDispatch] = useReducer(sortReducer, sortInitialState);
  const [breakdownState, breakdownDispatch] = useReducer(breakdownReducer, breakdownIntialState);
  const [viewState, viewDispatch] = useReducer(viewReducer, viewIntialState);
  const [filterState, filterDispatch]: any = useReducer<any>(filterReducer, filterInitialState);

  const state: IStoreState = {
    data: dataState,
    sort: sortState,
    breakdown: breakdownState,
    view: viewState,
    filters: filterState
  };

  const dispatchers = [dataDispatch, sortDispatch, breakdownDispatch, viewDispatch, filterDispatch];

  const dispatch: (action: string) => void = (action: string) =>
    dispatchers.map((dispatcher: React.Dispatch<any>) => {
      dispatcher(action);
    });
  return (
    <AirtrakStateContext.Provider value={state}>
      <AirtrakDispatchContext.Provider value={dispatch}>
        {children}
      </AirtrakDispatchContext.Provider>
    </AirtrakStateContext.Provider>
  );
};
