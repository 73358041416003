import React from 'react';
import { DateTime } from 'luxon';
import { InlineDropDown, Tooltip } from '@ems/client-design-system';
import { Pill } from '@ems/client-design-system';
// utils
import { getDeployedProductId, tableDateTimeFormat, tableDateTimeFormatShort } from 'src/utils';
import {
  IStatusItem,
  IFilterType,
  IFilterItem,
  IAddress,
  IPhoneNumbers,
  IRangeFilters,
  IComplainerFilterItem,
} from '../interfaces';
import { Link } from 'react-router-dom';
import { COMPLAINTS, OPERATIONS, COMPLAINER, COMPLAINT, ANONYMOUS } from 'src/constants';

export const getComplainerDisplayName = (name: string, anonymousText: string) => {
  if (name === ANONYMOUS || name === `${ANONYMOUS} ${ANONYMOUS}`) {
    return anonymousText;
  }
  return name;
};

const getDisplayName = ({
  complainerName,
  complainerId,
  id,
  anonymousText,
  ruleInfo,
}: {
  complainerName: string;
  complainerId: number;
  id: number;
  anonymousText: string;
  ruleInfo: any;
}) => {
  let name = complainerName.trim().length === 0 ? '—' : complainerName;
  if (name === `${ANONYMOUS} ${ANONYMOUS}`) {
    name = anonymousText;
  }
  return (
    <Tooltip text={`${name} - ${complainerId}`}>
      <Link
        className="rule_link"
        to={{
          pathname: `/${getDeployedProductId()}/${COMPLAINTS}/${id}`,
          state: ruleInfo,
        }}>
        {name}
      </Link>
    </Tooltip>
  );
};

const getShortDisplayTime = (time, twelveHourFormat) => {
  const readableTime = tableDateTimeFormat(time, twelveHourFormat);
  const shortTime = tableDateTimeFormatShort(time, twelveHourFormat);
  return (
    <Tooltip text={readableTime}>
      <span>{shortTime}</span>
    </Tooltip>
  );
};

const getDisplayReason = disturbanceTypes => {
  const reasons = disturbanceTypes && disturbanceTypes.join(', ');
  return (
    <Tooltip text={reasons}>
      <span className="no-rule_link">{reasons}</span>
    </Tooltip>
  );
};

const getDisplayPostcode = postcode => {
  if (!postcode) {
    return '';
  }
  return <span className="no-rule_link">{postcode}</span>;
};

const statusIcon = (complaint, statusOptions: IStatusItem[], onDropdownClick, canUpdate) => {
  const updateSelection = newStatus => {
    onDropdownClick(complaint.id, newStatus.key, 'AircraftComplaint');
  };

  let selectedItem = statusOptions.find(option => option.key === complaint.status);
  if (!selectedItem) {
    selectedItem = {
      key: '—',
      label: '—',
      icon: <span className="status_icon" style={{ backgroundColor: 'transparent', width: 0 }} />,
    };
  }

  if (!canUpdate) {
    return (
      <div className="u-flex-center">
        {selectedItem.icon}
        {selectedItem.label}
      </div>
    );
  }

  return (
    <div className="u-flex-center">
      <InlineDropDown
        searchItems={statusOptions}
        hasIcon={false}
        isIconOnly={false}
        isNullable={false}
        updateSelection={updateSelection}
        selectedItem={selectedItem}
      />
    </div>
  );
};

const correlatedPill = (complaintId, operationId) => {
  if (!operationId) {
    return <></>;
  }

  const pillTitle = 'Flight';
  return (
    <div className="operation_pill-container">
      <div key={`pill_${complaintId}`} className={`operation_pill`}>
        <Link
          to={{
            pathname: `/${getDeployedProductId()}/${OPERATIONS}/${operationId}`,
          }}
          className="pill--link"
          tabIndex={0}>
          <Pill title={pillTitle} icon="ic-ac-cargo" />
        </Link>
      </div>
    </div>
  );
};

export const formatComplaintsData = ({
  data,
  statusOptions,
  onDropdownClick,
  canUpdate,
  twelveHourFormat,
  translationData,
  ruleInfo,
}) => {
  const { anonymous: anonymousText } = translationData;
  for (const [id, complaint] of data) {
    complaint.displayName = getDisplayName({
      complainerName: complaint.complainerName,
      complainerId: complaint.complainerId,
      id,
      anonymousText,
      ruleInfo,
    });
    complaint.displayTime = tableDateTimeFormat(complaint.time, twelveHourFormat);
    complaint['displayTime-short'] = getShortDisplayTime(complaint.time, twelveHourFormat);
    complaint.reason = getDisplayReason(complaint.disturbanceTypes);
    complaint.complainerPostcode = getDisplayPostcode(complaint.complainerPostcode);
    complaint.displayStatus = statusIcon(complaint, statusOptions, onDropdownClick, canUpdate);
    complaint.correlated = correlatedPill(id, complaint.operationId);
    data.set(id, complaint);
  }
  return data;
};

const removeEmptyPhoneNumbers = (phoneNumbers: IPhoneNumbers[]) => {
  return phoneNumbers.filter(phoneNumber => {
    if (phoneNumber.number) {
      return phoneNumber;
    }
  });
};

export const getComplainerFormData = ({ ...data }) => {
  const {
    preferredResponseMethod,
    title,
    firstName,
    lastName,
    email,
    phoneNumbers,
    address: {
      streetAddress,
      streetAddress2,
      city,
      state,
      postcode,
      position: { latitude, longitude, altitude },
      country,
    },
  } = data;
  return {
    title,
    firstName,
    lastName,
    email,
    address: {
      streetAddress,
      streetAddress2,
      city,
      state,
      country,
      postcode,
      position: { latitude, longitude, altitude },
    },
    phoneNumbers: removeEmptyPhoneNumbers(phoneNumbers),
    preferredResponseMethod,
  };
};

export const getComplaintWithDefaultValues = data => {
  const { phoneNumbers } = data;
  return {
    ...data,
    phoneNumbers: phoneNumbers && phoneNumbers.length > 0 ? phoneNumbers : [{ number: '' }],
  };
};

export const getAnonymousComplainerFormData = () => ({
  firstName: ANONYMOUS,
  lastName: ANONYMOUS,
});

export const isAnonymousComplainer = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => {
  return firstName === ANONYMOUS && lastName === ANONYMOUS;
};

export const getComplaintFormData = data => ({
  complainerId: data.complainerId,
  reportedTime: data.contactTime,
  time: data.incidentTime,
  disturbanceTypes: data.reason,
  responseRequired: data.responseRequired,
  comments: data.complaint,
});

const convertStringToRegEx = (regExp: string) => {
  return new RegExp(regExp);
};

export const validateFields = ({
  values,
  createComplaintValidations,
  translationData,
  recordAlreadyExist = false,
  privacyEnabled = false,
  activePrivacyAgreement,
  formType,
  isAnonymous,
}: {
  values;
  createComplaintValidations;
  translationData;
  recordAlreadyExist?: boolean;
  privacyEnabled?: boolean;
  activePrivacyAgreement: null | { id: number };
  formType: null | 'complaint' | 'Complainer';
  isAnonymous: boolean;
}) => {
  const {
    title,
    email,
    firstName,
    lastName,
    phone,
    complaint,
    address: { streetAddress, streetAddress2, city, state, postcode },
  } = createComplaintValidations;
  const {
    invalidInput: invalidInputText,
    termsAndConditions: termsAndConditionsText,
    noPrivacyAgreementFound: noPrivacyAgreementFoundText,
  } = translationData;
  const errors: any = {};
  const privacyAgreementId =
    activePrivacyAgreement !== undefined && activePrivacyAgreement && activePrivacyAgreement.id
      ? activePrivacyAgreement.id
      : null;

  // Method to safely set phone number errors
  const setPhoneNumberErrors = (
    index: number,
    phoneNumberErrorArray: Array<{ number: typeof invalidInputText }> | undefined
  ) => {
    if (!phoneNumberErrorArray) {
      phoneNumberErrorArray = [];
    }
    phoneNumberErrorArray[index] = { number: invalidInputText };
    return phoneNumberErrorArray;
  };

  if ((formType === COMPLAINER || !recordAlreadyExist) && !isAnonymous) {
    if (!convertStringToRegEx(title.match).test(values.title)) {
      errors.title = invalidInputText;
    }
    if (!convertStringToRegEx(firstName.match).test(values.firstName)) {
      errors.firstName = invalidInputText;
    }
    if (!convertStringToRegEx(lastName.match).test(values.lastName)) {
      errors.lastName = invalidInputText;
    }
    if (values.email !== null && !convertStringToRegEx(email.match).test(values.email)) {
      errors.email = invalidInputText;
    }
    if (!convertStringToRegEx(phone.match).test(values.phoneNumbers[0].number)) {
      errors.phoneNumbers = setPhoneNumberErrors(0, errors.phoneNumbers);
    }
    if (
      values.phoneNumbers[1] &&
      !convertStringToRegEx(phone.match).test(values.phoneNumbers[1].number)
    ) {
      errors.phoneNumbers = setPhoneNumberErrors(1, errors.phoneNumbers);
    }
    if (
      streetAddress &&
      !convertStringToRegEx(streetAddress.match).test(values.address.streetAddress)
    ) {
      errors.address = { ...errors.address, streetAddress: invalidInputText };
    }
    if (
      streetAddress2 &&
      !convertStringToRegEx(streetAddress2.match).test(values.address.streetAddress2)
    ) {
      errors.address = { ...errors.address, streetAddress2: invalidInputText };
    }
    if (!convertStringToRegEx(state.match).test(values.address.state)) {
      errors.address = { ...errors.address, state: invalidInputText };
    }
    if (!convertStringToRegEx(city.match).test(values.address.city)) {
      errors.address = { ...errors.address, city: invalidInputText };
    }
    if (
      values.address.postcode !== null &&
      !convertStringToRegEx(postcode.match).test(values.address.postcode)
    ) {
      errors.address = { ...errors.address, postcode: invalidInputText };
    }
  }
  if (formType === COMPLAINT || !recordAlreadyExist) {
    if (!convertStringToRegEx(complaint.match).test(values.complaint)) {
      errors.complaint = invalidInputText;
    }
    if (privacyEnabled && !recordAlreadyExist) {
      // only on new inquiry submission
      if (typeof values.privacyEnabled !== 'undefined' && !values.privacyEnabled) {
        errors.privacyEnabled = termsAndConditionsText;
      }
      if (!privacyAgreementId) {
        errors.privacyEnabled = noPrivacyAgreementFoundText;
      }
    }
  }
  if (
    values.preferredContact === 'Phone1' &&
    values.phoneNumbers[0] &&
    values.phoneNumbers[0].number === ''
  ) {
    errors.phoneNumbers = setPhoneNumberErrors(0, errors.phoneNumbers);
  } else if (
    values.preferredContact === 'Phone2' &&
    values.phoneNumbers[1] &&
    values.phoneNumbers[1].number === ''
  ) {
    errors.phoneNumbers = setPhoneNumberErrors(1, errors.phoneNumbers);
  } else if (values.preferredContact === 'Email' && values.email === '') {
    errors.email = invalidInputText;
  }

  return errors;
};

export const getIfFiltersEmpty = (
  filters: IFilterType,
  timeFilter: IRangeFilters,
  complainerItem: IComplainerFilterItem
) => {
  const totalFilterLength = Object.values(filters).reduce(
    (accumulator, currentValue) => accumulator + currentValue.length,
    0
  );

  const areTimeFiltersEmpty = timeFilter.from === '' && timeFilter.to === '';
  return totalFilterLength === 0 && areTimeFiltersEmpty && complainerItem === null;
};

export const formatFilterItems = filterItems => {
  return filterItems && filterItems.length
    ? filterItems.map(item => {
        const filterItem: IFilterItem = {
          key: item,
          label: item,
          icon: '',
          className: '',
        };
        return filterItem;
      })
    : [];
};

export const translateLabels = (items: IFilterItem[], translationList) => {
  if (items === undefined) {
    return items;
  }

  items.map(item => {
    const translatedLabel = translationList[item.label];
    if (translatedLabel !== undefined) {
      item.label = translatedLabel;
    }
  });
  return items;
};

export const getMatchString = (searchKey: string, { phoneNumber, firstName, lastName }: any) => {
  if (searchKey === 'PHONE_NUMBER') {
    return `phoneNumber: "${phoneNumber}"`;
  }
  if (searchKey === 'NAME') {
    let matchName = '';
    const fullName = firstName + lastName;
    if (fullName.length < 1) {
      return matchName;
    }
    if (firstName) {
      matchName = `firstName: "${firstName}"`;
    }
    if (lastName) {
      matchName = `${matchName}, lastName: "${lastName}"`;
    }
    return matchName;
  }
};

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const textHighlighting = (text: string, substr: string) => {
  if (!text) {
    return '';
  }
  const parts = text.split(new RegExp(`(${escapeRegExp(substr)})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => (
        <span key={i} className={part.toLowerCase() === substr.toLowerCase() ? 'highlighter' : ''}>
          {part}
        </span>
      ))}{' '}
    </span>
  );
};

const getPhoneNumbers = phoneNumbers => {
  return phoneNumbers.map(phone => phone.number).join(', ');
};

export const complainerRenderOption = (complainer, searchKey, searchValue = '') => (
  <div className="complainer-option">
    <div className="row complainer-option__full-name">
      <p>
        {searchKey === 'FIRST_NAME'
          ? textHighlighting(complainer.firstName, searchValue)
          : complainer.firstName}
      </p>
      <p>
        {searchKey === 'LAST_NAME'
          ? textHighlighting(complainer.lastName, searchValue)
          : complainer.lastName}
      </p>
    </div>
    <div className="complainer-option__address">{convertAddressToString(complainer.address)}</div>
    <div className="complainer-option__phone-number">
      {complainer.phoneNumbers.length > 0 &&
        (searchKey === 'PHONE_NUMBER'
          ? textHighlighting(getPhoneNumbers(complainer.phoneNumbers), searchValue)
          : getPhoneNumbers(complainer.phoneNumbers))}
    </div>
  </div>
);

export const getComplainerFormValues = ({
  firstName,
  lastName,
  email,
  phoneNumbers,
  title,
  preferredResponseMethod,
  address: { streetAddress, streetAddress2, city, state, country, postcode, position },
  id,
}) => ({
  id,
  title: title || '',
  firstName: firstName || '',
  lastName: lastName || '',
  email: email || '',
  preferredResponseMethod,
  phoneNumbers:
    phoneNumbers.length > 0
      ? phoneNumbers.map(phone => ({ number: phone.number }))
      : [{ number: '' }],
  address: {
    place_name: convertAddressToString({
      streetAddress,
      streetAddress2,
      city,
      state,
      country,
      postcode,
      position,
    }),
    streetAddress: streetAddress || '',
    streetAddress2: streetAddress2 || '',
    city: city || '',
    state: state || '',
    country: country || '',
    postcode: postcode || '',
    position: {
      latitude:
        position && typeof position.latitude !== 'undefined' && position.latitude
          ? position.latitude
          : 0,
      longitude:
        position && typeof position.longitude !== 'undefined' && position.longitude
          ? position.longitude
          : 0,
    },
  },
});

export const formatComplaint = complaint => {
  const checkAvailableComments = notes => {
    if (Array.isArray(notes) && notes.length) {
      return notes[0];
    }
    return null;
  };
  const comment = checkAvailableComments(complaint.notes);
  return {
    id: complaint.id,
    complainerId: complaint.complainerId,
    incidentTime: complaint.time,
    contactTime: complaint.reportedTime,
    responseRequired: complaint.responseRequired,
    privacyAgreementId: complaint.privacyAgreementId,
    disturbanceTypes: complaint.disturbanceTypes,
    complaint: complaint.comments,
    comment: comment ? comment.body : '',
    commentId: comment ? comment.id : null,
  };
};

export const formatComplainer = complainer => {
  let {
    address: { position },
  } = complainer;
  position = position
    ? { latitude: position.latitude, longitude: position.longitude, altitude: position.altitude }
    : { latitude: 0, longitude: 0, altitude: 0 };
  return {
    title: complainer.title,
    firstName: complainer.firstName,
    lastName: complainer.lastName,
    email: complainer.email,
    address: {
      ...complainer.address,
      place_name: convertAddressToString(complainer.address),
      position,
    },
    phoneNumbers:
      complainer.phoneNumbers.length > 0
        ? complainer.phoneNumbers.map(phone => ({ number: phone.number }))
        : [{ number: '' }],
    preferredResponseMethod: complainer.preferredResponseMethod,
    complainerComments: complainer.comments,
  };
};

export const convertDatesToLocale = (time, reportedTime, timeZone) => {
  let isoReportedTime = reportedTime;
  let isoTime = time;
  if (!time) {
    isoTime = new Date().toISOString();
  }
  if (!reportedTime) {
    isoReportedTime = new Date().toISOString();
  }
  const localeReportedTime = DateTime.fromISO(isoReportedTime)
    .setZone(timeZone)
    .set({ seconds: 0 })
    .toISO();
  const localeTime = DateTime.fromISO(isoTime)
    .setZone(timeZone)
    .set({ seconds: 0 })
    .toISO();
  return { localeTime, localeReportedTime };
};

export const convertAddressToString = (address: IAddress) => {
  const addressFieldOrder = ['streetAddress', 'streetAddress2', 'city', 'state', 'postcode'];
  const addressKeys = Object.keys(address);
  const result: string[] = [];
  if (addressKeys.length > 0) {
    addressFieldOrder.forEach(key => {
      addressKeys.map(orderItem => {
        if (orderItem === key && address[key]) {
          result.push(address[key]);
        }
      });
    });
    return result.join(', ');
  }
};

export const objectsEquals = (x, y) => {
  if (x === y) {
    return true;
  }
  // if both x and y are null or undefined and exactly the same
  if (!(x instanceof Object) || !(y instanceof Object)) {
    return false;
  }
  // if they are not strictly equal, they both need to be Objects
  if (x.constructor !== y.constructor) {
    return false;
  }
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.
  for (const p in x) {
    if (!x.hasOwnProperty(p)) {
      continue;
    }
    // other properties were tested using x.constructor === y.constructor
    if (!y.hasOwnProperty(p)) {
      return false;
    }
    // allows to compare x[ p ] and y[ p ] when set to undefined
    if (x[p] === y[p]) {
      continue;
    }
    // if they have the same strict value or identity then they are equal
    if (typeof x[p] !== 'object') {
      return false;
    }
    // Numbers, Strings, Functions, Booleans must be strictly equal
    if (!objectsEquals(x[p], y[p])) {
      return false;
    }
    // Objects and Arrays must be tested recursively
  }
  for (const p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
      return false;
    }
  }
  // allows x[ p ] to be set to undefined
  return true;
};

export const isAddressEqual = (previousAddress, newAddress) => {
  delete previousAddress.__typename;
  delete previousAddress.place_name;
  delete newAddress.__typename;
  return objectsEquals(previousAddress, newAddress);
};

export const isComplainerUpdated = (previousComplainer, newComplainer) => {
  return objectsEquals(previousComplainer, newComplainer);
};

export const getDisturbanceTypesPlaceholder = (disturbanceTypes: string[]) => {
  if (disturbanceTypes && disturbanceTypes.length > 0) {
    return disturbanceTypes.join(', ');
  }
  return '-';
};

export const convertDateFromISOToLocale = date =>
  DateTime.fromISO(date, { setZone: true })
    .setZone('local', {
      keepLocalTime: true,
    })
    .toJSDate();
