export const configQuery: string = 'settingByDeployedProductId';
export const unitTestNodeInConfig: string = 'Unittest';

export const translationQuery: string = 'translationsByLanguageTag';
export const unitTestNodeInTranslation: string = 'unittest';

export const operationQuery: string = 'operationSummariesByTimeRange';
export const operationSchema: string = `
{
  items{
    id
    startTime
    endTime
    acid
    airline
    runwayName
    airportId
    remoteAirportId
    operationType
    aircraftCategory
    time
    points
    {
      lat
      lon
      alt
    }
  }
}
`;

export const infringementRulesQuery: string = 'infringementRules';
export const infringementRulesSchema: string = `
  {
    id
    infringementType
    description
    name
    candidateFilter {
      aircraftCategories
      aircraftTypes
      airportRunways {
        airportId
        runwayNames
      }
      daysOfWeek
      endTime
      lowestMaxTakeOffWeight
      lowestMinLandingWeight
      operationTypes
      operatorCategories
      pathName
      queryString
      startTime
    }
    ... on CcoInfringementRule {
      id
      maxAltitudeVariation
      autoInfringementSegmentLength
      ceilingAltitude
      floorAltitude
      maxLevelSegments
      minLevelSegmentLength
    }
    ... on CdoInfringementRule {
      id
      maxAltitudeVariation
      autoInfringementSegmentLength
      ceilingAltitude
      floorAltitude
      maxLevelSegments
      minLevelSegmentLength
    }
    ... on CorridorInfringementRule {
      id
      name
      allowedPassage
      ceilingAltitude
      corridorId
    }
    ... on GateInfringementRule {
      gateCriteria {
        altitudeRange {
          start
          end
        }
        gateDirections
        gateId
        intersectionMode
        speedRange {
          start
          end
        }
      }
    }
    ... on CurfewInfringementRule {
      candidateFilter {
        startTime
        endTime
      }
    }
    ... on ExclusionInfringementRule {
      selectionZoneId
    }
    ... on MinHeightInfringementRule {
      id
      requiredAltitude
      flightDistance
    }
    ... on NoiseInfringementRule {
      metric
      thresholds {
        locationId
        threshold
      }
    }
  }
`;

export const gisQuery: string = 'gisLayers';
export const gisSchema: string = `
{
  id
  name
  format
  minZoomLevel
  maxZoomLevel
  visible
  ... on VectorLayerType {
    fillColor {
      name
      red
      green
      blue
      alpha
      hex
    }
    lineColor {
      name
      red
      green
      blue
      alpha
      hex
    }
    lineWidth
  }
}
`;

export const scenarioQuery: string = 'scenario';
export const scenariosQuery: string = 'scenarios';
export const scenariosSchema: string = `
{
  id
  name
  isActive
  isDefault
  isLocked
  startTime
  endTime
  airportId
  defaultOptimumTMAApproachDistance
  applyWeatherAdjustment
  defaultLoadFactor
  defaultDurationClimb
  defaultDurationTaxiIn
  defaultDurationTakeOff
  defaultDurationTaxiOut
  defaultDurationApproach
  emissionRateMode
  emissionRateAvGas
  emissionRateSingle
  emissionRateJetFuel
  emissionRateUnknown
  
}`;

export const searchAddressQuery: string = 'searchAddress';
export const searchAddressSchema: string = `
query SearchAddress($address: String!, $bounds: InputGeographicCircleType) {
  searchAddress(address: $address, bounds: $bounds) {
    id
    formattedAddress
  }
}
`;

export const geocodeCandidateDetailQuery: string = 'geocodeCandidateDetail';
export const geocodeCandidateDetailSchema: string = `
query geocodeCandidateDetail($id: String!) {
  geocodeCandidateDetail(id: $id) {
    city
    country
    county
    formattedAddress
    neighborhood
    postcode
    state
    streetAddress
    streetName
    streetNumber
    position {
      latitude
      longitude
      altitude
    }
  }
}
`;

export const geocodePositionQuery = 'geocodePosition';
export const geocodePositionSchema = `
query geocodePosition($position: InputPositionType!) {
  geocodePosition(position: $position) {
    formattedAddress
    position {
      altitude
    }
  }
}
`;
