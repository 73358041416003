import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import {
  HeaderContainer,
  FilterContainer,
  MapContainer,
  TableContainer,
  DataContainer,
} from 'src/@operations/containers';
import { OperationsStateProvider } from 'src/@operations/providers/OperationsStateProvider';
import { SplitLayout } from 'src/components/Layout';

const OperationsScreen = ({ updateUrl }) => (
  <SplitLayout>
    <OperationsStateProvider>
      <DataContainer>
        <MapContainer />
        <div className="container-fluid">
          <div className="header-container">
            <HeaderContainer updateUrl={updateUrl} />
          </div>
          <FilterContainer />
          <TableContainer />
        </div>
      </DataContainer>
    </OperationsStateProvider>
  </SplitLayout>
);

export const Operations = withRouteProps(OperationsScreen);
