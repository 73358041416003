import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
// context
import { useConfigSelectors } from 'src/app/reducers/configReducer';
// actions
import { selectRow, loadMore } from 'src/@complaints/actions';
// selectors
import { useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useSortSelectors, useDataSelectors, useFilterSelectors } from 'src/@complaints/reducers';
// components
import { Icons, Table } from '@ems/client-design-system';
// function
import { formatHeaders, formatComplaintsData } from 'src/@complaints/functions';
// context
import { ComplaintsDispatchContext } from 'src/@complaints/providers/ComplaintsStateProvider';
// utils
import { getSelectedIndexes } from 'src/utils';
import { convertMapToArray } from 'src/utils/arrays';
import { LoadMoreBar } from 'src/components';
import { UPDATE_COMPLAINT_STATUS } from 'src/@complaints/mutations';
import { IStatusItem } from 'src/@complaints/interfaces';
import { actionTypes } from 'src/@complaints/actionTypes';
import { usePermissions } from 'src/app/functions/permissions';

export const TableContainer: FC = () => {
  // Configuration
  const dispatcher = useContext(ComplaintsDispatchContext);
  const dataSelectors = useDataSelectors();
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const filterSelectors = useFilterSelectors();
  const filterString = filterSelectors.getFilterString();

  const configSelectors = useConfigSelectors();
  const {
    globals: { '12HourFormat': twelveHourFormat },
    grid: { resultSize },
    infringements: {
      grid: { statusColors },
    },
  } = configSelectors.getConfig();

  const [dataIds, setDataIds] = useState<number[]>([]);
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [statusItems, setStatusItems] = useState<IStatusItem[]>([]);
  const filterDataSelectors = useFilterDataSelectors();
  const complaintsFilterData = filterDataSelectors.getComplaintsFilterData();

  const { canUpdate } = usePermissions('Complaint');

  // Translation
  const languageSelector = useLanguageSelectors();
  const {
    components: {
      buttons: { loadMore: loadMoreText },
      labels: {
        table: { endTable },
        anonymous,
      },
      hints: { tryChangingFilters },
      lists: { infringementStatuses },
    },
    screens: {
      operations: {
        errors: { noDataFound },
      },
    },
  } = languageSelector.getLanguage();

  const data = dataSelectors.getData();
  const pageInfo = dataSelectors.getPageInfo();
  const selectedInTable = dataSelectors.getSelectedRows();
  const isLoading = dataSelectors.getIfLoading();
  const isLoadingMore = dataSelectors.getIfLoadingMore();

  const { itemsIds, hasNextPage, endCursor, dateRange } = dataSelectors.getNavigationData();
  const ruleInfo = { itemsIds, hasNextPage, endCursor, dateRange };

  const [updateComplaintStatus] = useMutation(UPDATE_COMPLAINT_STATUS, {
    update(cache, { data: { updateAircraftComplaint } }) {
      const id = updateAircraftComplaint.id;
      const status = updateAircraftComplaint.status;

      if (dispatcher) {
        dispatcher({
          type: actionTypes.INLINE_EDIT_STATUS,
          data: {
            id,
            status,
          },
        });
      }
    },
  });

  const columns = [
    'displayName',
    'displayTime',
    'reason',
    'complainerPostcode',
    'correlated',
    'displayStatus',
  ];

  const columnTypes = {
    complainerName: {
      title: 'complainerName',
      abbreviation: '',
    },
    displayTime: {
      title: 'time',
      abbreviation: '',
    },
    reason: {
      title: 'reason',
      abbreviation: '',
    },
    complainerPostcode: {
      title: 'complainerPostcode',
      abbreviation: '',
    },
    correlated: {
      title: 'correlated',
      abbreviation: '',
    },
    displayStatus: {
      title: 'status',
      abbreviation: '',
    },
  };

  const onSelectRow = (indexes: number[]) => {
    const ids: number[] = [];
    for (const index of indexes) {
      ids.push(dataIds[index]);
    }
    selectRow(ids, dispatcher);
  };

  useEffect(() => {
    const items: IStatusItem[] = [];
    const statusOptions = complaintsFilterData.statuses;
    statusOptions.map((key: string) => {
      const color = statusColors ? statusColors[key] : 'transparent';
      const option = {
        key,
        label: infringementStatuses[key] ? infringementStatuses[key] : key,
        icon: <Icons iconName="ic-ui-circle" fill={color} />,
      };
      items.push(option);
    });

    setStatusItems(items);
  }, [infringementStatuses]);

  const handleInlineDropdown = useCallback((id, status, type) => {
    updateComplaintStatus({
      variables: {
        complaint: {
          id,
          status,
        },
      },
      optimisticResponse: {
        __typename: type,
        updateAircraftComplaint: {
          id,
          status,
          __typename: type,
        },
      },
    });
  }, []);

  useEffect(() => {
    const { keys, items } = convertMapToArray(
      formatComplaintsData({
        data,
        statusOptions: statusItems,
        onDropdownClick: handleInlineDropdown,
        canUpdate,
        twelveHourFormat,
        translationData: {
          anonymous,
        },
        ruleInfo,
      })
    );
    setDisplayData(items);
    setDataIds(keys);
  }, [data, statusItems, handleInlineDropdown, canUpdate]);

  return (
    <>
      <Table
        className="complaints-regular-table"
        data={displayData}
        loading={isLoading}
        columns={columns}
        rowHeaders={formatHeaders(
          resultSize,
          isLoading,
          dispatcher,
          sortSelectors,
          languageSelector.getLanguage()
        )}
        gridID={'complaints'}
        selectedData={getSelectedIndexes(selectedInTable, dataIds)}
        columnTypes={columnTypes}
        onSelectRow={onSelectRow}
        showDashIfEmpty={true}
        hasEnded={displayData.length > 0 && pageInfo && !pageInfo.hasNextPage}
        languageData={{ noDataTitle: noDataFound, noDataText: tryChangingFilters, endTable }}
      />
      <LoadMoreBar
        isVisible={pageInfo && pageInfo.hasNextPage ? true : false}
        isLoadingMore={isLoadingMore ? true : false}
        loadMore={loadMore}
        dispatcher={dispatcher}
        sortString={sortString}
        filterString={filterString}
        resultSize={resultSize}
        endCursor={pageInfo && pageInfo.endCursor}
        loadMoreText={loadMoreText}
      />
    </>
  );
};
