import React, { SyntheticEvent, useState } from 'react';
import { DateTime } from 'luxon';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
// BluePrint JS
import { PopoverInteractionKind, Position } from '@blueprintjs/core';
// common components
import { Popover, Button, DateTimePicker, Icons } from '@ems/client-design-system';
// utils
import { LocaleCalendar } from 'src/utils/localeCalendar';
// constants
import { INPUT_TWELVE_HOURS_FORMAT, INPUT_TWENTYFOUR_HOURS_FORMAT, KEYCODES } from 'src/constants';
// functions
import { convertDateFromISOToLocale } from 'src/@complaints/functions';
import { useEffect } from 'react';

export const DateTimeComponent: any = ({
  className,
  value,
  maxDate,
  onChange,
  fieldName,
  setFieldValue,
  twelveHourFormat,
  ...rest
}) => {
  // Configuration
  const configSelectors = useConfigSelectors();
  const dateFormat = twelveHourFormat ? INPUT_TWELVE_HOURS_FORMAT : INPUT_TWENTYFOUR_HOURS_FORMAT;
  const {
    globals: { languageTag, firstDayOfWeek },
  } = configSelectors.getConfig();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: { monthsLong, weekdaysShort, weekdaysLong },
    },
  } = languageSelectors.getLanguage();

  const localeCalendar = new LocaleCalendar(
    { firstDayOfWeek },
    { monthsLong, weekdaysShort, weekdaysLong }
  );

  const [selectedDateValue, updateSelectedDateValue] = useState<Date>(
    convertDateFromISOToLocale(value)
  );

  const [timeObject, updateTimeObject] = useState({ hour: null, minute: null });

  // calendar's from and to dates
  // dialog's open & close state
  const [isDialogOpen, toggleDialog] = useState<boolean>(false);
  // on interaction with datepicker's popover
  const popOverInteraction = (
    nextOpenState: boolean,
    event?: SyntheticEvent<HTMLElement>
  ): void => {
    if (typeof event !== 'undefined' && event.type === 'mousedown') {
      // mouse clicked outside the Popover
      toggleDialog(false);
    }
  };
  // datepicker's button's title
  const buttonTitle = (from: Date, dateFormat: string): string => {
    const fromDateString: string = DateTime.fromISO(from, { setZone: true }).toFormat(dateFormat);
    return fromDateString;
  };
  // datepicker's button clicked
  const buttonOnClick = () => {
    toggleDialog(!isDialogOpen);
  };
  // when datepicker's date-values changed
  const dateChanged = (selectedDate: Date) => {
    if (selectedDate) {
      updateSelectedDateValue(selectedDate);
      onChange(fieldName, selectedDate, setFieldValue);
    }
  };

  // Sets inputted time (if valid) to time object
  const setTime = (event: React.KeyboardEvent<HTMLInputElement>, timeUnit: string) => {
    const validateTimeInput = (timeInput: number, timeUnit: string) => {
      if (Number.isInteger(timeInput)) {
        switch (timeUnit) {
          case 'hour24':
            return timeInput <= 24 && timeInput >= 0;
          case 'hour12':
            return timeInput <= 12 && timeInput >= 1;
          case 'minute':
            return timeInput <= 60 && timeInput >= 0;
          default:
            return false;
        }
      } else {
        return false;
      }
    };

    const inputValue = event.target as HTMLInputElement;
    if (event.keyCode !== KEYCODES.tab) {
      if (validateTimeInput(Number(inputValue.value), timeUnit)) {
        if (timeUnit === 'minute') {
          updateTimeObject({ hour: timeObject.hour, minute: Number(inputValue.value) });
        } else {
          updateTimeObject({ hour: Number(inputValue.value), minute: timeObject.minute });
        }
      }
    }
  };

  // on datepicker close set the time of the returned date-time value
  const onDatePickerBlur = () => {
    const newTime = selectedDateValue;
    if (timeObject.hour !== null) {
      newTime.setHours(timeObject.hour);
    }
    if (timeObject.minute !== null) {
      newTime.setMinutes(timeObject.minute);
    }
    onChange(fieldName, newTime, setFieldValue);
    updateSelectedDateValue(null);
    updateSelectedDateValue(newTime);
  };

  // This useEffect is needed to ensure the value on time picker input updates when tab is pressed.
  useEffect(() => {
    updateSelectedDateValue(selectedDateValue);
  }, [selectedDateValue]);

  return (
    <Popover
      position={Position.BOTTOM}
      interactionKind={PopoverInteractionKind.CLICK}
      transitionDuration={0}
      autoFocus
      isOpen={isDialogOpen}
      onClose={() => toggleDialog(false)}
      onInteraction={popOverInteraction}>
      <Button
        style="subtle"
        leftIcon={<Icons iconName={`ic-ui-calendar`} size="24" />}
        aria-label={`Calendar ${buttonTitle(value, dateFormat)}`}
        onClick={buttonOnClick}>
        {buttonTitle(value, dateFormat)}
      </Button>
      <DateTimePicker
        datePickerProps={{
          locale: languageTag,
          localeUtils: localeCalendar.localeUtils(),
        }}
        timePickerProps={{
          useAmPm: twelveHourFormat,
          onKeyUp: setTime,

          onKeyDown: setTime,
          onBlur: onDatePickerBlur,
        }}
        {...rest}
        value={selectedDateValue}
        className={className}
        onBlur={onDatePickerBlur}
        onChange={dateChanged}
        maxDate={maxDate}
      />
    </Popover>
  );
};
