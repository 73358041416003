import { useContext } from 'react';
import uuid from 'uuid';
import { TableIcon } from 'src/components/Icons';
import { IDataSelectors, IDataState, IFetchedData } from 'src/@noiseEvents/props';
import { actionTypes } from 'src/@noiseEvents/newActionTypes';
import { appActionTypes } from 'src/app/actionTypes';
import { NoiseEventsStateContext } from 'src/@noiseEvents/providers/NoiseEventsStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';
import {
  setSelectionDelta,
  getMapDataFromSelectedIds,
  convertMapToArray,
  onlyUnique,
} from 'src/utils';
// function
import { uniqueIds } from 'src/utils';
// constants
import {
  CAUSE_UNKNOWN,
  CAUSE_AIRCRAFT,
  CAUSE_COMMUNITY,
  CAUSE_WEATHER,
  CAUSE_EQUIPMENT,
} from 'src/constants';

export const useDataSelectors: () => IDataSelectors = () => {
  const state: any = useContext(NoiseEventsStateContext);
  const dataState: IDataState = state.data;

  return useSelectors(dataState, (state: IDataState) => ({
    getDataInformation: () => ({
      data: state.fetchedData,
      pageInfo: state.pageInfo,
      totalCount: state.totalCount,
      groomingCount: state.groomingCount,
      selectedInTable: state.selectedInTable,
      selectedInMap: state.selectedInMap,
      selectedOperations: state.selectedOperations,
      isLoading: state.isLoading,
      areAllRowsSelected: areAllRowsSelected(state),
      isLoadingMore: state.isLoadingMore,
    }),
    getSelectedIDs: () => {
      return uniqueIds([...state.selectedInTable, ...state.selectedInMap]);
    },
    getRequiredDataForMap: () => {
      return {
        selectionLength: state.selectedInMap.length,
        addedToSelection: state.addedToSelection,
        removedFromSelection: state.removedFromSelection,
        requiredData: state.selectedInMap,
        noiseMonitors: state.noiseMonitorData,
        inAirTracks: state.inAirTracks,
      };
    },
    getNavigationData: () => {
      const { keys } = convertMapToArray(state.fetchedData);
      return {
        itemsIds: keys,
        hasNextPage: state.pageInfo ? state.pageInfo.hasNextPage : false,
        endCursor: state.pageInfo ? state.pageInfo.endCursor : undefined,
        dateRange: state.selectedDateRange,
      };
    },
    getDataFromID: (id: number) => {
      const foundData = state.fetchedData.get(id);
      if (foundData) {
        return {
          runwayName: foundData.runwayName,
          aircraftCategory: foundData.aircraftCategory,
          airportId: foundData.airportId,
          remoteAirportId: foundData.remoteAirportId,
          operationType: foundData.operationType,
          aircraftType: foundData.aircraftType,
          time: foundData.time,
        };
      }
      return {};
    },
    getCause: (ids: number[]) => ids.map(id => state.fetchedData.get(id).cause).filter(onlyUnique),
  }));
};

export const dataInitialState: IDataState = {
  isLoading: true,
  selectedInTable: [],
  selectedInMap: [],
  selectedOperations: [],
  addedToSelection: [],
  removedFromSelection: [],
  fetchedData: new Map(),
  isLoadingMore: false,
  pageInfo: undefined,
  totalCount: undefined,
  groomingCount: undefined,
  noiseMonitorData: [],
  inAirTracks: [],
  selectedDateRange: null,
};

const areAllRowsSelected = state => {
  if (state.fetchedData.size && state.selectedInTable && state.selectedInTable.length) {
    if (state.fetchedData.size === state.selectedInTable.length) {
      return true;
    } else {
      return 'indeterminate';
    }
  }
  return false;
};

const setLocalSelectionData = (state, ids) => {
  const { removedFromSelection, addedToSelection } = setSelectionDelta(ids, [
    ...state.selectedInTable,
  ]);

  state.removedFromSelection = removedFromSelection.map(
    getMapDataFromSelectedIds(state.fetchedData, 'id')
  );
  state.addedToSelection = addedToSelection.map(getMapDataFromSelectedIds(state.fetchedData, 'id'));
};

const formatADIcon = (adFlag: string | null) => {
  if (!adFlag) {
    return adFlag;
  }
  return TableIcon({
    name: adFlag.toLowerCase(),
    prefix: 'ad',
    size: 32,
  });
};

const formatFlightIcon = (acType: string | null) => {
  if (!acType) {
    return TableIcon({
      name: 'unknown',
      prefix: 'ac',
      size: 24,
    });
  }

  return TableIcon({
    name: acType.toLowerCase(),
    prefix: 'ac',
    size: 24,
  });
};

const addDisplayData = (currentData, newData: IFetchedData[]) => {
  const data = new Map(currentData);
  for (const operation of newData) {
    const cause = addCause(operation);
    operation.tableId = uuid.v4();
    operation.displayFields = addDisplayField(operation);
    operation.displayFlag = formatADIcon(operation.operationType);
    operation.maxLevel = Number(operation.maxLevel);
    operation.sel = Number(operation.sel);
    operation.cause = cause;
    operation.classification = cause;
    data.set(operation.id, { ...operation });
  }
  return data;
};

const addDisplayItem = operation => {
  operation.tableId = uuid.v4();
  operation.displayFields = addDisplayField(operation);
  operation.displayFlag = formatADIcon(operation.operationType);
  operation.maxLevel = Number(operation.maxLevel);
  operation.sel = Number(operation.sel);
  return operation;
};

const isUnknownAircraft = operation => {
  if (
    [CAUSE_COMMUNITY, CAUSE_WEATHER, CAUSE_EQUIPMENT].indexOf(operation.classification) === -1 &&
    !operation.operationId
  ) {
    return true;
  } else {
    return false;
  }
};

const addCause = operation => {
  if (isUnknownAircraft(operation)) {
    return CAUSE_UNKNOWN;
  } else {
    return operation.classification;
  }
};

const addDisplayField = operation => {
  if (isUnknownAircraft(operation)) {
    return {
      adIcon: '',
      adType: '',
      flightCategory: '',
      flightIcon: '',
      model: '',
      correlated: '',
      classification: CAUSE_UNKNOWN,
    };
  }

  return {
    adIcon: formatADIcon(operation.operationType),
    adType: operation.operationType,
    flightCategory: operation.aircraftCategory,
    flightIcon: formatFlightIcon(operation.aircraftCategory),
    correlated: '',
    classification: operation.classification,
  };
};

export const dataReducer = (state: IDataState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_FIRST_FETCH:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case actionTypes.DATA_FETCHED:
      const { data, selectedDateRange, pageInfo, totalCount } = action.data;
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingMore: false,
        fetchedData: addDisplayData(state.fetchedData, data),
        pageInfo,
        totalCount,
        selectedDateRange,
      });
    case actionTypes.NOISE_MONITORS_RECEIVED: {
      const { data } = action.data;
      return Object.assign({}, state, {
        noiseMonitorData: data,
      });
    }
    case actionTypes.IN_AIR_DATA_RECEIVED: {
      const data = action.data;
      return Object.assign({}, state, {
        inAirTracks: data,
      });
    }
    case actionTypes.GET_TOTAL_COUNT:
      if (state.totalCount !== action.data.totalCount) {
        return Object.assign({}, state, {
          totalCount: action.data.totalCount,
        });
      }
      // if it's not different, simply return state
      return state;
    case actionTypes.GET_GROOMING_COUNT:
      if (state.groomingCount !== action.data.totalCount) {
        return Object.assign({}, state, {
          groomingCount: action.data.totalCount,
        });
      }
      return state;
    case actionTypes.LOAD_MORE:
      return Object.assign({}, state, {
        isLoadingMore: true,
      });
    case actionTypes.SELECT_ROW:
      const operationsForMap = action.data.map(id => state.fetchedData.get(id));
      setLocalSelectionData(state, action.data);
      return Object.assign({}, state, {
        selectedInTable: action.data,
        selectedInMap: operationsForMap,
        selectedOperations: operationsForMap,
      });
    case actionTypes.SELECT_TRACK:
      const selectedInTable: number[] = [];
      const selectedInMap: any[] = action.data.map(operation => operation.id);
      setLocalSelectionData(state, selectedInMap);
      const selectedOperations = action.data;
      if (action.data && action.data.length) {
        const dataLength = action.data.length;
        for (let i = dataLength; i--; ) {
          if (state.fetchedData.has(selectedInMap[i])) {
            selectedInTable.push(selectedInMap[i]);
          }
        }
      }
      return Object.assign({}, state, {
        selectedInTable,
        selectedInMap,
        selectedOperations,
      });
    case actionTypes.INLINE_EDIT_CAUSE:
      const { id, cause } = action.data;
      const item = state.fetchedData.get(id);
      const newItem = {
        ...item,
        ...{
          cause: cause === CAUSE_AIRCRAFT ? CAUSE_UNKNOWN : cause,
          classification: cause === CAUSE_AIRCRAFT ? CAUSE_UNKNOWN : cause,
          acid: null,
          aircraftCategory: null,
          aircraftType: null,
          operationId: null,
          operationType: null,
          displayCategory: null,
          displayFields: null,
          displayFlag: null,
        },
      };
      const dataEdited = new Map(state.fetchedData);
      dataEdited.set(id, addDisplayItem(newItem));
      return Object.assign({}, state, {
        fetchedData: dataEdited,
      });
    case actionTypes.INLINE_EDIT_GROOMING_TAG:
      const { id: groomedItemId, isCompleted } = action.data;
      const groomedItem = state.fetchedData.get(groomedItemId);
      let tags = groomedItem.tags;
      if(groomedItem.tags) {
        tags = groomedItem.tags.map(item => {
          const temp = item;
          if (
            typeof item.name !== 'undefined' &&
            typeof item.isCompleted !== 'undefined' &&
            item.name === 'Manual Event Match Review'
          ) {
            temp.isCompleted = isCompleted;
          }
          return temp;
        });
      }
      
      const newGroomedItem = {
        ...groomedItem,
        tags,
      };
      const dataEditedFromGrooming = new Map(state.fetchedData);
      dataEditedFromGrooming.set(groomedItemId, addDisplayItem(newGroomedItem));
      return Object.assign({}, state, {
        fetchedData: dataEditedFromGrooming,
      });
    case appActionTypes.ROUTE_CHANGE:
    case actionTypes.RESET_DATA:
      // remove tracks before resetting store
      setLocalSelectionData(state, []);

      return Object.assign({}, state, {
        isLoading: true,
        selectedInTable: [],
        selectedInMap: [],
        selectedOperations: [],
        addedToSelection: [],
        fetchedData: new Map(),
        isLoadingMore: false,
        pageInfo: undefined,
        totalCount: undefined,
      });
    default:
      return state;
  }
};
