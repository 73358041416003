import React, { FC, useState, useEffect } from 'react';
// selectors
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
// common components
import { SettingsPanel, BackgroundPanel, LayersPanel } from '@ems/client-design-system';
// functions
import { getMapSettings, useGis } from 'src/app/functions/mapSettings';

interface IMapSettingsContainer {
  instance?: string;
  config: {
    background: string;
    layers: string[];
  };
  updateEvent;
}

export const MapSettingsContainer: FC<IMapSettingsContainer> = ({
  instance = '',
  config,
  updateEvent,
}) => {
  // Configuration
  const configSelectors = useConfigSelectors();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { background: backgroundTitle, layers: layersTitle },
      buttons: { hide: hideTitle, showMore: showMoreTitle },
    },
  } = languageSelectors.getLanguage();
  // map settings items
  const { backgrounds, layers } = getMapSettings(
    instance,
    configSelectors.getConfig(),
    languageSelectors.getLanguage()
  );
  // get gisLayers from GQL (if not available in the global store yet)
  const { gisLayers: customLayers } = useGis();
  // manage local state for selected background
  const [selectedIndex, updateSelectedIndex] = useState<number>(0);
  useEffect(() => {
    if (typeof config.background !== 'undefined' && config.background) {
      backgrounds.forEach(({ value }, index) => {
        if (value === config.background) {
          updateSelectedIndex(index);
        }
      });
    }
  }, [config.background]);
  // manage local state for selected layers
  const [selectedLayers, updateSelectedLayers] = useState<string[]>(config.layers);

  return (
    <SettingsPanel>
      <BackgroundPanel
        title={backgroundTitle}
        backgrounds={backgrounds}
        selectedIndex={selectedIndex}
        onSelect={selectedIndex => {
          updateSelectedIndex(selectedIndex);
          updateEvent({
            selectedBackground: backgrounds[selectedIndex].value,
          });
        }}
      />
      <LayersPanel
        translationData={{
          title: layersTitle,
          hide: hideTitle,
          showMore: showMoreTitle,
        }}
        layers={[...layers, ...customLayers]}
        selected={selectedLayers}
        onSelect={selectedLayers => {
          updateSelectedLayers(selectedLayers);
          updateEvent({ selectedLayers });
        }}
      />
    </SettingsPanel>
  );
};
