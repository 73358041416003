import { useContext } from 'react';
import {
  IFilterState,
  IFilterSelectors,
  IRangeFilters,
  IFilterItem,
} from 'src/@complaints/interfaces';
import { actionTypes } from 'src/@complaints/actionTypes';
import { ComplaintsStateContext } from 'src/@complaints/providers/ComplaintsStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';
import {
  storeFilterReducerRow,
  clearFilterReducerRow,
  storeFilterReducerRangeItem,
  storeFilterComplainer,
  letterCaseFormat,
  readFilterReducerRow,
  readFilterReducerRangeItem,
  readFilterComplainer,
  ignoreSavedFilters,
  storeCorrelatedIds,
  readCorrelatedIds,
} from 'src/utils';
import { COMPLAINTS, NULL_VALUE, HAS_OPERATION, FLIGHT, NO_FLIGHT } from 'src/constants';
import { isCompletedTimeFormat } from '@ems/client-design-system';

export const useFilterSelectors: () => IFilterSelectors = () => {
  const state: any = useContext(ComplaintsStateContext);
  const filterState: IFilterState = state.filters;

  return useSelectors(filterState, (state: IFilterState) => ({
    getIfInitialised: () => state.isInitialised,
    getRegularFilters: () => state.filters,
    getTimeFilter: () => {
      const { from, to } = state.time;
      const fromComplete = from && isCompletedTimeFormat(from);
      const toComplete = to && isCompletedTimeFormat(to);
      let label = '';
      if (fromComplete || toComplete) {
        label = `${from}-${to}`;
      }

      return {
        value: state.time,
        label,
      };
    },
    getComplainerFilter: () => state.complainer,
    getHasCorrelatedIds: () => {
      return state.correlatedIds.length > 0;
    },
    getFilterString: () => {
      let filterString = '';
      Object.entries(state.filters).map(([key, value]) => {
        let selectedKeys = [];

        if (key === HAS_OPERATION && value.length > 0) {
          const operations = mapOperationValues(value);
          filterString += `${key}: ${operations[0].key}`;
        } else {
          selectedKeys = value.reduce((acc: Array<string | null>, current: IFilterItem) => {
            const currentKey =
              current.key === NULL_VALUE ? null : letterCaseFormat(key, current.key);
            return acc.concat(currentKey);
          }, []);
        }
        if (selectedKeys.length > 0) {
          // Add comma if it's not the first filter to be added
          if (filterString !== '') {
            filterString += ', ';
          }

          const values = JSON.stringify(selectedKeys);
          filterString += `${key}: ${values}`;
        }
      });

      const { from: timeFrom, to: timeTo } = state.time;
      const fromComplete = timeFrom && isCompletedTimeFormat(timeFrom);
      const toComplete = timeTo && isCompletedTimeFormat(timeTo);
      if (fromComplete && toComplete) {
        const fromSplit = timeFrom.split(':');
        const toSplit = timeTo.split(':');
        const fromInSeconds = Number(fromSplit[0]) * 60 * 60 + Number(fromSplit[1]) * 60;
        const toInSeconds = Number(toSplit[0]) * 60 * 60 + Number(toSplit[1]) * 60;
        if (filterString !== '') {
          filterString += ', '; // Add comma if it's not the first filter to be added
        }
        filterString += `, timePeriod: {start: ${fromInSeconds}, end: ${toInSeconds}}`;
      }

      if (state.complainer) {
        filterString += `, complainerIds: [${state.complainer.id}]`;
      }

      if (state.correlatedIds.length > 0) {
        if (filterString !== '') {
          filterString += ', '; // Add comma if it's not the first filter to be added
        }

        filterString += `limitToIds: [${state.correlatedIds.toString()}]`;
      }

      return `filter: {${filterString}}`;
    },
  }));
};

const initialRangeFilters: IRangeFilters = {
  from: '',
  to: '',
};

const initialStateObj: IFilterState = {
  isInitialised: false,
  filters: {
    disturbanceTypes: [],
    hasOperation: [],
    statuses: [],
  },
  complainer: null,
  time: initialRangeFilters,
  correlatedIds: []
};
export const filterInitialState: IFilterState = Object.assign({}, initialStateObj);
export const filterReducer = (state: IFilterState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_RANGE_FILTER: {
      const { type, value, field } = action.data;
      const newValue = {
        from: field === 'from' ? value : state[type].from,
        to: field === 'to' ? value : state[type].to,
      };

      storeFilterReducerRangeItem(COMPLAINTS, type, newValue);
      return Object.assign({}, state, {
        [type]: newValue,
      });
    }
    case actionTypes.UPDATE_COMPLAINER_FILTER: {
      const { complainer } = action.data;
      storeFilterComplainer(COMPLAINTS, complainer);
      return Object.assign({}, state, {
        complainer,
      });
    }
    case actionTypes.UPDATE_SELECTED_FILTER_ITEMS: {
      const category = action.data.category;
      const selectedItems = action.data.selectedItems;
      const newState = Object.assign({}, state, {
        filters: {
          ...state.filters,
          [category]: selectedItems,
        },
      });
      storeFilterReducerRow(COMPLAINTS, newState.filters);
      return newState;
    }
    case actionTypes.CLEAR_SELECTED_FILTER_ITEMS: {
      clearFilterReducerRow(COMPLAINTS);
      return Object.assign({}, initialStateObj, {
        isInitialised: true,
      });
    }
    case actionTypes.INITIALISE_STORE: {
      const { correlatedIds } = action.data;
      const savedFiltersRow = readFilterReducerRow(COMPLAINTS);
      const savedTime = readFilterReducerRangeItem(COMPLAINTS, 'time');
      const savedComplainer = readFilterComplainer(COMPLAINTS);
      const savedCorrelated = readCorrelatedIds(COMPLAINTS);

      const initialState = {
        isInitialised: true,
        time: savedTime ? JSON.parse(savedTime) : initialStateObj.time,
        complainer: savedComplainer ? JSON.parse(savedComplainer) : initialStateObj.complainer,
        filters: savedFiltersRow ? JSON.parse(savedFiltersRow) : initialStateObj.filters,
        correlatedIds:
          savedCorrelated && savedCorrelated.length ? JSON.parse(savedCorrelated) : correlatedIds,
      };

      storeFilterReducerRow(COMPLAINTS, initialState.filters);
      storeFilterReducerRangeItem(COMPLAINTS, 'time', initialState.time);
      storeFilterComplainer(COMPLAINTS, initialState.complainer);
      storeCorrelatedIds(COMPLAINTS, initialState.correlatedIds);
      ignoreSavedFilters(COMPLAINTS);

      return Object.assign({}, state, initialState);
    }
    default:
      return state;
  }
};

const mapOperationValues = opearionArray => {
  return opearionArray.map(item => {
    if (item.key === FLIGHT) {
      return { ...item, key: true };
    } else if (item.key === NO_FLIGHT) {
      return { ...item, key: false };
    }
  });
};
