import { useContext } from 'react';
// interfaces
import { ITimeInModesState, IActions, ITimeInModesSelector } from 'src/@settings/interfaces';
// helpers
import { useSelectors } from 'src/utils/storeHelpers';
// actionTypes
import { actionTypes } from 'src/@settings/actionTypes';
// provider
import { SettingsStateContext } from 'src/@settings/provider/SettingsStateProvider';
// utils
import uuid from 'uuid';

export const useTimeInModesSelector: () => ITimeInModesSelector = () => {
  const state: any = useContext(SettingsStateContext);
  const timeInModesState: ITimeInModesState = state.timeInModes;
  return useSelectors(timeInModesState, (state: ITimeInModesState) => ({
    getIfLoading: () => state.isLoading,
    getTimeInModes: () => state.timeInModes,
    getAirport: (id: string) => getAirportById(id, state),
  }));
};

const getAirportById = (id: string, state: any) => {
  return state.airports.find(each => each.icaoCode === id);
};

export const timeInModesInitialState: ITimeInModesState = {
  airports: [{
    id: 'EGLL',
    icaoCode: 'EGLL',
    runways: [
      {
        name: '09L'
      }
    ]
  }],
  isLoading: false,
  timeInModes: []
};

const addTableId = (newData: any) => {
  const data = new Array();
  if (newData && newData.length > 0) {
    for (const mapping of newData) {
      data.push({
        ...mapping,
        tableId: uuid.v4(),
      });
    }
  }
  return data;
};

export const timeInModesReducer = (state: ITimeInModesState, action: IActions) => {
  switch (action.type) {
    case actionTypes.TIME_IN_MODES_LOADING:
      return Object.assign({}, state, { isLoading: true });
    case actionTypes.GET_TIME_IN_MODES:
      if (action.timeInModesData) {
        const timeInModesData = action.timeInModesData;
        const itemsMap = addTableId(timeInModesData);
        return Object.assign({}, state, { timeInModes: itemsMap, isLoading: false });
      }
    case actionTypes.GET_AIRPORTS:
      return Object.assign({}, state, { airports: action.airports });
    default:
      return state;
  }
};
