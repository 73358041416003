import React, { useState, useEffect } from 'react';
// components
import {
  DescriptionList,
  getConvertedVerticalDistance,
  getConvertedSpeed,
} from '@ems/client-design-system';
import { useLanguageSelectors, useGeometrySelectors, useConfigSelectors } from 'src/app/reducers';
import { getGeometryType } from 'src/utils';
import { GATE_INFRINGEMENT } from 'src/constants';

export const GatesDetailsContainer = ({ infringement }) => {
  const [items, updateItems] = useState<any>([]);
  // Configuration
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: { gateInfractions },
    },
    fields: {
      gates: { gate, infraction, altitude, speed },
    },
  } = languageSelectors.getLanguage();

  // geometries
  const geometrySelectors = useGeometrySelectors();
  const gateGeometry = geometrySelectors.getGeometry(getGeometryType(GATE_INFRINGEMENT));

  useEffect(() => {
    if (infringement && gateGeometry) {
      const feature = gateGeometry.features.find(({ id }) => id === `${infringement.gateId}`);
      const name =
        feature && typeof feature.properties.name !== 'undefined' ? feature.properties.name : '';
      updateItems([
        {
          title: gate,
          description: name,
        },
        {
          title: infraction,
          description: gateInfractions[infringement.reason],
        },
        {
          title: altitude,
          description: getConvertedVerticalDistance(
            infringement.position.altitude,
            units.distanceVertical,
            0
          ),
        },
        {
          title: speed,
          description: getConvertedSpeed(infringement.speed, units.speed, 0),
        },
      ]);
    } else {
      updateItems([
        {
          title: gate,
          description: null,
        },
        {
          title: infraction,
          description: null,
        },
        {
          title: altitude,
          description: null,
        },
        {
          title: speed,
          description: null,
        },
      ]);
    }
  }, [infringement, gateGeometry]);

  return <DescriptionList items={items} loading={!infringement} />;
};
