import React, { SyntheticEvent, useState } from 'react';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
// BluePrint JS
import { PopoverInteractionKind, Position } from '@blueprintjs/core';
// common components
import { Popover, Button, DateRangePicker, Icons } from '@ems/client-design-system';
// functions
import { useDateRangeFilter, predefinedDateRangeList } from 'src/app/functions/dateRange';
import { format, getDatePickerRange } from 'src/utils/dateTimeConverters';
import { LocaleCalendar } from 'src/utils/localeCalendar';
import { AIRTRAK } from 'src/constants'

export const DateFilter = ({ updateUrl, moduleName, scenario }) => {
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { languageTag, firstDayOfWeek },
    operations: {
      filter: { dateFormat },
    },
  } = configSelectors.getConfig();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: { dateRangeList, monthsLong, weekdaysShort, weekdaysLong },
    },
  } = languageSelectors.getLanguage();
  // By supplying LocaleUtils to Blueprint DatePicker as a prop, you can customise how dates are rendered
  const localeCalendar = new LocaleCalendar(
    { firstDayOfWeek },
    { monthsLong, weekdaysShort, weekdaysLong }
  );
  // calendar's from and to dates
  const updateQueryString = true;
  const { from, to } = useDateRangeFilter(updateQueryString);
  // dialog's open & close state
  const [isDialogOpen, toggleDialog] = useState(false);
  // on interaction with datepicker's popover
  const popOverInteraction = (
    nextOpenState: boolean,
    event?: SyntheticEvent<HTMLElement>
  ): void => {
    if (typeof event !== 'undefined' && event.type === 'mousedown') {
      // mouse clicked outside the Popover
      toggleDialog(false);
    }
  };
  // datepicker's button's title
  const buttonTitle = (from: Date, to: Date, dateFormat: string): string => {
    const fromDateString = format(from, dateFormat);
    const toDateString = format(to, dateFormat);
    return fromDateString === toDateString
      ? `${format(from, dateFormat, languageTag)}`
      : `${format(from, dateFormat, languageTag)} - ${format(to, dateFormat, languageTag)}`;
  };
  // datepicker's button clicked
  const buttonOnClick = () => {
    toggleDialog(!isDialogOpen);
  };
  // when datepicker's date-values changed
  const dateChanged = (selectedDates: any) => {
    // Upon selection of the TO date, the calendar control will hide and the corresponding data in the table will be loaded
    if (selectedDates.length === 2 && selectedDates[0] && selectedDates[1]) {
      toggleDialog(false);
      setTimeout(() => {
        updateUrl({
          from: selectedDates[0], // from Date
          to: selectedDates[1], // to Date
        });
      }, 0);
    }
  };

  const formatDate = (inputString) => {
    return new Date(inputString);
  }

  const maxDate = (moduleName === AIRTRAK && scenario !== null) ? formatDate(scenario.endTime) : getDatePickerRange('max');
  const minDate = (moduleName === AIRTRAK && scenario !== null) ? formatDate(scenario.startTime) : getDatePickerRange('min');

  return (
    <Popover
      position={Position.BOTTOM}
      interactionKind={PopoverInteractionKind.CLICK}
      transitionDuration={0}
      autoFocus={true}
      isOpen={isDialogOpen}
      onClose={() => toggleDialog(false)}
      onInteraction={popOverInteraction}>
      <Button
        style="subtle"
        leftIcon={<Icons iconName={`ic-ui-calendar`} size="24" />}
        aria-label={`Calendar ${buttonTitle(from, to, dateFormat)}`}
        onClick={buttonOnClick}>
        {buttonTitle(from, to, dateFormat)}
      </Button>
      <DateRangePicker
        locale={languageTag}
        localeUtils={localeCalendar.localeUtils()}
        shortcuts={moduleName === AIRTRAK ? false : predefinedDateRangeList(dateRangeList)}
        maxDate={maxDate}
        defaultValue={[from, to]}
        onChange={dateChanged}
        contiguousCalendarMonths={moduleName === AIRTRAK ? false : true}
        minDate={minDate}
        allowSingleDayRange={moduleName === AIRTRAK ? true : false}
      />
    </Popover>
  );
};
