import { useContext } from 'react';
import { isCompletedTimeFormat, isValidRange, isValidDecibel } from '@ems/client-design-system';
import { NoiseEventsStateContext } from 'src/@noiseEvents/providers/NoiseEventsStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';
import {
  IFilterState,
  IFilterSelectors,
  IFilterItem,
  IRangeFilters,
  IRangeValidity,
} from 'src/@noiseEvents/props';
import { actionTypes } from 'src/@noiseEvents/newActionTypes';
import { noiseEventsRequiringGroomingFilter } from 'src/@noiseEvents/resolvers/schema';
import {
  letterCaseFormat,
  storeFilterReducerRow,
  readFilterReducerRow,
  storeFilterReducerRangeItem,
  readFilterReducerRangeItem,
  clearFilterReducerRow,
  clearFilterReducerRangeItem,
  ignoreSavedFilters,
  storeCorrelatedIds,
  readCorrelatedIds,
} from 'src/utils';
import {
  UNIT_DECIBEL,
  UNIT_SECOND,
  DECIBEL_FILTER_MIN,
  DECIBEL_FILTER_MAX,
  SECONDS_FILTER_MIN,
  SECONDS_FILTER_MAX,
  ERROR_MAX_GREATER_THAN_MIN,
  NOISEEVENT,
  NULL_VALUE,
} from 'src/constants';

export const useFilterSelectors: () => IFilterSelectors = () => {
  const state: any = useContext(NoiseEventsStateContext);
  const filterState: IFilterState = state.filter;

  return useSelectors(filterState, (state: IFilterState) => ({
    getIfInitialised: () => state.isInitialised,
    getIsGroomingRequiredFilter: () => state.isGroomingRequired,
    getRegularFilters: () => state.tableFilters,
    getTimeFilter: () => {
      const { from, to } = state.time;
      const fromComplete = from && isCompletedTimeFormat(from);
      const toComplete = to && isCompletedTimeFormat(to);
      let label = '';
      if (fromComplete || toComplete) {
        label = `${from}-${to}`;
      }

      return {
        value: state.time,
        label,
      };
    },
    getLMaxFilter: () => {
      const { from, to } = state.maxLevel;
      let isFromValid = isValidDecibel(from, DECIBEL_FILTER_MIN, DECIBEL_FILTER_MAX);
      let isToValid = isValidDecibel(to, DECIBEL_FILTER_MIN, DECIBEL_FILTER_MAX);
      let label = '';
      let error: IRangeValidity | null = null;
      if ((from || to) && (isFromValid || isToValid)) {
        const fromStr = from ? `${from} ${UNIT_DECIBEL}` : '';
        const toStr = to ? `${to} ${UNIT_DECIBEL}` : '';
        label = `${fromStr}-${toStr}`;
      }
      if (from && to && !isValidRange(from, to)) {
        isFromValid = false;
        isToValid = false;
        error = {
          from: true,
          to: true,
          error: ERROR_MAX_GREATER_THAN_MIN,
        };
      }

      return {
        value: state.maxLevel,
        label,
        validity: error,
      };
    },
    getSELFilter: () => {
      const { from, to } = state.sel;
      let isFromValid = isValidDecibel(from, DECIBEL_FILTER_MIN, DECIBEL_FILTER_MAX);
      let isToValid = isValidDecibel(to, DECIBEL_FILTER_MIN, DECIBEL_FILTER_MAX);
      let label = '';
      let error: IRangeValidity | null = null;
      if ((from || to) && (isFromValid || isToValid)) {
        const fromStr = from ? `${from}${UNIT_DECIBEL}` : '';
        const toStr = to ? `${to}${UNIT_DECIBEL}` : '';
        label = `${fromStr}-${toStr}`;
      }
      if (from && to && !isValidRange(from, to)) {
        isFromValid = false;
        isToValid = false;
        error = {
          from: true,
          to: true,
          error: ERROR_MAX_GREATER_THAN_MIN,
        };
      }

      return {
        value: state.sel,
        label,
        validity: error,
      };
    },
    getDurationFilter: () => {
      const { from, to } = state.duration;
      let isFromValid = isValidDecibel(from, SECONDS_FILTER_MIN, SECONDS_FILTER_MAX);
      let isToValid = isValidDecibel(to, SECONDS_FILTER_MIN, SECONDS_FILTER_MAX);
      let label = '';
      let error: IRangeValidity | null = null;
      if ((from || to) && (isFromValid || isToValid)) {
        const fromStr = from ? `${from} ${UNIT_SECOND}` : '';
        const toStr = to ? `${to} ${UNIT_SECOND}` : '';
        label = `${fromStr}-${toStr}`;
      }
      if (from && to && !isValidRange(from, to)) {
        isFromValid = false;
        isToValid = false;
        error = {
          from: true,
          to: true,
          error: ERROR_MAX_GREATER_THAN_MIN,
        };
      }

      return {
        value: state.duration,
        label,
        validity: error,
      };
    },
    getHasCorrelatedIds: () => {
      return state.correlatedIds.length > 0;
    },
    getIfFilterApplied: () => {
      let isFiltered = false;
      Object.entries(state.tableFilters).forEach(([, value]) => {
        if (value.selectedItems.length) {
          isFiltered = true;
        }
      });
      if (state.time.from !== '' && state.time.to !== '') {
        isFiltered = true;
      }
      if (state.maxLevel.from !== '' && state.maxLevel.to !== '') {
        isFiltered = true;
      }
      if (state.sel.from !== '' && state.sel.to !== '') {
        isFiltered = true;
      }
      if (state.duration.from !== '' && state.duration.to !== '') {
        isFiltered = true;
      }
      return isFiltered;
    },
    getFilterString: () => {
      // Manually formats the selected filters to fit what is required
      // Unlike JSON.stringify, the key side needs to be unquoted
      // For operation types and aircraft categories, each value in the array must be unquoted as well,
      // as they are enum types in the back-end.
      // Example outputs: {runwayNames: ["04L"], aircraftCategories: [BusinessJet]}
      // OR {} (initially)
      let filterString = '';
      Object.entries(state.tableFilters).map(([key, value]) => {
        const valuesToFormat = ['classifications', 'aircraftCategories', 'operatorCategories'];
        const selectedKeys = value.reduce((acc: Array<string | null>, current: IFilterItem) => {
          const currentKey = current.key === NULL_VALUE ? null : letterCaseFormat(key, current.key);
          return acc.concat(currentKey);
        }, []);
        if (selectedKeys.length > 0) {
          // Add comma if it's not the first filter to be added
          if (filterString !== '') {
            filterString += ', ';
          }

          const values = JSON.stringify(selectedKeys);
          const formattedValues = valuesToFormat.includes(key) ? values.replace(/\"/g, '') : values;
          if (key === 'classifications') {
            // combine and group of selected enmus
            // e.g. selecting Aircraft filter would add the followings:
            // selectedEnums = SingleLocalAircraft,MultipleLocalAircraft,NonLocalAircraft,MixedLocalAndNonLocalAircraft
            const selectedEnums = selectedKeys
              .map(classification => `${state.classificationGroups[classification].join()}`)
              .join();
            filterString += `classifications: [${selectedEnums}]`;
          } else {
            filterString += `${key}: ${formattedValues}`;
          }
        }
      });

      const { from: timeFrom, to: timeTo } = state.time;
      const fromComplete = timeFrom && isCompletedTimeFormat(timeFrom);
      const toComplete = timeTo && isCompletedTimeFormat(timeTo);
      if (fromComplete && toComplete) {
        const fromSplit = timeFrom.split(':');
        const toSplit = timeTo.split(':');
        const fromInSeconds = Number(fromSplit[0]) * 60 * 60 + Number(fromSplit[1]) * 60;
        const toInSeconds = Number(toSplit[0]) * 60 * 60 + Number(toSplit[1]) * 60;
        if (filterString !== '') {
          filterString += ', '; // Add comma if it's not the first filter to be added
        }
        filterString += `, timePeriod: {start: ${fromInSeconds}, end: ${toInSeconds}}`;
      }

      [
        { filter: 'maxLevelRange', range: state.maxLevel, min: 0, max: 140 },
        { filter: 'sELRange', range: state.sel, min: 0, max: 140 },
        { filter: 'durationRange', range: state.duration, min: 0, max: 240 },
      ].forEach(({ filter, range, min, max }) => {
        const { from, to } = range;
        const validRange = from && to && isValidRange(from, to);
        if ((from && isValidDecibel(from, min, max)) || (to && isValidDecibel(to, min, max))) {
          if (filterString !== '') {
            filterString += ', '; // Add comma if it's not the first filter to be added
          }
          if (validRange) {
            filterString += `${filter}: {start: ${Number(from)}, end: ${Number(to)}}`;
          } else {
            if ((from && !to) || (!from && to)) {
              filterString += `${filter}: {${from ? `start: ${Number(from)}` : ``}${
                to ? `end: ${Number(to)}` : ``
              }}`;
            } else {
              filterString += `${filter}: {start: ${Number(from)}}`;
            }
          }
        }
      });

      if (state.correlatedIds.length > 0) {
        if (filterString !== '') {
          filterString += ', '; // Add comma if it's not the first filter to be added
        }

        filterString += `limitToIds: [${state.correlatedIds.toString()}]`;
      }

      if (state.isGroomingRequired) {
        if (filterString !== '') {
          filterString += ', '; // Add comma if it's not the first filter to be added
        }
        filterString += noiseEventsRequiringGroomingFilter;
      }

      return `filter: {${filterString}}`;
    },
  }));
};

const initialRangeFilters: IRangeFilters = {
  from: '',
  to: '',
};

const initialStateObj: IFilterState = {
  isInitialised: false,
  isGroomingRequired: false,
  tableFilters: {
    locationNames: [],
    aircraftCategories: [],
    operatorCategories: [],
    classifications: [],
  },
  time: initialRangeFilters,
  maxLevel: initialRangeFilters,
  duration: initialRangeFilters,
  sel: initialRangeFilters,
  classificationGroups: {
    Unknown: [],
    Aircraft: [],
    Community: [],
    Weather: [],
    Equipment: [],
  },
  correlatedIds: [],
};

export const filterInitialState: IFilterState = Object.assign({}, initialStateObj);

export const filterReducer = (state: IFilterState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_RANGE_FILTER: {
      const { type, value, field } = action.data;
      const newValue = {
        from: field === 'from' ? value : state[type].from,
        to: field === 'to' ? value : state[type].to,
      };

      storeFilterReducerRangeItem(NOISEEVENT, type, newValue);
      return Object.assign({}, state, {
        [type]: newValue,
      });
    }
    case actionTypes.UPDATE_GROOMING_FLAG_FILTER: {
      return Object.assign({}, state, {
        isGroomingRequired: action.data.isGroomingRequired,
      });
    }
    case actionTypes.UPDATE_SELECTED_ITEMS: {
      const { category, selectedItems } = action.data;
      const newState = Object.assign({}, state, {
        tableFilters: {
          ...state.tableFilters,
          [category]: selectedItems,
        },
      });

      storeFilterReducerRow(NOISEEVENT, newState.tableFilters);
      return newState;
    }
    case actionTypes.CLEAR_SELECTED_ITEMS:
      clearFilterReducerRow(NOISEEVENT);
      clearFilterReducerRangeItem(NOISEEVENT, 'time');
      clearFilterReducerRangeItem(NOISEEVENT, 'duration');
      clearFilterReducerRangeItem(NOISEEVENT, 'sel');
      clearFilterReducerRangeItem(NOISEEVENT, 'maxLevel');
      return Object.assign({}, initialStateObj, {
        isInitialised: true,
        isGroomingRequired: state.isGroomingRequired,
        classificationGroups: state.classificationGroups,
      });
    case actionTypes.INITIALISE_STORE:
      const { classificationGroups, correlatedIds } = action.data;
      const savedFiltersRow = readFilterReducerRow(NOISEEVENT);
      const savedTime = readFilterReducerRangeItem(NOISEEVENT, 'time');
      const savedDuration = readFilterReducerRangeItem(NOISEEVENT, 'duration');
      const savedSel = readFilterReducerRangeItem(NOISEEVENT, 'sel');
      const savedMaxLevel = readFilterReducerRangeItem(NOISEEVENT, 'maxLevel');
      const savedCorrelated = readCorrelatedIds(NOISEEVENT);

      const initialState = {
        isInitialised: true,
        isGroomingRequired: false,
        classificationGroups,
        tableFilters: savedFiltersRow ? JSON.parse(savedFiltersRow) : initialStateObj.tableFilters,
        time: savedTime ? JSON.parse(savedTime) : initialStateObj.time,
        duration: savedDuration ? JSON.parse(savedDuration) : initialStateObj.duration,
        sel: savedSel ? JSON.parse(savedSel) : initialStateObj.sel,
        maxLevel: savedMaxLevel ? JSON.parse(savedMaxLevel) : initialStateObj.maxLevel,
        correlatedIds:
          savedCorrelated && savedCorrelated.length ? JSON.parse(savedCorrelated) : correlatedIds,
      };

      storeFilterReducerRow(NOISEEVENT, initialState.tableFilters);
      storeFilterReducerRangeItem(NOISEEVENT, 'time', initialState.time);
      storeFilterReducerRangeItem(NOISEEVENT, 'duration', initialState.duration);
      storeFilterReducerRangeItem(NOISEEVENT, 'sel', initialState.sel);
      storeFilterReducerRangeItem(NOISEEVENT, 'maxLevel', initialState.maxLevel);
      storeCorrelatedIds(NOISEEVENT, initialState.correlatedIds);
      ignoreSavedFilters(NOISEEVENT);

      return Object.assign({}, state, initialState);
    default:
      return state;
  }
};
