import { useState, useEffect } from 'react';
import { useConfigSelectors } from 'src/app/reducers';
import turfCircle from '@turf/circle';
import { UNIT_KILOMETER, UNIT_METER, UNIT_MILE } from 'src/constants';

export const useCircleRanges = (mapApis: any, selectedMapStyle = '') => {
  const [isRangeCircle, updateRangeCircle] = useState<boolean>(false);
  const [mapStyle, mapStyleApplied] = useState<string>(selectedMapStyle);
  // Configuration
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  const distanceUnits = units.distance;
  const {
    map: { mapStyleURL: defaulMapStyle },
  } = configSelectors.getConfig();

  useEffect(() => {
    if (mapStyle !== selectedMapStyle) {
      mapStyleApplied(selectedMapStyle);
    }
  }, [selectedMapStyle]);

  const addRemoveCircles = (point: any | null) => {
    const sourceIdentifier = 'source-circle';
    const circlesLayerIdentifier = 'range-circles';
    const labelsLayerIdentifier = 'range-labels';
    const circleRanges = [1, 3, 6, 10];
    if (isRangeCircle) {
      if (mapApis) {
        if (mapApis.getLayer(labelsLayerIdentifier)) {
          mapApis.removeLayer(labelsLayerIdentifier);
        }
        if (mapApis.getLayer(circlesLayerIdentifier)) {
          mapApis.removeLayer(circlesLayerIdentifier);
        }
        if (mapApis.getLayer(circlesLayerIdentifier)) {
          mapApis.removeLayer(circlesLayerIdentifier);
        }
        if (mapApis.getSource(sourceIdentifier)) {
          mapApis.removeSource(sourceIdentifier);
        }
      }
      updateRangeCircle(false);
    }
    if (point) {
      const { longitude, latitude } = point;
      const center = [longitude, latitude];
      const unitOfDistance = (distanceUnits === UNIT_KILOMETER || distanceUnits === UNIT_METER) ? UNIT_KILOMETER : UNIT_MILE;
      const options: {
        steps: number;
        units: 'degrees' | 'kilometers' | 'miles' | 'meters' | 'millimeters' | 'centimeters' | 'acres' | 'nauticalmiles' | 'inches' | 'yards' | 'feet' | 'radians' | 'hectares' | undefined;
        properties: { rangeCircle: boolean },
      } = {
        steps: 0,
        units: unitOfDistance === UNIT_KILOMETER ? 'kilometers' : 'miles',
        properties: { rangeCircle: true },
      };

      const polygons = circleRanges.map(radius => {
        options.steps = radius * 360;
        return turfCircle(center, radius, options);
      });

      const points = polygons.map(({ geometry: { coordinates } }, index) => {
        const position = coordinates[0].length - Math.round(coordinates[0].length / 4);
        return {
          type: 'Feature',
          properties: {
            label: `${circleRanges[index]}${unitOfDistance}`,
          },
          geometry: {
            type: 'Point',
            coordinates: coordinates[0][position],
          },
        };
      });

      mapApis.addSource(sourceIdentifier, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: polygons.concat(points),
        },
      });

      mapApis.addLayer({
        id: circlesLayerIdentifier,
        type: 'line',
        source: sourceIdentifier,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': selectedMapStyle === defaulMapStyle ? '#5a6872' : '#fff',
          'line-opacity': {
            stops: [
              [8, 0],
              [10, 1],
            ],
          },
          'line-blur': 0.7,
          'line-width': ['interpolate', ['linear'], ['zoom'], 8, 1, 10, 1.5, 12, 2, 14, 2.5, 16, 3],
        },
        filter: ['==', 'rangeCircle', true],
      });

      mapApis.addLayer({
        id: labelsLayerIdentifier,
        type: 'symbol',
        source: sourceIdentifier,
        layout: {
          'symbol-placement': 'point',
          'text-size': ['interpolate', ['linear'], ['zoom'], 8, 0, 9, 0, 11, 10, 16, 16],
          'text-font': ['Roboto Medium'],
          'text-field': ['get', 'label'],
          'text-variable-anchor': ['left'],
          'text-radial-offset': 0.1,
          'text-justify': 'auto',
        },
        paint: {
          'text-color': '#2e384d',
          'text-opacity': {
            stops: [
              [8, 0],
              [10, 1],
            ],
          },
        },
      });

      updateRangeCircle(true);
    }
  };

  return {
    isRangeCircle,
    addRemoveCircles,
  };
};
