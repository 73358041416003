import gql from 'graphql-tag';
import { infringementRulesQuery, infringementRulesSchema } from 'src/app/resolvers/schema';

export const fetchInfringementRulesData = client => {
  return new Promise((resolve, reject) => {
    const GET_RULES = gql`
      query {
        ${infringementRulesQuery} ${infringementRulesSchema}
      }
    `;
    client
      .query({ query: GET_RULES })
      .then(response => {
        const {
          data: { infringementRules: data },
        } = response;
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
