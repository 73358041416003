import { IAircraftMappingsData } from 'src/@settings/interfaces';
import { Circle } from 'src/@settings/components';
import { assignDropdown } from './settings';
import { AIRCRAT_MODEL_SEPERATOR } from 'src/constants';

export const formatAircraftMappingsData = (
  data,
  ltoOptions,
  enrouteOptions,
  translationData,
  updateAircraftMapping,
): IAircraftMappingsData[] => {
  const mapObject: IAircraftMappingsData[] = [];
  let index = 0;
  for (const item of data) {
    const aircraftMappingObj = { ...item };
    const status = getEmpissionsMappingStatus(
      aircraftMappingObj.ltoModel,
      aircraftMappingObj.enrouteModel
    );
    aircraftMappingObj.status = status || '';
    if (typeof aircraftMappingObj.ltoModel === 'string') {
      aircraftMappingObj.ltoModel = assignDropdown(
        index,
        item,
        ltoOptions,
        translationData,
        'ltoModel',
        aircraftMappingObj.ltoModel,
        updateAircraftMapping
      );
    }

    if (typeof aircraftMappingObj.enrouteModel === 'string') {
      aircraftMappingObj.enrouteModel = assignDropdown(
        index,
        item,
        enrouteOptions,
        translationData,
        'enrouteModel',
        aircraftMappingObj.enrouteModel,
        updateAircraftMapping
      );
    }
    mapObject.push(aircraftMappingObj);
    index = index + 1;
  }
  return mapObject;
};

export const getFormattedAircraftMappingRecord = record => {
  const enrouteModel = record.enrouteModel.split(AIRCRAT_MODEL_SEPERATOR);
  const ltoModel = record.ltoModel.split(AIRCRAT_MODEL_SEPERATOR);
  return {
    id: record.id,
    scenarioId: record.scenarioId,
    mappedEnrouteAircraft: enrouteModel[0] ? enrouteModel[0].trim() : '',
    aircraftType: record.aircraftType,
    engine: record.engine,
    mappedEnrouteEngine: enrouteModel[1] ? enrouteModel[1].trim() : '',
    mappedLTOAircraft: ltoModel[0] ? ltoModel[0].trim() : '',
    mappedLTOEngine: ltoModel[1] ? ltoModel[1].trim() : '',
  };
};

export const getEmpissionsMappingStatus = (ltoModel, enrouteModel) => {
  const ltoEmissions = ltoModel.split(AIRCRAT_MODEL_SEPERATOR);
  const enrouteEmissions = enrouteModel.split(AIRCRAT_MODEL_SEPERATOR);
  if (ltoEmissions[0] !== '?' && enrouteEmissions[0] !== '?') {
    return Circle({ circleType: 'full-circle' });
  } else if (ltoEmissions[0] === '?' && enrouteEmissions[0] === '?') {
    return Circle({ circleType: 'empty-circle' });
  } else if (ltoEmissions[0] !== '?' && enrouteEmissions[0] === '?') {
    return Circle({ circleType: 'upper-half-filled' });
  } else if (enrouteEmissions[0] !== '?' && ltoEmissions[0] === '?') {
    return Circle({ circleType: 'lower-half-filled' });
  }
};
