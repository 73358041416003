import React from 'react';
// selectors
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
import { useConfigSelectors } from 'src/app/reducers';
// components
import { BlockHeader, DescriptionList, Card, Icons, Tooltip } from '@ems/client-design-system';
// functions
import { convertObjectKeys, getOperationTheme } from 'src/utils';

export const OperationDetailsContainer = ({ operation }) => {
  let items: any = [];
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const currentOperationsTheme = getOperationTheme(selectedTrackTheme);

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      operationDetails: { title },
      lists: { operationTypes, aircraftCategories },
    },
    fields: {
      infringements: {
        acid,
        airportId,
        remoteAirportId,
        runwayName,
        aircraftCategory,
        aircraftType,
        airline,
        operationType,
      },
    },
  } = languageSelectors.getLanguage();

  const hasData: boolean = operation && !!Object.keys(operation).length;
  const convertedTranslationTooltips = convertObjectKeys({
    ...operationTypes,
    ...aircraftCategories,
  });

  if (hasData) {
    const operationTheme = currentOperationsTheme[operation.operationType.toLowerCase()];
    const operationName = operation.operationType.toLowerCase();
    items = [
      {
        title: operationType,
        description: (
          <div className={'operation-details_opType'}>
            <Tooltip text={convertedTranslationTooltips[operation.operationType]}>
              <Icons
                className={`ad-${operationName}`}
                iconName={`ic-ad-${operationName}`}
                size={28}
                style={{
                  fill: operationTheme,
                  color: operationTheme,
                }}
              />
            </Tooltip>
          </div>
        ),
      },
      {
        title: acid,
        description: operation.acid,
      },
      {
        title: airportId,
        description: operation.airportId,
      },
      {
        title: remoteAirportId,
        description: operation.remoteAirportId,
      },
      {
        title: runwayName,
        description: operation.runwayName,
      },
      {
        title: aircraftCategory,
        description: (
          <Tooltip text={convertedTranslationTooltips[operation.aircraftCategory]}>
            <Icons iconName={`ic-ac-${operation.aircraftCategory.toLowerCase()}`} size={20} />
          </Tooltip>
        ),
      },
      {
        title: aircraftType,
        description: operation.aircraftType,
      },
      {
        title: airline,
        description: operation.airline,
      },
    ];
  } else {
    items = [
      {
        title: operationType,
        description: null,
      },
      {
        title: acid,
        description: null,
      },
      {
        title: airportId,
        description: null,
      },
      {
        title: remoteAirportId,
        description: null,
      },
      {
        title: runwayName,
        description: null,
      },
      {
        title: aircraftCategory,
        description: null,
      },
      {
        title: aircraftType,
        description: null,
      },
      {
        title: airline,
        description: null,
      },
    ];
  }

  return (
    <div className="block">
      <BlockHeader title={title} loading={!hasData} />
      <Card>
        <DescriptionList items={items} loading={!hasData} />
      </Card>
    </div>
  );
};
