import React, { FC, useContext, useState, useEffect } from 'react';
// providers
import { AirtrakDispatchContext } from 'src/@airtrak/providers/AirtrakStateProvider';
// selectors
import {
  useBreakdownSelector,
  useDataSelectors,
  useFilterSelectors,
  useSortSelectors,
} from 'src/@airtrak/reducers';
import { useLanguageSelectors } from 'src/app/reducers';
// functions
import {
  formatHeaders,
  getSortData,
  getCompactedMetric,
  getCombinedColumnName,
} from 'src/@airtrak/functions';
// utils
import { capitalize, convertMapToArray } from 'src/utils';
// components
import { Table } from '@ems/client-design-system';
// constants
import { AIRCRAFT_BREAKDOWN, AIRLINE_BREAKDOWN, CARBON_EMISSION_ROUNDING_PRECISION } from 'src/constants';
import { IColumnType } from '@ems/client-design-system/dist/components/Table/interfaces';

export const TableContainer: FC = ({}) => {
  const dataSelectors = useDataSelectors();
  const dispatcher = useContext(AirtrakDispatchContext);
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();
  const sortSelectors = useSortSelectors();
  const sortObject = sortSelectors.getSortObject();
  const sortString = sortSelectors.getSortString();
  const [rowData, setRowData] = useState<object[]>([]);
  const itemsMap = dataSelectors.getTableData();
  const { items } = convertMapToArray(itemsMap);
  const breakdownSelector = useBreakdownSelector();
  const breakdown = breakdownSelector.getBreakdown();
  const [columns, setColumns] = useState<string[]>([]);
  const [footerColumns, setfooterColumns] = useState<string[]>([]);
  const [headerNames, setHeaderNames] = useState<string[]>([]);
  const footerData = dataSelectors.getFooterData();
  const [columnTypes, setColumnTypes] = useState<{[key: string]: IColumnType}>({});
  const metric = dataSelectors.getMetric();
  const filterSelector = useFilterSelectors();
  const filters = filterSelector.getRegularFilters();
  const unitLabel = dataSelectors.getCarbonEmissionDisplayUnit();

  const getColumnTypes = columnValues => {
    const columnTypeValues = {};
    columnValues.forEach(columnValue => {
      columnTypeValues[columnValue] = {
        title: columnValue,
        abbreviation: '',
        formatter: 'formatNumberToString',
        fractionDigits: CARBON_EMISSION_ROUNDING_PRECISION,
      };
    });
    return columnTypeValues;
  };

  const {
    fields: { airtrak },
    components: {
      hints: { noDataTitle, noDataText },
      lists: { metrics },
    },
  } = translationData;

  useEffect(() => {
    const sortData = getSortData(rowData, sortObject);
    setRowData(sortData);
  }, [sortString]);

  useEffect(() => {
    const sortData = getSortData(items, sortObject);
    setRowData(sortData);
    if (items.length > 0) {
      let columns;
      let footerCols = ['total'];
      let headerNamesObj;
      const columnValues = Object.keys(items[0]);
      const index = columnValues.indexOf('tableId');
      columnValues.splice(index, 1);
      const filteredColumns = {};
      if (breakdown === AIRLINE_BREAKDOWN || breakdown === AIRCRAFT_BREAKDOWN) {
        columns = columnValues;
        headerNamesObj = columns.reduce((a, b) => ((a[b] = capitalize(b)), a), {});
      } else {
        columnValues.forEach(col => {
          col === 'Combined' ? filteredColumns[col] = getCombinedColumnName(metric, airtrak, filters, unitLabel) : filteredColumns[col] = airtrak[col];
        });
        columns = Object.keys(filteredColumns);
        headerNamesObj = filteredColumns;
      }
      footerCols = footerCols.concat(columns.slice(1, columns.length));
      let metricName = metrics[metric];
      if (columns.length > 3) {
        metricName = getCompactedMetric(metrics[metric], translationData);
      }
      footerData.total = `${metricName} (${unitLabel})`;
      setColumns(columns);
      setHeaderNames(headerNamesObj);
      setfooterColumns(footerCols);
      setColumnTypes(getColumnTypes(columns));
    } else {
      setColumns(['dummy']);
      setHeaderNames([]);
      setfooterColumns([]);
      setColumnTypes({});
    }
  }, [itemsMap]);

  return (
    <>
      <div className="airtrak-table_container">
        <div className="table_wrapper-airtrak-data">
          <Table
            className="airtrak-regular-table airtrak_data_table"
            data={rowData}
            columns={columns}
            rowHeaders={formatHeaders(dispatcher, sortSelectors, headerNames)}
            gridID={'airtrak'}
            columnTypes={columnTypes}
            languageData={{
              noDataTitle: `${noDataTitle}`,
              noDataText: `${noDataText}`,
            }}
          />
        </div>
        {items.length > 0 && (
          <div className="table_wrapper-airtrak-footer">
            <Table
              className="airtrak-regular-table footer-table"
              data={[]}
              columns={columns}
              rowHeaders={formatHeaders(dispatcher, sortSelectors, [])}
              gridID={'airtrak'}
              columnTypes={columnTypes}
              showFooter={true}
              footerIds={footerColumns}
              footerData={footerData}
            />
          </div>
        )}
      </div>
    </>
  );
};
