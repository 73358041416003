import React, { useState } from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SimpleSidebarLayout } from 'src/components/Layout';
import { ContentContainer } from 'src/@dashboard/containers';

const DashboardsScreen = () => {
  const [selectedReport, updateSelectedReport] = useState(null);
  const subMenuLinkSelected = selectedObject => {
    updateSelectedReport(selectedObject);
  };
  return (
    <SimpleSidebarLayout subMenuLinkSelected={subMenuLinkSelected}>
      <div className="container-single">
        <ContentContainer selectedReport={selectedReport} />
      </div>
    </SimpleSidebarLayout>
  );
};

export const Dashboards = withRouteProps(DashboardsScreen);
