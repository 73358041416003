// map control for the settings and layers
export const TOGGLE_MAP_SETTINGS_CTRL = 'mapSettingsCtrl';

// operator category features
export const OPERATOR_CATEGORY = 'operatorCategory';

// operator tag filtering
export const OPERATION_TAGS = 'operationTags';

// data export for all the pages
export const DATA_EXPORT = 'exportData';

// grooming for noise events
export const GROOMING = 'grooming';

// Analytics tracking flag
export const ANALYTICS_TRACKING = 'analyticsTracking';

// formation count display
export const FORMATION_COUNT = 'formationCount';

// Dynamic tile server enabled
export const DYNAMIC_TILE_SERVER = 'dynamicTileServer';
