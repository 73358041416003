export const fetchReportsQuery = 'periodicReportsByTimeRange';

export const fetchReportsSchema = `
{
  totalCount
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
  items {
    dataType
    endTime
    errorCount
    fileSize
    id
    isAvailable
    lastUpdateTime
    name
    nextUpdateTime
    outOfDate
    reportFileResource {
      authenticationScheme
      uri
    }
    reportFormat
    reportInterval
    startTime
    time
  }
}
`;
