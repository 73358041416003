import {
  Button,
  Dropdown,
  Icons,
  IDropdownItem,
  Overlay,
  SkeletonText,
} from '@ems/client-design-system';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useConfigSelectors, useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useFilterSelectors } from 'src/@complaints/reducers';
import { DateFilter, PageHeader } from 'src/components';
import {
  convertObjectKeys,
  formatNumber,
  getDeployedProductId,
  translatableTemplate,
} from 'src/utils';
import { goBack } from 'src/app/functions/itemsNavigation';
import { useDataSelectors, useSortSelectors } from 'src/@complaints/reducers';
import { ExportContainer } from 'src/containers/ExportContainer';
import { withAvailabilityChecks } from 'src/app/hocs/withPermissionsCheck';
import { DATA_EXPORT } from 'src/app/featureToggles';
import { COMPLAINTS, OPERATIONS } from 'src/constants';
import { usePermissions } from 'src/app/functions/permissions';
import { withQueryStringUpdater } from 'src/app/hocs/withQueryStringUpdater';
import { IHeaderContainer } from 'src/@complaints/interfaces';
import { UPDATE_COMPLAINTS } from 'src/@complaints/mutations';
import { getData } from 'src/@complaints/actions';
import { ComplaintsDispatchContext } from 'src/@complaints/providers/ComplaintsStateProvider';
import { Link } from 'react-router-dom';

export const HeaderContainer: FC<IHeaderContainer> = ({ updateUrl, fromCorrelated }) => {
  const client = useApolloClient();
  const dispatcher = useContext(ComplaintsDispatchContext);

  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      buttons: { bulkEdit, cancel, update, addComplaint },
      headings: { updateComplaint: updateSingle, updateComplaints: updateMultiple },
      lists: { infringementStatuses },
      labels: {
        table: { totalItems },
      },
      dropdowns: {
        empty: statusEmpty,
        status: { label: statusLabel, placeholder: statusMultiple },
      },
    },
    screens: {
      complaints: { title },
      operations: { title: goBackTitle },
    },
  } = languageSelectors.getLanguage();

  const configSelectors = useConfigSelectors();
  const {
    grid: { resultSize },
    infringements: {
      grid: { statusColors },
    },
  } = configSelectors.getConfig();

  const { canUpdate } = usePermissions('Complaint');

  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();

  const filtersSelectors = useFilterDataSelectors();
  const filterData = filtersSelectors.getComplaintsFilterData();

  const filterSelectors = useFilterSelectors();
  const filterString = filterSelectors.getFilterString();
  const hasCorrelatedIds = filterSelectors.getHasCorrelatedIds()

  let statusOptions: string[] = [];
  if (filterData) {
    statusOptions = filterData.statuses;
  }

  const dataSelectors = useDataSelectors();
  const totalCount = dataSelectors.getTotalCount();
  const selectedIds = dataSelectors.getSelectedRows();
  const data = dataSelectors.getData();
  const disableBulkEditBtn = selectedIds.length < 1;

  const { itemsIds, hasNextPage, endCursor, dateRange } = dataSelectors.getNavigationData();
  const ruleInfo = { itemsIds, hasNextPage, endCursor, dateRange };

  // data export feature
  const DataExportFeature = useMemo(
    () =>
      withAvailabilityChecks(ExportContainer, {
        feature: DATA_EXPORT,
        permissions: 'Export',
      }),
    []
  );

  // Date Range Picker
  const DateFilterHOC = withQueryStringUpdater(DateFilter, updateUrl);

  // Bulk Edit
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>(statusMultiple);
  const [selectedItem, setSelectedItem] = useState<IDropdownItem | null>(null);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  const [updateComplaintsCall] = useMutation(UPDATE_COMPLAINTS, {
    onCompleted() {
      getData({ client, sortString, filterString, dispatcher, resetData: true, resultSize });
    },
  });

  const updateComplaints = () => {
    if (selectedItem) {
      updateComplaintsCall({
        variables: { ids: selectedIds, status: selectedItem.key },
      });

      handleOverlayClose();
    }
  };

  const handleOverlayState = () => {
    let placeholderValue = statusMultiple;
    const complaintsToUpdate: any = [];

    selectedIds.map(item => {
      complaintsToUpdate.push(data.get(item).status);
    });

    const statusIsSame = complaintsToUpdate.every((val, i, arr) => val === arr[0]);

    if (statusIsSame) {
      if (!complaintsToUpdate[0]) {
        placeholderValue = statusEmpty;
      } else {
        const selected = statusItems.filter(obj => {
          return obj.key === complaintsToUpdate[0];
        });

        setSelectedItem(selected[0]);
      }
    }

    setPlaceholder(placeholderValue);
    setIsOverlayOpen(!isOverlayOpen);
  };

  const handleOverlayClose = () => {
    setSelectedItem(null);
    setIsOverlayOpen(false);
  };

  const handleOverlayUpdate = (item: IDropdownItem) => {
    setPlaceholder(statusMultiple);
    if (selectedItem && item.label !== selectedItem.label) {
      setSaveDisabled(false);
    } else if (!selectedItem) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }

    setSelectedItem(item);
  };

  const translationDataList = convertObjectKeys({
    ...infringementStatuses,
  });
  const statusItems: any[] = [];
  statusOptions.map((key: string) => {
    const option = {
      key,
      label: translationDataList[key],
      icon: `ui-circle`,
      fill: statusColors[key],
    };
    statusItems.push(option);
  });

  const returnToButton = (
    <Button
      size="s"
      style="subtle"
      onClick={() => goBack(OPERATIONS, null, [])}
      aria-label={`Go back to ${goBackTitle}`}
      leftIcon={<Icons iconName={`ic-ui-chevron-left`} size="20" />}>
      {goBackTitle}
    </Button>
  );

  return (
    <>
      <PageHeader
        title={title}
        showReturnButton={fromCorrelated || hasCorrelatedIds}
        returnToButton={returnToButton}
      >
        <SkeletonText loading={typeof totalCount === 'undefined' || totalCount === -1} width="4rem">
          <span className="page-count">
            {totalCount && formatNumber(totalCount)} {totalItems}
          </span>
        </SkeletonText>
        <div className="page-tools">
          <DataExportFeature source={COMPLAINTS} selectedIds={selectedIds} />
          {canUpdate && (
            <>
              <Button
                className="page-tools_button"
                style="primary"
                size="s"
                leftIcon={<Icons iconName={`ic-ui-edit`} size="20" />}
                disabled={disableBulkEditBtn}
                onClick={() => handleOverlayState()}>
                {bulkEdit}
              </Button>
              <Link
                className="link_button"
                to={{
                  pathname: `/${getDeployedProductId()}/${COMPLAINTS}/create`,
                  state: ruleInfo,
                }}>
                <Button
                  style="primary"
                  leftIcon={<Icons iconName={`ic-ui-add`} size="20" />}>
                  {addComplaint}
                </Button>
              </Link>
            </>
          )}
          <DateFilterHOC />
        </div>
        <Overlay
          openState={isOverlayOpen}
          onClose={handleOverlayClose}
          classes={['overlay--bulk-edit']}>
          <div className="overlay_header">
            <h3>
              {selectedIds.length > 1
                ? translatableTemplate(updateMultiple, {
                    id: 'count',
                    value: selectedIds.length.toString(),
                  })
                : translatableTemplate(updateSingle, {
                    id: 'count',
                    value: selectedIds.length.toString(),
                  })}
            </h3>
            <Button
              size="s"
              style="subtle"
              iconOnly={true}
              onClick={() => handleOverlayClose()}
              aria-label="Close modal"
              className="overlay_close"
              leftIcon={
                <Icons
                  iconName="ic-ui-cancel"
                  size={16}
                  style={{ cursor: 'pointer', fill: '#5a6872' }}
                />
              }
            />
          </div>
          <div className="overlay_content">
            <Dropdown
              label={statusLabel}
              placeholderValue={placeholder}
              searchItems={statusItems}
              isNullable={false}
              updateSelection={item => handleOverlayUpdate(item)}
              selectedItem={selectedItem}
            />
          </div>
          <div className="overlay_footer">
            <Button
              onClick={() => handleOverlayClose()}
              style="subtle"
              className="bulk-edit_cancel">
              {cancel}
            </Button>
            <Button style="primary" onClick={() => updateComplaints()} disabled={saveDisabled}>
              {update}
            </Button>
          </div>
        </Overlay>
      </PageHeader>
    </>
  );
};
