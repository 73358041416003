import React, { ChangeEvent, useContext, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useConfigSelectors, useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useFilterSelectors } from 'src/@complaints/reducers/filterReducer';
import { ComplaintsDispatchContext } from 'src/@complaints/providers/ComplaintsStateProvider';
import {
  Filter,
  FilterRow,
  RangeSelector,
  validateTimeFormat,
  isValidTime,
  isCompletedTimeFormat,
  onCloseTimeDialogValidation,
  PersonFilter,
} from '@ems/client-design-system';
import {
  updateSelectedFilterItems,
  clearSelectedFilterItems,
  updateRangeFilter,
  updateComplainerFilter,
  getComplainersByPartialFullName,
} from 'src/@complaints/actions';
import { IFilterItem, IComplainer, IComplainerFilterItem } from 'src/@complaints/interfaces';
import { getIfFiltersEmpty, formatFilterItems, translateLabels } from 'src/@complaints/functions';
import { convertObjectKeys } from 'src/utils/objectModifiers';
import { HAS_OPERATION } from 'src/constants';

export const FilterContainer = () => {
  const client = useApolloClient();
  const dispatcher = useContext(ComplaintsDispatchContext);
  const configSelectors = useConfigSelectors();
  const {
    complaints: {
      filter: { availableCategoryList },
    },
  } = configSelectors.getConfig();
  const filterSelectors = useFilterSelectors();
  const filteredItems = filterSelectors.getRegularFilters();
  const complainerItem = filterSelectors.getComplainerFilter();

  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        complainer,
        filters: { clear: clearValue, filter: filterValue, clearFilters },
      },
      hints: { noMatchesFound, loading },
      filters: filtersTranslation,
      lists: { complaintsFilterCategories, reason, status, correlated },
    },
  } = languageSelectors.getLanguage();

  const convertedDataList = convertObjectKeys({
    ...reason,
    ...status,
    ...correlated,
  });

  const filtersDataSelectors = useFilterDataSelectors();
  const complaintsFilterData = filtersDataSelectors.getComplaintsFilterData();

  const convertedFilterData = {};
  Object.entries(complaintsFilterData).map(([key, value]) => {
    convertedFilterData[key] = formatFilterItems(value);
  });

  const { value: timeFilters, label: timeLabel } = filterSelectors.getTimeFilter();

  const filterComponents: JSX.Element[] = [];

  // Complainer Filter
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchData, setSearchData] = useState<IComplainer[]>([]);
  const [isLoadingComplainers, setIsLoadingComplainers] = useState<boolean>(false);
  const onComplainerClearSelect = () => {
    updateComplainerFilter(null, dispatcher);
    setSearchData([]);
  };

  const onComplainerItemSelect = (item: IComplainer) => {
    const nameArray: string[] = [];
    [item.firstName, item.lastName].forEach((field: string | null) => {
      if (field) {
        nameArray.push(field.trimRight());
      }
    });
    const name = nameArray.join(' ');

    const complainer: IComplainerFilterItem = {
      id: item.id,
      name,
    };
    updateComplainerFilter(complainer, dispatcher);
    setSearchValue('');
    setSearchData([]);
  };

  const onResultsReturned = (res: IComplainer[]) => {
    setSearchData(res);
    setIsLoadingComplainers(false);
  };

  const onSearchUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    setSearchData([]);

    if (value !== '') {
      setIsLoadingComplainers(true);
      getComplainersByPartialFullName(client, value, onResultsReturned);
    }
  };

  availableCategoryList.map((category: string) => {
    if (category === 'time') {
      filterComponents.push(
        <RangeSelector
          key="filter-time-selector"
          type="time"
          min={null}
          max={null}
          fromInputValue={timeFilters.from}
          toInputValue={timeFilters.to}
          setInputValue={(value: string, field: 'from' | 'to') => {
            updateRangeFilter('time', value, field, dispatcher);
          }}
          label={timeLabel}
          validateInputFormat={validateTimeFormat}
          isValidValue={isValidTime}
          onCloseValidation={onCloseTimeDialogValidation}
          isCompletedValueFormat={isCompletedTimeFormat}
          languageData={filtersTranslation.time}
        />
      );
    } else if (category === 'complainer') {
      filterComponents.push(
        <PersonFilter
          key="filter-complainer-selector"
          searchData={searchData}
          searchValue={searchValue}
          onSearchUpdate={onSearchUpdate}
          onItemSelect={onComplainerItemSelect}
          selectedData={complainerItem}
          onClearSelect={onComplainerClearSelect}
          isLoading={isLoadingComplainers}
          languageData={{
            title: complainer,
            noMatchesFound,
            loading,
          }}
        />
      );
    } else {
      let originalItems = convertedFilterData[category];
      if (originalItems !== undefined) {
        originalItems = translateLabels(originalItems, convertedDataList);
        filterComponents.push(
          <Filter
            key={category}
            categoryName={complaintsFilterCategories[category]}
            filterItems={originalItems}
            selectedItems={filteredItems[category]}
            updateItems={(items: IFilterItem[]) =>
              updateSelectedFilterItems(category, items, dispatcher)
            }
            languageData={{ clearValue, filterValue, noMatchesFound }}
            iconCategories={[]}
            type={category === HAS_OPERATION ? 'single' : 'multiple'}
          />
        );
      }
    }
  });

  return (
    <div>
      <FilterRow
        filters={filterComponents}
        clearFilters={() => clearSelectedFilterItems(dispatcher)}
        clearDisabled={getIfFiltersEmpty(filteredItems, timeFilters, complainerItem)}
        languageData={{ clearFilters }}
      />
    </div>
  );
};
