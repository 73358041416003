import React, { ReactElement } from 'react';
// common components
import { Icons } from '@ems/client-design-system';
// props
import { IExport } from 'src/app/props';

interface IReport {
  key: string;
  label: string;
  icon: ReactElement;
  export: IExport;
}

// constants
import {
  EXCEL_EXPORT,
  WORD_EXPORT,
  KML_EXPORT,
  INM_EXPORT,
  COMPLAINTS,
  COMPLAINT,
  OPERATIONS,
  OPERATION,
  INFRINGEMENTS,
  INFRINGEMENT,
  NOISEEVENTS,
  NOISEEVENT,
} from 'src/constants';

export const exportOptions = ({
  source,
  translationData,
}: {
  source: string;
  translationData: any;
}): IReport[] => {
  const { excel, word, kml, inm } = translationData;
  const excelReportIcon = <Icons iconName="ic-ui-report" title={excel} fill="#36b37e" />;
  const wordReportIcon = <Icons iconName="ic-ui-report" title={word} fill="#2684ff" />;
  const kmlReportIcon = <Icons iconName="ic-ui-report" title={kml} fill="#00b8d9" />;
  const inmReportIcon = <Icons iconName="ic-ui-report" title={inm} fill="#e88354" />;

  const exportOperationToWord = {
    key: WORD_EXPORT,
    label: word,
    icon: wordReportIcon,
    export: {
      type: WORD_EXPORT,
      format: 'Word',
      templateName: 'Selection/Operations Selection Report',
      outputFilename: 'Operations Report',
    },
  };

  const exportOperationToExcel = {
    key: EXCEL_EXPORT,
    label: excel,
    icon: excelReportIcon,
    export: {
      type: EXCEL_EXPORT,
      format: 'Excel',
      templateName: 'Selection/Operations Selection Report',
      outputFilename: 'Operations Report',
    },
  };

  const exportOperationToKml = {
    key: KML_EXPORT,
    label: kml,
    icon: kmlReportIcon,
    export: {
      type: KML_EXPORT,
      format: 'KML',
      templateName: '',
      outputFilename: 'Operations Report - Kml',
    },
  };

  const exportOperationToInm = {
    key: INM_EXPORT,
    label: inm,
    icon: inmReportIcon,
    export: {
      type: INM_EXPORT,
      format: 'Inm',
      templateName: '',
      outputFilename: 'Operations Report - Inm',
    },
  };

  const exportInfringementsToWord = {
    key: WORD_EXPORT,
    label: word,
    icon: wordReportIcon,
    export: {
      type: WORD_EXPORT,
      format: 'Word',
      templateName: 'Selection/Infringements Selection Report',
      outputFilename: 'Infringements Report',
    },
  };

  // const exportInfringementsToWordOnDetailsPage = {
  //   key: WORD_EXPORT,
  //   label: word,
  //   icon: wordReportIcon,
  //   export: {
  //     type: WORD_EXPORT,
  //     format: 'Word',
  //     templateName: 'Letters/Infringement Letter',
  //     outputFilename: 'Infringement Letter',
  //   },
  // };

  const exportInfringementsToExcel = {
    key: EXCEL_EXPORT,
    label: excel,
    icon: excelReportIcon,
    export: {
      type: EXCEL_EXPORT,
      format: 'Excel',
      templateName: 'Selection/Infringements Selection Report',
      outputFilename: 'Infringements Report',
    },
  };

  const exportNoiseEventToWord = {
    key: WORD_EXPORT,
    label: word,
    icon: wordReportIcon,
    export: {
      type: WORD_EXPORT,
      format: 'Word',
      templateName: 'Selection/Events Selection Report',
      outputFilename: 'Events Report',
    },
  };

  const exportNoiseEventToExcel = {
    key: EXCEL_EXPORT,
    label: excel,
    icon: excelReportIcon,
    export: {
      type: EXCEL_EXPORT,
      format: 'Excel',
      templateName: 'Selection/Events Selection Report',
      outputFilename: 'Events Report',
    },
  };

  const exportComplaintToWordOnDetailsPage = {
    key: WORD_EXPORT,
    label: word,
    icon: wordReportIcon,
    export: {
      type: WORD_EXPORT,
      format: 'Word',
      templateName: 'Letters/Inquiry Letter',
      outputFilename: 'Inquiry Letter',
    },
  };

  const exportComplaintToWord = {
    key: WORD_EXPORT,
    label: word,
    icon: wordReportIcon,
    export: {
      type: WORD_EXPORT,
      format: 'Word',
      templateName: 'Selection/Inquiries Selection Report',
      outputFilename: 'Inquiries Report',
    },
  };

  const exportComplaintToExcel = {
    key: EXCEL_EXPORT,
    label: excel,
    icon: excelReportIcon,
    export: {
      type: EXCEL_EXPORT,
      format: 'Excel',
      templateName: 'Selection/Inquiries Selection Report',
      outputFilename: 'Inquiries Report',
    },
  };

  switch (source) {
    case OPERATIONS:
      return [
        exportOperationToWord,
        exportOperationToExcel,
        exportOperationToKml,
        exportOperationToInm,
      ];
    case OPERATION:
      return [exportOperationToWord];
    case INFRINGEMENTS:
      return [exportInfringementsToWord, exportInfringementsToExcel];
    case INFRINGEMENT:
      return [exportInfringementsToWord];
    case NOISEEVENTS:
      return [exportNoiseEventToWord, exportNoiseEventToExcel];
    case NOISEEVENT:
      return [exportNoiseEventToWord];
    case COMPLAINTS:
      return [exportComplaintToWord, exportComplaintToExcel];
    case COMPLAINT:
      return [exportComplaintToWordOnDetailsPage];
    default:
      return [];
  }
};
