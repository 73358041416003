import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import {
  fetchQuery,
  summarySchema,
  noiseMonitorAirportIdsQuery,
  noiseMonitorSchema,
  operationSchema,
  inAirQuery,
  operationIdsQuery,
} from 'src/@noiseEvents/resolvers/schema';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
import { dateTimeInQuery } from 'src/utils/dateTimeConverters';

const FETCH_SIZE = 1000;
export const fetchNoiseEventsData = (
  client: ApolloClient<object>,
  count = FETCH_SIZE,
  sortString: any,
  filterString: any,
  cursor?: string | null
) => {
  const { from, to } = dateRangeStore.getDateFilters();
  return new Promise((resolve, reject) => {
    const cursorParam = cursor ? `after:"${cursor}"` : ``;
    const queryParams = `
      startTime: "${dateTimeInQuery(from, 'start')}"
      endTime: "${dateTimeInQuery(to, 'end')}"
      first:${count}
      ${cursorParam}
    `;
    const GET_NOISE_EVENTS = gql`
      query {
        ${fetchQuery}(${queryParams} ${sortString} ${filterString}) ${summarySchema}
      }
    `;
    client
      .query({
        query: GET_NOISE_EVENTS,
      })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data[fetchQuery] &&
          response.data[fetchQuery].items
        ) {
          const { items: data, pageInfo, totalCount } = response.data[fetchQuery];
          resolve({
            data,
            pageInfo,
            totalCount,
            selectedDateRange: { from, to },
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchNoiseMonitorLocations = (client: ApolloClient<object>, airportIds: string[]) => {
  return new Promise((resolve, reject) => {
    const queryParams = `
      airportIds: ${JSON.stringify(airportIds)}
    `;
    const GET_MONITORS = gql`
      query {
        ${noiseMonitorAirportIdsQuery}(${queryParams}) ${noiseMonitorSchema}
      }
    `;
    client
      .query({ query: GET_MONITORS })
      .then(response => {
        if (response && response.data && response.data[noiseMonitorAirportIdsQuery]) {
          resolve({
            data: response.data[noiseMonitorAirportIdsQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchInAirTracksForPlayback = (
  client: ApolloClient<object>,
  startTime: string,
  endTime: string
) => {
  return new Promise((resolve, reject) => {
    const GET_OPERATIONS = gql`
      query {
        ${inAirQuery}(
          startTime: "${startTime}"
          endTime: "${endTime}"
          filter: {
            operationTypes: [Arrival, Departure, Overflight, TouchAndGo]
          }
          timeRangeMode: InFlight
        ) {
          items ${operationSchema}
        }
      }
    `;

    client
      .query({ query: GET_OPERATIONS })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data[inAirQuery] &&
          response.data[inAirQuery].items
        ) {
          resolve({
            data: response.data[inAirQuery].items,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchOperationsByIds = (client: ApolloClient<object>, operationIds: number[] = []) => {
  return new Promise((resolve, reject) => {
    const GET_OPERATIONS_BY_ID = gql`
      query {
        ${operationIdsQuery}(
          ids: ${JSON.stringify(operationIds)}
        ) ${operationSchema}
      }
    `;

    client
      .query({ query: GET_OPERATIONS_BY_ID })
      .then(response => {
        if (response && response.data && response.data[operationIdsQuery]) {
          resolve({
            data: response.data[operationIdsQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
