import React, { ComponentType } from 'react';
import { DateTime } from 'luxon';
// ts
import { IWithRouteProps, IDateRange } from 'src/app/props';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
// functions
import { dateToStringYMd } from 'src/utils/dateTimeConverters';
import {
  isValidDateRangeQueryString,
  UpdateQueryString,
  constructDateRangeQueryString,
} from 'src/app/functions/queryString';
import { useRouteHistoryTracker, getRoute } from 'src/app/functions/routing';
import { updateDateRangeFilter, rangeLimitInMonths } from 'src/app/functions/dateRange';
// screens
import { NotFound } from 'src/screens/notFound';

// import { Redirect } from 'react-router-dom'; // TODO

/**
 * A higher-order component (HOC) is an advanced technique in React for reusing component logic
 */
export const withRouteProps = (Screen: ComponentType<IWithRouteProps>) => ({
  history,
  location,
  match,
  ...props
}) => {
  const { search, pathname } = location;
  // Configuration
  // tslint:disable:react-hooks-nesting (see note below)
  const configSelectors = useConfigSelectors();
  const {
    dateRange: { rangeLimits },
  } = configSelectors.getConfig();
  // tslint:disable:react-hooks-nesting (see note below)
  useRouteHistoryTracker(history);
  // update URL to reflect new date range selection
  const updateUrl = ({ from, to }: IDateRange) => {
    const modifiedFrom = dateToStringYMd(from);
    let modifiedTo = dateToStringYMd(to);
    // If from and to are at least a month apart, make sure they are only maximum 1 month apart.
    const luxTo = DateTime.fromISO(modifiedTo);
    const luxFrom = DateTime.fromISO(modifiedFrom);
    const monthDiff = luxTo.diff(luxFrom, 'months').toObject().months;
    // range limits config
    const rangeLimit = rangeLimitInMonths(rangeLimits);
    // apply the range limit
    if (rangeLimit !== 0 && monthDiff >= rangeLimit) {
      modifiedTo = luxFrom.plus({ months: rangeLimit }).toISODate();
    }
    updateDateRangeFilter({ from: modifiedFrom, to: modifiedTo }, getRoute(pathname));
    UpdateQueryString(
      history,
      pathname,
      constructDateRangeQueryString({
        from: modifiedFrom,
        to: modifiedTo,
      })
    );
  };

  // will be changed, please read note below
  return isValidDateRangeQueryString(search) ? (
    <Screen history={history} location={location} match={match} updateUrl={updateUrl} {...props} />
  ) : (
      <NotFound />
    );
};

/**
 * TODO: What's the best way to display an error for an invalid query string?
 * 1. Display an error banner, 2. show a not-found page or 3. redirect
 * This will be addressed in another story
 * const { params: { deployedProductId } } = match;
 * return <Redirect to={`/${deployedProductId}/Invalid-date-range`} />;
 */

/**
 * Why ignore react-hooks-nesting here?
 * For some arrow functions/function expressions,
 * the rule has no way to determine whether those are a component,
 * a hook, both of which could contain hook calls, or a regular function that should not contain hook calls.
 */
