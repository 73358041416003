import React, { useEffect, FC, useContext, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useSortSelectors, useFilterSelectors } from 'src/@operations/reducers';
import { OperationDispatchContext } from 'src/@operations/providers/OperationsStateProvider';
// actions
import {
  initialiseFilterStore,
  resetAndFetchData,
  fetchData,
  updateTableIds,
} from 'src/@operations/actions';
// stores
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
// utils
import { screenScrollToTop, useWindowDimensions } from 'src/utils';
import { IDataContainer } from 'src/@operations/props';
import { convertDataToFilterForm } from 'src/@operations/functions';
import { usePermissions } from 'src/app/functions/permissions';
import { OPERATION_TAGS } from 'src/app/featureToggles';
import { AIRCRAFT_TYPE_ICON_CODES } from 'src/constants';

export const DataContainer: FC<IDataContainer> = ({ children }) => {
  const client = useApolloClient();
  const dispatcher = useContext(OperationDispatchContext);
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    grid: { resultSize },
    operations: {
      pca: { maxAltitudeRange, maxHorizontalRange },
      availableOperationTags,
    },
  } = configSelectors.getConfig();
  const initialFilters = configSelectors.getOperationFilterSet();
  const sortSelectors = useSortSelectors();
  const filterSelectors = useFilterSelectors();
  const filterString = filterSelectors.getFilterString();
  const isInitialised = filterSelectors.getIfInitialised();
  const usingPca = filterSelectors.getIfUsingPcaFilter();
  const pcaPosition = filterSelectors.getPcaPosition();
  const sortString = sortSelectors.getSortString();
  const { from, to } = dateRangeStore.getDatesStrings();
  const { canRead: infringementsRead } = usePermissions('Infringement');
  const { canRead: noiseEventsRead } = usePermissions('NoiseEvent');
  const { canRead: complaintsRead } = usePermissions('Complaint');

  const { width: windowWidth } = useWindowDimensions();
  const isPortraitLayout = configSelectors.getIfPortraitLayout();
  const [savedWidth, setSavedWidth] = useState<number>(windowWidth);
  const [savedLayout, setSavedLayout] = useState<boolean>(isPortraitLayout);

  const FEATURE_FLAG_OPERATION_TAGS = configSelectors.isFeatureAvailable(OPERATION_TAGS);

  useEffect(() => {
    if (windowWidth !== savedWidth || isPortraitLayout !== savedLayout) {
      updateTableIds(dispatcher);
      setSavedWidth(windowWidth);
      setSavedLayout(isPortraitLayout);
    }
  }, [windowWidth, dispatcher, isPortraitLayout]);

  useEffect(() => {
    if (isInitialised) {
      resetAndFetchData({
        client,
        resultSize,
        dispatcher,
        sortString,
        filterString,
        pcaData: usingPca
          ? {
              position: pcaPosition,
              altRange: maxAltitudeRange,
              horizRange: maxHorizontalRange,
            }
          : undefined,
        permissions: {
          infringements: infringementsRead,
          noiseEvents: noiseEventsRead,
          complaints: complaintsRead
        },
        featureFlags: {
          operationTags: FEATURE_FLAG_OPERATION_TAGS,
        },
        availableFilters: {
          operationTags: availableOperationTags,
        },
      });
    }
  }, [
    filterString,
    sortString,
    from,
    to,
    usingPca,
    pcaPosition,
    infringementsRead,
    noiseEventsRead,
    complaintsRead,
  ]);

  useEffect(() => {
    screenScrollToTop();
  }, []);

  useEffect(() => {
    if (isInitialised) {
      fetchData({
        client,
        resultSize,
        dispatcher,
        sortString,
        filterString,
        permissions: {
          infringements: infringementsRead,
          noiseEvents: noiseEventsRead,
          complaints: complaintsRead,
        },
        featureFlags: {
          operationTags: FEATURE_FLAG_OPERATION_TAGS,
        },
        availableFilters: {
          operationTags: availableOperationTags,
        },
      });
    } else {
      const convertedFilterData = {};
      Object.entries(initialFilters).map(([key, value]) => {
        const iconType: string = AIRCRAFT_TYPE_ICON_CODES[key] || '';
        convertedFilterData[key] = convertDataToFilterForm(value, iconType);
      });

      initialiseFilterStore(dispatcher, convertedFilterData);
    }
  }, [isInitialised]);

  return <>{children}</>;
};
