import React from 'react';
import { DateTime } from 'luxon';
// components
import {
  MapPopupMoveable,
  getConvertedDistance,
  getConvertedVerticalDistance,
  Icons,
} from '@ems/client-design-system';
import { useConfigSelectors } from 'src/app/reducers';
// functions
import { tooltipTimeFormat } from 'src/utils';
// style
import './styles.scss';

const BRAND01 = '#0b6bf2';
const UI01 = '#8897A2';

export const AMSLPopup = ({
  onClose,
  labels,
  pointData,
  anchor = 'top-left',
  trackPcas = [],
  mapApis,
  draggable,
}: any) => {
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  const showPointData = pointData && pointData.showPointData;

  if (!showPointData) {
    return null;
  }

  const timeDay = tooltipTimeFormat(pointData.time);
  const lat = pointData.latitude;
  const lon = pointData.longitude;
  const isPca = trackPcas.some(pca => {
    const trackTimeConverted = DateTime.fromJSDate(pca.time).toISO();
    const pointTimeConverted = DateTime.fromISO(pointData.time).toISO();
    return trackTimeConverted === pointTimeConverted;
  });

  return (
    <MapPopupMoveable
      latitude={lat}
      longitude={lon}
      anchorLongitude={lon}
      anchorLatitude={lat}
      anchorLine={true}
      className={'amsl-popup'}
      offsetLeft={anchor === 'top-left' ? 8 : -8}
      offsetTop={anchor === 'top-left' ? 8 : -8}
      onClose={onClose}
      closeButton={draggable}
      draggable={draggable}>
      <div className="amsl-popup_title">{pointData.flightId}</div>
      <br />
      <div className="amsl-popup_label">{labels.amsl}:</div>{' '}
      <span className="amsl-popup_value">
        {getConvertedVerticalDistance(pointData.amsl, units.distanceVertical, 0)}
      </span>
      <br />
      <div className="amsl-popup_label">{labels.distance}:</div>{' '}
      <span className="amsl-popup_value">
        {getConvertedDistance(pointData.dist, units.distance, -1)}
      </span>
      <br />
      <div className="amsl-popup_label">{labels.time}:</div>{' '}
      <span className="amsl-popup_value">{timeDay}</span>
      <br />
      {pointData.distanceHorizontal && (
        <>
          <div className="distance-table_separator" />
          <div className="amsl-popup_title">Distance to Inquirer</div>
          <table className="distance-table">
            <tbody>
              <tr>
                <td>
                  <Icons
                    iconName="ic-distance-hypotenuse"
                    fill={isPca ? BRAND01 : UI01}
                    size="24"
                    inline={true}
                  />
                </td>
                <td>
                  <Icons
                    iconName="ic-distance-horizontal"
                    fill={isPca ? BRAND01 : UI01}
                    size="24"
                    inline={true}
                  />
                </td>
                <td>
                  <Icons
                    iconName="ic-distance-vertical"
                    fill={isPca ? BRAND01 : UI01}
                    size="24"
                    inline={true}
                  />
                </td>
              </tr>
              <tr>
                <td>{getConvertedDistance(pointData.distanceSlant, units.distance, -1)}</td>
                <td>{getConvertedDistance(pointData.distanceHorizontal, units.distance, -1)}</td>
                <td>
                  {getConvertedVerticalDistance(
                    pointData.distanceVertical,
                    units.distanceVertical,
                    0
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </MapPopupMoveable>
  );
};
