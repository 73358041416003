import React, { useContext, useEffect } from 'react';
// reducers
import { useConfigSelectors, useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useFilterSelectors } from 'src/@airtrak/reducers/filterReducer';
import { useDataSelectors } from 'src/@airtrak/reducers';
// providers
import { AirtrakDispatchContext } from 'src/@airtrak/providers//AirtrakStateProvider';
// components
import { Filter, FilterRow } from '@ems/client-design-system';
import { IFilterItem } from 'src/@operations/props';
// actions
import {
  updateSelectedFilterItems,
  clearSelectedFilterItems,
  setDefaultPhaseFilters,
} from 'src/@airtrak/actions';
import { getIfFiltersEmpty, formatFilterItems, translateLabels } from 'src/@airtrak/functions';
import { convertObjectKeys } from 'src/utils/objectModifiers';
// constants
import { EMMISIONS, PHASES, REGIONS, TMA_EFFICIENCY } from 'src/constants';

export const FilterContainer = () => {
  const dispatcher = useContext(AirtrakDispatchContext);
  const configSelectors = useConfigSelectors();
  const {
    airtrak: {
      filter: { availableCategoryList },
    },
  } = configSelectors.getConfig();
  const filterSelectors = useFilterSelectors();
  const filteredItems = filterSelectors.getRegularFilters();
  const dataSelectors = useDataSelectors();
  const metric = dataSelectors.getMetric();

  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        filters: { clear: clearValue, filter: filterValue, clearFilters },
      },
      hints: { noMatchesFound },
      lists: { airtrakFilterCategories, phases, regions },
    },
  } = languageSelectors.getLanguage();

  const convertedDataList = convertObjectKeys({
    ...phases,
    ...regions,
  });

  const filtersSelectors = useFilterDataSelectors();
  const airtrakFilterData = filtersSelectors.getAirtrakFilterData();

  const convertedFilterData = {};
  Object.entries(airtrakFilterData).map(([key, value]) => {
    convertedFilterData[key] = formatFilterItems(value);
  });

  useEffect(() => {
    const defaultPhaseFilters = translateLabels(
      formatFilterItems(airtrakFilterData.ltoPhases),
      convertedDataList
    );
    setDefaultPhaseFilters(defaultPhaseFilters, dispatcher);
  });

  const filterComponents: JSX.Element[] = [];

  availableCategoryList.map((category: string) => {
    let originalItems = convertedFilterData[category];
    if (originalItems !== undefined) {
      if (category === PHASES || category === REGIONS) {
        originalItems = translateLabels(originalItems, convertedDataList);
      }
      if (metric === TMA_EFFICIENCY && (category === PHASES || category === EMMISIONS)) {
        return;
      }
      filterComponents.push(
        <Filter
          key={category}
          categoryName={airtrakFilterCategories[category]}
          filterItems={originalItems}
          selectedItems={filteredItems[category]}
          updateItems={(items: IFilterItem[]) =>
            updateSelectedFilterItems(
              category,
              items,
              convertedDataList,
              airtrakFilterData,
              dispatcher
            )
          }
          languageData={{ clearValue, filterValue, noMatchesFound }}
          iconCategories={[]}
        />
      );
    }
  });

  return (
    <div>
      <FilterRow
        filters={filterComponents}
        clearFilters={() => clearSelectedFilterItems(dispatcher)}
        clearDisabled={getIfFiltersEmpty(filteredItems)}
        languageData={{ clearFilters }}
      />
    </div>
  );
};
