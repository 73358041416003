import React, { FC, useState, useEffect, useContext, useMemo } from 'react';
import cx from 'classnames';
import { useApolloClient } from '@apollo/react-hooks';
import { NoiseEventsDispatchContext } from 'src/@noiseEvents/providers/NoiseEventsStateProvider';
import { Icons } from '@ems/client-design-system';
import { MapContainer } from 'src/@noiseEvents/containers/MapContainer';
import { useDataSelectors } from 'src/@noiseEvents/reducers';
import { AudioPlayback } from 'src/components/AudioPlayback';
import { fetchInAirData } from 'src/@noiseEvents/actions';
import { ContinuousNoiseGraph } from 'src/components/ContinuousNoiseGraph';
import { PlaybackControl } from 'src/components/PlaybackControl';
// selectors
import { useConfigSelectors, useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
// resolvers
import { fetchContinuousNoiseData } from 'src/@infringements/resolvers/summaryResolver';
// utils
import { addPaddingToDuration } from 'src/utils';
import { processNoiseData } from 'src/app/functions/noise';
import { fetchOperationsByIds } from '../resolvers/getDataResolver';
import { fetchProfilesById } from 'src/@noiseEvents/resolvers/summaryResolver';
import { IMarkedTime } from '../interfaces';
import { NoiseError, NMTSelector } from 'src/components/NoiseGraphs';
import { ONE_DAY_IN_SECONDS } from 'src/constants';

interface IMapPlaybackContainer {
  playbackModeUpdated?: (isPlaybackMode: boolean) => void;
}
export const MapPlaybackContainer: FC<IMapPlaybackContainer> = ({ playbackModeUpdated }) => {
  const client = useApolloClient();
  const dispatcher = useContext(NoiseEventsDispatchContext);
  const [isPlaybackMode, setIsPlaybackMode] = useState<boolean>(false);
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      errors: { noiseEventDurationExceededError, noiseEventNoDataError },
    },
  } = languageSelectors.getLanguage();

  // Playback variables
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [runningStatus, setRunningStatus] = useState<boolean>(true);
  const [draggingStatus, setDraggingStatus] = useState<boolean>(false);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(0);
  const [currentNoiseData, setCurrentNoiseData] = useState<any[]>([]);
  const [continuousNoiseData, setContinuousNoiseData] = useState<any>([]);
  const [processedNoiseData, setProcessedNoiseData] = useState<any>([]);
  const [pointData, setPointData] = useState<any>([]);
  const [playbackDuration, setPlaybackDuration] = useState<number>(0);
  const [playbackStartEnd, setPlaybackStartEnd] = useState<{
    startTime: string | null;
    endTime: string | null;
  }>({
    startTime: null,
    endTime: null,
  });
  const [playbackError, setPlaybackError] = useState<string | null>(null);
  const [selectedNMT, setSelectedNMT] = useState<any>({ key: null, label: null });
  const [downloadedIds, setDownloadedIds] = useState<number[]>([]);
  const dataSelectors = useDataSelectors();
  const { requiredData, inAirTracks, noiseMonitors } = dataSelectors.getRequiredDataForMap();
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { noiseSampleType },
  } = configSelectors.getConfig();

  // filters data
  const filtersSelectors = useFilterDataSelectors();
  const operationFilterData = filtersSelectors.getOperationsFilterData();

  useEffect(() => {
    const isExportEnabled =
      (!isPlaybackMode && runningStatus) ||
      (isPlaybackMode && !runningStatus) ||
      (!isPlaybackMode && !runningStatus);
    if (isExportEnabled) {
      if (playbackModeUpdated) {
        playbackModeUpdated(false);
      }
    } else {
      if (playbackModeUpdated) {
        playbackModeUpdated(true);
      }
    }
  }, [isPlaybackMode, runningStatus]);

  useEffect(() => {
    setPlaybackError(null);
    if (requiredData.length !== currentNoiseData.length) {
      setIsPlaybackMode(false);
    }

    if (requiredData.length) {
      const operationIds: number[] = [];
      requiredData.map(currentData => {
        if (!currentData.operationId) {
          return;
        }

        // if it doesn't already exist in the point data
        if (pointData.findIndex(item => item.id === currentData.operationId) === -1) {
          // if it doesn't exist in the accumulator
          if (operationIds.findIndex(item => item === currentData.operationId) === -1) {
            operationIds.push(currentData.operationId);
          }
        }
      }, []);

      // only make the request if there are ids to request
      if (operationIds.length) {
        fetchOperationsByIds(client, operationIds)
          .then((data: any) => {
            const initPointData = data.data;
            fetchProfilesById(client, operationIds).then((profileData: any) => {
              const mergedData = initPointData.map(e => {
                const foundData = profileData.data.find(d => d.id === e.id);
                return foundData
                  ? {
                      ...e,
                      profile: foundData.profile,
                    }
                  : e;
              });

              setPointData([...pointData, ...mergedData]);
            });
          })
          .catch((error: string) => {
            console.error(error);
            setPlaybackError(noiseEventNoDataError);
          });
      }
    }

    if (requiredData.length) {
      const locationIds: number[] = [];
      let startTime: string | null = null;
      let endTime: string | null = null;
      requiredData.map((item: any, index: number) => {
        const { locationId } = item;
        if (locationIds.findIndex(e => e === locationId) === -1) {
          locationIds.push(locationId);
        }

        if (startTime === null || item.startTime < startTime) {
          startTime = item.startTime;
        }

        if (endTime === null || item.endTime > endTime) {
          endTime = item.endTime;
        }
      });

      const duration = addPaddingToDuration({
        seconds: 60,
        startTime,
        endTime,
      });

      setPlaybackDuration(duration.duration);

      if (duration.duration > ONE_DAY_IN_SECONDS) {
        setPlaybackError(noiseEventDurationExceededError);
      }

      if (
        playbackStartEnd.startTime !== duration.startTime ||
        playbackStartEnd.endTime !== duration.endTime
      ) {
        setDownloadedIds([]);
        setContinuousNoiseData([]);
        setPlaybackStartEnd({ startTime: duration.startTime, endTime: duration.endTime });
      }
    }
    setCurrentNoiseData(requiredData);
  }, [requiredData]);

  useEffect(() => {
    if (
      (requiredData.length || continuousNoiseData.length) &&
      playbackStartEnd.startTime &&
      playbackStartEnd.endTime
    ) {
      setProcessedNoiseData(
        processNoiseData(requiredData, continuousNoiseData, playbackDuration, playbackStartEnd)
      );
    } else {
      setProcessedNoiseData([]);
    }
  }, [continuousNoiseData, requiredData, playbackStartEnd]);

  // Set the selected nmt to the first in the list, or keep it the same if it still exists
  useEffect(() => {
    if (requiredData.length) {
      const fidx = requiredData.findIndex(e => e.locationId === selectedNMT.key);
      if (selectedNMT.key && fidx !== -1) {
        return;
      }

      const locationId = requiredData[0].locationId;
      const found = noiseMonitors.find(e => e.id === locationId);
      if (found) {
        setSelectedNMT({
          key: locationId,
          label: found.name,
        });
      }
    }
  }, [requiredData]);

  useEffect(() => {
    if (
      playbackError === null &&
      selectedNMT.key &&
      playbackStartEnd.startTime &&
      playbackStartEnd.endTime &&
      downloadedIds.findIndex(e => e === selectedNMT.key) === -1
    ) {
      setDownloadedIds([...downloadedIds, selectedNMT.key]);
      fetchContinuousNoiseData(client, [selectedNMT.key], noiseSampleType, {
        startTime: playbackStartEnd.startTime,
        endTime: playbackStartEnd.endTime,
      })
        .then((data: any) => {
          setContinuousNoiseData([...continuousNoiseData, ...data.data]);
        })
        .catch(error => {
          setContinuousNoiseData([]);
        });
    }
  }, [selectedNMT, playbackStartEnd, downloadedIds]);

  const [playbackMarkers, setPlaybackMarkers] = useState<string[]>([]);

  useEffect(() => {
    const markers: string[] = [];

    requiredData.map((item: any) => {
      if (item.locationId === selectedNMT.key) {
        markers.push(item.time);
      }
    });

    setPlaybackMarkers(markers);
  }, [requiredData, selectedNMT]);

  useEffect(() => {
    if (
      currentNoiseData &&
      playbackStartEnd.startTime &&
      playbackStartEnd.endTime &&
      playbackDuration < ONE_DAY_IN_SECONDS
    ) {
      fetchInAirData(
        client,
        dispatcher,
        playbackStartEnd.startTime,
        playbackStartEnd.endTime,
        operationFilterData.airportIds
      );
    }
  }, [currentNoiseData]);

  const hasPlaybackData = requiredData.length > 0 && playbackDuration <= ONE_DAY_IN_SECONDS;
  const markedTimes: IMarkedTime[] = [];
  requiredData.map(data => {
    if (data.operationId) {
      markedTimes.push({
        time: data.time,
        id: data.operationId,
      });
    }
  });

  const audioArray = useMemo(
    () =>
      currentNoiseData.length && selectedNMT.key
        ? currentNoiseData
            .filter(data => data.locationId === selectedNMT.key)
            .map(data => data.audio)
        : null,
    [currentNoiseData, selectedNMT]
  );
  const hasAudio = useMemo(() => {
    if (!audioArray) {
      return false;
    }

    return audioArray.findIndex(e => e && e.resourceUri && e.resourceUri.uri) !== -1;
  }, [audioArray]);

  return (
    <div className="map_wrapper">
      <MapContainer
        currentTime={currentTime}
        isPlaybackMode={isPlaybackMode}
        inAirData={inAirTracks}
        noiseMonitors={noiseMonitors}
        noiseData={currentNoiseData}
        processedNoiseData={processedNoiseData}
        markedTime={markedTimes}
        pointData={pointData}
        isPlaybackRunning={runningStatus}
      />
      <div className="map-overlay-panel">
        <ContinuousNoiseGraph
          noiseData={processedNoiseData}
          currentTime={isPlaybackMode ? currentTime : 0}
          shouldDisplay={hasPlaybackData}
          selectedNMT={selectedNMT}
        />
        {playbackError !== null ? <NoiseError error={playbackError} /> : null}
        {playbackError === null ? (
          <NMTSelector
            selectedNMT={selectedNMT}
            nmtOptions={processedNoiseData}
            nmtList={noiseMonitors}
            setSelectedNMT={setSelectedNMT}
            isPlaybackRunning={isPlaybackMode && runningStatus}
          />
        ) : null}
        {isPlaybackMode && hasPlaybackData && playbackError === null && (
          <>
            <AudioPlayback
              audio={audioArray}
              isPlaying={isPlaybackMode && runningStatus && !draggingStatus}
              currentTime={currentTime}
              playbackSpeed={playbackSpeed}
            />
            <PlaybackControl
              startTime={playbackStartEnd.startTime}
              endTime={playbackStartEnd.endTime}
              markerTimes={playbackMarkers}
              onPositionUpdate={setCurrentTime}
              onPlaybackSpeedUpdate={setPlaybackSpeed}
              onRunningStatusUpdate={setRunningStatus}
              onDraggingStatusUpdate={setDraggingStatus}
              onPlaybackStop={() => {
                setIsPlaybackMode(false);
              }}
              playbackTimeBuffer={0}
              alwaysShowDot={true}
              showAudio={true}
              operationStartTime={playbackStartEnd.startTime}
              hasAudio={hasAudio}
            />
          </>
        )}
      </div>
      <button
        className={cx('mode-playback', {
          'mode-playback--hidden':
            isPlaybackMode || requiredData.length === 0 || playbackError !== null,
        })}
        onClick={() => setIsPlaybackMode(!isPlaybackMode)}>
        <Icons iconName={`ic-ui-play`} size="24" fill="#2e384d" />
      </button>
    </div>
  );
};
