import { useState, useEffect, useReducer } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// stores
import { filterStore as infringementsFilterStore } from 'src/@infringements/stores/filterStore';
import { filterStore as candidatesFilterStore } from 'src/@infringementsCandidates/stores/filterStore';
// resolver
import { fetchOperationsByTime } from 'src/app/resolvers/mapResolver';
// reducers
import { useConfigSelectors } from 'src/app/reducers';
// functions
import {
  getSelectedCoordinates,
  fitPointsInMap,
  getDateString,
  setSelectionFeatureState,
  setFilteredFeatureState,
  setFeatureStateForMap,
  whenMapHasLoadedSource,
  getTotalCountForLayer,
} from 'src/utils/mapHelpers';
import {
  mapboxStyleBackgroundNormalPaint,
  mapboxStyleForegroundPaint,
  mapboxStyleInfringementPaint,
  getInterpolatedOpacity,
  mapboxStyleHoverPaint,
  mapboxStyleInfringementSelectPaint,
} from 'src/utils/mapStyles';
import {
  manageRuleTypeSelection,
  manageRuleTypeRemoval,
  clearRuleTypeSelection,
  addExtraGates,
  removeExtraGates,
} from 'src/utils/ruleTypeReducerHelpers';
import { ResponseValidator } from 'src/utils';
// constants
import {
  CHANGE_EVENT,
  CORRIDOR_INFRINGEMENT,
  EXCLUSION_INFRINGEMENT,
  GATE_INFRINGEMENT,
} from 'src/constants';

const request = new ResponseValidator();

// action type
const FETCH_TRACKS = 'fetch-tracks';
const ADD_TRACKS = 'add-track';
const DELETE_TRACKS = 'delete-tracks';
const SELECT_TRACK = 'select-track';
const DESELECT_TRACK = 'deselect-track';
const DESELECT_ALL = 'deselect-all';
const SELECT_CORRIDOR = 'select-corridor';
const SELECT_INFRINGEMENT = 'select-infringement';
const SELECT_EXCLUSION = 'select-exclusion';
const SELECT_GATE = 'select-gate';
const UPDATE_EXTRA_GATES = 'update-extra-gates';
const EXTRA_GATES_REMOVED = 'extra-gates-removed';
const CLEAR_ALL = 'clear-all';
const INFRINGEMENT_REMOVED = 'infringement-removed';

/**
 * Map on infringements browser page
 * @param mapApis
 * @param mapBoxConfig
 * @param viewport
 * @param setViewport
 * @param dateRangeMapping
 */

export const useDataForMap = (
  instance,
  mapApis,
  mapBoxConfig,
  viewport,
  setViewport,
  dateRangeMapping,
  requiredData,
  addedToSelection,
  removedFromSelection,
  extraIds
) => {
  const client = useApolloClient();
  let filterStore: any;
  if (instance === 'inf-browser') {
    filterStore = infringementsFilterStore;
  } else if (instance === 'inf-candidate') {
    filterStore = candidatesFilterStore;
  }

  // reducer for selected tracks
  const tracksReducer = (state, action) => {
    const selectedTracks = [...state.selectedTracks];
    const newState = Object.assign({}, state);
    switch (action.type) {
      case FETCH_TRACKS:
        const { infringement, ...rest } = action.data;
        const { time, airportId } = infringement;
        const [instanceKey, t] = request.get(`${instance}-req1`);
        request.set(instanceKey, t);
        fetchOperationsByTime(client, time, airportId)
          .then((response: any) => {
            if (request.isValid(instanceKey, t)) {
              // ignore responses that are expired
              dispatchTracks({
                type: ADD_TRACKS,
                data: { infringement, operations: response.data, ...rest },
              });
            }
          })
          .catch(e => {
            console.warn(e);
          });
        return Object.assign({}, state, { fetchingTracks: true });
      case ADD_TRACKS:
        const { operations, dateString } = action.data;
        if (state.fetchingTracks) {
          operations.forEach(operation => {
            setFilteredFeatureState({
              mapApis,
              mapBoxConfig,
              operation,
              dateString,
              removeFeature: false,
            });
          });
        }
        return Object.assign({}, state, action.data, { fetchingTracks: false });
      case DELETE_TRACKS:
        // get in air tracks and the datestring associated
        const { operations: prevInAirTracks, dateString: prevDateString } = state;

        // loop through tracks and remove from map
        if (prevInAirTracks && prevInAirTracks.length && prevDateString) {
          prevInAirTracks.forEach(operation => {
            // remove each in-air track
            setFilteredFeatureState({
              mapApis,
              mapBoxConfig,
              operation,
              dateString: prevDateString,
              removeFeature: true,
            });
          });
        }

        delete newState.dateString;
        delete newState.operations;
        delete newState.infringement;

        if (state.fetchingTracks) {
          newState.fetchingTracks = false;
        }

        return newState;

      case DESELECT_TRACK:
        const deslelectOp = action.data;
        const deselectDateString = getDateString(deslelectOp.time, dateRangeMapping);
        setSelectionFeatureState({
          mapApis,
          mapBoxConfig,
          operation: deslelectOp,
          dateString: deselectDateString,
          removeFeature: true,
        });
        if (selectedTracks.includes(action.data.id)) {
          const index = selectedTracks.indexOf(action.data.id);
          selectedTracks.splice(index, 1);
        }
        return Object.assign({}, state, { selectedTracks: [] });
      case SELECT_TRACK:
        const selectOp = action.data;
        const selectDateString = getDateString(selectOp.time, dateRangeMapping);
        setSelectionFeatureState({
          mapApis,
          mapBoxConfig,
          operation: selectOp,
          dateString: selectDateString,
          removeFeature: false,
        });
        if (!selectedTracks.includes(action.data)) {
          selectedTracks.push(action.data);
        }
        return Object.assign({}, state, { selectedTracks });

      case DESELECT_ALL:
        selectedTracks.forEach(deselectOp => {
          const deselectDateString = getDateString(deselectOp.time, dateRangeMapping);
          setSelectionFeatureState({
            mapApis,
            mapBoxConfig,
            operation: deselectOp,
            dateString: deselectDateString,
            removeFeature: true,
          });
        });

        return Object.assign({}, state, { selectedTracks: [] });
      default:
        return { selectedTracks: [] };
    }
  };

  // reducer to provide latest exclusions state for the map
  const exclusionReducer = (state, action) => {
    const newState = Object.assign({}, state);
    const selectionIds = Object.keys(state);
    const { selectionZoneIdentifier } = mapBoxConfig;

    switch (action.type) {
      case SELECT_EXCLUSION:
        const { id: operationId, selectionZoneId: selectedId } = action.data;
        return manageRuleTypeSelection(
          EXCLUSION_INFRINGEMENT,
          operationId,
          selectedId, // added
          selectionIds,
          selectionZoneIdentifier, // sourceIdentifier
          mapApis,
          newState
        );
      case INFRINGEMENT_REMOVED:
        const {
          data: { id: removedOperationId },
        } = action;
        return manageRuleTypeRemoval(
          EXCLUSION_INFRINGEMENT,
          removedOperationId,
          selectionIds, // to remove
          selectionZoneIdentifier, // sourceIdentifier
          mapApis,
          newState
        );
      case CLEAR_ALL:
        return clearRuleTypeSelection(
          EXCLUSION_INFRINGEMENT,
          selectionIds,
          selectionZoneIdentifier, // sourceIdentifier
          mapApis,
          newState
        );
      default:
        return {};
    }
  };

  // reducer to provide latest corridors state for the map
  const corridorReducer = (state, action) => {
    const newState = Object.assign({}, state);
    const selectionIds = Object.keys(state);
    const { corridorIdentifier } = mapBoxConfig;

    switch (action.type) {
      case SELECT_CORRIDOR:
        const { id: selectedOperationId, corridorId: selectedId } = action.data;
        return manageRuleTypeSelection(
          CORRIDOR_INFRINGEMENT,
          selectedOperationId,
          selectedId, // added
          selectionIds,
          corridorIdentifier,
          mapApis,
          newState
        );
      case INFRINGEMENT_REMOVED:
        const {
          data: { id: removedOperationId },
        } = action;
        return manageRuleTypeRemoval(
          CORRIDOR_INFRINGEMENT,
          removedOperationId,
          selectionIds, // to remove
          corridorIdentifier, // sourceIdentifier
          mapApis,
          newState
        );
      case CLEAR_ALL:
        return clearRuleTypeSelection(
          CORRIDOR_INFRINGEMENT,
          selectionIds,
          corridorIdentifier, // sourceIdentifier
          mapApis,
          newState
        );
      default:
        return {};
    }
  };

  const extraGateReducer = (state, action) => {
    const { gateIdentifier } = mapBoxConfig;

    switch (action.type) {
      case UPDATE_EXTRA_GATES:
        const { selectedGateIds, extraGateIds } = action.data;
        const gatesToAdd = extraGateIds.filter(id => selectedGateIds.indexOf(id) === -1);

        addExtraGates(
          gatesToAdd, // add
          gateIdentifier,
          mapApis,
          state
        );

        return Object.assign({}, { gateIds: gatesToAdd });

      case EXTRA_GATES_REMOVED:
        const { gateIds: gatesToRemove } = state;

        removeExtraGates(
          gatesToRemove, // remove
          gateIdentifier,
          mapApis
        );

        return Object.assign({}, { gateIds: [] });

      default:
        return {};
    }
  };

  // reducer to provide latest gates state for the map
  const gateReducer = (state, action) => {
    const newState = Object.assign({}, state);
    const selectionIds = Object.keys(state);
    const { gateIdentifier } = mapBoxConfig;

    switch (action.type) {
      case SELECT_GATE:
        const { id: operationId, gateId: selectedId } = action.data;
        return manageRuleTypeSelection(
          GATE_INFRINGEMENT,
          operationId,
          selectedId, // added
          selectionIds,
          gateIdentifier,
          mapApis,
          newState
        );
      case INFRINGEMENT_REMOVED:
        const {
          data: { id: removedOperationId },
        } = action;
        return manageRuleTypeRemoval(
          GATE_INFRINGEMENT,
          removedOperationId,
          selectionIds, // to remove
          gateIdentifier, // sourceIdentifier
          mapApis,
          newState
        );
      case CLEAR_ALL:
        return clearRuleTypeSelection(
          GATE_INFRINGEMENT,
          selectionIds,
          gateIdentifier, // sourceIdentifier
          mapApis,
          newState
        );
      default:
        return {};
    }
  };

  const [infringementTracks, dispatchTracks] = useReducer(tracksReducer, {
    selectedTracks: [],
    fetchingTracks: false,
  });
  const [corridors, dispatchCorridors] = useReducer(corridorReducer, {});
  const [exclusions, dispatchExclusions] = useReducer(exclusionReducer, {});
  const [gates, dispatchGates] = useReducer(gateReducer, {});
  const [extraGates, dispatchExtraGates] = useReducer(extraGateReducer, { gateIds: [] });
  const [selectedInfringements, setSelectedInfringements]: any = useState([]);

  useEffect(() => {
    // remove all selection when filter changes
    if (requiredData.length) {
      requiredData.forEach(infringement => {
        const dateString = getDateString(infringement.time, dateRangeMapping);
        setSelectionFeatureState({
          mapApis,
          mapBoxConfig,
          operation: { id: infringement.operationId },
          dateString,
          removeFeature: true,
        });
      });
    }

    // get selected infringements data
    // add new reducer here
    setSelectedInfringements(requiredData);
    // extract coordinates for the above infringements (+ added a temporary solution to get missing position for the infrigement candidate)
    const coordinatesData = getSelectedCoordinates(requiredData);

    // make map zoom to fit the points selected
    fitPointsInMap(mapApis, viewport, setViewport, coordinatesData);

    // process selection removal
    if (removedFromSelection.length) {
      removedFromSelection.forEach(infringement => {
        const { operation, infringementType } = infringement;
        // change corridor state when corridor infringement is removed
        if (infringementType === CORRIDOR_INFRINGEMENT) {
          dispatchCorridors({ type: INFRINGEMENT_REMOVED, data: operation });
        } else if (infringementType === EXCLUSION_INFRINGEMENT) {
          dispatchExclusions({ type: INFRINGEMENT_REMOVED, data: operation });
        } else if (infringementType === GATE_INFRINGEMENT) {
          dispatchExtraGates({ type: EXTRA_GATES_REMOVED, data: operation });
          dispatchGates({ type: INFRINGEMENT_REMOVED, data: operation });
        }
        dispatchTracks({ type: DESELECT_TRACK, data: operation });
      });
    }

    if (requiredData.length === 1) {
      const infringement = requiredData[0];
      const dateString = getDateString(infringement.time, dateRangeMapping);
      dispatchTracks({ type: FETCH_TRACKS, data: { dateString, infringement } });
    } else {
      dispatchTracks({ type: DELETE_TRACKS });
    }

    // process new selection
    if (addedToSelection.length) {
      const selectedGateIds: number[] = [];
      addedToSelection.forEach(infringement => {
        const { operation, infringementType } = infringement;
        if (infringementType === CORRIDOR_INFRINGEMENT) {
          dispatchCorridors({ type: SELECT_CORRIDOR, data: operation });
        } else if (infringementType === EXCLUSION_INFRINGEMENT) {
          dispatchExclusions({ type: SELECT_EXCLUSION, data: operation });
        } else if (infringementType === GATE_INFRINGEMENT) {
          selectedGateIds.push(operation.gateId);
          dispatchGates({ type: SELECT_GATE, data: operation });
        }
        dispatchTracks({ type: SELECT_TRACK, data: operation });
      });
      if (selectedGateIds.length) {
        dispatchExtraGates({
          type: UPDATE_EXTRA_GATES,
          data: { selectedGateIds, extraGateIds: extraIds },
        });
      }
    }

    if (requiredData.length === 0) {
      dispatchCorridors({ type: CLEAR_ALL });
      dispatchExclusions({ type: CLEAR_ALL });
      dispatchGates({ type: CLEAR_ALL });
    }
  }, [mapApis, dateRangeMapping, requiredData.length, addedToSelection, removedFromSelection]);

  const handleFitlerUpdate = () => {
    dispatchTracks({ type: DESELECT_ALL });
    dispatchTracks({ type: DELETE_TRACKS });
  };

  useEffect(() => {
    handleFitlerUpdate();
    // when map is ready
    filterStore.on(CHANGE_EVENT, handleFitlerUpdate);
    return () => {
      filterStore.removeListener(CHANGE_EVENT, handleFitlerUpdate);
      mapApis = null; // invalidate mapApis on map unmount
    };
  }, [mapApis, dateRangeMapping]);
  return { infringementTracks, corridors, exclusions, gates, extraGates, selectedInfringements };
};

/**
 * Create style layers for infringement
 * @param mapBoxConfig
 */

export const useMapLayer = ({ mapApis, mapBoxConfig, maptype = 'operations' }) => {
  const [layers, updateLayers] = useState<object[]>();

  const [count, setCount] = useState<number>(0);
  const mapLayerFilter = [
    'all',
    ['!=', ['get', 'operationType'], 'Overflight'],
    ['boolean', ['feature-state', 'filtered'], false],
  ];
  const countFilter = ['!=', ['get', 'operationType'], 'Overflight'];
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');

  useEffect(() => {
    if (mapApis) {
      const totalCount = getTotalCountForLayer(mapApis, mapBoxConfig, countFilter);
      setCount(totalCount);
    }
  }, [mapApis]);

  useEffect(() => {
    // set line opacity to work with filtered feature state
    updateLayers([
      {
        prefix: mapBoxConfig.backgroundLayerPrefix,
        style: Object.assign({}, mapboxStyleBackgroundNormalPaint(selectedTrackTheme), {
          'line-opacity': getInterpolatedOpacity({ filter: mapLayerFilter, totalCount: count }),
        }),
      },
      {
        prefix: mapBoxConfig.foregroundLayerPrefix,
        style: maptype === 'operations' ? mapboxStyleForegroundPaint : mapboxStyleInfringementPaint,
      },
      { prefix: 'hovered_', style: mapboxStyleHoverPaint },
      {
        prefix: 'infringement_select_',
        style: mapboxStyleInfringementSelectPaint,
      },
    ]);
  }, []);
  return layers;
};

/**
 * Map on infringement detail page
 * @param mapApis
 * @param mapBoxConfig
 * @param dateString
 * @param operationId
 * @param infringementId
 * @param infringementType
 */

export const useInfringementMap = (
  instance,
  mapApis,
  mapBoxConfig,
  dateString,
  operationId,
  operation,
  infTypeId,
  infringementId,
  infringementType,
  showTracks,
  time,
  extraIds: number[] = []
) => {
  const client = useApolloClient();
  const {
    corridorIdentifier,
    selectionZoneIdentifier,
    gateIdentifier,
    sourcePrefix,
  } = mapBoxConfig;

  const infringementTrackReducer = (state, action) => {
    const sourceIdentifierMap = {};
    sourceIdentifierMap[`${CORRIDOR_INFRINGEMENT}`] = corridorIdentifier;
    sourceIdentifierMap[`${EXCLUSION_INFRINGEMENT}`] = selectionZoneIdentifier;
    sourceIdentifierMap[`${GATE_INFRINGEMENT}`] = gateIdentifier;
    switch (action.type) {
      case ADD_TRACKS:
        if (mapApis && showTracks) {
          whenMapHasLoadedSource(mapApis, `${sourcePrefix}${dateString}`).then(() => {
            action.data.tracks.forEach(operation => {
              setFilteredFeatureState({
                mapApis,
                mapBoxConfig,
                operation,
                dateString,
                removeFeature: false,
              });
            });
          });
        }
        const { tracks } = action.data;
        return Object.assign({}, state, { tracks, dateString });

      case SELECT_TRACK:
        if (mapApis && showTracks) {
          whenMapHasLoadedSource(mapApis, `${sourcePrefix}${dateString}`).then(() => {
            setSelectionFeatureState({
              mapApis,
              mapBoxConfig,
              operation: { id: action.data.operationId },
              dateString,
              removeFeature: false,
            });
          });
        }
        return Object.assign({}, state, { operation: action.data, dateString });

      case DELETE_TRACKS:
        if (mapApis && showTracks) {
          if (state.tracks && state.tracks.length) {
            whenMapHasLoadedSource(mapApis, `${sourcePrefix}${state.dateString}`).then(() => {
              state.tracks.forEach(operation => {
                setFilteredFeatureState({
                  mapApis,
                  mapBoxConfig,
                  operation,
                  dateString: state.dateString,
                  removeFeature: true,
                });
              });
            });
          }
          if (state.operation.operationId) {
            whenMapHasLoadedSource(mapApis, `${sourcePrefix}${state.dateString}`).then(() => {
              setSelectionFeatureState({
                mapApis,
                mapBoxConfig,
                operation: { id: state.operation.operationId },
                dateString: state.dateString,
                removeFeature: true,
              });
            });
          }
          if (state.infTypeSelected.infTypeId && state.infTypeSelected.sourceIdentifier) {
            setFeatureStateForMap(
              state.infTypeSelected.infTypeId,
              mapApis,
              state.infTypeSelected.sourceIdentifier,
              true // removeFeature
            );
          }
        }

        const operationChanged = Object.assign({}, state.opration, { operationId: null });

        return Object.assign({}, state, {
          tracks: {},
          operation: operationChanged,
          infTypeSelected: { sourceIdentifier: null, infTypeId: null },
        });

      case SELECT_INFRINGEMENT:
        const infTypeId = action.data.infTypeId;
        const sourceIdentifier = sourceIdentifierMap[action.data.infringementType];
        whenMapHasLoadedSource(mapApis, sourceIdentifier).then(() => {
          if (state.infTypeSelected.infTypeId && state.infTypeSelected.infTypeId !== infTypeId) {
            // avoid adding two corridors - remove previous corridor
            setFeatureStateForMap(
              state.infTypeSelected.infTypeId,
              mapApis,
              state.infTypeSelected.sourceIdentifier,
              true // removeFeature
            );
          }

          setFeatureStateForMap(
            infTypeId, // id
            mapApis,
            sourceIdentifier,
            false // removeFeature
          );
        });
        return Object.assign({}, state, { infTypeSelected: { sourceIdentifier, infTypeId } });
      default:
        return {
          tracks: {},
          operation: { operationId: null, dateString: null },
          infTypeSelected: { sourceIdentifier: null, infTypeId: null },
        };
    }
  };

  const extraGateReducer = (state, action) => {
    const { gateIdentifier } = mapBoxConfig;

    switch (action.type) {
      case UPDATE_EXTRA_GATES:
        const { gateIds } = action.data;

        whenMapHasLoadedSource(mapApis, gateIdentifier).then(() => {
          addExtraGates(
            gateIds, // added
            gateIdentifier,
            mapApis,
            state
          );
        });

        return Object.assign({}, { gateIds });
      default:
        return {};
    }
  };

  const [, dispatchExtraGates] = useReducer(extraGateReducer, { gateIds: [] });
  const [tracks, dispatchInfringementTracks] = useReducer(infringementTrackReducer, {
    tracks: {},
    operation: { operationId: null, dateString: null },
    infTypeSelected: { sourceIdentifier: null, infTypeId: null },
  });

  useEffect(() => {
    let t: number = 0;
    if (mapApis) {
      dispatchInfringementTracks({ type: DELETE_TRACKS, data: { infringementType } });
      if (operation) {
        const [instanceKey, getTime] = request.get(`${instance}-req2`);
        t = getTime;
        request.set(instanceKey, t);
        fetchOperationsByTime(client, time, operation.airportId)
          .then((response: any) => {
            // cancel responses that are expired
            if (request.isValid(instanceKey, t)) {
              dispatchInfringementTracks({ type: SELECT_TRACK, data: { operationId } });
              dispatchInfringementTracks({ type: ADD_TRACKS, data: { tracks: response.data } });
              if (
                infTypeId &&
                (infringementType === CORRIDOR_INFRINGEMENT ||
                  infringementType === EXCLUSION_INFRINGEMENT ||
                  GATE_INFRINGEMENT)
              ) {
                dispatchInfringementTracks({
                  type: SELECT_INFRINGEMENT,
                  data: { infringementType, infTypeId },
                });
              }

              if (infringementType === GATE_INFRINGEMENT) {
                dispatchExtraGates({
                  type: UPDATE_EXTRA_GATES,
                  data: { gateIds: extraIds },
                });
              }
            }
          })
          .catch(e => {
            console.warn(e);
          });
      }
    }
    return () => {
      t = 0;
      mapApis = null; // invalidate mapApis on map unmount
    };
  }, [mapApis, infringementId, dateString, operationId, operation]);

  return tracks;
};

/**
 * provides mapbox filter for the infringements available
 * @param features
 */

export const useMapTracksFilter = (features, selectedInfringements = []) => {
  // filter to be provided to map
  const [infringementFilter, setInfringementFilter]: any[] = useState(['all', false]);
  const [clickFilter, setClickFilter]: any[] = useState(false);

  // get infringement tracks and update fitler
  useEffect(() => {
    if (features && features.length) {
      const featuresFilter = features.map(feature => ['==', ['id'], feature.id]);
      const filter = ['any', ...featuresFilter];
      setInfringementFilter(filter);
      setClickFilter(filter);
    } else {
      setInfringementFilter(['all', false]);
      if (selectedInfringements && selectedInfringements.length) {
        setClickFilter([
          'any',
          ...selectedInfringements.map((infringement: any) => [
            '==',
            ['id'],
            infringement.operationId,
          ]),
        ]);
      } else {
        setClickFilter(['all', false]);
      }
    }
  }, [JSON.stringify(features), JSON.stringify(selectedInfringements)]);

  return { infringementFilter, clickFilter };
};
