import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  Pill,
  getConvertedDistance,
  getConvertedVerticalDistance,
} from '@ems/client-design-system';
// shared components
import { TableIcon, ColumnHeader } from 'src/components';
// action
import { sortTable } from 'src/@operations/actions';
// utils
import {
  getDeployedProductId,
  tableDateTimeFormat,
  tableDateTimeFormatShort,
  InlineEditDropDown,
  InlineEditDropDownWithIcon,
  getOperationTypeColor,
} from 'src/utils';
// const
import {
  CORRELATED_TYPES,
  OPERATIONS,
  INFRINGEMENTS,
  NOISEEVENTS,
  COMPLAINTS,
  FORMATION_COUNT_EDIT_VALUES,
} from 'src/constants';
import { IConfigurationUnits } from 'src/app/props';

import { Icons } from '@ems/client-design-system';

export const renderOperationTagPills = (operationTags: string[]): JSX.Element[] => (
  operationTags.map((tag: string, index: number) => (
    <Pill title={tag} icon="" type="operation-tag" key={`pill_${index}`} />
  ))
)

const renderCorrelatedPills = (
  correlatedItems,
  tableId,
  translation,
  availableWidth,
  useSmallPills
) => {
  const pillWidth = 96;
  const displayItems: any[] = [];
  const infringements = correlatedItems[CORRELATED_TYPES.INFRINGEMENT];
  const noiseEvents = correlatedItems[CORRELATED_TYPES.NOISEEVENT];
  const complaints = correlatedItems[CORRELATED_TYPES.COMPLAINT];
  const numInfringements = infringements.length;
  const numNoiseEvents = noiseEvents.length;
  const numComplaints = complaints.length;
  // Rollup if length is not wide enough
  const totalPillCount = numInfringements + (numNoiseEvents ? 1 : 0) + (numComplaints ? 1 : 0);
  if (pillWidth * totalPillCount < availableWidth || numInfringements === 1) {
    infringements.map(item => {
      const type = item.data;
      const translatedTitle = translation[type]; // example: type = "Cco"
      const pillTitle = useSmallPills ? '' : translatedTitle;
      displayItems.push(
        <div
          key={`${tableId}_${translatedTitle}_${item.id}`}
          className={`operation_pill ${type.length <= 3 && 'operation_pill--uppercase'}`}>
          <Link
            to={{
              pathname: `/${getDeployedProductId()}/${INFRINGEMENTS}/${item.id}`,
            }}
            className="pill--link"
            tabIndex={0}>
            <Pill title={pillTitle} icon="ic-infringements" type={item.type} />
          </Link>
        </div>
      );
    });
  } else if (numInfringements > 0) {
    const item = infringements[0];
    if (typeof item !== 'undefined') {
      const type = item.data;
      const translatedTitle = translation[type]; // example: type = "Cco"
      const pillTitle = useSmallPills ? '' : translatedTitle;

      const timeArray = infringements.map(inf => DateTime.fromISO(inf.time, { setZone: true }));
      const minTime = DateTime.min(...timeArray).toFormat('yyyy-MM-dd');
      const maxTime = DateTime.max(...timeArray).toFormat('yyyy-MM-dd');

      displayItems.push(
        <div key={`${tableId}_${CORRELATED_TYPES.INFRINGEMENT}`} className={`operation_pill`}>
          <Link
            to={{
              pathname: `/${getDeployedProductId()}/${INFRINGEMENTS}`,
              search: `?from=${minTime}&to=${maxTime}`,
              state: { fromCorrelated: true, ids: infringements.map(item => item.id) },
            }}
            className="pill--link"
            tabIndex={0}>
            <Pill title={pillTitle} icon="ic-infringements" type={CORRELATED_TYPES.INFRINGEMENT} />
          </Link>
          <span className={cx('pill-excess', `pill-excess--${CORRELATED_TYPES.INFRINGEMENT}`)}>
            +{numInfringements - 1}
          </span>
        </div>
      );
    }
  }

  if (numNoiseEvents > 1) {
    const item = noiseEvents[0];
    const type = item.data;
    const translatedTitle = translation[type]; // example: type = "Cco"
    const pillTitle = useSmallPills ? '' : translatedTitle;

    const timeArray = noiseEvents.map(inf => DateTime.fromISO(inf.time, { setZone: true }));
    const minTime = DateTime.min(...timeArray).toFormat('yyyy-MM-dd');
    const maxTime = DateTime.max(...timeArray).toFormat('yyyy-MM-dd');

    displayItems.push(
      <div key={`${tableId}_${CORRELATED_TYPES.NOISEEVENT}`} className={`operation_pill`}>
        <Link
          to={{
            pathname: `/${getDeployedProductId()}/${NOISEEVENTS}/`,
            search: `?from=${minTime}&to=${maxTime}`,
            state: { fromCorrelated: true, ids: noiseEvents.map(item => item.id) },
          }}
          className="pill--link"
          tabIndex={0}>
          <Pill title={pillTitle} icon="ic-noise" type={CORRELATED_TYPES.NOISEEVENT} />
        </Link>
        <span className={cx('pill-excess', `pill-excess--${CORRELATED_TYPES.NOISEEVENT}`)}>
          +{numNoiseEvents - 1}
        </span>
      </div>
    );
  } else if (numNoiseEvents === 1) {
    const item = noiseEvents[0];
    const type = item.data;
    const translatedTitle = translation[type]; // example: type = "Cco"
    const pillTitle = useSmallPills ? '' : translatedTitle;

    displayItems.push(
      <div key={`${tableId}_${translatedTitle}_${item.id}`} className={`operation_pill`}>
        <Link
          to={{
            pathname: `/${getDeployedProductId()}/${NOISEEVENTS}/${item.id}`,
          }}
          className="pill--link"
          tabIndex={0}>
          <Pill title={pillTitle} icon="ic-noise" type={CORRELATED_TYPES.NOISEEVENT} />
        </Link>
      </div>
    );
  }

  if (numComplaints > 1) {
    const item = complaints[0];
    const type = item.data;
    const translatedTitle = translation[type];
    const pillTitle = useSmallPills ? '' : translatedTitle;

    const timeArray = complaints.map(complaint =>
      DateTime.fromISO(complaint.time, { setZone: true })
    );
    const minTime = DateTime.min(...timeArray).toFormat('yyyy-MM-dd');
    const maxTime = DateTime.max(...timeArray).toFormat('yyyy-MM-dd');

    displayItems.push(
      <div key={`${tableId}_${CORRELATED_TYPES.COMPLAINT}`} className={`operation_pill`}>
        <Link
          to={{
            pathname: `/${getDeployedProductId()}/${COMPLAINTS}/`,
            search: `?from=${minTime}&to=${maxTime}`,
            state: { fromCorrelated: true, ids: complaints.map(item => item.id) },
          }}
          className="pill--link"
          tabIndex={0}>
          <Pill title={pillTitle} icon="ic-complaints" type={CORRELATED_TYPES.COMPLAINT} />
        </Link>
        <span className={cx('pill-excess', `pill-excess--${CORRELATED_TYPES.COMPLAINT}`)}>
          +{numComplaints - 1}
        </span>
      </div>
    );
  } else if (numComplaints === 1) {
    const item = complaints[0];
    const type = item.data;
    const translatedTitle = translation[type];
    const pillTitle = useSmallPills ? '' : translatedTitle;

    displayItems.push(
      <div key={`${tableId}_${translatedTitle}_${item.id}`} className={`operation_pill`}>
        <Link
          to={{
            pathname: `/${getDeployedProductId()}/${COMPLAINTS}/${item.id}`,
          }}
          className="pill--link"
          tabIndex={0}>
          <Pill title={pillTitle} icon="ic-complaints" type={CORRELATED_TYPES.COMPLAINT} />
        </Link>
      </div>
    );
  }

  if (displayItems.length > 0) {
    return <div className="operation_pill-container">{displayItems}</div>;
  } else {
    return '';
  }
};

/*
 * Rule Name link
 */
const linkToOpDetails = (rule: string | null, id: any | null, ruleInfo) => (
  <Link
    className="rule_link"
    to={{
      pathname: `/${getDeployedProductId()}/${OPERATIONS}/${id}`,
      search: undefined,
      state: ruleInfo,
    }}>
    {rule}
  </Link>
);

const formatADIcon = (adFlag: string = '', selectedTrackTheme: string) => {
  const operationTheme = getOperationTypeColor(selectedTrackTheme, adFlag);
  return (
    <Icons
      iconName={`ic-ad-${adFlag.toLowerCase()}`}
      size={24}
      style={{
        fill: operationTheme,
        color: operationTheme,
      }}
    />
  );
};

const formatFlightIcon = (acType: string | null) => {
  if (!acType) {
    return <Icons iconName={`ic-ac-unknown`} size={24} />;
  }
  return <Icons iconName={`ic-ac-${acType.toLowerCase()}`} size={24} />;
};

const formatCorrelated = (correlatedData, appliedFilters) => {
  const infringementItems: any[] = [];
  const noiseEventItems: any[] = [];
  const complaintItems: any = [];

  if (correlatedData) {
    if (correlatedData.infringements) {
      for (const inf of correlatedData.infringements) {
        if (
          !appliedFilters.length ||
          appliedFilters.findIndex(f => f.key === 'Infringements') !== -1 ||
          appliedFilters.findIndex(
            f => f.key.toLowerCase() === inf.infringementType.toLowerCase()
          ) !== -1
        ) {
          infringementItems.push({
            data: inf.infringementType,
            id: inf.id,
            time: inf.time,
          });
        }
      }
    }

    if (correlatedData.noiseEvents) {
      for (const inf of correlatedData.noiseEvents) {
        if (
          !appliedFilters.length ||
          appliedFilters.findIndex(f => f.key === 'NoiseEvents') !== -1
        ) {
          noiseEventItems.push({
            data: 'NoiseEvents',
            id: inf.id,
            time: inf.time,
          });
        }
      }
    }

    if (correlatedData.complaints) {
      for (const complaint of correlatedData.complaints) {
        if (
          !appliedFilters.length ||
          appliedFilters.findIndex(f => f.key === 'Complaints') !== -1
        ) {
          complaintItems.push({
            data: 'Complaint',
            id: complaint.id,
            time: complaint.time,
          });
        }
      }
    }

    infringementItems.sort((a, b) => a.id - b.id);
    noiseEventItems.sort((a, b) => a.id - b.id);
    complaintItems.sort((a, b) => a.id - b.id);
  }

  return {
    [CORRELATED_TYPES.INFRINGEMENT]: infringementItems,
    [CORRELATED_TYPES.NOISEEVENT]: noiseEventItems,
    [CORRELATED_TYPES.COMPLAINT]: complaintItems,
  };
};

export const formatRunwayField = (runway: string | null) => (runway ? runway : '—');

// In Landscape view, it only works at 1280px or above. At 1280px it is approximately 46px and then increases linearly.
// In portrait or smaller screens for landscape view, it will differ based off screen size.
// Less than 1280px scales down the correlated column from 514px to 0px linearly (though in reality it isn't quite linear but doesn't matter that it isn't exact)
// For greater than 1280px, it begins at 97px and increases linearly.
const calculatePillInformation = (windowWidth, isPortraitLayout, usingPca) => {
  const pillWidth = 96;
  // TODO: Change to 3 once we have complaints
  const smallBreakpoint = pillWidth * 3;
  const pcaWidth = usingPca ? 170 : 0;
  let availableWidth = windowWidth;
  if (!isPortraitLayout && windowWidth >= 1280) {
    availableWidth = 46 + (windowWidth - 1280) - pcaWidth;
  } else {
    if (windowWidth < 1280) {
      availableWidth = Math.max(0, 514 - pcaWidth - (1280 - windowWidth));
    } else {
      availableWidth = 97 + (windowWidth - 1280) - pcaWidth;
    }
  }
  const useSmallPills = availableWidth < smallBreakpoint;
  // console.log(`availableWidth: ${availableWidth}, useSmallPills: ${useSmallPills}`);
  return { availableWidth, useSmallPills };
};

export const addDisplayField = (
  operation,
  appliedFilters,
  units: IConfigurationUnits,
  twelveHourFormat: boolean,
  selectedTrackTheme: string
) => ({
  time: tableDateTimeFormat(operation.time, twelveHourFormat),
  'time-short': tableDateTimeFormatShort(operation.time, twelveHourFormat),
  adIcon: formatADIcon(operation.operationType, selectedTrackTheme),
  adType: operation.operationType,
  flightCategory: operation.aircraftCategory,
  operatorCategory: operation.operatorCategory,
  flightIcon: formatFlightIcon(operation.aircraftCategory),
  runway: formatRunwayField(operation.runwayName),
  correlated: formatCorrelated(operation.correlated, appliedFilters.correlated),
  model: operation.aircraftType,
  horizontal:
    operation.pca && operation.pca.horizontalDistance
      ? getConvertedDistance(operation.pca.horizontalDistance, units.distance, 1)
      : 0,
  diagonal:
    operation.pca && operation.pca.slantDistance
      ? getConvertedDistance(operation.pca.slantDistance, units.distance, 1)
      : 0,
  altitude:
    operation.pca && operation.pca.verticalDistance
      ? getConvertedVerticalDistance(operation.pca.verticalDistance, units.distanceVertical, 0)
      : 0,
  'pca-time':
    operation.pca && operation.pca.time
      ? tableDateTimeFormat(operation.pca.time, twelveHourFormat)
      : null,
  'pca-time-short':
    operation.pca && operation.pca.time
      ? tableDateTimeFormatShort(operation.pca.time, twelveHourFormat)
      : null,
});

export const canUpdateField = (canUpdate: boolean, readOnlyFields: string[], field: string) => {
  return canUpdate && readOnlyFields.findIndex(e => e === field) === -1;
};

/*
 * Prepare operation for render on table
 */
export const formatOperationsData = (
  data: any,
  translationData: any,
  filterData: any,
  onUpdateSelection: any,
  ruleInfo: any,
  appliedFilters: any,
  canUpdate: boolean,
  windowWidth: number,
  isPortraitLayout: boolean,
  usingPca: boolean,
  units: IConfigurationUnits,
  readOnlyFields: string[],
  twelveHourFormat: boolean,
  featureFlags: {
    operatorCategory: boolean;
    operationTags: boolean;
    formationCount: boolean;
  },
  selectedTrackTheme: string
) => {
  const { availableWidth, useSmallPills } = calculatePillInformation(
    windowWidth,
    isPortraitLayout,
    usingPca
  );
  // const availableWidth = 2000;
  // const useSmallPills = false;

  const mapObject = new Map();
  for (const [id, operationData] of data) {
    const operation = Object.assign({}, operationData);
    const displayFields = addDisplayField(
      operation,
      appliedFilters,
      units,
      twelveHourFormat,
      selectedTrackTheme
    );
    operation.acid = linkToOpDetails(operation.acid, operation.id, ruleInfo);
    operation.displayFlag = InlineEditDropDownWithIcon({
      id,
      operation,
      fieldType: 'operationType',
      fieldIcon: displayFields.adIcon,
      fieldTooltip: displayFields.adType,
      iconPrefix: 'ad',
      reduceChevronPadding: true,
      translationData,
      options: filterData.operationTypes,
      onUpdateSelection,
      canUpdate: canUpdateField(canUpdate, readOnlyFields, 'operationType'),
      selectedTrackTheme,
    });
    operation.displayCategory = InlineEditDropDownWithIcon({
      id,
      operation,
      fieldType: 'aircraftCategory',
      fieldIcon: displayFields.flightIcon,
      fieldTooltip: displayFields.flightCategory,
      iconPrefix: 'ac',
      reduceChevronPadding: false,
      translationData,
      options: filterData.aircraftCategories,
      onUpdateSelection,
      canUpdate: canUpdateField(canUpdate, readOnlyFields, 'aircraftCategory'),
      selectedTrackTheme,
    });
    operation.displayRunwayName = InlineEditDropDown({
      id,
      operation,
      fieldType: 'runwayName',
      position: 'center',
      labelName: displayFields.runway,
      translationData,
      options: filterData.runwayNames,
      onUpdateSelection,
      canUpdate: canUpdateField(canUpdate, readOnlyFields, 'runwayName'),
    });
    operation.correlated = renderCorrelatedPills(
      displayFields.correlated,
      operation.tableId,
      translationData,
      availableWidth,
      useSmallPills
    );
    operation.aircraftType = InlineEditDropDown({
      id,
      operation,
      fieldType: 'aircraftType',
      position: 'left',
      labelName: displayFields.model,
      translationData,
      options: filterData.aircraftTypes,
      onUpdateSelection,
      canUpdate: canUpdateField(canUpdate, readOnlyFields, 'aircraftType'),
    });
    operation.airportId = InlineEditDropDown({
      id,
      operation,
      fieldType: 'airportId',
      position: 'center',
      labelName: operation.airportId,
      translationData,
      options: filterData.airportIds,
      onUpdateSelection,
      canUpdate: canUpdateField(canUpdate, readOnlyFields, 'airportId'),
    });
    operation.operatorCategory = InlineEditDropDown({
      id,
      operation,
      fieldType: 'operatorCategory',
      position: 'left',
      labelName: operationData.operatorCategory,
      translationData,
      options: filterData.operatorCategories,
      onUpdateSelection,
      canUpdate: canUpdateField(canUpdate, readOnlyFields, 'operatorCategory'),
    });
    // If we display formationCount, do not display the remoteAirportId column
    if (featureFlags.formationCount) {
      operation.aircraftCount = InlineEditDropDown({
        id,
        operation,
        fieldType: 'aircraftCount',
        position: 'left',
        labelName: operationData.aircraftCount,
        translationData,
        options: FORMATION_COUNT_EDIT_VALUES,
        onUpdateSelection,
        canUpdate: canUpdateField(canUpdate, readOnlyFields, 'aircraftCount'),
      });
    } else {
      operation.remoteAirportId = InlineEditDropDown({
        id,
        operation,
        fieldType: 'remoteAirportId',
        position: 'left',
        labelName: operation.remoteAirportId,
        translationData,
        options: filterData.remoteAirportIds,
        onUpdateSelection,
        canUpdate: canUpdateField(canUpdate, readOnlyFields, 'remoteAirportId'),
      });
    }
    if (featureFlags.operatorCategory) {
      operation.metaData = { operatorCategory: operationData.operatorCategory };
    }
    if (featureFlags.operationTags && operationData.tags) {
      const operationTags: string[] = operationData.tags.map(tag => tag.name);
      operation.operationTags = renderOperationTagPills(operationTags);
    }
    operation.displayTime = displayFields.time;
    operation[`displayTime-short`] = displayFields[`time-short`];
    operation.displayAlt = displayFields.altitude;
    operation.displayHoriz = displayFields.horizontal;
    operation.displayDiag = displayFields.diagonal;
    operation.pcaTime = displayFields['pca-time'];
    operation[`pcaTime-short`] = displayFields[`pca-time-short`];
    mapObject.set(id, operation);
  }
  return mapObject;
};

/*
 * Prepare operation header
 */
export const formatHeaders = (
  resultSize: number,
  isLoading: boolean,
  dispatcher: any,
  sortSelectors: any,
  translationData: any
) => {
  // Overrides the headers provided
  // could improve and loop through data from config
  const {
    fields: { operations: rowHeaders },
    abbreviations: {
      aircraftCategory: aircraftCategoryAbrr,
      runwayName: runwayNameAbbr,
      diagonal: diagonalAbbr,
      horizontal: horizontalAbbr,
      altitude: altitudeAbbr,
      aircraftCount: aircraftCountAbbr,
    },
    components: {
      labels: { sortBy },
    },
  } = translationData;

  const acid = ColumnHeader({
    sortName: 'acid',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.acid,
      abbreviation: null,
      sortBy,
    },
  });
  const displayFlag = ColumnHeader({
    titleIcon: TableIcon({
      name: 'arrival',
      prefix: 'ad',
      size: 24,
      className: isLoading ? 'disabled' : '',
    }),
    sortName: 'operationType',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.operationType,
      abbreviation: null,
      sortBy,
    },
  });
  const displayTime = ColumnHeader({
    sortName: 'time',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayTime,
      abbreviation: null,
      sortBy,
    },
  });
  const airportId = ColumnHeader({
    sortName: 'airportId',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.airportId,
      abbreviation: null,
      sortBy,
    },
  });
  const remoteAirportId = ColumnHeader({
    sortName: 'remoteAirportId',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.remoteAirportId,
      abbreviation: null,
      sortBy,
    },
  });
  const displayRunwayName = ColumnHeader({
    sortName: 'runwayName',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayRunwayName,
      abbreviation: runwayNameAbbr,
      sortBy,
    },
  });
  const displayCategory = ColumnHeader({
    sortName: 'aircraftCategory',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayCategory,
      abbreviation: aircraftCategoryAbrr,
      sortBy,
    },
  });
  const aircraftType = ColumnHeader({
    sortName: 'aircraftType',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.aircraftType,
      abbreviation: null,
      sortBy,
    },
  });
  const displayHoriz = ColumnHeader({
    sortName: 'pcaHoriz',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.displayHoriz,
      abbreviation: horizontalAbbr,
      sortBy,
    },
  });
  const displayDiag = ColumnHeader({
    sortName: 'pcaDiag',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.displayDiag,
      abbreviation: diagonalAbbr,
      sortBy,
    },
  });
  const displayAlt = ColumnHeader({
    sortName: 'pcaHoriz',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.displayAlt,
      abbreviation: altitudeAbbr,
      sortBy,
    },
  });
  const pcaTime = ColumnHeader({
    sortName: 'pcaTime',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.pcaTime,
      abbreviation: null,
      sortBy,
    },
  });
  const correlated = ColumnHeader({
    sortName: 'correlated',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.correlated,
      abbreviation: null,
      sortBy,
    },
  });
  const operatorCategory = ColumnHeader({
    sortName: 'operatorCategory',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.operatorCategory,
      abbreviation: null,
      sortBy,
    },
  });
  const aircraftCount = ColumnHeader({
    sortName: 'aircraftCount',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.aircraftCount,
      abbreviation: aircraftCountAbbr,
      sortBy,
    },
  });
  const operationTags = ColumnHeader({
    sortName: 'operationTags',
    sortable: false,
    sortSelectors,
    sortTable,
    resultSize,
    isLoading,
    dispatcher,
    languageData: {
      title: rowHeaders.operationTags,
      abbreviation: null,
      sortBy: null,
    },
  });

  return Object.assign({}, rowHeaders, {
    acid,
    displayFlag,
    displayTime,
    airportId,
    remoteAirportId,
    displayRunwayName,
    displayCategory,
    operatorCategory,
    aircraftCount,
    aircraftType,
    displayDiag,
    displayHoriz,
    displayAlt,
    pcaTime,
    correlated,
    operationTags,
  });
};
