import React, { useState, useEffect } from 'react';
import { useConfigSelectors } from 'src/app/reducers';
// components
import {
  MapPopupMoveable,
  Button,
  getConvertedLocation,
  getConvertedVerticalDistance,
} from '@ems/client-design-system';

// style
import './styles.scss';

export const LocationPopup = ({
  longitude,
  latitude,
  address,
  elevation,
  onClose,
  languageData,
  onFilterButtonClick,
  isUsingPca,
  showFilterButton = true,
  mapApis,
}: any) => {
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  const [filteredPos, setFilteredPos] = useState<{ lat: number | null; lon: number | null }>({
    lat: null,
    lon: null,
  });

  useEffect(() => {
    if (!isUsingPca) {
      setFilteredPos({
        lat: null,
        lon: null,
      });
    }
  }, [isUsingPca]);

  const onFilterClick = () => {
    setFilteredPos({
      lat: latitude,
      lon: longitude,
    });

    onFilterButtonClick();
  };

  const approximatelyEqual = (pos1, pos2) => {
    return Math.abs(pos1 - pos2) < 0.001;
  };

  const isFilterButtonDisabled =
    filteredPos.lat && filteredPos.lon
      ? approximatelyEqual(filteredPos.lat, latitude) &&
        approximatelyEqual(filteredPos.lon, longitude)
      : false;

  if (!latitude || !longitude) {
    return null;
  }

  const { lat, lng, amsl, filter } = languageData;

  return (
    <MapPopupMoveable
      className="location-popup"
      offsetLeft={20}
      offsetTop={-140}
      longitude={longitude}
      latitude={latitude}
      anchorLongitude={longitude}
      anchorLatitude={latitude}
      onClose={onClose}
      anchorLine={true}
      draggable={true}>
      <div className="location-popup">
        {address && <h3 className="location-popup_title">{address}</h3>}
        <div className="location-popup_row">
          <p className="location-popup_col">
            <span className="location-popup_label">{lat}</span>
            <span className="location-popup_value">
              {getConvertedLocation(latitude, units.location, 5)}
            </span>
          </p>
          <p className="location-popup_col">
            <span className="location-popup_label">{lng}</span>
            <span className="location-popup_value">
              {getConvertedLocation(longitude, units.location, 5)}
            </span>
          </p>
          <p className="location-popup_col">
            <span className="location-popup_label">{amsl}</span>
            <span className="location-popup_value">
              {elevation !== null
                ? getConvertedVerticalDistance(elevation, units.distanceVertical, 0)
                : `—`}
            </span>
          </p>
        </div>
        {showFilterButton ? (
          <div className="location-filter-button">
            <Button style="primary" onClick={onFilterClick} disabled={isFilterButtonDisabled}>
              {filter}
            </Button>
          </div>
        ) : null}
      </div>
    </MapPopupMoveable>
  );
};
