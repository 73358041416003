import { Button, Icons, SkeletonText } from '@ems/client-design-system';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { ISelectFlightHeader } from 'src/@complaints/interfaces';
import { goBack } from 'src/app/functions/itemsNavigation';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { PageHeaderDetails } from 'src/components';
import { COMPLAINTS, FIELD_TWENTYFOUR_HOURS_FORMAT } from 'src/constants';

export const HeaderContainer: FC<ISelectFlightHeader> = ({ time, id, isEditing }) => {
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      complaints: {
        create: { titleView: detailsTitle },
        addFlight: { addFlightTitle, editFlightTitle },
      },
    },
  } = languageSelectors.getLanguage();

  const configSelectors = useConfigSelectors();
  const {
    globals: { timeZone },
  } = configSelectors.getConfig();

  const returnToButton = (
    <Button
      size="s"
      style="subtle"
      onClick={() => goBack(`${COMPLAINTS}/${id}`, null, [])}
      aria-label={`Go back to ${detailsTitle}`}
      leftIcon={<Icons iconName={`ic-ui-chevron-left`} size="20" />}>
      {detailsTitle}
    </Button>
  );

  const subtitle = (
    <SkeletonText loading={!time} width="15rem">
      {time
        ? DateTime.fromISO(time)
            .setZone(timeZone)
            .toFormat(FIELD_TWENTYFOUR_HOURS_FORMAT)
        : ''}
    </SkeletonText>
  );

  // TODO: Add date when data is available for it
  return (
    <PageHeaderDetails
      title={isEditing ? editFlightTitle : addFlightTitle}
      subtitle={subtitle}
      showReturnButton={true}
      returnToButton={returnToButton}
    />
  );
};
