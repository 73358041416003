import React, { FC, useContext, useState, useEffect, useRef, useMemo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { debounce } from 'debounce';
// selectors
import { useDataSelectors } from 'src/@operations/reducers/dataReducer';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
// common components
import { StyledMap, MapControl, GeocoderPin, RulerTool } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
import { OperationPopup, LocationPopup } from 'src/components';
// functions
import {
  useMapRef,
  useMapWhenReady,
  useMapProps,
  useDatesDataForMap,
  useMapConfig,
  fetchTrackTiles,
} from 'src/app/functions/map';
import { defineBbox } from 'src/app/functions/bbox';
import { useMapSettings } from 'src/app/functions/mapSettings';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import { useOperationsDataInMap, useFilterMap } from 'src/@operations/functions/map';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useFilterSelectors } from 'src/@operations/reducers';
// actions
import { selectTracks, setToPcaFilter } from 'src/@operations/actions';
// context
import { OperationDispatchContext } from 'src/@operations/providers/OperationsStateProvider';
// functions
import {
  flyTo,
  useHoverOnMapElement,
  useMapClick,
  useMapSelection,
  useMapHover,
  useGeocoderPinAlternative,
  addCustomTileSource,
  removeMapSources,
  toggleMapSourcesVisibility,
  offsetCoordinatesByPixels,
  getMarqueeSelectFeatures,
} from 'src/utils/mapHelpers';

import { useGeocodePosition } from 'src/utils/geocoding';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
// constants
import {
  DEFAULT_BBOX_DISTANCE,
  FLY_TO_DURATION,
  OPERATIONS,
  TRACKS,
  MAPTYPES,
  MARKER_OFFSET,
} from 'src/constants';
import { TOGGLE_MAP_SETTINGS_CTRL, DYNAMIC_TILE_SERVER } from 'src/app/featureToggles';
// ts
import { IGeocodeCandidateDetail } from 'src/app/props';
import { IRulerCoordinateObject, IBounds } from 'src/utils/interfaces';

export const MapContainer: FC = () => {
  const client = useApolloClient();
  const dispatcher: any = useContext(OperationDispatchContext);

  // get map props from config
  const {
    viewportFromProps,
    mapboxApiAccessToken,
    mapStyle: defaultMapStyle,
    ...mapProps
  } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    useTracks,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [TRACKS],
  });

  // handling selection when tracks layer is turned on and off
  const [disableTracks, updateDisableTracks] = useState<boolean>(!useTracks);
  useEffect(() => {
    if (disableTracks !== !useTracks) {
      updateDisableTracks(!useTracks);
      if (!useTracks) {
        const selectedOpsInTable = selectedOperations.filter(
          ({ id }) => selectedInTable.indexOf(id) !== -1
        );
        setSelectedOperations(selectedOpsInTable);
      }
    }
  }, [useTracks]);
  // used for taking screenshot of map
  const captureRef = useRef(null);
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();

  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { operations: opsFields },
    abbreviations: { operations: opsAbbreviation },
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        backToCenter: backToCenterLabel,
        search: searchLabel,
        addPin: addPinLabel,
        removePin: removePinLabel,
        lat: latLabel,
        lng: lngLabel,
        amsl: amslLabel,
        pcaFilter: filterLabel,
      },
    },
  } = languageSelectors.getLanguage();
  const filterSelectors = useFilterSelectors();
  const selectedFilters = filterSelectors.getRegularFilters();
  const isFilterApplied = filterSelectors.getIfFilterApplied();
  const isPca = filterSelectors.getIfUsingPcaFilter();
  const timeFilter = filterSelectors.getTimeValues();

  // get layers for styling
  const { layers, opstypeFilter } = useFilterMap({
    mapApis,
    mapBoxConfig,
    selectedFilters,
    timeFilter,
    isFilterApplied,
  });

  // get values when dates change
  const { datesArray, dateRangeMapping } = useDatesDataForMap(
    mapApis,
    mapBoxConfig,
    layers,
    disableTracks
  );

  const dataSelectors = useDataSelectors();
  const requiredDataForMap = dataSelectors.getRequiredDataForMap();
  const {
    selectedOperations,
    selectedInTable,
    pageInfo,
    isLoading,
  } = dataSelectors.getDataInformation();

  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);
  const [mapApiStartCursor, setMapApiStartCursor] = useState<string>();
  const [tileLayers, setTileLayers] = useState<string[]>([]);
  const selectedTrackTheme = configSelectors.getTheme('operations');

  useEffect(() => {
    if (isLoading === true) {
      // When we start a data fetch, clear the map data to give users a better state experience
      removeMapSources(mapApis, 'trackLayer_');
    }
  }, [isLoading]);

  useEffect(() => {
    if (pageInfo && FEATURE_FLAG_DYNAMIC_TILE_SERVER) {
      if (pageInfo.startCursor) {
        setMapApiStartCursor(pageInfo.startCursor);
      }
    }
  }, [pageInfo]);

  useEffect(() => {
    const handleTileLoading = async () => {
      const trackTiles = await fetchTrackTiles(mapApiStartCursor);
      setTileLayers(trackTiles);
    };
    if (mapApiStartCursor) {
      handleTileLoading();
    }
  }, [mapApiStartCursor]);

  useEffect(() => {
    if (tileLayers.length) {
      const operationCount = dataSelectors.getTotalCount();
      addCustomTileSource(mapApis, tileLayers, selectedTrackTheme, !disableTracks, operationCount);
    }
  }, [tileLayers, mapApis]);

  if (FEATURE_FLAG_DYNAMIC_TILE_SERVER) {
    // Toggle visibility of tile layer for dynamic server
    useEffect(() => {
      toggleMapSourcesVisibility({ mapApis, prefix: 'trackLayer_', hide: disableTracks });
    }, [disableTracks]);
  }

  const [layerCount, setLayerCount] = useState<number>(0);
  if (mapApis) {
    try {
      const layers = mapApis.getStyle().layers;
      if (layers.length !== layerCount) {
        setLayerCount(layers.length);
      }
    } catch {
      console.error(typeof mapApis.getStyle);
    }
  }

  useOperationsDataInMap({
    mapApis,
    dateRangeMapping,
    datesArray,
    mapBoxConfig,
    requiredDataForMap,
    selectedOperations,
    opstypeFilter,
    viewport,
    layerCount,
    disableTracks,
  });

  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  const labels = Object.assign(opsFields, opsAbbreviation);
  const { hoveredElement, handleHover } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: datesArray,
    tracksFilter: opstypeFilter,
    restrictZoomLevels: true,
    layerPrefix: 'tracks_background_',
    radius: 5,
    disabled: disableTracks,
    mapType: MAPTYPES.OPERATIONDETAILS,
  });

  const [showSelected, setShowSelected] = useState(false);
  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>({
    longitude: 0,
    latitude: 0,
  });
  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const [isSearchOpen, updateDialogStatus] = useState<boolean>(false);
  const [closeSearch, updateCloseSearch] = useState<boolean>(false);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);
  const [drag, updateDragStatus] = useState<boolean>(false);

  // geocoder bbox
  const [bbox, updateBbox] = useState<number[]>([]);
  useEffect(() => {
    // define the geocoder bbox at the start
    updateBbox(defineBbox(viewport, mapBoxConfig.geocoderBbox, DEFAULT_BBOX_DISTANCE));
  }, []);

  // Set max bounds for pan
  // react-map-gl doesn't support setMaxBound yet so we handle it manually
  const onViewportChange = viewport => {
    if (viewport.longitude < bbox[0]) {
      viewport.longitude = bbox[0];
    } else if (viewport.longitude > bbox[2]) {
      viewport.longitude = bbox[2];
    }
    if (viewport.latitude < bbox[1]) {
      viewport.latitude = bbox[1];
    } else if (viewport.latitude > bbox[3]) {
      viewport.latitude = bbox[3];
    }
    setViewport(viewport);
  };

  const { addRemoveCircles } = useCircleRanges(mapApis, mapStyle);
  const { latitude, longitude } = geocoding;
  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude,
      latitude,
    },
  });

  const setSelectedOperations = operations => {
    const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(
      DYNAMIC_TILE_SERVER
    );
    // TODO restrict the number of times the action needs to be dispatched
    selectTracks(operations, dispatcher, FEATURE_FLAG_DYNAMIC_TILE_SERVER);
  };

  const addPinToCentre = () => {
    // toggle the feature
    updateLocationAddress(null);
    if (longitude) {
      // remove the pin if it's already added to the map
      updateGeocoding({ longitude: 0, latitude: 0 });
      addRemoveCircles(null);
    } else {
      const { longitude, latitude } = viewport;
      updateGeocoding({ longitude, latitude });
      addRemoveCircles({ longitude, latitude });
      updateLocationTagOpen(true);
    }
  };

  const { handleClick, selectedOperation } = useMapClick({
    hoveredOperation: hoveredElement,
    setShowSelected,
    mapApis,
    datesArray,
    tracksFilter: opstypeFilter,
    selectedOperations,
    setSelectedOperations,
    layerPrefix: 'tracks_background_',
    disabled: disableTracks,
    mapType: MAPTYPES.OPERATIONDETAILS,
  });

  useMapSelection(selectedOperations, mapApis);
  const { removeHovered } = useMapHover(hoveredElement, mapApis, drag);

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);
  useGeocoderPinAlternative({
    mapApis,
    enableMap: enableMapControls,
    coordinates: [[longitude, latitude]],
  });

  const goToSelectedAddress = (address: IGeocodeCandidateDetail) => {
    const { formattedAddress, position } = address;
    if (position && typeof position !== 'undefined') {
      if (mapApis !== null) {
        const zoom = 12;
        const { longitude, latitude } = position;
        mapApis.flyTo({
          center: [longitude, latitude],
          zoom,
          duration: FLY_TO_DURATION,
        });
        setTimeout(() => {
          updateGeocoding({ longitude, latitude });
          addRemoveCircles({ longitude, latitude });
          updateLocationAddress(formattedAddress);
          updateLocationTagOpen(true);
          onViewportChange({
            ...viewport,
            zoom,
            maxPitch: 0,
            longitude,
            latitude,
          });
          updateCloseSearch(true);
          setTimeout(() => {
            updateCloseSearch(false);
          });
        }, FLY_TO_DURATION);
      }
    }
  };
  const AddressSearch = useMemo(
    () => (
      <div className="mapboxgl-ctrl-search">
        <AddressSearchContainer source="map" onAddressFound={goToSelectedAddress} />
      </div>
    ),
    [mapApis, addRemoveCircles]
  );

  // Ruler Tool

  const units = configSelectors.getUnits();
  const [isRulerEnabled, setIsRulerEnabled] = useState<boolean>(false);
  const [rulerCoordinates, updateRulerCoordinates] = useState<IRulerCoordinateObject>({
    start: { longitude: 0, latitude: 0 },
    end: { longitude: 0, latitude: 0 },
  });

  const toggleRuler = () => {
    if (isRulerEnabled) {
      setIsRulerEnabled(false);
      mapApis.isRulerEnabled = false;
    } else {
      setIsRulerEnabled(true);
      mapApis.isRulerEnabled = true;

      const { longitude, latitude } = viewport;
      const startMarkerCoordinates = offsetCoordinatesByPixels(
        [longitude, latitude],
        MARKER_OFFSET,
        mapApis
      );

      updateRulerCoordinates({
        start: { longitude: startMarkerCoordinates.lng, latitude: startMarkerCoordinates.lat },
        end: { longitude, latitude },
      });
    }
  };
  const rulerCoordinatesChanged = (rulerPoint: string, [longitude, latitude]: number[]) => {
    if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
      updateRulerCoordinates({
        ...rulerCoordinates,
        [rulerPoint]: { longitude, latitude },
      });
    }
  };

  const [selectedBounds, setSelectedBounds] = useState<IBounds | undefined>();

  useEffect(() => {
    if (selectedBounds) {
      let trackLayers = [];
      if (FEATURE_FLAG_DYNAMIC_TILE_SERVER) {
        mapApis.getStyle().layers.forEach(({ id }) => {
          if (id.includes('trackLayer_')) {
            trackLayers.push(id);
          }
        });
      } else {
        trackLayers = datesArray.map(date => `${mapBoxConfig.backgroundLayerPrefix}${date}`);
      }
      getMarqueeSelectFeatures({
        mapApis,
        bounds: selectedBounds,
        setSelectedOperations,
        setShowSelected,
        trackLayers,
        isDynamic: FEATURE_FLAG_DYNAMIC_TILE_SERVER,
        tracksFilter: opstypeFilter,
      });
    }
  }, [selectedBounds]);

  return (
    <div className="map_wrapper">
      <div ref={captureRef} className="map">
        <StyledMap
          onLoad={() => mapLoaded()}
          isLoading={FEATURE_FLAG_DYNAMIC_TILE_SERVER ? isLoading : null}
          viewport={viewport}
          mapStyle={mapStyle}
          onViewportChange={viewport => {
            viewport.maxPitch = 0;
            onViewportChange(viewport);
          }}
          mapboxApiAccessToken={mapboxApiAccessToken}
          {...mapProps}
          ref={mapRef}
          dragRotate={true}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }
          onClick={isSearchOpen ? undefined : handleClick}
          onHover={isSearchOpen ? undefined : debounce(handleHover, 100)}
          marqueeSelect
          getSelectionBounds={bounds => {
            setSelectedBounds(bounds);
          }}>
          {!isSearchOpen &&
            !drag &&
            showSelected &&
            selectedOperation &&
            selectedOperation.latitude &&
            selectedOperation.longitude && (
              <OperationPopup
                selectedOperation={selectedOperation}
                onClose={() => setShowSelected(false)}
                labels={labels}
                mapApis={mapApis}
              />
            )}
          {!isSearchOpen &&
            !drag &&
            !showSelected &&
            hoveredElement &&
            hoveredElement.latitude &&
            hoveredElement.longitude && (
              <OperationPopup
                selectedOperation={hoveredElement}
                onClose={() => setSelectedOperations(null)}
                labels={labels}
                mapApis={mapApis}
                hover
              />
            )}

          {isLocationTagOpen && (
            <LocationPopup
              latitude={latitude}
              longitude={longitude}
              address={locationAddress || place}
              elevation={elevation}
              languageData={{
                lat: latLabel,
                lng: lngLabel,
                amsl: amslLabel,
                filter: filterLabel,
              }}
              onFilterButtonClick={() =>
                setToPcaFilter(
                  dispatcher,
                  latitude,
                  longitude,
                  elevation,
                  place ||
                    locationAddress ||
                    `${latLabel}: ${latitude.toFixed(6)}, ${lngLabel}: ${longitude.toFixed(6)}`
                )
              }
              isUsingPca={isPca}
              mapApis={mapApis}
              onClose={() => {
                updateLocationTagOpen(!isLocationTagOpen);
              }}
            />
          )}

          <GeocoderPin
            latitude={enableMapControls ? latitude : 0}
            longitude={enableMapControls ? longitude : 0}
            draggable
            onClick={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
            onDragStart={() => {
              addRemoveCircles(null);
              removeHovered();
              updateDragStatus(true);
              updateLocationTagOpen(false);
            }}
            onDragEnd={([longitude, latitude]: number[]) => {
              if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
                updateGeocoding({ longitude, latitude });
              }

              updateLocationAddress(null);
              updateDragStatus(false);
              addRemoveCircles({ longitude, latitude });
              setTimeout(() => {
                updateLocationTagOpen(true);
              }, 1);
            }}
            onMouseEnter={() => {
              removeHovered();
              updateDragStatus(true);
            }}
            onMouseLeave={() => {
              updateDragStatus(false);
            }}
          />

          {enableMapControls && (
            <MapControl
              isPinAdded={latitude && longitude ? true : false}
              addPinToCentre={addPinToCentre}
              onDialogStateChange={isOpen => {
                updateDialogStatus(isOpen);
                if (isOpen) {
                  removeHovered();
                }
              }}
              rulerControl={{
                isRulerEnabled,
                toggleRuler,
              }}
              navigationControl={{
                showCompass: true,
                showHome: true,
                showSearch: true,
                showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
              }}
              translationData={{
                home: backToCenterLabel,
                search: searchLabel,
                addPin: addPinLabel,
                removePin: removePinLabel,
                mapSettings: mapSettingsTitle,
              }}
              resetView={resetView}
              addressSearch={AddressSearch}
              closeSearch={closeSearch}
              mapSettings={{
                update: () => {
                  applyBackground();
                  applyLayers();
                },
                reset: () => {
                  resetBackground();
                  resetLayers();
                },
                content: (
                  <MapSettingsContainer
                    instance={OPERATIONS}
                    config={{
                      background: mapStyle,
                      layers: layersDisplayed,
                    }}
                    updateEvent={({ selectedBackground, selectedLayers }) => {
                      if (typeof selectedBackground !== 'undefined') {
                        storeSelectedBackground(selectedBackground);
                      }
                      if (typeof selectedLayers !== 'undefined') {
                        storeSelectedLayers(selectedLayers);
                      }
                    }}
                  />
                ),
              }}
            />
          )}
          <MapReferenceLayers mapApis={mapApis} mapStyle={mapStyle} layers={layersDisplayed} />
          <RulerTool
            units={units.distance}
            coordinates={rulerCoordinates}
            isRulerEnabled={isRulerEnabled}
            addressCoordinates={geocoding}
            mapProjection={mapProjectionString}
            handleDragEvent={rulerCoordinatesChanged}
            mapApis={mapApis}
          />
        </StyledMap>
      </div>
    </div>
  );
};
