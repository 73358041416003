import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SplitLayout } from 'src/components/Layout';
import {
  FilterContainer,
  MapInfoContainer,
  HeaderContainer,
  TableContainer,
  DataContainer,
} from 'src/@infringementsCandidates/containers';
import { InfringementsStateProvider } from 'src/@infringementsCandidates/providers/InfringementsStateProvider';

const InfringementsCandidatesScreen = ({ location, updateUrl }) => {
  return (
    <SplitLayout>
      <InfringementsStateProvider>
        <DataContainer>
          <MapInfoContainer />
          <div className="container-fluid">
            <div className="header-container">
              <HeaderContainer updateUrl={updateUrl} />
            </div>
            <FilterContainer />
            <TableContainer />
          </div>
        </DataContainer>
      </InfringementsStateProvider>
    </SplitLayout>
  );
};

export const InfringementsCandidates = withRouteProps(InfringementsCandidatesScreen);
