import React from 'react';
// selectors
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
// error page
import { ErrorPage } from 'src/components';

export const NoAccess = () => {
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        noAccess: {
          title,
          errors: { screenError },
        },
      },
    },
  } = languageSelectors.getLanguage();

  if(!(title && screenError)) {
    throw new Error(`NoAccess.tsx: title is ${title} and screenError is ${screenError}`);
  }

  return (
    <ErrorPage title={title}>
      <p>{screenError}</p>
    </ErrorPage>
  );
};
