import { useContext } from 'react';
import { IScenariosState, IStoreState, IScenariosSelectors } from 'src/app/props';
import { appActionTypes } from 'src/app/actionTypes';
import { GlobalStateContext } from 'src/app/providers/GlobalStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';

export const useScenariosSelectors: () => IScenariosSelectors = () => {
  const state: IStoreState = useContext(GlobalStateContext);
  const scenariosState: IScenariosState = state.scenarios;

  return useSelectors(scenariosState, (state: IScenariosState) => ({
    getScenarios: () => state.scenarios,
    getActiveScenario: () => state.activeScenario,
    getSelectedScenario: () => state.currentScenario,
    getSelectedRowIndex: () => state.selectedRowIndex,
    getDefaultScenario: () => state.defaultScenario,
    getIfLoading: () => state.isLoading,
    getRecalculationStatus: () => getScenarioStatus(state),
    getOutStandingMonthsToProcess: () => state.outstandingMonthsToProcess,
  }));
};

const getScenarioStatus = (state: IScenariosState) => {
  return state.outstandingMonthsToProcess !== undefined && state.outstandingMonthsToProcess !== 0;
};

export const scenariosInitialState: IScenariosState = {
  scenarios: [
    {
      id: 1,
      name: 'Default',
      isActive: true,
      isDefault: true,
      isLocked: false,
      startTime: '1996-01-01T00:00:00+00:00',
      endTime: '2020-11-01T00:00:00+00:00',
      defaultLoadFactor: 0,
      airportId: 'EGLL',
      applyWeatherAdjustment: false,
      defaultOptimumTMAApproachDistance: 0,
      defaultDurationTaxiOut: 1072,
      defaultDurationTakeOff: 32,
      defaultDurationClimb: 98,
      defaultDurationApproach: 240,
      defaultDurationTaxiIn: 240,
      emissionRateMode: 'single',
      emissionRateAvGas: 3.1331,
      emissionRateSingle: 3.156,
      emissionRateJetFuel: 3.1497,
      emissionRateUnknown: 3.1497,
      outstandingMonthsToProcess: 0,
    },
  ],
  activeScenario: {
    id: 1,
    name: 'Default',
    isActive: true,
    isDefault: true,
    isLocked: false,
    startTime: '1996-01-01T00:00:00+00:00',
    endTime: '2020-11-01T00:00:00+00:00',
    airportId: 'EGLL',
    defaultOptimumTMAApproachDistance: 0,
    applyWeatherAdjustment: false,
    defaultLoadFactor: 0.0,
    defaultDurationTaxiOut: 1072,
    defaultDurationTakeOff: 32,
    defaultDurationClimb: 98,
    defaultDurationApproach: 240,
    defaultDurationTaxiIn: 240,
    emissionRateMode: 'multiple',
    emissionRateAvGas: 3.1331,
    emissionRateSingle: 3.156,
    emissionRateJetFuel: 3.1497,
    emissionRateUnknown: 3.1497,
    outstandingMonthsToProcess: 0,
  },
  currentScenario: {
    id: 1,
    name: 'Default',
    isActive: true,
    isDefault: true,
    isLocked: false,
    startTime: '1995-12-31T19:00:00-05:00',
    endTime: '2020-03-31T19:00:00-04:00',
    airportId: 'EGLL',
    defaultOptimumTMAApproachDistance: 0,
    applyWeatherAdjustment: false,
    defaultLoadFactor: 0.0,
    defaultDurationTaxiOut: 1072,
    defaultDurationTakeOff: 32,
    defaultDurationClimb: 98,
    defaultDurationApproach: 240,
    defaultDurationTaxiIn: 240,
    emissionRateMode: 'multiple',
    emissionRateAvGas: 3.1331,
    emissionRateSingle: 3.156,
    emissionRateJetFuel: 3.1497,
    emissionRateUnknown: 3.1497,
    outstandingMonthsToProcess: 0,
  },
  defaultScenario: {
    id: 1,
    name: 'Default',
    isActive: true,
    isDefault: true,
    isLocked: false,
    startTime: '1995-12-31T19:00:00-05:00',
    endTime: '2020-03-31T19:00:00-04:00',
    airportId: 'EGLL',
    defaultOptimumTMAApproachDistance: 0,
    applyWeatherAdjustment: false,
    defaultLoadFactor: 0.0,
    defaultDurationTaxiOut: 1072,
    defaultDurationTakeOff: 32,
    defaultDurationClimb: 98,
    defaultDurationApproach: 240,
    defaultDurationTaxiIn: 240,
    emissionRateMode: 'multiple',
    emissionRateAvGas: 3.1331,
    emissionRateSingle: 3.156,
    emissionRateJetFuel: 3.1497,
    emissionRateUnknown: 3.1497,
    outstandingMonthsToProcess: 0,
  },
  selectedRowIndex: 0,
  outstandingMonthsToProcess: 0,
  isLoading: false,
};

export const scenariosReducer = (state: IScenariosState, action: any) => {
  switch (action.type) {
    case appActionTypes.SCENARIOS_LOADING:
      return Object.assign({}, state, { isLoading: true });
    case appActionTypes.UPDATE_SCENARIOS:
      const defaultScenario = getDefaultScenario(action.payload);
      let activeScenario = getActiveScenario(action.payload);
      activeScenario = activeScenario ? activeScenario : defaultScenario;
      return Object.assign({}, state, {
        scenarios: action.payload,
        activeScenario,
        defaultScenario,
        isLoading: false,
      });
    case appActionTypes.UPDATE_CURRENT_SCENARIO:
      return Object.assign({}, state, {
        currentScenario: action.currentScenario.scenario,
        selectedRowIndex: action.currentScenario.selectedRowIndex,
      });
    case appActionTypes.UPDATE_SCENARIO_REPROCESS_STATUS:
      if(state.outstandingMonthsToProcess !== 0 && action.payload.outstandingMonthsToProcess === 0) {
        return Object.assign({}, state, {
          outstandingMonthsToProcess: action.payload.outstandingMonthsToProcess,
          activeScenario: {...state.activeScenario, ...action.payload}
        })
      }
      return Object.assign({}, state, {
        outstandingMonthsToProcess: action.payload.outstandingMonthsToProcess,
      });
    default:
      return state;
  }
};

const getDefaultScenario = scenarios => {
  let defaultScenario;
  scenarios.forEach(scenario => {
    if (scenario.isDefault) {
      defaultScenario = scenario;
    }
  });
  return defaultScenario;
};

const getActiveScenario = scenarios => {
  let activeScenario;
  scenarios.forEach(scenario => {
    if (scenario.isActive) {
      activeScenario = scenario;
    }
  });
  return activeScenario;
};
