import gql from 'graphql-tag';

export const UPDATE_SCENARIOS = gql`
  mutation updateScenarios($scenarios: [BulkScenarioChangeInput]) {
    updateScenarios(scenarios: $scenarios) {
      id
      name
      isActive
      isDefault
      isLocked
      startTime
      endTime
      defaultOptimumTMAApproachDistance
      applyWeatherAdjustment
      defaultLoadFactor
      defaultDurationClimb
      defaultDurationTaxiIn
      defaultDurationTakeOff
      defaultDurationTaxiOut
      defaultDurationApproach
      emissionRateMode
      emissionRateAvGas
      emissionRateSingle
      emissionRateJetFuel
      emissionRateUnknown
      outstandingMonthsToProcess
    }
  }
`;