import React, { createContext, useContext, useState, useEffect } from 'react';
import { debounce } from 'debounce';

interface IWindowDimensions {
  width: number;
  height: number;
}

const WindowDimensionsCtx: React.Context<IWindowDimensions> = createContext({
  width: 0,
  height: 0,
});
export const WindowDimensionsProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState<IWindowDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.onresize = debounce(handleResize, 1000);

    return () => {
      window.onresize = null;
    };
  }, []);
  return <WindowDimensionsCtx.Provider value={dimensions}>{children}</WindowDimensionsCtx.Provider>;
};

export const useWindowDimensions = () => useContext(WindowDimensionsCtx);
