import React, { useContext, useState, useEffect, FC } from 'react';
// components
import { Button, Table } from '@ems/client-design-system';
// reducers
import { useSortSelectors, useFleetMixSelector } from 'src/@settings/reducers';
import { useLanguageSelectors, useScenariosSelectors } from 'src/app/reducers';
// provider
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
// functions
import {
  formatFleetMixData,
  getColumnTypes,
  updateTableIds,
  formatHeaders,
  getFormattedMutationItem,
} from 'src/@settings/functions';
import {
  getSortData,
  getLtoEmissionMappings,
  getEnrouteEmissionMappings,
} from 'src/@settings/functions';
import { convertMapToArray } from 'src/utils';
// interfaces
import {
  IAirlineMappingContainer,
  IAirlineMapping,
  IFleetMixData,
  IMutationItem,
  TMode,
} from 'src/@settings/interfaces';
// constants
import { AIRLINE_MAPPING_MODULE, FLEETMIX_MODULE } from 'src/constants';
import { Prompt } from 'react-router-dom';

export const AirlineMappingContainer: FC<IAirlineMappingContainer> = ({
  airline,
  setChangesAvailable,
  areChangesDiscarded,
  updateFleetMixSettings,
  discardMutationData,
}) => {
  // dispatchers
  const dispatcher = useContext(SettingsDispatchContext);
  // selectors
  const sortSelector = useSortSelectors();
  const languageSelectors = useLanguageSelectors();
  const fleetMixSelector = useFleetMixSelector();
  const scenarioSelector = useScenariosSelectors();
  const translationData = languageSelectors.getLanguage();
  const {
    components: {
      info: { aircraftModelsInfo },
      hints: { areYouSureYouWantToLeave, noDataTitle, noDataText },
    },
    fields: { fleetMix: {
      numberOfAircraft: numberOfAircraftColumnHeader,
      aircraft: aircraftColumnHeader,
      mappedLTOAircraft: mappedLTOAircraftColumnHeader,
      mappedEnrouteAircraft: mappedEnrouteAircraftColumnHeader,
    } },
    components: {
      buttons: { deleteSelected, addNew },
    },
  } = translationData;

  const airlineMappingColumns:string[] = ['numberOfAircraft', 'aircraftType', 'mappedLTOAircraft', 'mappedEnrouteAircraft'];
  const airlineMappingColumnsHeaders = { 
    'numberOfAircraft': numberOfAircraftColumnHeader,
    'aircraftType': aircraftColumnHeader,
    'mappedLTOAircraft': mappedLTOAircraftColumnHeader,
    'mappedEnrouteAircraft': mappedEnrouteAircraftColumnHeader 
  };

  // variables
  const activeScenario = scenarioSelector.getActiveScenario();
  const mappings = fleetMixSelector.getCurrentAirlineMappings();
  const ltoEmissions = fleetMixSelector.getLtoEmissions();
  const enrouteEmissions = fleetMixSelector.getEnrouteEmissions();
  const aircrafts = fleetMixSelector.getAircrafts();
  const sortObject = sortSelector.getSortObject(AIRLINE_MAPPING_MODULE);
  const sortString = sortSelector.getSortString(AIRLINE_MAPPING_MODULE);
  // state
  const [data, setData] = useState<IAirlineMapping[]>(mappings);
  const copyOfMappings = mappings.map(item => {
    return { ...item };
  });
  const [apiData, setApiData] = useState<IAirlineMapping[]>(copyOfMappings);
  const [rowData, setRowData] = useState<IFleetMixData[]>([]);
  const [disableDelete, setDisableDelete] = useState<boolean>(true);
  const [selectedInTable, setSelectedInTable] = useState<number[]>([]);
  const [highlightRow, setHighlightRow] = useState<number>();
  const [mutationData, setMutationData] = useState<Map<number, IMutationItem>>(new Map());

  useEffect(() => {
    formatAndSetRowData(data);
  }, [data]);

  useEffect(() => {
    setData(apiData);
    formatAndSetRowData(apiData);
    mutationData.clear();
    setMutationData(mutationData);
  }, [areChangesDiscarded]);

  useEffect(() => {
    setData(getSortData(mappings, sortObject));
    setApiData(mappings);
  }, [mappings]);

  useEffect(() => {
    mutationData.clear();
    setMutationData(mutationData);
  }, [discardMutationData]);

  const updateFleetMix = (fleetMixId, selectedItem) => {
    setData(prev => {
      const prevData = prev.slice(0);
      prevData[fleetMixId] = { ...prevData[fleetMixId], ...selectedItem };
      handleMutation('Update', prevData[fleetMixId]);
      return prevData;
    });
    setChangesAvailable(true);
  };

  const formatAndSetRowData = data => {
    const formattedData = formatFleetMixData(
      updateTableIds([], data),
      aircrafts,
      getLtoEmissionMappings(ltoEmissions),
      getEnrouteEmissionMappings(enrouteEmissions),
      translationData,
      updateFleetMix
    );
    setRowData(formattedData);
  };

  useEffect(() => {
    const sortData = getSortData(data, sortObject);
    setData(sortData);
  }, [sortString]);

  const handleMutation = (mode: TMode, updatingItem) => {
    setMutationData(getFormattedMutationItem(mode, updatingItem, mutationData, FLEETMIX_MODULE));
    const { items } = convertMapToArray(mutationData);
    updateFleetMixSettings(items);
  };

  const onSelectRow = selectedIndexes => {
    setSelectedInTable(selectedIndexes);
    selectedIndexes.length > 0 ? setDisableDelete(false) : setDisableDelete(true);
  };

  const addNewRow = () => {
    const addNewDefaultRowData = {
      numberOfAircraft: 0,
      aircraftType: '',
      mappedLTOEngine: '',
      mappedLTOAircraft: '? / ?',
      mappedEnrouteAircraft: '? / ?',
      mappedEnrouteEngine: '',
      airline,
      id: new Date().getTime(),
      scenarioId: activeScenario.id,
    };
    setHighlightRow(0);
    handleMutation('Insert', addNewDefaultRowData);
    setData(updateTableIds([addNewDefaultRowData], data));
  };

  const onDeleteClick = () => {
    const deletingItems = getDeletingItems();
    deletingItems.map(item => {
      handleMutation('Delete', item);
    });
    const updatedData = data.filter((item, index) => !selectedInTable.includes(index));
    setData(updateTableIds([], updatedData));
    setSelectedInTable([]);
    mutationData.size > 0 ? setChangesAvailable(true) : setChangesAvailable(false);
  };

  const getDeletingItems = () => {
    return data.filter((item, index) => selectedInTable.includes(index));
  };

  return (
    <div className="airlines-mapping-table">
      <div className="aircraft-models-header">
        <p className={'aircraft-models-info'}>{aircraftModelsInfo}</p>
        <div>
          <Button
            style="standard"
            className="airtrak-delete-btn"
            disabled={disableDelete}
            onClick={onDeleteClick}>
            {deleteSelected}
          </Button>
          <Button style="standard" className="airtrak-addnew-btn" onClick={addNewRow}>
            {addNew}
          </Button>
        </div>
      </div>
      <div className="multiple-emission-table">
        <Table
          className="airlines-table"
          data={rowData}
          columns={airlineMappingColumns}
          rowHeaders={formatHeaders(dispatcher, sortSelector, airlineMappingColumnsHeaders, AIRLINE_MAPPING_MODULE)}
          gridID={'selectable'}
          areAllRowsSelected={false}
          columnTypes={getColumnTypes(airlineMappingColumns)}
          selectedData={selectedInTable}
          onSelectRow={onSelectRow}
          clickedRow={highlightRow}
          languageData={{
            noDataTitle: `${noDataTitle}`,
            noDataText: `${noDataText}`,
          }}
        />
      </div>
      <Prompt
        message={areYouSureYouWantToLeave}
        when={mutationData.size > 0}
      />
    </div>
  );
};
