import { useContext } from 'react';
import { IGeometryState, IGeometrySelectors } from 'src/app/props';
import { geometryActionTypes } from 'src/app/newActionTypes';
import { GeometryStateContext } from 'src/app/providers/GeometryProvider';
import { useSelectors } from 'src/utils/storeHelpers';
// constants
import {
  CORRIDOR_INFRINGEMENT,
  EXCLUSION_INFRINGEMENT,
  GATE_INFRINGEMENT,
  MONITOR_LOCATIONS,
  RUNWAYS,
} from 'src/constants';

export const useGeometrySelectors: () => IGeometrySelectors = () => {
  const state: any = useContext(GeometryStateContext);
  const geometryState: IGeometryState = state.data;
  return useSelectors(geometryState, (state: IGeometryState) => ({
    getGeometry: (source: string, as3d: boolean = false) =>
      as3d ? state[`${source}3d`] : state[source],
    getGisLayers: () => state.gisLayers,
    getKmlLayers: () => state.kmlLayers,
    hasAccessFailed: (source: string) => state.failedToGetData[source],
  }));
};

export const emptyFeatureCollectionTemplate = {
  type: 'FeatureCollection',
  features: [],
};

export const geometryInitialState: IGeometryState = {
  corridors: null,
  selectionzones: null,
  gates: null,
  gateSymbols: null,
  monitorLocations: null,
  runways: null,
  corridors3d: null,
  selectionzones3d: null,
  gates3d: null,
  monitorLocations3d: null,
  runways3d: null,
  gisLayers: [],
  kmlLayers: new Map(),
  failedToGetData: {
    corridors: false,
    selectionZones: false,
    gates: false,
    monitorLocations: false,
    runways: false,
    corridors3d: false,
    selectionZones3d: false,
    gates3d: false,
    monitorLocations3d: false,
    runways3d: false,
  },
};

export const geometryReducer = (state: IGeometryState, action: any) => {
  switch (action.type) {
    case geometryActionTypes[CORRIDOR_INFRINGEMENT]:
      // 2d data
      if (!state.corridors && !action.as3d) {
        return Object.assign({}, state, {
          corridors: action.data,
        });
      }
      // 3d data
      if (!state.corridors3d && action.as3d) {
        return Object.assign({}, state, {
          corridors3d: action.data,
        });
      }
      return state;
    case geometryActionTypes[EXCLUSION_INFRINGEMENT]:
      // 2d data
      if (!state.selectionzones && !action.as3d) {
        return Object.assign({}, state, {
          selectionzones: action.data,
        });
      }
      // 3d data
      if (!state.selectionzones3d && action.as3d) {
        return Object.assign({}, state, {
          selectionzones3d: action.data,
        });
      }
      return state;
    case geometryActionTypes[GATE_INFRINGEMENT]:
      // 2d data
      if (!state.gates && !action.as3d) {
        return Object.assign({}, state, {
          gates: action.data,
          gateSymbols: action.additionalData,
        });
      }
      // 3d data
      if (!state.gates3d && action.as3d) {
        return Object.assign({}, state, {
          gates3d: action.data,
        });
      }
      return state;
    case geometryActionTypes[MONITOR_LOCATIONS]:
      // 2d data
      if (!state.monitorLocations && !action.as3d) {
        return Object.assign({}, state, {
          monitorLocations: action.data,
        });
      }
      // 3d data
      if (!state.monitorLocations3d && action.as3d) {
        return Object.assign({}, state, {
          monitorLocations3d: action.data,
        });
      }
      return state;
    case geometryActionTypes[RUNWAYS]:
      // 2d data
      if (!state.runways && !action.as3d) {
        return Object.assign({}, state, {
          runways: action.data,
        });
      }
      // 3d data
      if (!state.runways3d && action.as3d) {
        return Object.assign({}, state, {
          runways3d: action.data,
        });
      }
      return state;
    case geometryActionTypes.GIS_LAYERS:
      if (!state.gisLayers.length) {
        return Object.assign({}, state, {
          gisLayers: action.data,
        });
      }
      return state;
    case geometryActionTypes.UPDATE_KML:
      if (state.kmlLayers.get(action.id) === undefined) {
        const kmlLayers = new Map(state.kmlLayers);
        kmlLayers.set(action.id, action.data);
        return Object.assign({}, state, {
          kmlLayers,
        });
      }
      return state;
    case geometryActionTypes.GEOMETRY_ERROR:
      const key = !action.as3d ? action.source : `${action.source}3d`;
      return Object.assign({}, state, {
        failedToGetData: {
          [key]: true,
        },
      });
    default:
      return state;
  }
};
