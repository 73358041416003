import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useApolloClient } from '@apollo/react-hooks';
import { debounce } from 'debounce';
// components
import { Spinner, SideBarMenu } from '@ems/client-design-system';
import { PageHeader, PowerBi } from 'src/components';
// reducers
import { useLanguageSelectors } from 'src/app/reducers';
// resolvers
import { fetchUserDashboards, fetchDashboardAccess } from 'src/@dashboard/resolvers/dataResolver';
// functions
import { useWindowSize } from 'src/app/functions/windowSize';
// constants
import { MOBILE_MODE } from 'src/constants';

export const ContentContainer = ({
  selectedReport = null,
}: {
  selectedReport?: null | { id: string; name: string };
}) => {
  const client = useApolloClient();
  // window height
  const { width, height } = useWindowSize();
  // TODO: Once all pages are mobile-friendly then use this logic to switch to mobile view when screen width smaller that the defined breakpoint
  // const mobileMode = isMobile || width < MOBILE_SCREEN_WIDTH;
  const mobileMode = isMobileOnly;
  const defaultAccess = {
    id: '',
    embedReportId: '',
    embedUrl: '',
    embedToken: '',
    expiration: '',
  };

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { dashboard: pageTitle },
      hints: { noReportsFound },
      errors: { contactSupportShort },
    },
  } = languageSelectors.getLanguage();

  const [loading, updateLoading] = useState<boolean>(false);
  const [userDashboards, setUserDashboards] = useState(); // dashboards for desktop mode or both
  const [powerBiAccess, setPowerBiAccess] = useState(defaultAccess);
  const isReportAvailable = Array.isArray(userDashboards) && userDashboards.length;
  const getReportHeight = (height: number) => {
    const iframeHeight = mobileMode ? 0 : 648;
    const paddingBottom = mobileMode ? 64 : 90;
    return `${height >= iframeHeight ? height - paddingBottom : iframeHeight - paddingBottom}px`;
  };

  const displayReport = debounce((id: string) => {
    if (!mobileMode && powerBiAccess.id === id) {
      return; // ignore and don't reload. Report already loaded
    }
    updateLoading(true);
    fetchDashboardAccess(client, id).then(({ embedReportId, embedUrl, embedToken, expiration }) => {
      setPowerBiAccess(defaultAccess);
      setPowerBiAccess({
        id,
        embedReportId,
        embedUrl,
        embedToken,
        expiration,
      });
    });
  }, 500);

  useEffect(() => {
    if (!mobileMode && !userDashboards) {
      fetchUserDashboards(client).then(data => {
        const selection = data.filter(({ mode }) => mode !== MOBILE_MODE);
        setUserDashboards(selection);
        if (selection && selection.length) {
          const [firstReport] = selection;
          displayReport(firstReport.id);
        }
      });
    }
  }, [mobileMode]);

  useEffect(() => {
    if (mobileMode && selectedReport) {
      displayReport(selectedReport.id);
    }
  }, [selectedReport]);

  if (!mobileMode && !userDashboards) {
    return <PageHeader title={pageTitle} />;
  }

  if (!mobileMode && !isReportAvailable) {
    return (
      <PageHeader title={pageTitle}>
        <p className="page-header-description">
          {noReportsFound}
          <br />
          {contactSupportShort}
        </p>
      </PageHeader>
    );
  }

  return (
    <div className="layout_content">
      {!mobileMode && (
        <div className="sidebar">
          <SideBarMenu
            content={userDashboards}
            onSelect={({ id }) => {
              displayReport(id);
            }}
          />
        </div>
      )}
      <div
        className="content"
        style={
          mobileMode
            ? { width, height: getReportHeight(height) }
            : { height: getReportHeight(height) }
        }>
        {loading && (
          <div className="loading-wrapper">
            <Spinner loading={loading} size="l" centered={true} />
          </div>
        )}
        {powerBiAccess.embedReportId && (
          <PowerBi
            id={powerBiAccess.embedReportId}
            embedUrl={powerBiAccess.embedUrl}
            accessToken={powerBiAccess.embedToken}
            loaded={() => {
              updateLoading(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
