import React, { FC, useState, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'debounce';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
// common components
import { StyledMap, MapControl, RulerTool } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
// functions
import { useMapSettings } from 'src/app/functions/mapSettings';
import { useMapRef, useMapWhenReady, useMapProps, useMapConfig } from 'src/app/functions/map';
import {
  flyTo,
  fitPointsInMap,
  useHoverOnMapElement,
  useHoveredPointData,
  useClickOnMapElement,
  useClickedPointData,
  offsetCoordinatesByPixels,
} from 'src/utils';
import { IMapProps } from 'src/@noiseEvents/interfaces';
import {
  useInAirPlayback,
  useNoiseMonitorPlayback,
  useStaticFlightDisplay,
  useStaticDbDisplay,
} from 'src/utils/playback';
import { NoisePopup } from 'src/components/NoisePopup';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { AMSLPopup } from 'src/components';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
// constants
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { MAPTYPES, MARKER_OFFSET } from 'src/constants';
import { IRulerCoordinateObject } from 'src/utils/interfaces';

export const MapContainer: FC<IMapProps> = ({
  inAirData,
  noiseMonitors,
  isPlaybackMode = false,
  currentTime = 0,
  noiseData,
  processedNoiseData,
  markedTime,
  pointData,
  isPlaybackRunning,
}) => {
  // get map props from config
  const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });
  // used for taking screenshot of map
  const captureRef = useRef(null);
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();
  // get field labels from language selectors
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { operations: opsFields },
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: { backToCenter: backToCenterLabel },
    },
    abbreviations: { operations: opsAbbreviation },
  } = languageSelectors.getLanguage();

  const labels = Object.assign(opsFields, opsAbbreviation);

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);

  // Hover / Click functionality
  const requiredMouseLayers = useMemo(
    () => ['static-points', 'static-lines', 'points', 'lines'],
    []
  );

  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  const mouseFilters = useMemo(() => ['any', true], []);

  const { hoveredElement, handleHover } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: requiredMouseLayers,
    tracksFilter: mouseFilters,
    restrictZoomLevels: false,
    layerPrefix: '',
    mapType: MAPTYPES.NOISEEVENTSUMMARY,
  });

  const { handleClick, clickedElement } = useClickOnMapElement(
    viewport,
    mapApis,
    requiredMouseLayers,
    mouseFilters,
    false,
    ''
  );

  const [hoveredPointData, setHoveredPointData] = useState<any>({
    amsl: null,
    time: null,
    longitude: null,
    latitude: null,
    showPointData: false,
    flightId: null,
  });
  const [clickedPointData, setClickedPointData] = useState<any>({
    amsl: null,
    time: null,
    longitude: null,
    latitude: null,
    showPointData: false,
    flightId: null,
  });

  const matchedHoverOperation = useMemo(() => {
    if (hoveredElement) {
      return inAirData.find(p => p.id === hoveredElement.properties.id);
    }

    return null;
  }, [hoveredElement]);

  useHoveredPointData({
    mapApis,
    operation: matchedHoverOperation,
    hoveredElement,
    profileHoverTime: null,
    setSelectedPointData: setHoveredPointData,
    isPlaybackMode,
    isPlaybackRunning,
    userHomeLocation: null,
    mapProjectionString: null,
  });

  const matchedClickOperation = useMemo(() => {
    if (clickedElement) {
      return inAirData.find(p => p.id === clickedElement.properties.id);
    }

    return null;
  }, [clickedElement]);

  useClickedPointData({
    operation: matchedClickOperation,
    clickedElement,
    setClickedPointData,
    userHomeLocation: null,
    mapProjectionString: null,
  });

  // restrict map pan
  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // resets map view
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  const highlightId = noiseData.length ? noiseData[0].operationId : -1;
  const highlightIds: number[] = useMemo(() => {
    const ids: number[] = [];
    noiseData.map(data => {
      ids.push(data.operationId);
    });
    return ids;
  }, [noiseData]);
  const selectedMonitor = useNoiseMonitorPlayback({
    mapApis,
    noiseMonitors,
    noiseData,
    processedNoiseData,
    currentTime,
    isPlaybackMode,
  });
  useStaticFlightDisplay(mapApis, pointData, isPlaybackMode, true, highlightIds, markedTime);
  const staticMonitors = useStaticDbDisplay(mapApis, noiseMonitors, noiseData, isPlaybackMode);
  useInAirPlayback(mapApis, inAirData, currentTime, isPlaybackMode, highlightId);

  // make map zoom to fit the points selected
  useEffect(() => {
    if (mapApis) {
      const selectedMonitorCoords: any = [];
      staticMonitors.map((monitor: any) => {
        selectedMonitorCoords.push({
          longitude: monitor.geometry.coordinates[0],
          latitude: monitor.geometry.coordinates[1],
        });
      });

      if (!isPlaybackMode) {
        fitPointsInMap(mapApis, viewport, setViewport, selectedMonitorCoords);
      }
    }
  }, [mapApis, staticMonitors]);

  useEffect(() => {
    if (mapApis) {
      // Reorder layers if needed
      if (mapApis.getLayer('noiseMonitors')) {
        mapApis.moveLayer('noiseMonitors');
      }
      if (mapApis.getLayer('static-monitors')) {
        mapApis.moveLayer('static-monitors');
      }
      if (mapApis.getLayer('selectedMonitors')) {
        mapApis.moveLayer('selectedMonitors');
      }
      if (mapApis.getLayer('static-points')) {
        mapApis.moveLayer('static-points');
      }
      if (mapApis.getLayer('static-lines')) {
        mapApis.moveLayer('static-lines');
      }
      if (mapApis.getLayer('lines')) {
        mapApis.moveLayer('lines');
      }
      if (mapApis.getLayer('points')) {
        mapApis.moveLayer('points');
      }
    }
  }, [inAirData, mapApis, noiseData]);

  const noisePopups: JSX.Element[] = [];
  if (mapApis && (selectedMonitor.length > 0 || staticMonitors.length > 0)) {
    const mapFn = (monitor: any) => {
      noisePopups.push(
        <NoisePopup
          key={`popup_${monitor.properties.id}`}
          zoomLevel={mapApis.getZoom()}
          monitorData={monitor}
        />
      );
    };
    if (isPlaybackMode) {
      selectedMonitor.map(mapFn);
    } else {
      staticMonitors.map(mapFn);
    }
  }
  // Ruler Tool

  const units = configSelectors.getUnits();
  const [isRulerEnabled, setIsRulerEnabled] = useState<boolean>(false);
  const [rulerCoordinates, updateRulerCoordinates] = useState<IRulerCoordinateObject>({
    start: { longitude: 0, latitude: 0 },
    end: { longitude: 0, latitude: 0 },
  });

  const toggleRuler = () => {
    if (isRulerEnabled) {
      setIsRulerEnabled(false);
      mapApis.isRulerEnabled = false;
    } else {
      setIsRulerEnabled(true);
      mapApis.isRulerEnabled = true;

      const { longitude, latitude } = viewport;
      const startMarkerCoordinates = offsetCoordinatesByPixels(
        [longitude, latitude],
        MARKER_OFFSET,
        mapApis
      );

      updateRulerCoordinates({
        start: { longitude: startMarkerCoordinates.lng, latitude: startMarkerCoordinates.lat },
        end: { longitude, latitude },
      });
    }
  };

  const rulerCoordinatesChanged = (rulerPoint: string, [longitude, latitude]: number[]) => {
    if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
      updateRulerCoordinates({
        ...rulerCoordinates,
        [rulerPoint]: { longitude, latitude },
      });
    }
  };

  return (
    <div ref={captureRef} className="map">
      <StyledMap
        onLoad={() => mapLoaded()}
        viewport={viewport}
        mapStyle={mapStyle}
        onViewportChange={viewport => {
          viewport.maxPitch = 0;
          onViewportChange(viewport);
        }}
        {...mapProps}
        ref={mapRef}
        onMouseMove={undefined}
        onClick={handleClick}
        onHover={debounce(handleHover, 5)}
        transformRequest={
          mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
        }>
        {noisePopups}
        {enableMapControls && (
          <MapControl
            rulerControl={{
              isRulerEnabled,
              toggleRuler,
            }}
            translationData={{
              home: backToCenterLabel,
              mapSettings: mapSettingsTitle,
            }}
            navigationControl={{
              showCompass: true,
              showHome: true,
              showSearch: false,
              showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
            }}
            resetView={resetView}
            mapSettings={{
              update: () => {
                applyBackground();
                applyLayers();
              },
              reset: () => {
                resetBackground();
                resetLayers();
              },
              content: (
                <MapSettingsContainer
                  config={{
                    background: mapStyle,
                    layers: layersDisplayed,
                  }}
                  updateEvent={({ selectedBackground, selectedLayers }) => {
                    if (typeof selectedBackground !== 'undefined') {
                      storeSelectedBackground(selectedBackground);
                    }
                    if (typeof selectedLayers !== 'undefined') {
                      storeSelectedLayers(selectedLayers);
                    }
                  }}
                />
              ),
            }}
          />
        )}

        {clickedElement && (
          <AMSLPopup
            labels={labels}
            pointData={clickedPointData}
            anchor="bottom-right"
            mapApis={mapApis}
            draggable
          />
        )}
        {hoveredElement && (
          <AMSLPopup
            labels={labels}
            pointData={hoveredPointData}
            mapApis={mapApis}
            draggable={false}
          />
        )}
        <RulerTool
          units={units.distance}
          coordinates={rulerCoordinates}
          isRulerEnabled={isRulerEnabled}
          mapProjection={mapProjectionString}
          handleDragEvent={rulerCoordinatesChanged}
          mapApis={mapApis}
        />
        <MapReferenceLayers mapApis={mapApis} mapStyle={mapStyle} layers={layersDisplayed} />
      </StyledMap>
    </div>
  );
};
