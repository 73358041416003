export const airlineMappingsQuery = 'airlineMappingsByScenarioIds';
export const airlineMappingsSchema = `{
  airline
  aircraftType
  numberOfAircraft
  mappedLTOEngine
  mappedLTOAircraft
  mappedEnrouteEngine
  mappedEnrouteAircraft
  scenarioId
  id
}`;
export const ltoMappingsQuery = 'ltoAircraftEngineMappings';
export const ltoMappingsSchema = `{ 
  aircraftType
  engine
 }`;
export const enrouteMappingsQuery = 'enrouteAircraftEngineMappings';
export const enrouteMappingsSchema = `{ 
  aircraftType
  engine
 }`;
export const aircraftFlightsQuery = 'aircraftFlightsByTimeRange';
export const aircraftFlightsSchema = `{ 
  aircraftType
  airline
  numberOfFlights
  engine
  }`;
export const aircraftMappingsQuery = 'aircraftMappingsByScenarioIds';
export const aircraftMappingsSchema = `{
  aircraftType
  id
  mappedLTOEngine
  mappedLTOAircraft
  mappedEnrouteEngine
  mappedEnrouteAircraft
  engine
  scenarioId
}`;
export const airlineLoadFactorsQuery = 'airlineLoadFactorByScenarioIds';
export const airlineLoadFactorsSchema = `{
  id
  airline 
  aircraftType
  origin
  destination
  startTime
  endTime
  dayOfWeek
  loadFactor
  scenarioId
}`;
export const phaseDefaultsQuery = 'phaseDefaultsByScenarioIds';
export const phaseDefaultsSchema = `{
    id
    scenarioId
    airport
    runway
    durationTaxiOut
    durationTakeoff
    durationClimb
    durationApproach
    durationTaxiIn
}`;
export const aircraftFuelMappingsQuery = 'aircraftFuelMappingsByScenarioIds';
export const aircraftFuelMappingsSchema = `{
  airline
  aircraftType
  engine
  factorCO2
  percentage
  comment
  scenarioId
  id
}`;
export const airportsQuery = 'airports';
export const airportsSchema = `{
  id
  icaoCode
  runways {
    name
  }
}`;