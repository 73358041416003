import React, { FC, useContext, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// actions
import { getData } from 'src/@airtrak/actions';
// selectors
import { useConfigSelectors, useLanguageSelectors, useScenariosSelectors } from 'src/app/reducers';
import {
  useBreakdownSelector,
  useDataSelectors,
  useViewSelector,
  useFilterSelectors,
} from 'src/@airtrak/reducers';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
// containers
import { TableContainer } from 'src/@airtrak/containers';
// components
import {
  Spinner,
  AirQualityCombinedGraph,
  AirQualityPieGraph,
  AirQualityLineGraph,
} from '@ems/client-design-system';
// providers
import { AirtrakDispatchContext } from 'src/@airtrak/providers/AirtrakStateProvider';
// functions
import {
  getFilterString,
  formatDataForGraphs,
  getExportFileName,
  getCombinedColumnName,
} from 'src/@airtrak/functions';
// constants
import { BAR, LINE, PIE, TABLE, TMA_EFFICIENCY, UNIT_KILOMETER } from 'src/constants';
import { useWindowSize } from 'src/app/functions/windowSize';
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
import { loadScenarioReprocessStatus } from 'src/app/actions';

export const ContentContainer: FC = () => {
  const dispatcher = useContext(AirtrakDispatchContext);
  const appDispatcher = useContext(GlobalDispatchContext);
  const viewSelector = useViewSelector();
  let view = viewSelector.getView();
  const dataSelectors = useDataSelectors();
  const data = dataSelectors.getData();
  const unitLabel = dataSelectors.getCarbonEmissionDisplayUnit();
  const isLineChartExport = dataSelectors.getIsLinechartExport();
  const isPieChartExport = dataSelectors.getIsPiechartExport();
  const isBarChartExport = dataSelectors.getIsBarchartExport();
  const rowGrouping = dataSelectors.getRowGrouping();
  const movements = dataSelectors.getMovements();
  const client = useApolloClient();
  const breakdownSelector = useBreakdownSelector();
  const breakdown = breakdownSelector.getBreakdown();
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();
  const filterSelector = useFilterSelectors();
  const filters = filterSelector.getRegularFilters();
  const metric = dataSelectors.getMetric();
  const scenariosSelector = useScenariosSelectors();
  const scenario = scenariosSelector.getActiveScenario();
  const scenarioId = scenario !== null ? scenario.id : 1;
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();  
  // window width, height
  const { width, height } = useWindowSize();
  const filterString = getFilterString(filters, breakdown, metric, scenarioId, width);
  const { from, to } = dateRangeStore.getDatesStrings();
  const fileName = getExportFileName(rowGrouping, view);
  const loading = dataSelectors.getIfLoading();

  if (rowGrouping.length === 0) {
    view = TABLE;
  }

  const {
    fields: { airtrak },
    components: {
      labels: { optimumDistance },
      lists: { metrics },
    },
  } = translationData;

  useEffect(() => {
    getData(client, filterString, metric, units.carbonEmissions, dispatcher);
    loadScenarioReprocessStatus(client, appDispatcher, scenario.id);
  }, [filterString, from, to, metric]);

  const getYAxisTitle = () => {
    return `${metrics[metric]} (${unitLabel})`;
  };

  const combinedColumnName = getCombinedColumnName(metric, airtrak, filters, unitLabel);

  const getSpinnerComponent = () => {
    return <div className="spinner-loading"> <Spinner loading={true} size="l" centered={true} /> </div>;
  };

  const getViewComponent = () => {
    switch (view) {
      case LINE:
        return (
          <div className="airtrak-graph-container">
            <AirQualityLineGraph
              translationData={{
                yAxisTitle: getYAxisTitle(),
                tmaSeriesName: optimumDistance,
              }}
              data={formatDataForGraphs(data, airtrak, breakdown, combinedColumnName)}
              metric={`(${unitLabel})`}
              xAxisLabels={rowGrouping}
              isExport={isLineChartExport}
              fileName={fileName}
              showTma={metric === TMA_EFFICIENCY}
              optimumTma={scenario.defaultOptimumTMAApproachDistance}
              tmaMetric={`(${UNIT_KILOMETER})`}
              imageWidth={width}
              imageHeight={height}
            />
          </div>
        );
      case BAR:
        return (
          <div className="airtrak-graph-container">
            <AirQualityCombinedGraph
              translationData={{
                yAxisTitle: getYAxisTitle(),
                secondaryYAxisTitle: airtrak.movements,
              }}
              data={formatDataForGraphs(data, airtrak, breakdown, combinedColumnName)}
              metric={`(${unitLabel})`}
              xAxisLabels={rowGrouping}
              secondaryData={movements}
              isExport={isBarChartExport}
              fileName={fileName}
              imageWidth={width}
              imageHeight={height}
            />
          </div>
        );
      case PIE:
        return (
          <div className="airtrak-graph-container">
            <AirQualityPieGraph
              translationData={{ yAxisTitle: getYAxisTitle() }}
              data={formatDataForGraphs(data, airtrak, breakdown, combinedColumnName)}
              metric={`(${unitLabel})`}
              isExport={isPieChartExport}
              fileName={fileName}
              imageWidth={width}
              imageHeight={height}
            />
          </div>
        );
      case TABLE:
        return <TableContainer />;
      default:
        return <TableContainer />;
    }
  };

  return loading ?  getSpinnerComponent() : getViewComponent();
};
