import { useState, useEffect, useRef, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { filterStore } from 'src/@infringements/stores/filterStore';
import { resetAndFetchData } from 'src/@infringements/actions/infringementsActions';
import {
  ITableFilterSelectedItem,
  ITableFilterItem,
  ITimeFilters,
} from 'src/@infringements/interfaces';
import { InfringementDispatchContext } from 'src/@infringements/providers/InfringementsStateProvider';
import { useSortSelectors } from 'src/@infringements/reducers';
// constants
import { NULL_VALUE } from 'src/constants';

export const convertDataToFilterForm = (data: string[], iconType: string = '') => {
  // Find and add an extra classname if required based on icon type
  const className =
    {
      ac: 'filter-icon-ac',
    }[iconType] || '';

  return data && data.length
    ? data.map(item => ({
        key: item,
        label: item,
        icon: iconType ? `${iconType}-${item.toLowerCase()}` : undefined,
        className,
      }))
    : [];
};

export const useLatestFilter = (translationData: any = {}) => {
  const [selectedItems, setSelectedItems] = useState(filterStore.getAllSelectedItems());
  const {
    extraFilterValues: { nullValue },
  } = translationData;
  useEffect(() => {
    const handleFilterUpdate = () => {
      const selectedValues = filterStore.getAllSelectedItems(); // fix null values for labels
      Object.keys(selectedValues).forEach(category => {
        selectedValues[category] = selectedValues[category].map(item =>
          item.label === NULL_VALUE ? { ...item, ...{ label: nullValue } } : item
        );
      });
      setSelectedItems(selectedValues);
    };

    filterStore.on('change', handleFilterUpdate);
    return () => {
      filterStore.removeListener('change', handleFilterUpdate);
    };
  }, []);

  return selectedItems;
};

export const useTimeFilter = (resultSize: number, showCandidates: boolean, atcView?: boolean) => {
  const client = useApolloClient();
  const dispatcher = useContext(InfringementDispatchContext);
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const [timeFilter, setTimeFilter] = useState(filterStore.getTimeFilterValues());
  const savedFromTime = useRef<string>('');
  const savedToTime = useRef<string>('');
  const candidatesRef = useRef<boolean>(showCandidates);
  useEffect(() => {
    candidatesRef.current = showCandidates;
  }, [showCandidates]);

  useEffect(() => {
    const handleTimeFilterUpdate = () => {
      const filterTimeValues = filterStore.getTimeFilterValues();
      setTimeFilter(filterStore.getTimeFilterValues());

      const isComplete =
        isCompletedTimeFormat(filterTimeValues.from) && isCompletedTimeFormat(filterTimeValues.to);
      const isEmpty = !filterTimeValues.from && !filterTimeValues.to;

      if (
        (isComplete || isEmpty) &&
        (filterTimeValues.from !== savedFromTime.current ||
          filterTimeValues.to !== savedToTime.current)
      ) {
        resetAndFetchData(client, dispatcher, {
          resultSize,
          sortString,
          showCandidates: candidatesRef.current,
          atcView,
        });
        savedFromTime.current = filterTimeValues.from;
        savedToTime.current = filterTimeValues.to;
      }
    };

    filterStore.on('change', handleTimeFilterUpdate);
    return () => {
      filterStore.removeListener('change', handleTimeFilterUpdate);
    };
  }, []);

  return timeFilter;
};

// Completed time format is HH:mm OR empty string
const isCompletedTimeFormat = (time: string) => {
  const timeRegex = /^\d{2}\:\d{2}$/;

  return timeRegex.test(time);
};

// Can be considered empty if the sum of all filter array lengths === 0
// Time filter values must also be blank
export const getIfFiltersEmpty = (filters: ITableFilterSelectedItem, timeFilter: ITimeFilters) => {
  const totalFilterLength = Object.values(filters).reduce(
    (accumulator, currentValue) => accumulator + currentValue.length,
    0
  );

  const timeFiltersEmpty = timeFilter.from === '' && timeFilter.to === '';

  return totalFilterLength === 0 && timeFiltersEmpty;
};

export const translateLabels = (items: ITableFilterItem[], translationList) => {
  if (items === undefined) {
    return items;
  }

  return items.map(item => {
    return {
      ...item,
      label:
        typeof translationList[item.label] !== 'undefined'
          ? translationList[item.label]
          : item.label,
    };
  });
};
