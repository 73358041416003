import React, { FC, useState } from 'react';
import {
  Checkbox,
  Dropdown,
  TextArea,
  Card,
  Overlay,
  Button,
  Icons,
} from '@ems/client-design-system';
import {
  getComplaintsFormikProps,
  fieldFormikProps,
  convertDatesToLocale,
  convertDateFromISOToLocale,
  getDisturbanceTypesPlaceholder,
} from 'src/@complaints/functions';
import { DateTimeComponent } from 'src/components';
// selectors
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
// interfaces
import { ICreateComplaint } from 'src/@complaints/interfaces';

export const ComplaintForm: FC<ICreateComplaint> = ({
  formikProps,
  columns,
  dateChange,
  recordAlreadyExist,
  twelveHourFormat,
  privacyEnabled,
  reasonItems,
  disturbanceTypes = [],
  reason,
  setReason,
  isAnonymous,
  activePrivacyAgreement,
}) => {
  const { complaintProps, responseRequiredProps, privacyEnabledProps } = getComplaintsFormikProps(
    columns
  );
  const configSelectors = useConfigSelectors();
  const {
    globals: { timeZone },
  } = configSelectors.getConfig();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { termsAndConditions: overlayHeading },
    },
  } = languageSelectors.getLanguage();

  const { localeTime } = convertDatesToLocale(null, null, timeZone);

  // date format to locale
  const maxContactDate = convertDateFromISOToLocale(localeTime);
  const maxIncidentDate = convertDateFromISOToLocale(formikProps.values.contactTime);

  // state
  const [overlay, setoverlay] = useState<boolean>(false);

  const handleTermsAndConditionClick = event => {
    setoverlay(true);
    event.preventDefault();
  };

  const privacyAgreementLabel = () => {
    if (recordAlreadyExist) {
      return columns.privacyEnabled;
    }
    return (
      <span>
        <span>{columns.agreedTo}</span>
        <a className="complaints-terms-and-conditions" onClick={handleTermsAndConditionClick}>
          {columns.termsAndConditions}
        </a>
      </span>
    );
  };

  return (
    <Card className="inquiry-card">
      <Overlay
        openState={overlay}
        onClose={() => setoverlay(false)}
        classes={['overlay--complaints-terms-conditions']}>
        <div className="overlay_header">
          <h3
            style={{
              fontWeight: 'normal',
            }}>
            {overlayHeading}
          </h3>
          <Button
            size="s"
            style="subtle"
            iconOnly={true}
            onClick={() => setoverlay(false)}
            aria-label="Close modal"
            className="overlay_close"
            leftIcon={
              <Icons
                iconName="ic-ui-cancel"
                size={16}
                style={{ cursor: 'pointer', fill: '#5a6872' }}
              />
            }
          />
        </div>
        <div
          className="overlay_content"
          dangerouslySetInnerHTML={{
            __html: activePrivacyAgreement ? activePrivacyAgreement.agreement : '',
          }}
        />
      </Overlay>
      <div className="row dropdown">
        <div className="complaints-datetime">
          <span className="complaints-datetime__label">{columns.contactTime}</span>
          <DateTimeComponent
            className="col-md-4"
            value={formikProps.values.contactTime}
            onChange={dateChange}
            setFieldValue={formikProps}
            fieldName="contactTime"
            maxDate={maxContactDate}
            twelveHourFormat={twelveHourFormat}
          />
        </div>
        <div className="complaints-datetime">
          <span className="complaints-datetime__label">{columns.incidentTime}</span>
          <DateTimeComponent
            className="col-md-4"
            value={formikProps.values.incidentTime}
            onChange={dateChange}
            setFieldValue={formikProps}
            fieldName="incidentTime"
            maxDate={maxIncidentDate}
            twelveHourFormat={twelveHourFormat}
          />
        </div>
      </div>
      <div className="dropdown dropdown--with-margin">
        <Dropdown
          label={columns.reason}
          placeholderValue={getDisturbanceTypesPlaceholder(disturbanceTypes)}
          searchItems={reasonItems}
          isNullable={false}
          selectedItem={reason}
          updateSelection={item => setReason(item)}
        />
      </div>
      <TextArea
        {...{
          className: 'col-md-9',
          ...fieldFormikProps('complaint', formikProps),
          ...complaintProps,
        }}
      />
      <Checkbox
        {...{
          checked: formikProps.values.responseRequired,
          ...fieldFormikProps('responseRequired', formikProps, isAnonymous),
          ...responseRequiredProps,
        }}
      />
      {privacyEnabled && (
        <Checkbox
          {...{
            checked: formikProps.values.privacyAgreementId
              ? true
              : formikProps.values.privacyEnabled,
            ...fieldFormikProps('privacyEnabled', formikProps),
            ...privacyEnabledProps,
            label: privacyAgreementLabel(),
            disabled: recordAlreadyExist ? true : false,
          }}
        />
      )}
    </Card>
  );
};
