// @ts-ignore
import { appActionTypes } from 'src/app/newActionTypes';
import { filtersInitialValues } from 'src/app/reducers/filtersReducer';
import { fetchFilterData } from 'src/app/resolvers/filtersResolver';
import { appAvailableFilters } from 'src/config';
import {
  DATA_FILTER_DIR,
  PRODUCT_PANY,
  PRODUCT_SZG,
  PRODUCT_SZG_ANOMSX,
  PRODUCT_LHR,
  PRODUCT_LHR_ANOMSX,
  PRODUCT_LHR_PT,
  PRODUCT_BNE,
  PRODUCT_BNE_ANOMSX,
  PRODUCT_KEF,
} from 'src/constants';
import { getDeployedProductId } from 'src/utils';

const getFilterFileName = (fileName?: string) => {
  if (process.env.NODE_ENV === 'development') {
    const productId = getDeployedProductId();
    if (productId) {
      if (productId.includes(PRODUCT_PANY)) {
        return 'pany';
      } else if (productId === PRODUCT_SZG || productId.includes(PRODUCT_SZG_ANOMSX)) {
        return 'szg';
      } else if (productId === PRODUCT_LHR || productId.includes(PRODUCT_LHR_ANOMSX)) {
        return 'lhr';
      } else if (productId.includes(PRODUCT_LHR_PT)) {
        return 'lhr.pt';
      } else if (productId === PRODUCT_BNE || productId.includes(PRODUCT_BNE_ANOMSX)) {
        return 'bne';
      } else if (productId === PRODUCT_KEF || productId.includes(PRODUCT_KEF)) {
        return 'kef';
      }
    } else {
      return 'default';
    }
  } else {
    if (typeof fileName !== 'undefined' && fileName) {
      return fileName;
    } else {
      return 'default';
    }
  }
};

export const loadFilters = (dispatcher, config): void => {
  const {
    globals: {
      data: {
        filters: { infringements, noiseEvents, operations, reports, airtrak, complaints, settings },
      },
    },
  } = config;

  const appFilters = { ...filtersInitialValues };

  const fileNames = {
    infringements: getFilterFileName(infringements),
    noiseEvents: getFilterFileName(noiseEvents),
    operations: getFilterFileName(operations),
    airtrak: getFilterFileName(airtrak),
    complaints: getFilterFileName(complaints),
    settings: getFilterFileName(settings),
    reports: reports ? reports : 'default',
  };

  const promises: any[] = [];
  let filtersToLoad = "Filters to load: \n";
  Object.keys(fileNames).forEach((path: string) => {
    if (
      fileNames[path] === 'default' ||
      appAvailableFilters.indexOf(`${DATA_FILTER_DIR}${path}/${fileNames[path]}`) !== -1
    ) {
      promises.push(fetchFilterData(path, fileNames[path], appFilters));
      filtersToLoad += `\t ${path}: ${fileNames[path]}\n`;
    } else {
      promises.push(fetchFilterData(path, 'default', appFilters));
      filtersToLoad += `\t ${path}: using default ('${fileNames[path]}' specified, but not found)\n`;
    }
  });

  console.log(filtersToLoad);

  if (promises.length > 0) {
    Promise.all(promises)
      .then(() => {
        dispatcher({ type: appActionTypes.UPDATE_FILTERS, payload: appFilters });
      })
      .catch(() => {
        dispatcher({
          type: appActionTypes.FILTERS_ERROR,
          payload: appFilters,
        });
      });
  }
};
