import { IFilterTypes, IFilterItem, IRangeFilters } from 'src/@operations/props';

export const convertDataToFilterForm = (data: string[], iconType: string = '') => {
  // Find and add an extra classname if required based on icon type
  const className =
    {
      ac: 'filter-icon-ac',
      co: 'filter-icon-co',
    }[iconType] || '';

  return data && data.length
    ? data.map(item => ({
        key: item,
        label: item,
        icon: iconType ? `${iconType}-${item.toLowerCase()}` : undefined,
        className,
      }))
    : [];
};

// Can be considered empty if the sum of all filter array lengths === 0
// Time filter values must also be blank
export const getIfFiltersEmpty = (filters: IFilterTypes, rangeFilters: IRangeFilters[]) => {
  const totalFilterLength = Object.values(filters).reduce(
    (accumulator, currentValue) => accumulator + currentValue.length,
    0
  );
  let timeFiltersEmpty = true;
  rangeFilters.forEach(filter => {
    if (filter.from !== '' || filter.to !== '') {
      timeFiltersEmpty = false;
    }
  });
  return totalFilterLength === 0 && timeFiltersEmpty;
};

export const translateLabels = (items: IFilterItem[], translationList) => {
  if (items === undefined) {
    return items;
  }

  items.map(item => {
    const translatedLabel = translationList[item.label];
    if (translatedLabel !== undefined) {
      item.label = translatedLabel;
    }
  });

  return items;
};
