// functions
import { assignDropdown, assignTextInput } from 'src/@settings/functions';
import { AIRCRAT_MODEL_SEPERATOR } from 'src/constants';
// interfaces
import { IAircraftFuelMapping } from '../interfaces';

export const formatFuelTypesData = (
  data,
  translationData,
  ltoOptions,
  filterData,
  updateFuelType,
  percentageOfFlights,
  co2Factor
) => {
  const formattedFuelTypes = new Array();
  let index = 0;
  for (const fuelType of data) {
    const fuelTypeObj = { ...fuelType };
    fuelTypeObj.airline = assignDropdown(
      index,
      fuelTypeObj,
      filterData.airlines,
      translationData,
      'airline',
      fuelTypeObj.airline,
      updateFuelType
    );
    fuelTypeObj.aircraftEngine = assignDropdown(
      index,
      fuelTypeObj,
      ltoOptions,
      translationData,
      'aircraftEngine',
      fuelTypeObj.aircraftEngine,
      updateFuelType
    );
    fuelTypeObj.factorCO2 = assignTextInput(
      index,
      translationData,
      'factorCO2',
      fuelTypeObj.factorCO2,
      updateFuelType,
      co2Factor
    );
    fuelTypeObj.percentage = assignTextInput(
      index,
      translationData,
      'percentage',
      fuelTypeObj.percentage,
      updateFuelType,
      percentageOfFlights
    );
    fuelTypeObj.comment = assignTextInput(
      index,
      translationData,
      'comment',
      fuelTypeObj.comment,
      updateFuelType,
      ''
    );
    formattedFuelTypes.push(fuelTypeObj);
    index = index + 1;
  }
  return formattedFuelTypes;
};

export const getFormattedFuelTypesMutationRecord = mapping => {
    const item: IAircraftFuelMapping = {
      id: mapping.id,
      scenarioId: mapping.scenarioId,
      airline: mapping.airline,
      aircraftType: mapping.aircraftEngine.split(AIRCRAT_MODEL_SEPERATOR)[0],
      factorCO2: parseFloat(mapping.factorCO2),
      percentage: parseFloat(mapping.percentage),
      comment: mapping.comment,
      engine: mapping.aircraftEngine.split(AIRCRAT_MODEL_SEPERATOR)[1],
    };
    return item;
};