import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
// selectors
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
// components
import { BlockHeader, DescriptionList, Card } from '@ems/client-design-system';
// context
import { UPDATE_OPERATION_DETAILS, updateOperationDetailsBuilder } from 'src/@operations/mutations';
// functions
import { formatFromISO, convertObjectKeys, useEffectAfterMount } from 'src/utils';
import { usePermissions } from 'src/app/functions/permissions';
import {
  addDisplayField,
  canUpdateField,
  renderOperationTagPills,
} from 'src/@operations/functions/operations';
// utils
import { InlineEditText, InlineEditDropDown, InlineEditDropDownWithIcon } from 'src/utils';
// constants
import {
  FIELD_TWENTYFOUR_HOURS_FORMAT,
  NULL_VALUE,
  FORMATION_COUNT_EDIT_VALUES,
} from 'src/constants';
// feature toggles
import { FORMATION_COUNT, OPERATION_TAGS } from 'src/app/featureToggles';

export const OperationPanelContainer = ({
  operation: operationData,
  operationFilterData,
  updatePageTitle = (acid: string) => {},
}) => {
  const [operation, updateOperationObject] = useState(operationData);

  // Configuration
  const configSelectors = useConfigSelectors();
  const FEATURE_FLAG_OPERATION_TAGS = configSelectors.isFeatureAvailable(OPERATION_TAGS);
  const {
    globals: { '12HourFormat': twelveHourFormat },
    operationDetails: { noiseClass: noiseClassSwitch },
    formValidations: { operation: operationValidation },
    operations: {
      filter: { nullable },
    },
  } = configSelectors.getConfig();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const units = configSelectors.getUnits();
  const readOnlyFields = configSelectors.getOperationReadOnlyFields();
  // Translation
  const languageSelectors = useLanguageSelectors();

  interface ILocalizationStrings {
    components: {
      labels: {
        entervalue: string
      },
      hints: {
        invalidEntry: string
      },
      operationDetails: {
        title: string,
        noiseClass: string
      },
      lists: {
        [listKey: string]: string[]
      },
    },
    fields: {
      operations: {
        [operationKey: string]: string
      }
    }
  }

  const {
    components: {
      labels: { entervalue: entervalueText },
      hints: { invalidEntry: invalidEntryText },
      operationDetails: { title, noiseClass },
      lists: {
        aircraftCategories,
        operatorCategories,
        operationTypes,
        correlatedCategories,
        extraFilterValues,
        shortenedCorrelated,
      },
    },
    fields: {
      operations: {
        operationType,
        flightNumber,
        tailNumber,
        beaconCode,
        aircraftCategory,
        aircraftType,
        airline,
        operatorCategory,
        airportId,
        remoteAirportId,
        runwayName,
        pathName,
        aircraftCount,
        operationTags,
      },
    },
  } = languageSelectors.getLanguage() as ILocalizationStrings;

  const { canUpdate } = usePermissions('Operation');
  // feature toggles
  const FEATURE_FLAG_FORMATION_COUNT = configSelectors.isFeatureAvailable(FORMATION_COUNT);

  const appliedFilters = {
    aircraftCategories: [],
    operatorCategories: [],
    aircraftTypes: [],
    airlines: [],
    airportIds: [],
    correlated: [],
    operationTypes: [],
    remoteAirportIds: [],
    runwayNames: [],
  };
  const displayFields = addDisplayField(
    operation,
    appliedFilters,
    units,
    twelveHourFormat,
    selectedTrackTheme
  );

  const [translationDataList, setTranslationDataList] = useState<object[]>([]);
  useEffect(() => {
    setTranslationDataList(
      convertObjectKeys({
        ...correlatedCategories,
        ...shortenedCorrelated,
        ...aircraftCategories,
        ...operatorCategories,
        ...operationTypes,
        ...extraFilterValues,
      })
    );
  }, [aircraftCategories, operatorCategories]);

  const filterDataTemplate = {
    operationTypes: [],
    runwayNames: [],
    aircraftTypes: [],
    airportIds: [],
    remoteAirportIds: [],
    airlines: [],
    aircraftCategories: [],
    operatorCategories: [],
    aircraftCounts: FORMATION_COUNT_EDIT_VALUES,
  };
  const [filterData, updateFilterData] = useState(filterDataTemplate);
  useEffect(() => {
    const filtersObject = { ...filterDataTemplate };
    Object.keys(operationFilterData).forEach(category => {
      const originalData = [...operationFilterData[category]];
      if (nullable && nullable.includes(category)) {
        filtersObject[category] = [NULL_VALUE, ...originalData];
      } else {
        filtersObject[category] = [...originalData];
      }
    });
    updateFilterData(filtersObject);
  }, [operationFilterData]);

  const [mutationData, setMutationData] = useState<any>({
    mutation: UPDATE_OPERATION_DETAILS,
    item: {},
  });
  const [updateOperationCall] = useMutation(mutationData.mutation, {
    update(cache, { data: { updateOperation } }) {
      if (updatePageTitle && updateOperation && typeof updateOperation.acid !== 'undefined') {
        updatePageTitle(updateOperation.acid);
      }
      updateOperationObject(
        Object.assign({}, operation, {
          ...updateOperation,
        })
      );
    },
  });

  useEffectAfterMount(() => {
    const { id, updateDataObject } = mutationData.item;
    updateOperationCall({
      variables: { id, ...updateDataObject },
    });
  }, [mutationData]);

  let loading = false;
  const onUpdateSelection = (id: number, updateDataObject: object) => {
    if (!loading) {
      loading = true;
      updateOperationObject(
        Object.assign({}, operation, {
          ...updateDataObject,
        })
      );
      const key = Object.keys(updateDataObject)[0];
      const clonedDataObject = { ...updateDataObject };
      const ref = 'aircraftCount';
      if (clonedDataObject[ref]) {
        clonedDataObject[ref] = parseInt(clonedDataObject[ref], 10);
      }
      setMutationData({
        mutation: updateOperationDetailsBuilder(key),
        item: { id, updateDataObject: clonedDataObject },
      });
    }
  };

  const [invalidEntry, setInvalidEntry] = useState<{ fieldType: string; isInvalid: boolean }>({
    fieldType: '',
    isInvalid: false,
  });

  // beaconCode value may need to be zero padded for UI
  const normaliseBeaconCode = (beaconCode: number): string => `${beaconCode}`.padStart(4, '0');

  const onCheckValidity = (object: { fieldType: string; isInvalid: boolean }) => {
    setInvalidEntry(object);
  };
  const canItBeUpdated = (fieldType: string) => {
    if (invalidEntry.isInvalid && invalidEntry.fieldType !== fieldType) {
      return false;
    }
    return true;
  };
  const noiseClassValue = (noiseClassSwitch: string) => {
    switch (noiseClassSwitch) {
      case 'azb':
        if (
          typeof operation.aircraft !== 'undefined' &&
          operation.aircraft.hasOwnProperty('azbNoiseClassification') &&
          operation.aircraft.azbNoiseClassification
        ) {
          return operation.aircraft.azbNoiseClassification;
        }
      case 'icao':
        if (
          typeof operation.aircraft !== 'undefined' &&
          operation.aircraft.hasOwnProperty('icaoNoiseClassification') &&
          operation.aircraft.icaoNoiseClassification
        ) {
          return operation.aircraft.icaoNoiseClassification;
        }
      default:
        return '—';
    }
  };

  const hasData: boolean = operation && !!Object.keys(operation).length;
  interface IDescriptionListItem {
    title: string;
    description: string | JSX.Element;
    isPrimary?: boolean;
  }
  let items: IDescriptionListItem[] = []

  if (hasData) {
    const dataItems: IDescriptionListItem[] = [
      {
        title: operationType,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditDropDownWithIcon({
              id: operation.id,
              operation,
              fieldType: 'operationType',
              fieldIcon: displayFields.adIcon,
              fieldTooltip: displayFields.adType,
              iconPrefix: 'ad',
              reduceChevronPadding: true,
              translationData: translationDataList,
              options: filterData.operationTypes,
              onUpdateSelection,
              canUpdate:
                canItBeUpdated('operationType') &&
                canUpdateField(canUpdate, readOnlyFields, 'operationType'),
              selectedTrackTheme,
            })}
          </div>
        ),
      },
      {
        title: flightNumber,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditText({
              id: operation.id,
              validation: {
                regexStr: operationValidation.flightNumber.match,
              },
              translationData: {
                invalidText: invalidEntryText,
                placeholder: entervalueText,
              },
              fieldType: 'flightNumber',
              value: operation.flightNumber,
              onUpdateSelection,
              onCheckValidity,
              canUpdate:
                canItBeUpdated('flightNumber') &&
                canUpdateField(canUpdate, readOnlyFields, 'flightNumber'),
            })}
          </div>
        ),
      },
      {
        title: tailNumber,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditText({
              id: operation.id,
              validation: {
                regexStr: operationValidation.tailNumber.match,
              },
              translationData: {
                invalidText: invalidEntryText,
                placeholder: entervalueText,
              },
              fieldType: 'tailNumber',
              value: operation.tailNumber,
              onUpdateSelection,
              onCheckValidity,
              canUpdate:
                canItBeUpdated('tailNumber') &&
                canUpdateField(canUpdate, readOnlyFields, 'tailNumber'),
            })}
          </div>
        ),
      },
      {
        title: beaconCode,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditText({
              id: operation.id,
              validation: {
                regexStr: operationValidation.beaconCode.match,
              },
              translationData: {
                invalidText: invalidEntryText,
                placeholder: entervalueText,
              },
              fieldType: 'beaconCode',
              value: normaliseBeaconCode(operation.beaconCode),
              onUpdateSelection,
              onCheckValidity,
              canUpdate:
                canItBeUpdated('beaconCode') &&
                canUpdateField(canUpdate, readOnlyFields, 'beaconCode'),
            })}
          </div>
        ),
      },
      {
        title: aircraftCategory,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditDropDownWithIcon({
              id: operation.id,
              operation,
              fieldType: 'aircraftCategory',
              fieldIcon: displayFields.flightIcon,
              fieldTooltip: displayFields.flightCategory,
              iconPrefix: 'ac',
              reduceChevronPadding: false,
              translationData: translationDataList,
              options: filterData.aircraftCategories,
              onUpdateSelection,
              canUpdate:
                canItBeUpdated('aircraftCategory') &&
                canUpdateField(canUpdate, readOnlyFields, 'aircraftCategory'),
              selectedTrackTheme,
            })}
          </div>
        ),
      },
      {
        title: aircraftType,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditDropDown({
              id: operation.id,
              operation,
              fieldType: 'aircraftType',
              position: 'left',
              labelName: operation.aircraftType,
              translationData: translationDataList,
              options: filterData.aircraftTypes,
              onUpdateSelection,
              canUpdate:
                canItBeUpdated('aircraftType') &&
                canUpdateField(canUpdate, readOnlyFields, 'aircraftType'),
            })}
          </div>
        ),
      },
      {
        title: operatorCategory,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditDropDown({
              id: operation.id,
              operation,
              fieldType: 'operatorCategory',
              position: 'left',
              labelName: operation.operatorCategory,
              translationData: translationDataList,
              options: filterData.operatorCategories,
              onUpdateSelection,
              canUpdate:
                canItBeUpdated('operatorCategory') &&
                canUpdateField(canUpdate, readOnlyFields, 'operatorCategory'),
            })}
          </div>
        ),
      },
      {
        title: airline,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditDropDown({
              id: operation.id,
              operation,
              fieldType: 'airline',
              position: 'left',
              labelName: operation.airline,
              translationData: translationDataList,
              options: filterData.airlines,
              onUpdateSelection,
              canUpdate:
                canItBeUpdated('airline') && canUpdateField(canUpdate, readOnlyFields, 'airline'),
            })}
          </div>
        ),
      },
      {
        title: airportId,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditDropDown({
              id: operation.id,
              operation,
              fieldType: 'airportId',
              position: 'left',
              labelName: operation.airportId,
              translationData: translationDataList,
              options: filterData.airportIds,
              onUpdateSelection,
              canUpdate:
                canItBeUpdated('airportId') &&
                canUpdateField(canUpdate, readOnlyFields, 'airportId'),
            })}
          </div>
        ),
      },
      {
        title: remoteAirportId,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditDropDown({
              id: operation.id,
              operation,
              fieldType: 'remoteAirportId',
              position: 'left',
              labelName: operation.remoteAirportId,
              translationData: translationDataList,
              options: filterData.remoteAirportIds,
              onUpdateSelection,
              canUpdate:
                canItBeUpdated('remoteAirportId') &&
                canUpdateField(canUpdate, readOnlyFields, 'remoteAirportId'),
            })}
          </div>
        ),
      },
      {
        title: runwayName,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditDropDown({
              id: operation.id,
              operation,
              fieldType: 'runwayName',
              position: 'left',
              labelName: operation.runwayName,
              translationData: translationDataList,
              options: filterData.runwayNames,
              onUpdateSelection,
              canUpdate:
                canItBeUpdated('runwayName') &&
                canUpdateField(canUpdate, readOnlyFields, 'runwayName'),
            })}
          </div>
        ),
      },
      {
        title: pathName,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditText({
              id: operation.id,
              validation: {
                regexStr: operationValidation.pathName.match,
              },
              translationData: {
                invalidText: invalidEntryText,
                placeholder: entervalueText,
              },
              fieldType: 'pathName',
              value: operation.pathName,
              onUpdateSelection,
              onCheckValidity,
              canUpdate:
                canItBeUpdated('pathName') && canUpdateField(canUpdate, readOnlyFields, 'pathName'),
            })}
          </div>
        ),
      },
    ];

    if (noiseClassSwitch !== 'None') {
      dataItems.push({
        title: noiseClass,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditText({
              id: operation.id,
              translationData: {
                invalidText: invalidEntryText,
                placeholder: entervalueText,
              },
              fieldType: 'noiseClass',
              value: noiseClassValue(noiseClassSwitch),
              onUpdateSelection,
              onCheckValidity,
              canUpdate: false,
            })}
          </div>
        ),
      });
    }

    if (FEATURE_FLAG_OPERATION_TAGS && operation.tags) {
      const operationTagList: string[] = operationData.tags.map((
        tag: {[key: string]: string}
      ) => tag.name);
      const operationTagPills = renderOperationTagPills(operationTagList);
      dataItems.push({
        title: operationTags,
        description: (<>{operationTagPills}</>),
      });
    }

    if (FEATURE_FLAG_FORMATION_COUNT) {
      dataItems.push({
        title: aircraftCount,
        description: (
          <div className="inline-edit-dropdown">
            {InlineEditDropDown({
              id: operation.id,
              operation,
              fieldType: 'aircraftCount',
              position: 'left',
              labelName: operation.aircraftCount,
              translationData: translationDataList,
              options: filterData.aircraftCounts,
              onUpdateSelection,
              canUpdate:
                canItBeUpdated('aircraftCount') &&
                canUpdateField(canUpdate, readOnlyFields, 'aircraftCount'),
            })}
          </div>
        ),
      });
    }

    items = [...dataItems]
  } else {
    const fields = [
      operationType,
      flightNumber,
      tailNumber,
      beaconCode,
      aircraftCategory,
      aircraftType,
      operatorCategory,
      airline,
      airportId,
      remoteAirportId,
      runwayName,
      pathName,
    ];
    if (noiseClassSwitch !== 'None') {
      fields.push(noiseClass);
    }
    const fieldItems: IDescriptionListItem[] = fields.map(title => ({
      title,
      description: null,
    }));
    items = [...fieldItems]
  }

  return (
    <div className="block operation-details">
      <Card>
        <BlockHeader
          title={title}
          cta={
            operation.time && (
              <span className="infringements_time">
                {formatFromISO(operation.time, FIELD_TWENTYFOUR_HOURS_FORMAT)}
              </span>
            )
          }
          loading={!hasData}
        />
        <DescriptionList items={items} loading={!hasData} />
      </Card>
    </div>
  );
};
