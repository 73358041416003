import React, { useState } from 'react';
import { Button } from '@ems/client-design-system';
// utils
import { detectIE } from 'src/app/functions/validation';
import { getUrlDetails } from 'src/app/functions/core';
// local translation
import * as strings from 'src/app/strings.json';
// style
import './styles.scss';

const translationData = strings.components.startScreen;

export const StartScreen = ({ clickEvent, text = 'welcome', error = '', displayButton = true }) => {
  const [plzWait, updatePlzWait] = useState<boolean>(false);
  const { siteName } = getUrlDetails();
  const isIeEdge = detectIE() !== false;

  const greeting = () => {
    if (!isIeEdge) {
      if (siteName) {
        return (
          <>
            <div className="intro">
              {text && typeof translationData[text] !== 'undefined' && (
                <h2>
                  {translationData[text]}
                  {(text === '403' || text === '404') && ` ${translationData.contactSupport}`}
                </h2>
              )}
              {error && typeof translationData[error] !== 'undefined' && (
                <p className="sitename error">
                  Error: {translationData[error]}
                  <br />
                  {translationData.contactSupport}
                </p>
              )}
            </div>
            {displayButton && (
              <div className="buttons">
                <Button
                  style="primary"
                  disabled={plzWait ? true : false}
                  aria-label={`Sign In`}
                  onClick={() => {
                    if (!plzWait) {
                      clickEvent();
                    }
                    updatePlzWait(true);
                  }}>
                  {plzWait ? translationData.pleaseWait : translationData.buttonText}
                </Button>
              </div>
            )}
          </>
        );
      } else {
        // sitename doesn't exist in the url
        return (
          <div className="intro">
            <h2>{translationData.welcome}</h2>
            <p className="sitename error">{translationData.missingSitename}</p>
          </div>
        );
      }
    } else {
      // IE and older Edge is not supported
      return (
        <div className="intro">
          <h2>{translationData.welcome}</h2>
          <p className="sitename error">{translationData.noSupported}</p>
        </div>
      );
    }
  };

  return (
    <div className="container">
      <div className="signin-wrapper" style={{ visibility: 'hidden', opacity: 0 }}>
        <div className="sign-in-box">
          <div className="logo" />
          {greeting()}
        </div>
      </div>
    </div>
  );
};
