import React, { FC, useState, useEffect, useRef } from 'react';
import { debounce } from 'debounce';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
// common components
import { StyledMap, MapControl, RulerTool } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
import { NoisePopup } from 'src/components/NoisePopup';
// functions
import { useMapSettings } from 'src/app/functions/mapSettings';
import {
  useMapRef,
  useMapWhenReady,
  useMapProps,
  useMapConfig,
  useDatesDataForMap,
} from 'src/app/functions/map';
import {
  useDataForMap,
  useMapLayer,
  useMapTracksFilter,
} from 'src/app/functions/infringementsOnMap';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
import {
  flyTo,
  useHoverOnMapElement,
  useMapHover,
  useMapClick,
  useMapSelection,
  useGeometryRequiredByMap,
  offsetCoordinatesByPixels,
} from 'src/utils';
import { useStaticDbDisplay } from 'src/utils/playback';
import { OperationPopup } from 'src/components';
import { useDataSelectors } from '../reducers';
import { IMapProps } from 'src/@infringements/interfaces';
import { useInfringementRulesSelectors } from 'src/app/reducers';
// constants
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { MAPTYPES, MARKER_OFFSET } from 'src/constants';
import { IRulerCoordinateObject } from 'src/utils/interfaces';

export const MapContainer: FC<IMapProps> = ({ noiseMonitors = [], noiseData = [] }) => {
  // get map props from config
  const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });
  // used for taking screenshot of map
  const captureRef = useRef(null);
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();
  // get field labels from language selectors
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { operations: opsFields },
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: { backToCenter: backToCenterLabel },
    },
    abbreviations: { operations: opsAbbreviation },
  } = languageSelectors.getLanguage();

  const labels = Object.assign(opsFields, opsAbbreviation);

  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  // restrict map pan
  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // getting map style layers
  const layers = useMapLayer({ mapApis, mapBoxConfig, maptype: 'infringements' });
  // changing map tiles when dates change
  const { dateRangeMapping, datesArray } = useDatesDataForMap(
    mapApis,
    mapBoxConfig,
    layers,
    false,
    MAPTYPES.INFRINGEMENTDETAILS
  );

  const dataSelectors = useDataSelectors();
  const {
    requiredData,
    infringementTypes,
    addedToSelection,
    removedFromSelection,
  } = dataSelectors.getRequiredDataForMap();

  const infRuleSelectors = useInfringementRulesSelectors();
  let extraGateIds: number[] = [];

  requiredData.map(infringement => {
    if (infringement.infringementType === 'Gate') {
      extraGateIds = extraGateIds.concat(infRuleSelectors.getGateIdsByRuleId(infringement.ruleId));
    }
  });

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);

  // add geometry shapes to map
  useGeometryRequiredByMap({
    mapApis,
    mapBoxConfig,
    infringementTypes,
  });

  // getting coordinates for infringements
  const { infringementTracks, selectedInfringements } = useDataForMap(
    'inf-browser',
    mapApis,
    mapBoxConfig,
    viewport,
    setViewport,
    dateRangeMapping,
    requiredData,
    addedToSelection,
    removedFromSelection,
    extraGateIds
  );
  // extrack map filters for the features
  const { infringementFilter, clickFilter } = useMapTracksFilter(
    infringementTracks.operations,
    selectedInfringements
  );

  // resets map view
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  // handle hover and get hovered operation
  const { hoveredElement, handleHover, setHoveredElement } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: datesArray,
    tracksFilter: infringementFilter,
    mapType: MAPTYPES.INFRINGEMENTDETAILS,
  });

  // set map selection for the hover object
  useMapHover(hoveredElement, mapApis);

  // Map selection
  const [showSelected, setShowSelected] = useState(false);
  const [selectedOperations, setSelectedOperations]: any = useState(null);

  // click handlers that provide selected operations
  const { handleClick, selectedOperation } = useMapClick({
    hoveredOperation: hoveredElement,
    setShowSelected,
    mapApis,
    datesArray,
    tracksFilter: clickFilter,
    selectedOperations,
    setSelectedOperations,
    mapType: MAPTYPES.INFRINGEMENTDETAILS,
  });

  // set feature state for the hovered operation
  useMapSelection(selectedOperations, mapApis, 'infringement-select', selectedInfringements);

  // reset hover and select when selection changes
  useEffect(() => {
    setShowSelected(false);
    setHoveredElement(null);
    setSelectedOperations([]);
  }, [selectedInfringements.length]);

  const selectedMonitor: any[] = useStaticDbDisplay(mapApis, noiseMonitors, noiseData, false);

  const noisePopups: JSX.Element[] = [];
  if (selectedMonitor.length > 0) {
    selectedMonitor.map((monitor: any) => {
      noisePopups.push(
        <NoisePopup
          key={`popup_${monitor.properties.id}`}
          zoomLevel={mapApis.getZoom()}
          monitorData={monitor}
        />
      );
    });
  }

  // Ruler Tool

  const units = configSelectors.getUnits();
  const [isRulerEnabled, setIsRulerEnabled] = useState<boolean>(false);
  const [rulerCoordinates, updateRulerCoordinates] = useState<IRulerCoordinateObject>({
    start: { longitude: 0, latitude: 0 },
    end: { longitude: 0, latitude: 0 },
  });

  const toggleRuler = () => {
    if (isRulerEnabled) {
      setIsRulerEnabled(false);
      mapApis.isRulerEnabled = false;
    } else {
      setIsRulerEnabled(true);
      mapApis.isRulerEnabled = true;

      const { longitude, latitude } = viewport;
      const startMarkerCoordinates = offsetCoordinatesByPixels(
        [longitude, latitude],
        MARKER_OFFSET,
        mapApis
      );

      updateRulerCoordinates({
        start: { longitude: startMarkerCoordinates.lng, latitude: startMarkerCoordinates.lat },
        end: { longitude, latitude },
      });
    }
  };

  const rulerCoordinatesChanged = (rulerPoint: string, [longitude, latitude]: number[]) => {
    if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
      updateRulerCoordinates({
        ...rulerCoordinates,
        [rulerPoint]: { longitude, latitude },
      });
    }
  };
  return (
    <div className="map_wrapper">
      <div ref={captureRef} className="map">
        <StyledMap
          onLoad={() => mapLoaded()}
          viewport={viewport}
          mapStyle={mapStyle}
          onViewportChange={viewport => {
            viewport.maxPitch = 0;
            onViewportChange(viewport);
          }}
          {...mapProps}
          ref={mapRef}
          onMouseMove={undefined}
          onClick={handleClick}
          onHover={debounce(handleHover, 5)}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }>
          {enableMapControls && (
            <MapControl
              translationData={{
                home: backToCenterLabel,
                mapSettings: mapSettingsTitle,
              }}
              navigationControl={{
                showCompass: true,
                showHome: true,
                showSearch: false,
                showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
              }}
              rulerControl={{
                isRulerEnabled,
                toggleRuler,
              }}
              resetView={resetView}
              mapSettings={{
                update: () => {
                  applyBackground();
                  applyLayers();
                },
                reset: () => {
                  resetBackground();
                  resetLayers();
                },
                content: (
                  <MapSettingsContainer
                    config={{
                      background: mapStyle,
                      layers: layersDisplayed,
                    }}
                    updateEvent={({ selectedBackground, selectedLayers }) => {
                      if (typeof selectedBackground !== 'undefined') {
                        storeSelectedBackground(selectedBackground);
                      }
                      if (typeof selectedLayers !== 'undefined') {
                        storeSelectedLayers(selectedLayers);
                      }
                    }}
                  />
                ),
              }}
            />
          )}
          {noisePopups}
          {showSelected &&
            selectedOperation &&
            selectedOperation.latitude &&
            selectedOperation.longitude && (
              <OperationPopup
                selectedOperation={selectedOperation}
                onClose={() => setShowSelected(false)}
                labels={labels}
                mapApis={mapApis}
              />
            )}
          {!showSelected && hoveredElement && hoveredElement.latitude && hoveredElement.longitude && (
            <OperationPopup
              selectedOperation={hoveredElement}
              onClose={() => {
                console.log(close);
              }}
              labels={labels}
              mapApis={mapApis}
            />
          )}
          <MapReferenceLayers mapApis={mapApis} mapStyle={mapStyle} layers={layersDisplayed} />
          <RulerTool
            units={units.distance}
            coordinates={rulerCoordinates}
            isRulerEnabled={isRulerEnabled}
            mapProjection={mapProjectionString}
            handleDragEvent={rulerCoordinatesChanged}
            mapApis={mapApis}
          />
        </StyledMap>
      </div>
    </div>
  );
};
