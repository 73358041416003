import React, { useContext } from 'react';
// selectors
import { useConfigSelectors, useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useFilterSelectors } from 'src/@noiseEvents/reducers';
import { NoiseEventsDispatchContext } from 'src/@noiseEvents/providers/NoiseEventsStateProvider';
// common components
import {
  Filter,
  FilterRow,
  RangeSelector,
  validateTimeFormat,
  validateLMaxFormat,
  validateSecondsFormat,
  onCloseNumberRangeValidation,
  isValidTime,
  isValidDecibel,
  isValidSeconds,
  isCompletedNumberFormat,
  isCompletedTimeFormat,
  onCloseTimeDialogValidation,
} from '@ems/client-design-system';
import {
  updateSelectedItems,
  clearSelectedItems,
  updateRangeFilter,
} from 'src/@noiseEvents/actions';
// function
import {
  getIfFiltersEmpty,
  translateLabels,
  convertDataToFilterForm,
} from 'src/@noiseEvents/functions';
import {
  UNIT_DECIBEL,
  UNIT_SECOND,
  DECIBEL_FILTER_MIN,
  DECIBEL_FILTER_MAX,
  SECONDS_FILTER_MIN,
  SECONDS_FILTER_MAX,
  ERROR_MAX_GREATER_THAN_MIN,
  NULL_VALUE,
  AIRCRAFT_TYPE_ICON_CODES
} from 'src/constants';
import { convertObjectKeys } from 'src/utils/objectModifiers';
// interfaces
import { IFilterItem } from 'src/@noiseEvents/props';
import { OPERATOR_CATEGORY } from 'src/app/featureToggles';

export const FilterContainer = () => {
  const dispatcher = useContext(NoiseEventsDispatchContext);
  const configSelectors = useConfigSelectors();
  const filterSelectors = useFilterSelectors();
  const filteredItems = filterSelectors.getRegularFilters();
  const {
    noiseEvents: {
      filter: { availableCategoryList, nullable, showSelectedIcons },
    },
  } = configSelectors.getConfig();
  const FEATURE_FLAG_OPERATOR_CATEGORY = configSelectors.isFeatureAvailable(OPERATOR_CATEGORY);

  // filters data
  const filtersSelectors = useFilterDataSelectors();
  const noiseEventFilterData = filtersSelectors.getNoiseEventsFilterData();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      buttons,
      labels: {
        from: fromLabel,
        to: toLabel,
        filters: { clear: clearValue, filter: filterValue, clearFilters },
      },
      hints: { noMatchesFound },
      filters: filtersTranslation,
      lists: {
        operationFilterCategories,
        aircraftCategories,
        operatorCategories,
        operationTypes,
        classificationTypes,
        correlatedCategories,
        extraFilterValues,
      },
      errors,
    },
  } = languageSelectors.getLanguage();

  // Error mapping
  const errorMap = {
    [ERROR_MAX_GREATER_THAN_MIN]: errors.invalidRangeError,
  };
  // Time filter
  const { value: timeFilters, label: timeLabel } = filterSelectors.getTimeFilter();
  // Lmax filter
  const {
    value: lmaxFilters,
    label: lmaxLabel,
    validity: lmaxValidity,
  } = filterSelectors.getLMaxFilter();
  const lmaxErrorItem = lmaxValidity
    ? {
        to: lmaxValidity.to,
        from: lmaxValidity.from,
        validationError: lmaxValidity ? errorMap[lmaxValidity.error] : '',
      }
    : null;
  const lMaxFilterTranslation = {
    from: fromLabel,
    to: toLabel,
    buttonTitle: buttons.maxLevelFilter,
    validationError: errors.validationDecibelError,
    invalidRangeError: errors.invalidRangeError,
    placeholder: {
      from: `00.0`,
      to: `140.0`,
    },
  };
  // Duration filter
  const {
    value: durationFilters,
    label: durationLabel,
    validity: durationValidity,
  } = filterSelectors.getDurationFilter();
  const durationErrorItem = durationValidity
    ? {
        to: durationValidity.to,
        from: durationValidity.from,
        validationError: durationValidity ? errorMap[durationValidity.error] : '',
      }
    : null;
  const durationFilterTranslation = {
    from: fromLabel,
    to: toLabel,
    buttonTitle: buttons.durationFilter,
    validationError: errors.validationDecibelError,
    invalidRangeError: errors.invalidRangeError,
    placeholder: {
      from: `00.0`,
      to: `140.0`,
    },
  };
  // SEL filter
  const {
    value: selFilters,
    label: selLabel,
    validity: selValidity,
  } = filterSelectors.getSELFilter();
  const selErrorItem = selValidity
    ? {
        to: selValidity.to,
        from: selValidity.from,
        validationError: selValidity ? errorMap[selValidity.error] : '',
      }
    : null;
  const selFilterTranslation = {
    from: fromLabel,
    to: toLabel,
    buttonTitle: buttons.selFilter,
    validationError: errors.validationDecibelError,
    invalidRangeError: errors.invalidRangeError,
    placeholder: {
      from: `00.0`,
      to: `140.0`,
    },
  };

  const convertedFilterData = {};
  Object.entries(noiseEventFilterData).map(([key, value]) => {
    const iconType: string = AIRCRAFT_TYPE_ICON_CODES[key] || '';
    if (key !== 'classificationGroups') {
      convertedFilterData[key] = convertDataToFilterForm(value, iconType);
    }
  });

  const convertedDataList = convertObjectKeys({
    ...aircraftCategories,
    ...operatorCategories,
    ...operationTypes,
    ...classificationTypes,
    ...correlatedCategories,
    ...extraFilterValues,
  });
  const filterComponents: JSX.Element[] = [];

  const rangeSelectors = ['time', 'maxLevel', 'sel', 'duration'];

  availableCategoryList.map((category: string) => {
    if (rangeSelectors.includes(category)) {
      switch (category) {
        case 'time':
          filterComponents.push(
            <RangeSelector
              key="filter-time-selector"
              type="time"
              min={null}
              max={null}
              fromInputValue={timeFilters.from}
              toInputValue={timeFilters.to}
              setInputValue={(value: string, field: 'from' | 'to') => {
                updateRangeFilter('time', value, field, dispatcher);
              }}
              label={timeLabel}
              validateInputFormat={validateTimeFormat}
              isValidValue={isValidTime}
              onCloseValidation={onCloseTimeDialogValidation}
              isCompletedValueFormat={isCompletedTimeFormat}
              languageData={filtersTranslation.time}
            />
          );
          break;
        case 'maxLevel':
          filterComponents.push(
            <RangeSelector
              key="filter-lmax-selector"
              type="number"
              metric={UNIT_DECIBEL}
              min={DECIBEL_FILTER_MIN}
              max={DECIBEL_FILTER_MAX}
              fromInputValue={lmaxFilters.from}
              toInputValue={lmaxFilters.to}
              setInputValue={(value: string, field: 'from' | 'to') => {
                updateRangeFilter('maxLevel', value, field, dispatcher);
              }}
              label={lmaxLabel}
              validateInputFormat={validateLMaxFormat}
              isValidValue={isValidDecibel}
              invalidRange={lmaxErrorItem}
              onCloseValidation={onCloseNumberRangeValidation}
              isCompletedValueFormat={isCompletedNumberFormat}
              languageData={lMaxFilterTranslation}
            />
          );
          break;
        case 'sel':
          filterComponents.push(
            <RangeSelector
              key="filter-sel-selector"
              type="number"
              metric={UNIT_DECIBEL}
              min={DECIBEL_FILTER_MIN}
              max={DECIBEL_FILTER_MAX}
              fromInputValue={selFilters.from}
              toInputValue={selFilters.to}
              setInputValue={(value: string, field: 'from' | 'to') => {
                updateRangeFilter('sel', value, field, dispatcher);
              }}
              label={selLabel}
              validateInputFormat={validateLMaxFormat}
              isValidValue={isValidDecibel}
              invalidRange={selErrorItem}
              onCloseValidation={onCloseNumberRangeValidation}
              isCompletedValueFormat={isCompletedNumberFormat}
              languageData={selFilterTranslation}
            />
          );
          break;
        case 'duration':
          filterComponents.push(
            <RangeSelector
              key="filter-duration-selector"
              type="number"
              metric={UNIT_SECOND}
              min={SECONDS_FILTER_MIN}
              max={SECONDS_FILTER_MAX}
              fromInputValue={durationFilters.from}
              toInputValue={durationFilters.to}
              setInputValue={(value: string, field: 'from' | 'to') => {
                updateRangeFilter('duration', value, field, dispatcher);
              }}
              label={durationLabel}
              validateInputFormat={validateSecondsFormat}
              isValidValue={isValidSeconds}
              invalidRange={durationErrorItem}
              onCloseValidation={onCloseNumberRangeValidation}
              isCompletedValueFormat={isCompletedNumberFormat}
              languageData={durationFilterTranslation}
            />
          );
          break;
        default:
      }
    } else {
      if(category === 'operatorCategories' && !FEATURE_FLAG_OPERATOR_CATEGORY) {
        return;
      }
      const NullValue = { key: NULL_VALUE, label: NULL_VALUE, icon: undefined };
      const originalItems = convertedFilterData[category];
      if (originalItems !== undefined) {
        const transformedItems =
          nullable && nullable.includes(category) ? [NullValue, ...originalItems] : originalItems;
        filterComponents.push(
          <Filter
            key={category}
            categoryName={operationFilterCategories[category]}
            filterItems={translateLabels(transformedItems, convertedDataList)}
            selectedItems={filteredItems[category]}
            updateItems={(items: IFilterItem[]) => updateSelectedItems(category, items, dispatcher)}
            languageData={{ clearValue, filterValue, noMatchesFound }}
            iconCategories={showSelectedIcons.map(
              (category: string) => operationFilterCategories[category]
            )}
          />
        );
      }
    }
  });

  return (
    <div>
      <FilterRow
        filters={filterComponents}
        clearFilters={() => clearSelectedItems(dispatcher)}
        clearDisabled={getIfFiltersEmpty(filteredItems, [
          timeFilters,
          lmaxFilters,
          selFilters,
          durationFilters,
        ])}
        languageData={{ clearFilters }}
      />
    </div>
  );
};
