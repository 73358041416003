import React, { useState, useEffect, FC } from 'react';
import { TextInput, Filter, IFilterItem, Checkbox } from '@ems/client-design-system';
// reducers
import { useLanguageSelectors, useScenariosSelectors } from 'src/app/reducers';
// utils
import { getDateRange, formatDateString, timePlusMinusDuration } from 'src/utils/dateTimeConverters';
// functions
import { formatDateFilterItems, isValidDateRange } from 'src/@settings/functions';
// interfaces
import { IConfigurationDetailsContainer } from 'src//@settings/interfaces';
import cx from 'classnames';
import { DEFAULT_SCENARIO_START_YEAR, SCENARIO_FUTURE_YEARS } from 'src/constants';

export const ConfigurationDetailsContainer: FC<IConfigurationDetailsContainer> = ({
  updateConfigurations,
}) => {
  const scenariosSelector = useScenariosSelectors();
  const selectedScenario = scenariosSelector.getSelectedScenario();
  const rowIndex = scenariosSelector.getSelectedRowIndex();
  const lockStatus = selectedScenario.isLocked;
  // state
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(rowIndex);
  const [startDate, setStartDate] = useState<string>(formatDateString(selectedScenario.startTime, 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(formatDateString(selectedScenario.endTime, 'yyyy-MM-dd'));
  const [name, setName] = useState<string>(selectedScenario.name);
  const [isLocked, setIsLocked] = useState<boolean>(lockStatus);
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: { configurationName, configDateRangeHeader, lockCheckboxText, startMonth, endMonth },
      lists: { monthsLong },
    },
  } = languageSelectors.getLanguage();

  const getFilterItems = () => {
    const scenarioFutureDate = new Date(timePlusMinusDuration(new Date().toISOString(), { years: SCENARIO_FUTURE_YEARS }));
    return getDateRange(0, DEFAULT_SCENARIO_START_YEAR, scenarioFutureDate.getMonth(), scenarioFutureDate.getFullYear());
  };

  const updateStartDate = (date: IFilterItem) => {
    const isValid = isValidDateRange(date.key, endDate);
    if (!isValid) {
      setEndDate(date.key);
    }
    setStartDate(date.key);
  };

  const updateEndDate = (date: IFilterItem) => {
    const isValid = isValidDateRange(startDate, date.key);
    if (!isValid) {
      setStartDate(date.key);
    }
    setEndDate(date.key);
  };

  useEffect(() => {
    if (
      startDate !== formatDateString(selectedScenario.startTime, 'yyyy-MM-dd') ||
      endDate !== formatDateString(selectedScenario.endTime, 'yyyy-MM-dd') ||
      name !== selectedScenario.name ||
      isLocked !== selectedScenario.isLocked
    ) {
      updateConfigurations({ name, startDate, endDate, isLocked, selectedRowIndex });
    }
  }, [startDate, endDate, name, isLocked]);

  useEffect(() => {
    setStartDate(formatDateString(selectedScenario.startTime, 'yyyy-MM-dd'));
    setEndDate(formatDateString(selectedScenario.endTime, 'yyyy-MM-dd'));
    setName(selectedScenario.name);
    setSelectedRowIndex(rowIndex);
    setIsLocked(lockStatus);
  }, [selectedScenario]);

  const onCheckBoxSelect = () => {
    setIsLocked(!isLocked);
  };

  const onScenarioNameChange = scenarioName => {
    const inputValue = scenarioName.currentTarget.value;
    setName(inputValue);
  };

  return (
    <div className="manageconfig-right">
      <div className="manageconfig-name">{configurationName}</div>
      <div className={cx('manageconfig-text-box', 'manageconfig-name')}>
        <TextInput
          value={name}
          onChange={onScenarioNameChange}
          disabled={rowIndex === 0 ? true : false}
        />
      </div>
      {rowIndex !== 0 && (
        <div>
          <div className="manageconfig-daterange-header">{configDateRangeHeader}</div>
          <div className="manageconfig-daterange">
            <div className="manageconfig-daterange__date">
              <span className="manageconfig-daterange__label">{startMonth}</span>
              <div className="manageconfig-daterange__filter">
                <Filter
                  key={''}
                  categoryName={''}
                  filterItems={getFilterItems()}
                  selectedItems={formatDateFilterItems([{
                    key: startDate,
                    label: formatDateString(selectedScenario.startTime, 'LLLL yyyy')
                  }], monthsLong)}
                  updateItems={(items: IFilterItem[]) => {
                    updateStartDate(items[0]);
                  }}
                  languageData={{ clearValue: '', filterValue: '', noMatchesFound: '' }}
                  iconCategories={[]}
                  isCategoryNameRequired={false}
                  showClear={false}
                  type={'single'}
                />
              </div>
            </div>
            <div className="manageconfig-daterange__date">
              <span className="manageconfig-daterange__label">{endMonth}</span>
              <div className="manageconfig-daterange__filter">
                <Filter
                  key={''}
                  categoryName={''}
                  filterItems={getFilterItems()}
                  selectedItems={formatDateFilterItems([{
                    key: endDate,
                    label: formatDateString(selectedScenario.endTime, 'LLLL yyyy')
                  }], monthsLong)}
                  updateItems={(items: IFilterItem[]) => {
                    updateEndDate(items[0]);
                  }}
                  languageData={{ clearValue: '', filterValue: '', noMatchesFound: '' }}
                  iconCategories={[]}
                  isCategoryNameRequired={false}
                  showClear={false}
                  type={'single'}
                />
              </div>
            </div>
          </div>
          <div>
            <Checkbox
              label={lockCheckboxText}
              focused={isLocked}
              checked={isLocked}
              onClick={onCheckBoxSelect}
            />
          </div>
        </div>
      )}
    </div>
  );
};
