import React, { FC, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// context
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
import { InfringementDispatchContext } from 'src/@infringements/providers/InfringementsStateProvider';
import { useSortSelectors, useDataSelectors } from 'src/@infringements/reducers';
// common components
import {
  Filter,
  FilterRow,
  RangeSelector,
  validateTimeFormat,
  isValidTime,
  onCloseTimeDialogValidation,
  isCompletedTimeFormat,
} from '@ems/client-design-system';
import {
  updateSelectedItems,
  clearSelectedItems,
  updateTimeFilterValue,
} from 'src/@infringements/actions/filterActions';
// store
import { filterStore } from 'src/@infringements/stores/filterStore';
// function
import {
  useLatestFilter,
  getIfFiltersEmpty,
  translateLabels,
  useTimeFilter,
} from 'src/@infringements/functions';
import { convertObjectKeys } from 'src/utils/objectModifiers';
import { isAtcView } from 'src/utils';
// interfaces
import { ITableFilterItem } from 'src/@infringements/interfaces';
// constants
import { NULL_VALUE } from 'src/constants';

export const FilterContainer: FC<any> = ({ source = '', availableCategoryList }) => {
  const client = useApolloClient();
  // Configuration
  const configSelectors = useConfigSelectors();
  const dispatcher = useContext(InfringementDispatchContext);
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const dataSelectors = useDataSelectors();
  const showCandidates = dataSelectors.getCandidatesEnabled();
  const atcView = isAtcView(source);

  const {
    grid: { resultSize },
    infringements: {
      filter: { nullable, showSelectedIcons },
    },
  } = configSelectors.getConfig();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        filters: { clear: clearValue, filter: filterValue, clearFilters },
      },
      hints: { noMatchesFound },
      lists: {
        infringementFilterCategories,
        aircraftCategories,
        ruleTypes,
        typeCategories,
        severities,
        extraFilterValues,
        infringementStatuses,
        vectored,
        timeFilter: languageData,
      },
    },
  } = languageSelectors.getLanguage();

  const timeFilters = useTimeFilter(resultSize, showCandidates, atcView);

  const dataListUpdated = convertObjectKeys({
    ...aircraftCategories,
    ...extraFilterValues,
    ...ruleTypes,
    ...typeCategories,
    ...severities,
    ...infringementStatuses,
    ...vectored,
  });

  const filterItems = filterStore.getAllFilterItems();
  const selectedItems = useLatestFilter({
    extraFilterValues,
  });
  const filterComponents: JSX.Element[] = [];
  availableCategoryList.map((category: string) => {
    const NullValue = { key: NULL_VALUE, label: NULL_VALUE, icon: undefined };
    const originalItems = filterItems[category];
    if (originalItems !== undefined) {
      const transformedItems =
        nullable && nullable.includes(category) ? [NullValue, ...originalItems] : originalItems;

      filterComponents.push(
        <Filter
          key={category}
          categoryName={infringementFilterCategories[category]}
          filterItems={translateLabels(transformedItems, dataListUpdated)}
          selectedItems={selectedItems[category]}
          updateItems={(items: ITableFilterItem[]) =>
            updateSelectedItems(
              client,
              category,
              items,
              dispatcher,
              sortString,
              resultSize,
              showCandidates,
              atcView
            )
          }
          iconCategories={showSelectedIcons.map(
            (category: string) => infringementFilterCategories[category]
          )}
          languageData={{ clearValue, filterValue, noMatchesFound }}
        />
      );
    }
  });

  /*
   * arr.splice(index, 0, item); will insert
   * item into arr at the specified index
   * (deleting 0 items first, that is, it's just an insert).
   */
  const fromComplete = timeFilters.from && isCompletedTimeFormat(timeFilters.from);
  const toComplete = timeFilters.to && isCompletedTimeFormat(timeFilters.to);
  let timeLabel = '';
  if (fromComplete || toComplete) {
    timeLabel = `${timeFilters.from}-${timeFilters.to}`;
  }

  filterComponents.splice(
    1,
    0,
    <RangeSelector
      key="filter-time-selector"
      type="time"
      min={null}
      max={null}
      fromInputValue={timeFilters.from}
      toInputValue={timeFilters.to}
      setInputValue={(value: string, field: 'from' | 'to') => {
        updateTimeFilterValue(value, field);
      }}
      label={timeLabel}
      validateInputFormat={validateTimeFormat}
      isValidValue={isValidTime}
      onCloseValidation={onCloseTimeDialogValidation}
      isCompletedValueFormat={isCompletedTimeFormat}
      languageData={languageData}
    />
  );

  return (
    <div>
      <FilterRow
        filters={filterComponents}
        clearFilters={() =>
          clearSelectedItems(client, resultSize, dispatcher, sortString, showCandidates, atcView)
        }
        clearDisabled={getIfFiltersEmpty(selectedItems, timeFilters)}
        languageData={{ clearFilters }}
      />
    </div>
  );
};
