/*
 * convert object keys from regular camelCase to Microsoft PascalCase
 */
export const convertObjectKeys = (object: any): any => {
  const output: any = {};
  Object.entries(object).forEach(([key, item]) => {
    output[capitalize(key)] = item;
  });
  return output;
};

/*
 * capitalize a string
 */
export const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const toTitleCase = (s: string): string =>
  s.replace(/\w\S*/g, (text: string) => {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  });

const isMergeableObject = (val: any) => {
  if(!val) return false;
  const objectType = Object.prototype.toString.call(val);
  if (objectType === '[object RegExp]') return false;
  if (objectType === '[object Date]') return false;
  if (typeof val !== 'object') return false;
  return true;
};

const emptyTarget = (val: any) => {
  return Array.isArray(val) ? [] : {};
};

const cloneIfNecessary = (value: any, optionsArgument: any) => {
  const clone = optionsArgument && optionsArgument.clone === true;
  return clone && isMergeableObject(value)
    ? deepMerge(emptyTarget(value), value, optionsArgument)
    : value;
};

const defaultArrayMerge = (target: any, source: any, optionsArgument: any) => {
  const destination = target.slice();
  source.forEach((item: any, i: number) => {
    if (typeof destination[i] === 'undefined') {
      destination[i] = cloneIfNecessary(item, optionsArgument);
    } else if (isMergeableObject(item)) {
      destination[i] = deepMerge(target[i], item, optionsArgument);
    } else if (target.indexOf(item) === -1) {
      destination.push(cloneIfNecessary(item, optionsArgument));
    }
  });
  return destination;
};

const mergeObject = (target: any, source: any, optionsArgument: any) => {
  const KEYS_TO_ALWAYS_OVERWRITE = ['columns'];
  const destination = {};
  if (isMergeableObject(target)) {
    Object.keys(target).forEach(key => {
      destination[key] = cloneIfNecessary(target[key], optionsArgument);
    });
  }
  Object.keys(source).forEach(key => {
    if(KEYS_TO_ALWAYS_OVERWRITE.includes(key)) {
      destination[key] = source[key].slice();
      return;
    }
    if (!isMergeableObject(source[key]) || !target[key]) {
      destination[key] = cloneIfNecessary(source[key], optionsArgument);
    } else {
      destination[key] = deepMerge(target[key], source[key], optionsArgument);
    }
  });
  return destination;
};

export const deepMerge = (target: any, source: any, optionsArgument: any) => {
  const sourceIsArray = Array.isArray(source);
  const options = optionsArgument || { arrayMerge: defaultArrayMerge };
  const arrayMerge = options.arrayMerge || defaultArrayMerge;

  if (sourceIsArray) {
    return Array.isArray(target)
      ? arrayMerge(target, source, optionsArgument)
      : cloneIfNecessary(source, optionsArgument);
  } else {
    return mergeObject(target, source, optionsArgument);
  }
};

export const deepMergeAll = (array: any, optionsArgument: any) => {
  if (!Array.isArray(array) || array.length < 2) {
    throw new Error('first argument should be an array with at least two elements');
  }
  return array.reduce((prev, next) => {
    return deepMerge(prev, next, optionsArgument);
  });
};


/*
 * Group array of objects by criteria passed in.
 * @param  {Array}           arr      The array to group items from
 * @param  {String[]} criteria The criteria to group by
 * @param  {string} aggregator The field to be summed up
 * @return {Array}                   The grouped object
 */
export const groupBy = (array: any[], criteria: string[], aggregator: string) => {
	return [...array.reduce((r, o) => {
    const keyValue = criteria.map(each => o[each]).join('-');

    let item = r.get(keyValue);
    if(item === undefined) {
      item = Object.assign({}, o, { [aggregator]: o[aggregator] ? o[aggregator] : 0 });
    }
    else {
      item[aggregator] += o[aggregator];
    }

    return r.set(keyValue, item);
  }, new Map).values()];
};
