import { useContext } from 'react';
import { IFiltersSelectors, IFiltersState, IStoreState } from 'src/app/props';
import { appActionTypes } from 'src/app/newActionTypes';
import { GlobalStateContext } from 'src/app/providers/GlobalStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';

// site specific filter data
export const useFilterDataSelectors: () => IFiltersSelectors = () => {
  const state: IStoreState = useContext(GlobalStateContext);
  const filtersState: IFiltersState = state.filters;

  return useSelectors(filtersState, (state: IFiltersState) => ({
    getOperationsFilterData: () => state.filters.operations,
    getNoiseEventsFilterData: () => state.filters.noiseEvents,
    getInfringementFilterData: () => state.filters.infringements,
    getReportsFilterData: () => state.filters.reports,
    hasCompletedInitialLoad: () => state.initialLoadCompleted,
    hasAccessFailed: () => state.failedToAccessFilters,
    getAirtrakFilterData: () => state.filters.airtrak,
    getComplaintsFilterData: () => state.filters.complaints,
    getSettingsFiltersData: () => state.filters.settings,
  }));
};

export const filtersInitialValues = {
  operations: null,
  noiseEvents: null,
  infringements: null,
  reports: null,
  airtrak: null,
  complaints: null,
  settings: null,
};

export const filtersInitialState: IFiltersState = {
  filters: { ...filtersInitialValues },
  failedToAccessFilters: false,
  initialLoadCompleted: false,
};

export const filterDataReducer = (state: IFiltersState, action: any) => {
  switch (action.type) {
    case appActionTypes.UPDATE_FILTERS:
      return Object.assign({}, state, {
        filters: action.payload,
        failedToAccessFilters: false,
        initialLoadCompleted: true,
      });
    case appActionTypes.FILTERS_ERROR:
      return Object.assign({}, state, {
        filters: action.payload,
        failedToAccessFilters: true,
        initialLoadCompleted: true,
      });
    default:
      return state;
  }
};
