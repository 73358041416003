import { useContext } from 'react';
import { isCompletedTimeFormat } from '@ems/client-design-system';
import { OperationStateContext } from 'src/@operations/providers/OperationsStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';
import { IFilterState, IFilterSelectors, IRangeFilters, ITimeRange } from 'src/@operations/props';
import { actionTypes } from 'src/@operations/newActionTypes';
import {
  letterCaseFormat,
  storeFilterReducerRow,
  readFilterReducerRow,
  storeFilterReducerRangeItem,
  readFilterReducerRangeItem,
  clearFilterReducerRow,
  clearFilterReducerRangeItem,
  ignoreSavedFilters,
  stripTrailingComma,
} from 'src/utils';
import { OPERATION, NULL_VALUE } from 'src/constants';

const buildOperationTagsClause = (selectedFilterKeys: string[]) => {
  let operationTagsString: string = '';
  for(const operationTag of selectedFilterKeys) {
    operationTagsString += `{name:"${operationTag}"},`
  }
  operationTagsString = stripTrailingComma(operationTagsString);
  return operationTagsString;
};

const buildCorrelatedClause = (selectedFilterKeys: string[]) => {
  const correlatedMapping = {
    NoiseEvents: 'hasNoiseEvents: true,',
    Infringements: 'hasInfringements: true,',
    Complaints: 'hasComplaints: true,',
    CCO: 'hasCcoInfringements: true,',
    CDO: 'hasCdoInfringements: true,',
  };
  let correlatedClause: string = '';
  for(let i = 0, ii = selectedFilterKeys.length; i < ii; i++) {
    const correlatedType: string | null = selectedFilterKeys[i];
    if(correlatedType === null) {
      return 'hasCorrelations: false,';
    }
    const mapped = correlatedMapping[correlatedType];
    correlatedClause += mapped ? mapped : `has${correlatedType}Infringements: true,`;
  }
  return correlatedClause;
};

const buildDefaultClause = (filterCategory: string, selectedFilterKeys: string[]) => {
  // For enum types, each value in the array must be unquoted
  const enumTypes = ['operationTypes', 'aircraftCategories', 'operatorCategories'];
  return enumTypes.includes(filterCategory) ?
    JSON.stringify(selectedFilterKeys).replace(/\"/g, '') :
    JSON.stringify(selectedFilterKeys)
};

const buildTimePeriodClause = (timeRange: ITimeRange) => {
  const { from: timeFrom, to: timeTo } = timeRange;
  const fromComplete = timeFrom && isCompletedTimeFormat(timeFrom);
  const toComplete = timeTo && isCompletedTimeFormat(timeTo);
  if (fromComplete && toComplete) {
    const fromSplit = timeFrom.split(':');
    const toSplit = timeTo.split(':');
    const fromInSeconds = Number(fromSplit[0]) * 60 * 60 + Number(fromSplit[1]) * 60;
    const toInSeconds = Number(toSplit[0]) * 60 * 60 + Number(toSplit[1]) * 60;
    return `timePeriod: {start: ${fromInSeconds}, end: ${toInSeconds}},`;
  } else {
    return '';
  }
}

export const useFilterSelectors: () => IFilterSelectors = () => {
  const state: any = useContext(OperationStateContext);
  const filterState: IFilterState = state.filter;

  return useSelectors(filterState, (state: IFilterState) => ({
    getIfInitialised: () => state.isInitialised,
    getRegularFilters: () => state.tableFilters,
    getTimeValues: () => state.time,
    getTimeFilter: () => {
      const { from, to } = state.time;
      const fromComplete = from && isCompletedTimeFormat(from);
      const toComplete = to && isCompletedTimeFormat(to);
      let label = '';
      if (fromComplete || toComplete) {
        label = `${from}-${to}`;
      }
      return {
        value: state.time,
        label,
      };
    },
    getIfFilterApplied: () => {
      let isFiltered = false;
      Object.entries(state.tableFilters).forEach(([, value]) => {
        if (value.length) {
          isFiltered = true;
        }
      });
      if (state.time.from !== '' && state.time.to !== '') {
        isFiltered = true;
      }
      return isFiltered;
    },
    getIfUsingPcaFilter: () => {
      return state.filterPca;
    },
    getPcaPosition: () => {
      return state.pcaPosition;
    },
    getPcaLocation: () => state.pcaLocation,
    getFilterString: () => {
      let filterString: string = '';
      for(const [filterCategory, selectedFilters] of Object.entries(state.tableFilters)) {
        if (!selectedFilters.length) {
          continue;
        }
        const selectedFilterKeys = [];
        for(const filter of selectedFilters) {
          const key = filter.key === NULL_VALUE ? null : letterCaseFormat(filterCategory, filter.key);
          selectedFilterKeys.push(key);
        }
        switch(filterCategory) {
          case 'correlated':
            filterString += buildCorrelatedClause(selectedFilterKeys);
            break;
          case 'operationTags':
            filterString += `includeTags: [${buildOperationTagsClause(selectedFilterKeys)}],`;
            break;
          default:
            filterString += `${filterCategory}: ${buildDefaultClause(filterCategory, selectedFilterKeys)},`;
        }
      }
      filterString += buildTimePeriodClause(state.time);
      filterString = stripTrailingComma(filterString);
      return `filter: {${filterString}}`;
    },
  }));
};

const initialRangeFilters: IRangeFilters = {
  from: '',
  to: '',
};

const initialStateObj: IFilterState = {
  isInitialised: false,
  tableFilters: {
    operationTypes: [],
    operatorCategories: [],
    operationTags: [],
    runwayNames: [],
    airportIds: [],
    remoteAirportIds: [],
    aircraftTypes: [],
    airlines: [],
    correlated: [],
    aircraftCategories: [],
  },
  time: initialRangeFilters,
  filterPca: false,
  pcaPosition: { latitude: 0, longitude: 0, altitude: null },
  pcaLocation: null,
};

export const filterInitialState: IFilterState = Object.assign({}, initialStateObj);

export const filterReducer = (state: IFilterState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_RANGE_FILTER: {
      const { type, value, field } = action.data;
      const newValue = {
        from: field === 'from' ? value : state[type].from,
        to: field === 'to' ? value : state[type].to,
      };

      storeFilterReducerRangeItem(OPERATION, type, newValue);
      return Object.assign({}, state, {
        [type]: newValue,
      });
    }
    case actionTypes.UPDATE_SELECTED_ITEMS: {
      const { category, selectedItems } = action.data;
      const newState = Object.assign({}, state, {
        tableFilters: {
          ...state.tableFilters,
          [category]: selectedItems,
        },
      });

      storeFilterReducerRow(OPERATION, newState.tableFilters);
      return newState;
    }
    case actionTypes.CLEAR_SELECTED_ITEMS:
      clearFilterReducerRow(OPERATION);
      clearFilterReducerRangeItem(OPERATION, 'time');
      return Object.assign({}, initialStateObj, {
        isInitialised: true,
      });
    case actionTypes.SET_TO_PCA_FILTER:
      const { latitude, longitude, elevation, location } = action.data;
      return Object.assign({}, state, {
        filterPca: true,
        pcaPosition: { latitude, longitude, altitude: elevation ? elevation : null },
        pcaLocation: location,
      });
    case actionTypes.CLEAR_PCA_FILTER:
      return Object.assign({}, state, {
        filterPca: false,
        pcaPosition: { latitude: 0, longitude: 0, altitude: null },
        pcaLocation: null,
      });
    case actionTypes.INITIALISE_STORE:
      const { initialFilters } = action.data;

      const savedFiltersRow = readFilterReducerRow(OPERATION);
      const savedTime = readFilterReducerRangeItem(OPERATION, 'time');
      storeFilterReducerRow(OPERATION, { ...initialStateObj.tableFilters, ...initialFilters });
      storeFilterReducerRangeItem(OPERATION, 'time', initialStateObj.time);
      ignoreSavedFilters(OPERATION);
      return Object.assign({}, state, {
        isInitialised: true,
        tableFilters: savedFiltersRow
          ? JSON.parse(savedFiltersRow)
          : { ...initialStateObj.tableFilters, ...initialFilters },
        time: savedTime ? JSON.parse(savedTime) : initialStateObj.time,
        filterPca: false,
      });
    default:
      return state;
  }
};
