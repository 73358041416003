import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
// components
import { SimpleSidebarLayout } from 'src/components/Layout';
import { ContentContainer } from 'src/@settings/containers';
// provider
import { SettingsStateProvider } from '../provider/SettingsStateProvider';

const SettingsScreen = () => (
  <SimpleSidebarLayout>
    <SettingsStateProvider>
      <div className="settings-container">
        <ContentContainer />
      </div>
    </SettingsStateProvider>
  </SimpleSidebarLayout>
);

export const Settings = withRouteProps(SettingsScreen);
